/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

import user4 from 'assets/images/users/avatar1.jpg'
import UserQuery from 'hooks/UserQuery'
import { useMemo } from 'react'
import AsyncImage from 'theme/ui/async-image'
import DeleteConfirmAlert from 'theme/ui/delete-confirm-alert'

const ProfileMenu = (props) => {
	const { user } = UserQuery()
	const profileAvatar = useMemo(() => user?.avatar?.path || user4, [user])

	const [menu, setMenu] = useState(false)
	const [username, setusername] = useState(user?.first_name)
	const [userProfilePicture, setUserProfilePicture] = useState(profileAvatar)

	useEffect(() => {
		if (user) {
			setusername(user?.name)
			setUserProfilePicture(profileAvatar)
		}
	}, [user])

	const [isOpen, setIsOpen] = useState(false)
	const onClose = () => setIsOpen(false)

	const onConfirm = () => {
		props.t('Logout')
		props.history.push('/logout')
	}

	return (
		<React.Fragment>
			<DeleteConfirmAlert
				title={'Logout ?'}
				subtitle={'Are you sure you want to Logout?'}
				isOpen={isOpen}
				onClose={onClose}
				onConfirm={onConfirm}
			/>
			<Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className='d-inline-block'>
				<DropdownToggle
					className='btn header-item waves-effect d-flex align-items-center'
					id='page-header-user-dropdown'
					tag='button'>
					<AsyncImage
						className='rounded-circle header-profile-user'
						src={userProfilePicture}
						alt='Header Avatar'
					/>
					<span className='d-none d-xl-inline-block fw-medium font-size-15 ms-1'>
						{username}
					</span>{' '}
					<i className='uil-angle-down d-none d-xl-inline-block font-size-15'></i>
				</DropdownToggle>
				<DropdownMenu className='dropdown-menu-end'>
					<Link to='/profile' className='dropdown-item'>
						<i className='uil uil-user-circle font-size-18 text-muted me-1 align-middle'></i>
						{props.t('View Profile')}{' '}
					</Link>
					<span className='dropdown-item cursor-pointer' onClick={() => setIsOpen(true)}>
						<i className='uil uil-sign-out-alt font-size-18 text-muted me-1 align-middle'></i>
						<span>Logout</span>
					</span>
				</DropdownMenu>
			</Dropdown>
		</React.Fragment>
	)
}

ProfileMenu.propTypes = {
	success: PropTypes.any,
	t: PropTypes.any,
}

const mapStatetoProps = () => {
	return {}
}

export default withRouter(connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)))
