import { capitalize } from 'lodash'
import * as yup from 'yup'
export const DURATIONS = {
	MONTHLY: 'monthly',
	ANNUAL: 'annual',
}

export const getInitialValues = (payment) => {
	return {
		transaction_id: payment?.transaction_id || null,
		amount: payment?.custom_price || 0,
		payment_method: payment?.payment_method ? createOption(payment?.payment_method) : '',
	}
}

const METHOD_TYPES = {
	ONLINE: 'online',
	OFFLINE: 'offline',
}

const createOption = (value, isDisabled = false) => ({
	label: capitalize(value),
	value: value,
	isDisabled,
})

export const planTypeOptions = [
	createOption(METHOD_TYPES.ONLINE),
	createOption(METHOD_TYPES.OFFLINE),
]

export const validationSchema = yup.object().shape({
	payment_method: yup.mixed().required('Please select payment method'),
	amount: yup
		.number()
		.min(1)
		.positive('Amount must be more then 0')
		.required('Please enter amount'),
})

export const getPayload = (values) => {
	return {
		payment_method: values?.payment_method?.value,
		custom_price: values?.amount,
		transaction_id: values?.transaction_id,
		status: values?.amount > 0 ? 'completed' : 'pending',
	}
}
