export const ROUTE_KEY = '/system-fields'

export const routes = {
	create: () => `${ROUTE_KEY}/create`,
	edit: (id) => `${ROUTE_KEY}/${id}/edit`,
}

export { default as Header } from './header'
export { default as SystemFieldForm } from './system-field-form'
export { default as SystemFieldQuery } from './system-field-form/system-field-query'
export { default as SystemFieldsListing } from './system-fields-listing'
