import { Fragment, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useLazyGetHouseholdsQuery } from 'modules/households/households-api'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import notification from 'utilities/notification'
import { toQueryString } from 'utils/commonFunctions'
import { useAssignGroupsMutation, useLazyGetInvitedClientsQuery } from '../invited-client-api'
import BulkActions from './bulk-actions'
import Filters from './filters'
import InvitedClientsTable from './invited-clients-table'

const InvitedClientsContainer = () => {
	const location = useLocation()
	const history = useHistory()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination
	const [rowSelection, setRowSelection] = useState({})
	const $selectedRows = useMemo(() => Object.keys(rowSelection).map(Number), [rowSelection])
	const [selectedRecords, setSelectedRecords] = useState([])
	const [previousData, setPreviousData] = useState()
	const [selectedTags, setSelectedTags] = useState({})
	const [tagList, setTagList] = useState([])
	const [householdList, setHouseholdList] = useState([])
	const [assignTags] = useAssignGroupsMutation()

	useEffect(() => {
		if (pagination) {
			const parseQueryString = getParseQueryString(location?.search)
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			history.push(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	const [fetchClients, { data, isLoading }] = useLazyGetInvitedClientsQuery()
	const [fetchHouseholds, { data: tagsData }] = useLazyGetHouseholdsQuery()

	useEffect(() => {
		if (fetchHouseholds) {
			fetchHouseholds({ limit: 1000, page: 1 })
		}
	}, [fetchHouseholds])

	const handleTagChange = (e, tag) => {
		const tagChecked = selectedTags[tag]?.checked
		if (selectedRecords?.length > 0) {
			setSelectedRecords((prevSelectedRecords) =>
				prevSelectedRecords?.map((record) => {
					const tags = record?.tags || []
					const hasTag = tags.includes(tag)

					if (tagChecked) {
						return {
							...record,
							tags: tags.filter((t) => t !== tag),
						}
					} else {
						return {
							...record,
							tags: hasTag ? tags : [...tags, tag],
						}
					}
				}),
			)
		} else {
			const isChecked = e.target.checked
			const newSelectedTags = { ...selectedTags }
			newSelectedTags[tag] = {
				checked: isChecked,
				indeterminate: false,
			}
			setSelectedTags(newSelectedTags)
			const checkedTaglist = Object.keys(newSelectedTags).filter(
				(key) => newSelectedTags[key].checked,
			)
			const tagsString = checkedTaglist.join(',')
			const encodedTags = encodeURIComponent(tagsString)
			setPagination((preRec) => ({
				...preRec,
				households: encodedTags,
			}))
			history.push(`${window.location.pathname}?groups=${encodedTags}`)
		}
	}

	useEffect(() => {
		if (tagsData?.households?.length > 0) {
			const tagsArr = tagsData?.households?.map((tag) => {
				return tag?.name
			})
			setTagList(tagsArr)
			const tagsDetails = tagsData?.households?.map((tag) => {
				return { name: tag?.name, id: tag?.id }
			})
			setHouseholdList(tagsDetails)
		}
	}, [tagsData])

	useEffect(() => {
		const newSelectedTags = {}
		if (selectedRecords?.length > 0) {
			tagList.forEach((tag) => {
				const tagInAllRecords = selectedRecords?.every((record) =>
					record?.tags?.includes(tag),
				)
				const tagInSomeRecords = selectedRecords?.some((record) =>
					record?.tags?.includes(tag),
				)
				newSelectedTags[tag] = {
					checked: tagInAllRecords,
					indeterminate: tagInSomeRecords && !tagInAllRecords,
				}
			})
			setSelectedTags(newSelectedTags)
		} else {
			setSelectedTags({})
			if (location?.search) {
				const parseQueryString = getParseQueryString(location?.search)
				if (parseQueryString?.households) {
					const listTags = parseQueryString?.households?.split(',')
					const newSelectedTags = {}
					listTags.forEach((tag) => {
						newSelectedTags[tag] = {
							checked: true,
							indeterminate: false,
						}
					})
					setSelectedTags(newSelectedTags)
				}
			}
		}
	}, [selectedRecords])

	useEffect(() => {
		const matchedIndexes = Object.keys(rowSelection).map(Number)
		const result = data?.clients?.filter((client) => matchedIndexes?.includes(client.id))
		setSelectedRecords(result)
		setPreviousData(result)
	}, [rowSelection])

	useEffect(() => {
		if (location?.search) {
			fetchClients({ ...getParseQueryString(location?.search), role: 'client' })
		}
		const parseQueryString = getParseQueryString(location?.search)
		if (parseQueryString?.groups) {
			const listTags = parseQueryString?.groups?.split(',')
			const newSelectedTags = {}
			listTags.forEach((tag) => {
				newSelectedTags[tag] = {
					checked: true,
					indeterminate: false,
				}
			})
			setSelectedTags(newSelectedTags)
		} else {
			setSelectedTags({})
		}
	}, [location?.search])

	const applyTags = async () => {
		if (selectedRecords?.length > 0) {
			const tagsPayload = []
			selectedRecords?.forEach((record) => {
				const tagIds = record?.tags
					.map((tag) => {
						const matchingTag = householdList.find((t) => t.name === tag)
						return matchingTag ? matchingTag?.id?.toString() : null
					})
					.filter((tagId) => tagId !== null)

				tagsPayload.push({
					user_id: record?.id,
					clientGroup_id: tagIds,
				})
			})
			const response = await assignTags({
				userClientGroups: tagsPayload,
			})
			if (response?.data?.status === 201) {
				notification('success', response?.data?.message)
			}
			setSelectedRecords([])
			setRowSelection({})
		}
	}

	return (
		<Fragment>
			{$selectedRows.length > 0 ? <BulkActions selectedRows={$selectedRows} /> : undefined}
			<Filters
				previousData={previousData}
				selectedRecords={selectedRecords}
				selectedTags={selectedTags}
				handleTagChange={handleTagChange}
				tagList={tagList}
				applyTags={applyTags}
				setPagination={setPagination}

			/>
			<InvitedClientsTable
				clients={data?.clients || []}
				isLoading={isLoading}
				meta={data?.meta}
				pagination={pagination}
				setPagination={setPagination}
				setRowSelection={setRowSelection}
				rowSelection={rowSelection}
			/>
		</Fragment>
	)
}

export default InvitedClientsContainer
