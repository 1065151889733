import { uniqWith } from 'lodash'
import moment from 'moment'
import Filters from './filters'

export const getFormikFilterValues = (values) => {
	const filters = {}

	const search = values?.search || ''
	if (search) {
		Object.assign(filters, { search })
	}

	if (values?.date_range) {
		const date_range = getDateRange(values?.date_range)
		Object.assign(filters, { ...date_range })
	}

	return filters
}

const getFitlerKey = (key) => {
	if (['from', 'to'].includes(key)) {
		return 'date_range'
	}
	return key
}

export const getActiveFilters = (filterObj) => {
	const filtersArray = Object.entries(filterObj)
		.filter((filter) => !['page', 'limit'].includes(filter[0]))
		.filter((filter) => {
			// eslint-disable-next-line no-unused-vars
			const [_, values] = filter
			return values.length > 0
		})
		.map((filter) => {
			const [key, values] = filter
			return [
				{
					label: filterKeys[key],
					name: getFitlerKey(key),
				},
				values,
			]
		})
	return uniqWith(filtersArray, (arrayVal, otherVal) => {
		return arrayVal[0]?.name === otherVal[0]?.name
	})
}

export const getInitialValues = (queryObj) => {
	const initialValues = {
		search: '',
		role: '',
		date_range: ',',
	}
	if (queryObj?.search) {
		Object.assign(initialValues, { search: queryObj?.search })
	}

	if (queryObj?.from && queryObj?.to) {
		Object.assign(initialValues, {
			date_range: `${queryObj?.from},${queryObj?.to}`,
		})
	}

	return initialValues
}

export const getDateRange = (range) => {
	switch (range) {
		case 'today':
			return {
				from: moment(new Date()).format('yyyy-MM-DD'),
				to: moment(new Date()).format('yyyy-MM-DD'),
			}

		case 'yesterday':
			return {
				from: moment().subtract(1, 'days').format('yyyy-MM-DD'),
				to: moment(new Date()).format('yyyy-MM-DD'),
			}

		case 'last_7_days':
			return {
				from: moment().subtract(7, 'days').format('yyyy-MM-DD'),
				to: moment(new Date()).format('yyyy-MM-DD'),
			}

		case 'last_30_days':
			return {
				from: moment().subtract(30, 'days').format('yyyy-MM-DD'),
				to: moment(new Date()).format('yyyy-MM-DD'),
			}

		case 'last_6_months':
			return {
				from: moment().subtract(180, 'days').format('yyyy-MM-DD'),
				to: moment(new Date()).format('yyyy-MM-DD'),
			}

		default:
			// eslint-disable-next-line no-case-declarations
			const dateRange = range.split(',')

			if (Array.isArray(dateRange) && dateRange.length > 1) {
				const [start, end] = dateRange

				if (
					moment(start, 'yyyy-MM-DD', true).isValid() &&
					moment(end, 'yyyy-MM-DD', true).isValid()
				) {
					return {
						from: start,
						to: end,
					}
				}
			}
	}
}

export const filterKeys = {
	search: 'Search',
	from: 'Date Range',
	to: 'Date Range',
}

export default Filters
