import classNames from 'classnames'
import { WorkflowStatuses } from 'utilities/helpers'
import TabsList from './tabs-list'

export const getTabClass = (activeTab, tab, status) => {
	if (activeTab === tab?.id) {
		return classNames('font-semibold text-main')
	} else if (tab?.isRejected) {
		return classNames('font-semibold text-main')
	} else if (
		(activeTab > tab?.id &&
			[
				WorkflowStatuses?.ASSIGNED,
				WorkflowStatuses?.IN_REVIEW,
				WorkflowStatuses?.IN_FINAL_REVIEW,
				WorkflowStatuses?.DRAFT,
			].includes(status)) ||
		(activeTab === tab?.id &&
			[WorkflowStatuses?.IN_REVIEW, WorkflowStatuses?.IN_FINAL_REVIEW].includes(status))
	) {
		return classNames('font-semibold text-[#228B22]')
	} else {
		return classNames('font-medium text-[#495057]')
	}
}

export { default as TabsListMobile } from './tabs-list-mobile'
export default TabsList
