import { useHistory, useParams } from 'react-router-dom'

import { SpinLoader } from 'components/Common'
import { SystemFieldForm, SystemFieldQuery } from 'modules/system-fields'
import ResourceNotFound from 'pages/StaticPages/ResourceNotFound'
import AppLayout from 'theme/layouts/app-layout'

const SystemFieldsEdit = () => {
	const history = useHistory()
	const params = useParams()
	const ID = params?.id
	const { field, isLoading } = SystemFieldQuery(ID)

	return (
		<AppLayout
			meta={{
				title: 'Edit System Field',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<div className='page-header bg-white'>
						<div className='flex w-full flex-col'>
							<div className='mb-3 flex w-full justify-between'>
								<div className='max-w-[733px]'>
									<h4 className='page-title'>Edit System Field</h4>
									<p className='text-xs text-[#74788D]'>
										These system fields, integral to the document mapping
										process, are available for your customization. You have the
										flexibility to precisely manage and configure these fields
										directly from this designated interface, empowering you to
										tailor the document mapping to meet your specific needs and
										preferences.
									</p>
								</div>

								<button
									onClick={() => history.goBack()}
									className='h-fit min-w-max rounded bg-main px-3 py-2 text-white'>
									Back
								</button>
							</div>
						</div>
					</div>
					<div className='page-body'>
						{isLoading ? (
							<SpinLoader />
						) : (
							<>
								{field ? (
									<SystemFieldForm field={field} />
								) : (
									<ResourceNotFound
										title='System field not found'
										description='Request system field is not found'
									/>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default SystemFieldsEdit
