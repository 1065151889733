import classNames from 'classnames'
import { ErrorMessage } from 'formik'
import { useMemo } from 'react'

const TextareaField = (props) => {
	const { field, form, label, id, isRequiredField, placeholder } = props
	const { onChange, onBlur, name, value } = field

	const touched = useMemo(() => form?.touched[name], [form?.touched])
	const error = useMemo(() => form?.errors[name], [form?.errors])

	return (
		<div className='form-group'>
			{label ? (
				<label className='text-sm font-semibold' htmlFor={id}>
					{label} {isRequiredField ? <span className='text-red-500'>*</span> : undefined}
				</label>
			) : undefined}

			<textarea
				name={name}
				onChange={onChange}
				onBlur={onBlur}
				id={id}
				cols='30'
				rows='4'
				value={value}
				placeholder={placeholder}
				className={classNames('form-control', { 'is-invalid': touched && error })}>
				{value}
			</textarea>
			<ErrorMessage name={name}>
				{(message) => {
					return <span className='error mt-[3px] text-[13px]'>{message.toString()}</span>
				}}
			</ErrorMessage>
		</div>
	)
}

export default TextareaField
