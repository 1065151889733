import classNames from 'classnames'
import { useMediaQuery } from 'react-responsive'
import FileCard from './file-card'

const UploadedFiles = ({ uploadedFiles }) => {
	const isDesktop = useMediaQuery({
		query: '(min-width: 768px)',
	})

	return (
		<div>
			<h3 className='mb-6 text-lg font-semibold text-gray2-dark'>Uploaded Files</h3>
			<div
				className={classNames(
					isDesktop
						? 'grid grid-cols-2 flex-wrap gap-3'
						: 'flex w-full space-x-3 overflow-scroll py-1',
				)}>
				{uploadedFiles.map((file, idx) => (
					<FileCard file={file} key={idx} />
				))}
			</div>
			<div className='my-3 !border' />
		</div>
	)
}

export default UploadedFiles
