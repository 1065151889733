import { Menu, Transition } from '@headlessui/react'
import { Field, FormikProvider, useFormik } from 'formik'
import { Fragment, useMemo } from 'react'

import { ReactComponent as ChevronDownIcon } from 'assets/svg/chevron-down.svg'

const ViewByDropdown = () => {
	const options = useMemo(
		() => [
			{
				label: 'Last edited',
				value: 'lastEdited',
			},
			{
				label: 'A to Z',
				value: 'atoz',
			},
			{
				label: 'Folder wise',
				value: 'folderwise',
			},
		],
		[],
	)
	const formik = useFormik({
		initialValues: {
			filter_type: {
				label: 'Last edited',
				value: 'lastEdited',
			},
		},
	})
	return (
		<div>
			<FormikProvider value={formik}>
				<form>
					<Field name='filter_type'>
						{({ field, form }) => (
							<Menu as='div' className='relative inline-block text-left'>
								<Menu.Button
									as='button'
									type='button'
									className='flex w-full min-w-[112px] justify-between rounded !border !border-gray-400 bg-white px-3 py-2 text-sm font-medium text-gray2'>
									<span>
										View by :{' '}
										<span className='font-medium text-black'>
											{' '}
											{field?.value?.label}
										</span>
									</span>
									<span className='ms-2'>
										<ChevronDownIcon />
									</span>
								</Menu.Button>
								<Transition
									as={Fragment}
									enter='transition ease-out duration-100'
									enterFrom='transform opacity-0 scale-95'
									enterTo='transform opacity-100 scale-100'
									leave='transition ease-in duration-75'
									leaveFrom='transform opacity-100 scale-100'
									leaveTo='transform opacity-0 scale-95'>
									<Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
										<div className='px-3 py-2'>
											<p className='m-0 mt-2 p-0 px-1 text-[15px] font-medium'>
												View By
											</p>
										</div>

										<div className='p-2'>
											{options.map((option, idx) => (
												<Menu.Item
													key={idx}
													as='button'
													type='button'
													onClick={() => {
														form.setFieldValue('filter_type', option)
													}}
													className={`flex w-full items-center space-x-1 rounded px-2 py-2 text-sm hover:bg-main/10 hover:text-main ${
														idx !== options.length - 1
															? 'border-b border-gray-300'
															: ''
													} ${
														field?.value?.value === option.value
															? 'font-semibold text-blue-600' // Apply blue color to selected option
															: ''
													}`}>
													<span className='p-1 text-[15px]'>
														{option?.label}
													</span>
												</Menu.Item>
											))}
										</div>
									</Menu.Items>
								</Transition>
							</Menu>
						)}
					</Field>
				</form>
			</FormikProvider>
		</div>
	)
}

export default ViewByDropdown
