import { useState } from 'react'
import { Link } from 'react-router-dom'
import Actions from './Actions'
import SearchInput from './search-input'
import { useMediaQuery } from 'react-responsive'

const PackageTable = ({ documents, packageDocument }) => {
	const [filteredData, setFilteredData] = useState(documents)
	const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
	return (
		<div>
			<SearchInput setFilteredData={setFilteredData} data={documents} />
			<table className='document-package '>
				<tbody>
					{filteredData?.length > 0 ? (
						filteredData?.map((document, idx) => {
							return (
								<tr key={idx}>
									<td className='w-[5%]'>
										<span>{idx + 1}.</span>
									</td>

									<div className='flex mobile_alignment py-3 sm:py-0'>
									<td className='w-[30%] remove_width file-box'>
										<span
											className='file-name max-w-fit font-ibmplex font-bold'
											title={`${document?.title}`}>{`${document?.title}`}
										</span>
									</td>                      

									<td className='remove_width w-[25%] ' align='left'>
											{document?.file?.original_name ? (
												<Link
													to={`documents/${document?.id}/edit`}
													className='hover:underline'>
													<span className='block truncate' title={document?.file?.original_name }>
														{document?.file?.original_name.length > 15 && isMobile
															? `${document?.file?.original_name.slice(
																	0,
																	15,
															  )}...`
															: document?.file?.original_name}
													</span>
												</Link>
											) : (
												'-'
											)}
										</td>
									</div>

									<td className='w-[20%]'>
										<Actions
											field={document}
											documents={filteredData}
											packageDocument={packageDocument}
										/>
									</td>
								</tr>
							)
						})
					) : (
						<tr>
							<td colSpan={5} className='text-center'>
								No records found
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	)
}

export default PackageTable
