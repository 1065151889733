import * as yup from 'yup'
import clientInvitation from './client-invitation'

export const validationSchema = yup.object({
	is_owner: yup.boolean().required(),
	teamName: yup
		.string()
		.typeError('Please enter team name')
		.when('is_owner', {
			is: true,
			then: (schema) => schema.required('Please enter team name'),
			otherwise: (schema) => schema.optional(),
		}),
})

export default clientInvitation
