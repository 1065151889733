import WebViewer from '@pdftron/webviewer'
import { disabledElements } from 'modules/documents/completed-documents/document-preview/config'
import { useLazyGetSettingQuery } from 'modules/settings/settings-api'
import { useEffect, useRef } from 'react'
import notification from 'utilities/notification'

const PdfViewer = ({ fileUrl }) => {
	const viewer = useRef(null)

	const [getSetting] = useLazyGetSettingQuery()
	const webviewerInstance = async (file) => {
		const response = await getSetting('pdf_tron_apikey')
		const settingValue = response?.data?.setting
		try {
			if (settingValue?.status === 200 && settingValue?.data?.setting_value) {
				return await WebViewer(
					{
						path: '/lib',
						initialDoc: file,
						disabledElements: disabledElements,
						licenseKey: settingValue?.data?.setting_value,
					},
					viewer.current,
				)
			}
		} catch (error) {
			notification(error)
		}
	}

	useEffect(() => {
		if (fileUrl) {
			webviewerInstance(fileUrl).then((instance) => {
				if (instance) {
					const { documentViewer, annotationManager } = instance.Core

					documentViewer.addEventListener('documentLoaded', () => {
						annotationManager.enableReadOnlyMode()
						documentViewer.enableReadOnlyMode()
					})
				}
			})
		}

		return () => {
			viewer.current = null
		}
	}, [fileUrl])

	return <div ref={viewer} style={{ height: '100vh' }}></div>
}

export default PdfViewer
