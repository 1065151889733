const MultiCheckbox = ({ input, options, readOnly }) => {
	const handleChange = (event) => {
		const values = Array.isArray(input.value) ? input.value : []
		const newValue = [...values]
		if (event.target.checked) {
			newValue.push(event.target.value)
		} else {
			const index = newValue.indexOf(event.target.value)
			if (index > -1) {
				newValue.splice(index, 1)
			}
		}
		return input.onChange(newValue)
	}

	return (
		<div className='flex flex-wrap gap-3'>
			{options.map((option, index) => (
				<label key={index} className='form-check-label'>
					<input
						type='checkbox'
						className='form-check-input'
						name={`${input.name}[${index}]`}
						value={option.value}
						checked={
							Array.isArray(input?.value) ? input?.value.includes(option.value) : []
						}
						onChange={handleChange}
						disabled={readOnly}
					/>
					&nbsp;
					{option.label}
				</label>
			))}
		</div>
	)
}

export default MultiCheckbox
