import PropTypes from 'prop-types'
import Select from 'react-select'
import { FormFeedback, FormGroup, Label } from 'reactstrap'

const CountryDropdown = ({
	meta: { touched, error },
	label,
	input,
	onInputChange,
	isSearchable = true,
	isLoading,
	isMulti,
	options,
	...rest
}) => {
	const onChange = (value) => {
		return input.onChange(value)
	}

	return (
		<FormGroup>
			{label && <Label>{label}</Label>}
			<Select
				{...input}
				{...rest}
				name={name}
				className='basic-single'
				classNamePrefix='select'
				options={options}
				onInputChange={onInputChange}
				onChange={onChange}
				onBlur={(value) => input.onBlur(value.value)}
				value={input.value}
				isSearchable={isSearchable}
				isClearable
				isMulti={isMulti}
				isLoading={isLoading}
			/>
			{touched && error && (
				<FormFeedback style={{ display: 'block' }}>{touched && error}</FormFeedback>
			)}
		</FormGroup>
	)
}

CountryDropdown.propTypes = {
	input: PropTypes.object.isRequired,
	meta: PropTypes.object,
	options: PropTypes.array,
}

CountryDropdown.defaultProps = {
	meta: null,
	options: [],
}

export default CountryDropdown
