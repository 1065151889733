import Header from 'modules/clients/header'
import HouseholdsListingContainer from 'modules/households/households-listing'
import { AppLayout } from 'theme/layouts'

const HouseholdsListing = () => {
	return (
		<AppLayout
			meta={{
				title: 'Groups list',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<div className='page-header bg-white'>
						<Header />
					</div>

					<div className='page-body'>
						<HouseholdsListingContainer />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default HouseholdsListing
