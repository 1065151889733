import { Link } from 'react-router-dom'
import { ANNOUNCEMENTS_APP_ROUTES } from './routes'

const Header = () => {
	return (
		<div className='flex w-full flex-col'>
			<div className='mb-3 flex w-full justify-between'>
				<div className='max-w-[733px]'>
					<h4 className='page-title'>Announcements</h4>
					<p className='text-xs text-[#74788D]'>
						Manage your announcements without hassle through our user-friendly
						dashboard. Stay in control of your communication strategy, easily monitor
						template performance and history, and make updates with just a few clicks,
						all in one streamlined hub designed for efficiency.
					</p>
				</div>
				<Link
					to={ANNOUNCEMENTS_APP_ROUTES.create()}
					className='h-fit min-w-max rounded bg-main px-3 py-2 text-white'>
					Create new
				</Link>
			</div>
		</div>
	)
}

export default Header
