import { useState } from 'react'

import { formatDate } from 'utilities/helpers'
import DeleteConfirmAlert from './delete-confirm-alert'

const Actions = ({ id, created_at, isActionActive }) => {
	const [isOpen, setIsOpen] = useState(false)
	const onClose = () => setIsOpen(false)

	return (
		<div className='w-[150px]'>
			<DeleteConfirmAlert isOpen={isOpen} onClose={onClose} id={id} />
			{isActionActive ? (
				<div className='flex space-x-2'>
					<button
						type='button'
						className='action-btn btn-red-outline'
						onClick={() => setIsOpen(true)}>
						<i className='uil-trash-alt' style={{ fontSize: 16 }} />
						<span>Delete</span>
					</button>
				</div>
			) : (
				<span className='text-sm !text-gray-700'>{formatDate(created_at)}</span>
			)}
		</div>
	)
}

export default Actions
