import { ReactComponent as DocumentIcon } from 'assets/svg/document-icon.svg'
import { ReactComponent as FolderIcon } from 'assets/svg/folder-icon.svg'
const DataList = ({ type, listData }) => {
	if (listData?.length === 0) {
		return <p className='text-center'>No {type}s found to review</p>
	}
	return (
		<div>
			{listData.map((item, idx) => (
				<div
					className='flex items-center justify-between space-x-2 px-4 py-3 shadow-sm'
					key={idx}>
					<div className='flex items-center gap-1'>
						{type === 'folder' ? (
							<FolderIcon height={15} />
						) : (
							<DocumentIcon height={20} />
						)}
						<span className='my-1 ml-2 text-sm font-medium'>{item?.title}</span>
					</div>
					<div>
						<input type='checkbox' className='form-check-input' />
					</div>
				</div>
			))}
		</div>
	)
}

export default DataList
