export const disabledElements = [
    "toolbarGroup-Shapes",
    "toolbarGroup-Edit",
    "toolbarGroup-Insert",
    "toolbarGroup-Annotate",
    "toolbarGroup-Forms",
    "notesPanel",
    "viewControlsButton",
    "selectToolButton",
    "toggleNotesButton",
    "searchButton",
    "freeTextToolGroupButton",
    "crossStampToolButton",
    "checkStampToolButton",
    "dotStampToolButton",
    "rubberStampToolGroupButton",
    "dateFreeTextToolButton",
    "eraserToolButton",
    "panToolButton",
    "signatureToolGroupButton",
    "viewControlsOverlay",
    "toolbarGroup-View",
    "leftPanelButton",
    "menuButton",
    "highlightToolGroupButton",
    "underlineToolGroupButton",
    "strikeoutToolGroupButton",
    "squigglyToolGroupButton",
    "stickyToolGroupButton",
    "stickyToolGroupButton",
    "markInsertTextGroupButton",
    "markReplaceTextGroupButton",
    "shapeToolGroupButton",
    "freeHandToolGroupButton",
    "freeHandHighlightToolGroupButton",
    "undoButton",
    "redoButton",
    "toolsOverlay",
]

export const signatureViewDisabledElement = [
    "menuButton",
    "leftPanelButton",
    "viewControlsButton",
    "panToolButton",
    "selectToolButton",
    "toolbarGroup-Shapes",
    "toolbarGroup-Edit",
    "toolbarGroup-Insert",
    "toolbarGroup-Annotate",
    "toolbarGroup-View",
    "toolbarGroup-FillAndSign",
    "toolbarGroup-EditText",
    "searchButton",
    "toggleNotesButton",
    "highlightToolGroupButton",
    "underlineToolGroupButton",
    "strikeoutToolGroupButton",
    "squigglyToolGroupButton",
    "stickyToolGroupButton",
    "markInsertTextGroupButton",
    "markReplaceTextGroupButton",
    "shapeToolGroupButton",
    "freeHandToolGroupButton",
    "freeHandHighlightToolGroupButton",
    "freeTextToolGroupButton",
    "eraserToolButton",
    "checkBoxFieldToolGroupButton",
    "radioButtonFieldToolGroupButton",
    "listBoxFieldToolGroupButton",
    "comboBoxFieldToolGroupButton",
    "textFieldToolGroupButton",
    // "toolsOverlay",
    // "signatureFieldToolButton",
    // "signatureFieldToolButton2",
    // "signatureFieldToolButton3",
    // "signatureFieldToolButton4",
    "applyFormFieldButton",
]