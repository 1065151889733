import { Dialog, Transition } from '@headlessui/react'
import { Field, FormikProvider, useFormik } from 'formik'
import { Fragment, useEffect, useMemo, useState } from 'react'

import otpIcon from 'assets/svg/otp-icon.svg'
import { useVerifyOtpMutation } from 'modules/authentication/auth-api'
import { Button, OtpField } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { convertPhoneNumber, getPayload, validationSchema } from '.'
import { fetchPermissions, removePermissions, setDefaultPermissionsGroup, setUserDetails } from '..'

const OtpVerifyPopup = ({ onClose, isOpen, loginFormik, user }) => {
	const [verifyOtp] = useVerifyOtpMutation()
	const credentials = useMemo(() => loginFormik?.values, [loginFormik?.values])

	const [seconds, setSeconds] = useState(90)

	useEffect(() => {
		if (seconds > 0) {
			const intervalId = setInterval(() => {
				setSeconds((prevSeconds) => prevSeconds - 1)
			}, 1000)

			return () => clearInterval(intervalId)
		} else {
			notification('warn', 'Please re-enter your credentials to regenerate the OTP.')
			onClose()
		}
	}, [seconds])

	const formik = useFormik({
		initialValues: {
			otp: 0,
		},
		validationSchema: validationSchema,
		onSubmit: async (values, { setSubmitting, resetForm }) => {
			try {
				setSubmitting(true)
				const payload = getPayload({ ...values, ...credentials })
				const response = await verifyOtp(payload)
				if (response?.data?.status === 200) {
					const user = response?.data?.user
					const permissions = await fetchPermissions()
					await setDefaultPermissionsGroup(permissions)
					await setUserDetails(user)
				} else {
					notification('warn', response?.data?.message)
					onClose()
					loginFormik.handleReset()
					resetForm()
				}
				setSubmitting(false)
			} catch (error) {
				await removePermissions()
				setSubmitting(false)
			}
		},
	})

	useEffect(() => {
		if (!isOpen) {
			formik.handleReset()
		}
	}, [isOpen])

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-10' onClose={() => {}}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/50' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'>
							<Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all'>
								<div className='mt-2 text-center'>
									<h3 className='text-lg font-medium leading-6 text-gray-900'>
										OTP Verification
									</h3>
									<p className='text-sm text-gray-500'>
										Please enter the OTP (one time password) to verify your
										account. A code has been sent to{' '}
										<span className='font-semibold text-gray-800'>
											{user?.phone_number
												? convertPhoneNumber(user?.phone_number)
												: undefined}
										</span>
									</p>
								</div>

								<img src={otpIcon} alt='' className='mx-auto h-24 text-gray-400' />

								<FormikProvider value={formik}>
									<form onSubmit={formik.handleSubmit}>
										<div className='mx-auto my-6 flex flex-col items-center justify-center space-y-4'>
											<Field name='otp' component={OtpField} otpLength={6} />
											<p className='text-center text-sm text-gray-500'>
												You OTP will expire in{' '}
												<span className='font-bold text-black'>
													{seconds}
												</span>{' '}
												seconds. After that, you will need to re-enter your
												credentials to generate a new OTP.
											</p>
											<div className='flex items-center space-x-3'>
												<Button
													type='button'
													variant='white'
													size='sm'
													onClick={onClose}>
													Cancel
												</Button>
												<Button
													type='submit'
													size='sm'
													isLoading={formik.isSubmitting}
													isLoadingText='Verifying...'>
													Verify
												</Button>
											</div>
										</div>
									</form>
								</FormikProvider>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export default OtpVerifyPopup
