import { toast } from 'react-toastify'

const notification = (type = 'info', message) => {
	switch (type) {
		case 'success':
			return toast.success(message)

		case 'error':
			return toast.error(message)

		case 'info':
			return toast.info(message)

		case 'warn':
			return toast.warn(message)

		default:
			return toast.info(message)
	}
}

export default notification
