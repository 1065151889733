import * as yup from 'yup'
import ClientInviteForm from './client-invite-form'

export const validationSchema = yup.object().shape({
	first_name: yup.string().required('Please enter first name'),
	last_name: yup.string().required('Please enter last name'),
	email: yup.string().required('Please enter email'),
	group_id: yup.mixed().required('Please select Role'),
})

export const getPayload = (values) => {
	return {
		first_name: values?.first_name,
		last_name: values?.last_name,
		email: values?.email,
		group_id: values?.group_id?.value,
		resend: values?.resend,
		isEmailSend: values?.isEmailSend,
	}
}

export default ClientInviteForm
