const NotificationButton = ({ formik }) => {
	const { values, setFieldValue } = formik

	const toggleNotification = () => {
		setFieldValue('send_notification', !values?.send_notification)
	}

	return (
		<button type='button' className='btn !bg-[#F9F9F9] !p-1' onClick={toggleNotification}>
			{values?.send_notification ? (
				<i className='uil-bell text-[16px]'></i>
			) : (
				<i className='uil-bell-slash text-[16px]'></i>
			)}
		</button>
	)
}

export default NotificationButton
