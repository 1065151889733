import classNames from 'classnames'
import { Field, FormikProvider, useFormik } from 'formik'
import { useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDataTable } from 'theme/ui/data-table'
import { Button, SearchField } from 'theme/ui/forms'
import { useDebounce } from 'use-debounce'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import { getFormikFilterValues, getInitialValues } from '.'

const SettingsFilters = ({ isEditable, handleChange, saveChanges, setPagination }) => {
	const history = useHistory()
	const location = useLocation()
	const parseQueryString = getParseQueryString(location?.search)
	const initialValues = getInitialValues(parseQueryString)
	const formik = useFormik({ initialValues: initialValues })
	const tableContext = useDataTable()

	const formValues = useMemo(() => formik?.values, [formik?.values])
	const [debounceValues] = useDebounce(formValues, 800)

	useEffect(() => {
		if (debounceValues) {
			const filterValues = getFormikFilterValues(debounceValues)
			if (Object.values(filterValues).length === 0) {
				setPagination({
					pageIndex: 0,
					pageSize: 10,
				})
			}
			const paginationParams = getPaginationParams(location?.search)
			const filterObj = {
				...getFormikFilterValues(debounceValues),
				page: 1,
				limit: paginationParams.limit,
			}
			const queryString = toQueryString(filterObj)
			history.push(`${window.location.pathname}?${queryString}`)
			if (tableContext) {
				tableContext.resetPageIndex()
			}
		}
	}, [debounceValues])
	return (
		<>
			<div className='mb-4 flex items-center justify-between  '>
				<FormikProvider value={formik}>
					<form className=''>
						<div className='flex flex-col space-y-3'>
							<div className='flex justify-between'>
								<div className='min-w-[320px]'>
									<Field
										className='form-control'
										type='search'
										name='search'
										component={SearchField}
										placeholder='Search system settings...'
									/>
								</div>
							</div>
						</div>
					</form>
				</FormikProvider>
				<div>
					{!isEditable ? (
						<Button
							type='button'
							variant='primary'
							onClick={handleChange}
							className={classNames('btn-sm')}>
							<i className='uil-edit me-2 text-sm' />
							<span>Edit</span>
						</Button>
					) : (
						<div className='flex gap-2'>
							<Button
								type='button'
								variant='white'
								onClick={handleChange}
								className={classNames('btn-sm')}>
								Cancel
							</Button>
							<Button
								type='button'
								variant='success'
								onClick={saveChanges}
								className={classNames('btn-sm')}>
								Save changes
							</Button>
						</div>
					)}
				</div>
			</div>
		</>
	)
}

export default SettingsFilters
