import { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Switch from 'react-switch'
import { Button } from 'reactstrap'

import apiEndpoints from 'helpers/apiEndpoints'
import UnAuthorize from 'pages/StaticPages/UnAuthorize'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { find } from 'store/common/actions'
import AppLayout from 'theme/layouts/app-layout'
import { isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'
import hasPermission from 'utils/checkPermission'
import { KanbanView } from './components/kanban'
import useKanbanQuery from './components/kanban/useKanbanQuery'
import ListView from './components/list-view'
import useListViewQuery from './components/list-view/useListViewQuery'
import TaskForm, { prepareInitialValues } from './components/task-form'

const TasksList = () => {
	const params = useParams()
	const history = useHistory()
	const location = useLocation()
	const TASK_ID = Number(params?.id)

	const [isKanbanView, setIsKanbanView] = useState(location.pathname.includes('/tasks-kanban'))
	const [task, setTask] = useState(null)
	const [isOpen, setIsOpen] = useState(false)
	const [isFetching, setIsFetching] = useState(false)
	const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 })
	const { assignments, meta, isLoading, mutate } = useListViewQuery(pagination)

	const onOpen = () => setIsOpen(true)
	const onClose = () => {
		setIsOpen(false)
		history.push(isKanbanView ? '/tasks-kanban' : '/tasks')
	}

	function toggleCanvas() {
		setIsOpen(!isOpen)
	}

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = 'auto'
		}

		return () => {
			document.body.style.overflow = 'auto'
		}
	}, [isOpen])

	const dispatch = useDispatch()

	useEffect(() => {
		if (params?.id) {
			onOpen()
			setIsFetching(true)
			setTask(null)
			dispatch(find(apiEndpoints.task, Number(params?.id))).then((response) => {
				setIsFetching(false)
				if (response?.status === 200) {
					const task = prepareInitialValues(response?.data)
					setTask(task)
				}
			})
		} else {
			const task = prepareInitialValues()
			setTask(task)
		}
	}, [params?.id])

	const { columns, mutate: refetch, isLoading: loading } = useKanbanQuery()

	const handleViewSwitch = () => {
		setIsKanbanView(!isKanbanView)
		history.push(isKanbanView ? '/tasks' : '/tasks-kanban')
	}

	return (
		<AppLayout
			meta={{
				title: 'Tasks',
			}}>
			{!hasPermission('task:list') ? (
				<UnAuthorize />
			) : (
				<div className='page-content px-0 !pt-[70px]'>
					<div className='page-container'>
						<div className='page-header bg-white'>
							<div>
								<h4 className='page-title'>Tasks List</h4>
								<p className='text-xs text-[#74788D]'>
									The most important feature in the workflow section is the
									creating one. When assigning documents/packages here,
									<br />
									do not ignore to fill in all the required fields completely and
									follow the document/package adding rules.
								</p>
								<div className='custom-switch flex items-center space-x-2'>
									<span>List View</span>
									<Switch
										uncheckedIcon={false}
										checkedIcon={false}
										onColor='#F1B44C'
										offColor='#626ed4'
										height={20}
										width={40}
										handleDiameter={12}
										onChange={handleViewSwitch}
										checked={isKanbanView}
									/>
									<span>Kanban View</span>
								</div>
							</div>

							{isAllowTo(permissions?.task?.create) ? (
								<Fragment>
									<Button color='primary' className='min-w-max' onClick={onOpen}>
										Create Task
									</Button>

									{!isFetching ? (
										<TaskForm
											initialValues={task}
											prepareInitialValues={prepareInitialValues}
											id={TASK_ID}
											canvasState={[isOpen, setIsOpen]}
											toggleCanvas={toggleCanvas}
											onClose={onClose}
											mutate={mutate}
											refetch={refetch}
										/>
									) : undefined}
								</Fragment>
							) : undefined}
						</div>

						<div className='page-body'>
							{isKanbanView ? (
								<KanbanView
									columns={columns}
									mutate={refetch}
									isLoading={loading}
								/>
							) : (
								<ListView
									assignments={assignments}
									meta={meta}
									isLoading={isLoading}
									mutate={mutate}
									pagination={pagination}
									setPagination={setPagination}
								/>
							)}
						</div>
					</div>
				</div>
			)}
		</AppLayout>
	)
}

export default TasksList
