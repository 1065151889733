import { SpinLoader } from 'components/Common'
import { useLazyGetPaymentEditQuery } from 'modules/payments/payments-api'
import PaymentForm from 'modules/payments/payments-form/payments-form'
import Pages404 from 'pages/StaticPages/Page404'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const PaymentEdit = () => {
	const params = useParams()
	const [fetchPayment, { data, isFetching }] = useLazyGetPaymentEditQuery(params?.id)
	useEffect(() => {
		if (params?.id) {
			fetchPayment(params?.id)
		}
	}, [params?.id])

	const payment = useMemo(() => data?.payment, [data?.payment])

	return (
		<AppLayout
			meta={{
				title: 'Payment details',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<PageHeader
						title='Payments'
						hasBackButton={true}
						description='Easily manage your payments hassle-free from one convenient dashboard. Stay in control of your finances, effortlessly track transaction history, and make changes with just a few clicks, all in one centralized hub designed for your convenience.'
					/>
					<div className='page-body'>
						{isFetching ? (
							<SpinLoader />
						) : (
							<>{data?.payment ? <PaymentForm payment={payment} /> : <Pages404 />}</>
						)}
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default PaymentEdit
