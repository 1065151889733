import { capitalize } from 'lodash'
import ReactSelect from 'react-select'

const FormBuilder = ({ document }) => {
	const fields = Array.isArray(document?.document_body) ? document?.document_body : []

	return (
		<div className=' rounded-b-lg bg-white p-6'>
			<div className=''>
				<h3 className='mb-1 text-lg'>Form Builder</h3>
				<p className='text-sm text-gray-500'>
					This section shows how the fields were mapped with their data types and how they
					were presented to the client.
				</p>
			</div>
			<div className='grid grid-cols-2 gap-6'>
				{fields.map((field, idx) => {
					const options = Array.isArray(field?.values) ? field?.values : []
					if (field?.type === 'select') {
						return (
							<div key={idx} className='form-group'>
								<label htmlFor=''>
									{field?.label} -{' '}
									<span className='text-[13px] font-normal  text-gray-500'>
										{capitalize(field?.user_type).replace('_', ' ')}
									</span>
								</label>
								<ReactSelect
									options={options}
									className='select-dropdown-container'
									classNamePrefix='select-dropdown'
									isDisabled
								/>
							</div>
						)
					} else if (field?.type === 'radio-group') {
						return (
							<div key={idx} className='form-group'>
								<label htmlFor=''>
									{field?.label} -{' '}
									<span className='text-[13px] font-normal  text-gray-500'>
										{capitalize(field?.user_type).replace('_', ' ')}
									</span>
								</label>
								<div className='flex flex-wrap gap-3'>
									{options.map((option, i) => (
										<div key={i}>
											<label htmlFor=''>
												<input
													type='radio'
													className='form-check-input'
													disabled
													name=''
													id=''
												/>
												&nbsp;
												{option?.label}
											</label>
										</div>
									))}
								</div>
							</div>
						)
					} else if (field?.type === 'checkbox') {
						return (
							<div key={idx} className='form-group'>
								<label htmlFor=''>
									{field?.label} -{' '}
									<span className='text-[13px] font-normal  text-gray-500'>
										{capitalize(field?.user_type).replace('_', ' ')}
									</span>
								</label>
								<div className='flex flex-wrap gap-3'>
									{options.map((option, i) => (
										<div key={i}>
											<label htmlFor=''>
												<input
													type='checkbox'
													className='form-check-input'
													name=''
													id=''
													disabled
												/>
												&nbsp;
												{option?.label}
											</label>
										</div>
									))}
								</div>
							</div>
						)
					} else {
						return (
							<div key={idx} className='form-group'>
								<label htmlFor=''>
									{field?.label} -{' '}
									<span className='text-[13px] font-normal  text-gray-500'>
										{capitalize(field?.user_type).replace('_', ' ')}
									</span>
								</label>
								<input
									type='text'
									name={field?.name}
									disabled
									className='form-control'
									id=''
								/>
							</div>
						)
					}
				})}
			</div>
		</div>
	)
}

export default FormBuilder
