import { Fragment, useState } from 'react'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

import { useAppRouter } from 'hooks'
import { useDeleteDocumentPackageMutation } from 'modules/documents/document-package-api'
import { dispatch } from 'store'
import DeleteConfirmAlert from 'theme/ui/delete-confirm-alert'
import notification from 'utilities/notification'
import * as actions from '../../../store/file-manager/actions'
import CreateFolder from '../search-bar/create-folder'
import AddToFolder from './add-to-folder'

const OptionsList = ({ data, type }) => {
	const [isOpen, setIsOpen] = useState(false)
	const { navigate } = useAppRouter()
	const [isDeleteOpen, setIsDeleteOpen] = useState({
		open: false,
		id: undefined,
	})
	const [isFolderPopupOpen, setIsFolderPopupOpen] = useState(false)
	const [menu, setMenu] = useState(false)

	const onOpen = () => setIsOpen(true)
	const onClose = () => setIsOpen(false)
	const [deleteDocumentPackage, { isLoading }] = useDeleteDocumentPackageMutation()
	const onDeleteOpen = (id) => {
		setIsDeleteOpen({
			open: true,
			id,
		})
	}
	const onDeleteClose = () =>
		setIsDeleteOpen({
			open: false,
			id: undefined,
		})
	const handleFolderDelete = async () => {
		const id = data?.id
		const response = await deleteDocumentPackage(id)
		if (response?.data?.status === 200) {
			notification('success', response?.data?.message)
		}
		onClose()
	}

	const attachInWorkflow = (data, type) => {
		const item = {
			title: data?.title,
			value: JSON.stringify({
				id: data?.id,
				minClients: data?.minClients,
			}),
		}
		if (type === 'folder') {
			dispatch(actions.setSelectedFolders(item))
			navigate(`/workflows/create?folder=true`)
		} else {
			dispatch(actions.setSelectedDocuments(item))
			navigate(`/workflows/create?document=true`)
		}
	}

	const handleOptionClick = (action, data, type) => {
		switch (action) {
			case 'view':
				if (type === 'folder') {
					console.log('View folder', data.id)
				} else {
					console.log('View file', data.id)
				}
				break
			case 'rename':
				setIsFolderPopupOpen(!isFolderPopupOpen)
				break
			case 'attach':
				attachInWorkflow(data, type)
				break
			case 'addToFolder':
				onOpen()
				break
			case 'star':
				console.log(type === 'folder' ? 'Star folder' : 'Star file', data.id)
				break
			case 'delete':
				if (type === 'folder') {
					onDeleteOpen()
				}
				break
			default:
				break
		}
	}

	return (
		<Fragment>
			<Dropdown
				isOpen={menu}
				toggle={() => setMenu(!menu)}
				className='dropdown inline-block'
				tag='li'>
				<DropdownToggle
					tag='button'
					type='button'
					className='m-0 !border !border-none bg-transparent p-0'>
					<i className='mdi mdi-dots-vertical text-[16px]'></i>
				</DropdownToggle>
				<DropdownMenu className='dropdown-menu-md dropdown-menu-end p-0'>
					<div className='w-full overflow-hidden rounded-md bg-white text-left shadow-lg ring-1 ring-black/5'>
						<div className='relative divide-y'>
							<div className='flex flex-col gap-1 py-2'>
								<span
									onClick={() => handleOptionClick('view', data, type)}
									className='flex cursor-pointer gap-3 px-3 py-2 text-[15px] hover:bg-blue-50 hover:text-blue-600'>
									<i className='uil-eye'></i>
									View
								</span>
								<span
									onClick={() => handleOptionClick('rename', data, type)}
									className='flex cursor-pointer gap-3 px-3 py-2 text-[15px] hover:bg-blue-50 hover:text-blue-600'>
									<i className='uil-pen'></i>
									Rename
								</span>
								<span
									onClick={() => handleOptionClick('attach', data, type)}
									className='flex cursor-pointer gap-3 px-3 py-2 text-[15px] hover:bg-blue-50 hover:text-blue-600'>
									<i className='uil-paperclip'></i>
									Attach in Workflow
								</span>
								<span
									onClick={() => handleOptionClick('addToFolder', data, type)}
									className='flex cursor-pointer gap-3 px-3 py-2 text-[15px] hover:bg-blue-50 hover:text-blue-600'>
									<i className='uil-folder'></i>
									Move to Folder
								</span>
								<span
									onClick={() => handleOptionClick('star', data, type)}
									className='flex cursor-pointer gap-3 px-3 py-2 text-[15px] hover:bg-blue-50 hover:text-blue-600'>
									<i className='uil-star'></i>
									Star
								</span>
								<span
									onClick={() => handleOptionClick('delete', data, type)}
									className='flex cursor-pointer gap-3 px-3 py-2 text-[15px] text-red-600 hover:bg-blue-50 hover:text-blue-600'>
									<i className='uil-trash text-red-600'></i>
									Delete
								</span>
							</div>
						</div>
					</div>
				</DropdownMenu>
			</Dropdown>
			<CreateFolder
				onClose={() => setIsFolderPopupOpen(!isFolderPopupOpen)}
				isOpen={isFolderPopupOpen}
				type={type}
				data={data}
			/>
			<AddToFolder onClose={onClose} isOpen={isOpen} type={type} data={data} />
			<DeleteConfirmAlert
				isOpen={isDeleteOpen?.open}
				onClose={onDeleteClose}
				isLoading={isLoading}
				onConfirm={handleFolderDelete}
				className='z-[9999]'
			/>
		</Fragment>
	)
}

export default OptionsList
