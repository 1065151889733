import CompletedDocumentsContainer from './completed-documents-container'

export const API_URL = {
	CLIENT_DOCUMENTS: '/client/v2/documents',
	ADVISOR_DOCUMENTS: '/advisor/v2/documents',
}

export const getDocumentUrl = (defaultGroupName) => {
	return defaultGroupName === 'client' ? API_URL.CLIENT_DOCUMENTS : API_URL.ADVISOR_DOCUMENTS
}

export const getWorkflowClient = (document, defaultGroupName) => {
	if (defaultGroupName === 'client') {
		return document?.workflow_step?.workflow?.client
	} else {
		return document?.workflow_step?.workflow?.parentWorkflow?.client
	}
}

export const getWorkflowTitle = (document, defaultGroupName) => {
	if (defaultGroupName === 'client') {
		return document?.workflow_step?.workflow?.title
	} else {
		return document?.workflow_step?.workflow?.parentWorkflow?.title
	}
}

export default CompletedDocumentsContainer
