import { FileManagerSlice } from './slices'

const { actions } = FileManagerSlice

export const setSelectedFolders = (payload) => (dispatch) => {
	dispatch(actions.setSelectedFolders(payload))
}

export const setSelectedDocuments = (payload) => (dispatch) => {
	dispatch(actions.setSelectedDocuments(payload))
}
