import noImage from 'assets/images/no-image.png'
import { useMemo } from 'react'
import { findObjectByField } from 'utils/commonFunctions'

const UploadFilesTab = ({ steps }) => {
	const STEP_TYPE = 'uploads'
	const currentStep = useMemo(() => findObjectByField(steps, 'type', STEP_TYPE), [steps])
	const files = currentStep?.files || []

	return (
		<table className='table-1 not-prose border text-sm'>
			<thead>
				<tr className='divide-x'>
					<th>#.</th>
					<th>File</th>
					<th>Action</th>
				</tr>
			</thead>
			<tbody>
				{files.length > 0 ? (
					files.map((file, idx) => (
						<tr key={idx} className='divide-x'>
							<td className='vertical-middle'>{idx + 1}</td>
							<td className='vertical-middle'>
								<a
									href={`${file?.files?.path}`}
									style={{
										fontSize: 11,
									}}
									title='Click Here to View'
									target='_blank'
									rel='noreferrer'>
									<div className='d-flex align-items-center btn-link link w-f-content'>
										<div>
											<img
												src={noImage}
												style={{
													height: 40,
												}}
												alt='no-image'
											/>
										</div>
										<div
											className='flex-col'
											style={{
												marginLeft: 10,
											}}>
											<strong className='ml-3'>{file?.title}</strong>
											<span className='text-muted ml-3'>
												{file?.files?.original_name}
											</span>
										</div>
									</div>
								</a>
							</td>
							<td className='vertical-middle'>
								<a
									href={`${file?.files?.path}`}
									target='_blank'
									rel='noreferrer'
									className='btn btn-primary btn-sm flex items-center space-x-1'>
									<i className='fas fa-download' />
									<span>Download</span>
								</a>
							</td>
						</tr>
					))
				) : (
					<tr>
						<td colSpan={3}>
							<p className='m-0 text-center '>No files found</p>
						</td>
					</tr>
				)}
			</tbody>
		</table>
	)
}

export default UploadFilesTab
