import { Field } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'

import { SelectField } from 'theme/ui/forms'
import { getDocumentOptions } from '.'
import { useLazyGetDocumentTemplatesQuery } from '../document-api'

const DocumentTemplateDropdown = ({ name }) => {
	const [search, setSearch] = useState('')
	const [debounceSearch] = useDebounce(search, 600)

	const params = useMemo(() => {
		const $params = { page: 1, limit: 20, status: 'published' }
		if (debounceSearch) {
			Object.assign($params, { search: debounceSearch })
		}

		return $params
	}, [debounceSearch])

	const [fetchDocumentTemplates, { data, isFetching }] = useLazyGetDocumentTemplatesQuery()

	useEffect(() => {
		fetchDocumentTemplates({ params })
	}, [params])

	const documentOptions = useMemo(() => getDocumentOptions(data?.documents), [data?.documents])

	return (
		<Field
			isRequired={true}
			label='Select Documents'
			name={name}
			component={SelectField}
			options={documentOptions}
			isLoading={isFetching}
			onInputChange={(value) => setSearch(value)}
			isSearchable={true}
			isMulti={true}
			isRequiredField
			placeholder='For eg:- Invoices Copy, Tax Invoices'
		/>
	)
}

export default DocumentTemplateDropdown
