import { Field } from 'formik'
import { useState } from 'react'

import { SelectField } from 'theme/ui/forms'
import useSubscriptionOptionsQuery from './use-subscription-options-query'

const SubscriptionDropdown = () => {
	const [search, setSearch] = useState('')
	const { isLoading, options } = useSubscriptionOptionsQuery(search)

	return (
		<Field
			label='Select Subscription Plan'
			name='subscription'
			type='select'
			options={options}
			isLoading={isLoading}
			isRequiredField
			isSearchable={false}
			onInputChange={(value) => setSearch(value)}
			component={SelectField}
		/>
	)
}

export default SubscriptionDropdown
