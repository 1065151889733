import { useSelector } from 'react-redux'

import { SpinLoader } from 'components/Common'
import { useAppRouter } from 'hooks'
import { AssignSubscription, useLazyGetTeamDetailsQuery } from 'modules/teams'
import { useEffect } from 'react'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'
import UnAuthorizePage from '../StaticPages/UnAuthorize'

const TeamsSubscribe = () => {
	const { params } = useAppRouter()
	const teamId = params?.id
	const [fetchTeam, { data, isFetching }] = useLazyGetTeamDetailsQuery()
	useEffect(() => {
		if (teamId) {
			fetchTeam(teamId)
		}
	}, [teamId])

	const isSuperAdmin = useSelector((state) => state.Permissions.isSuperAdmin)

	return (
		<AppLayout
			meta={{
				title: 'Assign Subscription Team',
			}}>
			{isSuperAdmin ? (
				<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
					<div className='page-container'>
						<PageHeader
							hasBackButton={true}
							title='Assign Subscription'
							description='Easily manage your teams hassle-free from one convenient dashboard. Stay in control of your spending, effortlessly track renewal dates, and make changes with just a few clicks, all in one centralized hub designed for your convenience'></PageHeader>
						<div className='page-body'>
							{isFetching ? (
								<SpinLoader />
							) : (
								<AssignSubscription team={data?.data?.team} />
							)}
						</div>
					</div>
				</div>
			) : (
				<UnAuthorizePage />
			)}
		</AppLayout>
	)
}

export default TeamsSubscribe
