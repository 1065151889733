import { Fragment, useMemo } from 'react'
import StickyBox from 'react-sticky-box'
import { reduxForm } from 'redux-form'
import WorkflowCommentSection from '../workflow-comment-section'
import AssignedFields from './assigned-fields'
import UploadedFiles from './uploaded-files'

const WorkflowReviewCard = ({ uploads, workflow, handleSubmit, individual_document }) => {
	const user_type = useMemo(() => workflow?.user_type, [workflow])
	const userkey = useMemo(() => (user_type === 'advisor' ? 'advisor' : 'client'), [user_type])
	const user = useMemo(() => workflow[userkey], [workflow])

	return (
		<Fragment>
			<form onSubmit={handleSubmit}>
				<div className=''>
					<div className='flex items-center space-x-10'>
						<div className='flex flex-col'>
							<span className='text-sm font-bold'>Workflow Name</span>
							<span className='text-sm font-normal text-[#74788D]'>
								{workflow?.workflow_template?.title || '-'}
							</span>
						</div>

						<div className='flex flex-col'>
							<span className='text-sm font-bold'>User ({workflow?.user_type})</span>
							<span className='text-sm font-normal text-[#74788D]'>
								{user?.first_name} {user?.last_name}
							</span>
						</div>

						<div className='flex flex-col'>
							<span className='text-sm font-bold'>Current Status</span>
							<span className='text-sm font-normal text-[#74788D]'>
								{workflow?.status}
							</span>
						</div>
					</div>

					<div>
						<h3 className='mb-2 mt-8 text-lg font-bold not-italic leading-6 text-[#495057]'>
							Assigned Fields
						</h3>
						<div className='flex w-full justify-between space-x-4'>
							<div
								style={{
									width: 'calc(100% - 280px)',
								}}>
								<AssignedFields
									individual_document={individual_document}
									user_type={user_type}
								/>
							</div>
							<div className='w-[280px]'>
								<StickyBox offsetBottom={20} offsetTop={80}>
									<UploadedFiles uploads={uploads} />
								</StickyBox>
							</div>
						</div>
					</div>
				</div>
			</form>
			<WorkflowCommentSection workflowId={workflow?.id} stepId={individual_document?.id} />
		</Fragment>
	)
}

export default reduxForm({
	form: 'workflow_review',
	enableReinitialize: true,
})(WorkflowReviewCard)
