import { Link } from 'react-router-dom'

import { UncontrolledTooltip } from 'reactstrap'
import { TEAM_APP_ROUTES } from '../routes'

const TeamActions = ({ team, isActionActive }) => {
	return (
		<div className='max-w-fit'>
			{isActionActive ? (
				<div className='flex w-[70px] space-x-2'>
					<div id={`view_${team?.id}`}>
						<Link
							title='View'
							to={TEAM_APP_ROUTES.findOne(team?.id)}
							className='action-btn btn-gray-outline !py-1'>
							<i className='uil-eye' style={{ fontSize: 16 }} />
						</Link>
						<UncontrolledTooltip placement='top' target={`view_${team?.id}`}>
							View Details
						</UncontrolledTooltip>
					</div>

					{!team?.subscription ? (
						<div id={`assing_sub_${team?.id}`}>
							<Link
								title='Assign Subscription'
								to={TEAM_APP_ROUTES.subscribe(team?.id)}
								className='action-btn btn-info-outline !py-1.5'>
								<i className='far fa-money-bill-alt' style={{ fontSize: 16 }} />
							</Link>
							<UncontrolledTooltip placement='top' target={`assing_sub_${team?.id}`}>
								Assign Subscription
							</UncontrolledTooltip>
						</div>
					) : undefined}
				</div>
			) : (
				<span>{team?.created_at}</span>
			)}
		</div>
	)
}

export default TeamActions
