import { Field, FormikProvider, useFormik } from 'formik'
import { useEffect, useMemo, useState } from 'react'

import { ReactComponent as SearchIcon } from 'assets/svg/search-icon.svg'
import { useAppRouter, useUser } from 'hooks'
import { DOCUMENT_TEMPLATE_APP_ROUTES } from 'modules/documents/routes'
import { Button } from 'theme/ui/forms'
import { getFormValues } from '.'
import { FILE_MANAGER_ROUTES } from '../routes'
import CreateFolder from './create-folder'
import SearchItemDropdown from './search-item-dropdown'

const SearchBar = () => {
	const { location, navigate } = useAppRouter()
	const { isSuperAdmin } = useUser()
	const [isOpen, setIsOpen] = useState(false)
	const onOpen = () => setIsOpen(true)
	const onClose = () => setIsOpen(false)

	const formik = useFormik({
		initialValues: {
			search: '',
			search_type: {
				icon: 'uil-file-blank',
				label: 'All',
				value: 'all',
			},
		},
	})
	const formValues = useMemo(
		() => (formik?.values?.search?.length >= 3 ? getFormValues(formik?.values) : undefined),
		[formik?.values],
	)

	useEffect(() => {
		if (formValues) {
			console.log('formValues', formValues)
		}
	}, [formValues])

	return (
		<div className='flex items-center justify-between bg-[#F2ECFF] px-[30px] py-[15px]'>
			<FormikProvider value={formik}>
				<form className='flex-1'>
					<div className='flex h-[56px] w-full max-w-[512px] items-center justify-between space-x-1 rounded bg-white px-2.5 shadow'>
						<div className='px-2'>
							<SearchIcon height={20} width={20} />
						</div>
						<Field name='search'>
							{({ field }) => (
								<input
									type='text'
									{...field}
									className='flex-1 px-1 py-2 text-base font-normal text-gray2-dark'
									placeholder='Search documents & folder'
								/>
							)}
						</Field>

						<SearchItemDropdown />
					</div>
				</form>
			</FormikProvider>
			<CreateFolder onClose={onClose} isOpen={isOpen} type={'folder'} />
			{[FILE_MANAGER_ROUTES.allFolders()].includes(location?.pathname) ? (
				<div className='rounded bg-white'>
					<Button
						type='button'
						onClick={() => setIsOpen(true)}
						variant='primary-outline'
						className='bg-white '>
						<div className='flex items-center space-x-2 font-medium'>
							<span className='text-base'>+</span>
							<span>Create New</span>
						</div>
					</Button>
				</div>
			) : undefined}

			{!isSuperAdmin && [FILE_MANAGER_ROUTES.myDocuments()].includes(location?.pathname) ? (
				<div className='rounded bg-white'>
					<Button
						type='button'
						variant='primary-outline'
						onClick={() => navigate(DOCUMENT_TEMPLATE_APP_ROUTES.create())}
						className='bg-white '>
						<div className='flex items-center space-x-2 font-medium'>
							<span className='text-base'>+</span>
							<span>Create New</span>
						</div>
					</Button>
				</div>
			) : undefined}

			{isSuperAdmin &&
			[FILE_MANAGER_ROUTES.readyMadeTemplate()].includes(location?.pathname) ? (
				<div className='rounded bg-white'>
					<Button
						type='button'
						variant='primary-outline'
						onClick={() => navigate(DOCUMENT_TEMPLATE_APP_ROUTES.create())}
						className='bg-white '>
						<div className='flex items-center space-x-2 font-medium'>
							<span className='text-base'>+</span>
							<span>Create New</span>
						</div>
					</Button>
				</div>
			) : undefined}
		</div>
	)
}

export default SearchBar
