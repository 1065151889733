import FilterDropdown from './FilterDropdown'

export default FilterDropdown

export const FILTERS = {
	WORKFLOW: 'workflow',
	CLIENTS: 'clients',
	DUE_DATE: 'due_date',
	HAS_ATTACHMENT: 'has_attachment',
}

export const filterOptions = [
	{
		label: 'Workflow',
		key: FILTERS.WORKFLOW,
	},
	{
		label: 'Clients',
		key: FILTERS.CLIENTS,
	},
	{
		label: 'Due Date',
		key: FILTERS.DUE_DATE,
	},
	{
		label: 'Has Attachment',
		key: FILTERS.HAS_ATTACHMENT,
	},
]
