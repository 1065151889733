import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'

import { TextField } from 'components/Forms/TextFieldComponent'
import { required } from 'utils/formValidation'

const validate = {
	email: required('email'),
	first_name: required('first name'),
	last_name: required('last name'),
}

const StepForm = ({ handleSubmit, isReadOnly, workflowUUID }) => {
	return (
		<Fragment>
			<div className='step1-container'>
				<h3 className='mb-2 text-center'>Create Account</h3>
				<p className='text-muted mb-4 text-center'>Get your Experivise account now</p>

				{/* {!isReadOnly ? (
					<Fragment>
						<button className='btn-outline--primary flex items-center justify-center space-x-3'>
							<img src={google} alt='' />
							<span>Continue with google</span>
						</button>

						<div className='my-3 flex w-full justify-center'>
							<div className='flex w-full items-center'>
								<div className='line' />
								<div>
									<span className='mx-1'>Or use email</span>
								</div>
								<div className='line' />
							</div>
						</div>
					</Fragment>
				) : undefined} */}

				<form className='flex flex-col space-y-5' onSubmit={handleSubmit}>
					<div className='grid-col-1 grid gap-1'>
						{workflowUUID ? (
							<div className='grid grid-cols-2 gap-3'>
								<Field
									label='First Name'
									name='first_name'
									placeholder='First Name'
									component={TextField}
									validate={validate.first_name}
								/>
								<Field
									label='Last Name'
									name='last_name'
									placeholder='Last Name'
									component={TextField}
									validate={validate.last_name}
								/>
							</div>
						) : undefined}

						<Field
							label='Email'
							name='email'
							placeholder='Email'
							component={TextField}
							readOnly={isReadOnly}
							validate={validate.email}
						/>
					</div>

					<div className='text-center'>
						<button
							type='submit'
							className='btn-primary-1 mx-auto rounded-[4px] px-10 py-2'>
							Next
						</button>
					</div>

					<p className='text-center font-normal'>
						Already have an account? <Link to='/login'>Sign in</Link>
					</p>
				</form>
			</div>
		</Fragment>
	)
}

export default reduxForm({
	form: 'onboarding_step_one',
	enableReinitialize: true,
})(StepForm)
