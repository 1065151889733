import { useState } from 'react'
import { Tooltip } from 'reactstrap'
import Badge from 'theme/ui/badge'
import { formatDate } from 'utilities/helpers'
import { OnBoardStatuses } from '../invited-clients'

const StatusColumn = ({ status, invitation_expires_at, created_at, id }) => {
	const [tooltipOpen, setTooltipOpen] = useState(false)
	const toggle = () => setTooltipOpen(!tooltipOpen)

	if (status === OnBoardStatuses.APPROVED) {
		return <Badge variant='success'>Onboarded</Badge>
	} else if ([OnBoardStatuses.ONBOARDING].includes(status)) {
		return <Badge variant='info'>Onboarding</Badge>
	} else if ([OnBoardStatuses.REJECTED].includes(status)) {
		return <Badge variant='danger'>Rejected</Badge>
	} else if ([OnBoardStatuses.RE_SUBMITTED, OnBoardStatuses.SUBMITTED].includes(status)) {
		return <Badge variant='primary'>Submitted</Badge>
	} else if ([OnBoardStatuses.NON_INVITED].includes(status)) {
		return <Badge variant='secondary'>Non Invited</Badge>
	} else if ([OnBoardStatuses.PENDING].includes(status)) {
		return (
			<div className='flex items-center space-x-1'>
				<Badge variant='warning'>Invited</Badge>
				<span
					className='bx bx-info-circle cursor-pointer text-gray-500'
					id={`invited-${id}`}
				/>
				<Tooltip
					isOpen={tooltipOpen}
					target={`invited-${id}`}
					toggle={toggle}
					placement='auto'
					className='!min-w-[250px]'>
					<ul className='m-0 list-none p-0 text-xs'>
						<li>Invited At: {created_at}</li>
						<li>Expires At: {formatDate(invitation_expires_at)}</li>
						<li>Onboard Status: {status}</li>
					</ul>
				</Tooltip>
			</div>
		)
	} else {
		return <Badge>-</Badge>
	}
}

export default StatusColumn
