import { FILTERS } from '.'
import ClientFilter from './ClientFilter'
import DueDateFilter from './DueDateFilter'
import HasAttachment from './HasAttachment'
import WorkflowFilter from './WorkflowFilter'

const ActiveFilter = ({ onClearFilter, activeFilter }) => {
	switch (activeFilter) {
		case FILTERS.WORKFLOW:
			return <WorkflowFilter activeFilter={activeFilter} onClearFilter={onClearFilter} />

		case FILTERS.CLIENTS:
			return <ClientFilter activeFilter={activeFilter} onClearFilter={onClearFilter} />

		case FILTERS.DUE_DATE:
			return <DueDateFilter activeFilter={activeFilter} onClearFilter={onClearFilter} />

		case FILTERS.HAS_ATTACHMENT:
			return <HasAttachment activeFilter={activeFilter} onClearFilter={onClearFilter} />
	}
}

export default ActiveFilter
