import DocumentTabs from 'modules/documents/document-tabs'
import DocumentTemplateContainer from 'modules/documents/document-templates'
import DocumentTemplateHeader from 'modules/documents/header'
import AppLayout from 'theme/layouts/app-layout'

const DocumentTemplatesListing = () => {
	return (
		<AppLayout
			meta={{
				title: 'Documents Templates',
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<DocumentTemplateHeader />
					<div className='page-body'>
						<DocumentTabs />
						<div className='mt-3'>
							<DocumentTemplateContainer />
						</div>
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default DocumentTemplatesListing
