import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const Actions = ({ isActionActive }) => {
	return (
		<div className='w-[120px]'>
			{isActionActive ? (
				<Link className='text-sm font-medium'>
					<span>Use as template</span>
					<i className='uil-angle-right text-base'></i>
				</Link>
			) : undefined}
		</div>
	)
}

export default Actions
