import { Fragment } from 'react'
import { Field } from 'redux-form'
import PdfFieldRender from './pdf-field-render'

const DocumentBoundFieldsTab = ({ documentBoundFields, isReadOnly, currentStatus }) => {
	return (
		<div className='flex flex-col divide-y'>
			{documentBoundFields.map((document, idx) => {
				const fieldName = `document_bound_fields[${idx}]`
				return (
					<div key={idx} className='flex flex-col py-3'>
						<h3 className='mb-3 mt-0 text-lg font-semibold'>
							{idx + 1}. {document?.title}
						</h3>

						<div className='grid grid-cols-1 gap-x-3 md:grid-cols-2'>
							<Field
								type='hidden'
								name={`${fieldName}.document_title`}
								value={document?.title}
								component='input'
							/>

							<Fragment>
								{document?.fields.length > 0 ? (
									<>
										{document.fields.map((field, fidx) => (
											<div key={fidx}>
												<PdfFieldRender
													fieldName={`${fieldName}.fields[${fidx}].${field?.name}`}
													field={field}
													error={undefined}
													isReadOnly={isReadOnly}
													currentStatus={currentStatus}
												/>
											</div>
										))}
									</>
								) : (
									<p className='col-span-2 m-0 text-center text-sm text-gray-500'>
										No document-bound fields were found here.
									</p>
								)}
							</Fragment>
						</div>
					</div>
				)
			})}
		</div>
	)
}

export default DocumentBoundFieldsTab
