import { Fragment, useEffect, useState } from 'react'

import { useAppRouter } from 'hooks'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import { useLazyGetTeamsQuery } from '../teams-api'
import TeamFilters from './team-filters'
import TeamTable from './team-table'

const TeamListingContainer = () => {
	const { location, navigate, parseQuery } = useAppRouter()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination

	useEffect(() => {
		if (pagination) {
			const queryString = toQueryString({
				...parseQuery,
				page: pageIndex + 1,
				limit: pageSize,
			})
			navigate(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	const [fetchTeams, { data, isLoading }] = useLazyGetTeamsQuery()

	useEffect(() => {
		if (location?.search) {
			fetchTeams(getParseQueryString(location?.search))
		}
	}, [location?.search])

	return (
		<Fragment>
			<TeamFilters />
			<TeamTable
				data={data?.teams}
				meta={data?.meta}
				isLoading={isLoading}
				pagination={pagination}
				setPagination={setPagination}
			/>
		</Fragment>
	)
}

export default TeamListingContainer
