import { WorkflowStatuses } from 'utilities/helpers'
import FieldType from './field-type'

const PdfFieldRender = ({ field, fieldName, error, isReadOnly, currentStatus }) => {
	const $isReadOnly =
		currentStatus === WorkflowStatuses.REJECTED ? field?.status === 'approved' : isReadOnly

	return (
		<div className='flex flex-col'>
			<FieldType field={field} fieldName={fieldName} isReadOnly={$isReadOnly} />
			{error ? <div className='invalid-feedback block'>{error}</div> : undefined}
		</div>
	)
}

export default PdfFieldRender
