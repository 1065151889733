import { Fragment, useRef, useState } from 'react'

import { useUploadFileMutation } from 'modules/files/file-apis'
import { FileUploadField } from 'theme/ui/forms'
import notification from 'utilities/notification'

const FileUploadCard = ({ title, onSetFileId }) => {
	const [isUploading, setIsUploading] = useState(false)
	const fileRef = useRef(null)
	const [uploadFile] = useUploadFileMutation()

	const onChange = async ($file) => {
		try {
			if ($file) {
				setIsUploading(true)
				onSetFileId(null)
				const formData = new FormData()
				formData.append('file', $file)
				const response = await uploadFile({ payload: formData })
				setIsUploading(false)
				if (response?.data?.status === 200) {
					notification(
						'success',
						'File uploaded successfully, please click on update button to save',
					)

					onSetFileId(response?.data?.data?.fileID)
				}
			}
		} catch (error) {
			setIsUploading(false)
		}
	}

	return (
		<Fragment>
			<FileUploadField
				isUploading={isUploading}
				label={title}
				onUpload={(file) => onChange(file)}
				isMarginBottom={false}
				fileRef={fileRef}
				allowedExtentions='.jpg,.jpeg,.png,.pdf'
			/>
		</Fragment>
	)
}

export default FileUploadCard
