import { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'

import logo from 'assets/images/experivise-logo-white.png'
import bg1 from 'assets/images/onboarding/bg1.png'
import apiEndpoints from 'helpers/apiEndpoints'
import { useAppRouter } from 'hooks'
import { store } from 'store/common/actions'
import notification from 'utilities/notification'
import { showSuccessToast, useQuery } from 'utils/commonFunctions'
import StepForm from './StepForm'

const StepTwo = (props) => {
	const { history } = props
	const { token } = useQuery(props.location.search)
	const { email } = useSelector((state) => state?.clientOnboarding)
	const [otp, setOtp] = useState('')
	const { navigate, parseQuery } = useAppRouter()
	const $continue = parseQuery?.continue
	const dispatch = useDispatch()
	const [isLoading, setIsLoading] = useState(false)

	const onSubmit = () => {
		setIsLoading(true)
		const payload = { email: email, otp }
		dispatch(store(apiEndpoints.userOnBoard.email.verifyOTP, payload)).then((response) => {
			setIsLoading(false)
			if (response?.status === 200) {
				notification('success', response?.message)

				if (token) {
					navigate(`/onboarding/step-three?token=${token}`)
				} else if ($continue) {
					navigate(`/onboarding/step-three?continue=${$continue}`)
				}
			}
		})
	}

	const onResend = () => {
		dispatch(store(apiEndpoints.userOnBoard.email.sendOTP, { email })).then((response) => {
			setIsLoading(false)
			if (response?.status === 200) {
				showSuccessToast(response?.message)
			}
		})
	}

	return (
		<Fragment>
			<Container className='onboarding-container overflow-hidden' fluid>
				<Row>
					<Col sm={7}>
						<div className='flex-column flex min-h-screen space-y-14'>
							<div className='flex items-center justify-between px-4'>
								<img
									src={logo}
									alt='Experivise'
									className='img-fluid onboarding-logo1'
									style={{ height: 90 }}
								/>
							</div>

							<StepForm
								history={history}
								otpState={[otp, setOtp]}
								isLoading={isLoading}
								onSubmit={onSubmit}
								onResend={onResend}
							/>
						</div>
					</Col>
					<Col
						sm={5}
						style={{ backgroundImage: 'url(' + bg1 + ')' }}
						className='bg-cover'>
						<div className='px-11 py-28'>
							<h2 className='text-[27px] font-bold'>
								Filling forms is more than easy now
							</h2>
							<p className='text-[#74788D]'>
								Filling forms is more than easy now, only fill fields that are
								relevant for you. Advisor will assign those workflows to you and you
								can fill them at your own pace through experivise.
							</p>
						</div>
					</Col>
				</Row>
			</Container>
		</Fragment>
	)
}

export default StepTwo
