import { Link } from 'react-router-dom'

const WorkflowSignatureStatus = ({ message }) => {
	return (
		<div className='prose mx-auto my-10 flex max-w-none flex-col text-center'>
			<i className='uil-clock-three text-8xl text-yellow-500' />

			{message ? <h3>{message}</h3> : <h3>Please wait for your turn to proceed.</h3>}
			<p className='text-sm text-gray-500'>
				We kindly request that you refrain from proceeding further until all other
				individuals involved have successfully affixed their asked information within the
				workflow. This will ensure that the process remains organized and that
				everyone&apos;s input is accounted for before moving forward.
			</p>

			<Link
				to='/assigned-workflows'
				className='btn btn-primary btn-sm mx-auto max-w-fit text-sm'>
				Go back to the Workflows
			</Link>
		</div>
	)
}

export default WorkflowSignatureStatus
