import { useReactTable } from '@tanstack/react-table'
import { Fragment, useEffect } from 'react'

import { DataTablePagination, useSetDataTable } from 'theme/ui/data-table'
import { WorkflowOutstandingsSkeleton } from 'theme/ui/skeletons'
import TableRow from './table-row'

const TrashTable = ({ meta, isLoading, pagination, data, setPagination }) => {
	const table = useReactTable({
		data: data,
		manualPagination: true,
		pageCount: meta?.totalPages ?? -1,
		state: { pagination },
		onPaginationChange: setPagination,
	})
	const setTable = useSetDataTable()

	useEffect(() => {
		setTable(table)
		return () => {
			setTable(null)
		}
	}, [table])

	return (
		<div>
			<table className='react-table-secondary'>
				<thead>
					<tr className='cursor-pointer border-b border-white uppercase'>
						<th className='w-[250px] p-3'>Title</th>
						<th className='w-[170px] p-3'>No Of Files</th>
						<th className='w-[130px] p-3'>Last Edit</th>
						<th className='w-[150px] p-3 text-right'>Actions</th>
					</tr>
				</thead>
			</table>

			<div className='flex w-full flex-col'>
				{isLoading ? (
					<WorkflowOutstandingsSkeleton pageSize={table.getState().pagination.pageSize} />
				) : (
					<Fragment>
						{data.length > 0 ? (
							data.map((folder, idx) => <TableRow folder={folder} key={idx} />)
						) : (
							<p className='text-center'>No Starred file found</p>
						)}
					</Fragment>
				)}
			</div>
			{pagination && meta ? <DataTablePagination table={table} meta={meta} /> : undefined}
		</div>
	)
}

export default TrashTable
