import { Dialog, Transition } from '@headlessui/react'
import { Field, Form, FormikProvider, useFormik } from 'formik'
import {
	useCreateDocumentPackageMutation,
	useUpdateDocumentPackageMutation,
} from 'modules/documents/document-package-api'
import { Fragment, useEffect, useState } from 'react'
import notification from 'utilities/notification'

const CreateFolder = ({ isOpen, onClose, type, data }) => {
	const [isSubmitting, setSubmitting] = useState(false)

	const [createDocumentPackage] = useCreateDocumentPackageMutation()
	const [updateDocumentPackage] = useUpdateDocumentPackageMutation()

	const formik = useFormik({
		initialValues: {
			name: data?.title || '',
			search_type: {
				icon: 'uil-file-blank',
				label: 'All',
				value: 'all',
			},
		},
		onSubmit: async (values) => {
			if (type !== 'folder') return

			setSubmitting(true)
			try {
				const payload = { title: values.name }
				const response = data?.id
					? await updateDocumentPackage({ payload, id: data.id })
					: await createDocumentPackage(payload)

				if (response?.data?.status === 200) {
					notification('success', response?.data?.message)
					onClose()
				}
				setSubmitting(false)
			} catch (error) {
				notification('error', 'An error occurred while processing your request.')
			} finally {
				setSubmitting(false)
			}
		},
	})

	useEffect(() => {
		if (data?.title) {
			formik.setFieldValue('name', data.title)
		}
	}, [data?.title])

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-[1008]' onClose={onClose}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/30' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'>
							<Dialog.Panel className='w-full max-w-lg transform overflow-hidden rounded-3xl bg-white p-2 text-left align-middle shadow-xl transition-all'>
								<div className='top-o relative flex justify-between rounded-3xl bg-blue-50 p-3'>
									<div></div>
									<div className='absolute left-6 top-8 rounded-full !border !border-gray-300 bg-white p-2'>
										<i className='uil-folder-plus p-2 text-[20px] font-bold'></i>
									</div>
									<button type='button' className='right-0' onClick={onClose}>
										<i className='fas fa-times rounded-full bg-white px-2 py-1 text-[20px]' />
									</button>
								</div>
								<div className='mt-10 flex flex-col '>
									<div className='ms-4 flex flex-col justify-start ps-2'>
										<Dialog.Title
											as='h2'
											className='mb-3 text-left text-lg font-bold leading-6 text-gray-900'>
											{data?.id ? 'Update' : 'Create new'} {type}
										</Dialog.Title>
										<FormikProvider value={formik}>
											<Form className='flex-1 !pe-6'>
												<div className='!pe-6'>
													<Field
														type='text'
														name='name'
														className='form-control !py-3'
														placeholder='Enter name...'
													/>
												</div>
											</Form>
										</FormikProvider>
									</div>
									<hr className='mx-4 my-3 bg-gray-300' />
									<div className='mb-3 ms-4 flex w-full flex-col gap-2 pe-5 ps-2'>
										<button
											type='button'
											className={`rounded-md !border  ${
												formik.values.name.length < 3 || isSubmitting
													? 'bg-gray-300 '
													: 'border-main bg-main'
											} p-2 font-medium text-white`}
											disabled={formik.values.name.length < 3 || isSubmitting}
											onClick={formik.handleSubmit}>
											{isSubmitting
												? 'Submitting..'
												: data?.id
												? 'Update'
												: 'Create'}
										</button>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export default CreateFolder
