// This component provies you phone number input with country flag

import 'react-phone-number-input/style.css'

import { Fragment, useEffect, useMemo, useState } from 'react'
import ReactNumberInput, {
	getCountries,
	getCountryCallingCode,
} from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en'

import PropTypes from 'prop-types'

import { FormGroup, InputGroup, Label } from 'reactstrap'

import internationalNumber from 'assets/svg/internationalNumber.svg'
import { parsePhoneNumber } from 'react-phone-number-input'

const InputCountrySelect = ({
	meta: { touched, invalid, error },
	label,
	input,
	infoText,
	...rest
}) => {
	let invalidColor = '#f46a6a'
	let validColor = '#CED4DA'

	const phoneNumber = useMemo(() => parsePhoneNumber(input?.value), [input?.value])
	const [number, setNumber] = useState(input?.value)

	const isInvalid = touched && Boolean(invalid)
	const height =
		rest && !['checkbox', 'radio', 'textarea'].includes(rest.type)
			? {
					height: '38px',
					border: isInvalid ? `1px solid ${invalidColor}` : `1px solid ${validColor}`,
			  }
			: {}

	const [country, setCountry] = useState(phoneNumber?.country || 'CA')
	const [flagURL, setFlagURL] = useState(getFlagURLfromCode(country))

	useEffect(() => {
		setFlagURL(getFlagURLfromCode(country))
	}, [country])

	return (
		<Fragment>
			<FormGroup>
				{label && (
					<Fragment>
						<Label className='text-left'>{label}</Label>
						{/* Only for Testing🧪 Remove it later👇🏻 */}
						{/* <Label className="text-left float-right">{input.value}</Label> */}
					</Fragment>
				)}

				<InputGroup className='flex rounded  px-2.5 ' style={height}>
					<div className=' flex items-center justify-center'>
						<CountrySelectFlag
							labels={en}
							value={country}
							flagURL={flagURL}
							onChange={setCountry}
						/>
					</div>
					<div
						style={{
							borderLeft: isInvalid
								? `1px solid ${invalidColor}`
								: `1px solid ${validColor}`,
						}}
						className='flex flex-1 items-center justify-center'>
						<ReactNumberInput
							// {...input} // ⚠️ keep this commented
							{...rest}
							country={country}
							placeholder={rest.placeholder}
							className={`w-full px-2`}
							name={input.name}
							value={number}
							international
							onChange={(value) => {
								setNumber(value)
								input.onChange(value)
							}}
						/>
					</div>
				</InputGroup>

				{infoText && (
					<i className='text-muted' style={{ fontSize: 12 }}>
						{infoText}
					</i>
				)}

				{touched && error && <div className='block text-[#f46a6a]'>{touched && error}</div>}
			</FormGroup>
		</Fragment>
	)
}

InputCountrySelect.propTypes = {
	input: PropTypes.object.isRequired,
	meta: PropTypes.object,
}

InputCountrySelect.defaultProps = {
	meta: null,
}

// 🚩flag picker
const CountrySelectFlag = ({ value, onChange, labels, flagURL, ...rest }) => {
	return (
		<div>
			<div className='PhoneInputCountry'>
				<select
					{...rest}
					value={value}
					onChange={(event) => onChange(event.target.value || undefined)}
					className='PhoneInputCountrySelect'>
					<option value=''>{labels['ZZ']}</option>
					{getCountries().map((country) => (
						<option key={country} value={country}>
							{labels[country]} +{getCountryCallingCode(country)}
						</option>
					))}
				</select>

				<div className='PhoneInputCountryIcon me-2'>
					<img
						className='PhoneInputCountryIconImg rounded-sm'
						alt='Azerbaijan'
						src={flagURL}
					/>
				</div>

				<div className='PhoneInputCountryCodeInDegit'>
					{value ? '+' + getCountryCallingCode(value) : null}
				</div>

				<div className='PhoneInputCountrySelectArrow me-1'></div>
			</div>
		</div>
	)
}

export default InputCountrySelect

function getFlagURLfromCode(countryShortName) {
	if (!countryShortName) {
		return internationalNumber
	}

	return `http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryShortName}.svg` // https://www.npmjs.com/package/country-flag-icons
}
