import { createApi } from '@reduxjs/toolkit/query/react'
import apiEndpoints from 'helpers/apiEndpoints'
import { capitalize } from 'lodash'

import axiosBaseQuery from 'store/axios-base-query'
import { formatDate } from 'utilities/helpers'

const API_URL = apiEndpoints.subscriptions
const PENDING_SUB_API_URL = apiEndpoints.userSubscriptions

export const subscriptionApi = createApi({
	reducerPath: 'Subscriptions',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['Subscription', 'PendingSubscription'],
	endpoints: (builder) => ({
		getSubscriptions: builder.query({
			keepUnusedDataFor: 0,
			query: (params) => ({
				url: API_URL,
				method: 'GET',
				params: params,
			}),
			providesTags: ['Subscription'],
			transformResponse: (response, _, arg) => {
				const subscriptions = Array.isArray(response?.data?.subscriptions)
					? response?.data?.subscriptions.map((subscription, idx) => ({
							...subscription,
							index: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + idx + 1,
							id: subscription?.id,
							plan_type: capitalize(subscription?.plan_type),
							created_at: formatDate(subscription?.created_at),
					  }))
					: []

				return {
					subscriptions,
					meta: response?.data?.meta,
				}
			},
		}),
		getSubscription: builder.query({
			keepUnusedDataFor: 0,
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'GET',
			}),
			providesTags: ['Subscription'],
		}),
		createSubscription: builder.mutation({
			query: (payload) => ({
				url: API_URL,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['Subscription'],
		}),
		updateSubscription: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${API_URL}/${id}`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['Subscription'],
		}),
		deleteSubscription: builder.mutation({
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Subscription'],
		}),
		getPendingSubscription: builder.query({
			keepUnusedDataFor: 0,
			query: (params) => ({
				url: PENDING_SUB_API_URL,
				method: 'GET',
				params: params,
			}),
			providesTags: ['PendingSubscription'],
			transformResponse: (response, _, arg) => {
				const pendingSubscriptions = Array.isArray(response?.data?.userHasSubscription)
					? response?.data?.userHasSubscription.map((subscription, idx) => ({
							...subscription,
							created_at: formatDate(subscription?.created_at),
							index: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + idx + 1,
					  }))
					: []
				return {
					pendingSubscriptions,
					meta: response?.data?.meta,
				}
			},
		}),
		toggleStatus: builder.mutation({
			query: ({ payload, id }) => ({
				url: `/teams/${id}/toggle-status`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['PendingSubscription'],
		}),
	}),
})

export const {
	useLazyGetSubscriptionsQuery,
	useLazyGetSubscriptionQuery,
	useCreateSubscriptionMutation,
	useUpdateSubscriptionMutation,
	useDeleteSubscriptionMutation,
	useLazyGetPendingSubscriptionQuery,
	useToggleStatusMutation,
} = subscriptionApi
