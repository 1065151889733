import TeamDropdown from 'components/Common/TeamDropdown'
import { useHistory } from 'react-router-dom'
import { Button } from 'theme/ui/forms'
import experiviseLightLogo from '../../../assets/images/experivise-logo-dark.png'

const NoSubscriptionHeader = () => {
	const history = useHistory()

	return (
		<div className='fixed left-0 right-0 top-0 mb-3 flex flex-row items-center justify-between !border-b  bg-white p-2 px-3'>
			<div>
				<span className='flex items-center '>
					<img src={experiviseLightLogo} alt='' style={{ height: 40 }} className='mt-1' />
					<span className='d-sm-block d-none'>
						<span className='mx-2 text-lg font-semibold text-gray-500'>Experivise</span>
					</span>
				</span>
			</div>
			<div className='flex items-center'>
				<div className='mx-3 rounded !border '>
					<TeamDropdown disableTeamPage={true} />
				</div>
				<div className=''>
					<Button
						className='flex items-center rounded !border !border-gray-500 !bg-gray-500 px-3 py-2 text-white '
						size='sm'
						onClick={() => history.push('/logout')}>
						<i className='uil uil-sign-out-alt font-size-18 me-1 items-center '></i>
						Logout
					</Button>
				</div>
			</div>
		</div>
	)
}

export default NoSubscriptionHeader
