import { useState } from 'react'

import { Field } from 'formik'
import { CreatableSelectField } from 'theme/ui/forms'
import TagOptionsQuery from './TagOptionsQuery'

const TagDropdown = ({ formValues }) => {
	const [search, setSearch] = useState('')
	const { options, isLoading } = TagOptionsQuery(search)
	const onCreateOption = (value) => {
		return value
	}
	return (
		<Field
			label='Tags'
			type='select'
			name='tags'
			options={options}
			isMulti
			isLoading={isLoading}
			onInputChange={(value) => setSearch(value)}
			component={CreatableSelectField}
			formValues={formValues}
			onCreateOption={onCreateOption}
		/>
	)
}

export default TagDropdown
