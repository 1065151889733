import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const useUser = () => {
	const { user } = useSelector((state) => state.jwtauth)
	const { isSuperAdmin, defaultGroup, groups } = useSelector((state) => state.Permissions)
	const isTeamDisabled = defaultGroup?.team?.status === 0
	const teams = useMemo(
		() => (Array.isArray(groups) ? groups.map((group) => group?.team) : []),
		[groups],
	)

	const team = defaultGroup?.team
	const group = defaultGroup?.group
	const permissions = useMemo(
		() =>
			Array.isArray(group?.accesses) ? group?.accesses.map((access) => access?.module) : [],
		[group],
	)

	return {
		team: team,
		user: user,
		group: group,
		teams: teams,
		groups: groups,
		permissions: permissions,
		isSuperAdmin: isSuperAdmin === 1,
		isTeamDisabled: isTeamDisabled,
	}
}

export default useUser
