import { ClientOnboardingSlices } from './slices'

const { actions } = ClientOnboardingSlices

export const saveEmail = (payload) => (dispatch) => {
	return new Promise((resolve) => {
		dispatch(actions.saveEmail(payload))
		resolve(true)
	})
}

export const savePassword = (payload) => (dispatch) => {
	return new Promise((resolve) => {
		dispatch(actions.savePassword(payload))
		resolve(true)
	})
}

export const savePhoneNumber = (payload) => (dispatch) => {
	return new Promise((resolve) => {
		dispatch(actions.savePhoneNumber(payload))
		resolve(true)
	})
}

export const clear = () => (dispatch) => {
	return new Promise((resolve) => {
		dispatch(actions.clear())
		resolve(true)
	})
}
