import { Fragment, useRef } from "react";

import nextStep from "assets/svg/figma/nextStep.svg";
import workFlow1 from "assets/svg/figma/workFlow1.svg";
import workFlow2 from "assets/svg/figma/workFlow2.svg";
import workFlow3 from "assets/svg/figma/workFlow3.svg";
import LeftSidebarBottomImage from "../components/LeftSidebarBottomImage";


const WorkFlowMap = () => {

    const workFlowMapRef = useRef(null);

    let stepsDom = workFlowMapRef.current?.querySelectorAll('.nextStepImg');
    let lastStep = stepsDom && stepsDom[stepsDom?.length - 1];
    lastStep?.classList?.add("hidden");

    let workflowsList = [
        {
            id: 1,
            imgUrl: workFlow1,
            title: "Advisor will assign you fields that are relevant to you",
        },
        {
            id: 2,
            imgUrl: workFlow2,
            title: "Fill out the fields and upload documents",
        },
        {
            id: 3,
            imgUrl: workFlow3,
            title: "Advisor will review the data submitted by you",
        }
    ]


    return (
        <div className="flex flex-col justify-between h-full">
            <div className="px-3" ref={workFlowMapRef} >
                <h5 className="!mb-9  text-center font-bold text-xl ">
                    How does Experivise works?
                </h5>

                {workflowsList.map(({ title, imgUrl, id }) => {
                    return (
                        <Flow
                            key={id}
                            id={id}
                            imgUrl={imgUrl}
                            title={title}
                        />
                    )
                })}
            </div>

            <LeftSidebarBottomImage />
        </div>
    );
};

export default WorkFlowMap;

const Flow = ({ id, imgUrl, title }) => {

    let isLeft = id % 2 === 0;

    return (
        <Fragment>
            <div className="flex items-center px-4 ">
                <img src={imgUrl} className="rounded-full me-2" alt={"title"} />
                <span className="ms-2 font-medium text-slate-600 ">
                    {title}
                </span>
            </div>
            <div className="flex items-center px-4 ">
                <div className="w-14 my-1">
                    <img
                        src={nextStep}
                        alt="title"
                        className={` nextStepImg ${isLeft ? "-scale-x-100 float-right" : null}`}
                    />
                </div>
            </div>
        </Fragment>
    );
};
