import { useEffect } from 'react'
import Swal from 'sweetalert2'

import LoadingIcon from 'assets/gif/loading-icon.gif'

const LoadingPopup = ({ isVisible, timer }) => {
	useEffect(() => {
		if (isVisible) {
			Swal.fire({
				timer: timer,
				showCancelButton: false,
				showConfirmButton: false,
				html:
					'<div class="text-center flex flex-col items-center !space-y-4 mb-3"><img src="' +
					LoadingIcon +
					'" class="!h-[120px] !w-[120px]" /><h3 class="font-semibold">Hold on!</h3><p class="text-gray-500 !text-lg !mb-0">We are verifying your information through IDs given by you.</p></div>',
				customClass: {
					container: 'bg-green-200',
					popup: '!max-w-md !rounded-lg',
					footer: '!bg-[#5B73E8] !-mb-5 !rounded-b-lg !h-2',
				},
				footer: `<div></div>`,
			})
		}
	}, [isVisible])

	return <></>
}

export default LoadingPopup
