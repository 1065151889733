import { countries } from 'countries-list'
import { hasFlag } from 'country-flag-icons'
import Flags from 'country-flag-icons/react/3x2'
import { Fragment } from 'react'

const useCountryOptions = () => {
	const options = Object.entries(countries)
		.map((code) => {
			const exist = hasFlag(code[0])
			if (!exist) {
				return undefined
			}
			const Component = Flags[code[0]]

			return {
				value: code[1].name,
				label: (
					<Fragment>
						<Component
							title={code[0]}
							style={{ display: 'inline-block', height: '1em', width: '1em' }}
						/>
						&nbsp;
						<span>{`${code[1].name}`}</span>
					</Fragment>
				),
			}
		})
		.filter(Boolean)
	return options
}

export default useCountryOptions
