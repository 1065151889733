import { Fragment, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";

import successImg from "assets/gif/success-icon.gif";

const ThankYouPage = () => {

    useEffect(() => {
        document.body.className = "authentication-bg"
        return function cleanup() {
            document.body.className = ""
        };
    });

    return (
        <Fragment>
            <div className="my-5 pt-sm-5">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="text-center">
                                <div>
                                    <Row className="row justify-content-center">
                                        <Col sm={4}>
                                            <div className="error-img">
                                                <img src={successImg} alt="" className="img-fluid mx-auto d-block" />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <h4 className="text-uppercase mt-4">
                                    Thanks for sharing your selfie.
                                </h4>
                                {/* <p className="text-muted">
                                    It will be as simple as Occidental in fact, it will be Occidental
                                </p> */}
                                {/* <div className="mt-5">
                                    <Link className="btn btn-primary waves-effect waves-light" to="/">Back to Dashboard</Link>
                                </div> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment>
    )
}

export default ThankYouPage