const Header = () => {
	return (
		<div className='flex w-full flex-col'>
			<div className='mb-3 flex w-full justify-between'>
				<div className='max-w-[733px]'>
					<h4 className='page-title'>Roles</h4>
					<p className='text-xs text-[#74788D]'>
						Effortlessly manage roles within your organization from a single,
						user-friendly dashboard. Take full control of role assignments, monitor
						permissions, and streamline updates with just a few clicks. All your role
						management needs are centralized in one convenient hub for maximum
						efficiency and convenience
					</p>
				</div>
			</div>
			<div className='-mb-[12px] space-x-2'></div>
		</div>
	)
}

export default Header
