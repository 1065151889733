import { getPageNumbers } from 'components/Common/data-table'
import { useMemo } from 'react'

const Header = ({ meta, table }) => {
	const { startIndex, endIndex } = useMemo(() => getPageNumbers(meta), [meta, table])
	return (
		<div className='mb-3 flex items-center justify-between '>
			<h4 className='text-[15px] font-semibold'>Pending Invites ({meta?.totalRecords})</h4>
			<span className='flex items-center gap-1 text-sm'>
				Showing {startIndex} - {endIndex} of {meta?.totalRecords}
			</span>
		</div>
	)
}

export default Header
