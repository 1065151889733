import { createApi } from '@reduxjs/toolkit/query/react'

import apiEndpoints from 'helpers/apiEndpoints'
import axiosBaseQuery from 'store/axios-base-query'
import { USERS_APP_ROUTES } from './routes'
const API_URL = apiEndpoints.users

export const usersApi = createApi({
	reducerPath: 'Users',
	refetchOnFocus: true,
	keepUnusedDataFor: 0,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['Users'],
	endpoints: (builder) => ({
		getUsers: builder.query({
			keepUnusedDataFor: 0,
			query: (params) => ({
				url: API_URL,
				method: 'GET',
				params: params,
			}),
			providesTags: ['Users'],
			transformResponse: (response, _, arg) => {
				const usersData = Array.isArray(response.data?.users)
					? response.data?.users.map((user, idx) => ({
							ind: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + idx + 1,
							id: user.id,
							name: user.first_name,
							email: user.email,
							status: user.status ? 'Active' : 'Inactive',
							hasTeam: user.hasTeam,
					  }))
					: []
				return {
					usersData,
					meta: response?.data?.meta,
				}
			},
		}),
		removeUser: builder.mutation({
			query: (params) => ({
				url: USERS_APP_ROUTES.delete(params?.userId),
				method: 'DELETE',
			}),
			invalidatesTags: ['Users'],
		}),
	}),
})

export const { useLazyGetUsersQuery, useRemoveUserMutation } = usersApi
