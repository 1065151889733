import StyledBadge from 'components/Common/StyledBadge'

export default function Status({ status }) {
	switch (status) {
		case 0:
			return <StyledBadge color='warning'>Draft</StyledBadge>

		case 1:
			return <StyledBadge color='success'>Published</StyledBadge>

		case 2:
			return <StyledBadge color='info'>Prepared</StyledBadge>

		default:
			return <StyledBadge color='warning'>Draft</StyledBadge>
	}
}
