import { useEffect } from 'react'

import { useAppRouter } from 'hooks'
import ClientDetailsCard from 'modules/clients/client-details'
import { useLazyGetClientDetailsQuery } from 'modules/clients/invited-client-api'
import { NotFound } from 'modules/error-sections'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'
import Spinner from 'theme/ui/spinner'

const ClientInviteEdit = () => {
	const [fetchClient, { data, isLoading, isUninitialized }] = useLazyGetClientDetailsQuery()
	const { params } = useAppRouter()
	const id = params?.id

	useEffect(() => {
		if (id) {
			fetchClient(id)
		}
	}, [id])

	return (
		<AppLayout
			meta={{
				title: 'Edit Invited Client',
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<PageHeader
						title='Edit Invited Client'
						description='Effortlessly streamline your client onboarding process with our intuitive invitation form page. Seamlessly invite clients and collect essential information in one smooth flow.'
						hasBackButton={true}
					/>
					<div className='page-body'>
						{isUninitialized || isLoading ? (
							<Spinner />
						) : data?.client ? (
							<ClientDetailsCard
								isOnBoarded={false}
								isEditMode={true}
								client={data?.client}
							/>
						) : (
							<NotFound />
						)}
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default ClientInviteEdit
