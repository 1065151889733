import { Disclosure } from '@headlessui/react'
import { useReactTable } from '@tanstack/react-table'
import classNames from 'classnames'

import { getDocumentOptions } from 'pages/Documents/utils/utils'
import { Fragment, useEffect, useState } from 'react'
import { DataTablePagination, useSetDataTable } from 'theme/ui/data-table'
import { DocumentPackagesSkeleton } from 'theme/ui/skeletons'
import { useLazyGetDocumentPackageDetailsQuery } from '../document-package-api'
import Heading from './Heading'
import DocumentPackageModal from './document-package-modal'
import PackageFormModal from './package-form-modal'
import PackageTable from './package-table/package-table'
import './package-table/package-table.css'

const DocumentPackageTable = ({ data, meta, isLoading, pagination, setPagination }) => {
	const [state, setState] = useState({
		openModal: false,
		addModal: false,
		id: undefined,
	})
	const [initialValues, setInitialValues] = useState()

	const closeModal = () => {
		setState({ openModal: false, addModal: false, id: undefined })
	}

	const table = useReactTable({
		data: data,
		manualPagination: true,
		pageCount: meta?.totalPages ?? -1,
		state: { pagination },
		onPaginationChange: setPagination,
	})
	const setTable = useSetDataTable()
	useEffect(() => {
		if (table) {
			setTable(table)
		}
		return () => {
			setTable(null)
		}
	}, [table])

	const handleClickEditPackage = async (id) => {
		const response = await getDocumentPackageDetails(id)
		if (response?.data?.status === 200) {
			setState({
				...state,
				openModal: true,
				id,
			})
		}
	}

	const handleClickAddDocument = async (id) => {
		const response = await getDocumentPackageDetails(id)
		if (response?.data?.status === 200) {
			setState({
				...state,
				addModal: true,
				id,
			})
		}
	}

	const [getDocumentPackageDetails, { data: editData }] = useLazyGetDocumentPackageDetailsQuery()

	useEffect(() => {
		if (editData) {
			const data = editData?.data
			setInitialValues({
				title: data?.title,
				documents: getDocumentOptions(data?.documents),
			})
		}
	}, [editData])

	return (
		<div>
			<PackageFormModal
				open={state?.openModal}
				onClose={closeModal}
				id={state?.id}
				initialValues={initialValues}
			/>
			<DocumentPackageModal
				open={state?.addModal}
				onClose={closeModal}
				id={state?.id}
				initialValues={initialValues}
			/>
			<table className='document-package mb-4'>
				<thead>
					<tr className='h-[45px] cursor-pointer border-b border-white bg-white px-3 '>
						<th className='w-[5%]'>#.</th>
						<th className='w-[200px] sm:w-[30%]'>Title</th>
						<th className='w-[150px] sm:w-[25%]'>Files</th>
						<th className='w-[150px] sm:w-[20%]'>Action</th>
					</tr>
				</thead>
			</table>

			<div className='flex w-full flex-col'>
				{isLoading ? (
					<DocumentPackagesSkeleton pageSize={table.getState().pagination.pageSize} />
				) : (
					<>
						{data?.length > 0 ? (
							data?.map((document, idx) => {
								const documents = document?.documents || []

								return (
									<div key={idx}>
										<Disclosure as={'div'} className='mb-2'>
											{({ open }) => (
												<Fragment>
													<Disclosure.Button
														as='button'
														className={classNames(
															'flex w-full justify-between rounded  border-b bg-white px-3 py-3',
															{
																'shadow-[0px_2px_4px_0px_rgba(15,34,58,0.12)]':
																	open,
															},
														)}>
														<Heading
															open={open}
															totalDocuments={documents?.length}
															document={document}
															handleClickEditPackage={
																handleClickEditPackage
															}
															handleClickAddDocument={
																handleClickAddDocument
															}
														/>
													</Disclosure.Button>
													<Disclosure.Panel>
														<PackageTable
															packageDocument={document}
															documents={documents}
														/>
													</Disclosure.Panel>
												</Fragment>
											)}
										</Disclosure>
									</div>
								)
							})
						) : (
							<p className='text-center'>No Workflow founds to review</p>
						)}
					</>
				)}
			</div>
			{pagination && meta ? <DataTablePagination table={table} meta={meta} /> : undefined}
		</div>
	)
}

export default DocumentPackageTable
