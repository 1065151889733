import { Disclosure } from '@headlessui/react'
import classNames from 'classnames'
import { useEffect, useMemo } from 'react'

import { ReactComponent as CommentIcon } from 'assets/svg/comment-icon.svg'
import { getWorkflowCardBg } from 'modules/assigned-workflows/assigned-workflows-listing/workflow-card'
import CommentCard from 'modules/assigned-workflows/comment-card'
import { useLazyGetWorkflowCommentsQuery } from 'modules/workflows/workflow-comment-section/workflow-comment-api'

const CommentSection = ({ workflowId, stepId }) => {
	const { backgroundColor, colorName } = useMemo(() => getWorkflowCardBg('assigned'), [])
	const [fetchComments, { data }] = useLazyGetWorkflowCommentsQuery()

	useEffect(() => {
		if (workflowId && stepId) {
			fetchComments({
				page: 1,
				limit: 10,
				workflow: workflowId,
				workflow_step: stepId,
			})
		}
	}, [workflowId, stepId])

	const comments = useMemo(() => data?.comments || [], [data?.comments])

	return (
		<div className='max-h-[65vh] overflow-auto rounded-md !border !p-3 !px-5'>
			<Disclosure>
				{({ open }) => (
					<>
						<Disclosure.Button
							className={classNames(
								'flex w-full justify-between py-2 text-left text-sm font-medium focus:outline-none focus-visible:ring',
								{ 'border-b': open },
							)}>
							<div className='flex items-center space-x-2'>
								<span>
									<CommentIcon />
								</span>
								<span>{comments?.length?.toString()} Comments</span>
							</div>

							{open ? (
								<div className='flex items-center space-x-1 text-sm font-medium text-main'>
									<span>Hide</span>
									<span className='uil-angle-up text-base'></span>
								</div>
							) : (
								<div className='flex items-center space-x-1 text-sm font-medium text-main'>
									<span>View</span>
									<span className='uil-angle-down text-base'></span>
								</div>
							)}
						</Disclosure.Button>
						<Disclosure.Panel className='pb-2 pt-3 text-sm text-gray-500'>
							<div className='flex flex-col space-y-3'>
								{comments.map((comment, i) => (
									<CommentCard
										variant='vertical'
										key={i}
										advisor={comment?.user}
										comment={comment}
										backgroundColor={backgroundColor}
										colorName={colorName}
										isReplyShow={true}
									/>
								))}
							</div>
						</Disclosure.Panel>
					</>
				)}
			</Disclosure>
		</div>
	)
}

export default CommentSection
