import { createApi } from '@reduxjs/toolkit/query/react'

import apiEndpoints from 'helpers/apiEndpoints'
import axiosBaseQuery from 'store/axios-base-query'

const API_URL = apiEndpoints.systemSettings

export const systemSettingApi = createApi({
	reducerPath: 'SystemSettings',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['SystemSetting'],
	endpoints: (builder) => ({
		getSystemSettings: builder.query({
			query: () => ({
				url: API_URL,
				method: 'GET',
			}),
			providesTags: ['SystemSetting'],
		}),
		getOneSystemSetting: builder.query({
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'GET',
			}),
			providesTags: ['SystemSetting'],
		}),
		createSystemSetting: builder.mutation({
			query: (payload) => ({
				url: API_URL,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['SystemSetting'],
		}),
		updateSystemSetting: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${API_URL}/${id}`,
				method: 'PATCH',
				data: payload,
			}),
		}),
	}),
})

export const {
	useLazyGetSystemSettingsQuery,
	useLazyGetOneSystemSettingQuery,
	useCreateSystemSettingMutation,
	useUpdateSystemSettingMutation,
} = systemSettingApi
