import { SpinLoader } from 'components/Common'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Row } from 'reactstrap'
import AppLayout from 'theme/layouts/app-layout'
import ButtonLink from '../../components/Common/ButtonLink'
import apiEndpoints from '../../helpers/apiEndpoints'
import * as actions from '../../store/common/actions'
import UnAuthorize from '../StaticPages/UnAuthorize'

const UserDetails = (props) => {
	const { id } = props.match.params
	const dispatch = useDispatch()
	const [user, setUser] = useState(null)
	const { loading } = useSelector((state) => state.common)
	const isSuperAdmin = useSelector((state) => state.Permissions.isSuperAdmin)

	useEffect(() => {
		if (id && isSuperAdmin) {
			dispatch(actions.find(apiEndpoints.users, id)).then((response) => {
				if (response && response.status === 200) {
					setUser(response.data)
				}
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	const groups = user && user.groups ? user.groups : []

	return (
		<AppLayout
			meta={{
				title: 'User Details',
			}}>
			{isSuperAdmin ? (
				<div className='page-content'>
					<Container fluid>
						<Card>
							<CardHeader>
								<CardTitle>User Details</CardTitle>
								<ButtonLink to='/users' color='primary'>
									Back
								</ButtonLink>
							</CardHeader>
							<CardBody>
								{loading ? (
									<SpinLoader />
								) : (
									<>
										<Row className='mb-3'>
											<Col sm={3} md={3} xs={12}>
												<strong>Name</strong>
												<br />
												{user && user.first_name && user.last_name
													? `${user.first_name} ${user.last_name}`
													: '-'}
											</Col>
											<Col sm={3} md={3} xs={12}>
												<strong>Email</strong>
												<br />
												{user && user.email ? user.email : '-'}
											</Col>
											<Col sm={3} md={3} xs={12}>
												<strong>Phone Number</strong>
												<br />
												{user && user.phone_number
													? user.phone_number
													: '-'}
											</Col>
											<Col sm={3} md={3} xs={12}>
												<strong>Status</strong>
												<br />
												{user && user.status === 1 ? 'Active' : 'Inactive'}
											</Col>
										</Row>
										<Row>
											<Col sm={12} md={12} xs={12}>
												<strong>Team Details</strong>
												<br />
												<table className='table-1 table-bordered mt-2'>
													<thead>
														<tr>
															<th>#.</th>
															<th>Team</th>
															<th>Role</th>
														</tr>
													</thead>
													<tbody>
														{groups.map((group, index) => (
															<Fragment key={index}>
																<tr>
																	<td>{++index}</td>
																	<td>
																		{group &&
																		group.team &&
																		group.team.team_name
																			? group.team.team_name
																			: '-'}
																	</td>
																	<td>
																		{group &&
																		group.group &&
																		group.group.name
																			? group.group.name
																			: '-'}
																	</td>
																</tr>
															</Fragment>
														))}
													</tbody>
												</table>
											</Col>
										</Row>
									</>
								)}
							</CardBody>
						</Card>
					</Container>
				</div>
			) : (
				<UnAuthorize />
			)}
		</AppLayout>
	)
}

export default UserDetails
