import QRCode from "react-qr-code"
import { Modal } from "reactstrap"

const QrCodeModal = ({ modalState, token }) => {
    const [isOpen, setIsOpen] = modalState

    const removeBodyCss = () => document.body.classList.add("no_padding")
    const toggleModal = () => {
        setIsOpen(!isOpen)
        removeBodyCss()
    }

    const QR_CODE_URL = `${window?.location?.origin}/onboarding/take-selfie-from-phone?token=${token}#take-selfie`
    // console.log('QR_CODE_URL', QR_CODE_URL);
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggleModal}
            scrollable={true}
            id="staticBackdrop"
            backdrop="static"
        >
            <div className="flex justify-end">
                <button type="button" className="text-2xl px-3 py-2" onClick={() => setIsOpen(false)}>
                    <i className="uil uil-times" />
                </button>
            </div>
            <div className="modal-body">
                <div className="flex flex-col space-y-3 items-center max-w-xs mx-auto mb-6">
                    <div className="h-[230px] w-[230px] p-2 !border rounded-md" >
                        <QRCode
                            value={QR_CODE_URL}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            viewBox={`0 0 256 256`}
                        />
                    </div>
                    <p className="font-medium text-base mb-0 text-center">Scan the QR code with your phone to take a selfie with your phone</p>
                    <p className="font-medium text-base mb-0">or</p>

                    {process.env.NODE_ENV === 'development' ? (
                        <p className="max-w-sm">
                            <a href={QR_CODE_URL}>{QR_CODE_URL}</a>
                        </p>
                    ) : undefined}

                    <button type="button" className="btn-primary-2">
                        Send link to 1678-888-8787
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default QrCodeModal