import { getIn } from 'formik'
import PropTypes from 'prop-types'
import { Fragment, useMemo } from 'react'
import DatePicker from 'react-datepicker'
import { Label } from 'reactstrap'
import './react-datepicker.css'
const DatePickerInput = ({
	form,
	field,
	onAutoSave,
	input,
	label,
	placeholder,
	minDate,
	isRequiredField,
	...custom
}) => {
	const { name } = field
	const touched = useMemo(() => getIn(form?.touched, name), [form?.touched])
	const errors = useMemo(() => getIn(form?.errors, name), [form?.errors])
	const isInvalid = touched && errors

	const selectedDate = useMemo(() => {
		const value = form?.values?.[name]
		return value || null
	}, [form?.values, name])
	return (
		<Fragment>
			<div className='flex flex-col space-y-[10px]'>
				{label && (
					<Label className='m-0'>
						{label}
						{isRequiredField ? <span className='text-red-500'>*</span> : undefined}
					</Label>
				)}
				<div className='flex flex-col '>
					<DatePicker
						{...custom}
						selected={selectedDate}
						onChange={(option) => form.setFieldValue(field?.name, option)}
						onBlur={() => {
							if (onAutoSave) {
								onAutoSave()
							}
							return field.onBlur
						}}
						className={`react-datepicker w-100 !border p-2.5 shadow-none ${
							isInvalid ? '!border-red-400' : '!border-gray-300'
						}`}
						minDate={minDate}
						placeholderText={placeholder || 'Select date'}
					/>
				</div>
				{isInvalid ? (
					<>
						{Array.isArray(errors) ? (
							<div className='error mt-[1px]'>
								<ul className='list-disc text-xs'>
									{errors.map((error, idx) => (
										<li key={idx}>{error}</li>
									))}
								</ul>
							</div>
						) : (
							<>
								{errors ? (
									<div className='error mt-[1px] text-[13px]'>{errors}</div>
								) : undefined}
							</>
						)}
					</>
				) : undefined}
			</div>
		</Fragment>
	)
}

DatePickerInput.propTypes = {
	input: PropTypes.object.isRequired,
	meta: PropTypes.object,
	label: PropTypes.string,
	minDate: PropTypes.object,
	variant: PropTypes.oneOf(['default', 'horizontal']),
	width: PropTypes.string,
}

DatePickerInput.defaultProps = {
	meta: null,
	label: '',
	variant: 'default',
	width: 'w-56',
}

export default DatePickerInput
