import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import { isAllowTo } from 'utilities/helpers'
import notification from 'utilities/notification'
import { permissions } from 'utilities/permissions'
import {
	useDeleteDocumentTemplateMutation,
	useLazyDocumentDeleteEligibleQuery,
} from '../document-api'
import DeleteConfirmAlert from './delete-confirm-alert'

const Actions = ({ field }) => {
	const canRead = isAllowTo(permissions?.documentTemplate?.edit)
	const canDelete = isAllowTo(permissions?.documentTemplate?.delete)
	const [isOpen, setIsOpen] = useState({
		open: false,
		id: undefined,
	})

	const onClose = () =>
		setIsOpen({
			open: false,
			id: undefined,
		})

	const [documentDeleteEligible, { isLoading }] = useLazyDocumentDeleteEligibleQuery()
	const [deleteDocumentTemplate, { isLoading: isDeleting }] = useDeleteDocumentTemplateMutation()

	const checkdocumentDeleteEligibility = async (id) => {
		const response = await documentDeleteEligible(id)
		if (response?.data?.status === 200 && response?.data?.data) {
			setIsOpen({
				open: true,
				id,
			})
		}
	}

	const onDelete = async () => {
		const id = isOpen?.id
		const response = await deleteDocumentTemplate(id)
		if (response?.data?.status === 200) {
			notification('success', response?.data?.message)
		}
		onClose()
	}

	return (
		<Fragment>
			<DeleteConfirmAlert
				isOpen={isOpen?.open}
				onClose={onClose}
				isLoading={isDeleting}
				onConfirm={onDelete}
			/>
			<div className='w-[250px]'>
				<div className='flex space-x-2'>
					{canRead && (
						<Link
							to={`/documents/${field.id}/edit`}
							className='action-btn btn-gray-outline'>
							<i className='uil-edit' style={{ fontSize: 16 }} />
							<span>Edit</span>
						</Link>
					)}
					{canDelete && (
						<button
							type='button'
							className='action-btn btn-red-outline'
							onClick={() => checkdocumentDeleteEligibility(field?.id)}>
							{isLoading ? (
								<Fragment>
									<Spinner size='sm' />
									&nbsp;&nbsp;Loading...
								</Fragment>
							) : (
								<Fragment>
									<i className='uil-trash-alt' style={{ fontSize: 16 }} />
									<span>Delete</span>
								</Fragment>
							)}
						</button>
					)}
				</div>
			</div>
		</Fragment>
	)
}

export default Actions
