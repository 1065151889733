import { createColumnHelper } from '@tanstack/react-table'
import { Fragment, useState } from 'react'

import DataTable from 'theme/ui/data-table'
import { truncateMiddle } from 'utilities/helpers'
import { getWorkflowLink } from '../workflow-assign-form'
import Actions from './actions'

const WorkflowsLinksTable = ({ workflows, meta, isLoading, pagination, setPagination }) => {
	const [sorting, setSorting] = useState([])
	const [actionActiveId, setActionActiveId] = useState(null)
	const [hoveredIndex, setHoveredIndex] = useState(null)
	const [copiedIndex, setCopiedIndex] = useState(null)
	const columnHelper = createColumnHelper()
	const onMouseEnter = (id) => setActionActiveId(id)
	const onMouseLeave = () => setActionActiveId(null)

	const handleCopy = (link, index) => {
		navigator.clipboard.writeText(link)

		setCopiedIndex(index)

		setTimeout(() => {
			setCopiedIndex(null)
		}, 2000)
	}

	const columns = [
		columnHelper.accessor('index', {
			id: 'index',
			size: 30,
			header: () => <span>#</span>,
			cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		}),

		columnHelper.accessor((row) => row.fullName, {
			id: 'fullName',
			size: 140,
			header: () => <span>Advisor</span>,
			cell: (info) => (
				<span className='text-sm font-bold !text-gray-700' title={info.getValue()}>
					{info.getValue()}
				</span>
			),
		}),

		columnHelper.accessor((row) => row, {
			id: 'link',
			size: 250,
			header: () => <span>Link</span>,
			cell: (info) => {
				const $link = getWorkflowLink(info.getValue()?.link)
				return (
					<div
						className='flex max-w-[200px] items-center'
						onMouseEnter={() => setHoveredIndex(info.getValue().index)}
						onMouseLeave={() => setHoveredIndex(null)}>
						<span
							key={info.getValue()?.index}
							onClick={() => handleCopy($link, info.getValue()?.index)}
							className='truncate-middle cursor-pointer text-[13px] !text-main hover:underline'
							id='text'>
							{truncateMiddle($link, 30)}
						</span>
						{hoveredIndex === info.getValue()?.index && (
							<i
								className={`uil max-w-fit ${
									copiedIndex === info.getValue()?.index
										? 'uil-check rounded-full !bg-green-600 px-0.5 !text-white'
										: 'uil-link-alt !text-main'
								} mx-2 `}
								onClick={() => handleCopy($link, info.getValue()?.index)}
								style={{ cursor: 'pointer' }}
								title='Copy link'></i>
						)}
					</div>
				)
			},
		}),

		columnHelper.accessor((row) => row.created_by, {
			id: 'created_by',
			size: 100,
			header: () => <span>Reviewer</span>,
			cell: (info) => (
				<span className='text-sm  !text-gray-700' title={info.getValue()?.fullName}>
					{info.getValue()?.fullName}
				</span>
			),
		}),

		columnHelper.accessor((row) => row, {
			id: 'created_at',
			size: 150,
			header: () => <span>Created At</span>,
			cell: (info) => (
				<Actions
					key={info.getValue()?.id}
					id={info.getValue()?.id}
					created_at={info.getValue()?.created_at}
					isActionActive={actionActiveId === info?.row?.id}
				/>
			),
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={workflows || []}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			setSorting={setSorting}
			sorting={sorting}
			meta={meta}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		/>
	)
}

export default WorkflowsLinksTable
