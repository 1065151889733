import { createColumnHelper } from '@tanstack/react-table'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Badge from 'theme/ui/badge'
import DataTable, { IndeterminateCheckbox } from 'theme/ui/data-table'
import PopoverButtons from 'theme/ui/popover'
import StyledBadge from 'theme/ui/styled-badge'
import { statusColor } from '.'
import Actions from './actions'

const InvitedClientsTable = ({
	clients,
	meta,
	isLoading,
	pagination,
	setPagination,
	rowSelection,
	setRowSelection,
}) => {
	const [actionActiveId, setActionActiveId] = useState(null)
	const [sorting, setSorting] = useState([])
	const onMouseEnter = (id) => setActionActiveId(id)
	const onMouseLeave = () => setActionActiveId(null)
	const history = useHistory()

	const applyFilter = (tag) => {
		const encodedTags = encodeURIComponent(tag)
		setPagination((preRec) => ({
			...preRec,
			tags: encodedTags,
		}))
		history.push(`${window.location.pathname}?groups=${encodedTags}`)
	}

	const columnHelper = createColumnHelper()
	const columns = [
		{
			id: 'select',
			size: 20,
			header: ({ table }) => (
				<IndeterminateCheckbox
					{...{
						checked: table.getIsAllRowsSelected(),
						indeterminate: table.getIsSomeRowsSelected(),
						onChange: table.getToggleAllRowsSelectedHandler(),
					}}
				/>
			),
			cell: ({ row }) => (
				<div className='px-1'>
					<IndeterminateCheckbox
						{...{
							checked: row.getIsSelected(),
							disabled: !row.getCanSelect(),
							indeterminate: row.getIsSomeSelected(),
							onChange: row.getToggleSelectedHandler(),
						}}
					/>
				</div>
			),
		},

		columnHelper.accessor((row) => row, {
			id: 'name',
			header: () => <span>Name</span>,
			cell: (info) => {
				return (
					<span className='line-clamp-1 max-w-[180px] text-sm font-bold !text-gray-700'>
						{info.getValue()?.full_name}
					</span>
				)
			},
		}),

		columnHelper.accessor('email', {
			size: 200,
			header: () => <span>Email</span>,
			cell: (info) => <span>{info.getValue()}</span>,
		}),

		columnHelper.accessor((row) => row, {
			id: 'groups',
			size: 150, // Column width
			header: () => (
				<div style={{ minWidth: '100px' }}>
					<span>Groups</span>
				</div>
			),
			cell: (info) => {
				const tagsData = info.getValue()?.tags;
				return (
					<div style={{ minWidth: '100px' }}>
						<span className='max-w-[180px]'>
							{tagsData?.length > 0 && (
								<div className='flex flex-wrap items-center gap-2'>
									<Badge
										variant='primary'
										className={'cursor-pointer'}
										onClick={() => applyFilter(tagsData[0])}>
										{tagsData[0]}
									</Badge>
									{tagsData?.length > 1 ? (
										<PopoverButtons
											data={tagsData}
											title={'Groups'}
											onClick={applyFilter}
										/>
									) : undefined}
								</div>
							)}
						</span>
					</div>
				);
			},
		}),
		
		columnHelper.accessor('status', {
			header: () => <span>Onboard Status</span>,
			size: 150,
			cell: (info) => (
				<div className='inline-block'>
					<StyledBadge color={statusColor[info.getValue()]}>
						{info.getValue()}
					</StyledBadge>
				</div>
			),
		}),

		columnHelper.accessor((row) => row, {
			id: 'created_at',
			size: 200,
			header: () => <span>Created At</span>,
			cell: (info) => (
				<Actions
					id={info.getValue()?.id}
					user={info.getValue()}
					created_at={info.getValue()?.created_at}
				/>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'expired_at',
			size: 200,
			header: () => <span>Expires At</span>,
			cell: (info) => (
				<Actions
					id={info.getValue()?.id}
					user={info.getValue()}
					created_at={info.getValue()?.expired_at}
					isActionActive={actionActiveId === info?.row?.id}
				/>
			),
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={clients}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			setSorting={setSorting}
			sorting={sorting}
			meta={meta}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			setRowSelection={setRowSelection}
			rowSelection={rowSelection}
			isRowSelection={true}
		/>
	)
}

export default InvitedClientsTable
