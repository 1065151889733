import { Fragment, useState } from 'react'
import { Button } from 'theme/ui/forms'
import ToolTip from 'theme/ui/tool-tip'
import OnboardPopup from './onboard-popup/onboard-popup'

const BulkActions = ({ selectedRows }) => {
	const [isOpen, setIsOpen] = useState(false)
	const onOpen = () => setIsOpen(true)
	const onClose = () => setIsOpen(false)

	return (
		<Fragment>
			<OnboardPopup onClose={onClose} isOpen={isOpen} selectedRows={selectedRows} />

			<Button
				type='button'
				id='onboard-now'
				size='sm'
				variant='primary-outline'
				onClick={onOpen}>
				Onboard Now
			</Button>
			<ToolTip
				targetId='onboard-now'
				placement='auto'
				tooltipText='This action will apply only to clients with a status of Invited & Non-Invited.'
			/>
		</Fragment>
	)
}

export default BulkActions
