import Header from 'modules/clients/header'
import InvitedClientsContainer from 'modules/clients/invited-clients'
import AppLayout from 'theme/layouts/app-layout'

const InvitedClientsListing = () => {
	return (
		<AppLayout
			meta={{
				title: 'Invited Clients',
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<div className='page-header bg-white'>
						<Header />
					</div>
					<div className='page-body'>
						<InvitedClientsContainer />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default InvitedClientsListing
