import { createApi } from '@reduxjs/toolkit/query/react'
import apiEndpoints from 'helpers/apiEndpoints'

import axiosBaseQuery from 'store/axios-base-query'

const API_URL = apiEndpoints.comment

export const workflowCommentApi = createApi({
	reducerPath: 'WorkflowComments',
	keepUnusedDataFor: 0,
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['WorkflowComment'],
	endpoints: (builder) => ({
		getWorkflowComments: builder.query({
			keepUnusedDataFor: 0,
			query: (params) => ({
				url: API_URL,
				method: 'GET',
				params: params,
			}),
			providesTags: ['WorkflowComment'],
			transformResponse: (response, _, arg) => {
				const comments = Array.isArray(response?.data?.comments)
					? response?.data?.comments.map((comment, idx) => ({
							...comment,
							index: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + idx + 1,
					  }))
					: []

				return {
					comments: comments,
					meta: response?.data?.meta,
				}
			},
		}),
		getWorkflowComment: builder.query({
			keepUnusedDataFor: 0,
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'GET',
			}),
			providesTags: ['WorkflowComment'],
		}),
		createWorkflowComment: builder.mutation({
			query: (payload) => ({
				url: API_URL,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['WorkflowComment'],
		}),
		updateWorkflowComment: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${API_URL}/${id}`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['WorkflowComment'],
		}),
		deleteWorkflowComment: builder.mutation({
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['WorkflowComment'],
		}),
	}),
})

export const {
	useLazyGetWorkflowCommentsQuery,
	useLazyGetWorkflowCommentQuery,
	useCreateWorkflowCommentMutation,
	useUpdateWorkflowCommentMutation,
	useDeleteWorkflowCommentMutation,
} = workflowCommentApi
