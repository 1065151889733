import { ReactComponent as CloseIcon2 } from 'assets/svg/close-icon-2.svg'

const PopupHeader = ({ onClose, title, icon }) => {
	return (
		<div>
			<div className='relative -left-[2%] top-[2%]'>
				<button type='button' className='absolute right-0 top-3' onClick={onClose}>
					<CloseIcon2 />
				</button>
			</div>

			<div className='mx-auto mt-0 flex w-full flex-col items-center p-4'>
				<span className='mb-3'>{icon}</span>
				{title ? <h3 className='m-0 text-lg font-semibold'>{title}</h3> : undefined}
			</div>
		</div>
	)
}

export default PopupHeader
