import { Fragment, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import { useLazyGetAnnouncementsQuery } from '../announcements-api'
import AnnouncementsFilters from './announcements-filters'
import AnnouncementssTable from './announcements-table'

const AnnouncementsListingContainer = () => {
	const location = useLocation()
	const history = useHistory()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination
	const parseQueryString = getParseQueryString(location?.search)

	useEffect(() => {
		if (pagination) {
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			history.push(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	const [fetchAnnouncements, { data, isFetching }] = useLazyGetAnnouncementsQuery()
	useEffect(() => {
		if (location?.search) {
			fetchAnnouncements({
				...parseQueryString,
			})
		}
	}, [location?.search])

	return (
		<Fragment>
			<AnnouncementsFilters setPagination={setPagination} />
			<AnnouncementssTable
				data={data?.announcements}
				meta={data?.meta}
				isLoading={isFetching}
				pagination={pagination}
				setPagination={setPagination}
			/>
		</Fragment>
	)
}

export default AnnouncementsListingContainer
