import { Tab } from '@headlessui/react'
import { snakeCase } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { useAppRouter } from 'hooks'
import { useLazyGetWorkflowCommentsQuery } from 'modules/workflows/workflow-comment-section/workflow-comment-api'
import { WorkflowStatuses } from 'utilities/helpers'
import { getWorkflowCardBg } from '../assigned-workflows-listing/workflow-card'
import { createTabs, getFileLabel } from '../helpers'
import CommentSection from './comment-section'
import PopupContainer, { WORKFLOW_ACTIONS } from './popup'
import TabsContent from './tabs-content'
import TabsList, { TabsListMobile } from './tabs-list'

const AssignedWorkflowForm = ({
	workflow,
	uploads,
	individualDocument,
	createdBy,
	tab2InitialValues,
}) => {
	const { parseQuery, params } = useAppRouter()
	const id = params?.id
	const [workflowAction, setWorkflowAction] = useState(null)
	const [activeTab, setactiveTab] = useState(1)
	const [CompleteSteps, setCompleteSteps] = useState([])
	const [isOpen, setIsOpen] = useState(false)
	const onClose = () => setIsOpen(false)
	const selectedIndex = Number(parseQuery?.step) - 1
	const isDesktop = useMediaQuery({
		query: '(min-width: 768px)',
	})
	const { backgroundColor, colorName } = useMemo(() => getWorkflowCardBg('assigned'), [])
	const [fetchComments, { data }] = useLazyGetWorkflowCommentsQuery()
	const currentStatus = workflow?.status
	const user_type = workflow?.user_type
	const files = useMemo(() => getFileLabel(uploads?.note, user_type), [uploads?.note, user_type])
	const filesToUpload = useMemo(
		() =>
			files.filter((f) => {
				const uploaded = uploads?.files.map((f) => f?.title)
				return !uploaded.includes(f?.file)
			}),
		[files, uploads?.files],
	)
	useEffect(() => {
		if (
			snakeCase(workflow?.status) === 'assigned' &&
			files?.length > 0 &&
			uploads?.files?.length < files?.length
		) {
			setWorkflowAction(WORKFLOW_ACTIONS.BEGIN)
			setIsOpen(true)
		}

		if (
			(workflow?.status === WorkflowStatuses?.REJECTED ||
				workflow?.status === WorkflowStatuses?.FINAL_REVIEWER_REJECTED) &&
			workflow &&
			individualDocument
		) {
			fetchComments({
				page: 1,
				limit: 10,
				workflow: workflow?.id,
				workflow_step: individualDocument?.id,
			}).then((response) => {
				if (response?.data?.comments.length > 0) {
					setWorkflowAction(WORKFLOW_ACTIONS.CORRECTIONS)
					setIsOpen(true)
				}
			})
		}
	}, [workflow, files, uploads, individualDocument])

	const notes = useMemo(() => workflow?.notes, [workflow?.notes])
	const tabs = useMemo(() => createTabs(workflow, uploads), [workflow, uploads])
	const toggleTab = (tab) => {
		if (activeTab !== tab) {
			if (tab >= 1 && tab <= 5) {
				setactiveTab(tab)
			}
		}
	}

	return (
		<div>
			<Tab.Group selectedIndex={selectedIndex}>
				<div className='flex w-full flex-col justify-between gap-8 md:!flex-row'>
					{isDesktop ? (
						<TabsList tabs={tabs} workflow={workflow} />
					) : (
						<TabsListMobile tabs={tabs} workflow={workflow} />
					)}

					<TabsContent
						files={files}
						setWorkflowAction={setWorkflowAction}
						setIsOpen={setIsOpen}
						tabs={tabs}
						workflow={workflow}
						uploads={uploads}
						activeTab={activeTab}
						createdBy={createdBy}
						toggleTab={toggleTab}
						user_type={user_type}
						tab2InitialValues={tab2InitialValues}
						CompleteSteps={CompleteSteps}
						currentStatus={currentStatus}
						id={id}
						individualDocument={individualDocument}
						isLoading={false}
						setCompleteSteps={setCompleteSteps}
					/>

					{isDesktop ? (
						<div className='w-[276px]'>
							<CommentSection
								stepId={individualDocument?.id}
								workflowId={workflow?.id}
							/>
						</div>
					) : undefined}
				</div>
			</Tab.Group>

			<PopupContainer
				isOpen={isOpen}
				onClose={onClose}
				action_type={workflowAction}
				backgroundColor={backgroundColor}
				colorName={colorName}
				files={filesToUpload}
				notes={notes}
				comments={data?.comments || []}
			/>
		</div>
	)
}

export default AssignedWorkflowForm
