import { Field, FormikProvider, useFormik } from 'formik'
import { Button, TextareaField } from 'theme/ui/forms'
import { validationSchema } from '.'
import {
	useCreateWorkflowCommentMutation,
	useUpdateWorkflowCommentMutation,
} from './workflow-comment-api'

const CommentForm = ({ comment, workflowId, stepId, parent_comment_id, onClose }) => {
	const [createWorkflowComment] = useCreateWorkflowCommentMutation()
	const [updateWorkflowComment] = useUpdateWorkflowCommentMutation()

	const formik = useFormik({
		initialValues: {
			workflow: workflowId,
			workflow_step: stepId,
			message: comment?.message || '',
			type: 'WorkflowReview',
			parent_comment_id: parent_comment_id || undefined,
		},
		validationSchema: validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { resetForm }) => {
			if (comment) {
				await updateWorkflowComment({ payload: values, id: comment?.id })
			} else {
				await createWorkflowComment(values)
			}
			resetForm()
			onClose()
		},
	})
	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				<div className='flex flex-col space-y-3'>
					<Field placeholder='Comment' name='message' component={TextareaField} />
					<div>
						<Button
							type='submit'
							isLoading={formik.isSubmitting}
							size='sm'
							isLoadingText='Posting...'>
							Post comment
						</Button>
						{onClose ? (
							<Button
								type='button'
								size='sm'
								className='ml-1'
								onClick={onClose}
								variant='white'>
								Cancel
							</Button>
						) : undefined}
					</div>
				</div>
			</form>
		</FormikProvider>
	)
}

export default CommentForm
