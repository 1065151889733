import { useUser } from 'hooks'
import { Link } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'

const SocialSource = () => {
	const { team, user } = useUser()
	return (
		<Card style={{ minHeight: '380px' }}>
			<CardBody>
				<h4 className='card-title '>Team</h4>

				<div className='mt-3 flex flex-col items-center justify-center text-center'>
					<div className='avatar-sm mx-auto mb-4'>
						<span className='avatar-title rounded-circle bg-soft-primary font-size-24'>
							<i className='uil-user text-primary text-[30px]'></i>
						</span>
					</div>
					<p className='font-16 text-muted !mt-5 mb-2'></p>
					<h5>
						<Link to='#' className='text-dark'>
							{team?.team_name} - <span className='text-muted font-16'>Team</span>{' '}
						</Link>
					</h5>
					<p className='text-muted'>{user?.fullName}</p>
					<Link to='#' className='text-reset font-16'>
						Ask a question <i className='mdi mdi-chevron-right'></i>
					</Link>
				</div>
			</CardBody>
		</Card>
	)
}

export default SocialSource
