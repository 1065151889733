import { Fragment, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import { useLazyGetHouseholdsQuery } from '../households-api'
import HouseholdsFilters from './households-filters'
import HouseholdsTable from './households-table'

const HouseholdsListingContainer = () => {
	const location = useLocation()
	const history = useHistory()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination
	const parseQueryString = getParseQueryString(location?.search)

	useEffect(() => {
		if (pagination) {
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			history.push(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	const [fetchHouseholds, { data, isFetching }] = useLazyGetHouseholdsQuery()

	useEffect(() => {
		if (location?.search) {
			fetchHouseholds({
				...getParseQueryString(location?.search),
			})
		}
	}, [location?.search])

	return (
		<Fragment>
			<HouseholdsFilters setPagination={setPagination} />

			<HouseholdsTable
				data={data?.households}
				meta={data?.meta}
				isLoading={isFetching}
				pagination={pagination}
				setPagination={setPagination}
			/>
		</Fragment>
	)
}

export default HouseholdsListingContainer
