import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'

const StatusQuery = () => {
	const { data, isLoading } = useQuery(apiEndpoints.taskStatus)

	return {
		statuses: data?.data || [],
		isLoading,
	}
}

export default StatusQuery
