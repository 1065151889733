import Actions from './Actions'

const Heading = ({
	open,
	totalDocuments,
	handleClickEditPackage,
	handleClickAddDocument,
	document,
}) => {
	return (
		<div className='flex w-full items-left sm:items-center mx-0 sm:mx-3 justify-between'>
			<div className='flex items-left sm:items-center space-x-4 gap-y-[10px] sm:gap-y-[0px] text-left sm:text-center special_mobile_alignment text_adjust'>
				<span className='font-ibmplex text-sm sm:text-lg font-bold'>{document?.title}</span>
				{totalDocuments ? (
				<div className='flex h-6 text-center mobile_margin items-center justify-center space-x-0 sm:space-x-1 rounded !bg-[#F2F5FF] px-2 text-xs text-main sm:w-auto w-[100px]'> {/* Only apply width 100px on screens less than 768px */}
					<span className='w-full text-center'>{totalDocuments} Documents</span> {/* Center the text */}
				</div>
				) : undefined}
			</div>
			<div className='flex items-center space-x-4'>
				<Actions
				handleClickEditPackage={handleClickEditPackage}
				handleClickAddDocument={handleClickAddDocument}
				field={document}
				/>
				<div
				className=''
				style={{
					fontSize: 28,
				}}>
				{open ? <i className='uil-angle-down' /> : <i className='uil-angle-up' />}
				</div>
			</div>
		</div>

	)
}

export default Heading
