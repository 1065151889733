import { createApi } from '@reduxjs/toolkit/query/react'
import apiEndpoints from 'helpers/apiEndpoints'

import axiosBaseQuery from 'store/axios-base-query'
import { formatDate } from 'utilities/helpers'

const API_URL = apiEndpoints.features

export const featuresApi = createApi({
	reducerPath: 'Features',
	keepUnusedDataFor: 0,
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['Feature'],
	endpoints: (builder) => ({
		getFeatures: builder.query({
			keepUnusedDataFor: 0,
			query: (params) => ({
				url: API_URL,
				method: 'GET',
				params: params,
			}),
			providesTags: ['Feature'],
			transformResponse: (response, _, arg) => {
				const features = Array.isArray(response?.data?.features)
					? response?.data?.features.map((feature, idx) => ({
							index: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + idx + 1,
							id: feature?.id,
							name: feature?.name,
							description: feature?.description,
							created_at: formatDate(feature?.created_at),
					  }))
					: []

				return {
					features: features,
					meta: response?.data?.meta,
				}
			},
		}),
		getFeature: builder.query({
			keepUnusedDataFor: 0,
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'GET',
			}),
			providesTags: ['Feature'],
		}),
		createFeature: builder.mutation({
			query: (payload) => ({
				url: API_URL,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['Feature'],
		}),
		updateFeature: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${API_URL}/${id}`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['Feature'],
		}),
		deleteFeature: builder.mutation({
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Feature'],
		}),
	}),
})

export const {
	useLazyGetFeaturesQuery,
	useLazyGetFeatureQuery,
	useCreateFeatureMutation,
	useUpdateFeatureMutation,
	useDeleteFeatureMutation,
} = featuresApi
