import { createColumnHelper } from '@tanstack/react-table'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import Badge from 'theme/ui/badge'
import DataTable from 'theme/ui/data-table'
import { ROLES_APP_ROUTES } from '../routes'

const RolesTable = ({ data, meta, isLoading, pagination, setPagination }) => {
	const columnHelper = createColumnHelper()
	const [sorting, setSorting] = useState([])
	const columns = [
		columnHelper.accessor((row) => row, {
			id: 'id',
			size: 50,
			header: () => <span>#</span>,
			cell: (info) => <div>{info?.getValue()?.ind}</div>,
		}),
		columnHelper.accessor((row) => row, {
			id: 'name',
			size: 200,
			header: () => <span>Name</span>,
			cell: (info) => <div>{info?.getValue()?.name}</div>,
		}),
		columnHelper.accessor((row) => row, {
			id: 'description',
			size: 200,
			header: () => <span>Description</span>,
			cell: (info) => <div>{info?.getValue()?.description}</div>,
		}),
		columnHelper.accessor((row) => row, {
			id: 'status',
			size: 200,
			header: () => <span>Status</span>,
			cell: (info) => (
				<>
					<Badge variant={info?.getValue()?.is_active == 'Active' ? 'success' : 'danger'}>
						<span className='capitalize'>{info?.getValue()?.is_active}</span>
					</Badge>
				</>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'action',
			size: 200,
			enableSorting: false,
			header: () => <span>Action</span>,
			cell: (info) => (
				<>
					<div className='max-w-fit'>
						<div className='flex space-x-2'>
							<Link
								to={ROLES_APP_ROUTES.edit(info?.getValue()?.id)}
								className='action-btn btn-info-outline'>
								<i className='uil-edit' style={{ fontSize: 16 }} />
								<span>Edit</span>
							</Link>
						</div>
					</div>
				</>
			),
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={data}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			meta={meta}
			setSorting={setSorting}
			sorting={sorting}
		/>
	)
}

export default RolesTable
