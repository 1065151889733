import Task from './Task'

export default Task

export const convertToTranslate = (transform) => {
	if (transform) {
		return `translate3d(${transform?.x}px, ${transform?.y}px, 0)`
	} else {
		return null
	}
}
