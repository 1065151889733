import NotificationsTemplatesFilters from './notifications-templates-filters'

export const getFormikFilterValues = (values) => {
	const filters = {}

	const search = values?.search || ''
	if (search) {
		Object.assign(filters, { search })
	}

	return filters
}

export const getInitialValues = (queryObj) => {
	const initialValues = {
		search: '',
	}
	if (queryObj?.search) {
		Object.assign(initialValues, { search: queryObj?.search })
	}

	return initialValues
}

export default NotificationsTemplatesFilters
