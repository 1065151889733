import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Field } from 'formik'
import { Fragment } from 'react'
import StatusQuery from './status-query'

const StatusDropdown = ({ values, name }) => {
	const { options } = StatusQuery('')

	return (
		<div className='full_width'>
			<Menu as='div' className='relative inline-block text-left full_width'>
				{({ open }) => (
					<>
						<Menu.Button
							className={classNames('btn btn-secondary full_width', {
								'!bg-main/20 !text-main !shadow-md': open,
							})}>
							<span>Status</span>{' '}
							<span className='ms-2 inline-flex h-4 w-4 items-center justify-center rounded-full bg-main/20 text-xs font-semibold text-main'>
								{values.length}
							</span>
						</Menu.Button>

						<Transition
							as={Fragment}
							enter='transition ease-out duration-100'
							enterFrom='transform opacity-0 scale-95'
							enterTo='transform opacity-100 scale-100'
							leave='transition ease-in duration-75'
							leaveFrom='transform opacity-100 scale-100'
							leaveTo='transform opacity-0 scale-95'>
							<Menu.Items
								static
								className='absolute right-0 z-[1005] mt-2 w-56 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
								<div className='px-1 py-1'>
									<Menu.Item disabled>
										<div className='custom-scroll flex max-h-72 flex-col space-y-1 overflow-auto pr-1'>
											{options.map((option, i) => (
												<div className='flex' key={i}>
													<Field
														id={`${name}_${i}`}
														name={name}
														type='checkbox'
														value={option.value}
														className='peer/status hidden'
													/>
													<label
														key={i}
														htmlFor={`${name}_${i}`}
														className='mb-0 w-full cursor-pointer select-none rounded border-gray-200 px-[14px] py-1.5 text-gray-500 transition-colors duration-200 ease-in-out hover:bg-main/10 peer-checked/status:border-gray-200  peer-checked/status:bg-main/20 peer-checked/status:text-main'>
														{option.label}
													</label>
												</div>
											))}
										</div>
									</Menu.Item>
								</div>
							</Menu.Items>
						</Transition>
					</>
				)}
			</Menu>
		</div>
	)
}

export default StatusDropdown
