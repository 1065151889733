/* eslint-disable no-case-declarations */
import { Fragment } from 'react'
import { Field } from 'redux-form'

import { CheckBox } from 'components/Forms/CheckBoxComponent'
import { RadioComponent } from 'components/Forms/RadioComponent'
import { SelectField } from 'components/Forms/SelectComponent'
import { TextField } from 'components/Forms/TextFieldComponent'
import MultiCheckbox from 'components/Forms2/multi-checkbox'
import { required } from 'utils/formValidation'

const validate = {
	field: [required('field value', 'enter')],
}

const FieldType = ({ field, fieldName, isReadOnly }) => {
	const type = field?.type || 'text'

	switch (type) {
		case 'text':
		case 'password':
		case 'email':
		case 'number':
		case 'date':
		case 'color':
			return (
				<Field
					label={field?.label}
					infoText={field?.description}
					type={type}
					name={fieldName}
					component={TextField}
					placeholder={field?.placeholder}
					validate={validate.field}
					disabled={isReadOnly}
				/>
			)

		case 'select':
			const options = Array.isArray(field?.values)
				? field.values.map((option) => ({ label: option?.label, value: option?.value }))
				: []

			return (
				<Field
					label={field?.label}
					infoText={field?.description}
					type='select'
					name={fieldName}
					component={SelectField}
					options={options}
					isMulti={field?.multiple}
					validate={validate.field}
					isDisabled={isReadOnly}
				/>
			)

		case 'checkbox':
			const checkboxOptions = Array.isArray(field?.values) ? field.values : []

			return (
				<Fragment>
					{checkboxOptions.length > 1 ? (
						<div className='flex-col'>
							<label className='mb-2'>{field?.label}</label>
							{field?.description && (
								<i className='text-muted mb-2' style={{ fontSize: 12 }}>
									{field?.description}
								</i>
							)}
							<Field
								name={fieldName}
								component={MultiCheckbox}
								value={[]}
								options={checkboxOptions}
								readOnly={isReadOnly}
							/>
						</div>
					) : (
						<div className='flex-col'>
							<Field
								label={field?.label}
								name={fieldName}
								id={fieldName}
								component={CheckBox}
								readOnly={isReadOnly}
							/>
						</div>
					)}
				</Fragment>
			)

		case 'radio-group':
			const radios = Array.isArray(field?.values) ? field.values : []

			return (
				<Fragment>
					<div className='flex-col'>
						<label className='mb-0'>{field?.label}</label>
						{field?.description && (
							<i className='text-muted mb-2' style={{ fontSize: 12 }}>
								{field?.description}
							</i>
						)}
					</div>
					{radios.map((item, idx) => {
						return (
							<Fragment key={idx}>
								<Field
									label={item?.label}
									id={item?.value}
									value={item?.value}
									type='radio'
									name={fieldName}
									component={RadioComponent}
									readOnly={isReadOnly}
								/>
							</Fragment>
						)
					})}
				</Fragment>
			)

		case 'checkbox-group':
			const checkboxes = Array.isArray(field?.values) ? field.values : []

			return (
				<Fragment>
					<div className='flex-col'>
						<label className='mb-0'>{field?.label}</label>
						{field?.description && (
							<Fragment>
								<i className='text-muted mb-2' style={{ fontSize: 12 }}>
									{field?.description}
								</i>
							</Fragment>
						)}
					</div>
					<Field
						type='checkbox'
						options={checkboxes}
						multiple={field?.multiple}
						name={fieldName}
						component={CheckBox}
						readOnly={isReadOnly}
					/>
				</Fragment>
			)

		default:
			return (
				<Field
					label={field?.label}
					infoText={field?.description}
					type='text'
					name={fieldName}
					component={TextField}
					placeholder={field?.placeholder}
					validate={validate.field}
					disabled={isReadOnly}
				/>
			)
	}
}

export default FieldType
