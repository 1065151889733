import { Fragment } from 'react'
import { Field } from 'redux-form'

import { RadioComponent } from 'components/Forms/RadioComponent'
import { TextField } from 'components/Forms/TextFieldComponent'
import AsyncImage from 'theme/ui/async-image'

const Tab4 = ({ client }) => {
	const STEP_KEY = 'upload_selfie'
	const step = client.find((item) => item?.user_key === STEP_KEY)

	return (
		<Fragment>
			{step ? (
				<div className='space-y-6'>
					<AsyncImage
						src={step?.file?.path}
						alt='Selfie'
						className='bordered h-32 w-32 rounded-full'
					/>

					<div>
						<label>Status:</label>
						<div className='flex space-x-5'>
							<Field
								id={`step-${STEP_KEY}-status-approved`}
								label='Approved'
								name='step.upload_selfie.status'
								type='radio'
								component={RadioComponent}
								value='1'
							/>

							<Field
								id={`step-${STEP_KEY}-status-rejected`}
								value='0'
								label='Rejected'
								name='step.upload_selfie.status'
								type='radio'
								component={RadioComponent}
							/>
						</div>
					</div>

					<Field
						label='Addtional Notes :'
						type='textarea'
						name={`step.${STEP_KEY}.reason`}
						component={TextField}
					/>
				</div>
			) : (
				<div className='alert alert-warning'>Step not found</div>
			)}
		</Fragment>
	)
}

export default Tab4
