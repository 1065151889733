import PopupContainer from './popup-container'

export const WORKFLOW_ACTIONS = {
	BEGIN: 'workflow.begin',
	CORRECTIONS: 'workflow.corrections',
	SUBMITTED: 'workflow.submitted',
}

export const mobileAnimation = {
	enter: 'ease-out duration-300',
	enterFrom: 'opacity-0 translate-y-full',
	enterTo: 'opacity-100 translate-y-0',
	leave: 'ease-in duration-200',
	leaveFrom: 'opacity-100 translate-y-0',
	leaveTo: 'opacity-0 translate-y-full',
}
export const desktopAnimation = {
	enter: 'ease-out duration-300',
	enterFrom: 'opacity-0 scale-95',
	enterTo: 'opacity-100 scale-100',
	leave: 'ease-in duration-200',
	leaveFrom: 'opacity-100 scale-100',
	leaveTo: 'opacity-0 scale-95',
}

export function combineFirstTwo(arr) {
	if (arr.length < 2) {
		return arr
	}
	const firstTwo = arr.slice(0, 2)
	return firstTwo
}

export default PopupContainer
