import { useState } from 'react'
import { Link } from 'react-router-dom'
import DeleteConfirmAlert from 'theme/ui/delete-confirm-alert'
import notification from 'utilities/notification'
import { USERS_APP_ROUTES } from '../routes'
import { useRemoveUserMutation } from '../users-api'

const UsersActions = ({ hasTeam, id }) => {
	const [isOpen, setIsOpen] = useState(false)
	const onOpen = () => setIsOpen(true)
	const onClose = () => setIsOpen(false)

	const [removeUser, { isLoading }] = useRemoveUserMutation()
	const onRemoveUser = async (userId) => {
		try {
			const response = await removeUser({ userId })
			if (response?.data?.status === 200) {
				notification('success', response?.data?.message)
			}
		} catch (error) {
			notification('warn', error?.message)
		}
	}
	return (
		<div>
			<DeleteConfirmAlert
				isDeleting={isLoading}
				isOpen={isOpen}
				onClose={onClose}
				onConfirm={() => onRemoveUser(id)}
			/>
			<div className='max-w-fit'>
				<div className='flex space-x-2'>
					<Link to={USERS_APP_ROUTES.view(id)} className='action-btn btn-info-outline'>
						<i className='uil-edit' style={{ fontSize: 16 }} />
						<span>Edit</span>
					</Link>
					{!hasTeam && (
						<button
							type='button'
							className='action-btn btn-red-outline'
							onClick={onOpen}>
							<i className='uil-trash-alt' style={{ fontSize: 16 }} />
							<span>Delete</span>
						</button>
					)}
				</div>
			</div>
		</div>
	)
}

export default UsersActions
