import React, { useContext, useRef, useState } from 'react'

const CanvasContext = React.createContext()

export const CanvasProvider = ({ options, children }) => {
	const { classNames, height, width } = options

	const [isDrawing, setIsDrawing] = useState(false)
	const canvasRef = useRef(null)
	const contextRef = useRef(null)

	const prepareCanvas = () => {
		const canvas = canvasRef.current
		canvas.width = width * 2
		canvas.height = height * 2
		canvas.style.width = `${width}px`
		canvas.style.height = `${height}px`
		// canvas.style.border = `1px #205bba solid`
		canvas.className = classNames

		const context = canvas.getContext('2d')
		context.scale(2, 2)
		context.lineCap = 'round'
		context.strokeStyle = 'black'
		context.lineWidth = 2
		contextRef.current = context
	}

	const startDrawing = ({ nativeEvent }) => {
		const { offsetX, offsetY } = nativeEvent
		contextRef.current.beginPath()
		contextRef.current.moveTo(offsetX, offsetY)
		setIsDrawing(true)
	}

	const finishDrawing = () => {
		contextRef.current.closePath()
		setIsDrawing(false)
	}

	const draw = ({ nativeEvent }) => {
		if (!isDrawing) {
			return
		}
		const { offsetX, offsetY } = nativeEvent
		contextRef.current.lineTo(offsetX, offsetY)
		contextRef.current.stroke()
	}

	const clearCanvas = () => {
		const canvas = canvasRef.current
		const context = canvas.getContext('2d')
		context.fillStyle = 'white'
		context.clearRect(0, 0, canvas.width, canvas.height)
	}

	const saveCanvasAsImage = () => {
		const canvas = canvasRef.current
		const link = document.createElement('a')
		link.download = 'canvas_image.png'
		link.href = canvas.toDataURL('image/png')
		return canvas.toDataURL('image/png')
	}

	return (
		<CanvasContext.Provider
			value={{
				canvasRef,
				contextRef,
				prepareCanvas,
				startDrawing,
				finishDrawing,
				clearCanvas,
				draw,
				saveCanvasAsImage,
			}}>
			<div>{children}</div>
		</CanvasContext.Provider>
	)
}

export const useCanvas = () => useContext(CanvasContext)
