import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Fragment, useState } from 'react'
import { FormFeedback, Input, Label } from 'reactstrap'

const InputLg = ({
	meta: { touched, invalid, error },
	label,
	input,
	hideMessage = false,
	className: { inputClass },
	...rest
}) => {
	const [val] = useState('')

	return (
		<Fragment>
			<div>
				{label ? <Label className='m-0'>{label}</Label> : undefined}
				<Input
					{...rest}
					{...input}
					value={val || input.value}
					invalid={touched && Boolean(invalid)}
					className={classNames(
						inputClass,
						'!focus:border-b !border-0 !px-0 !text-2xl !font-semibold',
					)}
				/>

				{touched && error && !hideMessage && (
					<FormFeedback>{touched && error}</FormFeedback>
				)}
			</div>
		</Fragment>
	)
}

InputLg.propTypes = {
	input: PropTypes.object.isRequired,
	meta: PropTypes.object,
}

InputLg.defaultProps = {
	meta: null,
	className: { formGroupClass: '', labelClass: '', inputClass: '' },
}

export default InputLg
