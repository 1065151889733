import { useState } from 'react'
import { FormBuilderContext, FormBuilderUpdater } from '.'

export const FormBuilderProvider = ({ children }) => {
	const [formBuilder, setFormBuilder] = useState(null)

	const onSetFormBuilder = (value) => {
		setFormBuilder(value)
	}

	return (
		<FormBuilderContext.Provider value={formBuilder}>
			<FormBuilderUpdater.Provider value={onSetFormBuilder}>
				{children}
			</FormBuilderUpdater.Provider>
		</FormBuilderContext.Provider>
	)
}
