import { useMemo } from 'react'

import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import { useAppRouter } from 'hooks'
import { getWorkflowCardBg } from 'modules/assigned-workflows/assigned-workflows-listing/workflow-card'
import StatusBadge from 'modules/assigned-workflows/assigned-workflows-listing/workflow-card/status-badge'
import WorkflowDetails from 'modules/assigned-workflows/workflow-details'
import ProgressBar from 'theme/ui/progress-bar'

const TabsListMobile = ({ tabs, workflow }) => {
	const { parseQuery } = useAppRouter()

	const activeTab = useMemo(() => Number(parseQuery?.step) - 1, [parseQuery?.step])
	const progress = useMemo(
		() => (Number(parseQuery?.step) / tabs.length) * 100,
		[parseQuery?.step, tabs],
	)

	const colors = useMemo(() => getWorkflowCardBg(workflow?.status), [workflow?.status])

	return (
		<div className='flex w-full flex-col divide-y-2 bg-white p-3'>
			<div className='flex items-center justify-between'>
				<div className='flex flex-col'>
					<span>
						Step {parseQuery?.step}/{tabs.length}
					</span>
					<Tab.List>
						{tabs.map((tab, idx) => (
							<Tab
								key={idx}
								className={classNames(
									'text-base font-bold',
									tab?.id === activeTab ? 'block' : 'hidden',
								)}>
								<span>{tab.title}</span>
							</Tab>
						))}
					</Tab.List>
				</div>
				<div>
					<ProgressBar value={progress} variant='primary' className='!my-5 w-[91px]' />
				</div>
			</div>

			<div className='flex items-center justify-between pt-3'>
				<StatusBadge
					status={workflow?.status}
					color={colors?.color}
					bgColor={colors?.backgroundColor}
				/>
				<WorkflowDetails workflow={workflow} />
			</div>
		</div>
	)
}

export default TabsListMobile
