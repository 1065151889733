import { Field, FormikProvider, useFormik } from 'formik'
import { useMemo } from 'react'

import { Button, InputField } from 'theme/ui/forms'
import { sendInvite, validationSchema } from '.'
import ExpiredInDropdown from './expired-in-dropdown'
import ExpiredInQuery from './expired-in-dropdown/expired-in-query'
import RolesDropdown from './roles-dropdown'
import RolesQuery from './roles-dropdown/roles-query'

const UserInviteForm = ({ onClose }) => {
	const { roles } = RolesQuery()
	const { options } = ExpiredInQuery()
	const ownerRole = useMemo(() => roles.find((r) => r.slug === 'owner'), [roles])
	const expiredOption = useMemo(() => options.find((o) => o?.value === '1 day'), [options])

	const formik = useFormik({
		initialValues: {
			role: ownerRole || '',
			email: '',
			expiredIn: expiredOption || '',
			isEmailSend: true,
		},
		validationSchema: validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true)
				await sendInvite(values)
				onClose()
				setSubmitting(false)
			} catch (error) {
				setSubmitting(false)
			}
		},
	})

	return (
		<div>
			<div className='!py-2'>
				<div className='w-full bg-[#EFF1FD] py-2 text-center'>
					<span className='text-[13px]'>
						Link will be shared on email mentioned below
					</span>
				</div>

				<FormikProvider value={formik}>
					<form onSubmit={formik.handleSubmit}>
						<div className='space-y-6 p-6'>
							{/* <UserType /> */}

							<RolesDropdown />

							<Field
								label='Email Address'
								name='email'
								type='email'
								component={InputField}
								isRequiredField
								placeholder='jhon@example.com'
							/>

							<ExpiredInDropdown />

							<div className='text-center'>
								<Button
									type='submit'
									disabled={!formik.isValid}
									isLoading={formik.isSubmitting}
									isLoadingText='Invite Sending...'>
									Invite
								</Button>
							</div>
						</div>
					</form>
				</FormikProvider>
			</div>
		</div>
	)
}

export default UserInviteForm
