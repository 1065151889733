/* eslint-disable no-unused-vars */
import axios from 'axios'
import { useRef, useState } from 'react'
import { Field, reduxForm } from 'redux-form'

import { CheckBox } from 'components/Forms/CheckBoxComponent'
import apiEndpoints from 'helpers/apiEndpoints'
import { Button, FileUploadField } from 'theme/ui/forms'
import { showSuccessToast } from 'utils/commonFunctions'
import FieldMappingList from './field-mapping-list'

const ClientImportForm = (props) => {
	const { handleSubmit, csvFieldsState, dbFieldsState, fileIdState, isLoading } = props
	const [csvFields, setCsvFields] = csvFieldsState
	const [dbFieldsOptions, setDbFieldsOptions] = dbFieldsState
	const [fileId, setFileId] = fileIdState
	const [firstRow, setFirstRow] = useState([])

	const [isUploading, setIsUploading] = useState(false)
	const csvRef = useRef(null)
	const [acvtiveStep, setAcvtiveStep] = useState(1)
	const onChange = (file) => {
		const formData = new FormData()
		formData.append('file', file)

		if (file) {
			setIsUploading(true)
			axios
				.post(apiEndpoints.clientCSV, formData)
				.then((res) => {
					setIsUploading(false)
					const response = res?.data
					const fields = response?.data?.headerFields
					const dbFields = response?.data?.dbFields
					if (response?.status === 200 && Array.isArray(fields)) {
						showSuccessToast(response?.message)
						setFileId(response?.data?.id)
						setCsvFields(
							fields
								.map((item, idx) => ({ name: item, idx }))
								.filter((field) => field?.name),
						)
						setDbFieldsOptions(
							dbFields.map((item) => ({
								label: item,
								value: item,
							})),
						)
						const firstRow = Object?.values(response?.data?.firstRow)
						setFirstRow(firstRow)
					}
				})
				.catch((error) => {
					setIsUploading(false)
					// showErrorToast(error?.response?.data?.message)
					if (csvRef?.current) {
						csvRef.current.value = null
					}
				})
		}
	}

	const handlePrevious = () => {
		setAcvtiveStep(1)
		setFirstRow([])
		setDbFieldsOptions([])
		setCsvFields([])
	}

	return (
		<form onSubmit={handleSubmit}>
			{acvtiveStep === 1 && (
				<div className='mobile_alignmentt flex w-full gap-10'>
					<div className='form-container mx-auto'>
						<div className='form-row'>
							<div className='form-heading'>
								<h3>Upload CSV file</h3>
								<p>
									Assigning documents/packages here, do not ignore to fill in all
									the required fields completely.
								</p>
							</div>

							<div className='form-fields-card'>
								<div className='flex flex-col space-y-5'>
									<div className='flex flex-col space-y-1'>
										<FileUploadField
											label='Upload CSV file'
											isUploading={isUploading}
											fileRef={csvRef}
											onUpload={onChange}
											allowedExtentions='.csv'
											isMarginBottom={false}
											isDisabled={csvFields?.length > 0}
										/>

										<a
											href='/csv-templates/csv-template-1.csv'
											download={true}
											target='_blank'
											className='text-main hover:!underline'>
											<i className='uil-file-download mb-1 text-base' />
											<span>Download Import Template</span>
										</a>
									</div>
								</div>
							</div>
						</div>

						<div className='flex justify-end pt-3'>
							<button
								className='btn btn-primary'
								onClick={() => setAcvtiveStep(2)}
								disabled={csvFields?.length <= 0}>
								Next
							</button>
						</div>
					</div>
				</div>
			)}

			{acvtiveStep === 2 && (
				<div className=''>
					{csvFields?.length > 0 && (
						<>
							<FieldMappingList
								csvFields={csvFields}
								dbFieldsOptions={dbFieldsOptions}
								firstRow={firstRow}
							/>

							<div className='my-4 flex items-center justify-center'>
								<Field
									name='isEmailSend'
									id='isEmailSend'
									label='Would you like to send an invitation to join our system?'
									component={CheckBox}
									ignoreBottomMargin
									type='checkbox'
								/>
							</div>

							<div className='flex items-center justify-between'>
								<Button variant='white' onClick={() => handlePrevious()}>
									Previous
								</Button>

								<Button
									isLoading={isLoading}
									type='submit'
									disabled={!csvFields.length}>
									Import
								</Button>
							</div>
						</>
					)}
				</div>
			)}
		</form>
	)
}

export default reduxForm({
	form: 'import',
	enableReinitialize: true,
})(ClientImportForm)
