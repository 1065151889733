import { createColumnHelper } from '@tanstack/react-table'
import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import Badge from 'theme/ui/badge'

import DataTable from 'theme/ui/data-table'
import { formatPrice, getStatusVariant } from 'utilities/helpers'
import { getDuration } from '.'
import PaymentsActions from './payments-action'

const PaymentsTable = ({ data, meta, isLoading, pagination, setPagination }) => {
	const [actionActiveId, setActionActiveId] = useState(null)
	const onMouseEnter = (id) => setActionActiveId(id)
	const onMouseLeave = () => setActionActiveId(null)
	const [sorting, setSorting] = useState([])
	const columnHelper = createColumnHelper()

	const columns = [
		columnHelper.accessor('index', {
			size: 20,
			header: () => <span>#</span>,
			cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		}),

		// columnHelper.accessor((row) => row?.subscription, {
		// 	id: 'subscription',
		// 	size: 200,
		// 	enableSorting: false,
		// 	header: () => <span>Subscription Details</span>,
		// 	cell: (info) => {
		// 		return (
		// 			<div className='flex flex-col'>
		// 				<div className='flex flex-row'>
		// 					<span className='me-2 text-sm font-bold capitalize'>
		// 						{info.getValue()?.plan?.plan_type}
		// 					</span>
		// 					<Badge variant={getSubStatusVariant(info.getValue().status)}>
		// 						{info.getValue().status}
		// 					</Badge>
		// 				</div>
		// 				<span className='text-xs capitalize text-gray-400'>
		// 					{info.getValue()?.plan?.duration}
		// 				</span>
		// 			</div>
		// 		)
		// 	},
		// }),
		columnHelper.accessor((row) => row, {
			id: 'amount',
			size: 200,
			header: () => <span>Amount</span>,
			cell: (info) => (
				<div>
					<span className='font-bold text-main'>
						{formatPrice(info.getValue().custom_price, info.getValue().currency)}
					</span>
					/{' '}
					<span className='text-xs text-gray-500'>
						{getDuration(info.getValue().subscription?.plan?.duration)}
					</span>
				</div>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'status',
			size: 200,
			header: () => <span>Status</span>,
			cell: (info) => (
				<>
					<Badge variant={getStatusVariant(info.getValue().status)}>
						<span className='capitalize'>{info.getValue().status}</span>
					</Badge>
				</>
			),
		}),
		columnHelper.accessor((row) => row?.user, {
			id: 'user',
			size: 200,
			enableSorting: false,
			header: () => <span>User</span>,
			cell: (info) => {
				return (
					<div className='flex flex-col'>
						<Link
							to={`/users/${info.getValue()?.id}/show`}
							target='_blank'
							className='text-sm font-bold text-main hover:!underline'>
							{info.getValue()?.fullName}
						</Link>
						<span className='text-xs text-gray-400'>{info.getValue()?.email}</span>
					</div>
				)
			},
		}),
		columnHelper.accessor((row) => row, {
			id: 'payment_method',
			size: 200,
			header: () => <span>Payment Method</span>,
			cell: (info) => (
				<span className='capitalize'>
					{info.getValue()?.payment_method ? info.getValue()?.payment_method : '-'}
				</span>
			),
		}),

		columnHelper.accessor((row) => row, {
			id: 'payment_date',
			size: 200,
			enableSorting: false,
			header: () => <span>Payment Date</span>,
			cell: (info) => (
				<PaymentsActions
					payment={info.getValue()}
					isActionActive={actionActiveId === info?.row?.id}
				/>
			),
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={data}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			setSorting={setSorting}
			sorting={sorting}
			meta={meta}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		/>
	)
}

export default PaymentsTable
