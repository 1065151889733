import { useEffect, useState } from 'react'

import placeholderImage from 'assets/images/users/avatar1.jpg'

const AsyncImage = (props) => {
	const [loadedSrc, setLoadedSrc] = useState(null)

	useEffect(() => {
		setLoadedSrc(null)
		if (props.src) {
			const handleLoad = () => {
				setLoadedSrc(props.src)
			}
			const image = new Image()
			image.addEventListener('load', handleLoad)

			image.src = props.src
			return () => {
				image.removeEventListener('load', handleLoad)
			}
		}
	}, [props.src])

	if (loadedSrc === props.src) {
		return <img {...props} />
	} else {
		return <img {...props} src={placeholderImage} />
	}
}

export default AsyncImage
