import { createApi } from '@reduxjs/toolkit/query/react'
import apiEndpoints from 'helpers/apiEndpoints'

import axiosBaseQuery from 'store/axios-base-query'

const API_URL = apiEndpoints.documentEventLog

export const documentEventLogApi = createApi({
	reducerPath: 'DocumentEventLogs',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['DocumentEventLog'],
	endpoints: (builder) => ({
		getDocumentEventLogs: builder.query({
			keepUnusedDataFor: 0,
			query: (params) => ({
				url: API_URL,
				method: 'GET',
				params: params,
			}),
			providesTags: ['DocumentEventLog'],
			transformResponse: (response, _, arg) => {
				const logs = Array.isArray(response?.data?.logs)
					? response?.data?.documentEventLogs.map((logs, idx) => ({
							...logs,
							index: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + idx + 1,
					  }))
					: []

				return {
					logs: logs,
					meta: response?.data?.meta,
				}
			},
		}),
		getDocumentEventLog: builder.query({
			keepUnusedDataFor: 0,
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'GET',
			}),
			providesTags: ['DocumentEventLog'],
		}),
		createDocumentEventLog: builder.mutation({
			query: (payload) => ({
				url: API_URL,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['DocumentEventLog'],
		}),
		updateDocumentEventLog: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${API_URL}/${id}`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['DocumentEventLog'],
		}),
		deleteDocumentEventLog: builder.mutation({
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['DocumentEventLog'],
		}),
	}),
})

export const {
	useLazyGetDocumentEventLogsQuery,
	useLazyGetDocumentEventLogQuery,
	useCreateDocumentEventLogMutation,
	useUpdateDocumentEventLogMutation,
	useDeleteDocumentEventLogMutation,
} = documentEventLogApi
