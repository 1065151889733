import { Card, CardBody, CardTitle, Col, Row, Table } from 'reactstrap'

const LatestTransaction = () => {
	return (
		<Row>
			<Col lg={12}>
				<Card>
					<CardBody>
						<CardTitle className='h4 mb-4'>Holdings</CardTitle>
						<p className='text mb-2'>Last Updated: 08-Nov-24</p>
						<div className='table-responsive'>
							<Table className='table-centered table-nowrap mb-0'>
								<thead className='table-light'>
									<tr>
										<th>Account</th>
										<th>Description</th>
										<th>Quantity</th>
										<th>Symbol</th>
										<th>Average Cost</th>
										<th>Book Value</th>
										<th>Close Price</th>
										<th>Market Value</th>
										<th>Income</th>
										<th>Total Gain/Loss</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Cash (A278376T)</td>
										<td>iShares Core 500 </td>
										<td>430</td>
										<td>IWV</td>
										<td>$501.44</td>
										<td>$250,720.00</td>
										<td>$600.81</td>
										<td>$258,348.30</td>
										<td>$10,028.80</td>
										<td>$17,657.10</td>
									</tr>
									<tr>
										<td>Cash (A278376T)</td>
										<td>iShares Core</td>
										<td>1800</td>
										<td>IEFA</td>
										<td>$44.05</td>
										<td>$79,290.00</td>
										<td>$73.69</td>
										<td>$132,642.00</td>
										<td>$1,585.80</td>
										<td>$54,937.80</td>
									</tr>
									<tr>
										<td>Cash (A278376T)</td>
										<td>iShares Core U.S.</td>
										<td>3350</td>
										<td>AGG</td>
										<td>$89.00</td>
										<td>$298,150.00</td>
										<td>$98.43</td>
										<td>$329,740.50</td>
										<td>$11,926.00</td>
										<td>$43,516.50</td>
									</tr>
									<tr>
										<td>Cash (A278376T)</td>
										<td>iShares Canadian</td>
										<td>1900</td>
										<td>XBB</td>
										<td>$20.00</td>
										<td>$38,000.00</td>
										<td>$28.44</td>
										<td>$54,036.00</td>
										<td>$1,520.00</td>
										<td>$17,556.00</td>
									</tr>
									<tr>
										<td>Cash (A278376T)</td>
										<td>Cash</td>
										<td>73000</td>
										<td>Cash</td>
										<td>$1.00</td>
										<td>$73,000.00</td>
										<td>$1.00</td>
										<td>$73,000.00</td>
										<td>$2,920.00</td>
										<td>$2,920.00</td>
									</tr>
									<tr className='table-light'>
										<td colSpan='5'>
											<strong>Total</strong>
										</td>
										<td>
											<strong>$739,160.00</strong>
										</td>
										<td></td>
										<td>
											<strong>$847,892.00</strong>
										</td>
										<td>
											<strong>$27,980.60</strong>
										</td>
										<td>
											<strong>$136,587.40</strong>
										</td>
									</tr>
								</tbody>
							</Table>
						</div>
					</CardBody>
				</Card>
			</Col>
		</Row>
	)
}

export default LatestTransaction
