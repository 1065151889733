import lightBulb from 'assets/images/onboarding/light-bulb.png'
import apiEndpoints from 'helpers/apiEndpoints'
import { dispatch } from 'store'
import { store } from 'store/common/actions'

export const validIdHelpers = [
	{
		title: 'Why we need your Id?',
		description:
			"We need your ID's to verify your identity. We will use both documents to verify informations.",
		icon: <img src={lightBulb} alt='' className='h-6 w-6' />,
	},
	{
		title: 'What are the supported documents for valid ID?',
		description: `
            <ul className="!list-disc ml-1">
                <li>Driving license</li>
                <li>Voter ID card</li>
            </ul>
        `,
		icon: <img src={lightBulb} alt='' className='h-6 w-6' />,
	},
]

export const godHelpers = [
	{
		title: 'Why we need your Id?',
		description:
			"We need your ID's to verify your identity. We will use both documents to verify informations.",
		icon: <img src={lightBulb} alt='' className='h-6 w-6' />,
	},
]

export const upload = (payload) => {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', payload.file)
		formData.append('type', payload.type)
		if (!payload?.is_recognised) {
			formData.append('is_recognised', payload.is_recognised)
		}
		dispatch(store(apiEndpoints.userOnBoard.verifyDocument, formData)).then((response) => {
			if (response?.status === 200) {
				resolve(response)
			} else {
				reject(new Error('Something went wrong.'))
			}
		})
	})
}

export const VALID_DOCUMENT = 'valid_document'
export const GOVERNMENT_DOCUMENT = 'government_document'
