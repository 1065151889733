import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const SubscriptionDetails = () => {
	return (
		<AppLayout
			meta={{
				title: 'Subscriptions Details',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<PageHeader
						title='Subscriptions'
						hasBackButton={true}
						description='Easily manage your subscriptions hassle-free from one convenient dashboard. Stay in control of your spending, effortlessly track renewal dates, and make changes with just a few clicks, all in one centralized hub designed for your convenience'
					/>

					<div className='page-body'>subscription details</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default SubscriptionDetails
