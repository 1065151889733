import { useState } from "react"
import { useAsyncDebounce } from "react-table"

const GlobalTableFilter = ({ filter, setFilter }) => {
    const [value, setValue] = useState(filter)

    const onChange = useAsyncDebounce(value => {
        setFilter(value || undefined)
    }, 400)


    return (
        <div style={{ width: 200, margin: 3 }}>
            <input
                type='search'
                className="form-control"
                value={value || ''}
                onChange={(e) => {
                    setValue(e.target.value)
                    onChange(e.target.value)
                }}
                placeholder='Search'
                style={{ height: 35 }}
            />
        </div>
    )
}

export default GlobalTableFilter