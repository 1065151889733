const TbodySekeleton = ({ table }) => {
	const pageSize = table.getState().pagination.pageSize
	const headerGroups = table.getHeaderGroups()[0].headers

	return (
		<tbody className='animate-pulse'>
			{Array(pageSize)
				.fill(0)
				.map((headerGroup, idx) => {
					return (
						<tr key={idx}>
							{headerGroups.map((header, idx) => {
								return (
									<td
										key={idx}
										style={{ width: `${header.getSize()}px` }}
										className='h-[48px] w-full'>
										<span className='block !h-[20px] w-full rounded bg-slate-200'></span>
									</td>
								)
							})}
						</tr>
					)
				})}
		</tbody>
	)
}

export default TbodySekeleton
