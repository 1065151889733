import { createContext, useContext, useState } from 'react'

const SignatureDocumentStatusContext = createContext()
const SignatureDocumentStatusUpdateContext = createContext()

export const useSignatureDocumentStatus = () => {
	const isDocumentSaved = useContext(SignatureDocumentStatusContext)
	const setIsDocumentSaved = useContext(SignatureDocumentStatusUpdateContext)
	return [isDocumentSaved, setIsDocumentSaved]
}

export const SignatureDocumentProvider = ({ children }) => {
	const [isDocumentSaved, setIsDocumentSaved] = useState(false)

	const onStatusChange = (value) => {
		setIsDocumentSaved(value)
	}

	return (
		<SignatureDocumentStatusContext.Provider value={isDocumentSaved}>
			<SignatureDocumentStatusUpdateContext.Provider value={onStatusChange}>
				{children}
			</SignatureDocumentStatusUpdateContext.Provider>
		</SignatureDocumentStatusContext.Provider>
	)
}
