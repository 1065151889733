export const disabledElements = [
	'toolbarGroup-Shapes',
	'toolbarGroup-Edit',
	'toolbarGroup-Insert',
	'toolbarGroup-Annotate',
	'toolbarGroup-Forms',
	'notesPanel',
	'viewControlsButton',
	'selectToolButton',
	'toggleNotesButton',
	'searchButton',
	'freeTextToolGroupButton',
	'crossStampToolButton',
	'checkStampToolButton',
	'dotStampToolButton',
	'rubberStampToolGroupButton',
	'dateFreeTextToolButton',
	'eraserToolButton',
	'panToolButton',
	'signatureToolGroupButton',
	'viewControlsOverlay',
	'toolbarGroup-View',
	'leftPanelButton',
	'menuButton',
	'stampToolGroupButton',
	'calloutToolGroupButton',
	'fileAttachmentToolGroupButton',
	'highlightToolGroupButton',
	'underlineToolGroupButton',
	'strikeoutToolGroupButton',
	'squigglyToolGroupButton',
	'stickyToolGroupButton',
	'markInsertTextGroupButton',
	'markReplaceTextGroupButton',
	'shapeToolGroupButton',
	'freeHandToolGroupButton',
	'freeHandHighlightToolGroupButton',
	'annotationPopup',
	'defaultSignatureDeleteButton',
]
