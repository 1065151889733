import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useDebounce } from 'use-debounce'

import { useLazyGetQueryDataQuery } from 'modules/clients/client-apis'
import { CLIENT_API_ROUTES } from 'modules/clients/routes'
import { toQueryString } from 'utils/commonFunctions'

const useDocumentViewQuery = (pagination, client) => {
	const values = useSelector((state) => state.form?.document_view_filter?.values)
	const [debounceSearch] = useDebounce(values?.search, 600)
	const { pageIndex, pageSize } = pagination

	const filters = useMemo(() => {
		const obj = { page: pageIndex + 1, limit: pageSize }
		if (debounceSearch) {
			Object.assign(obj, { search: debounceSearch })
		}

		return obj
	}, [values, debounceSearch, pageIndex, pageSize])

	const query = toQueryString(filters)

	const URL = useMemo(
		() =>
			client?.id && query
				? `${CLIENT_API_ROUTES.findAllDocuments(client?.id)}?${query}`
				: undefined,
		[client?.id, query],
	)
	const [fetchDocuments, { data, isLoading }] = useLazyGetQueryDataQuery()

	useEffect(() => {
		if (URL) {
			fetchDocuments(URL)
		}
	}, [URL])

	const documentData = data?.data?.documents

	const documents = useMemo(
		() =>
			Array.isArray(documentData)
				? documentData.map((document, idx) => ({
						id: idx + 1 + pageIndex * pageSize,
						document_name: document?.title,
				  }))
				: [],
		[documentData],
	)

	return {
		documents: documents || [],
		meta: data?.data?.meta,
		isLoading,
	}
}

export default useDocumentViewQuery
