import { useDropzone } from 'react-dropzone'

import dropzoneIcon from 'assets/images/onboarding/dropzone-icon.png'
import { useCallback } from 'react'
import { formatBytes } from 'utils/commonFunctions'

const Dropzone = ({ onDrop, className, accept = [], maxFiles, maxSize, multiple }) => {
	const validate = useCallback(
		(file) => {
			let errors = []

			if (accept.length > 0 && !accept.includes(file.type)) {
				errors.push({ code: 'file-type-denied', message: 'File type is not allowed.' })
			}

			if (typeof maxSize !== 'undefined' && file.size > maxSize * 1024 * 1024) {
				errors.push({
					code: 'file-size-exceed',
					message: `File size should not exceed ${maxSize} mb.`,
				})
			}

			return errors.length === 0 ? null : errors
		},
		[accept, maxSize],
	)

	const { getRootProps, getInputProps, isDragAccept, isFocused, fileRejections } = useDropzone({
		onDrop,
		maxFiles: maxFiles,
		multiple: multiple,
		validator: validate,
	})

	return (
		<div className=''>
			<div
				{...getRootProps()}
				className={`rounded-md border-2 border-dashed ${
					isFocused ? 'border-blue-600' : 'border-gray-300'
				} mb-2 flex items-center justify-center ${className}`}>
				<input {...getInputProps()} />

				{isDragAccept ? (
					<p>Drop the files here ...</p>
				) : (
					<div className='flex flex-col items-center justify-center'>
						<img src={dropzoneIcon} alt='' className='h-10 w-10' />
						<p className='m-0 font-semibold text-gray-400'>
							Drag or drop your file here or choose
						</p>
					</div>
				)}
			</div>

			{fileRejections.length > 0 ? (
				<div className='border-1 rounded-md border-red-500 bg-red-200 p-3 text-red-700'>
					{fileRejections.map(({ file, errors }) => (
						<li key={file.path}>
							<span className='font-semibold'>
								{file.path} - {formatBytes(file.size)} bytes
							</span>
							<ul className='list-disc text-xs'>
								{errors.map((e, idx) => (
									<li key={idx}>{e.message}</li>
								))}
							</ul>
						</li>
					))}
				</div>
			) : undefined}
		</div>
	)
}

export default Dropzone
