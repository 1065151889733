function formatPerm(value) {
	return value
}

export const permissions = {
	note: {
		list: formatPerm('note:list', 'List notes'),
		create: formatPerm('note:create', 'Create notes'),
		edit: formatPerm('note:edit', 'Edit notes'),
	},
	client: {
		list: formatPerm('client:list', 'List clients'),
		view: formatPerm('client:view', 'View clients'),
		delete: formatPerm('client:delete', 'Delete clients'),
		invitedList: formatPerm('client:invitedList', 'List invited clients'),
		create: formatPerm('client:create', 'Invite clients'),
		import: formatPerm('client:import', 'Import clients'),
		verify: formatPerm('client:verify', 'Verify clients'),
		editInvited: formatPerm('client:editInvited', 'Edit Invited client'),
	},
	documentTemplate: {
		list: formatPerm('documentTemplate:list', 'List document templates'),
		create: formatPerm('documentTemplate:create', 'Create document templates'),
		edit: formatPerm('documentTemplate:edit', 'Edit document templates'),
		delete: formatPerm('documentTemplate:delete', 'Delete document templates'),
		deletedList: formatPerm('documentTemplate:deletedList', 'List Deleted document templates'),
		restore: formatPerm('documentTemplate:restore', 'Restore document templates'),
	},
	documentPackage: {
		list: formatPerm('documentPackage:list', 'List document packages'),
		create: formatPerm('documentPackage:create', 'Create document packages'),
		edit: formatPerm('documentPackage:edit', 'Edit document packages'),
		delete: formatPerm('documentPackage:delete', 'Delete document packages'),
	},
	completedDocument: {
		list: formatPerm('completedDocument:list', 'List completed documents'),
		view: formatPerm('completedDocument:view', 'View completed documents'),
		download: formatPerm('completedDocument:download', 'Download completed documents'),
	},
	workflowTemplate: {
		list: formatPerm('workflowTemplate:list', 'List workflow templates'),
		create: formatPerm('workflowTemplate:create', 'Create workflow templates'),
		edit: formatPerm('workflowTemplate:edit', 'Edit workflow templates'),
		assign: formatPerm('workflowTemplate:assign', 'Assign workflow templates'),
		delete: formatPerm('workflowTemplate:delete', 'Delete workflow templates'),
	},
	workflowOutstandings: {
		list: formatPerm('workflowOutstandings:list', 'List outstanding workflows'),
		view: formatPerm('workflowOutstandings:view', 'Review outstanding workflows'),
	},
	workflowFinalReview: {
		list: formatPerm('workflowFinalReview:list', 'List workflows for final review'),
		view: formatPerm('workflowFinalReview:view', 'View workflows for final review'),
	},
	assignedWorkflow: {
		list: formatPerm('assignedWorkflow:list', 'List assigned workflows'),
		fill: formatPerm('assignedWorkflow:fill', 'Fill assigned workflows'),
	},
	task: {
		list: formatPerm('task:list', 'List tasks'),
		create: formatPerm('task:create', 'Create tasks'),
		edit: formatPerm('task:edit', 'Edit tasks'),
	},
	teamMember: {
		list: formatPerm('teamMember:list', 'List team members'),
		inviteList: formatPerm('teamMember:inviteList', 'List invites'),
		invite: formatPerm('teamMember:invite', 'Invite team members'),
	},
	profileDetails: {
		edit: formatPerm('profileDetails:edit', 'Edit profile'),
		list: formatPerm('profileDetails:list', 'Profile details'),
	},
	workflowFiles: {
		list: formatPerm('workflowFiles:list', 'List workflow'),
		download: formatPerm('workflowFiles:download', 'Download workflow'),
	},
	mySubscription: {
		list: formatPerm('mySubscription:list', 'List subscriptions'),
		view: formatPerm('mySubscription:view', 'View subscription'),
	},
	groups: {
		list: formatPerm('groups:list', 'List groups'),
		delete: formatPerm('groups:delete', 'Delete groups'),
		create: formatPerm('groups:create', 'Create groups'),
		edit: formatPerm('groups:edit', 'Edit groups'),
	},
	users: {
		list: formatPerm('users:list', 'List users'),
		delete: formatPerm('users:delete', 'Delete users'),
		create: formatPerm('users:create', 'Create users'),
		edit: formatPerm('users:edit', 'Edit users'),
	},
	teams: {
		list: formatPerm('teams:list', 'List teams'),
		view: formatPerm('teams:view', 'View teams'),
		assignSubscription: formatPerm('assignSubscription:view', 'Assign Subscription'),
	},
	payments: {
		list: formatPerm('payments:list', 'List payments'),
		view: formatPerm('payments:view', 'View payments'),
	},
	pendingPayments: {
		list: formatPerm('pendingPayments:list', 'List pendingPayments'),
		edit: formatPerm('pendingPayments:edit', 'Edit pendingPayments'),
	},
	subscriptions: {
		list: formatPerm('subscriptions:list', 'List subscriptions'),
		edit: formatPerm('subscriptions:edit', 'Edit subscriptions'),
		view: formatPerm('subscriptions:edit', 'View subscriptions'),
		create: formatPerm('subscriptions:edit', 'Create subscriptions'),
		delete: formatPerm('subscriptions:edit', 'Delete subscriptions'),
	},
	features: {
		list: formatPerm('features:list', 'List features'),
		edit: formatPerm('features:edit', 'Edit features'),
		view: formatPerm('features:edit', 'View features'),
		create: formatPerm('features:edit', 'Create features'),
		delete: formatPerm('features:edit', 'Delete features'),
	},
	systemFields: {
		list: formatPerm('systemFields:list', 'List systemFields'),
		edit: formatPerm('systemFields:edit', 'Edit systemFields'),
		view: formatPerm('systemFields:edit', 'View systemFields'),
		create: formatPerm('systemFields:edit', 'Create systemFields'),
		delete: formatPerm('systemFields:edit', 'Delete systemFields'),
	},
	systemSettings: {
		list: formatPerm('systemSettings:list', 'List systemSettings'),
		edit: formatPerm('systemSettings:edit', 'Edit systemSettings'),
	},
	announcements: {
		list: formatPerm('announcements:list', 'List announcements'),
		edit: formatPerm('announcements:edit', 'Edit announcements'),
		view: formatPerm('announcements:edit', 'View announcements'),
		create: formatPerm('announcements:edit', 'Create announcements'),
		delete: formatPerm('announcements:edit', 'Delete announcements'),
	},
	emailTemplates: {
		list: formatPerm('emailTemplates:list', 'List emailTemplates'),
		edit: formatPerm('emailTemplates:edit', 'Edit emailTemplates'),
		view: formatPerm('emailTemplates:edit', 'View emailTemplates'),
	},
	notificationTemplates: {
		list: formatPerm('notificationTemplates:list', 'List notificationTemplates'),
		edit: formatPerm('notificationTemplates:edit', 'Edit notificationTemplates'),
	},
}
