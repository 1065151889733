import { useLazyGetOneWorkflowTemplateQuery } from 'modules/workflows/workflow-template/workflow-template-apis'
import WorkflowsLinksContainer from 'modules/workflows/workflow-template/workflows-links-list/workflows-links-container'
import { useEffect, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const WorkflowLinksListing = () => {
	const params = useParams()
	const { id } = params

	const [fetchWorkflow, { data }] = useLazyGetOneWorkflowTemplateQuery()
	useEffect(() => {
		if (id) {
			fetchWorkflow(id)
		}
	}, [id])

	const workFlowName = useMemo(() => data?.workflow?.title, [data?.workflow?.title])

	return (
		<AppLayout
			meta={{
				title: `Workflow Links - ${workFlowName}`,
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<PageHeader
						title={`Workflow Links - ${workFlowName}`}
						description='The most important feature in the workflow section is the creating one. When assigning documents/packages here,
do not ignore to fill in all the required fields completely and follow the document/package adding rules.'
						hasBackButton={true}
						redirectTo='/workflows'>
						<div>
							<Link
								to={`/workflows/${id}/links/create`}
								className='!mt-5 h-fit min-w-max rounded bg-main px-3 py-2 text-white'>
								Create new
							</Link>
						</div>
					</PageHeader>
					<div className='page-body'>
						<WorkflowsLinksContainer />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default WorkflowLinksListing
