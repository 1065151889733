import classNames from 'classnames'
import { Fragment } from 'react'

const Message = ({ variant, children, className, onClose, isDismissible = true }) => {
	return (
		<Fragment>
			<div
				className={classNames('alert fade show mb-3', className, {
					'alert-info': variant === 'info',
					'alert-primary': variant === 'primary',
					'alert-success': variant === 'success',
					'alert-danger': variant === 'danger',
					'alert-warning': variant === 'warning',
					'alert-secondary': variant === 'secondary',
					'alert-dismissible': isDismissible,
				})}
				role='alert'>
				{children}
				{isDismissible && (
					<button
						type='button'
						className='btn-close'
						onClick={onClose}
						aria-label='Close'></button>
				)}
			</div>
		</Fragment>
	)
}

export default Message
