import classNames from 'classnames'
import { SpinLoader } from 'components/Common'
import { Field } from 'formik'
import { useLazyGetFeaturesQuery } from 'modules/subscriptions/features/features-api'
import { FEATURES_APP_ROUTES } from 'modules/subscriptions/routes'
import { useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { InputField } from 'theme/ui/forms'

const FeaturesListField = ({ formik }) => {
	const [fetchFeatures, { data, isFetching }] = useLazyGetFeaturesQuery()
	useEffect(() => {
		fetchFeatures({ page: 1, limit: 20 })
	}, [])

	const formValues = useMemo(() => formik?.values, [formik?.values])
	const features = useMemo(() => data?.features || [], [data?.features])
	const selectedFeatures = useMemo(() => formValues?.features || [], [formValues?.features])

	return (
		<div className='flex flex-col space-y-3'>
			<Field
				name='users_allowed'
				type='number'
				label='Number of users allowed'
				placeholder='0'
				component={InputField}
				isRequiredField
				helperText='The maximum number of users permitted to join your team.'
			/>

			{isFetching ? (
				<SpinLoader />
			) : (
				<>
					{features.length > 0 ? (
						<div className='flex flex-col space-y-1'>
							<label className='flex items-baseline justify-between text-[14px] font-bold text-[#495057]'>
								<span>
									Assign Features <span className='text-red-500'>*</span>
								</span>
							</label>

							<div className='custom-scroll flex max-h-96 snap-start flex-col divide-y overflow-auto rounded !border shadow-inner'>
								{features.map((feature, idx) => (
									<label
										key={idx}
										htmlFor={`feature_${idx}`}
										className={classNames(
											'm-0 flex w-full space-x-2 !px-5 py-2 transition-all hover:cursor-pointer hover:bg-main/10 peer-checked/feature:bg-main/20',
										)}>
										<Field
											id={`feature_${idx}`}
											name='features'
											type='checkbox'
											value={feature.id.toString()}
											className='peer/feature form-check-input'
										/>
										<div className='flex flex-col'>
											<span className='text-sm font-bold text-gray-800'>
												{feature?.name}
											</span>
											<span className='font-normal text-gray-400'>
												{feature?.description}
											</span>
										</div>
									</label>
								))}
							</div>

							<div className='flex items-center justify-between px-1 py-2 text-[13px] italic text-gray-400'>
								<span>Selected Features: {selectedFeatures.length}</span>
								<span>Total Features: {features.length}</span>
							</div>
						</div>
					) : (
						<div className='p-2'>
							<span className='text-gray-400'>
								There is feature available in the system{' '}
								<Link
									to={FEATURES_APP_ROUTES.create()}
									target='_blank'
									className='hover:unded font-bold
								'>
									click here
								</Link>{' '}
								to add.
							</span>
						</div>
					)}
				</>
			)}
		</div>
	)
}

export default FeaturesListField
