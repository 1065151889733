import ActiveSubscription from './active-subscription'

export function dateDifferenceInDays(start_date, end_date) {
	const d1 = new Date(start_date)
	const d2 = new Date(end_date)

	if (d1 > d2) {
		throw new Error('start_date should not be greater than end_date')
	}

	const timeDifference = d2 - d1
	const differenceInDays = timeDifference / (1000 * 3600 * 24)

	return Math.abs(differenceInDays)
}

export const getSubscriptionDays = (duration) => {
	switch (duration) {
		case 'annual':
			return 365

		default:
			return 30
	}
}

export const getRemainingDays = (subscription) => {
	try {
		const subscriptionDays = getSubscriptionDays(subscription?.plan?.duration)
		const remainingDays = dateDifferenceInDays(subscription?.start_date, subscription?.end_date)

		return {
			subscriptionDays: subscriptionDays,
			remainingDays: Math.floor(remainingDays) || 0,
			remainingPercentage: Math.floor((remainingDays / subscriptionDays) * 100),
		}
	} catch (error) {
		return {
			subscriptionDays: 0,
			remainingDays: 0,
			remainingPercentage: 0,
		}
	}
}

export { default as CurrentSubscription } from './current-subscription'
export { default as PaymentsDetails } from './payments-details'
export { default as UsageSubscription } from './usage-subscription'

export default ActiveSubscription
