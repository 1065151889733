import { ReactComponent as DocumentIcon } from 'assets/svg/workflow-doc-icon.svg'

const FileCard = ({ file }) => {
	return (
		<div className='bg-white !p-3 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]'>
			<div className='flex items-center space-x-3'>
				<span>
					<DocumentIcon />
				</span>
				<div className='flex flex-col space-y-1'>
					<h4 className='m-0 text-sm font-bold'>{file?.file}</h4>
					<p className='m-0 text-sm text-gray-500'>
						{file?.description || 'To confirm your identity'}
					</p>
				</div>
			</div>
		</div>
	)
}

export default FileCard
