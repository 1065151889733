import classNames from 'classnames'
import RolesDropdown from './roles-dropdown'

export const createRoleOption = (role) => ({
	label: role?.name,
	value: role?.id,
	slug: role?.slug,
})

export const createOptionLabel = (option, isDisabled) => {
	return (
		<span
			className={classNames('font-bold text-gray-900', {
				'disabled:opacity-50': isDisabled,
			})}>
			{option?.label}
		</span>
	)
}

export default RolesDropdown
