/* eslint-disable react-hooks/exhaustive-deps */

import { Fragment, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'

import logo from 'assets/images/experivise-logo-white.png'
import bg1 from 'assets/images/onboarding/bg1.png'
import { useAppRouter } from 'hooks'
import { useGetWorkflowAssignPayloadMutation } from 'modules/assigned-workflows/assigned-workflow-apis'
import { clear, saveEmail } from 'store/client-onboarding/actions'
import notification from 'utilities/notification'
import { useQuery } from 'utils/commonFunctions'
import { getEmail } from '..'
import { useEnrollClientMutation, useSendEmailOtpMutation } from '../clien-onboarding'
import StepForm from './StepForm'

const StepOne = (props) => {
	const { navigate, parseQuery } = useAppRouter()
	const { token } = useQuery(props.location.search)
	const $continue = parseQuery?.continue

	const workflowUUID = useMemo(() => {
		if ($continue) {
			const pathnames = $continue?.split('/')
			const lastIndex = pathnames?.length - 1
			return pathnames[lastIndex] || null
		}
		return null
	}, [$continue])

	const [enrollClient] = useEnrollClientMutation()
	const [sendEmailOtp] = useSendEmailOtpMutation()
	const [fetchPayload] = useGetWorkflowAssignPayloadMutation()

	const email = getEmail(token)
	const dispatch = useDispatch()
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		dispatch(clear())
	}, [])

	const onSubmit = async (values) => {
		setIsLoading(true)

		const response = await sendEmailOtp({ email: values?.email })
		const reduxStore = {
			email: values?.email,
		}
		if (response?.data?.status === 200) {
			if (workflowUUID) {
				const $response = await fetchPayload(workflowUUID)
				const payload = $response?.data?.data?.data

				const invitePayload = {
					first_name: values?.first_name,
					last_name: values?.last_name,
					email: values?.email,
					user: payload?.reqUser,
					team: payload?.team,
					group: payload?.groupData,
					group_id: payload?.groupData?.id,
				}

				await enrollClient(invitePayload)

				Object.assign(reduxStore, {
					first_name: values?.first_name,
					last_name: values?.last_name,
					group_id: payload?.groupData?.id,
					team_name: payload?.team?.team_name,
				})
			}

			notification('success', response?.message)

			dispatch(saveEmail(reduxStore)).then((res) => {
				if (res) {
					if (token) {
						navigate(`/onboarding/step-two?token=${token}`)
					} else if ($continue) {
						navigate(`/onboarding/step-two?continue=${$continue}`)
					}
				}
			})
		}
		setIsLoading(false)
	}

	return (
		<Fragment>
			<Container className='onboarding-container overflow-hidden' fluid>
				<Row>
					<Col sm={7}>
						<div className='flex-column flex min-h-screen space-y-14'>
							<div className='flex items-center justify-between px-4'>
								<img
									src={logo}
									alt='Experivise'
									className='img-fluid onboarding-logo1'
									style={{ height: 90 }}
								/>
							</div>

							<StepForm
								isReadOnly={typeof email === 'string'}
								initialValues={{
									email,
								}}
								workflowUUID={workflowUUID}
								isLoading={isLoading}
								onSubmit={onSubmit}
							/>
						</div>
					</Col>
					<Col
						sm={5}
						style={{ backgroundImage: 'url(' + bg1 + ')' }}
						className='bg-cover'>
						<div className='px-11 py-28'>
							<h2 className='text-[27px] font-bold'>
								Filling forms is more than easy now
							</h2>
							<p className='text-[#74788D]'>
								Filling forms is more than easy now, only fill fields that are
								relevant for you. Advisor will assign those workflows to you and you
								can fill them at your own pace through experivise.
							</p>
						</div>
					</Col>
				</Row>
			</Container>
		</Fragment>
	)
}

export default StepOne
