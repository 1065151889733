import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useDebounce } from 'use-debounce'

import { toQueryString } from 'utils/commonFunctions'
import { useLazyGetNotesQuery } from './client-notes-api'

const useNoteViewQuery = (pagination, client) => {
	const values = useSelector((state) => state.form?.note_view_filter?.values)
	const [debounceSearch] = useDebounce(values?.search, 600)
	const { pageIndex, pageSize } = pagination

	const filters = useMemo(() => {
		const obj = { page: pageIndex + 1, limit: pageSize }
		if (debounceSearch) {
			Object.assign(obj, { search: debounceSearch })
		}

		return obj
	}, [values, debounceSearch, pageIndex, pageSize])

	const query = toQueryString(filters)

	const [fetchNotes, { data, isLoading }] = useLazyGetNotesQuery()

	useEffect(() => {
		if (client?.id && query) {
			fetchNotes({ id: client?.id, query: query })
		}
	}, [client?.id, query])

	const responseData = data?.notes

	const response = useMemo(
		() =>
			Array.isArray(responseData)
				? responseData.map((note, idx) => ({
						index: idx + 1 + pageIndex * pageSize,
						id: note?.id,
						note: note?.notes,
						sharing: note?.visibility ? 'Public' : 'Private',
				  }))
				: [],
		[responseData],
	)

	return {
		data: response || [],
		meta: data?.meta,
		isLoading,
	}
}

export default useNoteViewQuery
