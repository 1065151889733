import { Dialog, Transition } from '@headlessui/react'
import { Field, FormikProvider, useFormik } from 'formik'
import { Fragment } from 'react'

import { useUpdateDocumentMutation } from 'modules/documents/document-api'
import { Button, InputField } from 'theme/ui/forms'
import notification from 'utilities/notification'

const RenamePopup = ({ isOpen, onClose, document }) => {
	const [updateDocument] = useUpdateDocumentMutation()

	const formik = useFormik({
		initialValues: {
			title: document?.title,
			status: document?.status,
		},
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true)
				const response = await updateDocument({
					id: document?.id,
					payload: values,
				}).unwrap()
				console.log('response', response)
				if (response?.status === 200) {
					onClose()
					setSubmitting(false)
				}
			} catch (error) {
				setSubmitting(false)
				notification('error', error?.message)
			}
		},
	})

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-[1008]' onClose={() => {}}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/30' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'>
							<Dialog.Panel className='w-full max-w-lg transform overflow-hidden rounded-3xl bg-white p-2 text-left align-middle shadow-xl transition-all'>
								<div className='top-o relative flex justify-between rounded-3xl bg-blue-50 p-3'>
									<div></div>
									<div className='absolute  left-6 top-8 rounded-full !border !border-gray-300  bg-white p-2 '>
										<i className='uil-folder-plus p-2 text-[20px] font-bold'></i>
									</div>
									<button type='button' className='right-0' onClick={onClose}>
										<i className='fas fa-times rounded-full bg-white px-2 py-1 text-[20px]' />
									</button>
								</div>

								<FormikProvider value={formik}>
									<form onSubmit={formik.handleSubmit}>
										<div className='mt-10 flex flex-col '>
											<div className='ms-4 flex flex-col justify-start ps-2'>
												<Dialog.Title
													as='h2'
													className='mb-3 text-left text-lg font-bold leading-6 text-gray-900'>
													Rename
												</Dialog.Title>
												<div className='!pe-6'>
													<Field component={InputField} name='title' />
												</div>
											</div>
											<hr className='mx-4 my-3 bg-gray-300' />
											<div className='mb-3 ms-4 flex w-full justify-center gap-2 pe-5 ps-2'>
												<Button
													type='button'
													onClick={onClose}
													variant='ghost'>
													Cancel
												</Button>
												<Button
													type='submit'
													isLoading={formik.isSubmitting}
													isLoadingText='Saving...'>
													Save
												</Button>
											</div>
										</div>
									</form>
								</FormikProvider>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export default RenamePopup
