const InviteItemSkeleton = () => {
	return (
		<div className='flex animate-pulse items-center justify-between px-3 py-2.5'>
			<div className='flex flex-col space-y-1'>
				<span className='h-[20px] w-[180px] rounded bg-slate-200' />
				<div className='flex items-center space-x-2'>
					<span className='h-[16px] w-[35px] rounded bg-slate-200' />
					<span className='h-1 w-1 rounded-full bg-gray-900' />
					<span className='h-[16px] w-[121px] rounded bg-slate-200' />
				</div>
			</div>

			<span className='h-[31px] w-[70px] rounded bg-slate-200' />
		</div>
	)
}

export default InviteItemSkeleton
