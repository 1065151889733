import { CompletedDocumentsContainer } from 'modules/documents'
import Header from 'modules/documents/header'
import AppLayout from 'theme/layouts/app-layout'

const CompletedDocumentsListing = () => {
	return (
		<AppLayout
			meta={{
				title: 'Completed Documents',
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<Header />
					<div className='page-body'>
						<CompletedDocumentsContainer />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default CompletedDocumentsListing
