import { Link } from 'react-router-dom'

import FeatureListingContainer from 'modules/subscriptions/features/feature-listing'
import { FEATURES_APP_ROUTES } from 'modules/subscriptions/routes'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const FeatureListing = () => {
	return (
		<AppLayout
			meta={{
				title: 'Features',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<PageHeader
						title='Features'
						description='Easily manage your Features hassle-free from one convenient dashboard. Stay in control of your spending, effortlessly track renewal dates, and make changes with just a few clicks, all in one centralized hub designed for your convenience'>
						<Link to={FEATURES_APP_ROUTES.create()} className='btn btn-primary'>
							Create new
						</Link>
					</PageHeader>
					<div className='page-body'>
						<FeatureListingContainer />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default FeatureListing
