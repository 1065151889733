import { FileManagerLayout, MyDocumentsListingContainer } from 'modules/file-manager'

const MyDocuments = () => {
	return (
		<FileManagerLayout
			meta={{
				title: 'File Manager - My Documents',
			}}>
			<div>
				<MyDocumentsListingContainer />
			</div>
		</FileManagerLayout>
	)
}

export default MyDocuments
