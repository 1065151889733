/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

import lightBulb from 'assets/images/onboarding/light-bulb.png'
import apiEndpoints from 'helpers/apiEndpoints'
import UserQuery from 'hooks/UserQuery'
import { snakeCase } from 'lodash'
import { mutate } from 'swr'
import Layout from '../components/Layout'
import withSteps from '../components/withSteps'
import Form from './Form'
import { socket } from './socket'

const TakeSelfie = (props) => {
	const step = props?.step
	// eslint-disable-next-line no-unused-vars
	const [isConnected, setIsConnected] = useState(socket.connected)
	const { user } = UserQuery()
	const connectNow = () => socket.connect()

	useEffect(() => {
		connectNow()
	}, [])

	const EVENT_ID = snakeCase(user?.email)

	useEffect(() => {
		if (EVENT_ID) {
			const onConnect = () => setIsConnected(true)
			const onDisconnect = () => setIsConnected(false)

			socket.on('connect', onConnect)
			socket.on('disconnect', onDisconnect)

			socket.on(EVENT_ID, () => {
				mutate(apiEndpoints.userOnBoard.stepStatus)
			})

			return () => {
				socket.off('connect', onConnect)
				socket.off('disconnect', onDisconnect)
			}
		}
	}, [EVENT_ID])

	const helperItems = [
		{
			title: 'Why do we need your selfie?',
			description:
				'We need to verify your face ID through the documents given by you with live selfie.',
			icon: <img src={lightBulb} alt='' className='h-6 w-6' />,
		},
	]
	return (
		<Layout helperItems={helperItems}>
			<div className='grid grid-cols-12'>
				<div className='col-span-1' />
				<div className='col-span-9 flex flex-col space-y-9 px-2'>
					<div>
						<h3 className='text-xl font-bold'>Take a selfie</h3>
						<p className='text-[#74788D]'>
							Make it easy for us to recognize you by taking a selfie
						</p>
						{step?.reason ? (
							<p className='text-red-700'>
								<strong>Note:</strong> &nbsp;{step?.reason}
							</p>
						) : undefined}
					</div>
					<Form />
				</div>
			</div>
		</Layout>
	)
}

export default withSteps(TakeSelfie)
