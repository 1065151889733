import { createApi } from '@reduxjs/toolkit/query/react'
import apiEndpoints from 'helpers/apiEndpoints'
import moment from 'moment'
import axiosBaseQuery from 'store/axios-base-query'

export const deletedDocumentApi = createApi({
	reducerPath: 'DeletedDocuments',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['DeletedDocuments'],
	endpoints: (builder) => ({
		getDeletedDocumentTemplates: builder.query({
			keepUnusedDataFor: 0,
			query: ({ params, url }) => ({
				url: url,
				method: 'GET',
				params: params,
			}),
			providesTags: ['DeletedDocuments'],
			transformResponse: (response, _, arg) => {
				const documents = Array.isArray(response?.data?.documents)
					? response?.data?.documents.map((document, idx) => ({
							index:
								parseInt(arg?.params?.limit) * (parseInt(arg?.params?.page) - 1) +
								idx +
								1,
							id: document?.id,
							title: document?.title,
							deleted_at: moment(document?.deleted_at).format('ll'),
					  }))
					: []
				return {
					documents,
					meta: response?.data?.meta,
				}
			},
		}),
		restoreDeletedDocument: builder.mutation({
			query: (id) => ({
				url: `${apiEndpoints.documentRestore}/${id}`,
				method: 'GET',
			}),
			invalidatesTags: ['DeletedDocuments'],
		}),
	}),
})

export const { useLazyGetDeletedDocumentTemplatesQuery, useRestoreDeletedDocumentMutation } =
	deletedDocumentApi
