import { Header, WorkflowOutstandings } from 'modules/workflows'
import AppLayout from 'theme/layouts/app-layout'
import { TableProvider } from 'theme/ui/data-table'

const WorkflowsOutstandingsListing = () => {
	return (
		<AppLayout
			meta={{
				title: 'Workflow Outstandings',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<div className='page-header bg-white'>
						<Header />
					</div>
					<div className='page-body'>
						<TableProvider>
							<WorkflowOutstandings />
						</TableProvider>
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default WorkflowsOutstandingsListing
