import { Popover, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import Badge from '../badge'

const PopoverButtons = ({ data, title, onClick }) => {
	return (
		<div className=''>
			<Popover className='relative !max-w-[200px]'>
				<Popover.Button className=''>
					<Badge variant='primary' className={'cursor-pointer'}>
						<span>+{data?.length - 1}</span>
					</Badge>
				</Popover.Button>
				<Transition
					as={Fragment}
					enter='transition ease-out duration-200'
					enterFrom='opacity-0 translate-y-1'
					enterTo='opacity-100 translate-y-0'
					leave='transition ease-in duration-150'
					leaveFrom='opacity-100 translate-y-0'
					leaveTo='opacity-0 translate-y-1'>
					<Popover.Panel className='absolute left-[6.5rem] top-0 z-10 w-[200px] -translate-x-1/2 transform px-4 sm:px-0'>
						<div className='overflow-hidden rounded-md bg-white shadow-md ring-1 ring-black/5'>
							<h6 className='mx-3 my-2'>{title}</h6>
							<div className='relative flex flex-col divide-y bg-white'>
								{data.map((item, i) => (
									<div key={i} className='px-3 py-2'>
										<p
											className='!m-0 cursor-pointer text-xs font-semibold text-main'
											onClick={() => onClick(item)}>
											{item}
										</p>
									</div>
								))}
							</div>
						</div>
					</Popover.Panel>
				</Transition>
			</Popover>
		</div>
	)
}

export default PopoverButtons
