import { TextField } from 'components/Forms/TextFieldComponent'
import { snakeCase } from 'lodash'
import { useMemo } from 'react'

import { Field } from 'redux-form'
import { findObjectByField } from 'utils/commonFunctions'
import K from 'utils/constants'
import { getReviewFileTabs } from '../helpers'
import FileCard from './file-card'

const UploadedFiles = ({ uploads, status }) => {
	const tabs = useMemo(() => getReviewFileTabs(uploads), [uploads])
	const step = useMemo(() => uploads[0], [uploads])
	const tab = useMemo(() => findObjectByField(tabs, 'value', step?.client_type), [tabs, step])
	const files = useMemo(() => step?.files || [], [step])
	const isDisabled = useMemo(() => {
		return [
			K.STATUS.FINAL_REVIEW,
			K.STATUS.FINAL_REVIEW_APPROVED,
			K.STATUS.GATHERING_SIGNATURE,
		].includes(snakeCase(status))
	}, [status])

	return (
		<div className='mt-[130px]'>
			<div className='mb-3 bg-white px-4 py-[11px] text-sm font-bold'>
				<div>
					<span>Files</span>
					<span className='ml-3 rounded-md bg-main p-1 text-[10px] text-white'>
						{files.length.toLocaleString('en-US', {
							minimumIntegerDigits: 2,
							useGrouping: false,
						})}
					</span>
				</div>
			</div>

			<div className='main-wrapper'>
				<div className='mb-3 flex flex-col space-y-3'>
					{files.length > 0 ? (
						files.map((file, idx) => (
							<FileCard file={file} key={idx} index={idx} tab={tab} />
						))
					) : (
						<p className='text-center text-sm text-gray-500'>
							No file was supposed to upload in this workflow.
						</p>
					)}
				</div>

				{files.length > 0 ? (
					<Field
						label='Add a note about the review for the client'
						type='textarea'
						name={`files_notes.${tab?.value}`}
						component={TextField}
						rows={3}
					/>
				) : undefined}
			</div>

			<div className='flex justify-end'>
				<button type='submit' className='btn-primary-1' disabled={isDisabled}>
					Save
				</button>
			</div>
		</div>
	)
}

export default UploadedFiles
