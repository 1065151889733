const Heading = ({ open, title, updatesCount }) => {
	return (
		<div className='flex w-full items-center justify-between'>
			<div className='flex items-center space-x-4'>
				<i className='uil-bars' />
				<span className='font-ibmplex text-lg font-bold'>{title}</span>
			</div>

			<div className='flex items-center space-x-4'>
				{updatesCount ? (
					<div className='flex h-6 w-[136px] items-center justify-center space-x-1 rounded !border border-main text-xs text-main'>
						<span className='!h-[6px] !w-[6px] rounded-full bg-main' />
						<span>{updatesCount} Update Available</span>
					</div>
				) : undefined}

				<div
					className=''
					style={{
						fontSize: 28,
					}}>
					{open ? <i className='uil-angle-down' /> : <i className='uil-angle-up' />}
				</div>
			</div>
		</div>
	)
}

export default Heading
