import { Fragment, useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { mutate } from 'swr'

import { Loader } from 'components/Common'
import useCountryOptions from 'components/Forms/CountryDropdown/useCountryOptions'
import apiEndpoints from 'helpers/apiEndpoints'
import { store } from 'store/common/actions'
import { showSuccessToast } from 'utils/commonFunctions'
import Form from './Form'

const FormWrapper = ({ user, step }) => {
	const [isLoading, setIsLoading] = useState(false)
	const dispatch = useDispatch()
	const countryOptions = useCountryOptions()
	const profile = step?.user?.userProfile

	const initialCountryOption = useMemo(
		() => countryOptions.find((country) => country.value === user?.userProfile?.country),
		[countryOptions, profile],
	)

	const initialValues = useMemo(
		() => ({
			first_name: user?.first_name,
			last_name: user?.last_name,
			...profile,
			country: initialCountryOption,
			address1: user?.userProfile?.address_1,
			address2: user?.userProfile?.address_2,
			unit: user?.userProfile?.unit,
			city: user?.userProfile?.city,
			zip_code: user?.userProfile?.zip_code,
			province: user?.userProfile?.province,
		}),
		[user, initialCountryOption],
	)

	const onSubmit = useCallback(
		(values) => {
			setIsLoading(true)
			const $values = { ...values }
			delete $values.id
			delete $values.created_at
			delete $values.updated_at
			delete $values.address_1
			delete $values.address_2

			const payload = {
				...$values,
				email: user?.email,
				phone_number: user?.phone_number,
				country: $values?.country?.value,
			}

			dispatch(store(apiEndpoints.userOnBoard.profile, payload)).then((response) => {
				setIsLoading(false)
				if (response?.status === 200) {
					showSuccessToast('Information has been saved.')
					mutate(apiEndpoints.userOnBoard.stepStatus)
				}
			})
		},
		[user],
	)

	return (
		<Fragment>
			{isLoading ? (
				<Loader />
			) : (
				<Form initialValues={initialValues} onSubmit={onSubmit} isLoading={isLoading} />
			)}
		</Fragment>
	)
}

export default FormWrapper
