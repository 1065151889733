import { useMemo } from 'react'
import { useDebounce } from 'use-debounce'

import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import { toQueryString } from 'utils/commonFunctions'
import { createWorkflowOption } from '.'

const WorkflowQuery = (search = '') => {
	const [debounceSearch] = useDebounce(search, 600)

	const queryParams = useMemo(() => {
		const params = { page: 1, limit: 10 }
		if (debounceSearch) {
			Object.assign(params, { search: debounceSearch })
		}
		return params
	}, [debounceSearch])

	const query = toQueryString(queryParams)

	const URL = useMemo(
		() =>
			query ? `${apiEndpoints.workflow_template}?${query}` : apiEndpoints.workflow_template,
		[query],
	)
	const { data, isLoading } = useQuery(URL)
	const workflows = Array.isArray(data?.data?.workflows)
		? data?.data?.workflows.map((workflow) => createWorkflowOption(workflow))
		: []

	return {
		workflows,
		isLoading,
	}
}

export default WorkflowQuery
