import lightBulb from 'assets/images/onboarding/light-bulb.png'
import UserQuery from 'hooks/UserQuery'
import Layout from '../components/Layout'
import withSteps from '../components/withSteps'
import FormWrapper from './FormWrapper'

const VerifyInformation = (props) => {
	const step = props?.step
	const { user } = UserQuery()

	const helperItems = [
		{
			title: 'Why do we need to verify the information?',
			description:
				"We match the information through the ID's given by you in order to Identify that it's really you.",
			icon: <img src={lightBulb} alt='' className='h-6 w-6' />,
		},
	]

	return (
		<Layout helperItems={helperItems}>
			<div className='grid grid-cols-12'>
				<div className='col-span-1' />
				<div className='col-span-9 px-2 pb-6'>
					<h3 className='text-xl font-bold'>Verify the information</h3>
					{step?.reason ? (
						<p className='text-red-700'>
							<strong>Note:</strong> &nbsp;{step?.reason}
						</p>
					) : undefined}
					<FormWrapper user={user} step={step} />
				</div>
			</div>
		</Layout>
	)
}

export default withSteps(VerifyInformation)
