/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog, Transition } from '@headlessui/react'
import { initializeWalkthrough } from '@pdftron/webviewer-walkthrough'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'

import { useUser } from 'hooks'
import { flatten } from 'lodash'
import { documentEventLog } from 'modules/document-event-log'
import { useCreateDocumentEventLogMutation } from 'modules/document-event-log/document-event-log-api'
import { useLazyGetUserSignatureQuery } from 'modules/profile/profile/user-profile-api'
import { useLazyGetSettingQuery } from 'modules/settings/settings-api'
import {
	useDocmentViewer,
	useSetDocumentViewer,
} from 'pages/Authentication/profile/components/workflow/document-viewer-provider'
import Alert from 'theme/ui/alert'
import { formatDate } from 'utilities/helpers'
import { ACTIONS, addTabs, createInstance, replaceBracketedText, walkthroughSteps } from '.'
import { toDataURL } from '..'

const DocumentList = ({ documentList, workflow }) => {
	const viewerRef = useRef(null)
	const steps = useMemo(() => walkthroughSteps, [])
	const setDocumentViewer = useSetDocumentViewer()
	const { user } = useUser()
	const [fetchSignature] = useLazyGetUserSignatureQuery()
	const [isOpen, setIsOpen] = useState(false)
	const openModal = () => setIsOpen(true)
	const [createDocumentEventLog] = useCreateDocumentEventLogMutation()
	const documentInstance = useDocmentViewer()
	const [getSetting] = useLazyGetSettingQuery()

	const { document_id, workflow_step_document_id } = useMemo(
		() => ({
			workflow_step_document_id:
				documentInstance?.UI?.TabManager?.getActiveTab()?.options?.document_id,
			document_id: documentInstance?.UI?.TabManager?.getActiveTab()?.options?.documentId,
		}),
		[documentInstance],
	)

	useEffect(() => {
		if (document_id && workflow_step_document_id) {
			createDocumentEventLog({
				document_id: document_id,
				workflow_step_document_id: workflow_step_document_id,
				event_type: documentEventLog.eventTypes.SignatureInsertion,
				module_type: documentEventLog.moduleTypes.ClientWorkflowFulfillment,
			})
		}
	}, [document_id, workflow_step_document_id])

	useEffect(() => {
		if (documentList.length > 0) {
			createInstance(viewerRef, getSetting)
				.then((instance) => {
					if (instance?.Core) {
						const { documentViewer, annotationManager, Annotations, Tools } =
							instance.Core

						const originalMouseLeftUp = Tools.SignatureCreateTool.prototype.mouseLeftUp

						Tools.SignatureCreateTool.prototype.mouseLeftUp = function (e) {
							const widget = annotationManager.getAnnotationByMouseEvent(e)
							const signature = signatureTool.getFullSignatureAnnotation()

							if (
								widget instanceof Annotations.SignatureWidgetAnnotation &&
								!widget.getAssociatedSignatureAnnotation() &&
								!widget.ReadOnly
							) {
								window.scrollTo({ top: 0, behavior: 'smooth' })
								signature
									? widget.sign(signature)
									: originalMouseLeftUp.call(this, e)
							}
						}

						instance.UI.enableFeatures([
							instance.UI.Feature.Initials,
							instance.UI.Feature.MultiTab,
						])
						instance.UI.TabManager.disableDeleteTabWarning()

						const iframeDoc = instance.UI.iframeWindow.document
						const signatureTool = documentViewer.getTool('AnnotationCreateSignature')

						documentViewer.addEventListener('documentLoaded', () => {
							if (user?.id) {
								fetchSignature(user?.id).then((response) => {
									const _signaturePath = response?.data?.signature?.path
									if (_signaturePath) {
										toDataURL(_signaturePath).then((response) => {
											signatureTool.deleteSavedSignature()
											signatureTool.importSignatures([response])
										})
									} else {
										openModal()
									}
								})
							}
						})

						// start adding timestamp to the timestamp field
						documentViewer.addEventListener('annotationsLoaded', () => {
							const fieldManager = annotationManager.getFieldManager()

							const signatureFields = []
							fieldManager.forEachField((field) => {
								if (field.name === 'signature') {
									field.children.forEach((f) => {
										signatureFields.push(f)
									})
								}
							})

							const signatureWidgetAnnots = annotationManager
								.getAnnotationsList()
								.filter(
									(annot) =>
										annot instanceof Annotations.SignatureWidgetAnnotation,
								)

							annotationManager.addEventListener(
								'annotationChanged',
								(annotations, action) => {
									const elements =
										iframeDoc.querySelectorAll('.signature-row-content')
									console.log('elements', elements)

									elements.forEach((element) => {
										element.disabled = true
									})
									annotationManager.getAnnotationsList().forEach((annot) => {
										if (
											annot instanceof
												Annotations.SignatureWidgetAnnotation &&
											annot.annot
										) {
											annot.annot.NoMove = true
										}
									})

									const selectedAnnot = annotations[0]

									// add timestamp when signature is inserted into the pdf
									signatureWidgetAnnots.forEach((annot) => {
										const signatureAnnot = annot.annot
										if (signatureAnnot) {
											if (signatureAnnot.Id === selectedAnnot.Id) {
												const fieldName = annot.fieldName

												const [$fieldName, $userRole] = fieldName.split('.')
												// const timestampFieldName = `${fieldName}-timestamp`
												const timestampFieldName = [
													$fieldName,
													$userRole,
													'timestamp',
												].join('.')
												const $signatureFields = signatureFields.map(
													(f) => f.children,
												)

												const timestampField = flatten(
													$signatureFields,
												).find((field) => field.name === timestampFieldName)
												if (timestampField) {
													if (action === ACTIONS.ADD) {
														window.scrollTo({
															top: document.body.scrollHeight,
															behavior: 'smooth',
														})
														const timestamp = formatDate(new Date())
														timestampField.setValue(timestamp)
													}

													if (action === ACTIONS.DELETE) {
														timestampField.setValue('')
													}
												}
											}
										}
									})
								},
							)
						})
						// end adding timestamp to the timestamp field

						const { start } = initializeWalkthrough(
							viewerRef.current,
							steps,
							() => {
								console.log('tutorial complete...')
							},
							{
								backgroundColor: '#dee3fa',
								textColor: '#37458b',
							},
						)
						start()

						documentViewer.addEventListener('documentLoaded', () => {
							documentViewer.getAnnotationsLoadedPromise().then(() => {
								setDocumentViewer(instance)
								const fieldManager = annotationManager.getFieldManager()

								fieldManager.forEachField((field) => {
									if (field?.name === 'signature') {
										field.children.forEach(async (_field) => {
											if (
												replaceBracketedText(_field?.name) ===
												`signature.${workflow?.user_type}`
											) {
												if (Array.isArray(_field.children)) {
													_field.children.forEach(($field) => {
														// eslint-disable-next-line no-unused-vars
														const [_0, _1, fieldType] =
															$field.name.split('.')
														if (fieldType === 'timestamp') {
															$field.widgets.map((annot) =>
																annot.fieldFlags.set(
																	'ReadOnly',
																	true,
																),
															)
														}
														if (fieldType === 'signature') {
															$field.widgets.map((annot) =>
																annot.fieldFlags.set(
																	'Required',
																	true,
																),
															)
															$field.widgets.map((annot) =>
																annot.fieldFlags.set(
																	'ReadOnly',
																	false,
																),
															)
														}
													})
												}
											} else {
												if (Array.isArray(_field.children)) {
													_field.children.forEach(($field) => {
														// eslint-disable-next-line no-unused-vars
														const [_0, _1, fieldType] =
															$field.name.split('.')
														if (fieldType === 'signature') {
															$field.widgets.map((annot) =>
																annot.fieldFlags.set(
																	'Required',
																	false,
																),
															)
															$field.widgets.map((annot) =>
																annot.fieldFlags.set(
																	'ReadOnly',
																	true,
																),
															)
														}
													})
												}
											}
										})
									}
								})
							})
						})

						documentViewer.addEventListener('annotationsLoaded', () => {
							annotationManager.addEventListener(
								'annotationSelected',
								(annotationList) => {
									annotationList.forEach((annotation) => {
										if (annotation.Subject === 'Signature') {
											// annotation.Locked = true
										}
									})
								},
							)
						})

						addTabs(documentList, instance).then(() => {
							const closeButtons = iframeDoc.querySelectorAll('.close-button-wrapper')
							if (closeButtons?.length) {
								closeButtons.forEach((element) => {
									element.remove()
								})
							}

							const addButtons = iframeDoc.querySelectorAll('.add-button')
							if (addButtons?.length) {
								addButtons.forEach((element) => {
									element.remove()
								})
							}
						})
					}
				})
				.catch((error) => {
					console.log('instance error', error)
				})
		}

		return () => {
			viewerRef.current = null
		}
	}, [documentList])

	return (
		<Fragment>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as='div' className='relative z-[1008]' onClose={() => {}} b>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'>
						<div className='fixed inset-0 bg-black/30' />
					</Transition.Child>

					<div className='fixed inset-0 overflow-y-auto'>
						<div className='flex min-h-full items-center justify-center p-4 text-center'>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 scale-95'
								enterTo='opacity-100 scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 scale-100'
								leaveTo='opacity-0 scale-95'>
								<Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all'>
									<Dialog.Title
										as='h3'
										className='mb-3 text-center text-xl font-medium leading-6 text-gray-900'>
										Your Signature could not be located.
									</Dialog.Title>
									<div className='mt-2'>
										<p className='text-sm text-gray-500'>
											Please upload your signature to your profile in order to
											insert it into documents via the provided link. This
											will allow for seamless integration of your signature
											into various documents.
										</p>
									</div>
									<Alert variant='warning'>
										Once you upload your signature Please refresh here to
										continue.
									</Alert>

									<div className='float-right mt-4'>
										<a
											target='_blank'
											href={`${window.location.origin}/profile#signatures`}
											className='text-sm text-main hover:!underline'
											rel='noreferrer'>
											<span>Click here to upload signature</span>
											<i className='uil-arrow-right text-base' />
										</a>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
			<div ref={viewerRef} style={{ height: '100vh' }} />
		</Fragment>
	)
}

export default DocumentList
