import { useState } from 'react'

import { Field } from 'formik'
import { SelectField } from 'theme/ui/forms'
import { createAssigneeOptionLabel } from '.'
import AssigneeOptionsQuery from './AssigneeOptionsQuery'

const AssigneeDropdown = () => {
	const [search, setSearch] = useState('')
	const { assigneeOptions, isLoading } = AssigneeOptionsQuery(search)

	const filterOption = ({ data }, search) => {
		if (
			data?.fullName?.toLowerCase()?.includes(search?.toLowerCase()) ||
			data?.email?.toLowerCase()?.includes(search?.toLowerCase()) ||
			(typeof data?.label === 'string' &&
				data.label.toLowerCase().includes(search.toLowerCase()))
		) {
			return true
		} else {
			return false
		}
	}
	return (
		<Field
			label='Assignee'
			type='select'
			name='assignee_id'
			options={assigneeOptions}
			isLoading={isLoading}
			onInputChange={(value) => setSearch(value)}
			component={SelectField}
			isRequiredField={true}
			placeholder='Select individual'
			getOptionLabel={(option) => createAssigneeOptionLabel(option)}
			filterOption={filterOption}
		/>
	)
}

export default AssigneeDropdown
