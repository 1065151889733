import { Field } from 'formik'
import { useState } from 'react'

import { SelectField } from 'theme/ui/forms'
import { createAdvisorOptionLabel } from '.'
import AdvisorsQuery from './advisors-query'

const AdvisorDropdown = ({ formValues }) => {
	const [search, setSearch] = useState('')
	const { advisors, isLoading } = AdvisorsQuery(
		search,
		formValues,
		formValues?.reviewers || [],
		'advisor',
	)

	const filterOption = ({ data }, search) => {
		if (
			data.fullName.toLowerCase().includes(search.toLowerCase()) ||
			data.email.toLowerCase().includes(search.toLowerCase())
		) {
			return true
		} else {
			return false
		}
	}

	return (
		<Field
			label='Select Advisor'
			type='select'
			name='advisor'
			options={advisors}
			isLoading={isLoading}
			onInputChange={(value) => setSearch(value)}
			component={SelectField}
			isRequiredField={true}
			labelHelperText={true}
			placeholder='Select Advisor'
			getOptionLabel={(option) => createAdvisorOptionLabel(option)}
			filterOption={filterOption}
		/>
	)
}

export default AdvisorDropdown
