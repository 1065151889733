import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

import {
	useDeleteDocumentTemplateMutation,
	useLazyDocumentDeleteEligibleQuery,
} from 'modules/documents/document-api'
import { DOCUMENT_TEMPLATE_APP_ROUTES } from 'modules/documents/routes'
import AddToFolder from 'modules/file-manager/all-folders-listing/add-to-folder'
import DeleteConfirmAlert from 'theme/ui/delete-confirm-alert'
import Spinner from 'theme/ui/spinner'
import notification from 'utilities/notification'

const OptionsList = ({ document, onRenameOpen }) => {
	const [isOpen, setIsOpen] = useState(false)
	const [menu, setMenu] = useState(false)
	const [isDeleteOpen, setIsDeleteOpen] = useState(false)

	const onOpen = () => setIsOpen(true)
	const onClose = () => setIsOpen(false)

	const onMarkStarred = (id) => {
		console.log('Mark Starred ', id)
	}

	const [documentDeleteEligible, { isLoading }] = useLazyDocumentDeleteEligibleQuery()
	const [deleteDocumentTemplate, { isLoading: isDeleting }] = useDeleteDocumentTemplateMutation()

	const checkDocumentDeleteEligibility = async (id) => {
		const response = await documentDeleteEligible(id)
		if (response?.data?.status === 200 && response?.data?.data) {
			setIsDeleteOpen(true)
		}
	}

	const onDelete = async (id) => {
		try {
			const response = await deleteDocumentTemplate(id)
			if (response?.data?.status === 200) {
				notification('success', response?.data?.message)
			}
			setIsDeleteOpen(false)
		} catch (error) {
			setIsDeleteOpen(false)
			notification('error', error?.message)
		}
	}

	return (
		<Fragment>
			<DeleteConfirmAlert
				title='Delete Document Template?'
				subtitle='Are you sure you want to delete this Document Template'
				isOpen={isDeleteOpen}
				onClose={() => setIsDeleteOpen(false)}
				isLoading={isDeleting}
				onConfirm={() => onDelete(document?.id)}
			/>
			<Dropdown
				isOpen={menu}
				toggle={() => setMenu(!menu)}
				className='dropdown  inline-block'
				tag='li'>
				<DropdownToggle
					tag='button'
					type='button'
					className='m-0 !border !border-none bg-transparent p-0'>
					<i className='mdi mdi-dots-vertical text-[16px]'></i>
				</DropdownToggle>
				<DropdownMenu className='dropdown-menu-md dropdown-menu-end  p-0 '>
					<div className='w-full overflow-hidden rounded-md bg-white text-left shadow-lg ring-1 ring-black/5'>
						<div className='relative divide-y p-2'>
							<div className='flex flex-col gap-1'>
								{/* <Link to='#' className='file-manager-action-dropdown-item'>
									<i className='uil-eye'></i>
									<span className='text-sm'>View</span>
								</Link> */}

								<Link
									to={DOCUMENT_TEMPLATE_APP_ROUTES.edit(document?.id)}
									className='file-manager-action-dropdown-item'>
									<i className='uil-pen'></i>
									<span className='text-sm'>Edit</span>
								</Link>
								<span
									className='file-manager-action-dropdown-item'
									onClick={onRenameOpen}>
									<i className='uil-edit-alt'></i>
									<span className='text-sm'>Rename</span>
								</span>
								{/* <Link
										to={WORKFLOW_TEMAPLTE_ROUTES.create()}
										className='file-manager-action-dropdown-item'>
										<i className='uil-paperclip'></i>
										<span className='text-sm'>Attach in Workflow</span>
									</Link> */}
								<span
									className='file-manager-action-dropdown-item'
									onClick={onOpen}>
									<i className='uil-folder'></i>
									<span className='text-sm'>Move to Folder</span>
								</span>
								<span
									onClick={() => onMarkStarred(document?.id)}
									className='file-manager-action-dropdown-item'>
									<i className='uil-star'></i>
									<span className='text-sm'>Star</span>
								</span>
								<span
									className='file-manager-action-dropdown-item'
									onClick={() => checkDocumentDeleteEligibility(document?.id)}>
									{isLoading ? (
										<>
											<Spinner />
											<span className='text-sm'>Checking...</span>
										</>
									) : (
										<>
											<i className='uil-trash text-red-600'></i>
											<span className='text-sm text-red-600'>Delete</span>
										</>
									)}
								</span>
							</div>
						</div>
					</div>
				</DropdownMenu>
			</Dropdown>

			<AddToFolder onClose={onClose} isOpen={isOpen} />
		</Fragment>
	)
}

export default OptionsList
