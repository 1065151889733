export const extractModuleName = (value) => value.substring(0, value.indexOf(':'))

export const extractAccessName = (value) => {
	const array = value.split(':')
	const position = array.length - 1
	const label = array[position]
	return capitalize(label)
}

export const capitalize = (value) => value.charAt(0).toUpperCase() + value.slice(1)

export const usePermission = (permissions) => {
	var modules = []
	for (let index = 0; index < permissions.length; index++) {
		if (!modules.includes(extractModuleName(permissions[index]))) {
			modules.push(extractModuleName(permissions[index]))
		}
	}

	const getPermissionsByModule = (moduleName) => {
		var rows = []
		for (let i = 0; i < permissions.length; i++) {
			if (extractModuleName(permissions[i]) === moduleName) {
				rows.push(permissions[i])
			}
		}
		return rows
	}

	const newPermissions = modules.map((moduleName) => ({
		[moduleName]: getPermissionsByModule(moduleName),
	}))

	return newPermissions
}

export const editablePermissions = (accesses) => {
	var newObj = {}
	for (let j = 0; j < accesses.length; j++) {
		newObj[extractModuleName(accesses[j].module)] = {
			...newObj[extractModuleName(accesses[j].module)],
			[accesses[j].module]: { id: accesses[j].id, module: true },
		}
	}
	return newObj
}

export const editablePermissionsArray = (accesses) => {
	const permissions = []
	for (let i = 0; i < accesses.length; i++) {
		const name = extractAccessName(accesses[i].module)
		permissions.push({ value: accesses[i].module, name })
	}
	return permissions
}

export const modifyPermissions = (values) => {
	const { accesses } = values
	var newAccess = []
	for (const key in accesses) {
		for (const access in accesses[key]) {
			if (accesses[key][access] && accesses[key][access].module) {
				if (accesses[key][access].id) {
					newAccess.push({ id: accesses[key][access].id, module: access })
				} else {
					newAccess.push({ module: access })
				}
			}
		}
	}
	values['accesses'] = newAccess
	return values
}

export const modifyModules = (modules) => {
	let permissionsArray = []
	modules.map((module) => permissionsArray.push({ module: module.value }))

	return permissionsArray
}

export const updateInitialValues = (elements, i, initialValues, key, res) => {
	if (!initialValues) {
		return false
	}
	var str = elements[i].name
	var mySubString = str.substring(str.indexOf(`][`) + 2, str.lastIndexOf(`][module]`))
	initialValues.accesses[key] = { ...initialValues.accesses[key], [mySubString]: { module: res } }
	return initialValues
}

export const UpdateInitials = (newInitialValues, key, mySubString, res) => {
	if (!newInitialValues.accesses) {
		Object.assign(newInitialValues, { accesses: {} })
	}
	return (newInitialValues.accesses[key] = {
		...newInitialValues.accesses[key],
		[mySubString]: { module: res },
	})
}

export const onChange = (e, module) => {
	var checkedCount = []
	const elements = document.getElementsByClassName(`${module}`)
	for (let i = 0; i < elements.length; i++) {
		if (elements[i].checked) {
			checkedCount.push(i)
		}
	}
	const checkBox = document.getElementById(`${module}`)
	if (checkedCount.length === elements.length) {
		checkBox.checked = true
	} else {
		checkBox.checked = false
	}
}

export const permissionsArrayUpdate = (permissions) => {
	let modulesList = []
	for (let i = 0; i < permissions.length; i++) {
		let moduleName = extractModuleName(permissions[i])
		modulesList.push({ name: moduleName })
	}
	const updatedModules = modulesList.filter((name, index) => {
		const _name = JSON.stringify(name)
		return (
			index ===
			modulesList.findIndex((obj) => {
				return JSON.stringify(obj) === _name
			})
		)
	})

	const modulePermissions = (moduleName) => {
		let rows = []
		for (let i = 0; i < permissions.length; i++) {
			if (extractModuleName(permissions[i]) === moduleName) {
				const permissionName = extractAccessName(permissions[i])
				rows.push({ value: permissions[i], name: permissionName })
			}
		}
		return rows
	}

	updatedModules.map((moduleName) => (moduleName.method = modulePermissions(moduleName.name)))

	return updatedModules
}
