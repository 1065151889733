import { useMemo } from 'react'
import StickyBox from 'react-sticky-box'
import { reduxForm } from 'redux-form'
import WorkflowCommentSection from '../workflow-comment-section'
import AssignedFields from './assigned-fields'
import UploadedFiles from './uploaded-files'

const WorkflowReviewCard = ({ uploads, workflow, handleSubmit, individual_document }) => {
	const user_type = useMemo(() => workflow?.user_type, [workflow])
	const userkey = useMemo(() => (user_type === 'advisor' ? 'advisor' : 'client'), [user_type])
	const user = useMemo(() => workflow[userkey], [workflow])
	const finalReviewer = useMemo(() => workflow?.workflowFinalReviewer?.finalReviewer, [workflow])
	const status = useMemo(() => workflow?.status, [workflow])

	return (
		<div className='flex flex-col'>
			<form onSubmit={handleSubmit}>
				<div className='grid grid-cols-1 gap-10 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5'>
					<div className='flex flex-col'>
						<span className='text-sm font-bold'>Workflow Name</span>
						<span className='text-sm font-normal text-[#74788D]'>
							{workflow?.workflow_template?.title || '-'}
						</span>
					</div>

					<div className='flex flex-col'>
						<span className='text-sm font-bold'>User ({workflow?.user_type})</span>
						<span className='text-sm font-normal text-[#74788D]'>
							{user?.first_name} {user?.last_name}
						</span>
					</div>

					<div className='flex flex-col'>
						<span className='text-sm font-bold'>Current Status</span>
						<span className='text-sm font-normal text-[#74788D]'>
							{workflow?.status}
						</span>
					</div>

					<div className='flex flex-col'>
						<span className='text-sm font-bold'>Final Reviewer</span>
						<span className='text-sm font-normal text-[#74788D]'>
							{finalReviewer ? (
								<>
									{finalReviewer?.first_name} {finalReviewer?.last_name}
								</>
							) : (
								<>No final reviewer is assigned</>
							)}
						</span>
					</div>

					<div className='flex flex-col'>
						<span className='text-sm font-bold'>Reviewers</span>
						<span className='text-sm font-normal text-[#74788D]'>
							No reviewer is assigned
						</span>
					</div>
				</div>

				<div>
					<h3 className='mb-2 mt-8 text-lg font-bold not-italic leading-6 text-[#495057]'>
						Assigned Fields
					</h3>
					<div className='flex w-full flex-col justify-between gap-4 lg:!flex-row'>
						<div className='lg:w-[calc(100%_-_280px)]'>
							<AssignedFields
								individual_document={individual_document}
								user_type={user_type}
							/>
						</div>
						<div className='lg:w-[280px]'>
							<StickyBox offsetBottom={20} offsetTop={80}>
								<UploadedFiles uploads={uploads} status={status} />
							</StickyBox>
						</div>
					</div>
				</div>
			</form>
			<WorkflowCommentSection workflowId={workflow?.id} stepId={individual_document?.id} />
		</div>
	)
}

export default reduxForm({
	form: 'workflow_review',
	enableReinitialize: true,
})(WorkflowReviewCard)
