import { useMediaQuery } from "react-responsive";
const Pagination = ({ total, limit, currentPage, onPageChange}) => {
	const totalPages = Math.ceil(total / limit)
	const pageNumber = []
	const isMobile = useMediaQuery({ query: '(max-width: 990px)' });

	let startPage = Math.max(1, currentPage - 1)
	let endPage = Math.min(totalPages, currentPage + 1)

	if (endPage - startPage < 2) {
		if (startPage === 1) {
			endPage = Math.min(startPage + 2, totalPages)
		} else if (endPage === totalPages) {
			startPage = Math.max(endPage - 2, 1)
		}
	}

	for (let i = startPage; i <= endPage; i++) {
		pageNumber.push(i)
	}

	return (
		<nav aria-label='Page navigation example' className={`w-full z-50 ${isMobile ? 'fixed bottom-0 flex justify-center items-center' : ''}`}>
			<ul className='pagination'>
				<li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
					<button
						className='page-link'
						onClick={(e) => onPageChange(e, currentPage - 1)}
						disabled={currentPage === 1}>
						Previous
					</button>
				</li>
				{pageNumber.map((number) => (
					<li
						key={number}
						className={`page-item ${currentPage === number ? 'active' : ''}`}>
						{currentPage === number ? (
							<button className='page-link'>{number}</button>
						) : (
							<button className='page-link' onClick={(e) => onPageChange(e, number)}>
								{number}
							</button>
						)}
					</li>
				))}
				<li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
					<button
						className='page-link'
						onClick={(e) => onPageChange(e, currentPage + 1)}
						disabled={currentPage === totalPages}>
						Next
					</button>
				</li>
			</ul>
		</nav>
	)
}

export default Pagination
