import { Popover, Transition } from '@headlessui/react'
import { Fragment } from 'react'

const TeamsPopover = ({ teams }) => {
	return (
		<div className=''>
			<Popover className='relative !max-w-[200px]'>
				{() => (
					<>
						<Popover.Button className='flex h-[28px]  w-[28px] items-center justify-center rounded-full !border border-main text-xs text-main hover:bg-main/30 hover:shadow'>
							<span>+{teams?.length - 1}</span>
						</Popover.Button>
						<Transition
							as={Fragment}
							enter='transition ease-out duration-200'
							enterFrom='opacity-0 translate-y-1'
							enterTo='opacity-100 translate-y-0'
							leave='transition ease-in duration-150'
							leaveFrom='opacity-100 translate-y-0'
							leaveTo='opacity-0 translate-y-1'>
							<Popover.Panel className='absolute left-1/2 z-10 mt-1 w-[200px] -translate-x-1/2 transform px-4 sm:px-0'>
								<div className='overflow-hidden rounded-md bg-white shadow-md ring-1 ring-black/5'>
									<h4 className='mx-3 my-2'>Teams</h4>
									<div className='relative flex flex-col divide-y bg-white'>
										{teams.map((item, i) => (
											<div key={i} className='px-3 py-2'>
												<p className='!m-0 text-xs font-semibold text-main'>
													{item.team_name}
												</p>
											</div>
										))}
									</div>
								</div>
							</Popover.Panel>
						</Transition>
					</>
				)}
			</Popover>
		</div>
	)
}

export default TeamsPopover
