import { Fragment } from 'react'
import { Field } from 'redux-form'

import OtpFieldComponent from 'components/Forms/OtpFieldComponent'
import { RadioComponent } from 'components/Forms/RadioComponent'
import { TextField } from 'components/Forms/TextFieldComponent'
import { required } from 'utils/formValidation'

const validate = {
	sin_number: required('sin number'),
}

const Tab1 = ({ client }) => {
	const STEP_KEY = 'sin_number'
	const step = client.find((item) => item?.user_key === STEP_KEY)
	const SIN_LENGTH = 9

	return (
		<Fragment>
			{step ? (
				<div className='space-y-6'>
					<Field
						label='SIN Number'
						id='step.sin_number.value'
						name='step.sin_number.value'
						otpLength={SIN_LENGTH}
						component={OtpFieldComponent}
						validate={validate.sin_number}
					/>

					<div>
						<label>Status:</label>
						<div className='flex space-x-5'>
							<Field
								id={`step-${STEP_KEY}-status-approved`}
								label='Approved'
								name='step.sin_number.status'
								type='radio'
								component={RadioComponent}
								value='1'
								defaultChecked={true}
							/>

							<Field
								id={`step-${STEP_KEY}-status-rejected`}
								label='Rejected'
								name='step.sin_number.status'
								type='radio'
								component={RadioComponent}
								value='0'
							/>
						</div>
					</div>

					<Field
						label='Addtional Notes :'
						type='textarea'
						name={`step.${STEP_KEY}.reason`}
						component={TextField}
					/>
				</div>
			) : (
				<div className='alert alert-warning'>Step not found</div>
			)}
		</Fragment>
	)
}

export default Tab1
