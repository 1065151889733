import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

import filterIcon from 'assets/images/svg-icons/sort-icon.svg'
import { filterOptions } from '.'
import ActiveFilter from './ActiveFilter'

const FilterDropdown = () => {
	const [isOpen, setIsOpen] = useState(false)
	const [activeFilter, setActiveFilter] = useState(null)
	const values = useSelector((state) => state?.form?.kanban_filter?.values)

	const numberOfFilters = useMemo(() => {
		let count = 0
		if (Array.isArray(values?.client) && values?.client?.length > 0) {
			count = count + 1
		}
		if (Array.isArray(values?.workflow) && values?.workflow?.length > 0) {
			count = count + 1
		}
		if (values?.due_date) {
			count = count + 1
		}
		if (values?.has_attachment) {
			count = count + 1
		}
		return count
	}, [values])

	const onFilterActive = (filter) => {
		setActiveFilter(filter)
	}

	const onClearFilter = () => setActiveFilter(null)

	return (
		<ButtonDropdown
			isOpen={isOpen}
			toggle={() => setIsOpen(!isOpen)}
			className='p-0'
			direction='left'>
			<DropdownToggle caret className='btn btn-sm btn-outline-secondary relative !px-1'>
				<div className='flex items-center space-x-1'>
					<span>
						<img src={filterIcon} className='h-4 w-4' />
					</span>
					<span>Filter</span>
				</div>

				{numberOfFilters ? (
					<div className='bg-primary absolute -right-2 -top-2 inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-white text-xs font-medium text-white '>
						{numberOfFilters}
					</div>
				) : undefined}
			</DropdownToggle>
			<DropdownMenu onClick={() => setIsOpen(true)} className='!mt-10 !p-0'>
				<DropdownItem
					tag='div'
					className='rounded !p-0 shadow-[2px_4px_8px_1px_rgba(73,80,87,0.14)]'>
					{activeFilter ? (
						<ActiveFilter activeFilter={activeFilter} onClearFilter={onClearFilter} />
					) : (
						<div className='flex  shrink-0 flex-col !border bg-white'>
							<div className='w-full px-3 py-2'>
								<h3 className='m-0 text-sm font-normal not-italic leading-5 text-[#495057]'>
									Filter By
								</h3>
							</div>
							<div className='py-2'>
								<ul className='m-0 divide-y p-0 text-xs'>
									{filterOptions.map((filter, idx) => (
										<li key={idx}>
											<button
												className='w-full cursor-pointer px-3 py-2 text-left hover:bg-slate-100 active:bg-slate-200'
												type='button'
												onClick={() => onFilterActive(filter.key)}>
												{filter.label}
											</button>
										</li>
									))}
								</ul>
							</div>
						</div>
					)}
				</DropdownItem>
			</DropdownMenu>
		</ButtonDropdown>
	)
}

export default FilterDropdown
