import { createColumnHelper } from '@tanstack/react-table'
import { Fragment, useState } from 'react'

import Badge from 'theme/ui/badge'
import DataTable from 'theme/ui/data-table'
import AnnouncementActions from './announcemt-actions'

const AnnouncementssTable = ({ data, meta, isLoading, pagination, setPagination }) => {
	const [sorting, setSorting] = useState([])
	const columnHelper = createColumnHelper()
	const columns = [
		columnHelper.accessor('index', {
			size: 20,
			header: () => <span>#</span>,
			cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		}),
		columnHelper.accessor((row) => row, {
			id: 'title',
			size: 200,
			header: () => <span>Title</span>,
			cell: (info) => <p className='m-0 p-0 font-bold'>{info.getValue().title}</p>,
		}),
		// columnHelper.accessor((row) => row, {
		// 	id: 'content',
		// 	size: 300,
		// 	header: () => <span>Content</span>,
		// 	cell: (info) => (
		// 		<p
		// 			className='m-0 line-clamp-1 p-0'
		// 			title={info.getValue().content}
		// 			dangerouslySetInnerHTML={{ __html: info.getValue().content }}
		// 		/>
		// 	),
		// }),

		columnHelper.accessor((row) => row, {
			id: 'status',
			size: 200,
			header: () => <span>Status</span>,
			cell: (info) => (
				<>
					<Badge variant={info?.getValue()?.status == 'ACTIVE' ? 'success' : 'danger'}>
						<span className='capitalize'>{info?.getValue()?.status}</span>
					</Badge>
				</>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'action',
			size: 200,
			enableSorting: false,
			header: () => <span>Actions</span>,
			cell: (info) => <AnnouncementActions announcement={info.getValue()} />,
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={data}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			setSorting={setSorting}
			sorting={sorting}
			meta={meta}
		/>
	)
}

export default AnnouncementssTable
