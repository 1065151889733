import Header from 'modules/clients/header'
import ClientListingContainer from 'modules/clients/onboarded-clients'
import AppLayout from 'theme/layouts/app-layout'

const ClientsListing = () => {
	return (
		<AppLayout
			meta={{
				title: 'Clients',
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<div className='page-header bg-white'>
						<Header />
					</div>
					<div className='page-body'>
						<ClientListingContainer />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default ClientsListing
