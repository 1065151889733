import { Link } from 'react-router-dom'

import {
	CurrentSubscription,
	PaymentsDetails,
	UsageSubscription,
} from 'modules/profile/my-subscriptions/active-subscription'
import { TEAM_APP_ROUTES } from 'modules/teams/routes'

const SubscriptionDetailsTab = ({ team, subscription, $subscription, total_users }) => {
	return (
		<div className='rounded-md bg-white p-3'>
			{subscription ? (
				<div className='grid grid-cols-1 gap-8'>
					<CurrentSubscription subscription={subscription} />

					<UsageSubscription
						subscription={subscription}
						total_users={total_users}
						$subscription={$subscription}
					/>

					<PaymentsDetails payments={$subscription?.payments} />
				</div>
			) : (
				<p className='m-0 text-center'>
					This team does not have an active subscription. Click{' '}
					<Link to={TEAM_APP_ROUTES.subscribe(team?.id)} className='hover:!underline'>
						here
					</Link>{' '}
					to add subscription
				</p>
			)}
		</div>
	)
}

export default SubscriptionDetailsTab
