import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	selectedClients: [],
}
export const ClientsSlices = createSlice({
	name: 'selectedClients',
	initialState: initialState,
	reducers: {
		selectedClients: (state, action) => {
			state.selectedClients = action.payload
		},
	},
})
