import { Tab } from '@headlessui/react'
import FieldMapping from './field-mapping'
import FormBuilder from './form-builder'

const TabsContent = ({ document }) => {
	return (
		<Tab.Panels>
			<Tab.Panel>
				<FieldMapping document={document} />
			</Tab.Panel>
			<Tab.Panel>
				<FormBuilder document={document} />
			</Tab.Panel>
		</Tab.Panels>
	)
}

export default TabsContent
