import TeamDropdown from 'components/Common/TeamDropdown'
import experiviseLightLogo from '../../../../assets/images/experivise-logo-dark.png'
import LeftSidebarBottomImage from './LeftSidebarBottomImage'
import Stepper from './Stepper'

const LeftSidebar = () => {
	return (
		<div className='flex h-full flex-col justify-between'>
			<div>
				<div className='ms-4 mt-2 ps-2'>
					<span className='flex items-center '>
						<img
							src={experiviseLightLogo}
							alt=''
							style={{ height: 40 }}
							className='mt-1'
						/>
						<span className='d-sm-block d-none'>
							<span className='mx-2 text-lg font-semibold text-gray-500'>
								Experivise
							</span>
						</span>
					</span>
				</div>
				<div className='px-7 pt-14'>
					<h3 className='text-xl font-bold'>Steps to complete</h3>
					<Stepper />
				</div>
				<LeftSidebarBottomImage />
			</div>
			<div className='!border-t'>
				<TeamDropdown />
			</div>
		</div>
	)
}

export default LeftSidebar
