import { SystemFieldForm } from 'modules/system-fields'
import { useHistory } from 'react-router-dom'
import AppLayout from 'theme/layouts/app-layout'

const SystemFieldsCreate = () => {
	const history = useHistory()

	return (
		<AppLayout
			meta={{
				title: 'Create System Field',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<div className='page-header bg-white'>
						<div className='flex w-full flex-col'>
							<div className='mb-3 flex w-full justify-between'>
								<div className='max-w-[733px]'>
									<h4 className='page-title'>Create System Field</h4>
									<p className='text-xs text-[#74788D]'>
										These system fields, integral to the document mapping
										process, are available for your customization. You have the
										flexibility to precisely manage and configure these fields
										directly from this designated interface, empowering you to
										tailor the document mapping to meet your specific needs and
										preferences.
									</p>
								</div>

								<button
									onClick={() => history.goBack()}
									className='h-fit min-w-max rounded bg-main px-3 py-2 text-white'>
									Back
								</button>
							</div>
						</div>
					</div>
					<div className='page-body'>
						<SystemFieldForm />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default SystemFieldsCreate
