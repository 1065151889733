import WorkflowDropdown from './workflow-dropdown'

export const createWorkflowOption = (workflow) => {
	return {
		title: workflow?.title,
		value: workflow?.id,
	}
}

export const createOptionLabel = (option) => {
	return (
		<span className='line-clamp-1' title={option?.title}>
			{option?.title}
		</span>
	)
}

export default WorkflowDropdown
