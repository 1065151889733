import { Fragment, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import {
	useCreateHouseholdMutation,
	useLazyGetHouseholdsQuery,
} from 'modules/households/households-api'
import { dispatch } from 'store'
import { getPaginationParams, getParseQueryString, getTagsParams } from 'utilities/helpers'
import notification from 'utilities/notification'
import { toQueryString } from 'utils/commonFunctions'
import * as actions from '../../../store/clients/actions'
import { useAssignTagsMutation, useLazyGetClientsQuery } from '../client-apis'
import Filters from './filters'
import OnboardedClientsTable from './onboarded-clients-table'

const ClientListingContainer = () => {
	const location = useLocation()
	const history = useHistory()
	const { page, limit } = getPaginationParams(location?.search)
	const { households } = getTagsParams(location?.search)
	const [pagination, setPagination] = useState({
		pageIndex: page - 1,
		pageSize: limit,
		households: households,
	})
	const { pageIndex, pageSize } = pagination
	const [tagList, setTagList] = useState([])
	const [uniqueTagList, setUniqueTagList] = useState([])
	const [householdList, setHouseholdList] = useState([])
	const [tagSearch, setTagSearch] = useState(null)
	const [selectedRecords, setSelectedRecords] = useState([])
	const [selectedTags, setSelectedTags] = useState({})
	const [previousData, setPreviousData] = useState()
	const [rowSelection, setRowSelection] = useState({})
	const [fetchClients, { data, isFetching: isLoading }] = useLazyGetClientsQuery()
	const [checkedTags, setCheckedTags] = useState([])
	const [fetchHouseholds, { data: tagsData }] = useLazyGetHouseholdsQuery()
	const [assignTags] = useAssignTagsMutation()
	const [isAssignDisabled, setIsAssignDisabled] = useState(true)

	const checkTags = () => {
		return checkedTags.every((item) => uniqueTagList.includes(item))
	}

	useEffect(() => {
		setIsAssignDisabled(checkTags())
	}, [checkedTags, selectedRecords, tagList])

	useEffect(() => {
		if (pagination) {
			const parseQueryString = getParseQueryString(location?.search)
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			history.push(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	useEffect(() => {
		if (location?.search) {
			const parseQueryString = getParseQueryString(location?.search)
			fetchClients({
				...getParseQueryString(location?.search),
				role: 'client',
			})
			if (parseQueryString?.groups) {
				const listTags = parseQueryString?.groups?.split(',')
				const newSelectedTags = {}
				listTags.forEach((tag) => {
					newSelectedTags[tag] = {
						checked: true,
						indeterminate: false,
					}
				})
				setSelectedTags(newSelectedTags)
			} else {
				setCheckedTags([])
				setSelectedTags({})
			}
		}
	}, [location?.search])

	useEffect(() => {
		if (fetchHouseholds) {
			fetchHouseholds({ limit: 1000, page: 1 })
		}
	}, [fetchHouseholds])

	useEffect(() => {
		if (tagsData?.households?.length > 0) {
			const tagsArr = tagsData?.households?.map((tag) => {
				return tag?.name
			})
			setTagList(tagsArr)
			setUniqueTagList(tagsArr)
			const tagsDetails = tagsData?.households?.map((tag) => {
				return { name: tag?.name, id: tag?.id }
			})
			setHouseholdList(tagsDetails)
		}
	}, [tagsData])

	useEffect(() => {
		const getTagsList = Object.keys(selectedTags).filter((key) => selectedTags[key].checked)
		setCheckedTags(getTagsList)
	}, [selectedTags])

	useEffect(() => {
		const newSelectedTags = {}
		if (selectedRecords?.length > 0) {
			tagList.forEach((tag) => {
				const tagInAllRecords = selectedRecords?.every((record) =>
					record?.tags?.includes(tag),
				)
				const tagInSomeRecords = selectedRecords?.some((record) =>
					record?.tags?.includes(tag),
				)
				newSelectedTags[tag] = {
					checked: tagInAllRecords,
					indeterminate: tagInSomeRecords && !tagInAllRecords,
				}
			})
			setSelectedTags(newSelectedTags)
		} else {
			setSelectedTags({})
			setCheckedTags([])
			if (location?.search) {
				const parseQueryString = getParseQueryString(location?.search)
				if (parseQueryString?.groups) {
					const listTags = parseQueryString?.groups?.split(',')
					const newSelectedTags = {}
					listTags.forEach((tag) => {
						newSelectedTags[tag] = {
							checked: true,
							indeterminate: false,
						}
					})
					setSelectedTags(newSelectedTags)
				}
			}
		}
	}, [selectedRecords])

	const [createHousehold] = useCreateHouseholdMutation()

	const createTag = async (tagName) => {
		try {
			const response = await createHousehold({
				name: tagName,
			})

			if (response?.data?.status === 201) {
				setTagSearch('')
				notification('success', response?.data?.message)
			} else {
				setTagSearch('')
				notification('warn', response?.data?.message)
			}
		} catch (error) {
			setTagSearch('')
			notification('warn', error?.response?.data?.message || 'An error occurred')
		}
	}

	const handleTagChange = (e, tag) => {
		const tagChecked = selectedTags[tag]?.checked
		if (selectedRecords?.length > 0) {
			setSelectedRecords((prevSelectedRecords) =>
				prevSelectedRecords?.map((record) => {
					const tags = record?.tags || []
					const hasTag = tags.includes(tag)

					if (tagChecked) {
						return {
							...record,
							tags: tags.filter((t) => t !== tag),
						}
					} else {
						return {
							...record,
							tags: hasTag ? tags : [...tags, tag],
						}
					}
				}),
			)
		} else {
			const isChecked = e.target.checked
			const newSelectedTags = { ...selectedTags }
			newSelectedTags[tag] = {
				checked: isChecked,
				indeterminate: false,
			}
			setSelectedTags(newSelectedTags)
			const checkedTaglist = Object.keys(newSelectedTags).filter(
				(key) => newSelectedTags[key].checked,
			)
			const tagsString = checkedTaglist.join(',')
			const encodedTags = encodeURIComponent(tagsString)
			setPagination((preRec) => ({
				...preRec,
				households: encodedTags,
			}))
			history.push(`${window.location.pathname}?groups=${encodedTags}`)
		}
	}
	const applyTags = async () => {
		if (selectedRecords?.length > 0) {
			const tagsPayload = []
			selectedRecords?.forEach((record) => {
				const tagIds = record?.tags
					.map((tag) => {
						const matchingTag = householdList.find((t) => t.name === tag)
						return matchingTag ? matchingTag?.id?.toString() : null
					})
					.filter((tagId) => tagId !== null)

				tagsPayload.push({
					user_id: record?.id,
					clientGroup_id: tagIds,
				})
			})
			const response = await assignTags({
				userClientGroups: tagsPayload,
			})
			if (response?.data?.status === 201) {
				notification('success', response?.data?.message)
			}
			setSelectedRecords([])
			setRowSelection({})
		}
	}

	const assignWorkflow = () => {
		dispatch(actions.selectedClients(selectedRecords))
		history.push(`/workflows/assign?clients=true`)
	}

	return (
		<Fragment>
			<Filters
				tagList={tagList}
				setTagSearch={setTagSearch}
				tagSearch={tagSearch}
				selectedTags={selectedTags}
				handleTagChange={handleTagChange}
				applyTags={applyTags}
				createTag={createTag}
				assignWorkflow={assignWorkflow}
				selectedRecords={selectedRecords}
				checkedTags={checkedTags}
				isAssignDisabled={isAssignDisabled}
				uniqueTagList={uniqueTagList}
				clients={data?.clients}
				previousData={previousData}
				setPagination={setPagination}
				rowSelection={rowSelection}
			/>

			<OnboardedClientsTable
				clients={data?.clients || []}
				meta={data?.meta}
				isLoading={isLoading}
				pagination={pagination}
				setPagination={setPagination}
				selectedRecords={selectedRecords}
				setSelectedRecords={setSelectedRecords}
				isFullRowSelection={true}
				setRowSelection={setRowSelection}
				rowSelection={rowSelection}
				setPreviousData={setPreviousData}
			/>
		</Fragment>
	)
}

export default ClientListingContainer
