import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Fragment, useState } from 'react'
import { FormFeedback, Input, Label } from 'reactstrap'

import eyeClose from 'assets/svg/eye-slash-svgrepo-com.svg'
import eyeOpen from 'assets/svg/eye-svgrepo-com.svg'
import PasswordValidationError from './PasswordValidationError'

export const TextField = ({
	meta: { touched, invalid, error },
	label,
	input,
	infoText,
	hideMessage = false,
	className: { formGroupClass, labelClass, inputClass },
	isValidatePassword,
	ignoreBottomMargin = false,
	isRequired = false,
	...rest
}) => {
	const [val] = useState('')
	const height =
		rest && !['checkbox', 'radio', 'textarea'].includes(rest.type) ? { height: '38px' } : {}
	const [showPassword, setShowPassword] = useState(false)

	const toggleShowPassword = () => {
		setShowPassword(!showPassword)
	}
	return (
		<Fragment>
			<div className={classNames({ 'mb-3': !ignoreBottomMargin }, formGroupClass)}>
				{label && (
					<Fragment>
						<Label className={'flex items-center text-left ' + labelClass}>
							{label}
							{isRequired && <span className='text-red-500'>&nbsp;*</span>}
						</Label>
					</Fragment>
				)}

				{input?.name === 'password' || rest?.type === 'password' ? (
					<div
						className={classNames(
							'form-control !flex items-center justify-between !px-[10px] !py-0',
							{
								'is-invalid ': touched && Boolean(invalid),
							},
						)}>
						<input
							{...rest}
							{...input}
							type={showPassword ? 'text' : 'password'}
							value={val || input.value}
							style={height}
							className={classNames('w-[90%] placeholder:!text-[#B5B5C3]', {
								'is-invalid': touched && Boolean(invalid),
							})}
						/>
						<div className='w-[10%]'>
							{showPassword ? (
								<img
									src={eyeClose}
									alt=''
									style={{ height: 20 }}
									className='logo logo-dark ms-3 cursor-pointer'
									onClick={toggleShowPassword}
								/>
							) : (
								<img
									src={eyeOpen}
									alt=''
									style={{ height: 20 }}
									onClick={toggleShowPassword}
									className='logo logo-dark ms-3 cursor-pointer'
								/>
							)}
						</div>
					</div>
				) : (
					<Input
						{...rest}
						{...input}
						value={val || input.value}
						invalid={touched && Boolean(invalid)}
						style={height}
						className={inputClass}
					/>
				)}

				{infoText && (
					<i className='text-muted' style={{ fontSize: 12 }}>
						{infoText}
					</i>
				)}
				{touched && error && !hideMessage && (
					<Fragment>
						{isValidatePassword ? (
							<PasswordValidationError type={rest?.type} value={input?.value} />
						) : (
							<FormFeedback>{touched && error}</FormFeedback>
						)}
					</Fragment>
				)}
			</div>
		</Fragment>
	)
}

TextField.propTypes = {
	input: PropTypes.object.isRequired,
	meta: PropTypes.object,
	isValidatePassword: PropTypes.bool,
	ignoreBottomMargin: PropTypes.bool,
}

TextField.defaultProps = {
	meta: null,
	className: { formGroupClass: '', labelClass: '', inputClass: '' },
	isValidatePassword: false,
	ignoreBottomMargin: false,
}
