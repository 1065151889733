import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import ReactNumberInput from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en'

import 'react-phone-number-input/style.css'

import { getFlagURLfromCode, getParsePhoneNumber } from '.'
import CountryFlagSelect from './country-flag-select'

const PhoneNumberField = ({
	id,
	form,
	label,
	field,
	placeholder,
	isRequiredField,
	labelHelperText,
	onAutoSave,
	disabled,
	autoComplete,
}) => {
	const { onBlur, name, value } = field
	const touched = useMemo(() => form?.touched[name], [form?.touched])
	const error = useMemo(() => form?.errors[name], [form?.errors])

	const phoneNumber = useMemo(() => getParsePhoneNumber(value), [value])
	const [country, setCountry] = useState(phoneNumber?.country || 'CA')
	const [flagURL, setFlagURL] = useState(getFlagURLfromCode(country))
	useEffect(() => {
		setFlagURL(getFlagURLfromCode(country))
	}, [country])

	return (
		<div className='form-group'>
			{label ? (
				<label
					htmlFor={id}
					className='flex items-baseline justify-between text-[14px] font-bold text-[#495057]'>
					<span>
						{label}{' '}
						{isRequiredField ? <span className='text-red-500'>*</span> : undefined}
					</span>
					{labelHelperText ? (
						<span className='text-[11px] font-normal'>
							<span className='text-red-500'>*</span> This asterisk indicates not
							optional
						</span>
					) : undefined}
				</label>
			) : undefined}

			<div className='flex items-center'>
				<CountryFlagSelect
					labels={en}
					value={country}
					flagURL={flagURL}
					onChange={setCountry}
					className={classNames(
						'form-control m-0 flex h-[38px] max-w-[100px] items-center !rounded-r-none !border-r-0',
						{
							'is-invalid': touched && error,
						},
					)}
				/>
				<ReactNumberInput
					id={id}
					country={country}
					placeholder={placeholder}
					className={classNames(
						'form-control !rounded-l-none placeholder:!text-[#B5B5C3]',
						{
							'is-invalid': touched && error,
						},
					)}
					autoComplete={autoComplete}
					name={name}
					disabled={disabled}
					value={value}
					international
					onChange={(value) => form.setFieldValue(name, value || '')}
					onBlur={() => {
						if (onAutoSave) {
							onAutoSave()
						}
						return onBlur
					}}
				/>
			</div>
			{touched && error ? <div className='invalid-feedback text-xs'>{error}</div> : undefined}
		</div>
	)
}

PhoneNumberField.defaultProps = {
	autoComplete: 'off',
}

export default PhoneNumberField
