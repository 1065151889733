import { Link } from 'react-router-dom'

import { isAllowTo } from 'utilities/helpers'
import notification from 'utilities/notification'
import { permissions } from 'utilities/permissions'
import { OnBoardStatuses } from '.'
import { useSendClientInvitationMutation } from '../client-apis'
import { CLIENT_APP_ROUTES } from '../routes'

const Actions = ({ isActionActive, created_at, user }) => {
	const [sendInvite] = useSendClientInvitationMutation()

	const onResend = async (user) => {
		const payload = {
			first_name: user?.first_name,
			last_name: user?.last_name,
			email: user?.email,
			group_id: user?.group?.id,
			resend: true,
		}
		const response = await sendInvite(payload)
		if (response?.data?.status === 200) {
			notification('success', response?.data?.message)
		}
	}

	return (
		<div className='w-[170px]'>
			{isActionActive ? (
				<div>
					{isAllowTo(permissions?.client?.verify) ? (
						<div className='flex space-x-3'>
							<Link
								to={CLIENT_APP_ROUTES.editInvitedClient(user?.id)}
								className='action-btn btn-primary-outline'>
								<i className='far fa-edit' />
								<span>Edit</span>
							</Link>

							{user?.status === OnBoardStatuses?.PENDING ? (
								<button
									onClick={() => onResend(user)}
									type='button'
									className='action-btn btn-gray-outline'>
									<i className='bx bx-send' />
									<span>Resend</span>
								</button>
							) : (
								<Link
									to={CLIENT_APP_ROUTES.verifyClient(user?.id)}
									className='action-btn btn-primary-outline'>
									<i className='mdi mdi-magnify-scan' />
									<span>Verify</span>
								</Link>
							)}
						</div>
					) : undefined}
				</div>
			) : (
				<span className=' text-sm !text-gray-700'>{created_at}</span>
			)}
		</div>
	)
}

export default Actions
