import { Field } from 'formik'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { SelectField } from 'theme/ui/forms'
import StatusQuery from '../../StatusQuery'

const StatusDropdown = ({ formik }) => {
	const { statuses } = StatusQuery()
	const params = useParams()

	const statusOptions = useMemo(
		() =>
			Array.isArray(statuses)
				? statuses.map((status) => ({
						label: (
							<div className='flex items-center space-x-2'>
								<div
									className='h-2 w-2 rounded-full'
									style={{
										backgroundColor: status?.color,
									}}
								/>
								<div>{status?.title}</div>
							</div>
						),
						value: status?.title,
				  }))
				: [],
		[statuses],
	)

	useEffect(() => {
		if (statusOptions && !params?.id) {
			formik.setFieldValue('progress_status', statusOptions[0])
		}
	}, [statusOptions])

	return (
		<div className='mt-2'>
			<Field
				label='Select Progress status'
				type='select'
				name='progress_status'
				component={SelectField}
				placeholder='Select Progress status'
				options={statusOptions}
				isRequiredField
				isClearable={false}
			/>
		</div>
	)
}

export default StatusDropdown
