import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	selectedFolders: [],
	selectedDocuments: [],
}

export const FileManagerSlice = createSlice({
	name: 'fileManager',
	initialState,
	reducers: {
		setSelectedFolders: (state, action) => {
			state.selectedFolders = action.payload
		},
		setSelectedDocuments: (state, action) => {
			state.selectedDocuments = action.payload
		},
	},
})
