import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { SpinLoader } from 'components/Common'
import FeatureForm from 'modules/subscriptions/features/feature-form'
import { useLazyGetFeatureQuery } from 'modules/subscriptions/features/features-api'
import Pages404 from 'pages/StaticPages/Page404'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const FeatureEdit = () => {
	const params = useParams()
	const Id = params?.id
	const [fetchFeature, { data, isFetching }] = useLazyGetFeatureQuery()

	useEffect(() => {
		if (Id) {
			fetchFeature(Id)
		}
	}, [Id])

	return (
		<AppLayout
			meta={{
				title: 'Edit Features',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<PageHeader
						title='Features'
						hasBackButton={true}
						description='Easily manage your Features hassle-free from one convenient dashboard. Stay in control of your spending, effortlessly track renewal dates, and make changes with just a few clicks, all in one centralized hub designed for your convenience'
					/>

					<div className='page-body'>
						{isFetching ? (
							<SpinLoader />
						) : (
							<>{data?.data ? <FeatureForm feature={data?.data} /> : <Pages404 />}</>
						)}
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default FeatureEdit
