import { useQuery } from 'hooks'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { formatDate } from 'utilities/helpers'
import { formatBytes, toQueryString } from 'utils/commonFunctions'
import { getFilters } from '.'

const WorkflowFilesQuery = (user_id, groupSlug, pagination) => {
	const { pageIndex, pageSize } = pagination
	const formValues = useSelector((state) => state.form.workflows_files_filters?.values) || {}

	const filters = useMemo(() => {
		const filterValues = getFilters(formValues)
		const obj = { page: pageIndex + 1, limit: pageSize, ...filterValues }
		return obj
	}, [pageIndex, pageSize, formValues])

	const query = useMemo(() => toQueryString(filters), [filters])
	const URL = useMemo(() => {
		const _groupSlug = groupSlug === 'client' ? 'client' : 'advisor'
		return user_id ? `/${_groupSlug}/${user_id}/workflow-files?${query}` : undefined
	}, [user_id, query, groupSlug])
	const { data, isLoading } = useQuery(URL)

	const files = useMemo(
		() =>
			Array.isArray(data?.data?.workflowFiles)
				? data?.data?.workflowFiles.map((file, idx) => ({
						index: idx + 1 + pageIndex * pageSize,
						id: file?.id,
						title: file?.title,
						file: file?.files,
						file_size: formatBytes(file?.files?.size),
						created_at: formatDate(file?.files?.created_at),
				  }))
				: [],
		[data?.data?.workflowFiles],
	)

	return {
		files: files,
		meta: data?.data?.meta || {},
		isLoading: isLoading,
	}
}

export default WorkflowFilesQuery
