/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable array-callback-return */
import { userTypes } from 'modules/workflows'
import { flattenArray, showErrorToast, toJson } from '../../../utils/commonFunctions'

export const findUploadSteps = (steps) => {
	if (Array.isArray(steps)) {
		return steps.filter((item) => item?.type === 'uploads')
	} else {
		return []
	}
}

export const getReviewFileTabs = (uploads) => {
	if (Array.isArray(uploads) && uploads[0]?.note) {
		const tabsValue = toJson(uploads[0]?.note).map((item) => item?.user_type)
		return userTypes.filter((item) => tabsValue.includes(item?.value))
	} else {
		return []
	}
}

export const getUploadsReview = (uploads) => {
	if (Array.isArray(uploads)) {
		return uploads.map((item) => {
			const fileTypes = toJson(item?.note)
			return {
				file: item?.files,
				info: fileTypes,
			}
		})
	} else {
		return []
	}
}

// remove duplicate objects by key from array
export const getUniqueListBy = (arr, key) => {
	return [...new Map(arr.map((item) => [item[key], item])).values()]
}

// combine all the fields of document into singal array
export const getFieldsArray = (array) => {
	if (Array.isArray(array)) {
		const mappedArray = array.map((item) => item?.document_body?.document || [])
		return flattenArray(mappedArray)
	} else {
		return []
	}
}

export const getReviewFields = (array) => {
	if (Array.isArray(array)) {
		const fieldsArray = getFieldsArray(array)
		// return getUniqueListBy(fieldsArray, 'name')
		return fieldsArray.filter(
			(value, index, self) =>
				index ===
				self.findIndex((t) => t.user_type === value.user_type && t.name === value.name),
		)
	} else {
		return []
	}
}

export const addStatusToFields = (array, values) => {
	if (Array.isArray(array) && Array.isArray(values)) {
		return array.map((field) => {
			let fieldObj = { ...field }

			const value = values.find(
				(i) => i?.field_name === field?.name && i?.user_type === field?.user_type,
			)
			if (value) {
				Object.assign(fieldObj, {
					status: value?.status,
					value: value?.field_value,
				})
			}

			return fieldObj
		})
	} else {
		return []
	}
}

export const addStatusToFiles = (array, values, user_type) => {
	if (Array.isArray(array) && Array.isArray(values)) {
		return array.map((file) => {
			let fileObj = { ...file }

			const value = values.find((i) => i?.title === file?.title && i?.user_type === user_type)
			if (value) {
				Object.assign(fileObj, { correction: value?.status === 'approved' ? false : true })
			}
			return fileObj
		})
	} else {
		return []
	}
}

export const getAllFiles = (uploads) => {
	if (Array.isArray(uploads)) {
		const files = uploads.map((upload) =>
			upload?.files.map((file) => ({ ...file, client_type: upload?.client_type })),
		)
		return flattenArray(files)
	} else {
		return []
	}
}

export const AUTO_APPROVED = 'autoApproved'
export const MANUAL_APPROVED = 'manualApproved'

export function getInitialValues(individualDocument, uploads, user_type) {
	const fields = getReviewFields(individualDocument?.documents).filter(
		(field) => field?.user_type === user_type,
	)
	const files = getAllFiles(uploads)

	const userTypes = uploads.map((item) => item?.client_type)
	const reviews = uploads.map((item) => item?.review_notes)
	const filesReview = {}
	userTypes.forEach((el, idx) => {
		Object.assign(filesReview, { [el]: reviews[idx] })
	})

	// const fieldsReview = toJson(individualDocument?.review_notes);
	const fieldsReview = individualDocument?.review_notes

	const initialValues = {
		fields: {
			[MANUAL_APPROVED]: fields
				.filter((field) => field?.isDefaultApproved === false)
				.map((field) => ({
					field_name: field?.name,
					field_value: field?.value,
					user_type: field?.user_type,
					status: field?.status || 'approved',
				})),
			[AUTO_APPROVED]: fields
				.filter((field) => field?.isDefaultApproved === true)
				.map((field) => ({
					field_name: field?.name,
					field_value: field?.value,
					user_type: field?.user_type,
					status: field?.status || 'approved',
				})),
		},
		files: files.map((file) => ({
			title: file?.title,
			user_type: file?.client_type,
			status: file?.correction ? 'reject' : 'approved',
		})),
		files_notes: filesReview,
		fields_notes: fieldsReview,
	}
	return initialValues
}

export function preparePayload(workflow, values) {
	try {
		const payload = { title: `${Math.random()}` }
		const fieldValues = [...values?.fields[AUTO_APPROVED], ...values?.fields[MANUAL_APPROVED]]
		payload.steps = workflow?.workflow_steps.map((step) => {
			let stepObj = { id: step?.id, notify: step?.notify, delete: false }

			if (step?.type === 'uploads') {
				stepObj.files = addStatusToFiles(step?.files, values?.files, step?.client_type)
				stepObj.type = 'uploads'
				stepObj.status =
					values?.files.filter(
						(file) =>
							file?.status === 'reject' && file?.user_type === step?.client_type,
					).length > 0
						? 'correction'
						: null
				stepObj.review_notes = values?.files_notes[step?.client_type] || null
			}

			if (step?.type === 'individual_document') {
				stepObj['documents'] = step?.documents
					.map((item) => {
						if (Array.isArray(item?.document_body?.document)) {
							return {
								id: item?.id,
								title: item?.title,
								document_body: addStatusToFields(
									item?.document_body?.document,
									fieldValues,
								),
							}
						}
					})
					.filter((item) => item)

				stepObj['rejected_fields'] = stepObj['documents'].map((item) => ({
					...item,
					document_body: item?.document_body
						.filter((doc) => doc?.status === 'reject')
						.map((field) => field?.label),
				}))

				stepObj.type = 'individual_document'
				stepObj.status =
					fieldValues.filter((field) => field?.status === 'reject').length > 0
						? 'correction'
						: null
				stepObj.review_notes = values?.fields_notes
			}

			return stepObj
		})
		return payload
	} catch (error) {
		showErrorToast('Something went wrong while processing request.')
	}
}
