import PaymentsDetailsContainer from 'modules/payments/payent-details/payment-details-container'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const PaymentDetails = () => {
	return (
		<AppLayout
			meta={{
				title: 'Payment details',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<PageHeader
						title='Payments'
						hasBackButton={true}
						description='Easily manage your payments hassle-free from one convenient dashboard. Stay in control of your finances, effortlessly track transaction history, and make changes with just a few clicks, all in one centralized hub designed for your convenience.'
					/>
					<div className='page-body'>
						<PaymentsDetailsContainer />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default PaymentDetails
