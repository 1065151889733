import { Field, FormikProvider, useFormik } from 'formik'

import { useAppRouter } from 'hooks'
import { Button, InputField, SelectField, TextareaField } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { durationOptions, getInitialValues, getPayload, planTypeOptions, validationSchema } from '.'
import { SUBSCRIPTION_APP_ROUTES } from '../routes'
import { useCreateSubscriptionMutation, useUpdateSubscriptionMutation } from '../subscriptions-api'
import FeaturesListField from './features-list-field'

const SubscriptionForm = ({ subscription }) => {
	const [createSubscription] = useCreateSubscriptionMutation()
	const [updateSubscription] = useUpdateSubscriptionMutation()
	const { goBack, navigate, hasHistory } = useAppRouter()
	const initialValues = getInitialValues(subscription)

	const formik = useFormik({
		initialValues: initialValues,
		enableReinitialize: true,
		validationSchema: validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true)
				const payload = getPayload(values)

				let response
				if (subscription) {
					response = await updateSubscription({ payload: payload, id: subscription?.id })
				} else {
					response = await createSubscription(payload)
				}

				if (response?.data?.status === 200) {
					notification('success', response?.data?.message)
					return hasHistory ? goBack() : navigate(SUBSCRIPTION_APP_ROUTES.findAll())
				}

				setSubmitting(false)
			} catch (error) {
				notification('warn', error?.message)
			}
		},
	})

	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				<div className='flex w-full gap-10'>
					<div className='form-container'>
						<div className='form-row'>
							<div className='form-heading'>
								<h3>Basic Details</h3>
								<p>
									Please enter the payment & duration details of subscription
									here.
								</p>
							</div>
							<div className='form-fields-card space-y-6'>
								<Field
									label='Select Plan Type'
									type='select'
									name='plan_type'
									component={SelectField}
									isSearchable={false}
									placeholder='Select Plan Type'
									options={planTypeOptions}
									isRequiredField
								/>
								<Field
									label='Enter Subscription description'
									placeholder='Enter Subscription description'
									name='description'
									type='textarea'
									component={TextareaField}
									isRequiredField
								/>
							</div>
						</div>

						<div className='form-row'>
							<div className='form-heading'>
								<h3>Payment & Duration</h3>
								<p>
									Please enter the payment & duration details of subscription
									here.
								</p>
							</div>
							<div className='form-fields-card space-y-6'>
								<Field
									label='Select Duration'
									type='select'
									name='duration'
									component={SelectField}
									isSearchable={false}
									placeholder='Select Duration'
									options={durationOptions}
									isRequiredField
								/>

								<Field
									name='price'
									type='number'
									label='Enter Price'
									placeholder='0.00'
									component={InputField}
									isRequiredField
								/>
							</div>
						</div>

						<div className='form-row'>
							<div className='form-heading'>
								<h3>Features Details</h3>
								<p>
									Please the features from below list to assign in the
									subscription
								</p>
							</div>
							<div className='form-fields-card space-y-6 '>
								<FeaturesListField formik={formik} />
							</div>
						</div>
					</div>
					<div className='max-w-[200px]'>
						<div className='flex items-start space-x-2 !py-5'>
							<i className='mdi mdi-lightbulb-on text-main' />
							<p className='text-xs text-gray-500'>
								Easily manage your subscription hassle-free from one convenient
								dashboard.
							</p>
						</div>
					</div>
				</div>
				<div className='form-container !border-t py-6'>
					<div className='flex justify-end'>
						<Button
							type='submit'
							isLoading={formik.isSubmitting}
							isLoadingText='Saving...'>
							Save
						</Button>
					</div>
				</div>
			</form>
		</FormikProvider>
	)
}

export default SubscriptionForm
