import { createApi } from '@reduxjs/toolkit/query/react'
import apiEndpoints from 'helpers/apiEndpoints'

import axiosBaseQuery from 'store/axios-base-query'

const API_URL = apiEndpoints.notificationsMaster

export const notificationsTemplatesApi = createApi({
	reducerPath: 'NotificationsTemplates',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['NotificationsTemplates'],
	endpoints: (builder) => ({
		getNotificationsTemplates: builder.query({
			keepUnusedDataFor: 0,
			query: (params) => ({
				url: API_URL,
				method: 'GET',
				params: params,
			}),
			providesTags: ['NotificationsTemplates'],
			transformResponse: (response, _, arg) => {
				const notificationsTemplates = Array.isArray(response?.data)
					? response?.data?.map((templates, idx) => ({
							index: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + idx + 1,
							id: templates?.id,
							name: templates?.title,
							slug: templates?.slug,
							message: templates?.message,
					  }))
					: []
				return {
					notificationsTemplates,
					meta: response?.meta,
				}
			},
		}),
		getNotificationTemplate: builder.query({
			keepUnusedDataFor: 0,
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'GET',
			}),
			providesTags: ['NotificationsTemplates'],
			transformResponse: (response) => {
				const notificationsTemplate = response?.data
					? {
							content: response?.data?.content,
							id: response?.data?.id,
							name: response?.data?.title,
							slug: response?.data?.slug,
							subject: response?.data?.title,
					  }
					: null
				return {
					notificationsTemplate,
				}
			},
		}),
		updateNotificationTemplate: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${API_URL}/${id}`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['NotificationsTemplates'],
		}),
	}),
})

export const {
	useLazyGetNotificationsTemplatesQuery,
	useLazyGetNotificationTemplateQuery,
	useUpdateNotificationTemplateMutation,
} = notificationsTemplatesApi
