import { useMemo } from 'react'

const OwnerDetailsTab = ({ owner }) => {
	const $owner = useMemo(
		() => [
			{ label: 'Name', value: owner?.fullName },
			{ label: 'Email', value: owner?.email },
			{ label: 'Phone Number', value: owner?.phone_number },
		],
		[owner],
	)

	return (
		<div className='rounded-md bg-white p-3'>
			<h5 className='!mb-6 font-semibold text-gray-700'>Owner Details</h5>
			<div className='flex flex-wrap gap-6'>
				{$owner.map((user, idx) => (
					<div key={idx} className='flex flex-col'>
						<span className='text-[13px] text-gray-500'>{user?.label}</span>
						<span className='text-sm font-semibold text-gray-800'>{user?.value}</span>
					</div>
				))}
			</div>
		</div>
	)
}

export default OwnerDetailsTab
