import { SelectField } from 'components/Forms/SelectComponent'
import { Fragment } from 'react'
import { Field } from 'redux-form'

export default function SystemFieldsArray({ csvFields, dbFieldsOptions, firstRow }) {
	return (
		<Fragment>
			{csvFields.map((field, idx) => {
				return (
					<Fragment key={idx}>
						<div className='row flex'>
							<div className='col-4'>
								<div className='form-group mb-3'>
									<label>{field.name}</label>

									<p className=''>
										{firstRow[idx]
											? `(e.g. - ${
													firstRow[idx].length > 20
														? firstRow[idx].substring(0, 20) + '...'
														: firstRow[idx]
											  })`
											: null}
									</p>
								</div>
							</div>
							<div className='col-4 flex justify-center py-[15px]'>
								<i className='uil-arrow-right text-[30px]'></i>
							</div>
							<div className='col-4 flex w-full flex-col'>
								<Field
									type='hidden'
									name={`fields[${idx}].csv`}
									component='input'
								/>
								<Field
									type='hidden'
									name={`fields[${idx}].index`}
									component='input'
								/>
								<div className='mt-3'>
									<Field
										name={`fields[${idx}].system`}
										component={SelectField}
										options={dbFieldsOptions}
									/>
								</div>
							</div>
						</div>
					</Fragment>
				)
			})}
		</Fragment>
	)
}
