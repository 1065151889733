import { Fragment, useEffect, useMemo } from 'react'

import { SpinLoader } from 'components/Common'
import { useLazyGetMySubscriptionQuery } from 'modules/subscriptions/user-subscriptions-api'
import CurrentSubscription from './current-subscription'
import DangerZone from './danger-zone'
import PaymentsDetails from './payments-details'
import UsageSubscription from './usage-subscription'

const ActiveSubscription = ({ activeTab }) => {
	const [fetchMySubscription, { isLoading, data }] = useLazyGetMySubscriptionQuery()
	useEffect(() => {
		if (activeTab === 3) {
			fetchMySubscription()
		}
	}, [activeTab])
	const $subscription = useMemo(() => data?.data, [data?.data])
	const subscription = useMemo(() => $subscription?.subscription, [$subscription])
	const payments = useMemo(() => $subscription?.payments || [], [$subscription])

	return (
		<Fragment>
			{isLoading ? (
				<SpinLoader />
			) : (
				<Fragment>
					{subscription ? (
						<div className='grid grid-cols-1 gap-8'>
							<CurrentSubscription subscription={subscription} />

							<UsageSubscription
								subscription={subscription}
								$subscription={$subscription}
							/>

							<PaymentsDetails payments={payments} />

							<DangerZone subscription={subscription} />
						</div>
					) : (
						<div>
							<p className='text-center text-gray-500'>
								No active subscription details found.
							</p>
						</div>
					)}
				</Fragment>
			)}
		</Fragment>
	)
}

export default ActiveSubscription
