import PdfPreview from './pdf-viewer'

export const getCoordinates = (field) => {
	const { x, y, isCheckbox } = field
	return {
		x: isCheckbox ? (x * 595) / 37.188 + 3 : (x * 595) / 37.188,
		y: isCheckbox ? (y * 842) / 52.625 + 3 : (y * 842) / 52.625 - 0,
		width: 6,
		height: 6,
	}
}

export default PdfPreview
