import { Fragment } from 'react'

const FieldMapping = ({ document }) => {
	const documentMappings = Array.isArray(document?.document_mapping)
		? document?.document_mapping
		: []
	return (
		<div className='max-h-[100vh] bg-white p-6'>
			<div className=''>
				<h3 className='mb-1 text-lg'>Fields Mapping</h3>
				<p className='text-sm text-gray-500'>
					Here, you can view the PDF field mapped to the system field, along with the
					associated user role and an indication of whether the field is document-bound.
				</p>
			</div>
			<div className='slim-scroll !max-h-[88%] overflow-auto rounded-b-lg '>
				<div className='relative overflow-x-auto !border sm:rounded-b-lg'>
					<table className='m-0 w-full text-left text-sm text-gray-500 rtl:text-right'>
						<thead className='text-xs uppercase text-gray-700 '>
							<tr className='border-b border-gray-200'>
								<th scope='col' className='px-6 py-3'>
									#
								</th>
								<th scope='col' className='bg-gray-50 px-6 py-3 '>
									PDF Field
								</th>
								<th scope='col' className='px-6 py-3'>
									System Field
								</th>
								<th scope='col' className='px-6 py-3'>
									User Type
								</th>
								<th scope='col' className='px-6 py-3'>
									Is Document Bound
								</th>
							</tr>
						</thead>
						<tbody>
							{documentMappings.length > 0 ? (
								<Fragment>
									{documentMappings.map((field, idx) => {
										return (
											<tr key={idx} className='border-b border-gray-200'>
												<td className='!px-6 !py-4'>{idx + 1}.</td>
												<th
													scope='row'
													className='whitespace-nowrap bg-gray-50 !px-6 !py-4 font-medium text-gray-900'>
													{field?.pdf_field}
												</th>
												<td className='!px-6 !py-4'>
													{field?.field?.label}
												</td>
												<td className='!px-6 !py-4'>
													{field?.user?.label}
												</td>
												<td className='!px-6 !py-4'>
													{field?.is_document_bound ? 'Yes' : 'No'}
												</td>
											</tr>
										)
									})}
								</Fragment>
							) : (
								<tr>
									<td colSpan={5} className='!px-6 !py-4'>
										<p className='m-0 text-center text-sm text-gray-400'>
											No Document Mappings found
										</p>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

export default FieldMapping
