import moment from 'moment'
import { useState } from 'react'

import { useUser } from 'hooks'
import AsyncImage from 'theme/ui/async-image'
import DeleteConfirmAlert from 'theme/ui/delete-confirm-alert'
import CommentForm from './comment-form'
import { useDeleteWorkflowCommentMutation } from './workflow-comment-api'

const CommentCard = ({ comment, workflowId, stepId, isReply = true }) => {
	const [isReplyOpen, setIsReplyOpen] = useState(false)
	const [isEditMode, setIsEditMode] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const { user } = useUser()
	const [deleteComment, { isLoading }] = useDeleteWorkflowCommentMutation()

	const onDelete = async (id) => {
		await deleteComment(id)
		setIsOpen(false)
	}

	const isActionAllow = comment?.user?.id === user?.id

	return (
		<article className='!px-6 !py-3'>
			<DeleteConfirmAlert
				title='Delete this Comment?'
				subtitle='Once you delete the comment, it cannot be recovered.'
				isDeleting={isLoading}
				onClose={() => setIsOpen(false)}
				isOpen={isOpen}
				onConfirm={() => onDelete(comment?.id)}
			/>
			<div className='flex w-full space-x-3'>
				<AsyncImage
					className='mr-2 h-7 w-7 rounded-full'
					src={comment?.user?.avatar?.path}
					alt={comment?.user?.fullName}
				/>
				<div className='flex w-[calc(100%_-_28px)] flex-col space-y-3'>
					<div className='flex items-center space-x-3'>
						<p className='mb-0 mr-3 inline-flex items-center text-sm font-semibold text-gray-900 '>
							{comment?.user?.fullName}
						</p>
						<p className='m-0 text-[13px] text-gray-400 '>
							<time
								dateTime={moment(comment?.created_at).format('YYYY-M-D')}
								title={moment(comment?.created_at).format('MMMM. D, YYYY')}>
								{moment(comment?.created_at).format('MMMM DD, YYYY')} at{' '}
								{moment(comment?.created_at).format('h:mm A')}
							</time>
						</p>
					</div>
					{isEditMode ? (
						<CommentForm
							onClose={() => setIsEditMode(false)}
							comment={comment}
							parent_comment_id={comment?.id}
							stepId={stepId}
							workflowId={workflowId}
						/>
					) : (
						<>
							<div>
								<p className='m-0 !text-[15px] text-gray-600'>{comment?.message}</p>
							</div>
							<div className='flex flex-col space-y-1'>
								<div className='flex items-center space-x-1'>
									{isReply ? (
										<>
											<button
												onClick={() => setIsReplyOpen(!isReplyOpen)}
												type='button'
												className='flex items-center text-sm font-bold text-gray-500 hover:underline '>
												Reply
											</button>
											<span className='font-bol text-xl'>·</span>
										</>
									) : undefined}

									{isActionAllow ? (
										<>
											<button
												type='button'
												onClick={() => setIsEditMode(true)}
												className='flex items-center text-sm font-bold text-gray-500 hover:underline '>
												Edit
											</button>
											<span className='font-bol text-xl'>·</span>
											<button
												onClick={() => setIsOpen(true)}
												type='button'
												className='flex items-center text-sm font-bold text-gray-500 hover:underline '>
												Delete
											</button>
										</>
									) : undefined}
								</div>
								{isReplyOpen ? (
									<CommentForm
										parent_comment_id={comment?.id}
										stepId={stepId}
										workflowId={workflowId}
										onClose={() => setIsReplyOpen(!isReplyOpen)}
									/>
								) : undefined}
							</div>
						</>
					)}
				</div>
			</div>
		</article>
	)
}

export default CommentCard
