import eyeClose from 'assets/svg/eye-slash-svgrepo-com.svg'
import eyeOpen from 'assets/svg/eye-svgrepo-com.svg'
import classNames from 'classnames'
import { useMemo, useState } from 'react'

const InputField = ({
	id,
	form,
	label,
	field,
	placeholder,
	isRequiredField,
	labelHelperText,
	helperText,
	onAutoSave,
	type,
	disabled,
	autoComplete,
}) => {
	const { onChange, onBlur, name, value } = field

	const touched = useMemo(() => form?.touched[name], [form?.touched])
	const error = useMemo(() => form?.errors[name], [form?.errors])
	const [showPassword, setShowPassword] = useState(false)

	const toggleShowPassword = () => {
		setShowPassword(!showPassword)
	}
	return (
		<div className='form-group'>
			{label ? (
				<label
					htmlFor={id}
					className='flex items-baseline justify-between text-[14px] font-bold text-[#495057]'>
					<span>
						{label}{' '}
						{isRequiredField ? <span className='text-red-500'>*</span> : undefined}
					</span>
					{labelHelperText ? (
						<span className='text-[11px] font-normal'>
							<span className='text-red-500'>*</span> This asterisk indicates not
							optional
						</span>
					) : undefined}
				</label>
			) : undefined}
			{name !== 'password' && type !== 'password' ? (
				<input
					id={id}
					onChange={onChange}
					onBlur={() => {
						if (onAutoSave) {
							onAutoSave()
						}
						return onBlur
					}}
					name={name}
					type={type}
					value={value}
					disabled={disabled}
					placeholder={placeholder}
					autoComplete={autoComplete}
					className={classNames('form-control placeholder:!text-[#B5B5C3]', {
						'is-invalid': touched && error,
					})}
				/>
			) : undefined}
			{name === 'password' || type === 'password' ? (
				<div
					className={classNames('form-control !flex items-center justify-between', {
						'is-invalid !px-[10px]': touched && error,
					})}>
					<input
						id={id}
						onChange={onChange}
						onBlur={() => {
							if (onAutoSave) {
								onAutoSave()
							}
							return onBlur
						}}
						name={name}
						type={showPassword ? 'text' : 'password'}
						value={value}
						disabled={disabled}
						placeholder={placeholder}
						autoComplete='off'
						className={classNames('w-[90%] placeholder:!text-[#B5B5C3]', {
							'is-invalid': touched && error,
						})}
					/>
					<div className='w-[10%]'>
						{showPassword ? (
							<img
								src={eyeClose}
								alt=''
								style={{ height: 20 }}
								className='logo logo-dark ms-3 cursor-pointer'
								onClick={toggleShowPassword}
							/>
						) : (
							<img
								src={eyeOpen}
								alt=''
								style={{ height: 20 }}
								onClick={toggleShowPassword}
								className='logo logo-dark ms-3 cursor-pointer'
							/>
						)}
					</div>
				</div>
			) : undefined}
			{helperText ? (
				<span className='text-[13px] italic text-gray-400'>{helperText}</span>
			) : undefined}
			{touched && error ? <div className='invalid-feedback text-xs'>{error}</div> : undefined}
		</div>
	)
}

InputField.defaultProps = {
	autoComplete: 'off',
}

export default InputField
