import { Header } from 'modules/workflows'
import WorkflowsTemplatesContainer from 'modules/workflows/workflow-template/workflows-list'
import AppLayout from 'theme/layouts/app-layout'

const WorkflowTemplatesListing = () => {
	return (
		<AppLayout
			meta={{
				title: 'Workflow Templates',
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<div className='page-header bg-white'>
						<Header />
					</div>
					<div className='page-body'>
						<WorkflowsTemplatesContainer />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default WorkflowTemplatesListing
