import DocumentTemplateDropdown from './document-template-dropdown'

export const getDocumentOptions = (documents) => {
	if (Array.isArray(documents)) {
		return documents.map((item) => ({ label: item?.title, value: item?.id }))
	} else {
		return []
	}
}

export default DocumentTemplateDropdown
