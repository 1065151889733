import { Field, FormikProvider, useFormik } from 'formik'
import { useAppRouter } from 'hooks'
import { useMemo } from 'react'
import Badge from 'theme/ui/badge'
import { Button, InputField, SelectField } from 'theme/ui/forms'
import { formatPrice, getSubStatusVariant } from 'utilities/helpers'
import notification from 'utilities/notification'
import { getInitialValues, getPayload, planTypeOptions, validationSchema } from '.'
import { useUpdatePaymentMutation } from '../payments-api'
import { PAYMENTS_APP_ROUTES } from '../routes'

const PaymentForm = ({ payment }) => {
	const [updatePayment] = useUpdatePaymentMutation()
	const { goBack, navigate, hasHistory } = useAppRouter()
	const subscription = useMemo(() => payment?.subscription, [payment?.subscription])
	const initialValues = getInitialValues(payment)

	const formik = useFormik({
		initialValues: initialValues,
		enableReinitialize: true,
		validationSchema: validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true)
				const payload = getPayload(values)
				let response
				if (payment) {
					response = await updatePayment({ payload: payload, id: payment?.id })
				}

				if (response?.data?.status === 200) {
					notification('success', response?.data?.message)
					return hasHistory ? goBack() : navigate(PAYMENTS_APP_ROUTES.findAll())
				}

				setSubmitting(false)
			} catch (error) {
				notification('warn', error?.message)
			}
		},
	})

	return (
		<>
			<FormikProvider value={formik}>
				<form onSubmit={formik.handleSubmit}>
					<div className='flex w-full gap-10'>
						<div className='form-container'>
							<div className='form-row'>
								<div className='form-heading'>
									<h3>User Details</h3>
									<p>User Name & email details </p>
								</div>
								<div className='form-fields-card space-y-6'>
									<div className='flex flex-col'>
										<span className='text-sm font-bold text-gray-900'>
											{payment?.user?.fullName}
										</span>
										<span className='text-sm text-gray-500'>
											{payment?.user?.email}
										</span>
									</div>
								</div>
							</div>
							<div className='form-row'>
								<div className='form-heading'>
									<h3>Subscription Details</h3>
									<p>Subscription type, price & status details </p>
								</div>
								<div className='form-fields-card flex flex-row justify-between'>
									<div className='flex flex-col'>
										<span className='text-sm capitalize text-gray-500'>
											Type
										</span>
										<span className='text-sm font-bold capitalize text-gray-900 '>
											{subscription?.plan?.plan_type}
										</span>
									</div>
									<div className='flex flex-col'>
										<span className='text-sm capitalize text-gray-500'>
											Price
										</span>
										{parseInt(subscription?.plan_price) !==
										parseInt(subscription?.plan?.price) ? (
											<div>
												<span className='text-sm font-bold capitalize text-gray-800 '>
													{formatPrice(subscription?.plan_price)}
												</span>
												<span className='ml-1 text-[13px] font-normal capitalize text-gray-500 line-through'>
													{formatPrice(subscription?.plan?.price)}
												</span>
											</div>
										) : (
											<span className='text-sm font-bold capitalize text-gray-800'>
												{formatPrice(subscription?.plan?.price)}
											</span>
										)}
									</div>
									<div className='flex flex-col'>
										<span className='text-sm capitalize text-gray-500'>
											Status
										</span>
										<div className='mt-1'>
											<Badge
												variant={getSubStatusVariant(subscription?.status)}>
												{subscription?.status}
											</Badge>
										</div>
									</div>
								</div>
							</div>
							<div className='form-row'>
								<div className='form-heading'>
									<h3>Payment Details</h3>
									<p>Edit method & amount of payment here.</p>
								</div>
								<div className='form-fields-card space-y-6'>
									<Field
										label='Payment Method'
										type='select'
										name='payment_method'
										component={SelectField}
										isSearchable={false}
										placeholder='Select Plan Type'
										options={planTypeOptions}
										isRequiredField
									/>
									<Field
										name='amount'
										type='number'
										label='Purchased Amount'
										placeholder='0.00'
										component={InputField}
										isRequiredField
									/>
									<Field
										name='transaction_id'
										type='text'
										label='Transaction Id'
										placeholder='0.00'
										component={InputField}
									/>
								</div>
							</div>
						</div>
						<div className='max-w-[200px]'>
							<div className='flex items-start space-x-2 !py-5'>
								<i className='mdi mdi-lightbulb-on text-main' />
								<p className='text-xs text-gray-500'>
									Easily manage your payments hassle-free from one convenient
									dashboard.
								</p>
							</div>
						</div>
					</div>
					<div className='form-container !border-t py-6'>
						<div className='flex justify-end'>
							<Button
								type='submit'
								isLoading={formik.isSubmitting}
								isLoadingText='Saving...'>
								Save
							</Button>
						</div>
					</div>
				</form>
			</FormikProvider>
		</>
	)
}

export default PaymentForm
