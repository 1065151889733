import { createApi } from '@reduxjs/toolkit/query/react'
import apiEndpoints from 'helpers/apiEndpoints'
import { CLIENT_API_ROUTES } from 'modules/clients/routes'

import axiosBaseQuery from 'store/axios-base-query'
import { formatDate } from 'utilities/helpers'

export const clientNotesApi = createApi({
	reducerPath: 'ClientNotes',
	keepUnusedDataFor: 0,
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['ClientNote'],
	endpoints: (builder) => ({
		getNotes: builder.query({
			keepUnusedDataFor: 0,
			query: ({ id, params }) => ({
				url: CLIENT_API_ROUTES.findAllNotes(id),
				method: 'GET',
				params: params,
			}),
			providesTags: ['ClientNote'],
			transformResponse: (response, _, arg) => {
				const notes = Array.isArray(response?.data?.notes)
					? response?.data?.notes.map((note, idx) => ({
							...note,
							index: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + idx + 1,
							created_at: formatDate(note?.created_at),
					  }))
					: []

				return {
					notes: notes,
					meta: response?.data?.meta,
				}
			},
		}),
		createNote: builder.mutation({
			query: (payload) => ({
				url: apiEndpoints.notes,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['ClientNote'],
		}),
		updateNote: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${apiEndpoints.notes}/${id}`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['ClientNote'],
		}),
	}),
})

export const { useLazyGetNotesQuery, useCreateNoteMutation, useUpdateNoteMutation } = clientNotesApi
