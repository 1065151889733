import { Field } from 'formik'
import { useMemo, useState } from 'react'
import { SelectField } from 'theme/ui/forms'
import { getUsersOptions, UsersQuery } from '.'

const UsersDropdownField = ({ isDisabled, isRequiredField }) => {
	const [search, setSearch] = useState('')
	const onInputChange = (value) => setSearch(value)
	const { users, isLoading: isTeamsLoading } = UsersQuery(search)
	const teamOptions = useMemo(() => getUsersOptions(users), [users, isTeamsLoading])

	return (
		<Field
			type='select'
			label='Select User'
			name='user_ids'
			id='user_ids'
			options={teamOptions}
			isMulti
			isLoading={isTeamsLoading}
			onInputChange={onInputChange}
			isSearchable={true}
			component={SelectField}
			isDisabled={isDisabled}
			isRequiredField={isRequiredField}
			isClearable={false}
		/>
	)
}

export default UsersDropdownField
