import { useUser } from 'hooks'
import { Fragment, useState } from 'react'
import Dropzone from 'react-dropzone'
import { Button } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { formatBytes } from 'utils/commonFunctions'
import { useDeleteUserSignatureMutation } from '../user-profile-api'

const UploadTab = ({ formik, fetchSignature, signature, isFetching }) => {
	const [files, setFiles] = useState([])
	const { user } = useUser()
	const onRemove = () => {
		setFiles([])
		formik.setFieldValue('signatureFile', null)
	}

	const onDropAccepted = (files) => {
		formik.setFieldValue('signatureFile', files[0])
		setFiles(
			files.map((file) =>
				Object.assign(file, {
					preview: URL.createObjectURL(file),
				}),
			),
		)
	}

	const onDropRejected = () => {
		return notification('warn', 'Kindly choose only .png file formats in this section.')
	}

	const [deleteSignature, { isLoading: isDeleting }] = useDeleteUserSignatureMutation()

	const onDelete = async (userId) => {
		try {
			const response = await deleteSignature(userId)
			if (response?.data?.status === 200) {
				await fetchSignature(user?.id)
				onRemove()
				notification('success', response?.data?.message)
			}
		} catch (error) {
			notification('warn', error?.message)
		}
	}

	return (
		<Fragment>
			{isFetching ? (
				<div className='h-[165px] min-w-[400px] animate-pulse rounded-lg  bg-slate-200' />
			) : (
				<Fragment>
					{signature ? (
						<div className='flex flex-col space-y-3'>
							<div className='flex h-[165px] min-w-[400px] items-center justify-center space-x-6 rounded-lg !border border-dashed border-main p-6 text-center'>
								<img src={signature?.path} className='block h-full w-auto' />
							</div>
							<div className='flex  space-x-1'>
								<p className='text-sm text-gray-500'>
									Click the delete action button to remove the signature
									permanently.
								</p>
								<Button
									type='button'
									variant='danger-outline'
									size='sm'
									onClick={() => onDelete(user?.id)}
									isLoading={isDeleting}
									isLoadingText='Deleting...'>
									<div className='flex items-center space-x-1'>
										<i className='uil-trash' />
										<span>Delete</span>
									</div>
								</Button>
							</div>
						</div>
					) : (
						<Fragment>
							<Dropzone
								onDropAccepted={onDropAccepted}
								onDropRejected={onDropRejected}
								accept={['image/png']}
								disabled={files.length > 0}
								maxFiles={1}>
								{({ getRootProps, getInputProps }) => (
									<div
										className='flex cursor-pointer flex-col items-center rounded-lg !border border-dashed p-6 text-center transition-all hover:border-main hover:bg-main/10 active:bg-main/20 disabled:!cursor-not-allowed'
										{...getRootProps()}>
										<input {...getInputProps()} multiple={false} />
										<div className='mb-3'>
											<svg
												fill='rgb(91 115 232 / 0.5)'
												width='30px'
												height='30px'
												viewBox='0 0 32 32'
												version='1.1'
												xmlns='http://www.w3.org/2000/svg'>
												<title>upload-cloud</title>
												<path d='M0 16v-1.984q0-3.328 2.336-5.664t5.664-2.336q1.024 0 2.176 0.352 0.576-2.752 2.784-4.544t5.056-1.824q3.296 0 5.632 2.368t2.368 5.632q0 0.896-0.32 2.048 0.224-0.032 0.32-0.032 2.464 0 4.224 1.76t1.76 4.224v2.016q0 2.496-1.76 4.224t-4.224 1.76h-0.384q0.288-0.8 0.352-1.44 0.096-1.312-0.32-2.56t-1.408-2.208l-4-4q-1.76-1.792-4.256-1.792t-4.224 1.76l-4 4q-0.96 0.96-1.408 2.24t-0.32 2.592q0.032 0.576 0.256 1.248-2.72-0.608-4.512-2.784t-1.792-5.056zM10.016 22.208q-0.096-0.96 0.576-1.6l4-4q0.608-0.608 1.408-0.608 0.832 0 1.408 0.608l4 4q0.672 0.64 0.608 1.6-0.032 0.288-0.16 0.576-0.224 0.544-0.736 0.896t-1.12 0.32h-1.984v6.016q0 0.832-0.608 1.408t-1.408 0.576-1.408-0.576-0.576-1.408v-6.016h-2.016q-0.608 0-1.088-0.32t-0.768-0.896q-0.096-0.288-0.128-0.576z'></path>
											</svg>
										</div>
										<h4 className='!mb-1 text-lg text-gray-800'>
											Upload signature
										</h4>
										<p className='!m-0 text-gray-500'>
											Drag & drop a signature image or a scan
										</p>
										<p className='!m-0 text-xs text-gray-400'>
											Only .png files are allowed to upload
										</p>
									</div>
								)}
							</Dropzone>
							<div>
								{files.map((file) => (
									<div
										key={file.name}
										className='my-2 flex flex-col rounded !border hover:border-main hover:bg-main/10'>
										<div className='flex items-center justify-between p-1'>
											<div
												className='flex items-center space-x-3'
												title={file.name}>
												<div className='inline-flex h-[70px] w-[70px] rounded !border border-[#eaeaea] p-1'>
													<div className='flex min-w-0 overflow-hidden'>
														<img
															src={file.preview}
															className='block h-full w-auto'
															onLoad={() => {
																URL.revokeObjectURL(file.preview)
															}}
														/>
													</div>
												</div>
												<div className='flex flex-col'>
													<span className='line-clamp-1 max-w-[190px] text-[15px] font-semibold text-gray-900'>
														{file.name}
													</span>
													<span className='text-sm text-gray-500'>
														{formatBytes(file.size)}
													</span>
												</div>
											</div>
											<div>
												<Button
													type='button'
													variant='white'
													size='sm'
													onClick={onRemove}>
													Remove
												</Button>
											</div>
										</div>
										{/* <div className='h-2 w-full rounded-b bg-gray-200'>
							<div
								className='h-full rounded-b rounded-r  bg-main'
								style={{ width: `${69}%` }}></div>
						</div> */}
									</div>
								))}
							</div>
						</Fragment>
					)}
				</Fragment>
			)}
		</Fragment>
	)
}

export default UploadTab
