import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import { useMemo } from 'react'

const StatusQuery = () => {
	const URL = `${apiEndpoints.workflow}/statuses`
	const { data, isLoading } = useQuery(URL)

	const statuses = data?.data || []

	const options = useMemo(
		() =>
			statuses.map((status) => ({
				label: (
					<span className='line-clamp-1 min-w-fit max-w-fit' title={status}>
						{status}
					</span>
				),
				value: status,
			})),
		[statuses],
	)

	return {
		options,
		isLoading,
	}
}

export default StatusQuery
