import { useMemo } from 'react'
import CommentCard from './comment-card'

const CommentCardContainer = ({ comment, workflowId, stepId, isReplyToComment }) => {
	const replies = useMemo(() => comment?.replies || [], [comment?.replies])

	return (
		<div>
			<CommentCard
				comment={comment}
				stepId={stepId}
				workflowId={workflowId}
				isReply={isReplyToComment}
			/>
			<div className='ml-6 lg:ml-12'>
				{replies.map((reply, idx) => (
					<CommentCard
						isReply={false}
						key={idx}
						comment={reply}
						stepId={stepId}
						workflowId={workflowId}
					/>
				))}
			</div>
		</div>
	)
}

export default CommentCardContainer
