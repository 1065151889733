import { Fragment } from 'react'
import { Spinner } from 'reactstrap'

const SpinLoader = ({ size = 'md', color = 'dark', isLoadingText = 'Loading...' }) => {
	return (
		<Fragment>
			<div className='flex items-center justify-center space-x-3'>
				<Spinner animation='border' size={size} color={color} />
				<span>{isLoadingText}</span>
			</div>
		</Fragment>
	)
}

export default SpinLoader
