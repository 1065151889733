import { Link } from 'react-router-dom'

import logo from 'assets/images/experivise-logo-white.png'
import { getEmail } from '../..'

const FormHeader = ({ token }) => {
	const email = getEmail(token)

	return (
		<div className='flex justify-between items-center px-4'>
			<img
				src={logo}
				alt='Experivise'
				className='img-fluid onboarding-logo1'
				style={{ height: 90 }}
			/>
			<div>
				<h6 className='text-gray-400 mb-0.5'>{email}</h6>

				{email ? (
					<Link to='/login' className=' text-[#5b73e8] font-[500] float-right'>
						Sign out
					</Link>
				) : (
					<Link to='/login' className='font-[500] float-right'>
						Sign in
					</Link>
				)}
			</div>
		</div>
	)
}

export default FormHeader
