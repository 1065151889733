import { Button } from '../forms'

const ResetButton = ({ onReset }) => {
	return (
		<Button variant='white' size='sm' onClick={onReset}>
			<div className='inline-flex items-center text-gray-600'>
				Clear filters
				{/* <svg
					className='h-2 w-2'
					aria-hidden='true'
					xmlns='http://www.w3.org/2000/svg'
					fill='none'
					viewBox='0 0 14 14'>
					<path
						stroke='currentColor'
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeWidth='2'
						d='m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6'
					/>
				</svg> */}
			</div>
		</Button>
	)
}

export default ResetButton
