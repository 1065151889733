import { Tab } from '@headlessui/react'

import { useUploadFileMutation } from 'modules/assigned-workflows/assigned-workflow-apis'
import FillDetailsTab from './fill-details-tab'
import UploadFileTab from './upload-file-tab'

const TabsContent = ({
	setWorkflowAction,
	setIsOpen,
	files,
	tabs,
	workflow,
	uploads,
	id,
	toggleTab,
	activeTab,
	isLoading,
	createdBy,
	user_type,
	CompleteSteps,
	setCompleteSteps,
	tab2InitialValues,
	individualDocument,
	currentStatus,
}) => {
	const [uploadFile] = useUploadFileMutation()

	const onSave = async (file, file_title, save_type, fileToDelete = null) => {
		const formData = new FormData()
		formData.append('workflow_id', workflow?.id)
		formData.append('workflow_step_id', uploads?.id)
		formData.append('save_type', save_type)
		formData.append('files[]', file)
		formData.append('file_titles[]', file_title)
		if (fileToDelete) {
			formData.append('deleted_files[]', fileToDelete)
		}
		return await uploadFile({ payload: formData })
	}

	return (
		<Tab.Panels className='flex-1'>
			{files.map((file, idx) => {
				const uploadedFile = uploads.files.find((f) => f?.title === file?.file)
				return (
					<Tab.Panel key={idx}>
						<UploadFileTab
							file={file}
							uploadedFile={uploadedFile?.files}
							onSave={onSave}
							tabs={tabs}
							workflow={workflow}
							uploads={uploads}
						/>
					</Tab.Panel>
				)
			})}

			<Tab.Panel>
				<FillDetailsTab
					setWorkflowAction={setWorkflowAction}
					setIsOpen={setIsOpen}
					currentStatus={currentStatus}
					user_type={user_type}
					individual_document={individualDocument}
					createdBy={createdBy}
					CompleteSteps={CompleteSteps}
					setCompleteSteps={setCompleteSteps}
					toggleTab={toggleTab}
					activeTab={activeTab}
					id={id}
					uploads={uploads}
					uploads_id={uploads?.id}
					isLoading={isLoading}
					initialValues={tab2InitialValues}
				/>
			</Tab.Panel>
		</Tab.Panels>
	)
}

export default TabsContent
