import { matchPath } from 'react-router-dom'

export function matchRoutes(routes, pathname) {
	const matchedRoutes = []

	const recursiveMatch = (routesArray, currentPath) => {
		for (let route of routesArray) {
			const match = matchPath(currentPath, {
				path: route.path,
				exact: true,
				strict: route.strict,
			})

			if (match) {
				matchedRoutes.push({
					route,
					match,
				})

				if (route.routes) {
					recursiveMatch(route.routes, currentPath)
				}

				break // Stop searching after the first match
			}
		}
	}

	recursiveMatch(routes, pathname)
	return matchedRoutes
}
