import { useDropzone } from 'react-dropzone'
import { validateFileType } from 'theme/ui/forms/file-upload-field'
import { sanitizePdf } from 'theme/ui/forms/file-upload-field/pdf-validation'

import notification from 'utilities/notification'

const FileUploadDropzone = ({ disabled, onFileDrop, multiple = false }) => {
	const onDrop = async (acceptedFiles, rejected) => {
		try {
			const rejectedFile = rejected[0]?.errors[0] || null
			if (rejectedFile) {
				throw new Error(rejectedFile?.message)
			}

			const file = acceptedFiles[0]
			if (file) {
				await validateFileType(file, ['.pdf'])
				await sanitizePdf(file)
				await onFileDrop(file)
			}
		} catch (error) {
			notification('error', error?.message)
		}
	}

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: 'application/pdf',
	})

	return (
		<div
			className={`dropzone client-workflow-dropzone hover:bg-slate-50 active:bg-main/10 ${
				disabled ? 'disabled' : ''
			}`}>
			<div className='dz-message needsclick' {...getRootProps()}>
				<input {...getInputProps()} multiple={multiple} />
				<div className='mb-2'>
					<i className='display-6 uil uil-file-upload-alt text-[#B5B5C3]' />
				</div>
				<h6 className='m-2 font-normal text-[#B5B5C3]'>
					Drop your documents here, or select
				</h6>
				<h6 className='text-primary font-normal'>Click to browse</h6>
			</div>
		</div>
	)
}

export default FileUploadDropzone
