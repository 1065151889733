import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import IsAuthenticated from 'utils/isAuthenticated'

const useStepsQuery = (options = {}) => {
	const isLoggedIn = IsAuthenticated()
	const { data, isLoading } = useQuery(
		isLoggedIn ? apiEndpoints.userOnBoard.stepStatus : undefined,
		options,
	)

	return {
		data: data?.data,
		onboardStatus: data?.onboardStatus,
		isLoading,
	}
}

export default useStepsQuery
