import { Fragment, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import { useLazyGetWorkflowLinkQuery } from '../workflow-template-apis'
import WorkflowsLinksTable from './workflows-links-table'

const WorkflowsLinksContainer = ({ workflowId }) => {
	const location = useLocation()
	const history = useHistory()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination
	const params = useParams()

	useEffect(() => {
		if (pagination) {
			const parseQueryString = getParseQueryString(location?.search)
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			history.push(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	const [fetchWorkflowLinks, { data, isLoading }] = useLazyGetWorkflowLinkQuery()

	useEffect(() => {
		const parsedQueryString = getParseQueryString(location?.search) || {}
		if (workflowId) {
			fetchWorkflowLinks({
				...parsedQueryString,
				id: workflowId,
			})
		}
	}, [workflowId, location?.search])

	return (
		<Fragment>
			{/* <Filters /> */}
			<WorkflowsLinksTable
				workflows={data?.workflowsLinks}
				meta={data?.meta}
				isLoading={isLoading}
				pagination={{
					pageSize: limit,
					pageIndex: page - 1,
				}}
				setPagination={setPagination}
			/>
		</Fragment>
	)
}

export default WorkflowsLinksContainer
