// import { SpinLoader } from 'components/Common'
import AnnouncementsForm from 'modules/announcements/announcements-form/announcements-form'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const AnnouncementCreate = () => {
	return (
		<AppLayout
			meta={{
				title: 'Announcement Create',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<PageHeader
						title={'Announcement Create'}
						hasBackButton={true}
						description='Manage your announcements without hassle through our user-friendly
						dashboard. Stay in control of your communication strategy, easily monitor
						template performance and history, and make updates with just a few clicks,
						all in one streamlined hub designed for efficiency.'
					/>
					<div className='page-body'>
						<AnnouncementsForm />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default AnnouncementCreate
