import { AnnouncementsListingContainer } from 'modules/announcements/announcements-listing'
import Header from 'modules/announcements/header'
import AppLayout from 'theme/layouts/app-layout'

const AnnouncementsListing = () => {
	return (
		<AppLayout
			meta={{
				title: 'Announcements list',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<div className='page-header bg-white'>
						<Header />
					</div>

					<div className='page-body'>
						<AnnouncementsListingContainer />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default AnnouncementsListing
