import WebViewer from '@pdftron/webviewer'
import { disabledElements } from './config'

export const addTabs = async (documentList, instance) => {
	return new Promise((resolve) => {
		documentList.forEach((document, idx) => {
			instance.UI.TabManager.addTab(document?.src, {
				filename: document?.title,
				extension: 'pdf',
				document_id: document?.workflow_step_document_id,
				documentId: document?.id,
				setActive: idx === 0,
				saveCurrentActiveTabState: false,
			})
		})

		resolve()
	})
}

export const walkthroughSteps = [
	{
		dataElement: 'zoomOverlayButton',
		header: 'Zoom',
		text: 'You can adjust the preview of pdf from here.',
	},
	{
		dataElement: 'save-button',
		header: 'Save document',
		text: 'After inserting your signature you have to save the document by clicking on save button.',
	},
]

export const createInstance = async (viewerRef, getSetting) => {
	const response = await getSetting('pdf_tron_apikey')
	const settingValue = response?.data?.setting
	try {
		if (settingValue?.status === 200 && settingValue?.data?.setting_value) {
			return await WebViewer(
				{
					path: '/lib',
					css: '/lib/css/webviewer.css',
					fullAPI: false,
					disabledElements: disabledElements,
					licenseKey: settingValue?.data?.setting_value,
				},
				viewerRef.current,
			)
		}
	} catch (error) {
		return error
	}
}

export function replaceBracketedText(inputString) {
	// Use regular expression to replace "[x]" with an empty string
	return inputString.replace(/\[[^\]]+\]/g, '')
}

export const createAnnotation = (Annotations, { X, Y, PageNumber, Id }, content) => {
	const annot = new Annotations.FreeTextAnnotation(
		Annotations.FreeTextAnnotation.Intent.FreeText,
		{
			PageNumber: PageNumber,
			X: X,
			Y: Y,
			Id: Id,
			Width: 130,
			Height: 15,
			FontSize: '12px',
			TextAlign: 'left',
			TextVerticalAlign: 'center',
			TextColor: new Annotations.Color(0, 0, 0),
			// StrokeColor: new Annotations.Color(252, 252, 252),
		},
	)
	annot.setContents(content)
	annot.LockedContents = true
	return annot
}

export const ACTIONS = {
	ADD: 'add',
	DELETE: 'delete',
}

export const roundToDecimal = (number, decimalPlaces) => {
	const factor = Math.pow(10, decimalPlaces)
	return Math.round(number * factor) / factor
}

export { default as DocumentList } from './document-list'

