import { showErrorToast } from '../utils/commonFunctions'

export const handleCommonError = (error) => {
	const statusCode = error?.response?.status
	let message = ''
	if (typeof error?.response?.data?.message === 'string') {
		message = error?.response?.data?.message
	} else if (
		Array.isArray(error?.response?.data?.message) &&
		error?.response?.data?.message.length > 0
	) {
		message = error?.response?.data?.message[0]
	} else if (error?.response?.data?.error) {
		message = error?.response?.data?.error
	} else if (error?.message) {
		message = error?.message
	} else {
		message = 'Something went wrong'
	}
	// dispatch(actions.catchError({ message: message }));
	if (![404, 400].includes(statusCode)) {
		showErrorToast(message)
	}
}
