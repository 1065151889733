import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import { useDebounce } from 'use-debounce'
import * as yup from 'yup'

export const validationSchema = yup.object().shape({
	title: yup.string().required('Title is required'),
	content: yup.string().required('Content is required'),
	alert_class: yup.mixed().required('Alert class is required'),
	icon_class: yup.string().required('Icon is required'),
	target_audience: yup.string().required('Please select targeted audience'),
	team_ids: yup
		.array()
		.typeError('Please select teams')
		.when('target_audience', {
			is: (val) => val === 'TEAM',
			then: (schema) => schema.min(1, 'Please select teams'),
			otherwise: (schema) => schema.notRequired(),
		}),
	user_ids: yup
		.array()
		.typeError('Please select users')
		.when('target_audience', {
			is: (val) => val === 'USER',
			then: (schema) => schema.min(1, 'Please select users'),
			otherwise: (schema) => schema.notRequired(),
		}),
	target_role: yup
		.string()
		.typeError('Please targeted role')
		.when('target_audience', {
			is: (val) => val === 'USER',
			then: (schema) => schema.min(1, 'Please targeted role'),
			otherwise: (schema) => schema.notRequired(),
		}),
	start_date: yup.date().required('Start date is required'),
	end_date: yup
		.date()
		.required('End date is required')
		.min(yup.ref('start_date'), 'End date must be greater than start date'),
	priority: yup.mixed().required('Please select priority'),
})

const createOption = (value, label) => ({ label, value })

const alertOption = (type) => ({
	label: (
		<div className='flex items-center gap-2'>
			<div className={`h-4 w-4 rounded-full bg-${type}`} />
			<span className='capitalize'>{type}</span>
		</div>
	),
	value: type,
})
const iconOption = (icon) => ({
	label: <span className={`uil ${icon} text-[20px] `}></span>,
	value: icon,
})
export const alertOptions = [
	alertOption('success'),
	alertOption('danger'),
	alertOption('warning'),
	alertOption('info'),
	alertOption('primary'),
	alertOption('secondary'),
]

export const iconsOptions = [
	iconOption('uil-check-circle'),
	iconOption('uil-exclamation-triangle'),
	iconOption('uil-exclamation-octagon'),
	iconOption('uil-question-circle'),
	iconOption('uil-user-circle'),
	iconOption('uil-pen'),
]

export const targetAudianceOptions = [
	createOption('ALL', 'ALL'),
	createOption('TEAM', 'TEAM'),
	createOption('USER', 'USER'),
]

export const targetRolesOptions = [
	createOption('ALL', 'ALL'),
	createOption('OWNER', 'OWNER'),
	createOption('ADVISOR', 'ADVISOR'),
	createOption('CLIENT', 'CLIENT'),
]

export const priorityOptions = [
	createOption(1, 'HIGH'),
	createOption(2, 'MEDIUM'),
	createOption(3, 'LOW'),
]

const formatDate = (date) => {
	if (!date) return null
	const d = new Date(date)
	return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(
		d.getDate(),
	).padStart(2, '0')}`
}

export const getInitialValues = (field) => ({
	title: field?.title || '',
	content: field?.content || '',
	hyperlink: field?.hyperlink,
	alert_class: field?.alert_class ? alertOption(field.alert_class) : '',
	icon_class: field?.icon_class ? field.icon_class : 'uil-check-circle',
	is_dismissible: field?.is_dismissible ? 'true' : 'false',
	target_audience: field?.target_audience ? field?.target_audience : 'ALL',
	team_ids: (field?.teams || []).map(({ id, team_name }) => createOption(id, team_name)),
	user_ids: (field?.users || []).map(({ id, fullName, email }) =>
		createOption(id, `${fullName} [${email}]`),
	),
	target_role: field?.target_role ? field?.target_role : 'ALL',
	start_date: field?.start_date ? new Date(field.start_date) : null,
	end_date: field?.end_date ? new Date(field.end_date) : null,
	priority: field?.priority ? field?.priority : null,
	status: field?.status ? field?.status : 'ACTIVE',
})

export const getPayload = (values) => ({
	title: values?.title,
	content: values?.content,
	hyperlink: values?.hyperlink || undefined,
	alert_class: values?.alert_class?.value || 'info',
	is_dismissible: values?.is_dismissible === 'true' ? true : false,
	target_audience: values?.target_audience || 'ALL',
	team_ids:
		values?.team_ids?.length > 0 && values?.target_audience === 'TEAM'
			? values?.team_ids?.map((option) => option.value)
			: undefined,
	user_ids:
		values?.user_ids?.length > 0 && values?.target_audience === 'USER'
			? values?.user_ids?.map((option) => option.value)
			: undefined,
	target_role: values?.target_audience === 'TEAM' ? values?.target_role : undefined,
	start_date: formatDate(values?.start_date),
	end_date: formatDate(values?.end_date),
	priority: values?.priority ? Number(values?.priority) : 3,
	status: values?.status || 'ACTIVE',
	icon_class: values?.icon_class || null,
})

export const UsersQuery = (search) => {
	const [debounceSearch] = useDebounce(search, 500)
	const URL = `${apiEndpoints.users}?page=1&limit=10${
		debounceSearch ? `&search=${debounceSearch}` : ''
	}`
	const { data, isLoading } = useQuery(URL)

	return {
		users: data?.data?.users || [],
		isLoading,
	}
}
export const getUsersOptions = (users) => {
	try {
		if (Array.isArray(users)) {
			return users.map((user) => createOption(user?.id, `${user?.fullName} [${user?.email}]`))
		} else {
			return []
		}
	} catch (error) {
		return []
	}
}
