import { Fragment } from 'react';
import { Link } from "react-router-dom";

import experiviseDarkLogo from "../../assets/images/experivise-logo-dark.png";
import logoSm from "../../assets/images/logo-sm.png";
// import logoDark from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-light.png";

const ClientOnBoardingNavBar = () => {
    function tToggle() {
        var body = document.body;
        body.classList.toggle("vertical-collpsed");
        body.classList.toggle("sidebar-enable");
    }

    return (
        <Fragment>
            <header id="page-topbar" className='shadow-none bg-transparent'>
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box">
                            <Link to="/dashboard" className="logo logo-dark  flex items-center justify-center">
                                <span className="logo-sm">
                                    <img src={experiviseDarkLogo} alt="" style={{ height: 22, marginTop: 20 }} />
                                </span>
                                <span className="logo-lg">
                                    <img src={experiviseDarkLogo} alt="" style={{ height: 20 }} />
                                </span>
                            </Link>
                            a
                            <Link to="/dashboard" className="logo logo-light">
                                <span className="logo-sm">
                                    <img src={logoSm} alt="" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logoLight} alt="" height="20" />
                                </span>
                            </Link>
                        </div>

                        <button
                            type="button"
                            onClick={() => {
                                tToggle();
                            }}
                            className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
                            id="vertical-menu-btn"
                        >
                            <i className="fa fa-fw fa-bars" />
                        </button>

                    </div>

                    <div className="flex space-x-6 px-8">

                        <button className='!border rounded-lg px-2.5 py-1'>
                            <div className='flex items-center space-x-2'>
                                <i className='uil uil-info-circle text-2xl' />
                                <span className='font-medium text-sm'>Need Help</span>
                            </div>
                        </button>

                        <button className='px-1'>
                            <div className='flex items-center space-x-1'>
                                <div className='font-semibold bg-gray-200 h-7 w-7 flex items-center justify-center rounded-full'>
                                    M
                                </div>
                                <span>Max</span>
                                <i className='uil uil-angle-down' />
                            </div>
                        </button>

                        {/* <ProfileMenu /> */}

                    </div>
                </div>
            </header>
        </Fragment>
    )
}

export default ClientOnBoardingNavBar