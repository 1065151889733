import { EmailTemplatesListingContainer } from 'modules/email-templates/email-templates-listing'
import Header from 'modules/email-templates/header'
import AppLayout from 'theme/layouts/app-layout'

const EmailTemplatesListing = () => {
	return (
		<AppLayout
			meta={{
				title: 'Email Templates list',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<div className='page-header bg-white'>
						<Header />
					</div>

					<div className='page-body'>
						<EmailTemplatesListingContainer />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default EmailTemplatesListing
