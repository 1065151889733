import classNames from 'classnames'
import PropTypes from 'prop-types'

import { ReactComponent as ArrowLeftIcon } from 'assets/svg/arrow-left-icon.svg'
import { useAppRouter } from 'hooks'
import { ASSIGN_WORKFLOW_APP_ROUTES } from 'modules/assigned-workflows/routes'
import { useMediaQuery } from 'react-responsive'
import WorkflowDetails from '../workflow-details/workflow-details'

const PageHeader = ({
	title,
	children,
	redirectTo,
	description,
	hasBackButton,
	headerRowClassName,
	workflow,
}) => {
	const { navigate } = useAppRouter()
	const isDesktop = useMediaQuery({
		query: '(min-width: 768px)',
	})

	const handleBackClick = () => {
		navigate(ASSIGN_WORKFLOW_APP_ROUTES.findAll())
	}

	return (
		<div className={classNames('page-header-client bg-white')}>
			<div
				className={classNames(
					'flex w-full items-center justify-between',
					headerRowClassName,
				)}>
				<div className='max-w-[733px]'>
					<div className='flex items-center space-x-3'>
						{hasBackButton ? (
							<button onClick={() => handleBackClick()}>
								<ArrowLeftIcon />
							</button>
						) : undefined}
						<div className='flex flex-col space-y-2'>
							{title ? <h4 className='page-title'>{title}</h4> : undefined}
							{isDesktop ? <div>{children}</div> : undefined}
						</div>
					</div>
				</div>
				{isDesktop ? <WorkflowDetails workflow={workflow} /> : undefined}
			</div>
		</div>
	)
}

PageHeader.defaultProps = {
	headerRowClassName: '',
	variant: 'admin',
}

PageHeader.propTypes = {
	variant: PropTypes.oneOf(['admin', 'client']),
}

export default PageHeader
