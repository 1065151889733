import { FieldArray } from 'formik'
import { useMemo, useState } from 'react'

import { useDocmentViewer } from 'pages/Authentication/profile/components/workflow/document-viewer-provider'
import { getCoordinates } from '../pdf-viewer'
import FormRow from './form-row'

const PdfFields = ({ formik, onAutoSave, document }) => {
	const documentInstance = useDocmentViewer()
	const [lastAnnotation, setLastAnnotation] = useState(null)
	const [annots, setAnnots] = useState([])
	// eslint-disable-next-line no-unused-vars
	const [page, setPage] = useState(1)

	const pdf_fields = useMemo(
		() => (Array.isArray(document?.pdf_fields) ? document?.pdf_fields : []),
		[document],
	)

	const formValues = useMemo(() => formik?.values, [formik?.values])
	const numberOfUsers = formValues?.min_clients?.value

	const userOptions = useMemo(() => {
		const users = [
			{ label: 'Advisor', value: 'advisor' },
			{ label: 'Client', value: 'client' },
		]
		if (numberOfUsers > 1) {
			users.push(
				...new Array(numberOfUsers - 1).fill(0).map((_, idx) => ({
					label: `Co Applicant ${idx + 1}`,
					value: `applicant_${idx + 1}`,
				})),
			)
		}
		return users
	}, [numberOfUsers])

	const onHighlight = async (field) => {
		try {
			const {
				Annotations,
				annotationManager,
				Math: MathViewer,
				DocumentViewer,
			} = documentInstance.Core
			const coordinates = getCoordinates(field)

			const displayMode = new DocumentViewer()
			displayMode.setCurrentPage(Number(page), true)
			// displayMode.displayPageLocation(Number(page), coordinates.x - 40, coordinates.y - 20)

			if (annots.length > 0) {
				annots.forEach((annotId) => {
					const annot = annotationManager.getAnnotationById(annotId)
					if (annot) {
						annotationManager.deleteAnnotation(annot)
					}
				})
			}

			if (lastAnnotation) {
				await annotationManager.hideAnnotation(lastAnnotation)
			}
			const { Rect } = MathViewer
			annotationManager.deleteAnnotations(annotationManager.getAnnotationsList())
			const quads = Rect.createFromDimensions(
				coordinates.x,
				coordinates.y,
				coordinates.width,
				coordinates.height,
			).toQuad()
			const highlight = new Annotations.TextHighlightAnnotation()
			highlight.PageNumber = page
			highlight.FillColor = [255, 0, 0]
			highlight.StrokeColor = new Annotations.Color(255, 0, 0)
			highlight.Quads = [quads]
			const $annots = [...annots]
			$annots.push(highlight?.Id)
			setAnnots($annots)
			annotationManager.addAnnotation(highlight)
			annotationManager.drawAnnotations(highlight.PageNumber)
			setLastAnnotation(highlight)
		} catch (error) {
			//
		}
	}

	return (
		<div className='h-[100vh] rounded'>
			<div className='slim-scroll !max-h-[92%] overflow-auto rounded-b bg-white !px-3 !py-3'>
				<FieldArray
					name='document_mapping'
					render={(arrayHelpers) => (
						<div className='flex flex-col divide-y'>
							{formValues?.document_mapping.map((field, idx) => {
								const pdf_field = pdf_fields.find(
									($field) => $field?.label === field?.pdf_field,
								)

								return (
									<FormRow
										key={idx}
										field={field}
										formik={formik}
										pdf_field={pdf_field}
										formValues={formValues}
										onAutoSave={onAutoSave}
										onHighlight={onHighlight}
										userOptions={userOptions}
										onRemove={() => arrayHelpers.remove(idx)}
										fieldName={`${arrayHelpers.name}[${idx}]`}
									/>
								)
							})}
						</div>
					)}
				/>
			</div>
		</div>
	)
}

export default PdfFields
