import { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import errorImg from '../../assets/images/404-error.png'

const Pages404 = () => {
	useEffect(() => {
		document.body.className = 'authentication-bg'
		return function cleanup() {
			document.body.className = ''
		}
	})

	return (
		<Fragment>
			<div className='pt-sm-5 my-5'>
				<Container>
					<Row>
						<Col md={12}>
							<div className='text-center'>
								<div>
									<Row className='row justify-content-center'>
										<Col sm={4}>
											<div className='error-img'>
												<img
													src={errorImg}
													alt=''
													className='img-fluid d-block mx-auto'
												/>
											</div>
										</Col>
									</Row>
								</div>
								<h4 className='text-uppercase mt-4'>Sorry, page not found</h4>
								<p className='text-muted'>
									The requested resource is not found in our system. Please ensure
									that the resource exists in the system.
								</p>
								<div className='mt-5'>
									<Link
										className='btn btn-primary waves-effect waves-light'
										to='/'>
										Go back
									</Link>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</Fragment>
	)
}

export default Pages404
