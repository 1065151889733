import { DURATIONS } from '../subscription-form'
import SubscriptionListingContainer from './subscription-listing-container'

export const getDuration = (druation) => {
	if (druation === DURATIONS.MONTHLY) {
		return 'per month'
	}

	if (druation === DURATIONS.ANNUAL) {
		return 'per year'
	}
}

export default SubscriptionListingContainer
