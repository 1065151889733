import { Fragment } from 'react'
import { Field } from 'redux-form'

import { CheckBox } from 'components/Forms/CheckBoxComponent'

const HasAttachment = ({ onClearFilter }) => {
	return (
		<Fragment>
			<div className='flex shrink-0 flex-col !border bg-white'>
				<div className='w-full px-3 py-2'>
					<button
						type='button'
						onClick={onClearFilter}
						className='m-0 text-sm font-medium not-italic leading-5 text-[#495057]'>
						<i className='uil-angle-left text-base' />{' '}
						<span>Filter via Attachment</span>
					</button>
				</div>

				<div className='custom-scroll divide-y overflow-auto px-3 py-2'>
					<Field
						label='Has Attachment'
						name='has_attachment'
						id='has_attachment'
						component={CheckBox}
					/>
				</div>
			</div>
		</Fragment>
	)
}

export default HasAttachment
