import { Field, FormikProvider, useFormik } from 'formik'
import { Fragment, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { Button, InputField, PasswordField, PhoneNumberField } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { getDisabledFields, registerRequest, registerValidation } from '.'
import TermsAndServicesPopup from './terms-and-services-popup'

const RegisterForm = ({ initialValues }) => {
	const history = useHistory()
	const disabledFields = useMemo(() => getDisabledFields(initialValues), [initialValues])
	const [isAgree, setIsAgree] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const onOpen = () => setIsOpen(true)
	const onClose = () => setIsOpen(false)
	const onAgree = () => setIsAgree(true)

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: registerValidation,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true)
				const response = await registerRequest(values)
				if (response?.status === 200) {
					notification(
						'success',
						response?.message + ' Please enter your email & password to continue',
					)
				}
				history.push('/login')
			} catch (error) {
				setSubmitting(false)
			}
		},
	})

	return (
		<Fragment>
			<FormikProvider value={formik}>
				<form onSubmit={formik.handleSubmit}>
					<div className='mt-4 space-y-6 p-2'>
						<div className='grid grid-cols-2 gap-3'>
							<Field
								name='first_name'
								label='First Name'
								component={InputField}
								placeholder='Enter First Name'
								type='text'
								isRequiredField
								disabled={disabledFields['first_name']}
							/>

							<Field
								name='last_name'
								label='Last Name'
								component={InputField}
								placeholder='Enter Last Name'
								type='text'
								isRequiredField
								disabled={disabledFields['last_name']}
							/>

							<div className='col-span-2'>
								<Field
									name='email'
									type='email'
									label='Enter Email Address'
									component={InputField}
									placeholder='Enter Email Address'
									isRequiredField
									disabled={disabledFields['email']}
								/>
							</div>

							<div className='col-span-2'>
								<Field
									name='phone_number'
									label='Phone Number'
									component={PhoneNumberField}
									placeholder='123 134 45657'
									type='text'
									autoComplete='new-password'
									isRequiredField
									disabled={disabledFields['phone_number']}
								/>
							</div>

							<div className='col-span-2'>
								<Field
									name='password'
									label='Password'
									type='password'
									autoComplete='new-password'
									component={PasswordField}
									placeholder='Enter Password'
									isRequiredField
								/>
							</div>

							<div className='col-span-2'>
								<Field
									name='passwordConfirm'
									label='Confirm Password'
									type='password'
									component={InputField}
									placeholder='Confirm Password'
									isRequiredField
								/>
							</div>

							<div className='col-span-2'>
								<Field
									name='team_name'
									label='Team Name'
									type='text'
									component={InputField}
									placeholder='Enter Team Name'
									isRequiredField
									disabled={disabledFields['team_name']}
								/>
							</div>
						</div>

						<div className='flex items-start space-x-2'>
							<input
								type='checkbox'
								className='form-check-input mt-1'
								id='is_agree'
								checked={isAgree}
								onChange={(e) => {
									if (e.target.checked) {
										onOpen()
									}
								}}
							/>
							<label className='cursor-pointer text-center font-normal text-gray-500'>
								By registering an account, you are required to accept our{' '}
								<span
									htmlFor='is_agree'
									className='cursor-pointer font-bold text-main hover:underline'
									onClick={onOpen}>
									Terms of Service and Privacy Policy.
								</span>
							</label>
						</div>

						<div>
							<Button
								className='btn btn-primary waves-effect waves-light w-full'
								type='submit'
								disabled={!isAgree}
								isLoading={formik.isSubmitting}
								isLoadingText='Processing...'>
								Register
							</Button>
						</div>

						<div className='mt-4 text-center'>
							<p className='text-muted mb-0'>
								Already have an account ?{' '}
								<Link to='/login' className='fw-medium text-primary'>
									{' '}
									Login
								</Link>
							</p>
						</div>
					</div>
				</form>
			</FormikProvider>
			<TermsAndServicesPopup isOpen={isOpen} onClose={onClose} onAgree={onAgree} />
		</Fragment>
	)
}

export default RegisterForm
