import FeatureForm from 'modules/subscriptions/features/feature-form'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const FeatureCreate = () => {
	return (
		<AppLayout
			meta={{
				title: 'Edit Feature',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<PageHeader
						title='Create Feature'
						hasBackButton={true}
						description='Easily manage your feature  hassle-free from one convenient dashboard. Stay in control of your spending, effortlessly track renewal dates, and make changes with just a few clicks, all in one centralized hub designed for your convenience'
					/>

					<div className='page-body'>
						<FeatureForm />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default FeatureCreate
