import FileUploader from './FileUploader'

const Form = ({
	onValidIdDrop,
	validIdState,
	onGodDrop,
	godState,
	onContinue,
	steps,
	isContinue,
	notes,
}) => {
	// eslint-disable-next-line no-unused-vars
	const [validId, setValidID] = validIdState
	// eslint-disable-next-line no-unused-vars
	const [god, setGod] = godState

	const maxSize = 4 // in MB
	const accept = ['image/jpeg', 'image/png', 'application/pdf']

	const [validIdStatus, godStatus] = steps

	return (
		<div>
			<div className='!mt-12 flex flex-col space-y-6'>
				{validIdStatus === 'pending' ? (
					<div>
						<label className='text-sm font-semibold'>Valid ID</label>
						<FileUploader
							onDrop={onValidIdDrop}
							progress={100}
							fileState={validIdState}
							accept={accept}
							maxSize={maxSize}
							onRemove={() => setValidID(null)}
						/>
						{notes[0] ? (
							<p className='text-red-700'>
								<strong>Note:</strong> &nbsp;{notes[0]}
							</p>
						) : undefined}
					</div>
				) : undefined}

				{godStatus === 'pending' ? (
					<div>
						<label className='text-sm font-semibold'>
							Official government document
						</label>
						<FileUploader
							onDrop={onGodDrop}
							progress={100}
							fileState={godState}
							accept={accept}
							maxSize={maxSize}
							onRemove={() => setGod(null)}
						/>
						{notes[1] ? (
							<p className='text-red-700'>
								<strong>Note:</strong> &nbsp;{notes[1]}
							</p>
						) : undefined}
					</div>
				) : undefined}

				{validIdStatus !== 'pending' && godStatus !== 'pending' ? (
					<div className='text-[#5B73E8]'>No Document is left to upload.</div>
				) : undefined}
			</div>

			<button
				className='btn-primary-1 my-8'
				onClick={() => onContinue('verify')}
				disabled={!isContinue}>
				Verify
			</button>
			<button
				className='btn-primary-1 mx-2 my-8'
				disabled={!isContinue}
				onClick={() => onContinue('continue')}
				title='Continue without verifying'>
				Continue
			</button>
		</div>
	)
}

export default Form
