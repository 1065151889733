import { Transition } from '@headlessui/react'
import classNames from 'classnames'
import { useEffect } from 'react'

const OffCanvas = ({ isOpen, onClose, children, width = '' }) => {
	useEffect(() => {
		const body = document.body

		if (isOpen) {
			body.style.overflow = 'hidden'
		} else {
			body.style.overflow = 'auto'
		}
		return () => {
			body.style.overflow = 'auto'
		}
	}, [isOpen])

	return (
		<Transition
			show={isOpen}
			as={'div'}
			enter='transform ease-out duration-300 transition'
			enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
			enterTo='translate-y-0 opacity-100 sm:translate-x-0'
			leave='transition ease-in duration-100'
			leaveFrom='opacity-100'
			leaveTo='opacity-0'>
			<div className='fixed inset-0 z-[1100] overflow-hidden'>
				<div className='absolute  inset-0 bg-black bg-opacity-50' onClick={onClose} />
				<div
					className={classNames(
						'absolute inset-y-0 right-0 w-full overflow-y-auto bg-white',
						width ? width : 'max-w-xs',
					)}>
					{children}
				</div>
			</div>
		</Transition>
	)
}

export default OffCanvas
