/* eslint-disable react-hooks/exhaustive-deps */
import Gleap from 'gleap'
import { Fragment, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { mutate } from 'swr'
import Loader from '../../components/Common/Loader'
import apiEndpoints from '../../helpers/apiEndpoints'
import * as authActions from '../../store/jwtauth/actions'

const Logout = (props) => {
	const dispatch = useDispatch()
	const { history } = props
	const clearCache = () => mutate(() => true, undefined, { revalidate: false })

	useEffect(() => {
		dispatch(authActions.logout(apiEndpoints.auth.logout)).then((response) => {
			if (response) {
				clearCache()
				Gleap.clearIdentity()
				history.push('/login')
			}
		})
	}, [])

	return (
		<Fragment>
			<Loader />
		</Fragment>
	)
}

export default Logout
