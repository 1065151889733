import { phone } from 'phone'
import { isValidPhoneNumber } from 'react-phone-number-input'

export const required =
	(input = 'input', type = 'enter') =>
	(value) => {
		if (!value || value.length === 0) {
			return `Please ${type} ${input}`
		}

		const regex = /^\S+(?: \S+)*$/
		return regex.test(value) ? undefined : `Please ${type} valid ${input}`
	}

export const phoneRequired =
	(input = 'input', type = 'enter') =>
	(value) => {
		const isValidNumber = phone(value)
		if (!isValidNumber?.isValid) {
			return `Please ${type} valid ${input}`
		}
		const regex = /^\S+(?: \S+)*$/
		return regex.test(value) ? undefined : `Please ${type} valid ${input}`
	}

export const requiredName =
	(input = 'input', type = 'enter') =>
	(value) => {
		if (!value) {
			return `Please ${type} ${input}`
		} else if (value.length === 0) {
			return `Please ${type} ${input}`
		} else if (value.length < 3) {
			return `${input} must be at least 3 characters`
		} else if (value.length > 50) {
			return `${input} must be less than 50 characters`
		} else {
			return undefined
		}
	}

export const emailCheck = (value) =>
	value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email' : undefined

/* Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character */
export const passwordCheck = (value) =>
	// value && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,30}$/.test(value)
	//     ? 'Invalid password'
	//     : undefined

	value &&
	!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*?[#?!@$%^&*-])[0-9a-zA-Z!@#$%^&*>~`=.,/'";:_}{<)(?]{8,}$/.test(
		value,
	)
		? 'Must be a strong password'
		: undefined

export const validatePassword = (password) => {
	let errors = [
		{
			contain: '8+',
			name: 'Characters',
			isWrong: undefined,
			// errorMessage: undefined
		},
		{
			contain: 'A',
			name: 'Uppercase',
			isWrong: undefined,
			// errorMessage: undefined
		},
		{
			contain: 'a',
			name: 'Lowercase',
			isWrong: undefined,
			// errorMessage: undefined
		},
		{
			contain: '1',
			name: 'Number',
			isWrong: undefined,
			// errorMessage: undefined
		},
		{
			contain: '#',
			name: 'Special',
			isWrong: undefined,
			// errorMessage: undefined
		},
	]

	if (!password) {
		return errors
	}

	if (password.length < 8) {
		errors[0].isWrong = true
		// errors[0].errorMessage = "Your password must be at least 8 characters"
	} else {
		errors[0].isWrong = false
	}

	if (password.search(/[a-z]/) < 0) {
		errors[2].isWrong = true
		// errors[2].errorMessage = "Your password must contain at least one lowercase letter"
	} else {
		errors[2].isWrong = false
	}

	if (password.search(/[A-Z]/) < 0) {
		errors[1].isWrong = true
		// errors[1].errorMessage = "Your password must contain at least one uppercase letter"
	} else {
		errors[1].isWrong = false
	}

	if (password.search(/[0-9]/) < 0) {
		errors[3].isWrong = true
		// errors[3].errorMessage = "Your password must contain at least one digit"
	} else {
		errors[3].isWrong = false
	}

	if (password.search(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/) < 0) {
		// Spacial
		errors[4].isWrong = true
		// errors[4].errorMessage = "Your password must contain at least one special characters"
	} else {
		errors[4].isWrong = false
	}

	return errors
}

export const confirmPassword = (value, allValues) =>
	value !== allValues.password ? 'Passwords does not match' : undefined

export const normalizeMaxLength = (maxLength) => (value, previousValue) => {
	if (!value) {
		return value
	}
	if (value.toString().trim().length <= maxLength) {
		return value
	}
	return previousValue
}
export const pdfTypeCheck = (fieldName) => (value) =>
	value && value.type !== 'application/pdf' ? `Invalid ${fieldName} type` : undefined

export const maxFileSizeCheck = (maxSize) => (value) => {
	if (value && value.size > maxSize) {
		return `File size should be less than ${maxSize / 1000000} MB`
	}
	return undefined
}

export const minLength = (min) => (value) =>
	value && value.length < min ? `Must be at least ${min} characters` : undefined

export const maxLength = (max) => (value) =>
	value && value.length > max ? `Must be less than ${max} characters` : undefined

export const numberCheck = (value) =>
	value && isNaN(Number(value)) ? 'Must be numeric values' : undefined

export const isValidPhoneNumberCheck = (value) => {
	if (!isValidPhoneNumber(value)) {
		return 'Invalid phone number'
	}
}

export const validatePasswordWithMessages = (password) => {
	let errors = [
		{
			contain: '8+',
			name: 'Characters',
			isWrong: undefined,
			errorMessage: 'Your password must be at least 8 characters',
		},
		{
			contain: 'A',
			name: 'Uppercase',
			isWrong: undefined,
			errorMessage: 'Your password must contain at least one uppercase letter',
		},
		{
			contain: 'a',
			name: 'Lowercase',
			isWrong: undefined,
			errorMessage: 'Your password must contain at least one lowercase letter',
		},
		{
			contain: '1',
			name: 'Number',
			isWrong: undefined,
			errorMessage: 'Your password must contain at least one digit',
		},
		{
			contain: '#',
			name: 'Special',
			isWrong: undefined,
			errorMessage: 'Your password must contain at least one special characters',
		},
	]

	if (!password) {
		return errors.map((error) => error?.errorMessage)
	}

	if (password.length < 8) {
		errors[0].isWrong = true
		errors[0].errorMessage = 'Your password must be at least 8 characters'
	} else {
		errors[0].isWrong = false
		errors[0].errorMessage = undefined
	}

	if (password.search(/[A-Z]/) < 0) {
		errors[1].isWrong = true
		errors[1].errorMessage = 'Your password must contain at least one uppercase letter'
	} else {
		errors[1].isWrong = false
		errors[1].errorMessage = undefined
	}

	if (password.search(/[a-z]/) < 0) {
		errors[2].isWrong = true
		errors[2].errorMessage = 'Your password must contain at least one lowercase letter'
	} else {
		errors[2].isWrong = false
		errors[2].errorMessage = undefined
	}

	if (password.search(/[0-9]/) < 0) {
		errors[3].isWrong = true
		errors[3].errorMessage = 'Your password must contain at least one digit'
	} else {
		errors[3].isWrong = false
		errors[3].errorMessage = undefined
	}

	if (password.search(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/) < 0) {
		// Spacial
		errors[4].isWrong = true
		errors[4].errorMessage = 'Your password must contain at least one special characters'
	} else {
		errors[4].isWrong = false
		errors[4].errorMessage = undefined
	}

	return errors.map((error) => error?.errorMessage)
}
