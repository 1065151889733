import { Button } from 'theme/ui/forms'

export default function FallbackRender({ error, resetErrorBoundary }) {
	const errorCode = error?.cause

	return (
		<div className='flex min-h-screen items-center justify-center'>
			<div role='alert' className='prose w-[500px] rounded-md border bg-white p-6 shadow-sm'>
				<h4 className='text-center'>
					{errorCode === 403 ? 'Access Denied' : 'Something went wrong!'}
				</h4>

				{process.env.NODE_ENV === 'development' || errorCode === 403 ? (
					<p className='text-center text-gray-500'>{error?.message}</p>
				) : undefined}

				<div className='flex items-center justify-center space-x-4'>
					<Button
						type='button'
						size='sm'
						onClick={() => {
							resetErrorBoundary()
							window.location.href = '/'
						}}>
						Go back
					</Button>
					<button
						onClick={resetErrorBoundary}
						className='cursor-pointer text-main hover:underline'>
						Try again
					</button>
				</div>
			</div>
		</div>
	)
}
