import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import { useMemo } from 'react'

const useWorkflowQuery = (id) => {
	const URL = useMemo(() => `${apiEndpoints.workflow}/completed/${id}`, [id])
	const { data, isLoading, mutate } = useQuery(URL)

	const childWorkflows = data?.data?.childWorkflows || []
	const siblingWorkflows = data?.data?.siblingWorkflows || []
	const parentWorkflow = data?.data?.parentWorkflow ? [data?.data?.parentWorkflow] : []

	const related_worflows = useMemo(() => {
		return [...childWorkflows, ...siblingWorkflows, ...parentWorkflow].map((workflow) => {
			const user = workflow?.client || workflow?.advisor
			return {
				id: workflow?.id,
				user_name: `${user?.first_name} ${user?.last_name}`,
				user_type: workflow?.user_type,
			}
		})
	}, [childWorkflows, siblingWorkflows, parentWorkflow])

	return {
		workflow: data?.data,
		user: data?.data?.client || data?.data?.advisor,
		related_worflows,
		isLoading,
		mutate,
	}
}

export default useWorkflowQuery
