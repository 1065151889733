import WebViewer from '@pdftron/webviewer'
import { documentEventLog } from 'modules/document-event-log'
import { useCreateDocumentEventLogMutation } from 'modules/document-event-log/document-event-log-api'
import { useLazyGetSettingQuery } from 'modules/settings/settings-api'
import { useEffect, useRef } from 'react'
import notification from 'utilities/notification'
import { disabledElements } from './config'

const PdfViewer = ({ document }) => {
	const viewer = useRef(null)
	const [createDocumentEventLog] = useCreateDocumentEventLogMutation()
	const [getSetting] = useLazyGetSettingQuery()
	const webviewerInstance = async (file) => {
		const response = await getSetting('pdf_tron_apikey')
		const settingValue = response?.data?.setting
		try {
			if (settingValue?.status === 200 && settingValue?.data?.setting_value) {
				return await WebViewer(
					{
						path: '/lib',
						initialDoc: file,
						disabledElements: disabledElements,
						licenseKey: settingValue?.data?.setting_value,
					},
					viewer.current,
				)
			}
		} catch (error) {
			notification(error)
		}
	}

	useEffect(() => {
		if (document?.fileUrl) {
			webviewerInstance(document?.fileUrl).then((instance) => {
				if (instance) {
					if (document?.id && document?.workflow_step_document_id) {
						createDocumentEventLog({
							document_id: document?.id,
							workflow_step_document_id: document?.workflow_step_document_id,
							event_type: documentEventLog.eventTypes.DocumentPreview,
							module_type: documentEventLog.moduleTypes.CompletedDocumentPreview,
						})
					}

					const { documentViewer, annotationManager } = instance.Core

					documentViewer.addEventListener('documentLoaded', () => {
						annotationManager.enableReadOnlyMode()
						documentViewer.enableReadOnlyMode()
					})
				}
			})
		}

		return () => {
			viewer.current = null
		}
	}, [document])

	return <div ref={viewer} style={{ height: '100vh' }}></div>
}

export default PdfViewer
