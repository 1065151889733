import { Disclosure } from '@headlessui/react'
import { useReactTable } from '@tanstack/react-table'
import classNames from 'classnames'
import { Fragment, useEffect } from 'react'

import { DataTablePagination, useSetDataTable } from 'theme/ui/data-table'
import { WorkflowOutstandingsSkeleton } from 'theme/ui/skeletons'
import Heading from './heading'
import WorkflowTable from './workflow-table'

const WorkflowOutstandingsTable = ({
	meta,
	isLoading,
	pagination,
	outstandings,
	setPagination,
}) => {
	const table = useReactTable({
		data: outstandings,
		manualPagination: true,
		pageCount: meta?.totalPages ?? -1,
		state: { pagination },
		onPaginationChange: setPagination,
	})
	const setTable = useSetDataTable()

	useEffect(() => {
		setTable(table)
		return () => {
			setTable(null)
		}
	}, [table])

	const skipStauses = ['Cancelled', 'Completed']

	return (
		<div>
			<div className='alignment_style'>
				<table className='workflows-outstandings mb-4'>
					<thead>
						<tr className='h-[45px] cursor-pointer border-b border-white bg-white px-3 '>
							{/* <th className='w-[40px]'>#.</th> */}
							<th className='w-[150px]'>Clients</th>
							<th className='w-[130px]'>Assigned On</th>
							<th className='w-[130px]'>Final Reviewer</th>
							<th>Reviewers</th>
							<th className='w-40'>Team</th>
							<th className='w-[220px] !max-w-[220px]'>Status</th>
						</tr>
					</thead>
				</table>

				<div className='flex w-full flex-col'>
					{isLoading ? (
						<WorkflowOutstandingsSkeleton pageSize={table.getState().pagination.pageSize} />
					) : (
						<>
							{outstandings.length > 0 ? (
								outstandings.map((outstanding, idx) => {
									const links = Array.isArray(outstanding?.shortUrls)
										? outstanding?.shortUrls
										: []

									const updatesCount = outstanding?.workflows
										.map((workflow) => workflow?.status)
										.filter((workflow) => !skipStauses.includes(workflow)).length

									return (
										<div key={idx}>
											<Disclosure as={'div'} className='mb-2'>
												{({ open }) => (
													<Fragment>
														<Disclosure.Button
															as='button'
															className={classNames(
																'flex w-full justify-between rounded  border-b bg-white px-3 py-1',
																{
																	'shadow-[0px_2px_4px_0px_rgba(15,34,58,0.12)]':
																		open,
																},
															)}>
															<Heading
																open={open}
																title={outstanding?.title}
																updatesCount={updatesCount}
																links={links}
																id={outstanding?.id}
															/>
														</Disclosure.Button>
														<Disclosure.Panel>
															<WorkflowTable
																team={outstanding?.team}
																workflows={outstanding?.workflows}
															/>
														</Disclosure.Panel>
													</Fragment>
												)}
											</Disclosure>
										</div>
									)
								})
							) : (
								<p className='text-center'>No Workflow founds to review</p>
							)}
						</>
					)}
				</div>
			</div>
			{pagination && meta ? <DataTablePagination table={table} meta={meta} /> : undefined}
		</div>
	)
}

export default WorkflowOutstandingsTable
