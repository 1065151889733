/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	email: null,
	password: null,
	phone_number: null,
}

export const ClientOnboardingSlices = createSlice({
	name: 'client_onboarding',
	initialState: initialState,
	reducers: {
		saveEmail: (state, action) => {
			state.first_name = action.payload.first_name
			state.last_name = action.payload.last_name
			state.email = action.payload.email
			state.group_id = action.payload.group_id
			state.team_name = action.payload.team_name
		},
		savePassword: (state, action) => {
			state.password = action.payload.password
		},
		savePhoneNumber: (state, action) => {
			state.phone_number = action.payload.phone_number
		},
		clear: (state, actions) => {
			state.first_name = null
			state.group_id = null
			state.last_name = null
			state.team_name = null
			state.email = null
			state.password = null
			state.phone_number = null
		},
	},
})

export const { saveEmail, savePassword, savePhoneNumber } = ClientOnboardingSlices.actions
