import { createColumnHelper } from '@tanstack/react-table'
import { useAppRouter } from 'hooks'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Badge from 'theme/ui/badge'
import DataTable, { IndeterminateCheckbox } from 'theme/ui/data-table'
import PopoverButtons from 'theme/ui/popover'
import { highlightWord } from 'utilities/helpers'
import Actions from './actions'
import StatusColumn from './status-column'

const OnboardedClientsTable = ({
	clients,
	meta,
	isLoading,
	pagination,
	setPagination,
	setSelectedRecords,
	rowSelection,
	setRowSelection,
	setPreviousData,
	isFullRowSelection,
}) => {
	const [actionActiveId, setActionActiveId] = useState(null)
	const [sorting, setSorting] = useState([])
	const onMouseEnter = (id) => setActionActiveId(id)
	const onMouseLeave = () => setActionActiveId(null)
	const history = useHistory()
	const { parseQuery } = useAppRouter()
	const search = parseQuery?.search
	const columnHelper = createColumnHelper()

	useEffect(() => {
		const matchedIndexes = Object.keys(rowSelection).map((k) => JSON.parse(k)?.id)
		const result = clients?.filter((client) => matchedIndexes?.includes(client.id))
		setSelectedRecords(result)
		setPreviousData(result)
	}, [rowSelection])

	const applyFilter = (tag) => {
		const encodedTags = encodeURIComponent(tag)
		setPagination((preRec) => ({
			...preRec,
			tags: encodedTags,
		}))
		history.push(`${window.location.pathname}?groups=${encodedTags}`)
	}

	const columns = [
		{
			id: 'select',
			size: 20,
			header: ({ table }) => (
				<IndeterminateCheckbox
					{...{
						checked: table.getIsAllRowsSelected(),
						indeterminate: table.getIsSomeRowsSelected(),
						onChange: table.getToggleAllRowsSelectedHandler(),
					}}
				/>
			),
			cell: ({ row }) => (
				<div className='px-1'>
					<IndeterminateCheckbox
						{...{
							checked: row.getIsSelected(),
							disabled: !row.getCanSelect(),
							indeterminate: row.getIsSomeSelected(),
							onChange: row.getToggleSelectedHandler(),
						}}
					/>
				</div>
			),
		},
		// columnHelper.accessor('index', {
		// 	size: 20,
		// 	header: () => <span>#</span>,
		// 	cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		// }),
		columnHelper.accessor((row) => row, {
			id: 'name',
			size: 150,
			header: () => <span className='min-w-[190px]'>Name</span>,
			cell: (info) => (
				<div className='max-w-[200px]'>
					<span className='line-clamp-1 text-sm font-bold !text-gray-700'>
						{info.getValue()?.full_name}
					</span>
					<span className='file-title text-[13px] !text-gray-400'>{info.getValue()?.email}</span>
				</div>
			),
		}),
		

		columnHelper.accessor((row) => row, {
			id: 'tags',
			size: 100,
			header: () => <span className='min-w-[80px]'>Groups</span>,
			cell: (info) => {
				const tagsData = info.getValue()?.tags
				return (
					<span className='max-w-[180px] min-w-[80px]'>
						{tagsData?.length > 0 && (
							<div className='flex flex-wrap items-center gap-2'>
								<Badge
									variant='primary'
									className={'cursor-pointer'}
									onClick={() => applyFilter(tagsData[0])}>
									{tagsData[0]}
								</Badge>
								{tagsData?.length > 1 ? (
									<PopoverButtons
										data={tagsData}
										title={'Groups'}
										onClick={applyFilter}
									/>
								) : undefined}
							</div>
						)}
					</span>
				)
			},
		}),

		columnHelper.accessor((row) => row, {
			id: 'status',
			size: 100,
			header: () => <span>Status</span>,
			cell: (info) => (
				<StatusColumn
					status={info.getValue()?.status}
					invitation_expires_at={info.getValue()?.invitation_expires_at}
					created_at={info.getValue()?.created_at}
					id={info.getValue()?.id}
				/>
			),
		}),

		columnHelper.accessor((row) => row.created_at, {
			id: 'created_at	',
			header: () => <span>Added On</span>,
			cell: (info) => <span>{info.getValue()}</span>,
		}),

		columnHelper.accessor((row) => row, {
			id: 'actions',
			size: 150,
			header: () => <span>Actions</span>,
			cell: (info) => (
				<Actions
					id={info.getValue()?.id}
					client={info.getValue()}
					isActionActive={actionActiveId === info?.row?.id}
				/>
			),
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={clients}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			setSorting={setSorting}
			sorting={sorting}
			meta={meta}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			isFullRowSelection={isFullRowSelection}
			setRowSelection={setRowSelection}
			rowSelection={rowSelection}
			isRowSelection={true}
		/>
	)
}

export default OnboardedClientsTable
