import { snakeCase } from 'lodash'
import { Fragment, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { ReactComponent as LineIcon } from 'assets/svg/line-icon.svg'
import { useAppRouter, useUser } from 'hooks'
import { useLazyGetAssignedWorkflowsQuery } from 'modules/assigned-workflows/assigned-workflow-apis'
import { getWorkflowUrl } from 'modules/workflows/assigned-workflows'
import Spinner from 'theme/ui/spinner'
import { ASSIGN_WORKFLOW_APP_ROUTES } from '../routes'
import WorkflowCard from './workflow-card'

const AssignedWorkflowsContainer = ({ history }) => {
	const { defaultGroup } = useSelector((state) => state.Permissions)
	const { user } = useUser()
	const [fetchAssignedWorkflow, { data, isLoading, isUninitialized }] =
		useLazyGetAssignedWorkflowsQuery()
	const { navigate } = useAppRouter()

	useEffect(() => {
		const group = snakeCase(defaultGroup?.group?.name)
		const stringUrl = getWorkflowUrl(group)
		fetchAssignedWorkflow({
			params: { page: 1, limit: 70 },
			url: stringUrl,
		})
	}, [])

	const workflows = useMemo(
		() => (Array.isArray(data?.workflows) ? data?.workflows : []),
		[data?.workflows],
	)

	const isFetching = isUninitialized || isLoading

	useEffect(() => {
		if (!isFetching && history?.length === 1 && workflows?.length > 0) {
			const workflow = workflows[0]
			const isRedirected = localStorage.getItem('workflow_redirection') === user?.email
			if (!isRedirected) {
				localStorage.setItem('workflow_redirection', user?.email)
				navigate(ASSIGN_WORKFLOW_APP_ROUTES.findOne(workflow?.id))
			}
		}
	}, [workflows, isFetching, history])

	return (
		<div>
			<div className='mb-4 flex items-center space-x-1'>
				<h3 className='m-0 text-lg font-medium'>Recently Assigned Workflows</h3>
				<span>
					<LineIcon height={16} width={30} />
				</span>
			</div>

			<div className='flex flex-wrap gap-3 md:grid-cols-2 md:gap-8'>
				{isUninitialized || isLoading ? (
					<div className='col-span-2 flex items-center'>
						<div className='mx-auto my-6 flex items-center space-x-3'>
							<Spinner />
							<span>Loading...</span>
						</div>
					</div>
				) : (
					<>
						{workflows.length > 0 ? (
							<Fragment>
								{workflows.map((workflow, idx) => (
									<WorkflowCard workflow={workflow} key={idx} />
								))}
							</Fragment>
						) : (
							<p className='col-span-2 text-center text-sm text-gray-500'>
								No Workflow is assigned yet
							</p>
						)}
					</>
				)}
			</div>
		</div>
	)
}

export default AssignedWorkflowsContainer
