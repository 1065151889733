/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useCallback, useRef, useState } from 'react'
import Webcam from 'react-webcam'

import selfiePlaceholder from 'assets/images/onboarding/selfie-placeholder.svg'
import apiEndpoints from 'helpers/apiEndpoints'
import { useDispatch } from 'react-redux'
import { store } from 'store/common/actions'
import { mutate } from 'swr'
import { showSuccessToast } from 'utils/commonFunctions'
import QrCodeModal from './QrCodeModal'

const videoConstraints = {
	width: 400,
	height: 400,
	facingMode: 'user',
}

const Form = () => {
	const [isOpen, setIsOpen] = useState(false)
	const [isGenerating, setIsGenerating] = useState(false)
	const [token, setToken] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const dispatch = useDispatch()

	const [src, setSrc] = useState(null)
	const [isWebCamActive, setIsWebCamActive] = useState(false)
	const webCamRef = useRef(null)

	const generateToken = async () => {
		return new Promise((resolve, reject) => {
			dispatch(store(apiEndpoints.userOnBoard.selfie.qrCodeToken.generate)).then(
				(response) => {
					if (response?.status === 200) {
						resolve({ token: response?.data?.token })
					} else {
						reject()
					}
				},
			)
		})
	}

	const onGenerateQrCode = () => {
		setIsGenerating(true)
		setToken(null)
		generateToken()
			.then((response) => {
				setIsGenerating(false)
				setToken(response?.token)
				setIsOpen(true)
			})
			.catch(() => {
				setIsGenerating(false)
				setToken(null)
			})
	}

	const onTakePicture = () => {
		setSrc(null)
		setIsWebCamActive(true)
	}

	const onCapture = useCallback(() => {
		const selfie = webCamRef?.current?.getScreenshot()
		if (selfie) {
			setSrc(selfie)
			setIsWebCamActive(false)
		}
	})

	const onSave = async () => {
		setIsLoading(true)
		const blob = await fetch(src)
			.then((response) => response.blob())
			.then((blob) => blob)
		const file = new File([blob], 'selfie.jpg', { type: 'image/jpg' })

		generateToken().then((res) => {
			const payload = new FormData()
			payload.append('token', res?.token)
			payload.append('file', file)

			dispatch(store(apiEndpoints.userOnBoard.selfie.upload, payload)).then((response) => {
				setIsLoading(false)
				if (response?.status === 200) {
					showSuccessToast(response?.message)
					mutate(apiEndpoints.userOnBoard.stepStatus)
				}
			})
		})
	}

	return (
		<Fragment>
			<div className='flex max-w-max flex-col space-y-4 text-center'>
				<div className='!flex h-44 w-44 overflow-hidden rounded-full !border border-gray-500'>
					{isWebCamActive ? (
						<Webcam
							imageSmoothing={true}
							mirrored={true}
							screenshotFormat='image/jpeg'
							audio={false}
							height={400}
							width={400}
							videoConstraints={videoConstraints}
							ref={webCamRef}
						/>
					) : (
						<img
							src={src || selfiePlaceholder}
							alt=''
							className='w-full object-cover'
						/>
					)}
				</div>

				<div className='flex items-center justify-center'>
					{isWebCamActive ? (
						<button
							onClick={onCapture}
							className='btn-primary-2 mx-auto flex items-center justify-center space-x-1 !py-1 px-4'
							htmlFor='icon-button-file'>
							<i className='uil uil-camera text-2xl' />
							<span className='font-semibold'>Capture</span>
						</button>
					) : (
						<button
							onClick={onTakePicture}
							className='btn-primary-2 mx-auto flex items-center justify-center space-x-1 !py-1 px-4'
							htmlFor='icon-button-file'>
							<i className='uil uil-camera text-2xl' />
							<span className='font-semibold'>
								{src ? 'Retake' : 'Take a picture'}
							</span>
						</button>
					)}

					{src ? (
						<button
							className='btn btn-primary py-1.5'
							onClick={() => {
								setSrc(null)
								setIsWebCamActive(false)
							}}>
							<i className='uil uil-times text-base' />
						</button>
					) : undefined}
				</div>

				<button
					type='button'
					disabled={isGenerating}
					className='cursor-pointer font-semibold text-[#5B73E8] hover:underline disabled:cursor-not-allowed disabled:no-underline disabled:opacity-50'
					onClick={onGenerateQrCode}>
					{isGenerating ? 'Generating...' : " Don't have a webcam?"}
				</button>
			</div>

			<div>
				<div className='h-6' />
				<button
					type='button'
					className='btn-primary-1'
					disabled={!src || isLoading}
					onClick={onSave}>
					{isLoading ? 'Saving...' : 'Save'}
				</button>
			</div>

			<QrCodeModal modalState={[isOpen, setIsOpen]} token={token} />
		</Fragment>
	)
}

export default Form
