import { useState } from 'react'
import { Link } from 'react-router-dom'
import { routes } from '..'
import DeleteConfirmAlert from './delete-confirm-alert'

const Actions = ({ field, created_at, isActionActive, mutate }) => {
	const [isOpen, setIsOpen] = useState(false)
	const onClose = () => setIsOpen(false)
	const ID = field?.id

	return (
		<div className='w-[170px]'>
			<DeleteConfirmAlert isOpen={isOpen} onClose={onClose} id={ID} mutate={mutate} />
			{isActionActive ? (
				<>
					<div className='flex space-x-3'>
						<Link to={routes.edit(ID)} className='action-btn btn-gray-outline'>
							<i className='uil-edit' style={{ fontSize: 16 }} />
							<span>Edit</span>
						</Link>
						<button
							type='button'
							className='action-btn btn-red-outline'
							onClick={() => setIsOpen(true)}>
							<i className='uil-trash-alt' style={{ fontSize: 16 }} />
							<span>Delete</span>
						</button>
					</div>
				</>
			) : (
				<span className=' text-sm !text-gray-700'>{created_at}</span>
			)}
		</div>
	)
}

export default Actions
