import { Disclosure, Tab } from '@headlessui/react'
import classNames from 'classnames'
import { getReviewFields } from 'pages/Workflows/utils/reviewUtils'
import { Fragment, useMemo } from 'react'
import { findObjectByField } from 'utils/commonFunctions'
import { getTabs } from '../workflow-review'
import { getDocumentBoundFields } from '../workflow-review/helpers'
import FieldsTable from './fields-table'

const FieldsTab = ({ steps, userType }) => {
	const STEP_TYPE = 'individual_document'
	const currentStep = useMemo(() => findObjectByField(steps, 'type', STEP_TYPE), [steps])

	const allFields = useMemo(
		() =>
			getReviewFields(currentStep?.documents).filter((f) => f?.is_document_bound === false) ||
			[],
		[currentStep],
	)

	const fields = useMemo(
		() => allFields.filter((field) => field?.user_type === userType),
		[allFields],
	)

	const documentBoundFields = useMemo(
		() => getDocumentBoundFields(currentStep?.documents, userType),
		[currentStep?.documents, userType],
	)

	const tabs = useMemo(() => getTabs(documentBoundFields.length > 0), [documentBoundFields])
	return (
		<Tab.Group>
			<Tab.List className='mb-[2px] flex divide-x !border-b'>
				{tabs.map((tab, idx) => (
					<Tab
						key={idx}
						className={({ selected }) =>
							classNames(
								'rounded-t !border !border-b-0 px-3 py-2 text-sm hover:bg-white',
								selected ? 'border-slate-200 bg-white' : 'border-transparent',
							)
						}>
						{tab?.label}
					</Tab>
				))}
			</Tab.List>
			<Tab.Panels>
				<Tab.Panel>
					<FieldsTable fields={fields} />
				</Tab.Panel>
				<Tab.Panel>
					<div className='flex flex-col divide-y'>
						{documentBoundFields.map((doc, idx) => {
							return (
								<Fragment key={idx}>
									<Disclosure>
										{({ open }) => (
											<div
												className={classNames(
													'!mb-2 rounded-t !border',
													open ? '!border' : '!border-transparent',
												)}>
												<Disclosure.Button
													as='button'
													className={classNames(
														'!mb-1 flex w-full items-center justify-between rounded border-b bg-white px-3 py-1',
														open ? '' : '',
													)}>
													<span className='text-base font-bold'>
														{idx + 1}. {doc?.title}
													</span>
													<div
														style={{
															fontSize: 28,
														}}>
														{open ? (
															<i className='uil-angle-down' />
														) : (
															<i className='uil-angle-up' />
														)}
													</div>
												</Disclosure.Button>
												<Disclosure.Panel>
													<FieldsTable fields={doc?.fields} />
												</Disclosure.Panel>
											</div>
										)}
									</Disclosure>
								</Fragment>
							)
						})}
					</div>
				</Tab.Panel>
			</Tab.Panels>
		</Tab.Group>
	)
}

export default FieldsTab
