import { getDuration } from 'modules/subscriptions/subscription-listing'
import { formatPrice } from 'utilities/helpers'
import SubscriptionDropdown from './subscription-dropdown'

export const createSubscriptionOption = (subscription) => {
	return {
		label: (
			<div className='flex flex-col space-y-1'>
				<span className='text-[15px] font-bold text-black'>{subscription?.plan_type}</span>
				<div>
					<span className='font-semibold text-main'>
						{formatPrice(subscription.price, 'USD')}
					</span>
					/{' '}
					<span className='text-xs text-gray-500'>
						{getDuration(subscription.duration)}
					</span>
				</div>
			</div>
		),
		value: subscription,
	}
}

export default SubscriptionDropdown
