import { useMemo } from 'react'

import { useQuery } from 'hooks'

import { formatDate } from 'utilities/helpers'
import { OWNER_INVITES_API_ROUTES } from '../routes'

const OwnerInvitesQuery = (pagination) => {
	const { pageSize, pageIndex } = pagination
	const URL = useMemo(
		() =>
			`${OWNER_INVITES_API_ROUTES.findAll()}?roleName=owner&page=${
				pageIndex + 1
			}&limit=${pageSize}`,
		[pageSize, pageIndex],
	)
	const { data, isLoading } = useQuery(URL)

	const invites = useMemo(() => {
		return Array.isArray(data?.data?.users)
			? data?.data?.users.map((invite) => ({
					id: invite?.id,
					email: invite?.user?.email,
					role: { name: invite?.group?.name, id: invite?.group?.id },
					invited_at: formatDate(invite?.user?.created_at),
					expired_at: formatDate(invite?.user?.invitation_expired_at),
			  }))
			: []
	}, [data?.data?.users])

	return {
		invites,
		meta: data?.data?.meta,
		isLoading,
	}
}

export default OwnerInvitesQuery
