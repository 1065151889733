import { useMemo } from 'react'
import { useDebounce } from 'use-debounce'

import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import { createAdvisorOption } from '.'

const ReceiverQuery = (search) => {
	const [debounceSearch] = useDebounce(search, 200)
	const URL = useMemo(
		() => debounceSearch && `${apiEndpoints.user.emailList}?search=${debounceSearch}`,
		[debounceSearch],
	)

	const { data, isLoading } = useQuery(URL)
	const emailList = data?.data?.emails?.length > 0 ? data?.data?.emails : []
	const options = useMemo(() => emailList.map((email) => createAdvisorOption(email)), [emailList])
	return {
		receiver: options,
		isLoading,
	}
}

export default ReceiverQuery
