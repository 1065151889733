import { useMemo } from 'react'
import { useDebounce } from 'use-debounce'

import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import { toQueryString } from 'utils/commonFunctions'
import { createAssigneeOption } from '.'

const AssigneeOptionsQuery = (search) => {
	const [debounceSearch] = useDebounce(search, 600)

	const defaultParams = { page: 1, limit: 20 }
	const searchParams = debounceSearch
		? { ...defaultParams, search: debounceSearch }
		: defaultParams

	const query = toQueryString(searchParams)
	const URL = `${apiEndpoints.user.teamUsers}?${query}`

	const { data, isLoading } = useQuery(URL)
	const assignees = data?.data?.users || []
	const options = useMemo(
		() =>
			Array.isArray(assignees)
				? assignees.map((assignee) => createAssigneeOption(assignee))
				: [],
		[assignees],
	)

	return {
		assigneeOptions: options,
		isLoading,
	}
}

export default AssigneeOptionsQuery
