import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { DataTablePagination } from 'components/Common/data-table'
import { differenceBy } from 'lodash'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import notification from 'utilities/notification'
import { toQueryString } from 'utils/commonFunctions'
import { useLazyGetSettingsQuery, useUpdateSystemSettingsMutation } from '../settings-api'
import SettingsFilters from './settings-filters/settings-filters'
import SettingsListingTable from './settings-listing-table'
const SettingsListingContainer = () => {
	const location = useLocation()
	const history = useHistory()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination
	const parseQueryString = getParseQueryString(location?.search)
	const [isEditable, setIsEditable] = useState(false)
	const [tableData, setTableData] = useState([])
	const [initialValues, setInitialValues] = useState([])

	useEffect(() => {
		if (pagination) {
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			history.push(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	const [fetchSettings, { data, isFetching }] = useLazyGetSettingsQuery()
	const [updateSystemSettings] = useUpdateSystemSettingsMutation()

	useEffect(() => {
		if (location?.search) {
			fetchSettings({
				...parseQueryString,
			})
		}
	}, [location?.search])

	useEffect(() => {
		if (data?.settingsData) {
			setTableData(data?.settingsData)
			setInitialValues(data?.settingsData)
		}
	}, [data])

	const handleInputChange = (id, newValue) => {
		setTableData((prevData) =>
			prevData.map((item) => (item.id === id ? { ...item, setting_value: newValue } : item)),
		)
	}

	const handleChange = () => {
		setIsEditable(!isEditable)
		setTableData(data?.settingsData)
	}

	const table = useReactTable({
		data: data || [],
		getCoreRowModel: getCoreRowModel(),
		manualPagination: true,
		pageCount: data?.meta?.totalPages ?? -1,
		state: { pagination },
		onPaginationChange: setPagination,
	})

	const saveChanges = async () => {
		const settingsData = differenceBy(tableData, initialValues, 'setting_value')
		const changedData = settingsData?.map((setting) => {
			return {
				id: setting?.id,
				setting_key: setting?.setting_key,
				setting_value: setting?.setting_value,
			}
		})
		if (changedData.length > 0) {
			try {
				const response = await updateSystemSettings(changedData)
				if (response?.data?.status === 200) {
					notification('success', response?.data?.message)
				}
				setIsEditable(false)
			} catch (error) {
				notification('warn', error?.message)
			}
		} else {
			setIsEditable(false)
		}
	}

	return (
		<div>
			<div className='m-1'>
				<SettingsFilters
					handleChange={handleChange}
					isEditable={isEditable}
					saveChanges={saveChanges}
					setPagination={setPagination}
				/>

				<>
					<SettingsListingTable
						handleInputChange={handleInputChange}
						tableData={tableData}
						isEditable={isEditable}
						isFetching={isFetching}
					/>
					{pagination && data?.meta ? (
						<DataTablePagination table={table} meta={data?.meta} />
					) : undefined}
				</>
			</div>
		</div>
	)
}

export default SettingsListingContainer
