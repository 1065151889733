import { Fragment } from "react"

const HelperCard = ({ items }) => {

    return (
        <Fragment>
            <div className="flex flex-col space-y-3">
                {items.map((item, idx) => (
                    <div className='bg-[#F7FAFF] p-3 rounded-lg' key={idx}>
                        <div className="flex space-x-3">
                            <div className="min-w-[24px]">
                                {item?.icon}
                            </div>
                            <div>
                                <strong className="text-[#495057] text-base font-medium">{item?.title}</strong>
                                <p className="text-[#74788D] text-sm font-normal " dangerouslySetInnerHTML={{ __html: item?.description }} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Fragment>
    )
}

export default HelperCard