import { Fragment, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import Filters from './filters'
import { useLazyGetWorkflowOutstandingsQuery } from './workflow-outstandings-apis'
import WorkflowOutstandingsTable from './workflow-outstandings-table'

const WorkflowOutstandingsContainer = () => {
	const location = useLocation()
	const history = useHistory()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination

	useEffect(() => {
		if (pagination) {
			const parseQueryString = getParseQueryString(location?.search)
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			history.push(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	const [fetchWorkflowOutstandings, { data, isFetching: isLoading }] =
		useLazyGetWorkflowOutstandingsQuery()

	useEffect(() => {
		if (location?.search) {
			fetchWorkflowOutstandings(getParseQueryString(location?.search))
		}
	}, [location?.search])

	return (
		<Fragment>
			<Filters setPagination={setPagination} />

			<WorkflowOutstandingsTable
				meta={data?.meta}
				isLoading={isLoading}
				pagination={{
					pageSize: limit,
					pageIndex: page - 1,
				}}
				outstandings={data?.outstandings || []}
				setPagination={setPagination}
			/>
		</Fragment>
	)
}

export default WorkflowOutstandingsContainer
