import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'store/axios-base-query'
import { findObjectByField } from 'utils/commonFunctions'
import { findUploadSteps } from '../workflow-review/helpers'

const API_URL = '/workflow/final-review'

export const workflowFinalReviewApi = createApi({
	reducerPath: 'WorkflowFinalReview',
	keepUnusedDataFor: 0,
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['WorkflowFinalReview'],
	endpoints: (builder) => ({
		getWorkflowFinalReviews: builder.query({
			query: (params) => ({
				url: API_URL,
				method: 'GET',
				params: params,
			}),
			providesTags: ['WorkflowFinalReview'],
			transformResponse: (response) => {
				const outstandings = Array.isArray(response?.data?.workflowTemplate)
					? response?.data?.workflowTemplate.map((template) => ({
							...template,
							workflows: Array.isArray(template?.workflows)
								? template?.workflows.map((workflow) => ({
										...workflow,
										user: workflow?.client || workflow?.advisor,
								  }))
								: [],
					  }))
					: []
				return {
					outstandings,
					meta: response?.data?.meta,
				}
			},
		}),
		getOneWorkflowFinalReview: builder.query({
			keepUnusedDataFor: 0,
			query: (id) => ({
				url: `/workflow/review/${id}`,
				method: 'GET',
			}),
			invalidatesTags: ['WorkflowFinalReview'],
			transformResponse: (response) => {
				const steps = response?.data?.workflow_steps || []
				const uploads = findUploadSteps(steps) || []
				const individual_document = findObjectByField(steps, 'type', 'individual_document')

				return {
					workflow: response?.data,
					uploads,
					individual_document,
				}
			},
		}),
		saveWorkflowFinalReview: builder.mutation({
			query: ({ payload, id }) => ({
				url: `workflow/${id}/final-review`,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['WorkflowFinalReview'],
		}),
	}),
})

export const {
	useLazyGetWorkflowFinalReviewsQuery,
	useLazyGetOneWorkflowFinalReviewQuery,
	useSaveWorkflowFinalReviewMutation,
} = workflowFinalReviewApi
