import classNames from 'classnames'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'

import { routes } from 'modules/workflows'
import { getWorkflowLink } from 'modules/workflows/workflow-template'
import notification from 'utilities/notification'
import { toSnakeCase } from 'utils/commonFunctions'
import { useCancelWorkflowOutstandingMutation } from '../workflow-outstandings-apis'

const Actions = ({ workflow, user_type }) => {
	const [cancelWorkflow, { isLoading: isCancelling }] = useCancelWorkflowOutstandingMutation()
	const [copiedIndex, setCopiedIndex] = useState(null)

	const handleCopy = (link, index) => {
		navigator.clipboard.writeText(link)

		setCopiedIndex(index)

		setTimeout(() => {
			setCopiedIndex(null)
		}, 4000)
	}
	const isCancellable =
		toSnakeCase(workflow?.status) === 'completed' ||
		toSnakeCase(workflow?.status) === 'cancelled'

	const isReviewAble = ![
		'assigned',
		'approved',
		'completed',
		'cancelled',
		'waiting_for_signatures',
		'rejected',
		'link',
	].includes(toSnakeCase(workflow?.status))

	const isReadAble = toSnakeCase(workflow?.status) === 'completed'

	const onCancel = async (id) => {
		const res = await Swal.fire({
			title: 'Are you sure you want to cancel this workflow?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
		})
		if (res?.isConfirmed) {
			cancelWorkflow(id).then((response) => {
				if (response?.data?.status === 200) {
					notification('success', response?.message)
				}
			})
		}
	}
	const workflowLink = Array.isArray(workflow?.shortUrls)
		? workflow?.shortUrls[0]?.short_url
		: null
	const $workflowLink = getWorkflowLink(workflowLink)

	return (
		<div className='flex space-x-1'>
			{/* {user_type === 'advisor' &&
			toSnakeCase(workflow?.status) === 'link' &&
			$workflowLink ? (
				<Fragment>
					<ToolTip
						targetId={`workflow-copy-tooltip-${workflow?.id}`}
						tooltipText={'Copy Link'}
					/>
					<button
						type='button'
						className={classNames(
							'action-btn  btn-gray !text-white',
							copiedIndex === workflow?.id ? 'bg-green-600' : 'bg-gray-500',
						)}
						id={`workflow-copy-tooltip-${workflow?.id}`}
						onClick={() => handleCopy($workflowLink, workflow?.id)}>
						<i
							className={classNames(
								`uil mx-2 `,
								copiedIndex === workflow?.id ? 'uil-check' : 'uil-link-alt ',
							)}
						/>
					</button>
				</Fragment>
			) : undefined} */}

			{isReadAble ? (
				<div
					className={classNames({
						'cursor-not-allowed': !isReadAble,
					})}>
					<Link
						className={classNames('action-btn btn-gray', {
							'pointer-events-none !bg-gray-500 opacity-50': !isReadAble,
						})}
						to={{
							pathname: isReadAble ? routes.view(workflow?.id) : '#',
						}}>
						View
					</Link>
				</div>
			) : undefined}

			{isReviewAble ? (
				<div
					className={classNames({
						'cursor-not-allowed': !isReviewAble,
					})}>
					<Link
						className={classNames('action-btn  btn-gray', {
							'pointer-events-none !bg-gray-500 opacity-50': !isReviewAble,
						})}
						to={{
							pathname: isReviewAble ? routes.review(workflow?.id) : '#',
						}}>
						Review
					</Link>
				</div>
			) : undefined}

			{!isCancellable ? (
				<div
					className={classNames({
						'cursor-not-allowed': isCancellable,
					})}>
					<button
						onClick={() => onCancel(workflow?.id)}
						disabled={isCancelling || isCancellable}
						className={classNames('action-btn  bg-red-500 text-white', {
							'pointer-events-none !border-gray-500 !bg-gray-500 opacity-50':
								isCancellable,
						})}>
						{isCancelling ? 'Cancelling...' : 'Cancel'}
					</button>
				</div>
			) : undefined}
		</div>
	)
}

export default Actions
