import { Disclosure } from '@headlessui/react'
import { ReactComponent as FolderIcon } from 'assets/svg/folder-icon.svg'
import classNames from 'classnames'
import { snakeCase } from 'lodash'
import moment from 'moment'
import { Fragment, useMemo, useState } from 'react'
import ToolTip from 'theme/ui/tool-tip'
import Actions from '../actions'
import TableRowBody from './table-row-body'

const TableRow = ({ folder }) => {
	const [isActionActive, setIsActionActive] = useState(false)

	const { data, folderCount, documentCount, lastUpdated } = useMemo(() => {
		const formattedDocuments = folder?.documents?.map((doc) => ({
			...doc,
			type: 'document',
		}))

		const formattedFolders = folder?.childPackages?.map((childFolder) => ({
			...childFolder,
			type: 'folder',
		}))

		return {
			data: [...formattedFolders, ...formattedDocuments],
			folderCount: folder?.childPackages?.length || 0,
			documentCount: folder?.documents?.length || 0,
			lastUpdated: moment(folder?.updated_at).calendar(),
		}
	}, [folder])

	return (
		<Disclosure as='div'>
			{({ open }) => (
				<Fragment>
					<Disclosure.Button
						onMouseEnter={() => setIsActionActive(true)}
						onMouseLeave={() => setIsActionActive(false)}
						className={classNames(
							'flex w-full justify-between border-b px-3 py-2.5',
							open ? 'bg-[#E7EDFF]' : 'bg-white',
							{
								'shadow-[0px_2px_4px_0px_rgba(15,34,58,0.12)]': isActionActive,
							},
						)}>
						<div className='flex w-full items-center justify-between'>
							<div className='inline-flex w-[230px] items-center space-x-1'>
								<i
									className={`uil mt-1 align-middle text-[15px] ${
										open ? 'uil-angle-down' : 'uil-angle-right'
									}`}></i>
								<input
									type='checkbox'
									className='form-check-input'
									onClick={(event) => event.stopPropagation()}
								/>
								<div className='flex items-center space-x-2'>
									<FolderIcon height={15} />
									<span
										id={`${snakeCase(folder?.title)}_title`}
										className='my-1 ml-2 line-clamp-1 text-sm font-medium'>
										{folder?.title}
									</span>
									<ToolTip
										targetId={`${snakeCase(folder?.title)}_title`}
										tooltipText={folder?.title}
									/>
								</div>
							</div>
							<div className='w-[170px]'>
								{folderCount} Folders, {documentCount} Files
							</div>
							<span className='w-[130px] text-[13px]'>{lastUpdated}</span>
							<Actions
								isActionActive={isActionActive}
								data={folder}
								type={'folder'}
							/>
						</div>
					</Disclosure.Button>
					<Disclosure.Panel as='div' className='px-3 py-2'>
						<TableRowBody data={data} />
					</Disclosure.Panel>
				</Fragment>
			)}
		</Disclosure>
	)
}

export default TableRow
