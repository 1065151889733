import Badge from 'theme/ui/badge'
import { formatDate, formatPrice, getStatusVariant, getSubStatusVariant } from 'utilities/helpers'

export const transformData = (data) => {
	const paymentDetails = {
		component: 'Payment Details',
		details: {
			amount: formatPrice(data?.custom_price, data?.currency),
			currency: data?.currency,
			payment_date: formatDate(data?.payment_date),
			payment_method: data?.payment_method,
			status: <Badge variant={getStatusVariant(data?.status)}>{data?.status}</Badge>,
		},
	}

	const subscriptionDetails = {
		component: 'Subscription Details',
		details: {
			'plan type': data?.subscription?.plan?.plan_type,
			description: data?.subscription?.plan?.description,
			'Plan Price':
				data?.subscription?.plan_price !== data?.subscription?.plan?.price ? (
					<div>
						<span>{formatPrice(data?.subscription?.plan_price, data?.currency)}</span>
						<span className='ml-1 !font-normal text-gray-500 line-through'>
							{formatPrice(data?.subscription?.plan?.price, data?.currency)}
						</span>
					</div>
				) : (
					formatPrice(data?.subscription?.plan?.price, data?.currency)
				),
			'User Allowed':
				data?.subscription?.users_allowed !== data?.subscription?.plan?.users_allowed ? (
					<div>
						<span>{data?.subscription?.users_allowed}</span>
						<span className='ml-1 !font-normal text-gray-500 line-through'>
							{data?.subscription?.plan?.users_allowed}
						</span>
					</div>
				) : (
					data?.subscription?.plan?.users_allowed
				),
			status: (
				<Badge variant={getSubStatusVariant(data?.subscription?.status)}>
					{data?.subscription?.status}
				</Badge>
			),

			duration: data?.subscription?.plan?.duration,
			Start: formatDate(data?.subscription?.start_date),
			End: formatDate(data?.subscription?.end_date),
		},
	}

	const userDetails = {
		component: 'User Details',
		details: {
			'Full Name': data?.user?.fullName,
			email: data?.user?.email,
			'Phone Number': data?.user?.phone_number,
		},
	}

	return [paymentDetails, userDetails, subscriptionDetails]
}
