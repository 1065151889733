import { Field, FormikProvider, useFormik } from 'formik'
import { useAppRouter } from 'hooks'
import TeamDropdownField from 'modules/system-fields/system-field-form/team-dropdown-field'
import DatePickerInput from 'pages/Tasks/components/task-form/date-picker'
import { useMemo } from 'react'
import { Button, InputField, RadioGroupField, SelectField } from 'theme/ui/forms'
import TextEditor from 'theme/ui/forms/text-editor'
import notification from 'utilities/notification'
import {
	alertOptions,
	getInitialValues,
	getPayload,
	iconsOptions,
	priorityOptions,
	targetAudianceOptions,
	targetRolesOptions,
	validationSchema,
} from '.'
import { useCreateAnnouncementMutation, useUpdateAnnouncementMutation } from '../announcements-api'
import { ANNOUNCEMENTS_APP_ROUTES } from '../routes'
import UsersDropdownField from './users-dropdown-field'

const AnnouncementsForm = ({ field }) => {
	const initialValues = getInitialValues(field)
	const { goBack, navigate, hasHistory } = useAppRouter()
	const [createAnnouncement] = useCreateAnnouncementMutation()
	const [updateAnnouncement] = useUpdateAnnouncementMutation()
	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema: validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true)
				const payload = getPayload(values)
				let response
				if (field?.id) {
					response = await updateAnnouncement({ payload: payload, id: field?.id })
				} else {
					response = await createAnnouncement(payload)
				}

				if (response?.data?.status === 200) {
					notification('success', response?.data?.message)
					return hasHistory ? goBack() : navigate(ANNOUNCEMENTS_APP_ROUTES.findAll())
				}

				setSubmitting(false)
			} catch (error) {
				setSubmitting(false)
			}
		},
	})
	const targetedAudiance = useMemo(
		() => formik?.values?.target_audience,
		[formik?.values?.target_audience],
	)

	return (
		<>
			<FormikProvider value={formik}>
				<form onSubmit={formik.handleSubmit}>
					<div className='flex w-full gap-10'>
						<div className='w-full'>
							<div className='flex justify-center'>
								<div className='grid grid-cols-1 gap-y-6 rounded bg-white  p-3 sm:max-w-[700px]'>
									<div className='form-heading'>
										<h3>Announcement details</h3>
										<p>Please enter the details of announcement here.</p>
									</div>
									<Field
										label='Title'
										placeholder='Enter announcement title'
										name='title'
										type='text'
										component={InputField}
										isRequiredField
									/>
									<Field
										label='Content'
										name='content'
										component={TextEditor}
										isRequiredField
									/>
									<Field
										label='Link'
										placeholder='Enter hyperlink'
										name='hyperlink'
										type='text'
										component={InputField}
										helperText={
											'Use the hyperlink option to redirect users to a specific location if needed.'
										}
									/>
									<Field
										label='Alert type'
										type='select'
										name='alert_class'
										component={SelectField}
										isSearchable={false}
										placeholder='Select alert type'
										options={alertOptions}
										isRequiredField
										isClearable={false}
									/>
									<Field
										label='Icon'
										type='select'
										name='icon_class'
										component={RadioGroupField}
										isSearchable={false}
										placeholder='Select icon type'
										options={iconsOptions}
										isRequiredField
										isClearable={false}
									/>
									<Field
										label='Dismissible'
										type='select'
										name='is_dismissible'
										component={RadioGroupField}
										placeholder='Select alert type'
										options={[
											{ label: 'True', value: true },
											{ label: 'False', value: false },
										]}
										isRequiredField
										isClearable={false}
									/>
									<Field
										label='Target audience'
										type='select'
										name='target_audience'
										component={RadioGroupField}
										isSearchable={false}
										placeholder='Select target audience'
										options={targetAudianceOptions}
										isRequiredField
										isClearable={false}
									/>
									{targetedAudiance == 'TEAM' && (
										<>
											{' '}
											<TeamDropdownField isRequiredField={true} />
											<Field
												label='Targeted role'
												type='select'
												name='target_role'
												component={RadioGroupField}
												isSearchable={false}
												placeholder='Select targeted role'
												options={targetRolesOptions}
												isRequiredField
												isClearable={false}
											/>
										</>
									)}
									{targetedAudiance == 'USER' && <UsersDropdownField />}
									<Field
										label='Start date'
										name='start_date'
										isRequiredField
										placeholder={'Start date'}
										component={DatePickerInput}
										minDate={new Date()}
									/>
									<Field
										label='End date'
										name='end_date'
										isRequiredField
										placeholder={'End date'}
										component={DatePickerInput}
										minDate={new Date()}
									/>
									<Field
										label='Priority'
										type='select'
										name='priority'
										component={RadioGroupField}
										isSearchable={false}
										placeholder='Select priority'
										options={priorityOptions}
										isRequiredField
										isClearable={false}
									/>
									<Field
										label='Status'
										type='select'
										name='status'
										component={RadioGroupField}
										placeholder='Select status'
										options={[
											{ label: 'ACTIVE', value: 'ACTIVE' },
											{ label: 'INACTIVE', value: 'INACTIVE' },
										]}
										isRequiredField
										isClearable={false}
									/>
									<div className='flex justify-end bg-transparent py-[10px]'>
										<Button
											type='submit'
											onClick={formik.handleSubmit}
											isLoading={formik?.isSubmitting}>
											Save
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
			</FormikProvider>
		</>
	)
}

export default AnnouncementsForm
