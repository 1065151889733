import axios from "axios";

export async function all(url, config = {}) {
    return await axios.get(url, { ...config })
}

export async function find(url, id, config = {}) {
    return await axios.get(`${url}/${id}`, { ...config })
}

export async function store(url, data, config = {}) {
    return axios.post(url, data, { ...config })
}

export async function update(url, id, data, config = {}) {
    return axios.patch(`${url}/${id}`, data, { ...config })
}

export async function destroy(url, id, config = {}) {
    return await axios.delete(`${url}/${id}`, { ...config })
}

export async function remove(url) {
    return await axios.delete(url)
}