import { createColumnHelper } from '@tanstack/react-table'
import { Fragment, useEffect, useMemo, useState } from 'react'

import fileIcon from 'assets/svg/file-icon.svg'
import { useLazyGetWorkflowFilesQuery } from 'modules/clients/client-apis'
import { ReactDataTable } from 'theme/ui/data-table'
import Actions from './actions'
import FilterForm from './filter-form'

const WorkflowFiles = ({ client }) => {
	const clientId = client?.id

	const [sorting, setSorting] = useState([])
	const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 })
	const { pageIndex, pageSize } = pagination
	const [fetchWorkflowFiles, { data, isFetching: isLoading }] = useLazyGetWorkflowFilesQuery()

	const URL = useMemo(() => {
		return clientId ? `/client/${clientId}/workflow-files` : undefined
	}, [clientId])
	useEffect(() => {
		if (pagination) {
			fetchWorkflowFiles({
				params: {
					page: pageIndex + 1,
					limit: pageSize,
				},
				url: URL,
			})
		}
	}, [pagination])

	const columnHelper = createColumnHelper()

	const columns = [
		columnHelper.accessor('index', {
			header: () => <span>#</span>,
			size: 20,
			cell: (info) => <span>{info.getValue()}.</span>,
		}),
		columnHelper.accessor((row) => row, {
			id: 'file_info',
			header: () => <span>File</span>,
			size: 200,

			cell: (info) => (
				<div className='flex max-w-xs items-center space-x-2'>
					<img src={fileIcon} alt='file-icon' className='w-[30px]' />
					<div className='flex flex-col'>
						<span className='font-bold text-gray-800'>{info.getValue()?.title}</span>
						<span className='line-clamp-1 max-w-fit text-xs text-gray-500'>
							{info.getValue()?.file?.original_name}
						</span>
					</div>
				</div>
			),
		}),
		// columnHelper.accessor('file_size', {
		// 	header: () => <span>File Size</span>,
		// 	size: 100,
		// 	cell: (info) => <span>{info.getValue()}</span>,
		// }),
		columnHelper.accessor((row) => row, {
			id: 'created_at',
			header: () => <span>Uploaded On</span>,
			cell: (info) => (
				<span className=' text-sm  !text-gray-700'>{info.getValue()?.created_at}</span>
			),
		}),
		columnHelper.accessor((row) => row.file, {
			id: 'action',
			header: () => <span>Actions</span>,
			cell: (info) => <Actions file={info.getValue()} />,
		}),
	]

	return (
		<Fragment>
			<div className='card !rounded-lg shadow-none'>
				<div className='card-header !border-none !bg-[#F2F5FF] p-4 py-3'>
					<h5 className='mb-0 font-bold'>Files</h5>
				</div>
				<div className='card-body'>
					<FilterForm />
					<ReactDataTable
						columns={columns}
						data={data?.data}
						isLoading={isLoading}
						pagination={pagination}
						setPagination={setPagination}
						setSorting={setSorting}
						sorting={sorting}
						meta={data?.meta}
					/>
				</div>
			</div>
		</Fragment>
	)
}

export default WorkflowFiles
