import { Fragment } from 'react'
import { FieldArray } from 'redux-form'
import SystemFieldsArray from './system-fields-array'

const FieldMappingList = ({ csvFields, dbFieldsOptions, firstRow }) => {
	return (
		<Fragment>
			<div className='row !border !border-b-gray-400 bg-white pt-3 '>
				<div className='col-4 px-3 '>
					<p className='font-extrabold'>CSV Fields</p>
				</div>
				<div className='col-4'></div>
				<div className='col-4 px-1'>
					<p className='font-extrabold'>System Fields</p>
				</div>
			</div>
			<div className='row justify-content-between bg-white'>
				{/* <FieldArray
                        type='text'
                        component={CSVFieldsArray}
                        name='csv_fields'
                        csvFields={csvFields}
                    /> */}
				<div className='col-sm-12 rounded p-3'>
					<FieldArray
						type='select'
						component={SystemFieldsArray}
						name='fields'
						csvFields={csvFields}
						dbFieldsOptions={dbFieldsOptions}
						firstRow={firstRow}
					/>
				</div>
			</div>
		</Fragment>
	)
}

export default FieldMappingList
