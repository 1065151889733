import * as yup from 'yup'

import { capitalize } from 'lodash'
import SubscriptionForm from './subscription-form'

const PLAN_TYPES = {
	INDIVIDUAL: 'individual',
	TEAM: 'team',
	ENTERPRISE: 'enterprise',
}

const createOption = (value, isDisabled = false) => ({
	label: capitalize(value),
	value: value,
	isDisabled,
})

export const planTypeOptions = [
	createOption(PLAN_TYPES.INDIVIDUAL),
	createOption(PLAN_TYPES.TEAM),
	// createOption(PLAN_TYPES.ENTERPRISE),
]

export const DURATIONS = {
	MONTHLY: 'monthly',
	ANNUAL: 'annual',
}

export const durationOptions = [createOption(DURATIONS.MONTHLY), createOption(DURATIONS.ANNUAL)]

export const validationSchema = yup.object().shape({
	plan_type: yup.mixed().required('Please select plan type'),
	description: yup.string().required('Please enter subscription description'),
	duration: yup.mixed().required('Please select duration'),
	price: yup
		.number()
		.min(1, 'Please enter the price greater than 0')
		.required('Please enter price'),
	users_allowed: yup
		.number()
		.min(1, 'Please add at least 1 user')
		.required('Please add number of users allowed'),
	features: yup.array().optional(),
})

export const getInitialValues = (subscription) => {
	return {
		plan_type: subscription?.plan_type ? createOption(subscription?.plan_type) : '',
		description: subscription?.description || '',
		price: subscription?.price || 0,
		users_allowed: subscription?.users_allowed || 0,
		duration: subscription?.duration ? createOption(subscription?.duration) : '',
		features: Array.isArray(subscription?.features)
			? subscription?.features.map((feature) => feature?.id?.toString())
			: [],
	}
}

export const getPayload = (values) => {
	return {
		plan_type: values?.plan_type?.value,
		description: values?.description,
		price: values?.price,
		users_allowed: values?.users_allowed,
		duration: values?.duration?.value,
		features: Array.isArray(values?.features)
			? values?.features.map((feature) => ({ id: parseInt(feature) }))
			: [],
	}
}

export default SubscriptionForm
