import classNames from 'classnames'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Webcam from 'react-webcam'

import { ReactComponent as FileUploadIcon } from 'assets/svg/workflow-file-upload-icon.svg'
import { ReactComponent as FileUploadedIcon } from 'assets/svg/workflow-file-uploaded-icon.svg'
import { useAppRouter } from 'hooks'
import { snakeCase } from 'lodash'
import { ASSIGN_WORKFLOW_APP_ROUTES } from 'modules/assigned-workflows/routes'
import { useMediaQuery } from 'react-responsive'
import { Button } from 'theme/ui/forms'
import { validateFileType } from 'theme/ui/forms/file-upload-field'
import { sanitizeImage } from 'theme/ui/forms/file-upload-field/image-validation'
import { sanitizePdf } from 'theme/ui/forms/file-upload-field/pdf-validation'
import { WorkflowStatuses, formatFileSize } from 'utilities/helpers'
import notification from 'utilities/notification'

const UploadFileTab = ({ file, onSave, uploadedFile, workflow, uploads, tabs }) => {
	const [$file, setFile] = useState(uploadedFile)
	const [isDrafting, setIsDrafting] = useState(false)
	const [isNewSelected, setIsNewSelected] = useState(false)
	const [isSaving, setIsSaving] = useState(false)
	const [isWebCamActive, setIsWebCamActive] = useState(false)
	const { navigate, parseQuery } = useAppRouter()
	const webCamRef = useRef(null)
	const isDesktop = useMediaQuery({
		query: '(min-width: 768px)',
	})
	const videoConstraints = useMemo(
		() => ({
			width: 400,
			height: 284,
			facingMode: 'user',
		}),
		[],
	)

	const onTakePicture = () => {
		setFile(null)
		setIsWebCamActive(true)
	}

	const onCapture = useCallback(async () => {
		const selfie = webCamRef?.current?.getScreenshot()
		const blob = await fetch(selfie)
			.then((response) => response.blob())
			.then((blob) => blob)
		const $selfie = new File([blob], `${snakeCase(file?.file)}.jpg`, { type: 'image/jpg' })

		if ($selfie) {
			setFile($selfie)
			setIsWebCamActive(false)
			setIsNewSelected(true)
		}
	})

	const onDrop = async (acceptedFiles, rejected) => {
		try {
			const rejectedFile = rejected[0]?.errors[0] || null
			if (rejectedFile) {
				throw new Error(rejectedFile?.message)
			}

			const file = acceptedFiles[0]
			if (file) {
				await validateFileType(file, [
					'.pdf',
					'.png',
					'.apng',
					'.gif',
					'.jpg',
					'.jpeg',
					'.jfif',
					'.pjpeg',
					'.pjp',
				])
				const [fileType, fileExtension] = file.type.split('/')
				if (fileType === 'application' && fileExtension === 'pdf') {
					await sanitizePdf(file)
				} else {
					await sanitizeImage(file)
				}
				setFile(file)
				setIsNewSelected(true)
			}
		} catch (error) {
			notification('error', error?.message)
		}
	}
	const { getRootProps, getInputProps } = useDropzone({ onDrop })

	const onSaveDraft = async (file, file_title) => {
		setIsDrafting(true)

		if (file && isNewSelected) {
			const response = await onSave(
				file,
				file_title,
				WorkflowStatuses?.DRAFT,
				uploadedFile?.id,
			)
			if (response?.data?.status === 200) {
				navigate(ASSIGN_WORKFLOW_APP_ROUTES.findAll())
			}
		} else {
			navigate(ASSIGN_WORKFLOW_APP_ROUTES.findAll())
		}
		setIsDrafting(false)
	}

	const onSaveNext = async (file, file_title) => {
		setIsSaving(true)

		const nextStepId = Number(parseQuery?.step) + 1

		if (isNewSelected && workflow?.status === WorkflowStatuses?.DRAFT) {
			const response = await onSave(file, file_title, 'in_review', uploadedFile?.id)
			if (response?.data?.status === 200 && nextStepId <= tabs.length) {
				navigate(`${window.location.pathname}?step=${nextStepId}`)
			}
		}

		if (!isNewSelected && workflow?.status === WorkflowStatuses?.DRAFT) {
			if (nextStepId <= tabs.length) {
				navigate(`${window.location.pathname}?step=${nextStepId}`)
			}
		}

		if (isNewSelected && workflow?.status === WorkflowStatuses?.REJECTED) {
			const $file = uploads?.files?.find((f) => f?.title === file_title)
			const response = await onSave(file, file_title, 'in_review', $file?.files?.id)
			if (response?.data?.status === 200 && nextStepId <= tabs.length) {
				navigate(`${window.location.pathname}?step=${nextStepId}`)
			}
		}

		if (!isNewSelected && workflow?.status === WorkflowStatuses?.REJECTED) {
			if (nextStepId <= tabs.length) {
				navigate(`${window.location.pathname}?step=${nextStepId}`)
			}
		}

		if (isNewSelected && workflow?.status === WorkflowStatuses?.ASSIGNED) {
			const response = await onSave(file, file_title, 'in_review')
			if (response?.data?.status === 200 && nextStepId <= tabs.length) {
				navigate(`${window.location.pathname}?step=${nextStepId}`)
			}
		}

		setIsSaving(false)
	}

	return (
		<div>
			{isDesktop ? (
				<h3 className='text-lg font-semibold text-gray2-dark'>{file?.file}</h3>
			) : undefined}

			{isWebCamActive ? (
				<div className='max-auto h-[285px] w-full max-w-[560px] items-center justify-center overflow-hidden rounded-md !border border-dashed   hover:!border-slate-200 '>
					<div className='mx-auto max-w-fit rounded-md'>
						<Webcam
							imageSmoothing={true}
							mirrored={true}
							screenshotFormat='image/jpeg'
							audio={false}
							height={400}
							width={400}
							videoConstraints={videoConstraints}
							ref={webCamRef}
						/>
					</div>
				</div>
			) : (
				<div
					className={classNames(
						'max-auto !z-40 flex h-[285px] w-full max-w-[560px] items-center justify-center rounded-md !border border-dashed   hover:!border-slate-200 hover:bg-slate-50 active:!border-main/90 active:bg-main/10',
						$file ? 'border-[#228B22]' : 'border-[#B5B5C399]',
					)}
					{...getRootProps()}>
					<input {...getInputProps()} multiple={false} />
					<div className='flex flex-col items-center space-y-1'>
						<div>{$file ? <FileUploadedIcon /> : <FileUploadIcon />}</div>
						<h3 className='m-0 text-lg font-semibold'>
							{$file ? $file?.name : 'Click Here To Upload'}
						</h3>
						<span className='text-sm text-[#74788D]'>
							{$file
								? formatFileSize($file?.size)
								: 'Supported Formats PDF, PNG, JPG'}
						</span>
						{$file ? (
							<div
								className='relative top-3'
								onClick={() => {
									setFile(null)
								}}>
								<span className='absolute !z-50 contents text-base font-bold text-[#8D65DE] hover:underline'>
									Re-upload
								</span>
							</div>
						) : undefined}
					</div>
				</div>
			)}

			{$file ? (
				<div>
					<div className='!my-6 !border' />
					<div className='flex items-center justify-between'>
						<Button
							type='button'
							variant='primary-outline'
							isLoading={isDrafting}
							onClick={() => onSaveDraft($file, file?.file)}>
							Save as Draft
						</Button>
						<Button
							type='button'
							isLoading={isSaving}
							onClick={() => onSaveNext($file, file?.file)}>
							Save and Next
						</Button>
					</div>
				</div>
			) : (
				<div className='my-3 flex items-center justify-center space-x-3'>
					{isWebCamActive ? (
						<div className='flex items-center justify-center space-x-3'>
							<Button
								variant='primary-outline'
								type='button'
								onClick={() => {
									setFile(null)
									setIsWebCamActive(false)
								}}>
								Back
							</Button>

							<Button type='button' onClick={onCapture}>
								Capture Now
							</Button>
						</div>
					) : (
						<Button type='button' variant='primary-outline' onClick={onTakePicture}>
							Take a Pic
						</Button>
					)}
				</div>
			)}
		</div>
	)
}

export default UploadFileTab
