// import { SpinLoader } from 'components/Common'
import { SpinLoader } from 'components/Common'
import { useLazyGetAnnouncementQuery } from 'modules/announcements/announcements-api'
import AnnouncementsForm from 'modules/announcements/announcements-form/announcements-form'
import Pages404 from 'pages/StaticPages/Page404'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const AnnouncementEdit = () => {
	const params = useParams()
	const [fetchAnnouncement, { data, isFetching }] = useLazyGetAnnouncementQuery(params?.id)
	useEffect(() => {
		if (params?.id) {
			fetchAnnouncement(params?.id)
		}
	}, [params?.id])

	return (
		<AppLayout
			meta={{
				title: 'Email Template Edit',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<PageHeader
						title={'Announcement Edit'}
						hasBackButton={true}
						description='Manage your announcements without hassle through our user-friendly
						dashboard. Stay in control of your communication strategy, easily monitor
						template performance and history, and make updates with just a few clicks,
						all in one streamlined hub designed for efficiency.'
					/>
					<div className='page-body'>
						{isFetching ? (
							<SpinLoader />
						) : (
							<>
								{data?.announcement ? (
									<AnnouncementsForm field={data?.announcement} />
								) : (
									<Pages404 />
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default AnnouncementEdit
