import { MDBDataTableV5 } from 'mdbreact'
import { useMemo } from 'react'
import UsersAction from './users-action'

const UsersTab = ({ users, team }) => {
	const columns = useMemo(
		() => [
			{ label: '#', field: 'id' },
			{ label: 'Full Name', field: 'name' },
			{ label: 'Email', field: 'email' },
			{ label: 'Phone Number', field: 'phone' },
			{ label: 'Role', field: 'role' },
			{ label: 'Status', field: 'status' },
			{ label: 'Action', field: 'actions' },
		],
		[],
	)

	const rows = useMemo(
		() =>
			users
				.filter(
					(u) =>
						Array.isArray(u.groups) &&
						u.groups.length > 0 &&
						u.groups[0]?.group?.name !== 'Owner',
				)
				.map((user, idx) => {
					const role =
						Array.isArray(user.groups) && user.groups.length > 0
							? user.groups[0]?.group?.name
							: '-'
					return {
						id: ++idx,
						name: user?.fullName,
						email: user?.email,
						phone: user?.phone_number,
						role: role,
						status: user.status ? 'Active' : 'Inactive',
						actions: !user.hasTeam ? (
							<UsersAction team={team} user={user} />
						) : undefined,
					}
				}),
		[],
	)

	return (
		<div className='rounded-md bg-white p-3'>
			<h5 className='!mb-6 font-semibold text-gray-700'>Other Users</h5>
			<MDBDataTableV5
				responsive
				bordered
				searchTop
				sortable={false}
				searchBottom={false}
				data={{ columns, rows }}
			/>
		</div>
	)
}

export default UsersTab
