import { Link } from 'react-router-dom'

const Header = () => {
	return (
		<div className='flex w-full flex-col'>
			<div className='mb-3 flex w-full justify-between'>
				<div className='max-w-[733px]'>
					<Link
						to={'/dashboard'}
						className='mb-2 flex items-center text-base font-normal not-italic leading-5'>
						<i className='uil-angle-left text-3xl' />
						<span>Back</span>
					</Link>
					<h4 className='page-title mx-3'>Notifications List</h4>
					<p className='mx-3 text-xs text-[#74788D]'>
						Stay updated with all your important alerts and messages in one place. View
						and manage your notifications to never miss out on essential updates
					</p>
				</div>
			</div>
		</div>
	)
}

export default Header
