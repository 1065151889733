import { Switch } from '@headlessui/react'

import notification from 'utilities/notification'
import { useToggleStatusMutation } from '../teams-api'

const ToggleTeamStatus = ({ team }) => {
	const [toggleStatus, { isLoading }] = useToggleStatusMutation()

	const onChange = async (checked, id) => {
		try {
			const payload = { status: checked ? 1 : 0 }
			await toggleStatus({ payload, id })
		} catch (error) {
			notification('warn', error?.message)
		}
	}

	return (
		<div>
			<Switch
				disabled={isLoading}
				checked={team?.status === 1}
				onChange={(checked) => onChange(checked, team?.id)}
				className={`${team?.status === 1 ? 'bg-green-600' : 'bg-gray-300'}
          relative inline-flex h-5 w-10 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}>
				<span className='sr-only'>Use setting</span>
				<span
					aria-hidden='true'
					className={`${team?.status === 1 ? 'translate-x-[20px]' : 'translate-x-0'}
            pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white ring-0 transition duration-200 ease-in-out`}
				/>
			</Switch>
		</div>
	)
}

export default ToggleTeamStatus
