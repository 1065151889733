import DocumentPackageContainer from 'modules/documents/document-packages'
import Header from 'modules/documents/header'
import AppLayout from 'theme/layouts/app-layout'
import { TableProvider } from 'theme/ui/data-table'

const DocumentPackageListing = () => {
	return (
		<AppLayout
			meta={{
				title: 'Document Packages',
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<Header />
					<div className='page-body'>
						<TableProvider>
							<DocumentPackageContainer />
						</TableProvider>
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default DocumentPackageListing
