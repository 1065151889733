/* eslint-disable no-unused-vars */
import Swal from 'sweetalert2'
import { handleCommonError } from '../../helpers/handleCommonError'
import * as requestFromServer from './cruds'
import { CommonSlices } from './slices'

const { actions } = CommonSlices

export const all =
	(url, config = {}) =>
	(dispatch) => {
		dispatch(actions.startCall({}))

		return requestFromServer
			.all(url, (config = {}))
			.then((response) => {
				dispatch(actions.endCall({}))
				if (response.status !== 200) {
					dispatch(actions.catchError({ message: response.data.message }))
					return false
				}

				dispatch(actions.all({ data: response.data.data }))
				return response.data
			})
			.catch((error) => {
				handleCommonError(error, dispatch)
			})
	}

export const store =
	(url, data, config = {}) =>
	(dispatch) => {
		dispatch(actions.startCall({}))

		return requestFromServer
			.store(url, data, config)
			.then((response) => {
				dispatch(actions.endCall({}))
				if (response.status !== 200) {
					dispatch(actions.catchError({ message: response.data.message }))
					return false
				}
				return response.data
			})
			.catch((error) => {
				handleCommonError(error, dispatch)
			})
	}

export const find =
	(url, id, config = {}) =>
	(dispatch) => {
		dispatch(actions.startCall({}))

		return requestFromServer
			.find(url, id, (config = {}))
			.then((response) => {
				dispatch(actions.endCall({}))
				if (response.status !== 200) {
					dispatch(actions.catchError({ message: response.data.message }))
					return false
				}

				return response.data
			})
			.catch((error) => {
				// handleCommonError(error, dispatch)
				return error.response ? error.response : error
			})
	}

export const update =
	(url, id, data, config = {}) =>
	(dispatch) => {
		dispatch(actions.startCall({}))

		return requestFromServer
			.update(url, id, data, (config = {}))
			.then((response) => {
				dispatch(actions.endCall({}))
				if (response.status !== 200) {
					dispatch(actions.catchError({ message: response.data.message }))
					return false
				}
				return response.data
			})
			.catch((error) => {
				handleCommonError(error, dispatch)
			})
	}

export const destroy = (url, id, warnings) => (dispatch) => {
	const title = warnings?.title
	const text = warnings?.text
	let config = {}

	return Swal.fire({
		title: title || 'Are you sure you want to Delete ?',
		text: text || '',
		icon: 'warning',
		showCancelButton: true,
		confirmButtonText: 'Yes',
	}).then((result) => {
		if (result.isConfirmed) {
			return requestFromServer
				.destroy(url, id, (config = {}))
				.then((response) => {
					dispatch(actions.endCall({}))
					if (response.status !== 200) {
						dispatch(actions.catchError({ message: response.data.message }))
						return false
					}
					return response.data
				})
				.catch((error) => {
					handleCommonError(error, dispatch)
				})
		}
	})
}

export const remove = (url) => (dispatch) => {
	return Swal.fire({
		title: 'Are you sure you want to Delete it?',
		icon: 'warning',
		showCancelButton: true,
		confirmButtonText: 'Yes',
	}).then((result) => {
		if (result.isConfirmed) {
			return requestFromServer
				.remove(url)
				.then((response) => {
					dispatch(actions.endCall({}))
					if (response?.status !== 200) {
						dispatch(actions.catchError({ message: response.data.message }))
						return false
					}
					return response.data
				})
				.catch((error) => {
					handleCommonError(error, dispatch)
				})
		}
	})
}

export const permissionCall =
	(url, config = {}) =>
	(dispatch) => {
		dispatch(actions.startPermissionCall({}))

		return requestFromServer
			.all(url, (config = {}))
			.then((response) => {
				dispatch(actions.endCall({}))
				if (response.status !== 200) {
					dispatch(actions.catchError({ message: response.data.message }))
					return false
				}

				dispatch(actions.all({ data: response.data.data }))
				return response.data
			})
			.catch((error) => {
				// handleCommonError(error, dispatch)
				return error
			})
	}

export const destroyRecord =
	(url, id, config = {}) =>
	(dispatch) => {
		return requestFromServer
			.destroy(url, id, (config = {}))
			.then((response) => {
				dispatch(actions.endCall({}))
				if (response.status !== 200) {
					dispatch(actions.catchError({ message: response.data.message }))
					return false
				}
				return response.data
			})
			.catch((error) => {
				handleCommonError(error, dispatch)
			})
	}
