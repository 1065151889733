import classNames from 'classnames'
import PropTypes from 'prop-types'
import CreatableSelect from 'react-select/creatable'
import { FormFeedback, Label } from 'reactstrap'

const CreatableSelectDropdown = ({
	meta: { touched, error },
	label,
	input: { name, value, onBlur, onChange },
	options,
	isMulti,
	variant,
	isDisabled = false,
	isLoading,
	width,
	onCreateOption,
	...custom
}) => {
	const handleCreateOption = (newValue) => {
		if (onCreateOption) {
			onCreateOption(newValue)
		}
		const newOption = { label: newValue, value: newValue }
		if (isMulti) {
			onChange([...value, newOption])
		} else {
			onChange(newOption)
		}
	}

	return (
		<div
			className={classNames('mb-3 flex', {
				'flex-row items-center space-x-[10px]': variant === 'horizontal',
				'flex-col space-y-[10px]': variant === 'default',
			})}>
			{label && <Label className='m-0'>{label}</Label>}
			<div className='flex flex-col'>
				<CreatableSelect
					name={name}
					className={`select-dropdown-container ${width || ''}`}
					classNamePrefix='select-dropdown'
					options={options}
					onChange={onChange}
					onCreateOption={handleCreateOption}
					onBlur={() => onBlur(value)}
					value={value}
					isSearchable
					isMulti={isMulti}
					isLoading={isLoading}
					isClearable
					isDisabled={isDisabled}
					{...custom}
				/>
				{touched && error && (
					<FormFeedback style={{ display: 'block' }}>{touched && error}</FormFeedback>
				)}
			</div>
		</div>
	)
}

CreatableSelectDropdown.propTypes = {
	meta: PropTypes.object,
	input: PropTypes.object.isRequired,
	label: PropTypes.string,
	options: PropTypes.array,
	isMulti: PropTypes.bool,
	variant: PropTypes.oneOf(['default', 'horizontal']),
	onCreateOption: PropTypes.func,
}

CreatableSelectDropdown.defaultProps = {
	meta: null,
	options: [],
	label: '',
	isMulti: false,
	variant: 'default',
}

export default CreatableSelectDropdown
