import { createColumnHelper } from '@tanstack/react-table'
import { Fragment, useState } from 'react'

import DataTable from 'theme/ui/data-table'
import FeatureActions from './feature-actions'

const FeatureTable = ({ data, meta, isLoading, pagination, setPagination }) => {
	const [sorting, setSorting] = useState([])

	const columnHelper = createColumnHelper()

	const columns = [
		columnHelper.accessor('index', {
			size: 20,
			header: () => <span>#</span>,
			cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		}),
		columnHelper.accessor('name', {
			id: 'name',
			size: 200,
			header: () => <span>Feature Name</span>,
			cell: (info) => {
				return (
					<span className='line-clamp-1 max-w-full text-sm font-bold !text-gray-700'>
						{info.getValue()}
					</span>
				)
			},
		}),
		columnHelper.accessor('description', {
			id: 'description',
			size: 250,
			header: () => <span>Feature Description</span>,
			cell: (info) => {
				return (
					<span className='line-clamp-2 max-w-full text-sm font-normal !text-gray-500'>
						{info.getValue()}
					</span>
				)
			},
		}),

		columnHelper.accessor((row) => row.created_at, {
			id: 'created_at',
			size: 200,
			header: () => <span>Created At</span>,
			cell: (info) => <span>{info.getValue()}</span>,
		}),

		columnHelper.accessor((row) => row, {
			id: 'actions',
			size: 200,
			enableSorting: false,
			header: () => <span>Actions</span>,
			cell: (info) => <FeatureActions feature={info.getValue()} />,
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={data}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			setSorting={setSorting}
			sorting={sorting}
			meta={meta}
		/>
	)
}

export default FeatureTable
