import * as yup from 'yup'
import OtpVerifyPopup from './otp-verify-popup'

export const getPayload = (values) => {
	return {
		mfa_token: values?.otp,
		email: values?.email,
		password: values?.password,
	}
}

export const convertPhoneNumber = (phoneNumber) => {
	let phoneNumberStr = phoneNumber.toString()

	if (phoneNumberStr.length < 5) {
		return 'Invalid phone number'
	}
	const stars = new Array(phoneNumberStr.length - 4)
		.fill(0)
		.map(() => '*')
		.join('')

	const maskedPhoneNumber =
		phoneNumberStr.substring(0, 2) + stars + phoneNumberStr.substring(phoneNumberStr.length - 2)

	return maskedPhoneNumber
}

export const validationSchema = yup.object().shape({
	otp: yup.string().required('Please enter your OTP.'),
})

export default OtpVerifyPopup
