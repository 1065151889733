import { Fragment } from 'react'
import { UncontrolledTooltip } from 'reactstrap'

import linkIcon from 'assets/images/svg-icons/link-icon.svg'
import { copyToClipboard } from '..'

const CopyLinkBtn = ({ link }) => {
	return (
		<Fragment>
			<button
				type='button'
				onClick={() => copyToClipboard(link)}
				className='btn !bg-[#F9F9F9] !p-1.5'
				id='copyToClipboard'>
				<img src={linkIcon} />
			</button>
			<UncontrolledTooltip target='copyToClipboard' className='absolute'>
				Copy link to issue
			</UncontrolledTooltip>
		</Fragment>
	)
}

export default CopyLinkBtn
