import { Fragment, useMemo, useState } from 'react'
import { Field } from 'redux-form'
import { useDebounce } from 'use-debounce'

import { SpinLoader } from 'components/Common'
import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import { toQueryString } from 'utils/commonFunctions'

const ClientFilter = ({ onClearFilter }) => {
	const [search, setSearch] = useState('')
	const [debounceSearch] = useDebounce(search, 600)

	const defaultParams = { page: 1, limit: 20, role: 'client' }
	const searchParams = useMemo(() => {
		return debounceSearch ? { ...defaultParams, search: debounceSearch } : defaultParams
	}, [defaultParams, debounceSearch])

	const query = toQueryString(searchParams)
	const URL = `${apiEndpoints.clients}?${query}`

	const { data, isLoading } = useQuery(URL)
	const clients = data?.clients || []
	const options = useMemo(() => (Array.isArray(clients) ? clients : []), [clients])

	return (
		<Fragment>
			<div className='flex shrink-0 flex-col !border bg-white'>
				<div className='w-full px-3 py-2'>
					<input
						className='form-control'
						type='search'
						onChange={(e) => setSearch(e.target.value)}
						placeholder='Search clients...'
					/>
				</div>
				<div className='w-full px-3 py-2'>
					<button
						type='button'
						onClick={onClearFilter}
						className='m-0 text-sm font-medium not-italic leading-5 text-[#495057]'>
						<i className='uil-angle-left text-base' /> <span>Filter via Client</span>
					</button>
				</div>

				<div className='custom-scroll max-h-[154px] divide-y overflow-auto py-2'>
					{isLoading ? (
						<div className='flex items-center space-x-2 px-3 py-2 '>
							<SpinLoader />
						</div>
					) : (
						<Fragment>
							{options.length > 0 ? (
								<Field
									type='checkbox'
									name='client'
									id='client-filter'
									component={CheckBox}
									options={clients}
								/>
							) : (
								<div className='flex items-center justify-center space-x-2 px-3 py-2 '>
									<p className='m-0 text-center text-gray-400'>No match found</p>
								</div>
							)}
						</Fragment>
					)}
				</div>
			</div>
		</Fragment>
	)
}

export default ClientFilter

export const CheckBox = (props) => {
	const { options, input, className } = props

	return (
		<Fragment>
			{options.map((option, index) => (
				<div
					className='flex items-center space-x-2 px-3 py-2 hover:bg-slate-100 active:bg-slate-200'
					key={index}>
					<input
						type='checkbox'
						className={`form-check-input ${className}`}
						style={{ minWidth: 14.5 }}
						id={`${option?.user?.id}_${index}`}
						name={`${input.name}[${index}]`}
						value={option.id}
						checked={input.value.indexOf(option?.user.id) !== -1}
						onChange={(event) => {
							const newValue = [...input.value]
							if (event.target.checked) {
								newValue.push(option?.user.id)
							} else {
								newValue.splice(newValue.indexOf(option?.user.id), 1)
							}
							return input.onChange(newValue)
						}}
					/>
					<label htmlFor={`${option?.user.id}_${index}`} className='mb-0 cursor-pointer'>
						<span className='line-clamp-1 text-sm font-normal not-italic leading-4 text-[#495057]'>
							{option?.user?.first_name}&nbsp;{option?.user?.last_name}
						</span>
						<span className='line-clamp-1 text-xs font-normal text-gray-400'>
							{option?.user?.email}
						</span>
					</label>
				</div>
			))}
		</Fragment>
	)
}
