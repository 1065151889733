import { useState } from 'react'
import { Field } from 'redux-form'

import fileIcon from 'assets/svg/file-icon.svg'
import { RadioComponent } from 'components/Forms/RadioComponent'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import PdfViewer from './pdf-viewer'

const FileCard = ({ file, index, tab }) => {
	let [isOpen, setIsOpen] = useState(false)

	function openModal() {
		setIsOpen(true)
	}
	const toggle = () => setIsOpen(!isOpen)
	const [type, extension] = file?.files?.mime_type.split('/') || []

	return (
		<div>
			<Modal isOpen={isOpen} toggle={toggle} size='lg' fullscreen='lg' backdrop='static'>
				<ModalHeader toggle={toggle}>Document Preview</ModalHeader>
				<ModalBody className='p-0'>
					{type === 'image' ? (
						<div
							className='mx-auto flex items-center
                         justify-center p-3'>
							<img src={file?.files?.path} className='h-auto w-[600px]' />
						</div>
					) : undefined}

					{type === 'application' && extension === 'pdf' ? (
						<PdfViewer fileUrl={file?.files?.path} />
					) : undefined}
				</ModalBody>
			</Modal>

			<div className='bg-white px-4 py-3'>
				<div className='flex flex-col'>
					<a
						href={file?.files?.path}
						style={{
							fontSize: 11,
						}}
						onClick={(e) => {
							e.preventDefault()
							openModal()
						}}
						title='Click Here to View'
						target='_blank'
						rel='noreferrer'>
						<div className='btn-link link w-f-content flex items-center'>
							<div>
								<img src={fileIcon} alt='no-image' />
							</div>
							<div className='ml-[10px] flex-col'>
								<strong className='text-sm font-bold text-gray-700'>
									{file?.title}
								</strong>
								<span
									className='text-sm text-[#74788D]'
									style={{
										overflowWrap: 'anywhere',
									}}>
									{file?.files?.original_name}
								</span>
							</div>
						</div>
					</a>
					<div className='!my-3 border-b'>
						<Field
							name={`files[${index}].title`}
							value={file?.title}
							type='hidden'
							component='input'
						/>
						<Field
							name={`files[${index}].user_type`}
							value={tab?.label}
							type='hidden'
							component='input'
						/>
					</div>
					<div className='flex items-center space-x-4'>
						<Field
							name={`files[${index}].status`}
							value='approved'
							label='Approve'
							type='radio'
							component={RadioComponent}
							defaultChecked={true}
							ignoreBottomMargin={true}
						/>
						<Field
							name={`files[${index}].status`}
							value='reject'
							label='Reject'
							type='radio'
							variant='red'
							component={RadioComponent}
							ignoreBottomMargin={true}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default FileCard
