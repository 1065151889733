import WorkflowFiles from './workflow-files'

export const getFilters = (values) => {
	const filterObj = {}
	if (values?.search) {
		Object.assign(filterObj, { search: values?.search })
	}

	return filterObj
}

export default WorkflowFiles
