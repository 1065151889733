import ExifReader from 'exifreader'

const sanitizeValue = (value) => {
	if (typeof value === 'string') {
		return value.replace(/<[^>]*>?/gm, '')
	}

	if (Array.isArray(value)) {
		return value.map(sanitizeValue)
	}

	if (typeof value === 'object') {
		return sanitizeMetadata(value)
	}

	return value
}

const sanitizeMetadata = (metadata) => {
	const sanitizedMetadata = {}

	for (const key in metadata) {
		// eslint-disable-next-line no-prototype-builtins
		if (metadata.hasOwnProperty(key)) {
			const value = metadata[key]
			sanitizedMetadata[key] = sanitizeValue(value)
		}
	}

	return sanitizedMetadata
}

const loadBuffer = async (arrayBuffer) => {
	return new Promise((resolve, reject) => {
		const tags = ExifReader.load(arrayBuffer)
		if (tags) {
			resolve({ message: 'file accepted' })
		} else {
			reject({ message: 'Invalid image format' })
		}
	})
}

export const sanitizeImage = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()

		reader.onload = async (event) => {
			try {
				const arrayBuffer = event.target.result
				const tags = await loadBuffer(arrayBuffer)

				const sanitizedTags = sanitizeMetadata(tags)
				resolve(sanitizedTags)
			} catch (error) {
				reject({ message: 'The select image is corrupted' })
			}
		}

		reader.onerror = () => {
			reject({ message: 'The select image is corrupted' })
		}

		reader.readAsArrayBuffer(file)
	})
}
