import axios from 'axios'

export const login = (url, data, config) => {
    return axios.post(url, data, { ...config })
}

export const logout = (url, config) => {
    return axios.get(url, { ...config })
}

export const register = (url, data, config) => {
    return axios.post(url, data, { ...config })
}

export const forgotPassword = (url, data, config) => {
    return axios.post(url, data, { ...config })
}

export const resetPassword = (url, data, config) => {
    return axios.post(url, data, { ...config })
}

export const get = (url, config) => {
    return axios.get(url, { ...config })
}