const Header = () => {
	return (
		<div className='flex w-full flex-col'>
			<div className='mb-3 flex w-full justify-between'>
				<div className='max-w-[733px]'>
					<h4 className='page-title'>User Management</h4>
					<p className='text-xs text-[#74788D]'>
						Manage your users with ease from a single, intuitive dashboard. View user
						information and delete accounts with a simple click. Stay organized and
						efficient with all essential user details centralized for your convenience.
					</p>
				</div>
			</div>
			<div className='-mb-[12px] space-x-2'></div>
		</div>
	)
}

export default Header
