import { useAppRouter } from 'hooks'
import { getInitialValuesFileManager, WorkflowForm } from 'modules/workflows/workflow-template'
import { useSelector } from 'react-redux'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const WorkflowTemplateCreate = () => {
	const { parseQuery } = useAppRouter()
	const { selectedFolders, selectedDocuments } = useSelector((state) => state?.fileManager)
	const initialValues = getInitialValuesFileManager(
		parseQuery,
		selectedFolders,
		selectedDocuments,
	)

	return (
		<AppLayout
			meta={{
				title: 'Create Workflow Template',
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<PageHeader
						title='Create Workflow'
						description='When assigning documents/packages here, do not ignore to fill in all the required fields completely and follow the document/package adding rules.'
						hasBackButton={true}
					/>
					<div className='page-body'>
						<WorkflowForm initialValues={initialValues} />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default WorkflowTemplateCreate
