import { useQuery } from 'hooks'

const InvitedClientQuery = (id) => {
	const URL = `users/${id}/user-onboard-detail`
	const { data, isLoading } = useQuery(URL)

	return {
		client: data?.data || [],
		isLoading,
	}
}

export default InvitedClientQuery
