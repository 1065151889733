import { useEffect, useState } from 'react'

function useWindowSize() {
	// Initialize state with the current window dimensions
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	})

	// Effect to handle the resize event
	useEffect(() => {
		// Define the handler to update the state
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			})
		}

		// Add event listener for window resize
		window.addEventListener('resize', handleResize)

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, []) // Empty dependency array means this effect runs once on mount and cleanup on unmount

	return windowSize
}

export default useWindowSize
