import { useMemo } from 'react'

import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import { createRoleOption } from '.'

const RolesQuery = () => {
	const URL = useMemo(() => apiEndpoints.group.groups, [])
	const { data, isLoading } = useQuery(URL)

	const roles = useMemo(() => {
		return Array.isArray(data?.data?.groups)
			? data?.data?.groups.map((group) => createRoleOption(group))
			: []
	}, [data?.data?.groups])

	return { roles, isLoading }
}

export default RolesQuery
