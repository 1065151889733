import { Link } from 'react-router-dom'
import { routes } from '.'

const Header = ({ title }) => {
	return (
		<div className='flex w-full flex-col'>
			<div className='mb-3 flex w-full justify-between'>
				<div className='max-w-[733px]'>
					{title ? <h4 className='page-title'>{title}</h4> : undefined}
					<p className='text-xs text-[#74788D]'>
						These system fields, integral to the document mapping process, are available
						for your customization. You have the flexibility to precisely manage and
						configure these fields directly from this designated interface, empowering
						you to tailor the document mapping to meet your specific needs and
						preferences.
					</p>
				</div>

				<Link
					to={routes.create()}
					className='h-fit min-w-max rounded bg-main px-3 py-2 text-white'>
					Create new
				</Link>
			</div>
		</div>
	)
}

export default Header
