/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo, useState } from 'react'
import { mutate } from 'swr'

import apiEndpoints from 'helpers/apiEndpoints'
import Layout from '../components/Layout'
import LoadingPopup from '../components/LoadingPopup'
import SuccessPopup from '../components/SuccessPopup'
import withSteps from '../components/withSteps'
import Form from './Form'
import { GOVERNMENT_DOCUMENT, VALID_DOCUMENT, godHelpers, upload, validIdHelpers } from './helpers'

const UploadDocuments = (props) => {
	const { history } = props
	const steps = props?.step

	const notes = Array.isArray(steps) ? steps.map((step) => step.reason) : []

	const _steps = useMemo(() => {
		return Array.isArray(steps) ? steps.map((step) => step?.status) : ['pending', 'pending']
	}, [steps])
	const [validIdStatus, godStatus] = _steps

	const [isVerifying, setIsVerifying] = useState(false)
	const [isVerified, setIsVerified] = useState(false)
	const TIMER = 3000

	const [validID, setValidID] = useState(null)
	const [god, setgod] = useState(null)
	// eslint-disable-next-line no-unused-vars
	const [isValidIdUploaded, setIsValidIdUploaded] = useState(false)

	const helperItems = useMemo(() => {
		return isValidIdUploaded ? godHelpers : validIdHelpers
	}, [isValidIdUploaded])

	const isContinue = useMemo(() => {
		let _isContinue
		if (validIdStatus === 'pending' && validID === null) {
			_isContinue = false
		} else if (godStatus === 'pending' && god === null) {
			_isContinue = false
		} else {
			_isContinue = true
		}
		return _isContinue
	}, [validIdStatus, godStatus, validID, god])

	const onValidIdDrop = useCallback((files) => {
		const file = files[0]
		if (file) {
			setValidID(file)
		}
	}, [])

	const onGodDrop = useCallback((files) => {
		const file = files[0]
		if (file) {
			setgod(file)
		}
	}, [])

	const verify = () => {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve()
			}, 2000)
		})
	}

	const onContinue = async (arg) => {
		try {
			setIsVerifying(true)
			const uploadFile = async (file, type) => {
				await upload({ file, type, is_recognised: arg === 'verify' })
			}

			if (validID) {
				await uploadFile(validID, VALID_DOCUMENT)
			}
			if (god) {
				await uploadFile(god, GOVERNMENT_DOCUMENT)
			}
			setIsVerified(true)
			setIsVerifying(false)
			await verify()
			mutate(apiEndpoints.userOnBoard.stepStatus)
		} catch (error) {
			// showErrorToast(error?.message || 'Something went wrong')
		}
	}

	return (
		<Layout helperItems={helperItems}>
			<div>
				{isVerified && <SuccessPopup isVisible={isVerified} />}
				{isVerifying && <LoadingPopup timer={TIMER} isVisible={isVerifying} />}
			</div>

			<div className='grid grid-cols-12'>
				<div className='col-span-1' />
				<div className='col-span-9 px-2'>
					<h3 className='text-xl font-bold'>
						Upload required identification documents for verification
					</h3>
					<p className='text-[#74788D]'>
						Your valid ID&apos;s are your ID&apos;s like driving license, voter ID card
						etc.
					</p>

					<Form
						steps={_steps}
						onValidIdDrop={onValidIdDrop}
						validIdState={[validID, setValidID]}
						onGodDrop={onGodDrop}
						godState={[god, setgod]}
						history={history}
						onContinue={onContinue}
						isContinue={isContinue}
						notes={notes}
					/>
				</div>
			</div>
		</Layout>
	)
}

export default withSteps(UploadDocuments)
