import { flattenArray } from '../utils/commonFunctions'

const useFormErrors = (errors) => {
	try {
		if (typeof errors !== 'object') {
			return null
		}

		return flatErrors(errors)
	} catch (error) {
		return error?.message
	}
}

export const getSystemFieldsError = (errors) => {
	try {
		if (Array.isArray(errors)) {
			const _errors = errors
				.filter((error) => error)
				.map((error) => Object.values(Object.values(error)))
			const _errorsArray = flattenArray(_errors)
			return _errorsArray[0] || null
		} else {
			return null
		}
	} catch (error) {
		return error?.message
	}
}

export const checkErrors = (errors) => {
	return new Promise((resolve) => {
		if (typeof errors !== 'object') {
			return null
		}

		const error = flatErrors(errors)
		resolve(error)
	})
}

const flatErrors = (errors) => {
	try {
		const errorValues = Object.values(errors)
		if (!Array.isArray(errorValues)) {
			return null
		}

		const flatValues = flattenArray(errorValues)

		if (Array.isArray(flatValues)) {
			for (let i = 0; i < flatValues.length; i++) {
				let item = flatValues[i]
				if (typeof item === 'string') {
					return item
				}

				if (typeof item === 'object') {
					return flatErrors(item)
				}
			}
		}
	} catch (error) {
		return error?.message
	}
}

export default useFormErrors
