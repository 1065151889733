import moment from 'moment'
import DateRange from './date-range'

const DATE_FORMAT = 'yyyy-MM-DD'

export const getDateRange = (range) => {
	switch (range) {
		case 'today':
			return {
				from: moment(new Date()).format(DATE_FORMAT),
				to: moment(new Date()).format(DATE_FORMAT),
			}

		case 'yesterday':
			return {
				from: moment().subtract(1, 'days').format(DATE_FORMAT),
				to: moment(new Date()).format(DATE_FORMAT),
			}

		case 'last_7_days':
			return {
				from: moment().subtract(7, 'days').format(DATE_FORMAT),
				to: moment(new Date()).format(DATE_FORMAT),
			}

		case 'last_30_days':
			return {
				from: moment().subtract(30, 'days').format(DATE_FORMAT),
				to: moment(new Date()).format(DATE_FORMAT),
			}

		case 'last_6_months':
			return {
				from: moment().subtract(180, 'days').format(DATE_FORMAT),
				to: moment(new Date()).format(DATE_FORMAT),
			}

		default:
			// eslint-disable-next-line no-case-declarations
			const dateRange = range.split(',')

			if (Array.isArray(dateRange) && dateRange.length > 1) {
				const [start, end] = dateRange

				if (
					moment(start, DATE_FORMAT, true).isValid() &&
					moment(end, DATE_FORMAT, true).isValid()
				) {
					return {
						from: start,
						to: end,
					}
				}
			}
	}
}

export const getDateLabel = (from, to) => {
	if (from === moment(from).format(DATE_FORMAT) && to === moment(to).format(DATE_FORMAT)) {
		return 'today'
	}

	if (
		from === moment().subtract(1, 'days').format(DATE_FORMAT) &&
		to === moment(to).format(DATE_FORMAT)
	) {
		return 'yesterday'
	}

	if (
		from === moment().subtract(7, 'days').format(DATE_FORMAT) &&
		to === moment(to).format(DATE_FORMAT)
	) {
		return 'last_7_days'
	}

	if (
		from === moment().subtract(30, 'days').format(DATE_FORMAT) &&
		to === moment(to).format(DATE_FORMAT)
	) {
		return 'last_30_days'
	}

	if (
		from === moment().subtract(180, 'days').format(DATE_FORMAT) &&
		to === moment(to).format(DATE_FORMAT)
	) {
		return 'last_6_months'
	}

	return 'custom_range'
}

export default DateRange
