import DeletedDocumentTemplateContainer from 'modules/documents/deleted-document-templates'
import DocumentTabs from 'modules/documents/document-tabs'
import Header from 'modules/documents/header'
import AppLayout from 'theme/layouts/app-layout'

const DeletedDocumentTemplateListing = () => {
	return (
		<AppLayout
			meta={{
				title: 'Deleted Document Templates',
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<Header />
					<div className='page-body'>
						<DocumentTabs />
						<div className='mt-3'>
							<DeletedDocumentTemplateContainer />
						</div>
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default DeletedDocumentTemplateListing
