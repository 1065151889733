import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import { useMemo } from 'react'
import { useDebounce } from 'use-debounce'
import { toQueryString } from 'utils/commonFunctions'

const AdvisorQuery = (search) => {
	const [debounceSearch] = useDebounce(search, 600)

	const defaultParams = { page: 1, limit: 20 }
	const searchParams = useMemo(() => {
		return debounceSearch ? { ...defaultParams, search: debounceSearch } : defaultParams
	}, [defaultParams, debounceSearch])

	const query = toQueryString(searchParams)
	const URL = `${apiEndpoints.user.users}?${query}`

	const { data, isLoading } = useQuery(URL)
	const advisors = data?.data[0] || []
	const options = useMemo(
		() =>
			Array.isArray(advisors)
				? advisors.map((advisor) => ({
						label: (
							<span
								className='line-clamp-1 min-w-fit max-w-fit'
								title={`${advisor?.first_name} ${advisor?.last_name}`}>{`${advisor?.first_name} ${advisor?.last_name}`}</span>
						),
						value: advisor?.id,
				  }))
				: [],
		[advisors],
	)

	return {
		options,
		isLoading,
	}
}

export default AdvisorQuery
