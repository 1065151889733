import { Fragment, useState } from 'react'
import { Tooltip } from 'reactstrap'
import Badge from 'theme/ui/badge'

const FoldersColumn = ({ folders, document }) => {
	const [tooltipOpen, setTooltipOpen] = useState(false)
	const toggle = () => setTooltipOpen(!tooltipOpen)

	return (
		<Fragment>
			{folders.length > 0 ? (
				<div className='flex max-w-[165px] flex-wrap items-center gap-1'>
					<Badge variant='primary-outline' className='max-w-[80px]' rounded={false}>
						<span className='line-clamp-1'>{folders[0]}</span>
					</Badge>
					<Badge
						id={`tooltip-${document?.id}-folder`}
						variant='primary-outline'
						className='cursor-pointer'
						rounded={false}>
						+{folders.slice(1).length}
					</Badge>
					<Tooltip
						isOpen={tooltipOpen}
						target={`tooltip-${document?.id}-folder`}
						toggle={toggle}
						placement='auto'>
						<div>
							<ul className='m-0 list-disc pl-3'>
								{folders.map((folder, idx) => (
									<li key={idx}>{folder}</li>
								))}
							</ul>
						</div>
					</Tooltip>
				</div>
			) : undefined}
		</Fragment>
	)
}

export default FoldersColumn
