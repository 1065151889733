import { capitalize, uniqWith } from 'lodash'
import { getDateRange } from './date-range'
import Filters from './filters'

export const getFormikFilterValues = (values) => {
	const filters = {}

	const search = values?.search || ''
	if (search) {
		Object.assign(filters, { search })
	}

	const role = values?.role || ''
	if (role) {
		Object.assign(filters, { role: role?.value })
	}

	if (values?.date_range) {
		const date_range = getDateRange(values?.date_range)
		Object.assign(filters, { ...date_range })
	}

	return filters
}

export const getInitialValues = (queryObj) => {
	const initialValues = {
		search: '',
		role: '',
		date_range: ',',
	}
	if (queryObj?.search) {
		Object.assign(initialValues, { search: queryObj?.search })
	}

	if (queryObj?.status) {
		Object.assign(initialValues, {
			role: { label: capitalize(queryObj?.role), value: queryObj?.role },
		})
	}

	if (queryObj?.from && queryObj?.to) {
		Object.assign(initialValues, {
			date_range: `${queryObj?.from},${queryObj?.to}`,
		})
	}

	return initialValues
}

export const filterKeys = {
	search: 'Search',
	role: 'Role',
	from: 'Date Range',
	to: 'Date Range',
}

const getFitlerKey = (key) => {
	if (['from', 'to'].includes(key)) {
		return 'date_range'
	}
	return key
}

export const getActiveFilters = (filterObj) => {
	const filters = Object.entries(filterObj)
		.filter((filter) => !['page', 'limit'].includes(filter[0]))
		.filter((filter) => {
			// eslint-disable-next-line no-unused-vars
			const [_, values] = filter
			return values.length > 0
		})
		.map((filter) => {
			const [key, values] = filter
			return [
				{
					label: filterKeys[key],
					name: getFitlerKey(key),
				},
				values,
			]
		})

	return uniqWith(filters, (arrayVal, otherVal) => {
		return arrayVal[0]?.name === otherVal[0]?.name
	})
}

export default Filters
