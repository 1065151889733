import { Col } from 'reactstrap'

const DocumentCard = ({ imgUrl, title, description }) => {

	return (
		<Col md={4} lg={3} className="!border border-gray-300 rounded-lg p-2.5 m-2 h-full">
			<img src={imgUrl}
				className='rounded-full mb-3'
				alt={title} />

			<h5 className='font-semibold text-base'>{title}</h5>
			<p className='text-slate-500 line-clamp-4'>{description}</p>

		</Col>

	)
}

export default DocumentCard