import { useAppRouter } from 'hooks'
import { useLazyGetDocumentVersionQuery } from 'modules/documents/document-versions/document-version-api'
import DocumentVersionDetailsContainer from 'modules/documents/document-versions/document-version-details'
import { useEffect, useMemo } from 'react'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'
import Spinner from 'theme/ui/spinner'

const DocumentTemplateVersionDetails = () => {
	const { params } = useAppRouter()
	const versionId = params?.id
	const [fetchDocument, { data, isUninitialized, isLoading }] = useLazyGetDocumentVersionQuery()
	useEffect(() => {
		if (versionId) {
			fetchDocument(versionId)
		}
	}, [versionId])
	const document = useMemo(() => data?.data, [data?.data])

	return (
		<AppLayout
			meta={{
				title: 'Document Template Version Details',
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<PageHeader
						title='Document Template Version '
						description='When assigning documents/packages here, do not ignore to fill in all the required fields completely and follow the document/package adding rules.'
						hasBackButton={true}
					/>
					<div className='page-body'>
						{isUninitialized || isLoading ? (
							<Spinner />
						) : (
							<DocumentVersionDetailsContainer document={document} />
						)}
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default DocumentTemplateVersionDetails
