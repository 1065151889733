/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import { add } from '../../utils/storageService'

const initialState = {
	error: null,
	loading: false,
	isSuperAdmin: false,
	isOnBoarded: false,
	defaultGroup: null,
	groups: [],
	hasTeam: null,
	user_id: 0,
	data: [],
}

export const PermissionSlices = createSlice({
	name: 'permissions',
	initialState: initialState,
	reducers: {
		startCall: (state, action) => {
			state.error = null
			state.loading = true
		},

		endCall: (state, action) => {
			state.error = null
			state.loading = false
		},

		catchError: (state, action) => {
			state.loading = false
			state.error = action.payload.message
		},

		setPermission: (state, action) => {
			const { is_root_user, groups, hasTeam, id } = action.payload.user
			const isOnBoarded = action.payload?.isOnBoarded

			state.isSuperAdmin = is_root_user
			state.groups = groups
			state.hasTeam = hasTeam ? hasTeam : null
			state.user_id = id
			state.isOnBoarded = isOnBoarded
		},

		setDefaultGroup: (state, action) => {
			if (action && action.payload) {
				const { group } = action.payload
				const permissions = group && group.accesses ? group.accesses : []
				permissions.length > 0
					? add('permissions', JSON.stringify(permissions))
					: add('permissions', [])
			}
			state.defaultGroup = action.payload
		},

		store: (state, action) => {
			state.data = action.payload.data
		},

		setHasTeam: (state, action) => {
			state.hasTeam = action.payload
		},

		resetPermissions: (state, action) => {
			state.error = null
			state.loading = false
			state.isSuperAdmin = false
			state.defaultGroup = null
			state.groups = []
			state.hasTeam = null
			state.user_id = 0
			state.data = []
		},
	},
})

export const { endCall, catchError } = PermissionSlices.actions
