import { Fragment } from 'react'
import { Button, Spinner } from 'reactstrap'

const ButtonWithLoader = (props) => {
	const {
		loading,
		type = 'button',
		color = 'primary',
		className,
		children,
		isDisabled,
		...rest
	} = props
	return (
		<Fragment>
			{loading ? (
				<Button color={color} type='button' disabled className={className} {...rest}>
					<Spinner size='sm' />
					&nbsp;&nbsp;Loading...
				</Button>
			) : (
				<Button
					color={color}
					type={type}
					disabled={isDisabled}
					className={className}
					{...rest}>
					{children}
				</Button>
			)}
		</Fragment>
	)
}

export default ButtonWithLoader
