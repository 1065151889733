import { Fragment, useState } from 'react'
import { Card, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'

import classNames from 'classnames'
import { useUser } from 'hooks'
import { isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'
import { setLastTabValue } from 'utils/lastTabValue'
import MySubscriptions from './my-subscriptions'
import ProfileManage from './profile'
import WorkflowFiles from './workflow-files'

const ProfileTabs = () => {
	const [activeTab, setActiveTab] = useState(1)
	const { user, group } = useUser()

	const toggleTab = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab)
			setLastTabValue(tab)
		}
	}

	const tabs = [
		{
			id: 1,
			label: 'Profile',
			icon: <i className='uil-user-circle font-size-20' />,
			component: <ProfileManage />,
			permission: isAllowTo(permissions?.profileDetails?.list),
		},
		{
			id: 2,
			label: 'Workflow Files',
			icon: <i className='uil-files-landscapes-alt font-size-20' />,
			component: <WorkflowFiles user_id={user?.id} groupSlug={group?.slug} />,
			permission: isAllowTo(permissions?.workflowFiles?.list),
		},
		{
			id: 3,
			label: 'My Subscriptions',
			icon: <i className='uil-money-insert font-size-20' />,
			component: <MySubscriptions activeTab={activeTab} />,
			permission:
				isAllowTo(permissions?.mySubscription?.list) ||
				isAllowTo(permissions?.mySubscription?.view),
		},
	]
		.filter((item) => item?.permission)
		.map((t, idx) => ({ ...t, id: idx + 1 }))

	return (
		<Fragment>
			{tabs.length > 0 ? (
				<Card className='mb-0'>
					<Nav tabs className='nav-tabs-custom nav-justified !flex flex-nowrap'>
						{tabs.map((tab, idx) => (
							<NavItem key={idx}>
								<NavLink
									className={classNames({
										active: activeTab === tab?.id,
									})}
									onClick={() => toggleTab(tab?.id)}>
									<span>{tab?.icon}</span>
									<span className='d-sm-block d-none'>
										<span className='block'>{tab?.label}</span>
									</span>
								</NavLink>
							</NavItem>
						))}
					</Nav>
					<TabContent activeTab={activeTab} className='p-4'>
						{tabs.map((tab, idx) => (
							<TabPane tabId={tab?.id} key={idx}>
								{tab?.component}
							</TabPane>
						))}
					</TabContent>
				</Card>
			) : undefined}
		</Fragment>
	)
}

export default ProfileTabs
