import { FileManagerLayout } from 'modules/file-manager'

const FolderDetails = () => {
	return (
		<FileManagerLayout
			meta={{
				title: 'File Manager - FolderDetails',
			}}>
			<div>FolderDetails</div>
		</FileManagerLayout>
	)
}

export default FolderDetails
