import { useState } from 'react'
import OwnerInvitesListing from './owner-invites-listing'
import OwnerInvitesQuery from './owner-invites-query'

const OwnerInvitesContainer = () => {
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 5,
	})
	const { invites, meta, isLoading } = OwnerInvitesQuery(pagination)

	return (
		<OwnerInvitesListing
			invites={invites}
			isLoading={isLoading}
			meta={meta}
			pagination={pagination}
			setPagination={setPagination}
		/>
	)
}

export default OwnerInvitesContainer
