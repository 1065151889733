import { Field, FormikProvider, useFormik } from 'formik'
import { useSelector } from 'react-redux'
import { Modal } from 'reactstrap'
import * as yup from 'yup'

import { Button, TextareaField } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { useCreateNoteMutation, useUpdateNoteMutation } from './client-notes-api'

const CreateNewNote = ({ client, note, isOpen, onClose }) => {
	const [createNote] = useCreateNoteMutation()
	const [updateNote] = useUpdateNoteMutation()

	const { defaultGroup } = useSelector((state) => state.Permissions)

	const team = defaultGroup?.team

	const formik = useFormik({
		initialValues: {
			notes: note?.note || '',
		},
		validationSchema: yup.object().shape({
			notes: yup.string().required('Please enter notes'),
		}),
		enableReinitialize: true,
		onSubmit: async (values, { resetForm }) => {
			try {
				const payload = {
					...values,
					visibility: 0,
					teams: [{ id: team?.id }],
					users: [{ id: client?.id }],
				}
				let response = null
				if (note) {
					response = await updateNote({ payload, id: note?.id })
				} else {
					response = await createNote(payload)
				}
				if (response?.data?.status === 200) {
					onClose()
					resetForm()
					notification('success', response?.data?.message)
				}
			} catch (error) {
				notification('error', error?.message)
			}
		},
	})

	return (
		<Modal isOpen={isOpen} toggle={onClose}>
			<div className='modal-header'>
				<h5 className='modal-title mt-0' id='myModalLabel'>
					{note ? 'Edit' : 'Create'} Note
				</h5>
				<button
					type='button'
					onClick={onClose}
					className='close'
					data-dismiss='modal'
					aria-label='Close'>
					<span aria-hidden='true'>&times;</span>
				</button>
			</div>

			<FormikProvider value={formik}>
				<form onSubmit={formik.handleSubmit}>
					<div className='modal-body'>
						<Field
							label='Notes'
							type='textarea'
							name='notes'
							component={TextareaField}
						/>
					</div>
					<div className='modal-footer'>
						<div className='d-flex align-items-center justify-content-center w-100'>
							<button
								type='button'
								onClick={onClose}
								className='btn btn-secondary waves-effect'
								data-dismiss='modal'>
								Close
							</button>
							&nbsp;
							<Button isLoading={formik.isSubmitting} type='submit'>
								Save
							</Button>
						</div>
					</div>
				</form>
			</FormikProvider>
		</Modal>
	)
}

export default CreateNewNote
