import { createApi } from '@reduxjs/toolkit/query/react'
import apiEndpoints from 'helpers/apiEndpoints'
import axiosBaseQuery from 'store/axios-base-query'

const API_URL = apiEndpoints.notifications

export const notificationApi = createApi({
	reducerPath: 'Notifications',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['Notifications'],
	endpoints: (builder) => ({
		getNotifications: builder.query({
			keepUnusedDataFor: 0,
			query: (params) => ({
				url: API_URL,
				method: 'GET',
				params: params,
			}),
			providesTags: ['Notifications'],
			transformResponse: (response) => {
				const unreadNotificationCount = response?.data?.unreadCount
				const notifications = Array.isArray(response?.data?.notifications)
					? response?.data?.notifications.map((notification) => ({
							id: notification?.id,
							title: notification?.title,
							content: notification?.content,
							created_at: notification?.created_at,
							is_read: notification?.is_read,
							message: notification?.message,
							type: notification?.type,
							link: notification?.link,
							profilePhoto: notification?.from_user?.avatar?.path,
					  }))
					: []
				return {
					notifications,
					unreadNotificationCount,
					meta: response?.data?.meta,
				}
			},
		}),
		updateNotification: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${API_URL}/${id}`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['Notifications'],
		}),
		toggleRead: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${API_URL}/${id}/toggle-read`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['Notifications'],
		}),
		readAll: builder.mutation({
			query: () => ({
				url: `${API_URL}/read-all`,
				method: 'PATCH',
			}),

			invalidatesTags: ['Notifications'],
		}),
	}),
})

export const {
	useLazyGetNotificationsQuery,
	useUpdateNotificationMutation,
	useToggleReadMutation,
	useReadAllMutation,
} = notificationApi
