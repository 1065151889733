import { createApi } from '@reduxjs/toolkit/query/react'
import apiEndpoints from 'helpers/apiEndpoints'

import axiosBaseQuery from 'store/axios-base-query'

const API_URL = apiEndpoints.task

export const taskApi = createApi({
	reducerPath: 'Tasks',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['Tasks'],
	endpoints: (builder) => ({
		deleteTask: builder.mutation({
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Tasks'],
		}),
	}),
})

export const { useDeleteTaskMutation } = taskApi
