import WorkflowDropdown from './WorkflowDropdown'

export default WorkflowDropdown

export const createWorkflowOption = (workflow) => ({
	title: workflow?.title,
	value: workflow?.id,
})

export const creatWorkflowOptionLabel = (workflow) => {
	return (
		<div className='flex items-center space-x-1'>
			<span className='font-bold text-gray-700'>{workflow?.title}</span>
		</div>
	)
}
