const K = {
	STEPS: {
		UPLOADS: 'uploads',
		INDIVIDUAL_DOCUMENT: 'individual_document',
		REVIEW_PROCESS: 'review_process',
		CORRECTIONS: 'corrections',
		SIGNATURES: 'signatures',
	},
	STATUS: {
		IN_CORRECTION: 'rejected',
		APPROVED: 'approved',
		WAITING_FOR_SIGNATURES: 'waiting_for_signatures',
		COMPLETED: 'completed',
		FINAL_REVIEW: 'in_final_review',
		FINAL_REVIEW_REJECTED: 'final_reviewer_rejected',
		FINAL_REVIEW_APPROVED: 'final_reviewer_approved',
		SIGNED: 'signed',
		GATHERING_SIGNATURE: 'gathering_signatures',
	},
}
export default K
