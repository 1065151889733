import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'
import { desktopAnimation, mobileAnimation } from '.'

const Popup = ({ isOpen, header, children }) => {
	const isDesktop = useMediaQuery({
		query: '(min-width: 768px)',
	})

	const animationConfig = useMemo(
		() => (isDesktop ? desktopAnimation : mobileAnimation),
		[isDesktop],
	)

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='popup-overlay relative' onClose={() => {}}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/25' />
				</Transition.Child>

				<div className='fixed inset-0 flex w-screen items-end justify-center md:items-center md:p-4'>
					<Transition.Child as={Fragment} {...animationConfig}>
						<Dialog.Panel className='w-full max-w-[516px] rounded-[20px] bg-white'>
							{header}

							<div className='rounded-t-[20px] bg-[#F8F8F8] p-3 md:rounded-b-[20px]'>
								{children}
							</div>
						</Dialog.Panel>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	)
}

export default Popup
