import { findIndex, toLower } from 'lodash'
import store from '../store/index'

export const hasPermission = (module) => {
	try {
		const defaultGroup = store?.getState()?.Permissions?.defaultGroup
		const permissions = defaultGroup?.group?.accesses || []
		if (Array.isArray(permissions) && permissions.length > 0 && module) {
			return findIndex(permissions, (o) => toLower(o.module) === toLower(module)) > -1
		}
		return findIndex(permissions, (o) => toLower(o.module) === toLower(module)) > -1
	} catch (error) {
		return false
	}
}

export default hasPermission
