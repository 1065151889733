import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { formatDate } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import { getFilters } from './filters-form'

const systemFieldsListQuery = (pagination) => {
	const { pageIndex, pageSize } = pagination
	const formValues = useSelector((state) => state.form.system_filters?.values || {})
	const filterValues = useMemo(() => getFilters(formValues), [formValues])

	const filters = useMemo(() => {
		const obj = { page: pageIndex + 1, limit: pageSize, ...filterValues }
		return obj
	}, [pageIndex, pageSize, filterValues])

	const query = useMemo(() => toQueryString(filters), [filters])
	const URL = useMemo(() => `${apiEndpoints.systemFields}?${query}`, [query])

	const { data, isLoading, mutate } = useQuery(URL)

	const fields = useMemo(
		() =>
			Array.isArray(data?.data?.documentFields)
				? data?.data?.documentFields.map((item, idx) => ({
						index: idx + 1 + pageIndex * pageSize,
						id: item?.id,
						field_name: item?.field_name,
						is_system_field: item?.is_system_field,
						teams: item?.teams || [],
						created_by: item?.created_by || '-',
						created_at: formatDate(item?.created_at),
				  }))
				: [],
		[data?.data?.documentFields],
	)

	return {
		fields,
		meta: data?.data?.meta,
		mutate,
		isLoading,
	}
}

export default systemFieldsListQuery
