import { Field, FormikProvider, useFormik } from 'formik'
import { useAppRouter } from 'hooks'
import { useAssignSubscriptionMutation } from 'modules/subscriptions/user-subscriptions-api'
import { useEffect, useMemo } from 'react'
import { Button, InputField } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { getPayload, validationSchema } from '.'
import { TEAM_APP_ROUTES } from '../routes'
import SubscriptionDropdown from './subscription-dropdown'

const AssignSubscription = ({ team }) => {
	const { navigate } = useAppRouter()
	const [assignSubscription] = useAssignSubscriptionMutation()

	const formik = useFormik({
		initialValues: {
			user_id: team?.teamOwner?.id,
			subscription: null,
			team_id: team?.id,
			plan_price: 0,
			users_allowed: 0,
			status: 'Active',
		},
		validationSchema: validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true)
				const payload = getPayload(values)
				const response = await assignSubscription(payload)
				if (response?.data?.status === 200) {
					notification('success', response?.data?.message)
					navigate(TEAM_APP_ROUTES.findOne(team?.id))
				}
				setSubmitting(false)
			} catch (error) {
				notification('warn', error?.message)
			}
		},
	})

	const formValues = useMemo(() => formik?.values, [formik?.values])

	useEffect(() => {
		const selectedSubscription = formValues?.subscription?.value
		if (selectedSubscription) {
			formik.setFieldValue('plan_price', selectedSubscription?.price)
			formik.setFieldValue('users_allowed', selectedSubscription?.users_allowed)
		}
	}, [formValues?.subscription])

	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				<div className='form-container'>
					<div className='form-row'>
						<div className='form-heading'>
							<h3>Assign Subscription</h3>
							<p>
								Assign subscriptions to teams to grant them access to explore the
								system.
							</p>
						</div>
						<div className='form-fields-card space-y-6'>
							<SubscriptionDropdown />

							{formValues?.subscription ? (
								<>
									<Field
										label='Price'
										name='plan_price'
										type='text'
										isRequiredField
										component={InputField}
									/>

									<Field
										name='users_allowed'
										type='text'
										label='Number of users allowed'
										placeholder='0'
										component={InputField}
										isRequiredField
										helperText='The maximum number of users permitted to join your team.'
									/>
								</>
							) : undefined}
						</div>
					</div>
					<div className='hidden max-w-[200px] sm:!block'>
						<div className='flex items-start space-x-2 !py-5'>
							<i className='mdi mdi-lightbulb-on text-main' />
							<p className='text-xs text-gray-500'>
								Easily manage your feature hassle-free from one convenient
								dashboard.
							</p>
						</div>
					</div>

					<div className='form-container !border-t py-6'>
						<div className='flex justify-end'>
							<Button
								type='submit'
								isLoading={formik.isSubmitting}
								isLoadingText='Assigning...'>
								Assign Now
							</Button>
						</div>
					</div>
				</div>
			</form>
		</FormikProvider>
	)
}

export default AssignSubscription
