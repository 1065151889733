// UI
import { Col, Container, Row } from 'reactstrap'

// Assets
import bg1 from 'assets/images/onboarding/bg2.png'

// State Mng
import { useDispatch } from 'react-redux'
import { SubmissionError } from 'redux-form'
import { savePassword } from 'store/client-onboarding/actions'

// Components
import Wrapper from '../components/Wrapper'
import FormHeader from '../components/header/FormHeader'
import StepForm from './StepForm'

// Utils
import { useAppRouter } from 'hooks'
import { useQuery } from 'utils/commonFunctions'
import { passwordCheck } from 'utils/formValidation'

const StepThree = (props) => {
	const { history } = props
	const { navigate, parseQuery } = useAppRouter()
	const $continue = parseQuery?.continue
	const { token } = useQuery(props.location.search)
	const dispatch = useDispatch()

	const onSubmit = (values) => {
		let isPassWrong = passwordCheck(values.password)

		if (isPassWrong) {
			throw new SubmissionError({
				password: isPassWrong,
			})
		}

		dispatch(savePassword({ password: values?.password })).then((res) => {
			if (res) {
				if (token) {
					navigate(`/onboarding/step-four?token=${token}`)
				} else if ($continue) {
					navigate(`/onboarding/step-four?continue=${$continue}`)
				}
			}
		})
	}

	return (
		<Wrapper>
			<Container className='onboarding-container overflow-hidden' fluid>
				<Row>
					<Col sm={7}>
						<div className='flex-column flex min-h-screen space-y-14'>
							<FormHeader token={token} />
							<StepForm history={history} onSubmit={onSubmit} />
						</div>
					</Col>
					<Col
						sm={5}
						style={{ backgroundImage: 'url(' + bg1 + ')' }}
						className='bg-cover'>
						<div className='px-11 py-28'>
							<h2 className='text-[27px] font-bold'>
								Upload multiple documents with ease
							</h2>
							<p className='text-[#74788D]'>
								No need to wonder what document you might need to upload. When
								workflow is assigned to you, all required document&apos;s list will
								be there for you to completely hassle free with absolutely no rush.
							</p>
						</div>
					</Col>
				</Row>
			</Container>
		</Wrapper>
	)
}

export default StepThree
