import { Fragment, useEffect, useRef, useState } from 'react'

import FilePreviewCard from 'theme/ui/file-preview-card'
import { FileUploadField } from 'theme/ui/forms'

const FileUploadCard = ({ file, fileType, title, onSetFile }) => {
	const [document, setDocument] = useState(null)
	useEffect(() => {
		if (file) {
			setDocument(file)
		}
	}, [file])

	const fileRef = useRef(null)

	const onChange = async ($file) => {
		if ($file) {
			onSetFile({
				file: $file,
				type: fileType,
			})
		}
	}

	return (
		<Fragment>
			{document ? (
				<div>
					<FilePreviewCard
						path={document?.path}
						mime_type={document?.mime_type}
						name={document?.name}
						title={title}
					/>
					<span
						className='float-right mt-1 cursor-pointer text-sm text-main hover:underline'
						onClick={() => setDocument(null)}>
						Re-upload
					</span>
				</div>
			) : (
				<div>
					<FileUploadField
						label={title}
						onUpload={(file) => onChange(file)}
						isMarginBottom={false}
						fileRef={fileRef}
						allowedExtentions='.jpg,.jpeg,.png,.pdf'
					/>
					{file ? (
						<span
							className='float-right -mt-1 cursor-pointer text-sm text-main hover:underline'
							onClick={() => setDocument(file)}>
							Cancel
						</span>
					) : undefined}
				</div>
			)}
		</Fragment>
	)
}

export default FileUploadCard
