import noImage from 'assets/images/no-image.png';
import pdfIcon from 'assets/images/onboarding/pdf-icon.png';

const FileIcon = ({ type, ...props }) => {
    const src = type === 'application/pdf' ? pdfIcon : noImage;
    return (
        <img
            src={src}
            alt={props.alt}
            {...props} />
    );
};

export default FileIcon