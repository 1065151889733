import { Fragment } from "react"

const SoftLoader = () => {
    return (
        <Fragment>
            <div className="soft-notification-container">
                <div className="soft-notification-box">
                    <i className="uil uil-sync" style={{ fontSize: 18 }} />
                    &nbsp;
                    <span>Saving...</span>
                </div>
            </div>
        </Fragment>
    )
}

export default SoftLoader