import { useEffect, useRef, useState } from 'react'

const useSubmitAble = (activeTab, tabs) => {
	const [isSubmitAble, setIsSubmitAble] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const mountedRef = useRef(true)

	const checkIfSubmitable = async (setIsSubmitAble, activeTab, tabs) => {
		setIsLoading(true)
		return new Promise((resolve) => {
			if (Number(activeTab) === tabs.length) {
				setTimeout(() => {
					setIsSubmitAble(true)
					resolve()
					setIsLoading(false)
				}, 1000)
			} else {
				setIsSubmitAble(false)
				resolve()
				setIsLoading(false)
			}
		})
	}

	useEffect(() => {
		checkIfSubmitable(setIsSubmitAble, activeTab, tabs)

		return () => {
			mountedRef.current = false
		}
	}, [activeTab, tabs])

	return {
		isSubmitAble,
		isSubmitAbleChecking: isLoading,
	}
}

export default useSubmitAble
