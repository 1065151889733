/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Switch } from 'react-router-dom'

import './assets/scss/theme.scss'

import ClientOnBoardingLayout from './components/ClientOnBoardingLayout'
import NonAuthLayout from './components/NonAuthLayout'
import { authRoutes, clientOnBoardingRoutes, userRoutes } from './routes/allRoutes'
import Authmiddleware from './routes/middleware/Authmiddleware'

const App = () => {
	return (
		<React.Fragment>
			<Router>
				<Switch>
					{authRoutes.map((route, idx) => (
						<Authmiddleware
							path={route.path}
							layout={NonAuthLayout}
							component={route.component}
							key={idx}
							isAuthProtected={false}
						/>
					))}

					{clientOnBoardingRoutes.map((route, idx) => (
						<Authmiddleware
							path={route.path}
							layout={ClientOnBoardingLayout}
							component={route.component}
							key={idx}
							isAuthProtected={true}
							exact
						/>
					))}

					{userRoutes.map((route, idx) => (
						<Authmiddleware
							path={route.path}
							component={route.component}
							key={idx}
							isAuthProtected={true}
							exact
						/>
					))}
				</Switch>
			</Router>
		</React.Fragment>
	)
}

App.propTypes = {
	layout: PropTypes.any,
}

const mapStateToProps = (state) => {
	return {
		layout: state.Layout,
	}
}

export default connect(mapStateToProps, null)(App)
