import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import FilePreviewPopup from 'theme/ui/file-preview-popup'

const Actions = ({ file }) => {
	const [isOpen, setIsOpen] = useState(false)
	const [url, setUrl] = useState(null)

	const toggle = async (url) => {
		setIsOpen(!isOpen)
		setUrl(url)
	}

	return (
		<Fragment>
			<FilePreviewPopup
				path={url}
				toggle={toggle}
				isOpen={isOpen}
				mime_type={file?.mime_type}
			/>
			<div className='flex space-x-1'>
				<button
					type='button'
					onClick={() => toggle(file?.path)}
					className='action-btn btn-gray-outline'>
					<i className='uil-eye' style={{ fontSize: 16 }} />
					<span>View</span>
				</button>
				<Link to={file?.path} className='action-btn btn-primary-outline' target='_blank'>
					<i className='uil-file-download' style={{ fontSize: 16 }} />
					<span>Download</span>
				</Link>
			</div>
		</Fragment>
	)
}

export default Actions
