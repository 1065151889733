import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data: [],
    pdf_fields: [],
    fileName: '',
    file_path: '',
    id: 0,
    newSystemFields: [],
}

export const FormBuilderSlices = createSlice({
    name: 'formBuilder',
    initialState: initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload
        },
        setPdfFields: (state, action) => {
            state.pdf_fields = action.payload.pdf_fields
            state.fileName = action.payload.fileName
            state.file_path = action.payload.file_path
            state.id = action.payload.id
        },
        setSystemFields: (state, action) => {
            state.newSystemFields = action.payload
        }
    }
})