import { Field, FormikProvider, useFormik } from 'formik'
import { useEffect, useMemo } from 'react'
import { useDebounce } from 'use-debounce'

import { useAppRouter } from 'hooks'
import { resetFilters, useDataTable } from 'theme/ui/data-table'
import { SearchField } from 'theme/ui/forms'
import TableFiltersCard from 'theme/ui/table-filters-card'
import { getPaginationParams } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import { getActiveFilters, getFormikFilterValues, getInitialValues } from './index'

const TeamFilters = () => {
	const { history, location, parseQuery, navigate } = useAppRouter()
	const initialValues = getInitialValues(parseQuery)
	const formik = useFormik({ initialValues: initialValues })
	const tableContext = useDataTable()

	const formValues = useMemo(() => formik?.values, [formik?.values])
	const [debounceValues] = useDebounce(formValues, 800)

	const onResetFilters = async () => {
		resetFilters(navigate).then(() => {
			formik.handleReset()
		})
	}

	useEffect(() => {
		if (debounceValues) {
			const paginationParams = getPaginationParams(location?.search)
			const filterObj = {
				...getFormikFilterValues(debounceValues),
				page: 1,
				limit: paginationParams.limit,
			}
			const queryString = toQueryString(filterObj)
			history.push(`${window.location.pathname}?${queryString}`)
			if (tableContext) {
				tableContext.resetPageIndex()
			}
		}
	}, [debounceValues])

	const activeFilters = getActiveFilters(parseQuery)

	return (
		<FormikProvider value={formik}>
			<form className='mb-3'>
				<div className='flex flex-col space-y-3'>
					{activeFilters.length > 0 ? (
						<TableFiltersCard
							filters={activeFilters}
							formik={formik}
							onResetFilters={onResetFilters}
						/>
					) : undefined}
					<div className='flex justify-between'>
						<div className='min-w-[320px]'>
							<Field
								className='form-control'
								type='search'
								name='search'
								component={SearchField}
								placeholder='Search teams...'
							/>
						</div>
					</div>
				</div>
			</form>
		</FormikProvider>
	)
}

export default TeamFilters
