import { capitalize } from 'lodash'

const FieldsList = ({ fields, title }) => {
	const $fields = fields.filter((field) => {
		// eslint-disable-next-line no-unused-vars
		const [_, values] = field
		const old_value = values?.old
		const new_value = values?.new
		return new_value && old_value
	})

	return (
		<div>
			{$fields.length > 0 ? (
				<>
					{title ? <h5 className='mt-4 prose-headings:h-5'>{title}</h5> : undefined}
					<div className='mb-3 w-full max-w-none rounded bg-white p-3'>
						<div className='mb-3 flex flex-col space-y-2'>
							{$fields.map((field, i) => {
								const [key, values] = field
								const old_value = values?.old
								const new_value = values?.new
								if (new_value && old_value) {
									return (
										<div
											key={i}
											className='flex items-start space-x-3 rounded px-3 py-2'>
											<span className='text-sm'>{i + 1}.</span>
											<div>
												<h6 className='text-sm'>
													{capitalize(key.replaceAll('_', ' '))}:
												</h6>

												<div className='flex items-center space-x-2'>
													<div className='bg-red-100 px-2 py-1 text-[13px]'>
														<s>{old_value || '-'}</s>
													</div>
													<div className='px-2 py-1'>
														<i className='uil-arrow-right text-lg' />
													</div>
													<div className='bg-green-100 px-2 py-1 text-[13px]'>
														<span>{new_value}</span>
													</div>
												</div>
											</div>
										</div>
									)
								} else {
									return undefined
								}
							})}
						</div>
					</div>
				</>
			) : (
				<p className='text-center text-sm text-gray-500'>
					There is no fields required to update in{' '}
					<strong className='text-gray-900'>{title}</strong> section.
				</p>
			)}
		</div>
	)
}

export default FieldsList
