import { createApi } from '@reduxjs/toolkit/query/react'

import axiosBaseQuery from 'store/axios-base-query'

const SIGNATURE_API_URL = (userId) => `users/${userId}/profile/signature`

export const userProfileApi = createApi({
	reducerPath: 'UserProfile',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['UserProfile'],
	endpoints: (builder) => ({
		getUserSignature: builder.query({
			keepUnusedDataFor: 0,
			query: (userId) => ({
				url: SIGNATURE_API_URL(userId),
				method: 'GET',
			}),
			invalidatesTags: ['UserProfile'],
			transformResponse: (response) => {
				return {
					signature: response?.data?.userProfile?.signatureFile,
				}
			},
		}),
		updateUserSignature: builder.mutation({
			query: ({ payload, userId }) => ({
				url: SIGNATURE_API_URL(userId),
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['UserProfile'],
		}),
		deleteUserSignature: builder.mutation({
			query: (userId) => ({
				url: SIGNATURE_API_URL(userId),
				method: 'DELETE',
			}),
			invalidatesTags: ['UserProfile'],
		}),
	}),
})

export const {
	useLazyGetUserSignatureQuery,
	useDeleteUserSignatureMutation,
	useUpdateUserSignatureMutation,
} = userProfileApi
