import classnames from 'classnames'
import { Button, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { reduxForm } from 'redux-form'

import { useFormErrors } from 'hooks'
import { useSelector } from 'react-redux'
import { showErrorToast } from 'utils/commonFunctions'
import { goToNextStep, tabs } from '.'
import ActiveTabContent from './ActiveTabContent'
import useSubmitAble from './useSubmitAble'

const ClientVerifyForm = (props) => {
	const { handleSubmit, client, tabState } = props
	const [activeTab, setActiveTab] = tabState
	const { isSubmitAble, isSubmitAbleChecking } = useSubmitAble(activeTab, tabs)
	const form = useSelector((state) => state?.form?.client_verify)

	const errors = form?.syncErrors || {}
	const values = form?.values
	const isNextAble = Number(activeTab) < tabs.length
	const formError = useFormErrors(errors)

	const onNext = async () => {
		try {
			await goToNextStep(isNextAble, values, formError, activeTab)
			setActiveTab(Number(activeTab) + 1)
		} catch (error) {
			showErrorToast(error?.message)
		}
	}

	const onPrev = () => setActiveTab(Number(activeTab) - 1)

	return (
		<form onSubmit={handleSubmit}>
			<div className='wizard clearfix'>
				<div className='steps clearfix'>
					<ul>
						{tabs.map((tab, i) => (
							<NavItem
								className={classnames({ current: activeTab === tab?.id })}
								key={i}>
								<NavLink className={classnames({ current: activeTab === tab?.id })}>
									<span className='number'>{tab?.id}</span> {tab?.label}
								</NavLink>
							</NavItem>
						))}
					</ul>
				</div>

				<div className='content clearfix p-4'>
					<div className='body'>
						<TabContent activeTab={activeTab}>
							{tabs.map((tab, i) => (
								<TabPane tabId={tab?.id} key={i}>
									<div className='grid grid-cols-5'>
										<div className='col-span-3'>
											<ActiveTabContent tabId={tab?.id} client={client} />
										</div>
									</div>
								</TabPane>
							))}
						</TabContent>
					</div>
				</div>

				<div className='actions clearfix p-4'>
					<div className='mb-0 flex justify-between'>
						<div
							className={
								Number(activeTab) === tabs[0]?.id ? 'previous disabled' : 'previous'
							}>
							{Number(activeTab) > tabs[0]?.id ? (
								<Button
									type='button'
									onClick={onPrev}
									color='primary'
									disabled={activeTab === tabs[0]?.id}>
									Previous
								</Button>
							) : undefined}
						</div>
						<div className={activeTab === tabs.length ? 'next disabled' : 'next'}>
							{isSubmitAble ? (
								<Button type={'submit'} color='success'>
									Save
								</Button>
							) : (
								<Button
									onClick={onNext}
									type={'button'}
									color='primary'
									disabled={isSubmitAbleChecking}>
									Next
								</Button>
							)}
						</div>
					</div>
				</div>
			</div>
		</form>
	)
}

const ClientVerifyFormRedux = reduxForm({
	form: 'client_verify',
	enableReinitialize: true,
})(ClientVerifyForm)

export default ClientVerifyFormRedux
