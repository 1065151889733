import { useDroppable } from '@dnd-kit/core'
import { snakeCase } from 'lodash'
import React from 'react'

import addIcon from 'assets/images/svg-icons/add-icon.svg'
import Task from './task'

const Column = ({ column, tasks, isLast, accepts }) => {
	const { setNodeRef } = useDroppable({
		id: column?.id,
		data: {
			type: snakeCase(column?.title),
			accepts: accepts,
			status: column?.title,
		},
	})

	return (
		<React.Fragment>
			<div>
				<div className='flex items-center justify-between py-[10px]'>
					<div className='flex items-center space-x-3'>
						<h2 className='m-0 text-sm font-bold uppercase'>{column.title}</h2>
						<span className='tag tag-secondary bg-gray-200 !font-bold'>
							{column?.cards?.length}
						</span>
					</div>
					{!isLast ? (
						<button>
							<img src={addIcon} />
						</button>
					) : undefined}
				</div>

				<div className='flex h-full flex-col space-y-3' ref={setNodeRef}>
					{tasks.map((task, idx) => (
						<Task key={idx} index={idx} task={task} />
					))}
				</div>
			</div>
		</React.Fragment>
	)
}

export default Column
