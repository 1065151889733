import classNames from 'classnames'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'

const DocumentTabs = () => {
	const tabs = useMemo(
		() => [
			{
				label: 'Documents',
				href: '/documents',
				permission: isAllowTo(permissions?.documentTemplate?.list),
			},
			{
				label: <i className='uil-trash-alt' style={{ fontSize: 16 }} />,
				href: '/deleted-document-templates',
				permission: isAllowTo(permissions?.documentTemplate?.deletedList),
			},
		],
		[],
	)
	return (
		<div className='flex space-x-1 '>
			<div className='flex divide-x overflow-hidden rounded border-[1px] !border-gray-300'>
				{tabs
					.filter((item) => item?.permission)
					?.map((tab, index) => {
						return (
							<Link to={tab.href} key={index} className='tabs_alignmentt'>
								<div
									className={classNames(
										'tabs_alignmentt px-3 py-2.5 text-sm font-medium leading-5 transition-colors',
										tab?.href == location?.pathname
											? ' bg-white text-main'
											: 'text-gray-500 hover:bg-gray-100 hover:text-main',
									)}>
									{tab?.label}
								</div>
							</Link>
						)
					})}
			</div>
		</div>
	)
}

export default DocumentTabs
