import { useDeleteClientDetailsMutation } from 'modules/clients/client-apis'
import { Fragment, useState } from 'react'
import { Spinner } from 'reactstrap'
import Swal from 'sweetalert2'
import { showSuccessToast } from 'utils/commonFunctions'

const DeleteClientDetails = ({ client }) => {
	const [isDeleting, setIsDeleting] = useState(false)
	const [deleteClientDetails] = useDeleteClientDetailsMutation()

	const handleDelete = async (id) => {
		await Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!',
		}).then(async (result) => {
			if (result?.isConfirmed) {
				setIsDeleting(true)
				const response = await deleteClientDetails(id)
				setIsDeleting(false)
				if (response?.data?.status === 200) {
					showSuccessToast(response?.data?.message)
				}
			}
		})
	}

	return (
		<label
			className='mb-0 flex w-full cursor-pointer select-none gap-2 rounded border-gray-200 px-[14px] py-2.5 text-gray-500 transition-colors duration-200 ease-in-out hover:bg-main/10 peer-checked/reviewer:border-gray-200  peer-checked/reviewer:bg-main/20 peer-checked/reviewer:text-main'
			onClick={() => handleDelete(client?.id)}
			disabled={isDeleting}>
			{isDeleting ? (
				<Fragment>
					<Spinner size='sm' />
					&nbsp;&nbsp;Deleting...
				</Fragment>
			) : (
				<Fragment>
					<i className='uil uil-trash text-danger' />
					<span>Delete client data</span>
				</Fragment>
			)}
		</label>
	)
}

export default DeleteClientDetails
