import { Dialog, Transition } from '@headlessui/react'
import userIcon from 'assets/svg/user-plus-icon.svg'
import { useOnboardClientsMutation } from 'modules/clients/invited-client-api'
import { ClientWorkflowAssignForm } from 'modules/workflows/workflow-template'
import { Fragment, useState } from 'react'
import notification from 'utilities/notification'

const OnboardPopup = ({ isOpen, onClose, selectedRows }) => {
	const [isChecked, setIsChecked] = useState(false)
	const [selectedIndex, setSelectedIndex] = useState(0)
	const [onboardClient] = useOnboardClientsMutation()
	const [isLoading, setIsLoading] = useState(false)

	const onOnboard = async () => {
		try {
			setIsLoading(true)
			const payload = {
				clients: selectedRows,
				isEmailSend: true,
			}
			const response = await onboardClient(payload)
			setIsLoading(false)
			if (response?.data?.status === 200) {
				onClose()
				notification('info', response?.data?.message)
			}
		} catch (error) {
			setIsLoading(false)
		}
	}

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-[1008]' onClose={() => {}}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/30' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'>
							<Dialog.Panel
								className={`w-full ${
									isChecked ? 'max-w-3xl' : 'max-w-xl'
								} transform overflow-hidden rounded-3xl bg-white p-2 text-left align-middle shadow-xl transition-all`}>
								<div className='top-o relative flex justify-between rounded-3xl bg-blue-50 p-3'>
									<div></div>
									<div className='absolute  left-6 top-6 rounded-full !border !border-gray-300  bg-white p-3 '>
										<img src={userIcon} alt='' className='' />
									</div>
									<button type='button' className='right-0' onClick={onClose}>
										<i className='fas fa-times rounded-full bg-white px-2 py-1 text-[20px]' />
									</button>
								</div>
								<div className='mt-10 flex flex-col '>
									<div className='ms-4 flex flex-col justify-start ps-2'>
										<Dialog.Title
											as='h2'
											className='mb-3 text-left text-2xl font-bold leading-6 text-gray-900'>
											Onboarding Clients
										</Dialog.Title>
										<div>
											<p className='m-0 p-0 text-lg font-medium'>
												You have chosen to onboard{' '}
												<span className='text-blue-400'>
													{selectedRows?.length} clients
												</span>
											</p>
											<p className='m-0 p-0 text-xs'>
												<span className='text-red-700'>*</span>This will
												apply only on clients with &quot;Submitted&quot;
												status
											</p>
										</div>
									</div>
									<hr className='mx-4 my-3 bg-gray-300' />
									<div className='px-3'>
										<div className='justify-left mb-3 flex items-center gap-1 ps-3 '>
											<input
												checked={isChecked}
												type='checkbox'
												name='is_workflow_assign'
												id='is_workflow_assign'
												className='form-check-input m-0 !border !border-black'
												onChange={(e) => setIsChecked(e.target.checked)}
											/>
											&nbsp;
											<label
												htmlFor='is_workflow_assign'
												className='m-0 text-sm font-medium text-[#495057]'>
												Would you like to assign workflow to those clients?
											</label>
										</div>

										{isChecked ? (
											<ClientWorkflowAssignForm
												setSelectedIndex={setSelectedIndex}
												selectedIndex={selectedIndex}
												initialValues={{}}
												onClose={onClose}
												selectedClients={selectedRows}
											/>
										) : undefined}
									</div>

									{!isChecked ? (
										<div className='my-2 ms-3 flex w-full flex-col gap-2 pe-5 ps-2'>
											<button
												type='button'
												className='rounded-md !border border-main bg-main p-2 font-medium text-white'
												size='sm'
												onClick={onOnboard}>
												{isLoading ? 'Processing...' : 'Onboard Now'}
											</button>
											<button
												type='button'
												size='sm'
												className='rounded-md bg-white p-2 font-medium text-black'
												onClick={onClose}>
												Cancel
											</button>
										</div>
									) : undefined}
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export default OnboardPopup
