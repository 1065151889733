/* eslint-disable no-prototype-builtins */

import notification from 'utilities/notification'

export function mergeObjects(oldObj, newObj) {
	const result = {}

	// Merge oldObj into result
	for (const key in oldObj) {
		if (oldObj.hasOwnProperty(key)) {
			result[key] = { old: oldObj[key] }
		}
	}

	// Merge newObj into result, handling duplicate keys
	for (const key in newObj) {
		if (newObj.hasOwnProperty(key)) {
			if (result.hasOwnProperty(key)) {
				// If the key already exists in result, push the value into an array
				result[key] = { ...result[key], new: newObj[key] }
			} else {
				// If the key doesn't exist in result, simply add it
				result[key] = { new: newObj[key] }
			}
		}
	}

	return result
}

export function mergeArrayOfObjects(arr) {
	const result = {}

	arr.forEach((obj) => {
		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				if (result.hasOwnProperty(key)) {
					// If the key already exists in result, push the value into an array
					if (Array.isArray(result[key])) {
						result[key].push(obj[key])
					} else {
						result[key] = [result[key], obj[key]]
					}
				} else {
					// If the key doesn't exist in result, simply add it
					result[key] = obj[key]
				}
			}
		}
	})

	return result
}

export const getDefaultFields = (user) => {
	return {
		first_name: user?.first_name,
		last_name: user?.last_name,
		email: user?.email,
		phone_number: user?.phone_number,
	}
}

export const getSettingsFields = (fields) => {
	const settings_fields = Array.isArray(fields) ? fields : []

	const arrayToObject = settings_fields.map((setting) => ({
		[setting?.user_key]: setting?.user_value,
	}))

	return mergeArrayOfObjects(arrayToObject)
}

export const getSanitizedFields = (fields) => {
	return (
		Object.entries(fields).filter((field) => {
			// eslint-disable-next-line no-unused-vars
			const [_, values] = field
			const old_value = values?.old
			const new_value = values?.new
			return old_value !== new_value
		}) || []
	)
}

export const transformChangedFieldsResponse = (response) => {
	try {
		const fields = {
			existing: response?.data?.existing_profile,
			updated: response?.data?.updation_profile?.existingClient,
		}
		const default_fields = {
			existing: getDefaultFields(fields.existing),
			updated: getDefaultFields(fields.updated),
		}
		return {
			default_fields: mergeObjects(default_fields.existing, default_fields.updated),
			address_fields: mergeObjects(fields.existing?.userProfile, fields.updated?.userProfile),
			settings_fields: mergeObjects(
				getSettingsFields(fields.existing?.settings),
				getSettingsFields(fields.updated?.settings),
			),
		}
	} catch (error) {
		notification('warn', 'Something went wrong while fetching changed fields')
		return {}
	}
}

export { default as ProfileInformationQuery } from './ProfileInformationQuery'
export { default as FieldsComparison } from './fields-comparison'
