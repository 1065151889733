import { useState } from 'react'
import Message from 'theme/ui/message'

const Announcements = ({ announcement }) => {
	const redirect = (link) => {
		window.location.href = link
	}
	const [showMessage, setShowMessage] = useState(true)

	return (
		<div>
			{showMessage && (
				<Message
					variant={announcement?.alert_class}
					onClose={() => setShowMessage(false)}
					isDismissible={announcement?.is_dismissible}>
					<i className={`uil ${announcement?.icon_class} me-2`}></i>
					<span className='font-bold'>{announcement?.title}</span>
					<p
						className='ms-4 mt-2'
						dangerouslySetInnerHTML={{ __html: announcement?.content }}
					/>
					{announcement?.hyperlink && (
						<button
							onClick={() => redirect(announcement?.hyperlink)}
							className=' mx-4 mb-2 me-2 rounded-lg !border !border-gray-400 bg-white px-3 py-1  font-medium text-gray-900 hover:bg-gray-100'>
							Click here
						</button>
					)}
				</Message>
			)}
		</div>
	)
}

export default Announcements
