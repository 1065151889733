export const getDocumentOptions = (documents) => {
    if (Array.isArray(documents)) {
        return documents.map((item) => ({ label: item?.title, value: item?.id }))
    } else {
        return []
    }
}

export const getInitialSystemFields = (documentMapping, pdf_fields) => {
    if (Array.isArray(documentMapping) && Array.isArray(pdf_fields)) {
        return pdf_fields.map((field, idx) => {
            let Obj = {}
            if (documentMapping[idx]) {
                Object.assign(Obj, {
                    ...documentMapping[idx],
                    pdf_field: field?.label
                })
            } else {
                Object.assign(Obj, { pdf_field: field?.label })
            }
            return Obj
        })
    } else {
        return []
    }
}