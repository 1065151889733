/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Webcam from 'react-webcam'

import selfiePlaceholder from 'assets/images/onboarding/selfie-placeholder.svg'
import apiEndpoints from 'helpers/apiEndpoints'
import { store } from 'store/common/actions'
import { mutate } from 'swr'
import { showSuccessToast } from 'utils/commonFunctions'

const videoConstraints = {
	width: 400,
	height: 400,
	facingMode: 'user',
}

const Form = ({ token }) => {
	const [src, setSrc] = useState(null)
	const [isWebCamActive, setIsWebCamActive] = useState(false)
	const webCamRef = useRef(null)
	const [isLoading, setIsLoading] = useState(false)
	const dispatch = useDispatch()

	const onTakePicture = () => {
		setSrc(null)
		setIsWebCamActive(true)
	}

	const onCapture = useCallback(() => {
		const selfie = webCamRef?.current?.getScreenshot()
		if (selfie) {
			setSrc(selfie)
			setIsWebCamActive(false)
		}
	})

	const onSave = async () => {
		setIsLoading(true)
		const blob = await fetch(src)
			.then((response) => response.blob())
			.then((blob) => blob)
		const file = new File([blob], 'selfie.jpg', { type: 'image/jpg' })

		const payload = new FormData()
		payload.append('token', token)
		payload.append('file', file)
		dispatch(store(apiEndpoints.userOnBoard.selfie.upload, payload)).then((response) => {
			setIsLoading(false)
			if (response?.status === 200) {
				showSuccessToast(response?.message)
				window.location.href = '/onboarding/thank-you'
			} else {
				mutate(`${apiEndpoints.userOnBoard.selfie.qrCodeToken.validate}?token=${token}`)
			}
		})
	}

	return (
		<Fragment>
			<div className='mx-auto flex max-w-max flex-col space-y-4 text-center'>
				<div className='!flex h-44 w-44 overflow-hidden rounded-full !border border-gray-500'>
					{isWebCamActive ? (
						<Webcam
							imageSmoothing={true}
							mirrored={true}
							screenshotFormat='image/jpeg'
							audio={false}
							height={400}
							width={400}
							videoConstraints={videoConstraints}
							ref={webCamRef}
						/>
					) : (
						<img
							src={src || selfiePlaceholder}
							alt=''
							className='w-full object-cover'
						/>
					)}
				</div>

				<div className='flex items-center justify-center'>
					{isWebCamActive ? (
						<button
							onClick={onCapture}
							className='btn-primary-2 mx-auto flex items-center justify-center space-x-1 !py-1 px-4'
							htmlFor='icon-button-file'>
							<i className='uil uil-camera text-2xl' />
							<span className='font-semibold'>Capture</span>
						</button>
					) : (
						<button
							onClick={onTakePicture}
							className='btn-primary-2 mx-auto flex items-center justify-center space-x-1 !py-1 px-4'
							htmlFor='icon-button-file'>
							<i className='uil uil-camera text-2xl' />
							<span className='font-semibold'>
								{src ? 'Retake' : 'Take a picture'}
							</span>
						</button>
					)}

					{src ? (
						<button
							className='btn btn-primary py-1.5'
							onClick={() => {
								setSrc(null)
								setIsWebCamActive(false)
							}}>
							<i className='uil uil-times text-base' />
						</button>
					) : undefined}
				</div>
			</div>

			<div className='mx-auto'>
				<div className='h-6' />
				<button
					type='button'
					className='btn-primary-1'
					onClick={onSave}
					disabled={!src || isLoading}>
					{isLoading ? 'Saving...' : 'Save'}
				</button>
			</div>
		</Fragment>
	)
}

export default withRouter(Form)
