import { Tab } from '@headlessui/react'

import { useAppRouter } from 'hooks'
import { DocumentViewerProvider } from 'pages/Authentication/profile/components/workflow/document-viewer-provider'
import FormBuilderTab from './form-builder-tab'
import SignatureTab from './signature-tab'
import UploadDocument from './upload-document'
import FieldMapping from './upload-document/field-mapping'

const TabsContent = ({ formik, document, onAutoSave, isAutoSaving }) => {
	const { params } = useAppRouter()
	const documentId = document?.id || params?.id

	return (
		<Tab.Panels className='px-[75px]'>
			<Tab.Panel>
				<UploadDocument formik={formik} document={document} onAutoSave={onAutoSave} />
			</Tab.Panel>
			<Tab.Panel>
				<DocumentViewerProvider>
					<FieldMapping
						isAutoSaving={isAutoSaving}
						document={document}
						formik={formik}
						onAutoSave={onAutoSave}
					/>
				</DocumentViewerProvider>
			</Tab.Panel>

			<Tab.Panel>
				<FormBuilderTab formik={formik} document={document} />
			</Tab.Panel>
			<Tab.Panel>
				<SignatureTab formik={formik} documentId={documentId} />
			</Tab.Panel>
		</Tab.Panels>
	)
}

export default TabsContent
