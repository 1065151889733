import { Fragment, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { snakeCase } from 'lodash'
import { useSelector } from 'react-redux'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import { getDocumentUrl } from '.'
import { useLazyGetCompletedDocumentQuery } from '../document-api'
import CompletedDocumentsTable from './completed-documents-table'
import DocumentPreviewPopup from './document-preview'
import Filters from './filters'

const CompletedDocumentsContainer = () => {
	const location = useLocation()
	const history = useHistory()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination

	useEffect(() => {
		if (pagination) {
			const parseQueryString = getParseQueryString(location?.search)
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			history.push(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	const [isOpen, setIsOpen] = useState(false)
	const [document, setDocument] = useState({
		fileUrl: null,
		id: 0,
		workflow_step_document_id: 0,
	})
	const toggle = async (document) => {
		setIsOpen(!isOpen)
		setDocument(document)
	}

	useEffect(() => {
		if (pagination) {
			const parseQueryString = getParseQueryString(location?.search)
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			history.push(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	const { defaultGroup } = useSelector((state) => state.Permissions)
	const group = useMemo(() => snakeCase(defaultGroup?.group?.name), [defaultGroup])
	const stringUrl = useMemo(() => getDocumentUrl(group), [group])

	const [fetchCompletedDocument, { data, isFetching: isLoading }] =
		useLazyGetCompletedDocumentQuery()

	useEffect(() => {
		if (location?.search) {
			fetchCompletedDocument({
				params: getParseQueryString(location?.search),
				url: stringUrl,
			})
		}
	}, [location?.search])

	const completedDocument = useMemo(() => {
		return Array.isArray(data?.data)
			? data?.data?.map((document) => {
					return {
						...document,
						// workflow_title: getWorkflowTitle(document, group),
					}
			  })
			: []
	}, [data?.data])

	return (
		<Fragment>
			<Filters setPagination={setPagination} />
			<CompletedDocumentsTable
				documents={completedDocument}
				meta={data?.meta}
				isLoading={isLoading}
				pagination={{
					pageSize: limit,
					pageIndex: page - 1,
				}}
				toggle={toggle}
				setPagination={setPagination}
			/>
			<DocumentPreviewPopup $document={document} toggle={toggle} isOpen={isOpen} />
		</Fragment>
	)
}

export default CompletedDocumentsContainer
