import classNames from 'classnames'
import { routes } from 'modules/workflows'

import { Link } from 'react-router-dom'
import { toSnakeCase } from 'utils/commonFunctions'

const Actions = ({ workflow }) => {
	const isReviewAble = !['assigned', 'completed', 'cancelled', 'waiting_for_signatures'].includes(
		toSnakeCase(workflow?.status),
	)

	return (
		<div className='flex space-x-1'>
			{isReviewAble ? (
				<div
					className={classNames({
						'cursor-not-allowed': !isReviewAble,
					})}>
					<Link
						className={classNames('action-btn  btn-gray', {
							'pointer-events-none !bg-gray-500 opacity-50': !isReviewAble,
						})}
						to={{
							pathname: isReviewAble ? routes.finalReviewView(workflow?.id) : '#',
						}}>
						Review
					</Link>
				</div>
			) : undefined}
		</div>
	)
}

export default Actions
