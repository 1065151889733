import React from 'react'
import { Link } from 'react-router-dom'
import {
	Card,
	CardBody,
	Col,
	Container,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Row,
	UncontrolledDropdown,
} from 'reactstrap'

//Import Breadcrumb

//Import Components
import LatestTransaction from './latest-transaction'
import MiniWidget from './mini-widget'
import RecentActivity from './recent-activity'
import SalesAnalyticsChart from './salesanalytics-chart'
import SocialSource from './socialsource'
import TopProduct from './topselling-product'

//Import Image
import setupanalytics from '../../../assets/images/setup-analytics-amico.svg'

const series1 = [
	{
		data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54],
	},
]

const options1 = {
	fill: {
		colors: ['#5b73e8'],
	},
	chart: {
		width: 70,
		sparkline: {
			enabled: !0,
		},
	},
	plotOptions: {
		bar: {
			columnWidth: '50%',
		},
	},
	labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
	xaxis: {
		crosshairs: {
			width: 1,
		},
	},
	tooltip: {
		fixed: {
			enabled: !1,
		},
		x: {
			show: !1,
		},
		y: {
			title: {
				formatter: function (seriesName) {
					return ''
				},
			},
		},
		marker: {
			show: !1,
		},
	},
}

const series2 = [70]

const options2 = {
	fill: {
		colors: ['#34c38f'],
	},
	chart: {
		sparkline: {
			enabled: !0,
		},
	},
	dataLabels: {
		enabled: !1,
	},
	plotOptions: {
		radialBar: {
			hollow: {
				margin: 0,
				size: '60%',
			},
			track: {
				margin: 0,
			},
			dataLabels: {
				show: !1,
			},
		},
	},
}

const series3 = [55]

const options3 = {
	fill: {
		colors: ['#5b73e8'],
	},
	chart: {
		sparkline: {
			enabled: !0,
		},
	},
	dataLabels: {
		enabled: !1,
	},
	plotOptions: {
		radialBar: {
			hollow: {
				margin: 0,
				size: '60%',
			},
			track: {
				margin: 0,
			},
			dataLabels: {
				show: !1,
			},
		},
	},
}

const series4 = [
	{
		data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54],
	},
]

const options4 = {
	fill: {
		colors: ['#f1b44c'],
	},
	chart: {
		width: 70,
		sparkline: {
			enabled: !0,
		},
	},
	plotOptions: {
		bar: {
			columnWidth: '50%',
		},
	},
	labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
	xaxis: {
		crosshairs: {
			width: 1,
		},
	},
	tooltip: {
		fixed: {
			enabled: !1,
		},
		x: {
			show: !1,
		},
		y: {
			title: {
				formatter: function (seriesName) {
					return ''
				},
			},
		},
		marker: {
			show: !1,
		},
	},
}

const Home = () => {
	const reports = [
		{
			id: 1,
			icon: 'mdi mdi-arrow-up-bold',
			title: 'Total Value',
			value: 34152,
			prefix: '$',
			suffix: '',
			badgeValue: '2.65%',
			decimal: 0,
			charttype: 'bar',
			chartheight: 40,
			chartwidth: 70,
			color: 'success',
			desc: 'since last week',
			series: series1,
			options: options1,
		},
		{
			id: 2,
			icon: '',
			title: 'Year to date return',
			value: 12.58,
			decimal: 2,
			prefix: '+',
			suffix: '%',
			charttype: 'bar',
			chartheight: 40,
			chartwidth: 70,
			badgeValue: '+ 107,892',
			color: 'success',
			desc: '',
			series: series4,
			options: options4,
		},
		{
			id: 3,
			icon: '',
			title: 'Monthly change',
			value: 4.58,
			decimal: 2,
			prefix: '+',
			suffix: '%',
			charttype: 'bar',
			chartheight: 40,
			chartwidth: 70,
			badgeValue: '+ 107,892',
			color: 'success',
			desc: '',
			series: series4,
			options: options4,
		},
	]

	return (
		<React.Fragment>
			<div className='page-content pt-0'>
				<div className='blur-overlay'></div>
				<Container fluid>
					<Row>
						<MiniWidget reports={reports} />
						<Col md={6} xl={3}>
							<Card>
								<CardBody>
									<div className='flex flex-col gap-6'>
										<h5 className='mt-1'>Select Account</h5>
										<div className='mt-2 flex w-fit !border !border-gray-300 px-2'>
											<UncontrolledDropdown>
												<DropdownToggle
													tag='a'
													className='text-reset'
													id='dropdownMenuButton5'
													caret>
													<span className='text-muted'>
														All Account
														<i className='mdi mdi-chevron-down ms-1'></i>
													</span>
												</DropdownToggle>
												<DropdownMenu className='dropdown-menu-end'>
													<DropdownItem href='#'>Advisor</DropdownItem>
													<DropdownItem href='#'>Owner</DropdownItem>
													<DropdownItem href='#'>Client</DropdownItem>
												</DropdownMenu>
											</UncontrolledDropdown>
										</div>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>

					<Row>
						<Col xl={8}>
							<SalesAnalyticsChart />
						</Col>
						<Col xl={4}>
							<Card className='bg-white'>
								<CardBody>
									<Row className='align-items-center'>
										<Col sm={8}>
											<p className='font-size-14 font-bold'>Quick Actions</p>
											<div className='mt-4 flex flex-col'>
												<Link
													to='#'
													className='waves-effect waves-light underline'>
													<i className='mdi mdi-arrow-right'></i>
													Open a new Account
												</Link>
												<Link
													to='#'
													className='waves-effect waves-light underline'>
													<i className='mdi mdi-arrow-right'></i>
													Add a new fund
												</Link>
												<Link
													to='#'
													className='waves-effect waves-light underline'>
													<i className='mdi mdi-arrow-right'></i>
													Save as PDF
												</Link>
												<Link
													to='#'
													className='waves-effect waves-light underline'>
													<i className='mdi mdi-arrow-right'></i>
													Refer a friend
												</Link>
											</div>
										</Col>
										<Col sm={4}>
											<div className='mt-sm-0 mt-4'>
												<img
													src={setupanalytics}
													className='img-fluid'
													alt=''
												/>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
							<TopProduct />
						</Col>
					</Row>
					<Row>
						<Col xl={4}>
							{/* <TopUser /> */}
							<Card>
								<CardBody>
									<h5>Notes from your Team</h5>
									<p className='font-medium'>
										Stock Market Commentary for Q2 2024
									</p>
									<p className='font-medium'>
										In Q2 2024, the U.S. stock market experienced modest
										volatility but ended the quarter with overall positive
										gains. The tech sector continued to lead, benefiting from
										Al- driven initiatives inflationary impacts on consume and
										robust consumer demand. However, investors were cautious,
										with the Federal inflationary impacts on consume a hawkish
										stance on inflation, indicating potential rate hikes in the
										latter half of the year. Earnings reports were mixed, with
										big banks showing pressure due to continued inflationary
										impacts on consumer spending. Defensive sectors like
										healthcare and utilities.
									</p>
								</CardBody>
							</Card>
						</Col>
						<Col xl={4}>
							<RecentActivity />
						</Col>
						<Col xl={4}>
							<SocialSource />
						</Col>
					</Row>
					<LatestTransaction />
				</Container>
			</div>
		</React.Fragment>
	)
}

export default Home
