import { Fragment, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { SpinLoader } from 'components/Common'
import {
	useLazyGetChangedFieldsQuery,
	useUpdateChangedFieldsMutation,
} from 'modules/authentication/auth-api'
import { FieldsComparison } from 'modules/authentication/profile-update'
import ResourceNotFound from 'pages/StaticPages/ResourceNotFound'
import AppLayout from 'theme/layouts/app-layout'
import notification from 'utilities/notification'

const ProfileUpdate = ({ match }) => {
	const history = useHistory()
	const token = match?.params?.token

	const [updateChangedFields, { isLoading: isUpdating }] = useUpdateChangedFieldsMutation()

	const [fetchChangedFields, { data, isLoading }] = useLazyGetChangedFieldsQuery()
	useEffect(() => {
		if (token) {
			fetchChangedFields(token)
		}
	}, [token])

	const onUpdate = async (token) => {
		try {
			const response = await updateChangedFields({ token })
			if (response?.data?.status === 200) {
				notification('success', response?.data?.message)
				history.push('/')
			}
		} catch (error) {
			notification('warn', error?.message)
		}
	}

	return (
		<AppLayout
			meta={{
				title: 'Profile update',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<div className='page-header bg-white'>
						<div className='flex flex-col'>
							<Link
								to='/profile'
								className='mb-2 flex max-w-fit items-center text-base font-normal not-italic leading-5'>
								<i className='uil-angle-left text-3xl' />
								<span>Back</span>
							</Link>
							<h4 className='page-title'>Profile Update</h4>
							<p className='text-xs text-[#74788D]'>
								We are pleased to provide you with a detailed comparison of various
								field values within your profile data. The following analysis allows
								you to review and assess any differences or changes in your profile
								information. This side-by-side comparison ensures transparency and
								helps you maintain accurate and up-to-date details. If you have any
								questions or need to make adjustments, please navigate to the
								profile update page where you can modify the relevant information.
								Your commitment to ensuring the accuracy of your profile data is
								greatly appreciated.
							</p>
						</div>
					</div>
					<div className='page-body'>
						{isLoading ? (
							<SpinLoader />
						) : (
							<Fragment>
								{data ? (
									<FieldsComparison
										default_fields={data?.default_fields}
										address_fields={data?.address_fields}
										settings_fields={data?.settings_fields}
										isUpdating={isUpdating}
										onUpdate={() => onUpdate(token)}
									/>
								) : (
									<ResourceNotFound
										title='Profile data not found'
										description='We regret to inform you that your profile data was not found. However, we appreciate your proactive approach in wanting to update it.
'
									/>
								)}
							</Fragment>
						)}
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default ProfileUpdate
