import { createApi } from '@reduxjs/toolkit/query/react'
import apiEndpoints from 'helpers/apiEndpoints'

import axiosBaseQuery from 'store/axios-base-query'
import { formatDate } from 'utilities/helpers'
import { transformData } from './payent-details'

const API_URL = apiEndpoints.payments

export const paymentsApi = createApi({
	reducerPath: 'Payments',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['Payments'],
	endpoints: (builder) => ({
		getPayments: builder.query({
			keepUnusedDataFor: 0,
			query: (params) => ({
				url: API_URL,
				method: 'GET',
				params: params,
			}),
			providesTags: ['Payments'],
			transformResponse: (response, _, arg) => {
				const payments = Array.isArray(response?.data?.payments)
					? response?.data?.payments.map((payment, idx) => ({
							...payment,
							index: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + idx + 1,
							id: payment?.id,
							payment_date: formatDate(payment?.payment_date),
					  }))
					: []
				return {
					payments,
					meta: response?.data?.meta,
				}
			},
		}),
		deletePayment: builder.mutation({
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Payments'],
		}),
		updatePayment: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${API_URL}/${id}`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['Payment'],
		}),
		getPayment: builder.query({
			keepUnusedDataFor: 0,
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'GET',
			}),
			providesTags: ['Payments'],
			transformResponse: (response) => {
				const payment = transformData(response?.data)
				return {
					payment,
				}
			},
		}),
		getPaymentEdit: builder.query({
			keepUnusedDataFor: 0,
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'GET',
			}),
			providesTags: ['Payments'],
			transformResponse: (response) => {
				const payment = response?.data
				return {
					payment,
				}
			},
		}),
	}),
})

export const {
	useLazyGetPaymentsQuery,
	useLazyGetPaymentQuery,
	useLazyGetPaymentEditQuery,
	useDeletePaymentMutation,
	useUpdatePaymentMutation,
} = paymentsApi
