import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import { useMemo } from 'react'

import { ReactComponent as GreenCheck } from 'assets/svg/green-check.svg'
import { ReactComponent as WarningIcon } from 'assets/svg/warning-icon.svg'
import { useAppRouter } from 'hooks'
import ProgressBar from 'theme/ui/progress-bar'
import { WorkflowStatuses } from 'utilities/helpers'
import { getTabClass } from '.'

const TabsList = ({ tabs, workflow }) => {
	const { parseQuery } = useAppRouter()

	const activeTab = useMemo(() => Number(parseQuery?.step) - 1, [parseQuery?.step])
	const progress = useMemo(
		() => (Number(parseQuery?.step) / tabs.length) * 100,
		[parseQuery?.step, tabs],
	)

	return (
		<div className='w-[184px]'>
			<h3 className='text-base font-medium'>
				Step {parseQuery?.step}
				<span className='text-xs'>/{tabs.length}</span>
			</h3>
			<ProgressBar value={progress} variant='primary' className='!my-5 w-[91px]' />
			<Tab.List className='flex flex-col items-start space-y-4'>
				{tabs.map((tab, idx) => {
					return (
						<Tab
							key={idx}
							className={classNames(
								'flex cursor-default items-center space-x-2 text-sm',
								getTabClass(activeTab, tab, workflow?.status),
							)}>
							<span>{tab.title}</span>

							{tab?.isRejected ? (
								<span>
									<WarningIcon />
								</span>
							) : activeTab > tab?.id ||
							  (activeTab >= tab?.id &&
									[
										WorkflowStatuses?.IN_REVIEW,
										WorkflowStatuses?.IN_FINAL_REVIEW,
									].includes(workflow?.status)) ? (
								<span>
									<GreenCheck />
								</span>
							) : undefined}
						</Tab>
					)
				})}
			</Tab.List>
		</div>
	)
}

export default TabsList
