import React from 'react'

const StyledBadge = ({ children, color }) => {
	const colors = {
		primary: {
			color: 'text-primary',
			bg: '!bg-[#F2F5FF]',
		},
		success: {
			color: 'text-success',
			bg: '!bg-[#34c38f10]',
		},
		danger: {
			color: 'text-danger',
			bg: '!bg-[#F46A6A0F]',
		},
		warning: {
			color: 'text-warning',
			bg: '!bg-[#F1B44C0F]',
		},
		default: {
			color: 'text-black',
			bg: '!bg-[#F9F9F9]',
		},
	}

	const classes = colors[color] || colors['default']

	return (
		<div className={`${classes.bg} w-auto rounded p-2 py-1`}>
			<span className={`${classes.color}`}>{children}</span>
		</div>
	)
}

export default StyledBadge
