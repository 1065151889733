/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import { showErrorToast } from '../../utils/commonFunctions'

const initialState = {
	error: null,
	loading: false,
	code: null,
	data: [],
}

export const CommonSlices = createSlice({
	name: 'common',
	initialState: initialState,
	reducers: {
		startCall: (state, action) => {
			state.error = null
			state.loading = true
			state.data = []
		},

		endCall: (state, action) => {
			state.error = null
			state.loading = false
		},

		catchError: (state, action) => {
			state.error = action.payload.message
			state.loading = false
			state.data = []
			showErrorToast(action.payload.message)
		},

		search: (state, action) => {
			state.error = null
			state.loading = false
			state.status = action.payload.status
		},

		all: (state, action) => {
			state.data = action.payload.data
		},

		startPermissionCall: (state, action) => {
			state.error = null
			state.loading = false
			state.data = []
		},
	},
})

export const { endCall, catchError } = CommonSlices.actions
