const CustomCheckBox = (props) => {
	const { label, input, id } = props

	return (
		<div>
			<input
				{...input}
				type='checkbox'
				className='btn-check'
				id={id}
				value={input.value}
				onChange={(value) => input.onChange(value)}
				autoComplete='off'
			/>
			<label className='btn btn-secondary btn-sm m-0 min-w-max' htmlFor={id}>
				{input.checked ? (
					<>
						<i className='uil-times' />
						&nbsp;
					</>
				) : undefined}

				<span>{label}</span>
			</label>
		</div>
	)
}

export default CustomCheckBox
