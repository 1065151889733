import { createColumnHelper } from '@tanstack/react-table'
import { useState } from 'react'

import { ReactComponent as DocumentIcon } from 'assets/svg/document-icon.svg'
import { snakeCase } from 'lodash'
import DataTable, { IndeterminateCheckbox } from 'theme/ui/data-table'
import ToolTip from 'theme/ui/tool-tip'
import Actions from './actions'
import FoldersColumn from './folders-column'

const DocumentsTable = ({
	pagination,
	setPagination,
	data,
	meta,
	rowSelection,
	setRowSelection,
	isLoading,
}) => {
	const [sorting, setSorting] = useState([])
	const [actionActiveId, setActionActiveId] = useState(null)
	const onMouseEnter = (id) => setActionActiveId(id)
	const onMouseLeave = () => setActionActiveId(null)
	const columnHelper = createColumnHelper()

	const columns = [
		{
			id: 'select',
			size: 30,
			header: ({ table }) => (
				<IndeterminateCheckbox
					{...{
						checked: table.getIsAllRowsSelected(),
						indeterminate: table.getIsSomeRowsSelected(),
						onChange: table.getToggleAllRowsSelectedHandler(),
					}}
				/>
			),
			cell: ({ row }) => (
				<div>
					<IndeterminateCheckbox
						{...{
							checked: row.getIsSelected(),
							disabled: !row.getCanSelect(),
							indeterminate: row.getIsSomeSelected(),
							onChange: row.getToggleSelectedHandler(),
						}}
					/>
				</div>
			),
		},
		columnHelper.accessor((row) => row, {
			id: 'title',
			size: 220,
			header: () => <span>Title</span>,
			cell: (info) => (
				<div className='flex items-center'>
					<span>
						<DocumentIcon height={20} />
					</span>
					<span
						id={`${snakeCase(info.getValue().title)}_title`}
						className='my-1 ml-2 line-clamp-1 text-sm font-medium'>
						{info.getValue().title}
					</span>
					<ToolTip
						targetId={`${snakeCase(info.getValue().title)}_title`}
						tooltipText={info.getValue().title}
					/>
				</div>
			),
		}),

		columnHelper.accessor((row) => row, {
			id: 'folders',
			size: 165,
			header: () => <span>Folder</span>,
			cell: (info) => (
				<FoldersColumn
					key={info?.row?.id}
					folders={info.getValue().folders}
					document={info.getValue()}
				/>
			),
		}),

		columnHelper.accessor((row) => row.last_edit, {
			id: 'last_edit',
			size: 170,
			header: () => <span>Last Edited</span>,
			cell: (info) => <span className='text-[13px]'>{info.getValue()}</span>,
		}),

		columnHelper.accessor((row) => row, {
			id: 'actions',
			header: () => <span className='float-end'>Actions</span>,
			cell: (info) => (
				<Actions
					document={info.getValue()}
					isActionActive={actionActiveId === info?.row?.id}
				/>
			),
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={data}
			isLoading={isLoading}
			setSorting={setSorting}
			pagination={pagination}
			setPagination={setPagination}
			sorting={sorting}
			meta={meta}
			variant='secondary'
			setRowSelection={setRowSelection}
			rowSelection={rowSelection}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		/>
	)
}

export default DocumentsTable
