export const fieldTabs = [
	{
		id: 0,
		label: 'Profile Fields',
	},
	{
		id: 1,
		label: 'Document Bound Fields',
	},
]

export const getTabs = (isDocumentBounds) => {
	return fieldTabs.filter((tab) => (isDocumentBounds ? tab?.id <= 1 : tab?.id < 1))
}

export { default as Header } from './header'
export { default as WorkflowReviewCard } from './workflow-review-card'
