import { Field, FieldArray } from 'formik'
import { Fragment, useMemo } from 'react'

import { Button } from 'theme/ui/forms'
import SystemFieldDropdown from '../system-field-dropdown'
import UserDropdown from '../user-dropdown'
import DocumentBoundField from './document-bound-field'

const DeletedPdfFields = ({ formik, onAutoSave }) => {
	const formValues = useMemo(() => formik?.values, [formik?.values])
	const numberOfUsers = formValues?.min_clients?.value

	const userOptions = useMemo(() => {
		const users = [
			{ label: 'Advisor', value: 'advisor' },
			{ label: 'Client', value: 'client' },
		]
		if (numberOfUsers > 1) {
			users.push(
				...new Array(numberOfUsers - 1).fill(0).map((_, idx) => ({
					label: `Co Applicant ${idx + 1}`,
					value: `applicant_${idx + 1}`,
				})),
			)
		}
		return users
	}, [numberOfUsers])

	const onRestore = (field) => {
		return new Promise((resolve) => {
			const document_mapping = formValues?.document_mapping
			formik.setFieldValue('document_mapping', [...document_mapping, field])
			resolve({ message: 'field successfully restored' })
		})
	}

	return (
		<div className='h-[100vh] rounded'>
			<div className='slim-scroll !max-h-[92%] overflow-auto rounded-b bg-white !px-3 !py-3'>
				<FieldArray
					name='delete_document_mapping'
					render={(arrayHelpers) => {
						return (
							<div className='flex flex-col divide-y'>
								{formValues?.delete_document_mapping.length > 0 ? (
									<Fragment>
										{formValues?.delete_document_mapping.map((field, idx) => {
											return (
												<div
													key={idx}
													className='rounded !px-2 !py-4 transition-all hover:bg-slate-50'>
													<div className='!mb-2 flex items-start justify-between space-x-2'>
														<SystemFieldDropdown
															label={field?.pdf_field}
															name={`${arrayHelpers.name}[${idx}].field`}
															onAutoSave={onAutoSave}
														/>
														<Field
															type='hidden'
															name={`${arrayHelpers.name}[${idx}].pdf_field`}
															value={field?.pdf_field}
															component='input'
														/>
														<UserDropdown
															name={`${arrayHelpers.name}[${idx}].user`}
															options={userOptions}
															onAutoSave={onAutoSave}
														/>
														<div className='!pt-[33px]'>
															<Button
																type='button'
																size='xs'
																variant='danger-outline'
																onClick={async () => {
																	onRestore(field)
																	arrayHelpers.remove(idx)
																}}>
																<i className='uil-upload' />
															</Button>
														</div>
													</div>

													<DocumentBoundField
														fieldName={`${arrayHelpers.name}[${idx}].is_document_bound`}
													/>
												</div>
											)
										})}
									</Fragment>
								) : (
									<span className='text-center text-sm text-gray-500'>
										The trash is now empty.
									</span>
								)}
							</div>
						)
					}}
				/>
			</div>
		</div>
	)
}

export default DeletedPdfFields
