import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import Tab3 from "./Tab3";
import Tab4 from "./Tab4";

const ActiveTabContent = ({ tabId, client }) => {

    switch (tabId) {
        case 1:
            return (
                <Tab1 client={client} />
            )

        case 2:
            return (
                <Tab2 client={client} />
            )

        case 3:
            return (
                <Tab3 client={client} />
            )

        case 4:
            return (
                <Tab4 client={client} />
            )

        default:
            return (
                <Tab1 client={client} />
            )
    }
}

export default ActiveTabContent