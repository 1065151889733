/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios'
import UserQuery from 'hooks/UserQuery'
import { Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { SpinLoader } from 'components/Common'
import apiEndpoints from 'helpers/apiEndpoints'
import { useUpdateProfileMutation } from 'modules/authentication/auth-api'
import * as authActions from 'store/jwtauth/actions'
import notification from 'utilities/notification'
import { showErrorToast, showSuccessToast } from 'utils/commonFunctions'
import { preparePayload } from '.'
import DocumentSection from './document-section'
import ProfileForm from './profile-form'
import SignatureForm from './signature-form'

const ProfileManage = () => {
	const dispatch = useDispatch()
	const { user_id } = useSelector((state) => state.Permissions)
	const [fileId, setFileId] = useState(null)
	const [isUpdating, setUpdating] = useState(false)
	const [upLoader, setUpLoader] = useState(false)
	const avatarRef = useRef(null)
	const { user: Profile, isLoading, refetch } = UserQuery()
	const [updateProfile] = useUpdateProfileMutation()
	const [isUpload, setIsUpload] = useState(false)
	const [isUploaded, setIsUploaded] = useState(false)
	const [resetHeuristicKey, setResetHeuristicKey] = useState(false)
	const resetForm = () => setResetHeuristicKey(!resetHeuristicKey)

	const handleUpload = (file) => {
		const formData = new FormData()
		formData.append('file', file)
		setIsUploaded(false)
		if (file) {
			setUpLoader(true)
			axios
				.post(apiEndpoints.file, formData)
				.then((res) => {
					setUpLoader(false)
					const response = res?.data
					if (response?.status === 200) {
						showSuccessToast(`${response?.message} Now you can save the profile.`)
						setFileId(response?.data?.fileID)
						setIsUploaded(true)
					}
				})
				.catch((error) => {
					setIsUploaded(false)
					setUpLoader(false)
					showErrorToast(
						error?.response?.data?.message ||
							'Something went wrong while uploading the profile picture. Please try again',
					)
					if (avatarRef.current) {
						avatarRef.current.value = null
					}
				})
		}
	}

	const onAvatarSave = async () => {
		try {
			setUpdating(true)

			const payload = {
				email: Profile?.email,
				first_name: Profile?.first_name,
				last_name: Profile?.last_name,
				phone_number: Profile?.phone_number,
			}
			if (fileId) {
				Object.assign(payload, { avatar: { id: fileId } })
			}

			const response = await updateProfile({ payload, id: user_id })
			if (response?.data?.status === 200 && response?.data?.data) {
				notification('success', response?.data?.message)
				dispatch(authActions.saveUserData(response?.data?.data))
				if (avatarRef.current) {
					avatarRef.current.value = null
				}
			}
			setIsUpload(false)
			setIsUploaded(false)
			setUpdating(false)
		} catch (error) {
			setUpdating(false)
			notification('error', error?.message)
		}
	}

	const handleUpdate = async (values) => {
		try {
			setUpdating(true)
			if (upLoader) {
				throw new Error('Please wait profile picture is uploading...')
			}
			const payload = preparePayload(values)
			if (fileId) {
				Object.assign(payload, { avatar: { id: fileId } })
			}
			const response = await updateProfile({ payload, id: user_id })
			if (response?.data?.status === 200 && response?.data?.data) {
				notification('success', response?.data?.message)
				dispatch(authActions.saveUserData(response?.data?.data))
				if (avatarRef.current) {
					avatarRef.current.value = null
				}
			}
			setIsUpload(false)
			setIsUploaded(false)
			setUpdating(false)
		} catch (error) {
			setUpdating(false)
			notification('error', error?.message)
		}
	}

	useEffect(() => {
		if (!isLoading && window.location.hash === '#signatures') {
			const section = document.querySelector('#signatures')
			section.scrollIntoView({ behavior: 'smooth', block: 'start' })
		}
	}, [isLoading])

	return (
		<Fragment>
			{isLoading ? (
				<SpinLoader />
			) : (
				<Fragment>
					<ProfileForm
						initialValues={Profile}
						loading={isUpdating}
						handleUpload={handleUpload}
						avatarRef={avatarRef}
						upLoader={upLoader}
						isUploaded={isUploaded}
						setIsUpload={setIsUpload}
						isUpload={isUpload}
						user={Profile}
						onAvatarSave={onAvatarSave}
						onSubmit={(values) => handleUpdate(values)}
					/>
					<div className='my-6 border-t border-gray-200' />
					<DocumentSection
						key={resetHeuristicKey}
						user={Profile}
						resetForm={resetForm}
						refetch={refetch}
					/>

					<SignatureForm />
				</Fragment>
			)}
		</Fragment>
	)
}

export default ProfileManage
