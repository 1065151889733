import { Switch } from '@headlessui/react'

const SwitchField = ({ input }) => {
	return (
		<Switch
			checked={input?.value}
			onChange={(checked) => {
				if (checked) {
					input.onChange(1)
				} else {
					input.onChange(0)
				}
			}}
			className={`${input?.value ? 'bg-main' : 'bg-gray-500'}
          relative inline-flex h-5 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}>
			<span className='sr-only'>Use setting</span>
			<span
				aria-hidden='true'
				className={`${input?.value ? 'translate-x-7' : 'translate-x-0'}
            pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white ring-0 transition duration-200 ease-in-out`}
			/>
		</Switch>
	)
}

export default SwitchField
