import PropTypes from 'prop-types'
import { Fragment } from 'react'
import OTPInput from 'react-otp-input'
import { FormGroup, Label } from 'reactstrap'

const OtpFieldComponent = (props) => {
	const { label, input, otpLength } = props

	return (
		<Fragment>
			<FormGroup>
				{label ? <Label className='text-left'>{label}</Label> : undefined}
				<OTPInput
					inputType='tel'
					name={input.name}
					value={input.value}
					onChange={input.onChange}
					numInputs={otpLength}
					renderSeparator={<span className='w-3'></span>}
					renderInput={(props) => (
						<input
							{...props}
							className='border-1 rounded-md border-gray-200 px-2 text-center'
							style={{
								width: 38,
								height: 38,
							}}
							required
						/>
					)}
				/>
			</FormGroup>
		</Fragment>
	)
}

OtpFieldComponent.propTypes = {
	input: PropTypes.object.isRequired,
	meta: PropTypes.object,
}

OtpFieldComponent.defaultProps = {
	meta: null,
}

export default OtpFieldComponent
