import { useEffect, useMemo, useState } from 'react'

import apiEndpoints from 'helpers/apiEndpoints'
import { useAppRouter } from 'hooks'
import { useLazyGetDocumentPackagesQuery } from 'modules/documents/document-package-api'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import ViewByDropdown from '../viewBy-filter'
import AllFoldersTable from './all-folders-table'

const AllFoldersListingContainer = () => {
	const [rowSelection, setRowSelection] = useState({})
	const selectedRows = useMemo(() => Object.keys(rowSelection), [rowSelection])
	const { location, navigate } = useAppRouter()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination

	const [fetchDocumentPackages, { data, isFetching: isLoading }] =
		useLazyGetDocumentPackagesQuery()

	useEffect(() => {
		if (pagination) {
			const parseQueryString = getParseQueryString(location?.search)
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			navigate(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	useEffect(() => {
		const stringUrl = apiEndpoints.documentPackage
		if (location?.search) {
			fetchDocumentPackages({
				params: getParseQueryString(location?.search),
				url: stringUrl,
			})
		}
	}, [location?.search])
	return (
		<div className='divide-y'>
			<div className='flex items-center justify-between pb-3'>
				<h3 className='m-0 text-base'>All Folders</h3>
				{/* <button type='button' className='cursor-pointer text-main hover:!underline'>
					<i>+</i>
					<span className='ml-1'>New Folder</span>
				</button> */}
				<ViewByDropdown />
			</div>

			<div className='pt-4'>
				<AllFoldersTable
					data={data?.data}
					pagination={pagination}
					meta={data?.meta}
					setPagination={setPagination}
					isLoading={isLoading}
				/>
			</div>
		</div>
	)
}

export default AllFoldersListingContainer
