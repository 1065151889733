import { TextField } from 'components/Forms/TextFieldComponent'
import { Field, reduxForm } from 'redux-form'

const FilterForm = () => {
	return (
		<form>
			<div className='max-w-[250px]'>
				<Field
					type='search'
					name='search'
					component={TextField}
					placeholder='Search for files...'
				/>
			</div>
		</form>
	)
}

export default reduxForm({
	form: 'workflows_files_filters',
})(FilterForm)
