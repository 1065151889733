import React, { useCallback, useEffect, useState } from 'react'
import { validatePasswordWithMessages } from 'utils/formValidation'

const PasswordValidationError = ({ type, value }) => {
	const [erorrs, setErorrs] = useState([])

	const validationError = useCallback(
		(value) => (type === 'password' ? validatePasswordWithMessages(value) : null),
		[type],
	)

	useEffect(() => {
		if (type === 'password') {
			const errors = validationError(value).filter((error) => error)
			setErorrs(errors)
		}
	}, [value])

	return (
		<React.Fragment>
			<div className='mt-1'>
				<ul className='p-0'>
					{erorrs.map((erorr, idx) => (
						<li className='text-[#f46a6a] text-sm' key={idx}>
							{erorr}
						</li>
					))}
				</ul>
			</div>
		</React.Fragment>
	)
}

export default PasswordValidationError
