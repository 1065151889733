const WorkflowTable = ({ workflows }) => {
	return (
		<div className='relative overflow-x-auto !border sm:rounded-lg'>
			<table className='m-0 w-full text-left text-sm text-gray-500 rtl:text-right '>
				<thead className='text-xs uppercase text-gray-700 '>
					<tr className='border-b border-gray-200'>
						<th scope='col' className='px-6 py-3'>
							#
						</th>
						<th scope='col' className='bg-gray-50 px-6 py-3 '>
							Workflow Name
						</th>
						<th scope='col' className='px-6 py-3'>
							Status
						</th>
					</tr>
				</thead>
				<tbody>
					{workflows.map((workflow, idx) => (
						<tr key={idx} className='border-b border-gray-200'>
							<td className='!px-6 !py-4'>{idx + 1}</td>
							<th
								scope='row'
								className='whitespace-nowrap bg-gray-50 !px-6 !py-4 font-medium text-gray-900'>
								{workflow?.workflowTitle}
							</th>
							<td className='!px-6 !py-4'>{workflow?.workflowStatus}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}

export default WorkflowTable
