import classNames from 'classnames'
import { Field } from 'formik'
import { useMemo, useState } from 'react'
import Select from 'react-select'

import { formatToTwoDigits } from 'utilities/helpers'
import { createOptionLabel } from '.'
import DocumentBadge from './document-badge'
import DocumentsQuery from './documents-query'

const MultiDocumentsDropdown = ({ formik }) => {
	const [search, setSearch] = useState('')
	const selectedDocs = useMemo(
		() => (Array.isArray(formik.values.documents) ? formik.values.documents : []),
		[formik.values.documents],
	)
	const { documents, isLoading } = DocumentsQuery(search, selectedDocs)
	const filterOption = ({ data }, search) => {
		if (data?.title?.toLowerCase().includes(search.toLowerCase())) {
			return true
		} else {
			return false
		}
	}
	const onChange = (fieldName, option) => {
		const updatedDocs = [...selectedDocs, option]
		formik.setFieldValue(fieldName, updatedDocs)
	}

	const onRemove = (fieldName, index) => {
		if (index < 0 || index >= selectedDocs.length) {
			return false
		}
		const updatedDocs = selectedDocs.slice(0, index).concat(selectedDocs.slice(index + 1))
		formik.setFieldValue(fieldName, updatedDocs)
	}

	const onRemoveAll = (fieldName) => {
		formik.setFieldValue(fieldName, [])
	}

	return (
		<Field name='documents'>
			{({ field }) => (
				<div className='flex-col space-y-[8px]'>
					<div className='flex items-center justify-between'>
						<label
							className={classNames(
								'mb-0 flex items-baseline justify-between space-x-2 text-[14px] font-bold text-[#495057]',
							)}>
							<div className='flex space-x-2'>
								<div>Documents </div>
								{selectedDocs.length > 0 ? (
									<span>({formatToTwoDigits(selectedDocs.length)})</span>
								) : undefined}
								<span>
									<i className='bx bxs-info-circle mb-0 mt-1 text-sm text-gray-300' />
								</span>
							</div>
						</label>

						{selectedDocs.length > 0 ? (
							<span
								className='cursor-pointer text-sm  text-red-500 hover:underline'
								onClick={() => onRemoveAll(field?.name)}>
								Clear All
							</span>
						) : undefined}
					</div>
					<Select
						value={null}
						options={documents}
						onInputChange={(value) => setSearch(value)}
						onChange={(option) => onChange(field?.name, option)}
						isSearchable={true}
						isLoading={isLoading}
						classNamePrefix={'select-dropdown'}
						className={classNames('select-dropdown-container')}
						placeholder='For eg:- Invoices Copy, Tax Invoices'
						getOptionLabel={(option) => createOptionLabel(option)}
						filterOption={filterOption}
						styles={{
							menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
							menu: (provided) => ({ ...provided, zIndex: 9999 }),
						}}
						menuPortalTarget={document.body}
						menuPosition={'fixed'}
					/>
					<div className='mb-2 flex flex-wrap gap-1'>
						{selectedDocs.map((doc, idx) => (
							<DocumentBadge
								key={idx}
								title={doc?.title}
								onRemove={() => onRemove(field.name, idx)}
							/>
						))}
					</div>
				</div>
			)}
		</Field>
	)
}

export default MultiDocumentsDropdown
