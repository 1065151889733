import { Field } from 'formik'
import { useMemo, useState } from 'react'
import { SelectField } from 'theme/ui/forms'
import { getTeamOptions } from '.'
import TeamsQuery from '../teams-query'

const TeamDropdownField = ({ isDisabled, isRequiredField }) => {
	const [search, setSearch] = useState('')
	const onInputChange = (value) => setSearch(value)
	const { teams, isLoading: isTeamsLoading } = TeamsQuery(search)
	const teamOptions = useMemo(() => getTeamOptions(teams), [teams, isTeamsLoading])

	return (
		<Field
			type='select'
			label='Select Team'
			name='team_ids'
			id='team_ids'
			options={teamOptions}
			isMulti
			isLoading={isTeamsLoading}
			onInputChange={onInputChange}
			isSearchable={true}
			component={SelectField}
			isDisabled={isDisabled}
			isRequiredField={isRequiredField}
		/>
	)
}

export default TeamDropdownField
