import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import Form from './Form'

const DocumentPackageModal = ({ open, onClose, initialValues, id }) => {
	return (
		<Fragment>
			<Transition appear show={open} as={Fragment}>
				<Dialog as='div' className='relative z-[1008]' onClose={onClose}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'>
						<div className='fixed inset-0 bg-black/30' />
					</Transition.Child>

					<div className='fixed inset-0 overflow-y-auto'>
						<div className='flex min-h-full items-center justify-center p-4 text-center'>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 scale-95'
								enterTo='opacity-100 scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 scale-100'
								leaveTo='opacity-0 scale-95'>
								<Dialog.Panel className='relative w-full max-w-xl transform overflow-hidden rounded-md bg-white text-left align-middle shadow-xl transition-all'>
									<Dialog.Title>
										<div className='bg-[#F2F5FF] p-4 py-3 '>
											<h5 className='mb-0 font-bold'>Add Document</h5>
										</div>
										<button
											type='button'
											className='absolute right-4 top-2'
											onClick={onClose}>
											<i className='uil-times text-xl' />
										</button>
									</Dialog.Title>
									<div className='mb-3 px-6 py-2'>
										<Form
											onClose={onClose}
											id={id}
											initialValues={initialValues}
										/>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</Fragment>
	)
}

export default DocumentPackageModal
