import arrowIcon from 'assets/images/svg-icons/arrow-right-icon.svg'
import { Field, FormikProvider, useFormik } from 'formik'
import apiEndpoints from 'helpers/apiEndpoints'
import { useUser } from 'hooks'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Offcanvas, OffcanvasBody } from 'reactstrap'
import { reduxForm } from 'redux-form'
import { store, update } from 'store/common/actions'
import { Button, InputField, TextareaField } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { preparePayload, validationSchema } from '.'
import AssigneeDropdown from './assignee-dropdown'
import ClientDropdown from './client-dropdown'
import CopyLinkBtn from './copy-link-btn'
import DatePicker from './date-picker/DatePicker'
import NotificationButton from './notification-button'
import StatusDropdown from './status-dropdown'
import TagDropdown from './tag-dropdown'
import WatcherDropdown from './watcher-dropdown'
import WorkflowDropdown from './workflow-dropdown'

const TaskForm = ({
	id,
	reset,
	mutate,
	refetch,
	onClose,
	canvasState,
	toggleCanvas,
	initialValues,
}) => {
	const [isOpen] = canvasState
	const { team } = useUser()
	const dispatch = useDispatch()
	// const [isUploading, setIsUploading] = useState(false)
	const [attachments, setAttachments] = useState([])

	useEffect(() => {
		if (Array.isArray(initialValues?.attachments)) {
			setAttachments(initialValues?.attachments)
		}
	}, [initialValues?.attachments])

	const formik = useFormik({
		initialValues: initialValues,
		enableReinitialize: true,
		validationSchema: validationSchema,
		onSubmit: async (values, { setSubmitting, resetForm }) => {
			setSubmitting(true)
			const payload = preparePayload({
				...values,
				team_id: team?.id,
				attachment_ids: attachments.map((attachment) => attachment?.id),
			})
			try {
				const action = id
					? update(apiEndpoints.task, id, payload)
					: store(apiEndpoints.task, payload)

				const response = await dispatch(action)

				setSubmitting(false)
				if (response?.status === 200) {
					notification('success', response?.message)
					onClose()
					mutate()
					reset()
					refetch()
					resetForm()
					setAttachments([])
				}
			} catch (error) {
				setSubmitting(false)
				notification('warn', error)
			}
		},
	})
	const formValues = useMemo(() => formik?.values, [formik?.values])

	// const onUpload = (e) => {
	// 	setIsUploading(true)
	// 	const file = e.target.files[0]

	// 	const payload = new FormData()
	// 	payload.append('file', file)
	// 	dispatch(store(apiEndpoints.file, payload)).then((response) => {
	// 		setIsUploading(false)
	// 		if (response?.status === 200) {
	// 			Object.assign(file, { path: response?.data?.path })
	// 			attachments.push({ file, id: response?.data?.fileID })
	// 			setAttachments([...attachments])
	// 			showSuccessToast('File Uploaded successfully')
	// 		}
	// 	})
	// }
	// const onRemove = (index, id) => {
	// 	dispatch(destroy(apiEndpoints.file, id)).then((response) => {
	// 		if (response?.affected === 1) {
	// 			attachments.splice(index, 1)
	// 			setAttachments([...attachments])
	// 		}
	// 	})
	// }

	return (
		<React.Fragment>
			<Offcanvas
				backdrop={true}
				scrollable
				isOpen={isOpen}
				onClosed={onClose}
				toggle={toggleCanvas}
				direction='end'
				className='!w-5/12 overflow-auto'>
				<FormikProvider value={formik}>
					<form onSubmit={formik.handleSubmit}>
						<div className='offcanvas-header border-b !px-8 !pt-[36px]'>
							<div className='flex w-full justify-between'>
								<div>
									<Field
										label='Task name'
										type='text'
										name='task_title'
										component={InputField}
										placeholder='Enter task name'
										isRequiredField
									/>
									<StatusDropdown formik={formik} />
								</div>
								<div className='space-x-1'>
									<NotificationButton formik={formik} />
									{id ? <CopyLinkBtn link={window.location.href} /> : undefined}
									<button
										type='button'
										className='btn !bg-[#F9F9F9] !p-1.5'
										onClick={onClose}>
										<img src={arrowIcon} />
									</button>
								</div>
							</div>
						</div>
						<OffcanvasBody className='min-h-screen !px-8'>
							<div className='flex justify-between'>
								<div
									className='flex flex-col gap-2'
									style={{
										minWidth: 'calc(100% - 200px)',
									}}>
									<AssigneeDropdown />

									<WatcherDropdown />

									<ClientDropdown />

									{formValues?.client_ids?.length > 0 && (
										<WorkflowDropdown formValues={formValues} />
									)}

									<DatePicker />

									<TagDropdown formValues={formValues} />
								</div>

								{/* <div className='flex flex-col items-end space-y-2 text-right md:col-span-2'>
									<span className='text-xs font-medium not-italic leading-4 text-[#74788D]'>
										Additional Fields
									</span>
									<label
										type='button'
										htmlFor='attachment'
										className='w-[86px] rounded bg-[#ebedf0] py-1 text-center text-xs font-normal not-italic text-[#495057]'>
										{isUploading ? 'uploading...' : 'Attachment'}
									</label>
									<input
										type='file'
										id='attachment'
										className='hidden'
										onChange={onUpload}
										disabled={isUploading}
									/>
								</div> */}
							</div>

							{/* <Attachments attachments={attachments} onRemove={onRemove} /> */}
							<div className='my-2'>
								<Field
									label='Description'
									name='description'
									type='textarea'
									component={TextareaField}
								/>
							</div>
							<div className='flex justify-end border-t py-3'>
								<Button
									type='submit'
									isLoading={formik.isSubmitting}
									isLoadingText='Saving...'>
									{formik.isSubmitting
										? 'Creating...'
										: id
										? 'Update Task'
										: 'Create Task'}
								</Button>
							</div>
						</OffcanvasBody>
					</form>
				</FormikProvider>
			</Offcanvas>
		</React.Fragment>
	)
}

export default reduxForm({
	form: 'task_form',
	enableReinitialize: true,
})(TaskForm)
