import { TabsProvider } from 'contexts/tabs-context'
import DocumentTemplateForm from 'modules/documents/document-template-form'
import { SignatureDocumentProvider } from 'modules/documents/document-template-form/signature-document-context'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const CreateDocumentTemplate = () => {
	return (
		<AppLayout
			meta={{
				title: 'Create Document Template',
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<PageHeader
						title='Create Document Template'
						description='When assigning documents/packages here, do not ignore to fill in all the required fields completely and follow the document/package adding rules.'
						hasBackButton={true}
					/>
					<SignatureDocumentProvider>
						<div>
							<TabsProvider>
								<DocumentTemplateForm />
							</TabsProvider>
						</div>
					</SignatureDocumentProvider>
				</div>
			</div>
		</AppLayout>
	)
}

export default CreateDocumentTemplate
