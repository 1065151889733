import { array, boolean, object, string } from 'yup'
import SystemFieldForm from './system-field-form'

export const validationSchema = object({
	field_name: string().required('Please enter a field name'),
	is_system_field: boolean().required('Please check or uncheck this field'),
	team_ids: array()
		.typeError('Please select at least one team')
		.of(
			object({
				label: string(),
				value: string(),
			}),
		)
		.when('is_system_field', {
			is: false,
			then: (schema) => schema.min(1,'Please select at least 1 team').required('Please select a team'),
			otherwise: (schema) => schema.min(0),
		}),
})

export const createTeamOption = (team) => ({
	// label: (
	// 	<div className='flex flex-col space-y-1'>
	// 		<span className='font-semibold'>{team?.team_name}</span>
	// 		<span className='text-xs text-gray-500'>
	// 			({team?.teamOwner?.first_name} {team?.teamOwner?.last_name})
	// 		</span>
	// 	</div>
	// ),
	label: team?.team_name,
	value: team?.id,
})

export const getTeamOptions = (teams) => {
	try {
		if (Array.isArray(teams)) {
			return teams.map((team) => createTeamOption(team))
		} else {
			return []
		}
	} catch (error) {
		return []
	}
}

export const getInitialValues = (field) => {
	const team_ids = Array.isArray(field?.teams)
		? field?.teams.map((team) => createTeamOption(team))
		: []

	return {
		field_name: field?.field_name || '',
		team_ids,
		is_system_field: field?.is_system_field || false,
	}
}

export const getPayload = (values) => {
	const team_ids = Array.isArray(values?.team_ids)
		? values?.team_ids.map((team_id) => team_id?.value)
		: []

	const documentFields = Object.assign(
		{},
		{ ...values, team_ids: values?.is_system_field ? [] : team_ids },
	)
	return { documentFields }
}

export { default as SystemFieldQuery } from './system-field-query'

export default SystemFieldForm
