import { Field, FormikProvider, useFormik } from 'formik'
import { useMemo } from 'react'

import { useAppRouter } from 'hooks'
import { Button, InputField } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { getPayload, getUserTypeOptions, validationSchema } from '.'
import {
	useCreateWorkflowTemplateMutation,
	useUpdateWorkflowTemplateMutation,
} from '../workflow-template-apis'
import AssignDocuments from './assign-documents'
import DocumentUploadSection from './document-upload-section'

const WorkflowForm = ({ initialValues, id }) => {
	const { navigate, goBack } = useAppRouter()
	const [createWorkflowTemplate] = useCreateWorkflowTemplateMutation()
	const [updateWorkflowTemplate] = useUpdateWorkflowTemplateMutation()

	const formik = useFormik({
		initialValues: {
			title: initialValues?.title || '',
			documents: initialValues?.documents || [],
			document_packages: initialValues?.document_packages || [],
			files: initialValues?.files || [],
			steps: initialValues?.steps || [],
			submitType: '',
		},
		enableReinitialize: true,
		validationSchema: validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true)
				const payload = getPayload(values)
				let response = null
				if (initialValues && id) {
					response = await updateWorkflowTemplate({ payload, id })
				} else {
					response = await createWorkflowTemplate(payload)
				}
				if (response?.data?.status === 200) {
					if (values?.submitType === 'save') {
						goBack()
						notification('success', response?.data?.message)
					} else if (values?.submitType === 'save_assign') {
						navigate(`/workflows/assign?workflow=${response?.data?.data?.id}`)
						notification('success', 'Workflow created successfully, Now you can assign')
					}
				}

				setSubmitting(false)
			} catch (error) {
				setSubmitting(false)
			}
		},
	})

	const formValues = useMemo(() => formik.values, [formik.values])
	const userTypeOptions = useMemo(() => getUserTypeOptions(formValues), [formValues])

	// const users = [
	// 	{ value: 'user1', label: 'User 1' },
	// 	{ value: 'user2', label: 'User 2' },
	// ]

	// const roles = [
	// 	{ value: 'admin', label: 'Admin' },
	// 	{ value: 'editor', label: 'Editor' },
	// ]

	// const groupedOptions = [
	// 	{
	// 		label: 'Users',
	// 		options: users.map((o) => ({ ...o, type: 'user' })),
	// 	},
	// 	{
	// 		label: 'Roles',
	// 		options: roles.map((o) => ({ ...o, type: 'roles' })),
	// 	},
	// ]

	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				<div className='flex w-full gap-10'>
					<div className='form-container mx-auto'>
						<div className='my-form-fields-card mx-auto space-y-6'>
							<Field
								label='Title'
								type='text'
								name='title'
								component={InputField}
								isRequiredField={true}
								labelHelperText={true}
								placeholder='For eg:- Alchemytech, Webosphere'
							/>

							<AssignDocuments formik={formik} />

							{/* <Field
								label='Document Packages'
								type='select'
								name='user_roles'
								options={groupedOptions}
								isMulti={true}
								component={SelectField}
								isHelperIcon={true}
								placeholder='For eg:- Co. Onboarding process 101'
							/> */}

							<DocumentUploadSection
								userTypeOptions={userTypeOptions}
								formValues={formValues}
							/>
						</div>

						<div className='my-form-fields-card mx-auto mt-3 flex min-w-[440px] justify-end space-x-3 !py-5'>
							<Button
								type='submit'
								onClick={() => formik.setFieldValue('submitType', 'save')}
								variant='primary-outline'
								isLoading={formik.isSubmitting}
								isLoadingText='Saving...'>
								Save
							</Button>
							<Button
								type='submit'
								onClick={() => formik.setFieldValue('submitType', 'save_assign')}
								isLoading={formik.isSubmitting}
								isLoadingText='Processing...'>
								Save and Assign
							</Button>
						</div>
					</div>
				</div>
			</form>
		</FormikProvider>
	)
}

export default WorkflowForm
