import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import { useMemo } from 'react'

import { getTabs } from '.'

const TabsHeader = () => {
	const $tabs = useMemo(() => getTabs(false), [])
	return (
		<Tab.List className='flex divide-x !border-b'>
			{$tabs.map((tab, idx) => (
				<Tab
					key={idx}
					className={({ selected }) =>
						classNames(
							'rounded-t !border !border-b-0 px-3 py-2 text-sm hover:bg-white',
							selected ? 'border-slate-200 bg-white' : 'border-transparent',
						)
					}>
					{tab?.label}
				</Tab>
			))}
		</Tab.List>
	)
}

export default TabsHeader
