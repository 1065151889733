import Header from 'modules/notification-templates/header'
import { NotificationsTemplatesListingContainer } from 'modules/notification-templates/notifications-templates-listing'
import AppLayout from 'theme/layouts/app-layout'

const NotificationsTemplatesListing = () => {
	return (
		<AppLayout
			meta={{
				title: 'Notifications Templates list',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<div className='page-header bg-white'>
						<Header />
					</div>

					<div className='page-body'>
						<NotificationsTemplatesListingContainer />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default NotificationsTemplatesListing
