/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, Fragment, useEffect, useImperativeHandle } from 'react'
import { useGlobalFilter, usePagination, useTable } from 'react-table'
import { Spinner } from 'reactstrap'
import GlobalTableFilter from './GlobalTableFilter'

const Table = forwardRef((props, ref) => {
	const {
		columns,
		data,
		fetchData,
		loading,
		pageCount: controlledPageCount,
		total,
		setPaginationState,
	} = props

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize, globalFilter },
		setGlobalFilter,
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0 }, // Pass our hoisted table state
			manualPagination: true, // Tell the usePagination
			manualGlobalFilter: true,
			autoResetFilters: false,
			pageCount: controlledPageCount,
		},
		// useSortBy,
		useGlobalFilter,
		usePagination,
	)

	useEffect(() => {
		if (setPaginationState) {
			setPaginationState({ pageIndex, pageSize })
		}
	}, [pageIndex, pageSize])

	useEffect(() => {
		if (fetchData) {
			fetchData({ pageIndex, pageSize, globalFilter })
		}
	}, [fetchData, pageIndex, pageSize, globalFilter])

	useImperativeHandle(ref, () => ({
		reloadData() {
			fetchData({ pageIndex, pageSize, globalFilter })
		},
	}))

	const TBodyStyle = loading ? { height: 'auto' } : {}

	return (
		<Fragment>
			<div className='table-responsive'>
				<div className='d-flex justify-content-between align-items-center'>
					<div className='form-group d-flex align-items-center mb-2'>
						<label className='m-0'>Show entries</label>{' '}
						<select
							value={pageSize}
							onChange={(e) => {
								setPageSize(Number(e.target.value))
							}}
							className='form-control'
							style={{ width: 50, padding: '3px 10px', marginLeft: '10px' }}>
							{[10, 20, 30, 40, 50].map((pageSize) => (
								<option key={pageSize} value={pageSize}>
									{pageSize}
								</option>
							))}
						</select>
					</div>
					<GlobalTableFilter filter={globalFilter} setFilter={setGlobalFilter} />
				</div>
				<table {...getTableProps()} className='table-1 table-bordered table-hover'>
					<thead>
						{headerGroups.map((headerGroup, idx) => (
							<tr {...headerGroup.getHeaderGroupProps()} key={idx}>
								{headerGroup.headers.map((column, idx) => (
									<Fragment key={idx}>
										<th
											{...column
												.getHeaderProps
												// column.getSortByToggleProps()
												()}>
											<div className='d-flex justify-content-between'>
												<div>{column.render('Header')}</div>
												&nbsp;&nbsp;
												<div className='ml-auto'>
													{column.isSorted ? (
														column.isSortedDesc ? (
															<i className='fas fa-sort-amount-down-alt' />
														) : (
															<i className='fas fa-sort-amount-up-alt' />
														)
													) : (
														''
													)}
												</div>
											</div>
										</th>
									</Fragment>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{loading ? (
							<Fragment>
								<tr>
									<td colSpan='10000' className='text-center'>
										<div
											className='d-flex justify-content-center align-items-center'
											style={TBodyStyle}>
											<Spinner animation='border' size='md' color='dark' />{' '}
											&nbsp;Loading...
										</div>
									</td>
								</tr>
							</Fragment>
						) : (
							<Fragment>
								{page.length > 0 ? (
									page.map((row, i) => {
										prepareRow(row)
										return (
											<tr {...row.getRowProps()} key={i}>
												{row.cells.map((cell, idx) => {
													return (
														<td {...cell.getCellProps()} key={idx}>
															{cell.render('Cell')}
														</td>
													)
												})}
											</tr>
										)
									})
								) : (
									<tr>
										<td colSpan='10000' className='text-center'>
											No data found
										</td>
									</tr>
								)}
							</Fragment>
							// <td colSpan="10000">
							//     Showing {page.length} of ~{total}{' '}
							//     results
							// </td>
						)}
					</tbody>
				</table>
			</div>
			<div className='d-flex justify-content-between'>
				<div>
					<span>
						Page{' '}
						<strong>
							{pageIndex + 1} of {pageOptions.length}
						</strong>{' '}
						{total ? <Fragment>| Total {total} entries</Fragment> : ''}
					</span>
				</div>
				<nav aria-label='Page navigation example'>
					<div className='pagination'>
						<button
							className='btn btn-sm btn-outline-secondary'
							onClick={() => gotoPage(0)}
							disabled={!canPreviousPage}>
							{'<<'}
						</button>
						&nbsp;
						<button
							className='btn btn-sm btn-outline-secondary'
							onClick={() => previousPage()}
							disabled={!canPreviousPage}>
							{'<'}
						</button>
						&nbsp;
						<button
							className='btn btn-sm btn-outline-secondary'
							onClick={() => nextPage()}
							disabled={!canNextPage}>
							{'>'}
						</button>
						&nbsp;
						<button
							className='btn btn-sm btn-outline-secondary'
							onClick={() => gotoPage(pageCount - 1)}
							disabled={!canNextPage}>
							{'>>'}
						</button>
					</div>
				</nav>
			</div>
		</Fragment>
	)
})

Table.displayName = 'ReactDataTable'

export default Table
