/* eslint-disable react-hooks/exhaustive-deps */
import classNames from 'classnames'
import { Fragment, useMemo } from 'react'

import stepIconActive from 'assets/images/onboarding/step-list-icon-active.svg'
import stepIconComplete from 'assets/images/onboarding/step-list-icon-complete.svg'
import stepIcon from 'assets/images/onboarding/step-list-icon.svg'

const Stepper = () => {
	const currentPath = window.location.pathname.split('/')[2] || null

	const steps = useMemo(() => {
		return [
			{
				ids: 'input-sin-number',
				title: 'Step 1',
				subTitle: 'Input SIN number',
				isCompleted:
					!['upload-documents', 'take-selfie'].includes('input-sin-number') &&
					currentPath !== 'input-sin-number',
			},
			{
				ids: ['upload-documents', 'verify-information'],
				title: 'Step 2',
				subTitle: 'Upload documents for verification',
				isCompleted:
					!['take-selfie'].includes('upload-documents') &&
					!['upload-documents', 'input-sin-number', 'verify-information'].includes(
						currentPath,
					),
			},
			{
				ids: 'take-selfie',
				title: 'Step 3',
				subTitle: 'Take a selfie for verification',
				isCompleted: currentPath === 'review-information',
			},
			{
				ids: 'review',
				title: 'Step 4',
				subTitle: 'Review',
			},
		]
	}, [])

	return (
		<Fragment>
			<div className='ml-3 flex  py-2'>
				<div className='mb-4 mt-4 flex flex-col'>
					{steps.map((_, idx) => {
						let borderColor = 'border-[#C9CDD5]'
						if (
							['upload-documents', 'verify-information'].includes(currentPath) &&
							idx === 0
						) {
							borderColor = 'border-[#4caf50]'
						} else if (currentPath === 'take-selfie') {
							borderColor = 'border-[#4caf50]'
						} else {
							borderColor = 'border-[#C9CDD5]'
						}
						return <div key={idx} className={`h-1/3 border-l ${borderColor}`} />
					})}
				</div>

				<ol className={classNames('relative ml-0 mt-4 text-gray-500 ')}>
					{steps.map((step, idx) => {
						const isActive = step?.ids.includes(currentPath)
						return (
							<li
								className={`${steps.length !== idx + 1 ? 'mb-12' : ''} ml-1`}
								key={idx}>
								<span className='absolute -left-4 flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-[#F5F7FF] dark:bg-gray-700 dark:ring-gray-900'>
									<Fragment>
										{step?.isCompleted ? (
											<img src={stepIconComplete} alt='' />
										) : (
											<Fragment>
												{isActive ? (
													<img src={stepIconActive} alt='' />
												) : (
													<img src={stepIcon} alt='' />
												)}
											</Fragment>
										)}
									</Fragment>
								</span>
								<p className='mb-1 text-xs font-medium uppercase text-[#74788D]'>
									{step.title}
								</p>
								<h3
									className={`${
										isActive
											? 'font-semibold text-[#495057]'
											: 'font-medium text-[#7B8190]'
									} text-base leading-tight`}>
									{step.subTitle}
								</h3>
							</li>
						)
					})}
				</ol>
			</div>
		</Fragment>
	)
}

export default Stepper
