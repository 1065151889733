import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import { useMemo } from 'react'
import { useDebounce } from 'use-debounce'
import { toQueryString } from 'utils/commonFunctions'
import { createOption } from '.'

const DocumentsQuery = (search = '', selectedDocs = []) => {
	const [debounceSearch] = useDebounce(search, 600)

	const queryParams = useMemo(() => {
		const params = { page: 1, limit: 20 }
		if (debounceSearch) {
			Object.assign(params, { search: debounceSearch })
		}
		return params
	}, [debounceSearch])

	const query = toQueryString({ ...queryParams, status: 'published' })

	const URL = useMemo(() => `${apiEndpoints.document}?${query}`, [query])
	const { data, isLoading } = useQuery(URL)

	const $selectedDocs = Array.isArray(selectedDocs)
		? selectedDocs.map((doc) => JSON.parse(doc?.value)?.id)
		: []

	const documents = Array.isArray(data?.data?.documents)
		? data?.data?.documents.map((document) => createOption(document, $selectedDocs))
		: []

	return {
		documents: documents,
		isLoading,
	}
}

export default DocumentsQuery
