import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import { useMemo } from 'react'
import { useDebounce } from 'use-debounce'
import { toQueryString } from 'utils/commonFunctions'

const ClientQuery = (search) => {
	const [debounceSearch] = useDebounce(search, 600)

	const defaultParams = { page: 1, limit: 20, role: 'client' }

	const searchParams = useMemo(() => {
		return debounceSearch ? { ...defaultParams, search: debounceSearch } : defaultParams
	}, [defaultParams, debounceSearch])

	const query = toQueryString(searchParams)
	const URL = `${apiEndpoints.clients}?${query}`

	const { data, isLoading } = useQuery(URL)
	const clients = data?.clients[0] || []
	const options = useMemo(
		() =>
			Array.isArray(clients)
				? clients.map((client) => ({
						label: (
							<span
								className='line-clamp-1 min-w-fit max-w-fit'
								title={`${client?.first_name} ${client?.last_name}`}>{`${client?.first_name} ${client?.last_name}`}</span>
						),
						value: client?.id,
				  }))
				: [],
		[clients],
	)

	return {
		options,
		isLoading,
	}
}

export default ClientQuery
