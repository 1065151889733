import TabsHeader from './tabs-header'

import activeUploadDocumentIcon from 'assets/svg/active-document-upload-icon.svg'
import activeFieldMapping from 'assets/svg/active-field-mapping.svg'
import activeFormBuilderIcon from 'assets/svg/active-form-builder-icon.svg'
import activeSignatureIcon from 'assets/svg/active-signature-icon.svg'
import fieldMapping from 'assets/svg/field-mapping.svg'
import formBuilderIcon from 'assets/svg/form-builder-icon.svg'
import signatureIcon from 'assets/svg/signature-icon.svg'

export const tabs = [
	{ id: 1, label: 'Upload Document', activeIcon: activeUploadDocumentIcon },
	{ id: 2, label: 'Field Mapping', activeIcon: activeFieldMapping, icon: fieldMapping },
	{
		id: 3,
		label: 'Form Builder',
		activeIcon: activeFormBuilderIcon,
		icon: formBuilderIcon,
	},
	{ id: 4, label: 'Signature', activeIcon: activeSignatureIcon, icon: signatureIcon },
]

export const getTabs = (isSignatureRequired) => {
	return tabs.filter((tab) => (isSignatureRequired ? tab?.id <= 4 : tab?.id < 4))
}

export default TabsHeader
