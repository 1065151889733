import { Field, reduxForm } from 'redux-form'

import { TextField } from 'components/Forms/TextFieldComponent'
import 'react-datepicker/dist/react-datepicker.css'

const FilterForm = () => {
	return (
		<form>
			<div className='flex justify-between'>
				<div className='w-[320px]'>
					<Field
						type='search'
						name='search'
						component={TextField}
						placeholder='Search documents...'
					/>
				</div>
			</div>
		</form>
	)
}

export default reduxForm({
	form: 'document_view_filter',
})(FilterForm)
