import { useMemo } from 'react'

import infinityIcon from 'assets/svg/infinity-svgrepo-com.svg'
import ProgressBar from 'theme/ui/progress-bar'
import { getRemainingDays } from '.'

const UsageSubscription = ({ subscription, $subscription }) => {
	const { remainingDays, remainingPercentage, subscriptionDays } = useMemo(
		() => getRemainingDays(subscription),
		[subscription],
	)

	return (
		<div>
			<div className='!mb-3 flex items-center justify-between'>
				<h3 className='m-0 text-lg font-medium text-gray-600'>Usage</h3>
			</div>

			<div className='grid-col-1 grid gap-4 sm:grid-cols-3'>
				<div className='rounded-lg !border !p-4'>
					<div className='flex flex-col space-y-6'>
						<div className='flex h-12 w-12 items-center justify-center rounded-full !border'>
							<span className='uil-users-alt text-xl' />
						</div>

						<div>
							<div className='!mb-3 flex items-baseline justify-between'>
								<span className='text-[13px] text-gray-500'>Total Users</span>
								<span className='text-sm font-semibold text-gray-950'>
									{$subscription?.user_counts} of {subscription?.users_allowed}
								</span>
							</div>

							<ProgressBar
								value={
									($subscription?.user_counts / subscription?.users_allowed) * 100
								}
								variant={
									($subscription?.user_counts / subscription?.users_allowed) *
										100 >=
									90
										? 'danger'
										: 'primary'
								}
							/>
						</div>
					</div>
				</div>

				<div className='rounded-lg !border !p-4'>
					<div className='flex flex-col space-y-6'>
						<div className='flex h-12 w-12 items-center justify-center rounded-full !border'>
							<span className='uil-document-layout-right text-xl' />
						</div>

						<div>
							<div className='!mb-3 flex items-baseline justify-between'>
								<span className='text-[13px] text-gray-500'>PDF Access count</span>
								<span className='flex text-sm font-semibold text-gray-950'>
									{$subscription?.pdf_access_count} of{' '}
									<img src={infinityIcon} alt='infinity' className='ml-1 h-5' />
								</span>
							</div>

							<ProgressBar value={97} />
						</div>
					</div>
				</div>

				<div className='rounded-lg !border !p-4'>
					<div className='flex flex-col space-y-6'>
						<div className='flex h-12 w-12 items-center justify-center rounded-full !border'>
							<span className='uil-calendar-alt text-xl' />
						</div>

						<div>
							<div className='!mb-3 flex items-baseline justify-between'>
								<span className='text-[13px] text-gray-500'>Remaining days</span>
								<span className='text-sm font-semibold text-gray-950'>
									{remainingDays} of {subscriptionDays}
								</span>
							</div>

							<ProgressBar value={remainingPercentage} />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default UsageSubscription
