import { FileManagerLayout } from 'modules/file-manager'
import TrashListingContainer from 'modules/file-manager/trash-listing'
import { TableProvider } from 'theme/ui/data-table'

const Trash = () => {
	return (
		<FileManagerLayout
			meta={{
				title: 'File Manager',
			}}>
			<div>
				<TableProvider>
					<TrashListingContainer />
				</TableProvider>
			</div>
		</FileManagerLayout>
	)
}

export default Trash
