import { PDFDocument } from 'pdf-lib'

export const sanitizePdf = async (file) => {
	try {
		const arrayBuffer = await file.arrayBuffer()
		await PDFDocument.load(arrayBuffer)
	} catch (error) {
		throw new Error('Failed to parse PDF document')
	}
}
