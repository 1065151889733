import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import { useMemo } from 'react'

const SystemFieldQuery = (id) => {
	const URL = useMemo(() => `${apiEndpoints.systemFields}/${id}`, [id])

	const { data, isLoading, mutate } = useQuery(URL, {
		revalidateIfStale: true,
	})
	const field = data?.data

	return {
		field,
		mutate,
		isLoading,
	}
}

export default SystemFieldQuery
