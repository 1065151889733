import { Field, getIn } from 'formik'
import moment from 'moment'
import { Fragment, useEffect, useMemo, useState } from 'react'

import { useUploadDocumentMutation } from 'modules/documents/document-api'
import { DocumentVersionPopup } from 'modules/documents/document-versions'
import { useDeleteFileMutation } from 'modules/files/file-apis'
import { CheckboxField, InputField, SelectField } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { formatBytes } from 'utils/commonFunctions'
import { userListOptions } from '../..'
import FileUploadDropzone from './file-upload-dropzone'
import UploadFileCard from './uploaded-file-card'

const UploadDocument = ({ formik, document, onAutoSave }) => {
	const useOptions = useMemo(() => userListOptions, [])
	const [file, setFile] = useState(null)
	const [isUploading, setIsUploading] = useState(false)
	const [progress, setProgress] = useState(null)
	const [uploadDocument] = useUploadDocumentMutation()
	const [deleteFile, { isLoading: isDeleting }] = useDeleteFileMutation()
	const [isOpen, setIsOpen] = useState(false)
	const onClose = () => setIsOpen(false)

	useEffect(() => {
		if (document?.file) {
			setFile(document?.file)
		}
	}, [document?.file])

	const onUploadProgress = (progressEvent) => {
		let percentComplete = progressEvent.loaded / progressEvent.total
		percentComplete = parseInt(percentComplete * 100)

		let uploadedSize = progressEvent.loaded
		if (progressEvent.loaded > file?.size) {
			uploadedSize = file?.size
		}
		setProgress({
			percentage: percentComplete,
			uploadedSize: formatBytes(uploadedSize),
		})
	}

	const onFileDrop = async (file) => {
		if (file) {
			setIsUploading(true)
			setFile(Object.assign(file, { timestamp: moment() }))
			const formData = new FormData()
			formData.append('file', file)
			const response = await uploadDocument({
				payload: formData,
				onUploadProgress,
			})

			if (response?.data?.status === 200) {
				const fileId = response?.data?.data[0]?.file_id
				setFile(Object.assign(file, { id: fileId }))
				formik.setFieldValue('file', fileId)
				notification('success', response?.data?.message)
			}
			setIsUploading(false)
		}
	}

	const onDelete = async (id) => {
		if (id) {
			await deleteFile(id)
			setFile(null)
		} else {
			setFile(null)
		}
		formik.setFieldValue('file', null)
	}

	const { error, touched } = useMemo(() => {
		const error = getIn(formik.errors, 'file')
		const touched = getIn(formik.touched, 'file')

		return {
			error,
			touched,
		}
	}, [formik.errors])

	return (
		<div className='flex flex-col gap-6'>
			<div className='grid grid-cols-3 gap-10'>
				<div className='col-span-1'>
					<h3 className='text-lg font-bold'>Document Title</h3>
					<p className='text-xs text-gray-500'>
						Selected is the workflow which you will assign it to specific client and
						co-applicant.
					</p>
				</div>
				<div className='col-span-2 rounded bg-white p-3'>
					<div className='grid grid-cols-2 gap-6'>
						<Field
							label='Title'
							name='title'
							component={InputField}
							isRequiredField={true}
							labelHelperText={true}
							onAutoSave={onAutoSave}
						/>

						<Field
							label='Maximum Clients'
							name='min_clients'
							options={useOptions}
							component={SelectField}
							isRequiredField={true}
							isSearchable={false}
							onAutoSave={onAutoSave}
							helperText='This document can involve a maximum number of clients.'
						/>

						<Field
							label='Is Signature required'
							id='signature_required'
							name='signature_required'
							component={CheckboxField}
						/>

						{document ? (
							<Fragment>
								<div className='flex items-center justify-between'>
									<div className='flex items-center space-x-2'>
										<span className='text-sm text-gray-600'>
											Current Version:
										</span>
										<span className='text-sm font-bold text-gray-700'>
											(V. {document?.version})
										</span>
									</div>
									<button
										type='button'
										onClick={() => setIsOpen(true)}
										className='cursor-pointer font-bold text-main hover:underline'>
										View All
									</button>
								</div>

								<DocumentVersionPopup
									id={document?.id}
									isOpen={isOpen}
									onClose={onClose}
								/>
							</Fragment>
						) : undefined}
					</div>
				</div>
			</div>
			<div>
				{file ? (
					<UploadFileCard
						file={file}
						time={moment(file?.timestamp).fromNow()}
						progress={progress?.percentage}
						uploadedSize={progress?.uploadedSize}
						onDelete={onDelete}
						isDeleting={isDeleting}
						document={document}
						disabled={isUploading && !(file?.progress > 0 && file?.progress < 100)}
					/>
				) : (
					<FileUploadDropzone onFileDrop={onFileDrop} />
				)}
				{error && touched ? <span className='text-red-500'>{error}</span> : undefined}
			</div>

			{/* {document ? (
				<DocumentViewerProvider>
					<FieldMapping document={document} formik={formik} onAutoSave={onAutoSave} />
				</DocumentViewerProvider>
			) : undefined} */}
		</div>
	)
}

export default UploadDocument
