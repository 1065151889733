import { useMemo } from 'react'

import { useQuery } from 'hooks'
import { createExpredInOption } from '.'
import { OWNER_INVITES_API_ROUTES } from '../../routes'

const ExpiredInQuery = () => {
	const URL = useMemo(() => OWNER_INVITES_API_ROUTES.expiration_options(), [])
	const { data, isLoading } = useQuery(URL)

	const options = useMemo(() => {
		return Array.isArray(data?.data?.expirationOptions)
			? data?.data?.expirationOptions.map((group) => createExpredInOption(group))
			: []
	}, [data?.data?.expirationOptions])

	return { options, isLoading }
}

export default ExpiredInQuery
