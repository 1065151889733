import { createColumnHelper } from '@tanstack/react-table'
import { Fragment, useState } from 'react'
import Badge from 'theme/ui/badge'

import DataTable from 'theme/ui/data-table'

const TeamMemberTable = ({ data, meta, isLoading, pagination, setPagination }) => {
	const [sorting, setSorting] = useState([])

	const columnHelper = createColumnHelper()

	const columns = [
		columnHelper.accessor('index', {
			size: 20,
			header: () => <span>#</span>,
			cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		}),

		columnHelper.accessor((row) => row.name, {
			id: 'name',
			size: 200,
			header: () => <span>Name</span>,
			cell: (info) => <span className='font-bold'>{info.getValue()}</span>,
		}),

		columnHelper.accessor((row) => row.email, {
			id: 'email',
			header: () => <span>Email</span>,
			cell: (info) => <span>{info.getValue()}</span>,
		}),

		columnHelper.accessor((row) => row.phone, {
			id: 'phone',
			header: () => <span>Phone</span>,
			cell: (info) => <span>{info.getValue()}</span>,
		}),

		columnHelper.accessor((row) => row.role, {
			id: 'role',
			header: () => <span>Role</span>,
			cell: (info) => <Badge>{info.getValue()}</Badge>,
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={data}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			setSorting={setSorting}
			sorting={sorting}
			meta={meta}
		/>
	)
}

export default TeamMemberTable
