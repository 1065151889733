import { useEffect, useRef } from 'react'

const IndeterminateCheckbox = ({ indeterminate, className = '', ...rest }) => {
	const ref = useRef(null)

	useEffect(() => {
		if (typeof indeterminate === 'boolean') {
			ref.current.indeterminate = !rest.checked && indeterminate
		}
	}, [ref, indeterminate])

	return (
		<input
			type='checkbox'
			ref={ref}
			className={
				className +
				' form-check-input cursor-pointer !text-sm indeterminate:!border-gray-400 indeterminate:!bg-gray-400'
			}
			{...rest}
		/>
	)
}

export default IndeterminateCheckbox
