import ProfileManage from './profile-manage'

export const preparePayload = (values) => {
	try {
		return {
			isMfaEnabled: values?.isMfaEnabled,
			first_name: values?.first_name,
			last_name: values?.last_name,
			email: values?.email,
			phone_number: values?.phone_number,
			address1: values?.userProfile?.address_1,
			address2: values?.userProfile?.address_2,
			unit: values?.userProfile?.unit,
			city: values?.userProfile?.city,
			province: values?.userProfile?.province,
			zip_code: values?.userProfile?.zip_code,
			country: values?.userProfile?.country,
		}
	} catch (error) {
		return {}
	}
}

export default ProfileManage
