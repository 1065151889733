import { useLazyGetPendingSubscriptionQuery } from 'modules/subscriptions/subscriptions-api'
import { Fragment, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import PendingSubFilters from './pending-sub-filters'
import PendingSubTable from './pending-sub-table'

const PendingSubListingContainer = () => {
	const location = useLocation()
	const history = useHistory()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination
	const parseQueryString = getParseQueryString(location?.search)

	useEffect(() => {
		if (pagination) {
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			history.push(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	const [fetchPendingSubscription, { data, isLoading }] = useLazyGetPendingSubscriptionQuery()

	useEffect(() => {
		if (location?.search) {
			fetchPendingSubscription(getParseQueryString(location?.search))
		}
	}, [location?.search])

	return (
		<Fragment>
			<PendingSubFilters setPagination={setPagination} />
			<PendingSubTable
				data={data?.pendingSubscriptions}
				meta={data?.meta}
				isLoading={isLoading}
				pagination={pagination}
				setPagination={setPagination}
			/>
		</Fragment>
	)
}

export default PendingSubListingContainer
