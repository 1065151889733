import { snakeCase } from 'lodash'
import PdfViewer from 'modules/workflows/workflow-review/uploaded-files/pdf-viewer'
import { Fragment, useState } from 'react'
import { FileIcon, defaultStyles } from 'react-file-icon'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import ToolTip from '../tool-tip'

const FilePreviewCard = ({ mime_type, path, name, title }) => {
	let [isOpen, setIsOpen] = useState(false)

	function openModal() {
		setIsOpen(true)
	}
	const toggle = () => setIsOpen(!isOpen)

	const [type, extension] = mime_type ? mime_type.split('/') : []
	return (
		<Fragment>
			<Modal isOpen={isOpen} toggle={toggle} size='lg' fullscreen='lg' backdrop='static'>
				<ModalHeader toggle={toggle}>File Preview</ModalHeader>
				<ModalBody className='p-0'>
					{type === 'image' ? (
						<div
							className='mx-auto flex items-center
                         justify-center p-3'>
							<img src={path} className='h-auto w-[600px]' />
						</div>
					) : undefined}

					{type === 'application' && extension === 'pdf' ? (
						<PdfViewer fileUrl={path} />
					) : undefined}
				</ModalBody>
			</Modal>
			<div className='flex min-w-[200px] items-center justify-between space-x-3 rounded-md !border bg-white px-3 py-2'>
				<div className='flex items-center space-x-3 '>
					<div className='h-auto w-9'>
						<FileIcon extension={extension} {...defaultStyles[extension]} />
					</div>
					<div className='flex flex-col'>
						{title ? (
							<Fragment>
								<span
									className='line-clamp-1 font-semibold'
									id={`${snakeCase(title)}-title`}>
									{title}
								</span>
								<ToolTip
									targetId={`${snakeCase(title)}-title`}
									tooltipText={title}
								/>
							</Fragment>
						) : undefined}
						{name ? (
							<span
								className='file-name line-clamp-1 text-xs text-gray-500'
								title={name}>
								{name}
							</span>
						) : undefined}
					</div>
				</div>

				{path && (
					<a
						onClick={(e) => {
							e.preventDefault()
							openModal()
						}}
						href={path}
						className='link'>
						View
					</a>
				)}
			</div>
		</Fragment>
	)
}

export default FilePreviewCard
