import { useEffect } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import PdfViewer from './pdf-viewer'

const DocumentPreviewPopup = ({ $document, isOpen, toggle }) => {
	useEffect(() => {
		const body = document.getElementsByTagName('body')[0].style

		return () => {
			body.paddingRight = 0
		}
	}, [isOpen])

	return (
		<Modal isOpen={isOpen} toggle={toggle} size='lg' fullscreen='lg' backdrop='static'>
			<ModalHeader toggle={toggle}>Document Preview</ModalHeader>
			<ModalBody className='p-0'>
				<PdfViewer document={$document} />
			</ModalBody>
		</Modal>
	)
}

export default DocumentPreviewPopup
