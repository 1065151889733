import { useMemo } from 'react'
import { useDebounce } from 'use-debounce'

import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import { toQueryString } from 'utils/commonFunctions'
import { createWatcherOption } from '.'

const WatcherOptionsQuery = (search) => {
	const [debounceSearch] = useDebounce(search, 600)

	const defaultParams = { page: 1, limit: 20 }
	const searchParams = debounceSearch
		? { ...defaultParams, search: debounceSearch }
		: defaultParams

	const query = toQueryString(searchParams)
	const URL = `${apiEndpoints.user.teamUsers}?${query}`

	const { data, isLoading } = useQuery(URL)

	const watchers = data?.data?.users || []

	const options = useMemo(
		() =>
			Array.isArray(watchers) ? watchers.map((watcher) => createWatcherOption(watcher)) : [],
		[watchers],
	)

	return {
		watcherOptions: options,
		isLoading,
	}
}

export default WatcherOptionsQuery
