const PaymentsDetailsCard = ({ title, data }) => {
	return (
		<>
			<div className='mb-4 rounded-md bg-white '>
				<div className='rounded border-b px-4 py-3'>
					<h5 className='font-bold'>{title}</h5>
				</div>
				<div className='flex flex-col p-2'>
					{Object.entries(data).map(([key, value], ind) => (
						<div className='m-0 grid grid-cols-2 px-3 py-2' key={ind}>
							<div>
								<span className='capitalize  text-gray-500'>
									{key.replace('_', ' ')}:
								</span>
							</div>
							<div className='break-words'>
								<strong className='line-clamp-1 capitalize' title={value}>
									{value || '-'}
								</strong>
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	)
}

export default PaymentsDetailsCard
