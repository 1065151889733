import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { SpinLoader } from 'components/Common'
import { useAppRouter } from 'hooks'
import { TeamDetailsContainer, useLazyGetTeamDetailsQuery } from 'modules/teams'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'
import UnAuthorize from '../StaticPages/UnAuthorize'

const TeamDetails = () => {
	const { params } = useAppRouter()
	const Id = params?.id
	const [fetchTeam, { data, isFetching }] = useLazyGetTeamDetailsQuery()
	useEffect(() => {
		if (Id) {
			fetchTeam(Id)
		}
	}, [Id])

	const { team, total_users } = useMemo(
		() => ({
			team: data?.data?.team,
			total_users: data?.data?.counts?.userCount,
		}),
		[data?.data],
	)
	const { owner, clients, users } = useMemo(
		() => ({
			owner: team?.teamOwner,
			clients: team?.clients || [],
			users: team?.users || [],
		}),
		[team],
	)

	const $subscription = useMemo(
		() => ({
			payments: data?.data?.payments || [],
			pdf_access_count: data?.data?.pdf_access_count,
		}),
		[data?.data],
	)

	const isSuperAdmin = useSelector((state) => state.Permissions.isSuperAdmin)

	return (
		<AppLayout
			meta={{
				title: `${team?.team_name} - Team Users`,
			}}>
			{isSuperAdmin ? (
				<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
					<div className='page-container'>
						<PageHeader
							title='Teams Details'
							hasBackButton={true}
							redirectTo={'/teams'}
							description='Easily manage your teams hassle-free from one convenient dashboard. Stay in control of your spending, effortlessly track renewal dates, and make changes with just a few clicks, all in one centralized hub designed for your convenience'></PageHeader>
						<div className='page-body'>
							{isFetching ? (
								<SpinLoader />
							) : (
								<div>
									<TeamDetailsContainer
										clients={clients}
										team={team}
										users={users}
										owner={owner}
										$subscription={$subscription}
										total_users={total_users}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			) : (
				<UnAuthorize />
			)}
		</AppLayout>
	)
}

export default TeamDetails
