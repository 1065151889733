import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import AddOptions from './add-options'
import OptionsList from './option-list'

const Actions = ({ isActionActive, type, data }) => {
	return (
		<div
			className='float-end flex w-[150px] items-center justify-end space-x-2  text-right'
			onClick={(event) => event.stopPropagation()}>
			{isActionActive ? (
				<Fragment>
					{type === 'document' ? (
						<Link to='#' className='text-sm font-medium'>
							<span>Use as template</span>
							<i className='uil-angle-right text-base'></i>
						</Link>
					) : (
						<AddOptions />
					)}

					<OptionsList data={data} type={type} />
				</Fragment>
			) : undefined}
		</div>
	)
}

export default Actions
