import { useSelector } from 'react-redux'

import { TeamListingContainer } from 'modules/teams'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'
import UnAuthorizePage from '../StaticPages/UnAuthorize'

const TeamsListing = () => {
	const isSuperAdmin = useSelector((state) => state.Permissions.isSuperAdmin)

	return (
		<AppLayout
			meta={{
				title: 'Teams',
			}}>
			{isSuperAdmin ? (
				<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
					<div className='page-container'>
						<PageHeader
							title='Teams'
							description='Easily manage your teams hassle-free from one convenient dashboard. Stay in control of your spending, effortlessly track renewal dates, and make changes with just a few clicks, all in one centralized hub designed for your convenience'></PageHeader>
						<div className='page-body'>
							<TeamListingContainer />
						</div>
					</div>
				</div>
			) : (
				<UnAuthorizePage />
			)}
		</AppLayout>
	)
}

export default TeamsListing
