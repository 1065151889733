export const SUBSCRIPTION_APP_ROUTES = {
	findAll: () => '/subscriptions',
	create: () => '/subscriptions/create',
	findOne: (id) => `/subscriptions/${id}`,
	edit: (id) => `/subscriptions/${id}/edit`,
}

export const FEATURES_APP_ROUTES = {
	findAll: () => '/features',
	create: () => '/features/create',
	findOne: (id) => `/features/${id}`,
	edit: (id) => `/features/${id}/edit`,
}
