import { Fragment } from 'react'
import { Field } from 'redux-form'

import { DatePickerInput } from 'components/Forms2'

const DueDateFilter = ({ onClearFilter }) => {
	return (
		<Fragment>
			<div className='flex max-w-[214px] shrink-0 flex-col !border bg-white'>
				<div className='w-full px-3 py-2'>
					<button
						type='button'
						onClick={onClearFilter}
						className='m-0 text-sm font-medium not-italic leading-5 text-[#495057]'>
						<i className='uil-angle-left text-base' /> <span>Filter via Due date</span>
					</button>
				</div>

				<div className='divide-y px-3 py-2'>
					<Field name='due_date' component={DatePickerInput} />
				</div>
			</div>
		</Fragment>
	)
}

export default DueDateFilter
