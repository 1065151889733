import FiltersForm from './filters-form'

export const getFilters = (values) => {
	const filters = {}

	if (values?.search) {
		Object.assign(filters, { search: values?.search })
	}

	return filters
}

export default FiltersForm
