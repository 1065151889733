import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

import UserQuery from 'hooks/UserQuery'

const ProfileMenu = () => {
    const [menu, setMenu] = useState(false)
    const { user } = UserQuery()

    return (
        <Fragment>
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
            >
                <DropdownToggle
                    className='p-2'
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    <div className='flex items-center space-x-1'>
                        <div className='font-semibold bg-gray-200 h-7 w-7 flex items-center justify-center rounded-full'>
                            {user?.first_name?.charAt(0) || 'M'}
                        </div>
                        <span>{user?.first_name || 'Max'}</span>
                        <i className='uil uil-angle-down' />
                    </div>

                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">

                    <Link to="/logout" className="dropdown-item">
                        <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
                        <span>Logout</span>
                    </Link>
                </DropdownMenu>
            </Dropdown>
        </Fragment>
    )
}

export default ProfileMenu