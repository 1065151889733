import { useState } from 'react'
import { Button } from 'theme/ui/forms'
import notification from 'utilities/notification'
import request from 'utilities/request'
import { OWNER_INVITES_API_ROUTES } from '../routes'

const InviteItem = ({ invite }) => {
	const [isLoading, setIsLoading] = useState(false)

	const onResendInvite = (invite) => {
		try {
			const payload = {
				email: invite?.email,
				group_id: invite?.role?.id,
				// isOwner: true,
				resend: true,
				isEmailSend: true,
			}
			setIsLoading(true)
			request.post(OWNER_INVITES_API_ROUTES.resend(), payload).then((response) => {
				setIsLoading(false)
				if (response?.status === 200) {
					notification('success', response?.message)
				} else {
					setIsLoading(false)
				}
			})
		} catch (error) {
			setIsLoading(false)
		}
	}

	return (
		<div className='flex items-center justify-between px-3 py-2.5 hover:bg-gray-50'>
			<div className='flex w-[200px] flex-col space-y-1'>
				<span title={invite?.email} className='truncate font-semibold text-black'>
					{invite?.email}
				</span>
				<div className='flex items-center space-x-2'>
					<span className='text-xs text-gray-500'>{invite?.role?.name}</span>
					<span className='h-1 w-1 rounded-full bg-gray-900' />
					<span className='text-xs text-gray-500'>{invite?.invited_at}</span>
				</div>
			</div>

			<Button
				type='button'
				variant='white'
				isLoading={isLoading}
				isLoadingText='Sending...'
				size='sm'
				onClick={() => onResendInvite(invite)}>
				Resend
			</Button>
		</div>
	)
}

export default InviteItem
