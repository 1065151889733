import { useMemo } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { getParseQueryString } from 'utilities/helpers'

const useAppRouter = () => {
	const history = useHistory()
	const location = useLocation()
	const params = useParams()

	const parseQuery = useMemo(() => getParseQueryString(location?.search), [location?.search])

	const navigate = (path) => history.push(path)
	const goBack = () => (location.key ? history.goBack() : undefined)
	const hasHistory = location?.key ? true : false

	return { navigate, goBack, hasHistory, history, location, params, parseQuery }
}

export default useAppRouter
