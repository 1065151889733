import { Field } from 'formik'
import DatePickerInput from './date-picker-input'

const DatePicker = () => {
	return (
		<div>
			<Field
				label='Due Date'
				name='due_date'
				component={DatePickerInput}
				minDate={new Date()}
			/>
		</div>
	)
}

export default DatePicker
