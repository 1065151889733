const DocumentBadge = ({ title, onRemove, idx }) => {
	return (
		<span
			key={idx}
			className='inline-flex items-center rounded bg-gray-100 px-2 py-1 text-xs font-normal text-gray-700 '>
			{title}
			<button
				type='button'
				className='ms-2 inline-flex items-center rounded-sm  p-1 text-sm text-gray-400 hover:border-gray-300 hover:bg-red-300 hover:text-red-600 '
				onClick={onRemove}>
				<svg
					className='h-2 w-2'
					aria-hidden='true'
					xmlns='http://www.w3.org/2000/svg'
					fill='none'
					viewBox='0 0 14 14'>
					<path
						stroke='currentColor'
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeWidth='2'
						d='m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6'
					/>
				</svg>
				<span className='sr-only'>Remove badge</span>
			</button>
		</span>
	)
}

export default DocumentBadge
