export const filterGroups = (g) => {
	if (['owner', 'advisor'].includes(g?.group?.slug) && g?.status === 'Pending') {
		return false
	} else if (g?.status === 'Non-Invited') {
		return false
	} else {
		return true
	}
}

export { default as RegisterForm } from './register'
