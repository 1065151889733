import { createContext, useContext, useState } from 'react'

const TabsContext = createContext()

export const useTabs = () => useContext(TabsContext)

export const TabsProvider = ({ children }) => {
	const [tab, setTab] = useState(1)

	const toggleTab = (tabId) => setTab(tabId)

	return (
		<TabsContext.Provider
			value={{
				activeTab: tab,
				nextTab: tab + 1,
				setActiveTab: toggleTab,
			}}>
			{children}
		</TabsContext.Provider>
	)
}
