import { useUser } from 'hooks'
import { WORKFLOW_TEMAPLTE_ROUTES } from 'modules/workflows/workflow-template/routes'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import OptionsList from './option-list'

const Actions = ({ isActionActive, type }) => {
	const { isSuperAdmin } = useUser()
	return (
		<div
			className='float-end flex w-[150px] items-center justify-end space-x-2  text-right'
			onClick={(event) => event.stopPropagation()}>
			{isActionActive ? (
				<Fragment>
					{type === 'document' ? (
						<Fragment>
							{!isSuperAdmin ? (
								<Link
									to={WORKFLOW_TEMAPLTE_ROUTES.create()}
									className='text-sm font-medium'>
									<span>Use as template</span>
									<i className='uil-angle-right text-base'></i>
								</Link>
							) : undefined}
						</Fragment>
					) : (
						<Link to='#' className='text-sm font-medium'>
							<i className='uil-plus text-sm'></i>
							<span>Add</span>
						</Link>
					)}

					<OptionsList />
				</Fragment>
			) : undefined}
		</div>
	)
}

export default Actions
