export const CLIENT_APP_ROUTES = {
	findAll: () => '/clients',
	invite: () => '/clients/create',
	import: () => '/clients/import',
	findOne: (id) => `/clients/${id}`,
	edit: (id) => `/clients/${id}/edit`,
	findAllInvites: () => '/invited-clients',
	verifyClient: (id) => `/clients/${id}/verify`,
	editInvitedClient: (id) => `/invited-clients/${id}/edit`,
}
export const CLIENT_API_ROUTES = {
	findAll: () => '/client/profiles',
	findOne: (id) => `/users/profiles/${id}`,
	findAllInvites: () => '/users/pending-invitation',
	destroy: (id) => `/users/delete/client-account/${id}`,
	findAllWorkflows: (id) => `/client/profiles/workflows/${id}`,
	findAllDocuments: (id) => `/client/profiles/documents/${id}`,
	findAllNotes: (id) => `/users/profiles/notes/${id}`,
}
