import { createApi } from '@reduxjs/toolkit/query/react'
import apiEndpoints from 'helpers/apiEndpoints'

import axiosBaseQuery from 'store/axios-base-query'
import { formatDate } from 'utilities/helpers'

const API_URL = apiEndpoints.household

export const householdsApi = createApi({
	reducerPath: 'Households',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['Households'],
	endpoints: (builder) => ({
		getHouseholds: builder.query({
			keepUnusedDataFor: 0,
			query: (params) => ({
				url: API_URL.households,
				method: 'GET',
				params: params,
			}),
			providesTags: ['Households'],
			transformResponse: (response, _, arg) => {
				const households = Array.isArray(response?.data?.clientgroups)
					? response?.data?.clientgroups?.map((household, idx) => ({
							...household,
							index: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + idx + 1,
							name: household?.name,
							created_at: formatDate(household?.created_at),
					  }))
					: []
				return {
					households,
					meta: response?.data?.meta,
				}
			},
		}),
		getUniqueHouseholds: builder.query({
			keepUnusedDataFor: 0,
			query: (params) => ({
				url: API_URL.uniqueHouseholds,
				method: 'GET',
				params: params,
			}),
			providesTags: ['Households'],
			transformResponse: (response, _, arg) => {
				const households = Array.isArray(response?.data)
					? response?.data?.map((household, idx) => ({
							...household,
							index: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + idx + 1,
							name: household?.name,
							clientsCount: household?.clients?.length || 0,
					  }))
					: []
				return {
					households,
					meta: response?.data?.meta,
				}
			},
		}),
		createHousehold: builder.mutation({
			query: (payload) => ({
				url: API_URL.households,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['Households'],
		}),
		updateHousehold: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${API_URL.households}/${id}`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['Households'],
		}),
		deleteHousehold: builder.mutation({
			query: (id) => ({
				url: `${API_URL.households}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Households'],
		}),
	}),
})

export const {
	useLazyGetHouseholdsQuery,
	useLazyGetUniqueHouseholdsQuery,
	useDeleteHouseholdMutation,
	useUpdateHouseholdMutation,
	useCreateHouseholdMutation,
} = householdsApi
