import { Link } from 'react-router-dom'
import { routes } from '..'

const Header = () => {
	return (
		<div className='flex w-full flex-col'>
			<div className='mb-0 flex w-full justify-between'>
				<div className='max-w-[733px]'>
					<Link
						to={routes.finalReviews()}
						className='mb-2 flex items-center text-base font-normal not-italic leading-5'>
						<i className='uil-angle-left text-3xl' />
						<span>Back</span>
					</Link>
					<h4 className='page-title font-ibmplex'>Final Review Workflow</h4>
					<p className='mb-0 text-xs text-[#74788D]'>
						When reviewing workflow here, do not ignore to check all the required fields
						completely and follow the steps to review it properly.
					</p>
				</div>
			</div>
		</div>
	)
}

export default Header
