/* eslint-disable no-case-declarations */
import { Fragment } from 'react'
import FieldType from './field-type'

const PdfFieldRender = ({ field, name, i, error }) => {
	return (
		<Fragment>
			<FieldType name={name} field={field} i={i} />
			{error && (
				<div className='invalid-feedback' style={{ display: 'block' }}>
					{error}
				</div>
			)}
		</Fragment>
	)
}

export default PdfFieldRender
