import { Dialog, Transition } from '@headlessui/react'
import { useAppRouter } from 'hooks'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { Button } from 'theme/ui/forms'

const WorkflowInitiatePopup = ({ isOpen, redirectUrl, workflow }) => {
	const { navigate } = useAppRouter()
	return (
		<Transition
			show={isOpen}
			enter='transition duration-100 ease-out'
			enterFrom='transform scale-95 opacity-0'
			enterTo='transform scale-100 opacity-100'
			leave='transition duration-75 ease-out'
			leaveFrom='transform scale-100 opacity-100'
			leaveTo='transform scale-95 opacity-0'
			as='div'>
			<Dialog as='div' open={true} onClose={() => {}} className='popup-overlay relative'>
				<div className='fixed inset-0 bg-black/30' aria-hidden='true' />
				<div className='fixed inset-0 flex w-screen items-center justify-center p-4'>
					<Dialog.Panel className='w-96 rounded-md bg-white'>
						<div className='rounded-t px-8 py-4'>
							<div className='mx-auto'>
								<h1 className='mb-1 text-center text-lg font-bold'>
									You have been assigned a workflow.
								</h1>

								<p className='m-0 text-center text-sm font-normal text-gray-500'>
									You have accessed a workflow URL for the &nbsp;
									<Link to={redirectUrl} className='link'>
										{workflow?.workflow_template?.title}
									</Link>{' '}
									&nbsp; workflow.
								</p>
							</div>
						</div>
						<div className='px-8 py-[20px]'>
							<div className='mx-auto flex w-fit items-center space-x-3'>
								<Button
									variant='primary'
									size='sm'
									onClick={() => navigate(redirectUrl)}>
									<div className='flex items-center space-x-2'>
										<span>Start Workflow</span>
									</div>
								</Button>
							</div>
						</div>
					</Dialog.Panel>
				</div>
			</Dialog>
		</Transition>
	)
}

export default WorkflowInitiatePopup
