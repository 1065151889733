import { createApi } from '@reduxjs/toolkit/query/react'
import apiEndpoints from 'helpers/apiEndpoints'
import axiosBaseQuery from 'store/axios-base-query'

export const documentPackageApi = createApi({
	reducerPath: 'DocumentPackages',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['DocumentPackages'],
	endpoints: (builder) => ({
		getDocumentPackages: builder.query({
			keepUnusedDataFor: 0,
			query: ({ params, url }) => ({
				url: url,
				method: 'GET',
				params: params,
			}),
			providesTags: ['DocumentPackages'],
			transformResponse: (response, _, arg) => {
				const DocumentPackages = Array.isArray(response?.data?.documentPackages)
					? response?.data?.documentPackages.map((document, idx) => ({
							...document,
							index:
								parseInt(arg?.params?.limit) * (parseInt(arg?.params?.page) - 1) +
								idx +
								1,
					  }))
					: []
				return {
					data: DocumentPackages,
					meta: response?.data?.meta,
				}
			},
		}),
		deleteDocumentPackage: builder.mutation({
			query: (id) => ({
				url: `${apiEndpoints.documentPackage}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['DocumentPackages'],
		}),
		createDocumentPackage: builder.mutation({
			query: (payload) => ({
				url: apiEndpoints.documentPackage,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['DocumentPackages'],
		}),
		updateDocumentPackage: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${apiEndpoints.documentPackage}/${id}`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['DocumentPackages'],
		}),
		getDocumentPackageDetails: builder.query({
			query: (id) => ({
				url: `${apiEndpoints.documentPackage}/${id}`,
				method: 'GET',
			}),
			invalidatesTags: ['DocumentPackages'],
		}),
	}),
})

export const {
	useLazyGetDocumentPackagesQuery,
	useDeleteDocumentPackageMutation,
	useCreateDocumentPackageMutation,
	useUpdateDocumentPackageMutation,
	useLazyGetDocumentPackageDetailsQuery,
} = documentPackageApi
