import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useDebounce } from 'use-debounce'

import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery, useUser } from 'hooks'
import { toQueryString } from 'utils/commonFunctions'
import { formatDate } from '../task-form'

const useKanbanQuery = () => {
	const values = useSelector((state) => state.form?.kanban_filter?.values)
	const [debounceSearch] = useDebounce(values?.search, 600)
	const { team, user } = useUser()

	const filters = useMemo(() => {
		const obj = { team_id: team?.id }
		if (debounceSearch) {
			Object.assign(obj, { search: debounceSearch })
		}

		if (values?.due_date) {
			Object.assign(obj, { due_date: formatDate(values?.due_date) })
		}

		if (values?.has_attachment) {
			Object.assign(obj, { has_attachment: values?.has_attachment })
		}

		if (values?.mytask) {
			Object.assign(obj, { assignee_id: user?.id })
		}

		return obj
	}, [values, debounceSearch])

	const clientsQuery = useMemo(() => {
		if (Array.isArray(values?.client) && values?.client?.length > 0) {
			const query = values?.client.map((client) => `client_id=${client}`).join('&')
			return `&${query}`
		} else {
			return ''
		}
	}, [values?.client])

	const workflowsQuery = useMemo(() => {
		if (Array.isArray(values?.workflow) && values?.workflow?.length > 0) {
			const query = values?.workflow.map((workflow) => `workflow_id=${workflow}`).join('&')
			return `&${query}`
		} else {
			return ''
		}
	}, [values?.workflow])

	const assigneeQuery = useMemo(() => {
		if (Array.isArray(values?.assignee_id) && values?.assignee_id?.length > 0) {
			const query = values?.assignee_id.map((assignee) => `assignee_id=${assignee}`).join('&')
			return `&${query}`
		} else {
			return ''
		}
	}, [values?.assignee_id])

	const tagQuery = useMemo(() => {
		if (Array.isArray(values?.tag_id) && values?.tag_id?.length > 0) {
			const query = values?.tag_id.map((tag) => `tag_id=${tag}`).join('&')
			return `&${query}`
		} else {
			return ''
		}
	}, [values?.tag_id])

	const query = toQueryString(filters)
	const URL = `${apiEndpoints.task}/card-view?${query}${assigneeQuery}${clientsQuery}${workflowsQuery}${tagQuery}`
	const { data, isLoading, mutate } = useQuery(URL)

	return {
		columns: data?.data || [],
		isLoading,
		mutate,
	}
}

export default useKanbanQuery
