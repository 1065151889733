import { useHistory } from 'react-router-dom'

export const useRedirectBasedOnType = () => {
	const history = useHistory()

	const redirectBasedOnType = (type, path) => {
		const validPath = path ? (path.startsWith('/') ? path : `/${path}`) : null
		if (validPath) {
			switch (type) {
				case 'team_owner_invitation':
					window.location.href = validPath
					break
				case 'review_needed':
					history.push(validPath)
					break
				case 'corrections_needed':
					history.push(validPath)
					break
				case 'client_onboarding_submission_review':
					history.push(validPath)
					break
				case 'new_workflow_assigned':
					history.push(validPath)
					break
				case 'invitation_to_join':
					window.location.href = validPath
					break
				default:
					break
			}
		}
	}

	return redirectBasedOnType
}
