import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import { useLazyGetNotificationsQuery } from '../notification-api'

export const useNotifications = () => {
	const history = useHistory()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination
	const parseQueryString = getParseQueryString(location?.search)

	useEffect(() => {
		if (pagination) {
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			if (history.location.pathname === '/notifications') {
				history.push(`${window.location.pathname}?${queryString}`)
			}
		}
	}, [pagination])

	const [fetchNotifications, { data, isLoading, isError }] = useLazyGetNotificationsQuery()

	useEffect(() => {
		fetchNotifications({
			page: pageIndex + 1,
			limit: pageSize,
		})
	}, [fetchNotifications, pagination])

	return { data, isLoading, isError, setPagination }
}
