import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import DeleteConfirmAlert from 'theme/ui/delete-confirm-alert'
import ToolTip from 'theme/ui/tool-tip'
import { isAllowTo } from 'utilities/helpers'
import notification from 'utilities/notification'
import { permissions } from 'utilities/permissions'
import { useDeleteClientMutation, useSendClientInvitationMutation } from '../client-apis'
import { OnBoardStatuses } from '../invited-clients'
import { CLIENT_APP_ROUTES } from '../routes'

const Actions = ({ client }) => {
	const [sendInvite] = useSendClientInvitationMutation()
	const isReadPermission = isAllowTo(permissions?.client?.view)
	const isDeletePermission = isAllowTo(permissions?.client?.delete)
	const [isOpen, setIsOpen] = useState(false)
	const onClose = () => setIsOpen(false)
	const ID = client?.id
	const [deleteClient, { isFetching }] = useDeleteClientMutation()

	const onConfirm = () => {
		deleteClient(client?.id).then((response) => {
			if (response?.data?.status === 200) {
				notification('success', response?.data?.message)
			}
		})
	}

	const onResend = async (user) => {
		try {
			const payload = {
				first_name: user?.first_name,
				last_name: user?.last_name,
				email: user?.email,
				group_id: user?.group?.id,
				resend: true,
			}
			const response = await sendInvite(payload)
			if (response?.data?.status === 200) {
				notification('success', response?.data?.message)
			}
		} catch (error) {
			notification('error', error?.message)
		}
	}

	return (
		<div className='mx-auto w-[130px]'>
			<DeleteConfirmAlert
				isDeleting={isFetching}
				isOpen={isOpen}
				onClose={onClose}
				onConfirm={onConfirm}
			/>

			<div className='flex justify-end space-x-2'>
				{client?.status === OnBoardStatuses?.PENDING ? (
					<Fragment>
						<ToolTip
							targetId={`resend-invitation-${ID}`}
							tooltipText='Resend Invitation'
							placement='top'
						/>
						<button
							id={`resend-invitation-${ID}`}
							onClick={() => onResend(client)}
							type='button'
							className='action-btn btn-gray-outline'>
							<i className='bx bx-send' />
						</button>
					</Fragment>
				) : undefined}

				{[OnBoardStatuses.RE_SUBMITTED, OnBoardStatuses.SUBMITTED].includes(
					client?.status,
				) ? (
					<Fragment>
						<ToolTip
							targetId={`client-verify-${ID}`}
							tooltipText='Verify Information'
							placement='top'
						/>
						<Link
							id={`client-verify-${ID}`}
							to={CLIENT_APP_ROUTES.verifyClient(client?.id)}
							className='action-btn btn-primary-outline'>
							<i className='mdi mdi-magnify-scan' />
						</Link>
					</Fragment>
				) : undefined}

				{isReadPermission ? (
					<Fragment>
						<ToolTip
							targetId={`client-view-${ID}`}
							tooltipText='View Details'
							placement='top'
						/>
						<Link
							id={`client-view-${ID}`}
							to={`/clients/${client?.id}`}
							className='action-btn btn-primary-outline'>
							<i className='uil uil-eye' style={{ fontSize: 16 }} />
						</Link>
					</Fragment>
				) : undefined}

				{isDeletePermission ? (
					<Fragment>
						<ToolTip
							targetId={`client-delete-${ID}`}
							tooltipText='Delete'
							placement='top'
						/>
						<button
							id={`client-delete-${ID}`}
							type='button'
							className='action-btn btn-red-outline'
							onClick={() => setIsOpen(true)}>
							<i className='uil-trash-alt' style={{ fontSize: 16 }} />
						</button>
					</Fragment>
				) : undefined}
			</div>
			{/* {isActionActive ? (
				<Fragment></Fragment>
			) : (
				<span className=' text-sm !text-gray-700'>{client?.updated_at}</span>
			)} */}
		</div>
	)
}

export default Actions
