import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container } from 'reactstrap'
import AppLayout from 'theme/layouts/app-layout'
import apiEndpoints from '../../helpers/apiEndpoints'
import * as actions from '../../store/common/actions'
import { showSuccessToast } from '../../utils/commonFunctions'
import UnAuthorize from '../StaticPages/UnAuthorize'
import TeamUserForm from './components/TeamUserForm'

const TeamUserManage = (props) => {
	const { match, history, location } = props
	const state = location.state
	const team = state && state.team ? state.team : {}
	const [loading, setLoading] = useState(false)
	const [user, setUser] = useState(null)
	const dispatch = useDispatch()
	const userId = match.params.id
	const team_name = team && team.name
	const isSuperAdmin = useSelector((state) => state.Permissions.isSuperAdmin)

	useEffect(() => {
		if (team_name) {
			setUser({ ...user, team_name, new_user: 'YES' })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [team_name])

	useEffect(() => {
		if (userId && isSuperAdmin) {
			setLoading(true)
			dispatch(actions.find(apiEndpoints.users, userId)).then((response) => {
				setLoading(false)
				if (response && response.status !== 200) {
					history.push({ pathname: '/teams/users', state: team && team.id })
				}
				if (response && response.status === 200) {
					const { first_name, last_name, phone_number, email } = response.data
					setUser({ first_name, last_name, phone_number, email, team_name })
				}
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId])

	const onSubmit = (values) => {
		setLoading(true)
		values['team_id'] = team && team.id
		let formValues = {}
		const group_id = values.group_id && values.group_id.value
		if (values.new_user === 'NO') {
			values['user_id'] = values.user_id.value
			formValues = {
				team_id: values.team_id,
				user_id: values.user_id,
				group_id,
			}
		} else {
			formValues = {
				team_id: values.team_id,
				first_name: values.first_name,
				last_name: values.last_name,
				user_id: 0,
				email: values.email,
				group_id,
			}
		}
		const { first_name, last_name, phone_number } = values
		const action = userId
			? actions.update(apiEndpoints.users, userId, {
					first_name,
					last_name,
					phone_number,
			  })
			: actions.store(apiEndpoints.user.inviteUser, formValues)
		dispatch(action).then((response) => {
			setLoading(false)
			if (response && response.status === 200) {
				showSuccessToast(response.message)
				history.push({
					pathname: '/teams/users',
					state: team && team.id,
				})
			}
		})
	}

	return (
		<AppLayout
			meta={{
				title: 'Manage Team User',
			}}>
			{isSuperAdmin ? (
				<div className='page-content'>
					<Container fluid>
						<TeamUserForm
							userId={userId}
							team={team}
							loading={loading}
							initialValues={user}
							onSubmit={onSubmit}
						/>
					</Container>
				</div>
			) : (
				<UnAuthorize />
			)}
		</AppLayout>
	)
}

export default TeamUserManage
