import { createColumnHelper } from '@tanstack/react-table'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import DataTable from 'theme/ui/data-table'
import Actions from './actions'
import FiltersForm from './filters-form'
import systemFieldsListQuery from './system-fields-list-query'
import TeamsPopover from './teams-popover'

const SystemFieldsListing = () => {
	const [actionActiveId, setActionActiveId] = useState(null)
	const onMouseEnter = (id) => setActionActiveId(id)
	const onMouseLeave = () => setActionActiveId(null)

	const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 })

	const { fields, isLoading, meta, mutate } = systemFieldsListQuery(pagination)
	const columnHelper = createColumnHelper()

	const columns = [
		columnHelper.accessor('index', {
			id: 'index',
			size: 50,
			header: () => <span>#</span>,
			cell: (info) => <span>{info.getValue()}.</span>,
		}),

		columnHelper.accessor((row) => row, {
			id: 'field_name',
			header: () => <span>Field Name</span>,
			cell: (info) => (
				<div className='flex flex-col'>
					<span className='line-clamp-1 max-w-[180px] text-sm font-bold !text-gray-700'>
						{info.getValue()?.field_name}
					</span>
					{info.getValue()?.is_system_field ? (
						<span className='rounded text-xs text-main'>(System Field)</span>
					) : undefined}
				</div>
			),
		}),

		columnHelper.accessor((row) => row.teams, {
			id: 'teams',
			header: () => <span>Teams</span>,
			cell: (info) => {
				const first_team = info.getValue()[0]

				return (
					<span className='max-w-[180px]'>
						{info.getValue()?.length > 0 ? (
							<div className='flex flex-wrap gap-2'>
								<span className='rounded !border border-main px-2 py-1 text-xs font-semibold text-main'>
									{first_team?.team_name}
								</span>
								{info.getValue()?.length > 1 ? (
									<TeamsPopover teams={info.getValue()} />
								) : undefined}
							</div>
						) : (
							<p className='m-0 text-xs text-gray-500'>No team assigned</p>
						)}
					</span>
				)
			},
		}),

		columnHelper.accessor((row) => row.created_by, {
			id: 'created_by',
			header: () => <span>Created by</span>,
			cell: (info) => {
				const full_name = `${info.getValue()?.first_name} ${info.getValue()?.last_name}`
				const id = info.getValue()?.id
				return (
					<span className='max-w-[180px] text-sm !text-gray-700'>
						<Link to={`/users/${id}/show`} className='font-semibold hover:!underline'>
							{full_name}{' '}
						</Link>
					</span>
				)
			},
		}),

		columnHelper.accessor((row) => row, {
			id: 'created_at',
			size: 180,
			header: () => <span>Created at</span>,
			cell: (info) => (
				<Actions
					key={info.getValue()?.id}
					field={info.getValue()}
					created_at={info.getValue()?.created_at}
					isActionActive={actionActiveId === info?.row?.id}
					mutate={mutate}
				/>
			),
		}),
	]

	return (
		<div>
			<FiltersForm />
			<DataTable
				columns={columns}
				data={fields}
				isLoading={isLoading}
				pagination={pagination}
				setPagination={setPagination}
				meta={meta}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			/>
		</div>
	)
}

export default SystemFieldsListing
