import PropTypes from 'prop-types';
import React from "react";
import { withTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";

import {
	changeSidebarType, showRightSidebarAction,
	toggleLeftmenu
} from "../../store/actions";
import ClientOnBoardingNavBar from './ClientOnBoardingNavBar';

const Header = () => {
	const { isSuperAdmin } = useSelector(state => state.Permissions)

	if (isSuperAdmin) {
		return <Redirect to={{ pathname: '/portfolio' }} />
	}

	return (
		<React.Fragment>
			<ClientOnBoardingNavBar />
		</React.Fragment>
	);
};

Header.propTypes = {
	changeSidebarType: PropTypes.func,
	leftMenu: PropTypes.any,
	leftSideBarType: PropTypes.any,
	showRightSidebar: PropTypes.any,
	showRightSidebarAction: PropTypes.func,
	t: PropTypes.any,
	toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
	const {
		layoutType,
		showRightSidebar,
		leftMenu,
		leftSideBarType,
	} = state.Layout;
	return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default withRouter(connect(mapStatetoProps, {
	showRightSidebarAction,
	toggleLeftmenu,
	changeSidebarType,
})(withTranslation()(Header)));
