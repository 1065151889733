import { Fragment } from "react"

import image1 from 'assets/images/onboarding/step-bottom-1.svg'
import image2 from 'assets/images/onboarding/step-bottom-2.svg'
import image3 from 'assets/images/onboarding/step-bottom-3.svg'
import image4 from 'assets/images/onboarding/step-bottom-4.svg'

const LeftSidebarBottomImage = () => {
    const currentPath = window.location.pathname.split('/')[2]
    let src = null

    if (currentPath === 'required-document-list') {
        src = image1
    }
    if (currentPath === 'input-sin-number') {
        src = image2
    }

    if (['upload-documents', 'verify-information'].includes(currentPath)) {
        src = image3
    }
    if (currentPath === 'take-selfie') {
        src = image4
    }

    return (
        <Fragment>
            {src ? (
                <div className="flex justify-end">
                    <img src={src} alt="" className="h-40" />
                </div>
            ) : undefined}
        </Fragment>
    )
}

export default LeftSidebarBottomImage