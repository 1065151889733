export const columns1 = [
	{
		id: 1,
		title: 'TO DO LIST',
		cards: [
			{
				id: 1,
				title: 'Add card',
				description: 'Add capability to add a card in a column',
			},
		],
	},
	{
		id: 2,
		title: 'IN PROCESS',
		cards: [
			{
				id: 2,
				title: 'Drag-n-drop support',
				description: 'Move a card between the columns',
			},
		],
	},
	{
		id: 3,
		title: 'IN REVIEW',
		cards: [
			{
				id: 2,
				title: 'Drag-n-drop support',
				description: 'Move a card between the columns',
			},
		],
	},
	{
		id: 4,
		title: 'COMPLETED',
		cards: [
			{
				id: 2,
				title: 'Drag-n-drop support',
				description: 'Move a card between the columns',
			},
		],
	},
]

export const COLUMNS = {
	TO_DO_LIST: 'To Do',
	IN_PROCESS: 'In Process',
	IN_REVIEW: 'In Review',
	COMPLETED: 'Completed',
}

export { default as KanbanView } from './KanbanView'
