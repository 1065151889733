import { useState } from 'react'
import { TableContext } from './table-context'
import { TableContextUpdater } from './table-context-updater'

export const TableProvider = ({ children }) => {
	const [table, setTable] = useState(null)

	const onSetTable = (value) => {
		setTable(value)
	}

	return (
		<TableContext.Provider value={table}>
			<TableContextUpdater.Provider value={onSetTable}>
				{children}
			</TableContextUpdater.Provider>
		</TableContext.Provider>
	)
}
