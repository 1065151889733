import { useMemo } from 'react'
import { useDebounce } from 'use-debounce'

import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import { createAdvisorOption } from '.'

const ReviewersQuery = (search, formValues, advisor_reviewer, fieldName) => {
	const [debounceSearch] = useDebounce(search, 200)

	const URL = useMemo(
		() =>
			debounceSearch
				? `${apiEndpoints.advisors}?page=1&limit=10&search=${debounceSearch}`
				: `${apiEndpoints.advisors}?page=1&limit=10`,
		[debounceSearch],
	)
	const { data, isLoading } = useQuery(URL)

	const reviewers = data?.data[0] || []

	const options = useMemo(
		() =>
			reviewers.map((reviewer) =>
				createAdvisorOption(reviewer, formValues, advisor_reviewer, fieldName),
			),
		[reviewers],
	)

	return {
		advisors: options,
		isLoading,
	}
}

export default ReviewersQuery
