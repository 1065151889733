import { Disclosure } from '@headlessui/react'
import classNames from 'classnames'
import moment from 'moment'
import { Fragment, useState } from 'react'

import { ReactComponent as FolderIcon } from 'assets/svg/folder-icon.svg'
import Actions from '../actions'
import TableRowBody from './table-row-body'

const TableRow = ({ folder }) => {
	const [isActionActive, setIsActionActive] = useState(false)

	return (
		<Disclosure as={'div'}>
			{({ open }) => (
				<Fragment>
					<Disclosure.Button
						onMouseEnter={() => setIsActionActive(true)}
						onMouseLeave={() => setIsActionActive(false)}
						as='button'
						className={classNames(
							'flex w-full justify-between border-b px-3 py-2.5',
							open ? 'bg-[#E7EDFF]' : 'bg-white',
							{
								'shadow-[0px_2px_4px_0px_rgba(15,34,58,0.12)]': isActionActive,
							},
						)}>
						<div className='flex w-full items-center justify-between'>
							<div className='inline-flex items-center space-x-1'>
								{open ? (
									<i className='uil-angle-down mt-1 align-middle text-[15px]'></i>
								) : (
									<i className='uil-angle-right mt-1 align-middle text-[15px]'></i>
								)}
								<input type='checkbox' name='' className='form-check-input' id='' />
								<div className='flex items-center space-x-2'>
									<FolderIcon height={15} />
									<span className='my-1 ml-2 text-sm font-medium'>
										{folder?.title}
									</span>
								</div>
							</div>
							<div>2 Folders, 12 Files</div>
							<span className='text-[13px]'>
								{moment(folder?.last_edit).calendar()}
							</span>
							<Actions isActionActive={isActionActive} />
						</div>
					</Disclosure.Button>
					<Disclosure.Panel as='div' className='px-3 py-2'>
						<TableRowBody data={folder.children} />
					</Disclosure.Panel>
				</Fragment>
			)}
		</Disclosure>
	)
}

export default TableRow
