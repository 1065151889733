import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { useCopyToClipboard } from 'usehooks-ts'

const PublicLinkPopup = ({ isOpen, onClose, link, copied, setCopied }) => {
	const [_value, copy] = useCopyToClipboard()

	return (
		<Transition
			show={isOpen}
			enter='transition duration-100 ease-out'
			enterFrom='transform scale-95 opacity-0'
			enterTo='transform scale-100 opacity-100'
			leave='transition duration-75 ease-out'
			leaveFrom='transform scale-100 opacity-100'
			leaveTo='transform scale-95 opacity-0'
			as='div'>
			<Dialog as='div' open={true} onClose={() => {}} className='popup-overlay relative'>
				<div className='fixed inset-0 bg-black/30' aria-hidden='true' />
				<div className='fixed inset-0 flex w-screen items-center justify-center p-4'>
					<Dialog.Panel className='w-[500px] rounded-md bg-white'>
						<div className='rounded-t px-8 py-4'>
							<div className='mx-auto divide-y'>
								<div className='flex items-start justify-between'>
									<div className='flex flex-col'>
										<h1 className='m-0 text-lg font-bold'>Share Workflow</h1>
										<p className='text-sm text-gray-500'>
											Invite people and collaborate on your project.
										</p>
									</div>
									<button type='button' className='' onClick={onClose}>
										<i className='fas fa-times text-lg' />
									</button>
								</div>
								<div className='pt-4'>
									<div className='mb-3 flex items-center space-x-3'>
										<span className='rounded !border bg-gray-100 px-2 py-0.5'>
											<i className='bx bx-globe mt-1 text-[17px]' />
										</span>
										<div className='flex flex-col'>
											<h4 className='m-0 text-sm font-bold'>Public access</h4>
											<p className='m-0 text-xs text-gray-500'>
												Anyone with the link can view
											</p>
										</div>
									</div>

									<div className='mx-auto flex items-center'>
										<div className='w-full'>
											<div className='relative w-full min-w-[100px]'>
												<input
													type='email'
													id='workflow-link'
													placeholder='Enter to copy'
													className='form-control !rounded-r-none !border-r-0 read-only:bg-gray-100'
													readOnly={true}
													value={link}
												/>
											</div>
										</div>
										<button
											data-clipboard-target='#workflow-link'
											className={classNames(
												'h-[38px]  !rounded !rounded-l-none !border  border-gray-300 bg-transparent px-2.5 py-1  hover:!bg-gray-100 active:!bg-gray-300 disabled:cursor-not-allowed disabled:!opacity-50',
												copied ? 'text-green-600' : 'text-gray-700',
											)}
											type='button'
											onClick={() => {
												copy(link)
												setCopied(true)
											}}>
											{copied ? (
												<div className='flex items-center space-x-2'>
													<span>
														<i className='uil-check' />
													</span>
													<span>Copied</span>
												</div>
											) : (
												<div className='flex items-center space-x-2'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														fill='none'
														viewBox='0 0 24 24'
														strokeWidth='1.5'
														stroke='currentColor'
														aria-hidden='true'
														className='h-4 w-4 text-gray-700'>
														<path
															strokeLinecap='round'
															strokeLinejoin='round'
															d='M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75'></path>
													</svg>
													<span>Copy</span>
												</div>
											)}
										</button>
									</div>
								</div>
							</div>
						</div>
					</Dialog.Panel>
				</div>
			</Dialog>
		</Transition>
	)
}

export default PublicLinkPopup
