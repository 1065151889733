import DocumentPreview from 'components/Common/DocumentPreview'
import { Fragment, useState } from 'react'

const ClientInfo = ({ client }) => {
	const [isOpen, setIsOpen] = useState(false)
	const [url, setUrl] = useState(null)

	const toggle = async (url) => {
		setIsOpen(!isOpen)
		setUrl(url)
	}

	return (
		<Fragment>
			<DocumentPreview url={url} toggle={toggle} isOpen={isOpen} />
			<div>
				{/* <div className='mb-4 rounded-lg bg-white'>
					<div className='bg-[#F2F5FF] p-4 py-3'>
						<h5 className='font-bold'>Personal Information</h5>
					</div>
					<Row className='m-0 border-b p-3'>
						<Col xs={6}>
							<span className='text-gray-500'>Full Name</span>
						</Col>
						<Col xs={6}>
							<strong>
								{client?.first_name} {client?.last_name}
							</strong>
						</Col>
					</Row>
					<Row className='m-0 border-b p-3'>
						<Col xs={6}>
							<span className='text-gray-500'>E-Mail</span>
						</Col>
						<Col xs={6}>
							<strong>{client?.email}</strong>
						</Col>
					</Row>
					<Row className='m-0 p-3'>
						<Col xs={6}>
							<span className='text-gray-500'>Contact Phone</span>
						</Col>
						<Col xs={6}>
							<strong>{client?.phone_number}</strong>
						</Col>
					</Row>
				</div> */}
			</div>
		</Fragment>
	)
}

export default ClientInfo
