import classNames from 'classnames'
import moment from 'moment'
import { Fragment, useMemo } from 'react'

import { ReactComponent as FolderIcon } from 'assets/svg/folder-icon.svg'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import Badge from 'theme/ui/badge'
import { FILE_MANAGER_ROUTES } from '../routes'

const RecentFolders = () => {
	const recentFolders = useMemo(
		() => [
			{
				name: 'Readymade Templates',
				numberOfFiles: 12,
				accessed_at: '2024-11-05T04:07:25.000Z',
				accessed_by: [],
				isSuperadmin: true,
			},
			{
				name: 'RBC Bank',
				numberOfFiles: 235,
				accessed_at: '2024-11-04T04:07:25.000Z',
				accessed_by: new Array(2).fill(0),
				isSuperadmin: false,
			},
			{
				name: 'Applications',
				numberOfFiles: 20,
				accessed_at: '2024-11-04T04:07:25.000Z',
				accessed_by: new Array(2).fill(0),
				isSuperadmin: false,
			},
		],
		[],
	)

	return (
		<div>
			<h3 className='mb-3 text-base font-semibold'>Folders</h3>
			<div className='grid grid-cols-3 gap-2.5'>
				{recentFolders.map((folder, idx) => (
					<div
						key={idx}
						className={classNames('rounded bg-white p-3 shadow', {
							'!border-l-2 border-main': folder?.isSuperadmin,
						})}>
						<div className='flex h-full flex-col justify-between space-y-3'>
							<div className='flex items-center justify-between'>
								<span>
									<FolderIcon />
								</span>
								{folder?.isSuperadmin ? (
									<Badge rounded={false}>By Experivise Admin</Badge>
								) : (
									<Fragment>
										{Array.isArray(folder?.accessed_by) ? (
											<div className='flex -space-x-4 rtl:space-x-reverse'>
												{folder.accessed_by.map((access, idx) => (
													<img
														key={idx}
														className='h-10 w-10 rounded-full border-2 border-white dark:border-gray-800'
														src='https://flowbite.com/docs/images/people/profile-picture-2.jpg'
														alt=''
													/>
												))}
											</div>
										) : undefined}
									</Fragment>
								)}
							</div>
							<div className='flex flex-col'>
								<span className='text-[15px] font-semibold'>{folder?.name}</span>
								<div
									className='flex items-center justify-between
                                    '>
									<span className='text-sm font-normal text-gray-400'>
										{folder?.numberOfFiles} Files
									</span>
									<span className='text-[13px] font-normal text-gray-400'>
										{moment(folder?.accessed_at).fromNow()}
									</span>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
			<Link className='link float-end mb-2 mt-3' to={FILE_MANAGER_ROUTES.allFolders()}>
				<span>View All Folders</span>
				<i className='uil-angle-down'></i>
			</Link>
		</div>
	)
}

export default RecentFolders
