import { Field } from 'formik'
import { useMemo, useState } from 'react'
import { SelectField } from 'theme/ui/forms'
import { creatWorkflowOptionLabel } from '.'
import WorkflowOptionsQuery from './WorkflowOptionsQuery'

const WorkflowDropdown = ({ formValues }) => {
	const [search, setSearch] = useState('')
	const clients = useMemo(
		() =>
			Array.isArray(formValues?.client_ids)
				? formValues?.client_ids
						?.filter((client) => client?.value !== undefined)
						?.map((client) => client?.value)
				: [],
		[formValues],
	)
	const { workflowOptions, isLoading } = WorkflowOptionsQuery(search, clients)

	const filterOption = ({ data }, search) => {
		if (
			data?.title?.toLowerCase()?.includes(search?.toLowerCase()) ||
			(typeof data?.label === 'string' &&
				data.label.toLowerCase().includes(search.toLowerCase()))
		) {
			return true
		} else {
			return false
		}
	}
	return (
		<Field
			label='Workflow'
			name='workflow_id'
			type='select'
			isLoading={isLoading}
			onInputChange={(value) => setSearch(value)}
			placeholder='Select workflow'
			options={workflowOptions}
			component={SelectField}
			getOptionLabel={(option) => creatWorkflowOptionLabel(option)}
			filterOption={filterOption}
		/>
	)
}

export default WorkflowDropdown
