import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'store/axios-base-query'

const API_URL = '/file'

export const fileApi = createApi({
	reducerPath: 'Files',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['File'],
	endpoints: (builder) => ({
		uploadFile: builder.mutation({
			query: ({ payload, onUploadProgress }) => ({
				url: API_URL,
				method: 'POST',
				data: payload,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				onUploadProgress: onUploadProgress,
			}),
		}),
		getFile: builder.query({
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'GET',
			}),
			invalidatesTags: ['File'],
		}),
		deleteFile: builder.mutation({
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['File'],
		}),
	}),
})

export const { useDeleteFileMutation, useLazyGetFileQuery, useUploadFileMutation } = fileApi
