import { useState } from 'react'

import { useAppRouter } from 'hooks'
import { useDispatch } from 'react-redux'
import * as actions from 'store/file-manager/actions'
import RenamePopup from '../rename-popup'
import OptionsList from './option-list'

const Actions = ({ isActionActive, document }) => {
	const [isOpen, setIsOpen] = useState(false)
	const dispatch = useDispatch()
	const { navigate } = useAppRouter()

	const attachInWorkflow = (data, type) => {
		const item = {
			title: data?.title,
			value: JSON.stringify({
				id: data?.id,
				minClients: data?.minClients,
			}),
		}
		if (type === 'folder') {
			dispatch(actions.setSelectedFolders(item))
			navigate(`/workflows/create?folder=true`)
		} else {
			dispatch(actions.setSelectedDocuments(item))
			navigate(`/workflows/create?document=true`)
		}
	}

	return (
		<div className='float-right w-[150px]'>
			<RenamePopup document={document} isOpen={isOpen} onClose={() => setIsOpen(false)} />
			{isActionActive ? (
				<div className='flex w-full items-center space-x-2'>
					<span
						className='text-sm font-medium text-main hover:cursor-pointer'
						onClick={() => attachInWorkflow(document, 'document')}>
						<span>Use in Workflow</span>
						<i className='uil-angle-right text-base '></i>
					</span>
					<OptionsList document={document} onRenameOpen={() => setIsOpen(true)} />
				</div>
			) : undefined}
		</div>
	)
}

export default Actions
