import { createContext, useContext, useState } from 'react'

const stepStatusQueryOptionsContext = createContext()
const StepStatusQueryOptionsUpdateContext = createContext()

export const useStepStatusQueryOptions = () => {
	return useContext(stepStatusQueryOptionsContext)
}

export const useStepStatusQueryOptionsUpdate = () => {
	return useContext(StepStatusQueryOptionsUpdateContext)
}

export const StepStatusQueryOptionsProvider = ({ children }) => {
	const [options, setOptions] = useState({
		revalidateIfStale: false,
		revalidateOnFocus: false,
		revalidateOnReconnect: false,
	})

	const changeOptions = (value) => {
		setOptions({
			revalidateIfStale: value,
			revalidateOnFocus: value,
			revalidateOnReconnect: value,
		})
	}

	return (
		<stepStatusQueryOptionsContext.Provider value={options}>
			<StepStatusQueryOptionsUpdateContext.Provider value={changeOptions}>
				{children}
			</StepStatusQueryOptionsUpdateContext.Provider>
		</stepStatusQueryOptionsContext.Provider>
	)
}
