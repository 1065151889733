import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

const IsAuthenticated = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const { loggedIn, user } = useSelector(state => state.jwtauth)
    const loggedInFlag = loggedIn && user

    useEffect(() => {
        if (loggedInFlag) {
            setIsLoggedIn(true)
        } else {
            setIsLoggedIn(false)
        }
    }, [loggedInFlag])


    return isLoggedIn
}

export default IsAuthenticated