import { capitalize } from 'lodash'
import { useState } from 'react'

import { statuses } from 'modules/workflows'
import { getReviewers } from 'modules/workflows/workflow-review/helpers'
import { formatDate, isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'
import Actions from './actions'
import './workflow-table.css'

const WorkflowTable = ({ workflows, team }) => {
	const [actionActiveId, setActionActiveId] = useState(null)
	const onMouseEnter = (id) => setActionActiveId(id)
	const onMouseLeave = () => setActionActiveId(null)

	return (
		<table className='workflows-outstandings'>
			<tbody>
				{workflows.map((workflow, idx) => {
					const finalReviewer = workflow?.workflowFinalReviewer?.finalReviewer
					const status = statuses.find((status) => status?.name === workflow?.status)
					const reviewers = getReviewers(workflow?.reviewerMaps || [])

					return (
						<tr
							key={idx}
							onMouseEnter={onMouseEnter ? () => onMouseEnter(idx) : null}
							onMouseLeave={onMouseLeave}>
							{/* <td className='w-[40px]'>
								<span>{idx + 1}.</span>
							</td> */}
							<td className='w-[160px]'>
								<div className='flex flex-col '>
									<div className='flex items-center space-x-2'>
										<span
											className='line-clamp-1 max-w-fit font-ibmplex font-bold'
											title={`${workflow?.user?.first_name} ${workflow?.user?.last_name}`}>{`${workflow?.user?.first_name} ${workflow?.user?.last_name}`}</span>
									</div>

									<span className='text-xs text-gray-500'>
										{capitalize(workflow?.user_type)}
									</span>
								</div>
							</td>
							<td className='w-[120px]'>
								<span>{formatDate(workflow?.created_at)}</span>
							</td>
							<td className='w-[130px]'>
								{finalReviewer ? (
									<span key={idx} className='text-sm font-semibold'>
										{finalReviewer?.first_name} {finalReviewer?.last_name}
									</span>
								) : (
									<p className='text-center'>-</p>
								)}
							</td>
							<td>
								{reviewers.length > 0 ? (
									<div className='flex flex-wrap gap-2'>
										{reviewers.map((reviewer, idx) => (
											<span
												key={idx}
												className='rounded !border border-zinc-300 bg-zinc-100 px-2 py-1 text-xs font-semibold'>
												{reviewer?.full_name}
											</span>
										))}
									</div>
								) : (
									<p className='text-center'>-</p>
								)}
							</td>
							<td className='w-40'>
								<span>{team?.team_name}</span>
							</td>
							<td className='w-[220px] !max-w-[220px] px-3 py-2'>
								{workflow?.status === 'Cancelled' ? (
									<div className='flex items-center space-x-2'>
										<span
											style={{
												backgroundColor: status?.color || '#74788D',
											}}
											className=' !h-3 !w-3 rounded-full'
										/>
										<span>{workflow?.status}</span>
									</div>
								) : (
									<>
										{actionActiveId === idx &&
										isAllowTo(permissions?.workflowOutstandings?.view) ? (
											<Actions
												workflow={workflow}
												user_type={workflow?.user_type}
											/>
										) : (
											<div className='flex items-center space-x-2'>
												<span
													style={{
														backgroundColor: status?.color || '#74788D',
													}}
													className=' !h-3 !w-3 rounded-full'
												/>
												<span
													className='line-clamp-1'
													title={workflow?.status}>
													{workflow?.status}
												</span>
											</div>
										)}
									</>
								)}
							</td>
						</tr>
					)
				})}
			</tbody>
		</table>
	)
}

export default WorkflowTable
