import PropTypes from 'prop-types'
import { useState } from 'react'
import { Dropdown } from 'reactstrap'

import { withTranslation } from 'react-i18next'
import NotificationMenu from '../notification-menu/notification-menu'

const NotificationDropdown = () => {
	const [menu, setMenu] = useState(false)

	return (
		<>
			<Dropdown
				isOpen={menu}
				toggle={() => setMenu(!menu)}
				className='dropdown inline-block'
				tag='li'>
				<NotificationMenu setMenu={setMenu} menu={menu} />
			</Dropdown>
		</>
	)
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
	t: PropTypes.any,
}
