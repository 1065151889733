/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment } from "react"

import logo from "assets/images/experivise-logo-dark.png"
import lightBulb from "assets/images/onboarding/light-bulb.png"
import { fromQueryToObject } from "utils/commonFunctions"
import HelperCard from "../components/HelperCard"
import LeftSidebar from "../components/LeftSidebar"
import SelfieTokenExpired from "../components/SelfieTokenExpired"
import Form from "./Form"
import TokenValidateQuery from "./TokenValidateQuery"

const TakeSelfieFromPhone = (props) => {
    const query = fromQueryToObject(props?.location?.search) || {}
    const token = query?.token
    const { isValidating, istokenInvalid } = TokenValidateQuery(token)

    const helperItems = [
        {
            title: 'Why do we need your selfie?',
            description: 'We need to verify your face ID through the documents given by you with live selfie.',
            icon: <img src={lightBulb} alt="" className="w-6 h-6" />
        }
    ]

    return (
        <Fragment>
            {isValidating ? (
                <Fragment>
                    <div className="container-fluid h-screen flex items-center justify-center">
                        <h3 className="text-xl font-bold">
                            Loading...
                        </h3>
                    </div>
                </Fragment>
            ) : (
                <Fragment>
                    {istokenInvalid ? (
                        <SelfieTokenExpired />
                    ) : (
                        <div className="container-fluid bg-white">
                            <div className="row">
                                <div className="col-sm-3 bg-[#F5F7FF] sm:min-h-screen pt-0 px-0">
                                    <img
                                        src={logo}
                                        alt=""
                                        className="h-14 m-3"
                                    />
                                    <LeftSidebar />
                                </div>
                                <div className="col-sm-6 pt-20" id="take-selfie">
                                    <div className="grid grid-cols-1 sm:grid-cols-12">
                                        <div className="sm:col-span-9 px-2 flex flex-col space-y-9">
                                            <div className="sm:text-left text-center">
                                                <h3 className="text-xl font-bold">
                                                    Take a selfie
                                                </h3>
                                                <p className="text-[#74788D]">
                                                    Make it easy for us to recognize you by taking a selfie
                                                </p>
                                            </div>
                                            <Form
                                                token={token}
                                            // setIstokenInvalid={setIstokenInvalid}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3 pt-20 pb-3">
                                    <div className="px-4">
                                        <HelperCard items={helperItems} />
                                    </div>
                                </div>
                            </div >
                        </div >
                    )}
                </Fragment>
            )}
        </Fragment>


    )
}

export default TakeSelfieFromPhone