/* eslint-disable no-unused-vars */
import * as requestFromServer from './cruds'
import { PermissionSlices } from './slices'

const { actions } = PermissionSlices

export const all =
	(url, config = {}) =>
	(dispatch) => {
		dispatch(actions.startCall({}))

		return requestFromServer
			.get(url, config)
			.then((response) => {
				dispatch(actions.endCall({}))
				if (response.status !== 200) {
					dispatch(actions.catchError({ message: response.data.message }))
					return false
				}
				dispatch(actions.store({ data: response.data.data }))
				return response.data
			})
			.catch((error) => {
				handleCommonError(error, dispatch)
			})
	}

const handleCommonError = (error, dispatch) => {
	if (error.response) {
		dispatch(
			actions.catchError({
				message: `${error.response.data.message} | ${
					error.response.data.error && error.response.data.error
				}`,
			}),
		)
	} else {
		dispatch(actions.catchError({ message: error.message }))
	}
}

export const userPermissions = (payload) => (dispatch) => {
	dispatch(actions.startCall({}))
	return new Promise((resolve) => {
		dispatch(actions.setPermission(payload))
		dispatch(actions.endCall({}))
		resolve({ success: true, message: 'Permissions successfully saved' })
	})
}

export const setDefaultGroup = (payload) => (dispatch) => {
	dispatch(actions.startCall({}))

	return new Promise((resolve) => {
		dispatch(actions.setDefaultGroup(payload))
		dispatch(actions.endCall({}))
		resolve({ success: true, message: 'Team Switched', data: payload })
	})
}

export const createTeam =
	(url, payload, config = {}) =>
	(dispatch) => {
		dispatch(actions.startCall({}))

		return requestFromServer
			.store(url, payload, (config = {}))
			.then((response) => {
				dispatch(actions.endCall({}))
				if (response.status !== 200) {
					dispatch(actions.catchError({ message: response.data.message }))
					return false
				}
				const { id, team_name, firm_name, firm_address, status, deleted_at, created_at } =
					response.data.data
				dispatch(
					actions.setHasTeam({
						id,
						team_name,
						firm_name,
						firm_address,
						status,
						deleted_at,
						created_at,
					}),
				)
				return response.data
			})
			.catch((error) => {
				handleCommonError(error, dispatch)
			})
	}

export const resetPermissions = () => (dispatch) => {
	dispatch(actions.resetPermissions({}))
}
