import { ReactComponent as CloseIcon2 } from 'assets/svg/close-icon-2.svg'
import { ReactComponent as SubmitIcon } from 'assets/svg/workflow-submit-icon.svg'
import { useAppRouter } from 'hooks'
import { ASSIGN_WORKFLOW_APP_ROUTES } from 'modules/assigned-workflows/routes'

const WorkflowSubmitted = ({ onClose }) => {
	const { navigate } = useAppRouter()
	return (
		<div className='grid grid-cols-1 gap-4'>
			<div className='flex w-full justify-between rounded-2xl bg-[#e9f3e9] px-6 py-[21px]'>
				<div className='relative top-[5%]'>
					<span className='absolute'>
						<SubmitIcon />
					</span>
				</div>
				<button type='button' className='' onClick={onClose}>
					<CloseIcon2 />
				</button>
			</div>

			<div className='space-y-6 p-6'>
				<h3 className='text-2xl font-bold text-gray2-dark'>
					Workflow Submitted for Advisor’s Review!
				</h3>
				<p className='text-sm text-[#74788D]'>
					Your workflow has been submitted and progressed to the{' '}
					<span className='font-medium'>'In Review'</span> state where an Advisor will
					review it. If the Advisor finds any issues, you will be prompted to make
					corrections. Please allow up to 4 hours for feedback.
				</p>

				<p className='text-sm text-[#74788D]'>
					Once approved, it will move to the{' '}
					<span className='font-medium'>'In Final Review'</span> state for Supervisor
					review. You will receive email notifications for any future state changes.
				</p>

				<div className='mx-auto text-center'>
					<button
						className=' text-base font-bold text-main'
						onClick={() => navigate(ASSIGN_WORKFLOW_APP_ROUTES.findAll())}>
						Go to Home
					</button>
				</div>
			</div>
		</div>
	)
}

export default WorkflowSubmitted
