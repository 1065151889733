import { createApi } from '@reduxjs/toolkit/query/react'
import apiEndpoints from 'helpers/apiEndpoints'
import axiosBaseQuery from 'store/axios-base-query'
const API_URL = apiEndpoints.settings

export const settingsApi = createApi({
	reducerPath: 'Settings',
	refetchOnFocus: true,
	keepUnusedDataFor: 0,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['Settings'],
	endpoints: (builder) => ({
		getSettings: builder.query({
			keepUnusedDataFor: 0,
			query: (params) => ({
				url: API_URL,
				method: 'GET',
				params: params,
			}),
			providesTags: ['Settings'],
			transformResponse: (response, _, arg) => {
				const settingsData = Array.isArray(response.data.systemSettings)
					? response.data?.systemSettings?.map((setting, idx) => ({
							ind: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + idx + 1,
							id: setting?.id,
							setting_key: setting?.setting_key,
							setting_value: setting?.setting_value,
							data_type: setting?.data_type,
							description: setting?.description,
					  }))
					: []

				return {
					settingsData,
					meta: response?.data?.meta,
				}
			},
		}),
		getSetting: builder.query({
			keepUnusedDataFor: 0,
			query: (key) => ({
				url: `${API_URL}/${key}/detail`,
				method: 'GET',
			}),
			providesTags: ['Settings'],
			transformResponse: (response) => {
				const setting = response
				return {
					setting,
				}
			},
		}),
		updateSystemSettings: builder.mutation({
			query: (payload) => ({
				url: API_URL,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['Settings'],
		}),
	}),
})

export const { useLazyGetSettingsQuery, useUpdateSystemSettingsMutation, useLazyGetSettingQuery } =
	settingsApi
