import { capitalize } from 'lodash'
import { Fragment, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import StickyBox from 'react-sticky-box'
import { TabContent, TabPane } from 'reactstrap'

import { SpinLoader } from 'components/Common'
import { routes } from 'modules/workflows'
import ResourceNotFound from 'pages/StaticPages/ResourceNotFound'
import DocumentsTab from './documents-tab'
import FieldsTab from './fields-tab'
import TabsHeader from './tabs-header'
import UploadFilesTab from './upload-files-tab'
import useWorkflowQuery from './useWorkflowQuery'

const CompletedWorkflowDetails = ({ id }) => {
	const { workflow, user, related_worflows, isLoading } = useWorkflowQuery(id)

	const steps = useMemo(() => workflow?.workflow_steps || [], [workflow, isLoading])
	const [activeTab, setActiveTab] = useState(1)

	const toggle = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab)
		}
	}

	return (
		<div>
			{isLoading ? (
				<SpinLoader />
			) : (
				<Fragment>
					{workflow ? (
						<div>
							<div className='prose mb-8 flex max-w-none items-top gap-4 workflow-details'>
								<div className='flex flex-col text-sm'>
									<strong>Template Title</strong>
									<span className='text-gray-500'>
										{workflow?.workflow_template?.title}
									</span>
								</div>

								<div className='flex flex-col text-sm'>
									<strong>Status</strong>
									<span className='text-gray-500'>{workflow?.status}</span>
								</div>

								<div className='flex flex-col text-sm'>
									<strong>Full Name</strong>
									<span className='text-gray-500'>
										{user?.first_name} {user?.last_name}
									</span>
								</div>

								<div className='flex flex-col text-sm'>
									<strong>Email</strong>
									<span className='text-gray-500'>{user?.email}</span>
								</div>

								<div className='flex flex-col text-sm'>
									<strong>Phone</strong>
									<span className='text-gray-500'>{user?.phone_number}</span>
								</div>

								<div className='flex flex-col text-sm'>
									<strong>Role</strong>
									<span className='text-gray-500'>
										{capitalize(workflow?.user_type)}
									</span>
								</div>
							</div>

							<div className='flex justify-between gap-3 workflow-details'>
								<div
									className='not-prose f-width'
									style={{
										width: 'calc(100% - 280px)',
									}}>
									<h3 className='mb-1 mt-4 text-lg'>Files & Documents</h3>
									<p className='text-sm text-gray-500'>
										The following fields necessitate manual verification since
										they are entered manually by the client.
									</p>
									<div className='bg-white p-3'>
										<TabsHeader activeTab={activeTab} toggle={toggle} />
										<TabContent activeTab={activeTab} className='text-muted'>
											<TabPane tabId={1}>
												<UploadFilesTab steps={steps} />
											</TabPane>
											<TabPane tabId={2}>
												<FieldsTab
													steps={steps}
													userType={workflow?.user_type}
												/>
											</TabPane>
											<TabPane tabId={3}>
												<DocumentsTab id={workflow?.id} />
											</TabPane>
										</TabContent>
									</div>
								</div>

								<div className='w-[280px] f-width'>
									<StickyBox offsetBottom={20} offsetTop={80}>
										{related_worflows.length > 0 ? (
											<div className='flex flex-col'>
												<h3 className='mb-1 mt-4 text-lg'>Other Users</h3>
												<p className='text-sm text-gray-500'>
													Users associated with this workflow.
												</p>

												<div className='bg-white p-3'>
													<ul className='mb-0 list-disc'>
														{related_worflows.map((workflow, idx) => (
															<li key={idx}>
																<Link
																	to={routes.view(workflow?.id)}
																	className='hover:text-blue-700 hover:!underline'>
																	<span>
																		{workflow?.user_name}
																	</span>
																	<span className='ml-1 text-xs font-normal text-gray-500'>
																		({workflow?.user_type})
																	</span>
																</Link>
															</li>
														))}
													</ul>
												</div>
											</div>
										) : undefined}
									</StickyBox>
								</div>
							</div>
						</div>
					) : (
						<ResourceNotFound />
					)}
				</Fragment>
			)}
		</div>
	)
}

export default CompletedWorkflowDetails
