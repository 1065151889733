import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import experiviseDarkLogo from 'assets/images/experivise-logo-dark.png'
import experiviseLightLogo from 'assets/images/experivise-logo-white.png'
import logoLight from 'assets/images/logo-light.png'
import logoSm from 'assets/images/logo-sm.png'
import SidebarContent from './sidebar-content'

const Sidebar = () => {
	
	function tToggle() {
		var body = document.body
		body.classList.toggle('vertical-collpsed')
		body.classList.toggle('sidebar-enable')
	}


	return (
		<React.Fragment>
			<div className='vertical-menu'>
				<div className='navbar-brand-box'>
					<div className='logo logo-dark'>
						<span className='logo-sm'>
							<img
								src={experiviseDarkLogo}
								alt=''
								style={{ height: 27 }}
								className='mt-6'
							/>
						</span>
						<span className='logo-lg'>
							<img
								src={experiviseLightLogo}
								alt=''
								style={{ height: 60 }}
								className='mt-2'
							/>
						</span>
					</div>

					<div className='logo logo-light'>
						<span className='logo-sm'>
							<img src={logoSm} alt='' style={{ height: 22 }} />
						</span>
						<span className='logo-lg'>
							<img src={logoLight} alt='' style={{ height: 22 }} />
						</span>
					</div>
				</div>
				<button
					onClick={() => {
						tToggle()
					}}
					type='button'
					className='btn btn-sm font-size-16 header-item waves-effect vertical-menu-btn px-3'>
					<i className='fa fa-fw fa-bars'></i>
				</button>
				<div data-simplebar className='h-100'>
					<SidebarContent/>
				</div>
			</div>
		</React.Fragment>
	)
}

Sidebar.propTypes = {
	type: PropTypes.string,
}

const mapStatetoProps = (state) => {
	return {
		layout: state.Layout,
	}
}
export default connect(mapStatetoProps, {})(withRouter(withTranslation()(Sidebar)))
