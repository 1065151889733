import { createApi } from '@reduxjs/toolkit/query/react'

import apiEndpoints from 'helpers/apiEndpoints'
import axiosBaseQuery from 'store/axios-base-query'

const API_URL = apiEndpoints.userOnBoard.stepStatus

export const onboardingApi = createApi({
	reducerPath: 'Oboarding',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['OboardingSteps'],
	endpoints: (builder) => ({
		getSteps: builder.query({
			keepUnusedDataFor: 0,
			query: (params) => ({
				url: API_URL,
				method: 'GET',
				params: params,
			}),
			providesTags: ['OboardingSteps'],
			transformResponse: (response) => {
				return {
					steps: response?.data || [],
					onboardStatus: response?.onboardStatus,
				}
			},
		}),
	}),
})

export const { useLazyGetStepsQuery } = onboardingApi
