import { useMemo } from 'react'
import { useDebounce } from 'use-debounce'

import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import { toQueryString } from 'utils/commonFunctions'
import { createTagOption } from './createTagOption'

const TagOptionsQuery = (search) => {
	const [debounceSearch] = useDebounce(search, 600)

	const defaultParams = { page: 1, limit: 20 }
	const searchParams = debounceSearch
		? { ...defaultParams, search: debounceSearch }
		: defaultParams

	const query = toQueryString(searchParams)
	const URL = `${apiEndpoints.task}/tags?${query}`

	const { data, isLoading } = useQuery(URL)

	const tags = useMemo(() => {
		if (Array.isArray(data?.data?.tags)) {
			return data?.data?.tags.map((tag) => createTagOption(tag))
		} else {
			return []
		}
	}, [data?.data?.tags])
	return {
		options: tags,
		isLoading,
	}
}

export default TagOptionsQuery
