import { Link } from 'react-router-dom'
import { isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'
import { ASSIGN_WORKFLOW_APP_ROUTES } from '../../assigned-workflows/routes'

const Actions = ({ id, last_updated, isActionActive }) => {
	const canFill = isAllowTo(permissions?.assignedWorkflow?.fill)
	return (
		<div className='w-full'>
			{isActionActive && canFill ? (
				<div className='flex w-full space-x-3'>
					{canFill ? (
						<Link
							to={ASSIGN_WORKFLOW_APP_ROUTES.findOne(id)}
							className='action-btn btn-gray'>
							<span>View</span>
						</Link>
					) : undefined}
				</div>
			) : (
				<span className=' text-sm  !text-gray-700'>{last_updated}</span>
			)}
		</div>
	)
}

export default Actions
