import { useMediaQuery } from 'react-responsive'

import classNames from 'classnames'
import { useUser } from 'hooks'
import { AssignedWorkflowsContainer as ClientAssignedWorkflowsContainer } from 'modules/assigned-workflows'
import { useLazyGetLoginHistoryQuery } from 'modules/clients/client-apis'
import { Header } from 'modules/workflows'
import AssignedWorkflowsContainer from 'modules/workflows/assigned-workflows'
import { useEffect, useMemo } from 'react'
import { PageHeader } from 'theme/layouts'
import AppLayout from 'theme/layouts/app-layout'
import AnnouncementsList from 'theme/ui/announcements'
import Spinner from 'theme/ui/spinner'

const AssignedWorkflowsListing = () => {
	const { user, group } = useUser()
	const fullName = user?.first_name + ' ' + user?.last_name
	const isDesktop = useMediaQuery({
		query: '(min-width: 768px)',
	})
	const isClient = useMemo(() => group?.slug === 'client', [group])
	const [fetchLoginHistory, { isLoading, isUninitialized, data }] = useLazyGetLoginHistoryQuery()
	useEffect(() => {
		if (user) {
			fetchLoginHistory(user?.id)
		}
	}, [user])

	const isFetching = isUninitialized || isLoading

	const history = useMemo(
		() => data?.data?.userLoginHistory || [],
		[data?.data?.userLoginHistory],
	)

	return (
		<AppLayout
			meta={{
				title: 'Assigned Workflows',
			}}
			variant={isClient ? 'client' : 'admin'}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					{isDesktop && isClient ? (
						<PageHeader
							title={`Welcome back, ${fullName}`}
							description='Track and manage your workflows and documents.'
							variant='client'
						/>
					) : undefined}
					<AnnouncementsList />

					{!isClient ? (
						<div className='page-header bg-white'>
							<Header />
						</div>
					) : undefined}

					{isUninitialized || isLoading ? (
						<Spinner />
					) : (
						<div
							className={classNames(
								isDesktop && isClient ? 'page-body-client' : 'page-body',
							)}>
							{isClient ? (
								<ClientAssignedWorkflowsContainer
									isFetching={isFetching}
									history={history}
								/>
							) : (
								<AssignedWorkflowsContainer />
							)}
						</div>
					)}
				</div>
			</div>
		</AppLayout>
	)
}

export default AssignedWorkflowsListing
