import DocumentVersionTable from './document-version-table'

const DocumentVersionsListing = ({ id }) => {
	return (
		<div>
			<DocumentVersionTable id={id} />
		</div>
	)
}

export default DocumentVersionsListing
