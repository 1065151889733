import classNames from 'classnames'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, FormFeedback, Input, Row } from 'reactstrap'
import { Field, FieldArray, reduxForm } from 'redux-form'
import Swal from 'sweetalert2'

import { ButtonWithLoader } from 'components/Common'
import { useDeleteClientSettingMutation } from 'modules/clients/client-apis'
import { showSuccessToast } from 'utils/commonFunctions'
import { required } from 'utils/formValidation'
import AddressFormPopup from './address-form-popup'

const CustomFields = ({ handleSubmit, loading, fields, client }) => {
	return (
		<form onSubmit={(values) => handleSubmit(values)}>
			<FieldArray
				name='fields'
				client={client}
				component={ArrayField}
				initialValues={fields}
			/>
			<div className='mb-3 hidden p-4 py-3 pt-0 text-center'>
				<ButtonWithLoader type='submit' loading={loading}>
					Submit
				</ButtonWithLoader>
			</div>
		</form>
	)
}

const CustomFieldsForm = reduxForm({
	form: 'custom_fields',
	enableReinitialize: true,
})(CustomFields)

export default CustomFieldsForm

const validate = {
	key: required('Key'),
	value: required('Value'),
}

const ArrayField = ({ fields, client }) => {
	const clientProfile = useMemo(
		() => ({
			address1: client?.userProfile?.address_1,
			address2: client?.userProfile?.address_2,
			city: client?.userProfile?.city,
			unit: client?.userProfile?.unit,
			province: client?.userProfile?.province,
			zip_code: client?.userProfile?.zip_code,
			country: client?.userProfile?.country,
		}),
		[client],
	)
	const { custom_fields } = useSelector((state) => state.form)
	const initialValues = custom_fields?.values?.fields || []

	useEffect(() => {
		if (fields.length === 0) {
			fields.push({})
		}
	}, [fields])
	const [deleteClientSetting] = useDeleteClientSettingMutation()

	const handleDelete = async (e, id, idx) => {
		e.preventDefault()
		if (id) {
			Swal.fire({
				title: 'Are you sure you want to Delete ?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes',
			}).then(async (result) => {
				if (result.isConfirmed) {
					const button = e.target
					button.disabled = true
					const response = await deleteClientSetting(id)
					if (response?.data?.status === 200) {
						showSuccessToast(response?.data?.message)
						fields.remove(idx)
					}
				}
			})
		} else {
			fields.remove(idx)
		}
	}

	const addField = (e) => {
		e.preventDefault()
		fields.push({})
	}

	return (
		<Fragment>
			<div className='mb-3 rounded-lg bg-white'>
				<div className='flex items-center justify-between bg-[#F2F5FF] p-4 py-3'>
					<h5 className='m-0 font-bold '>Client Information</h5>
					<div
						className='flex items-center space-x-3
					'>
						<AddressFormPopup client={client} />
					</div>
				</div>
				<Row className='m-0 border-b p-3'>
					<Col xs={6}>
						<span className='text-gray-500'>Address 1</span>
					</Col>
					<Col xs={6}>
						<strong>{clientProfile?.address1}</strong>
					</Col>
				</Row>
				<Row className='m-0 border-b p-3'>
					<Col xs={6}>
						<span className='text-gray-500'>Address 2</span>
					</Col>
					<Col xs={6}>
						<strong>{clientProfile?.address2}</strong>
					</Col>
				</Row>
				<Row className='m-0 border-b p-3'>
					<Col xs={6}>
						<span className='text-gray-500'>Unit</span>
					</Col>
					<Col xs={6}>
						<strong>{clientProfile?.unit || '-'}</strong>
					</Col>
				</Row>
				<Row className='m-0 border-b p-3'>
					<Col xs={6}>
						<span className='text-gray-500'>City</span>
					</Col>
					<Col xs={6}>
						<strong>{clientProfile?.city}</strong>
					</Col>
				</Row>
				<Row className='m-0 border-b p-3'>
					<Col xs={6}>
						<span className='text-gray-500'>Province</span>
					</Col>
					<Col xs={6}>
						<strong>{clientProfile?.province}</strong>
					</Col>
				</Row>
				<Row className='m-0 border-b p-3'>
					<Col xs={6}>
						<span className='text-gray-500'>Zip Code</span>
					</Col>
					<Col xs={6}>
						<strong>{clientProfile?.zip_code}</strong>
					</Col>
				</Row>
				<Row className='m-0 border-b p-3'>
					<Col xs={6}>
						<span className='text-gray-500'>Country</span>
					</Col>
					<Col xs={6}>
						<strong>{clientProfile?.country}</strong>
					</Col>
				</Row>
				<Row className='m-0 border-b p-3'>
					<Col xs={6}>
						<span className='text-gray-500'>SIN</span>
					</Col>
					<Col xs={6}>
						<strong>{client?.sin_number}</strong>
					</Col>
				</Row>
			</div>

			<div className='p-[0.75rem]'>
				<div className='flex justify-end px-4'>
					<button
						className='cursor-pointer text-main hover:underline'
						onClick={addField}
						type='button'>
						<i className='uil uil-plus' /> Add Field
					</button>
				</div>
				<table className='table-1 mb-0'>
					<tbody>
						{fields.map((field, idx) => {
							let item = initialValues[idx] || {}
							const id = item?.id
							// let isFocused = idx === activeField
							return (
								<tr className='hover:!bg-white' key={idx}>
									<td className='!border-none' style={{ verticalAlign: 'top' }}>
										<Field
											type='hidden'
											name={`${field}.id`}
											component='input'
										/>
										<Field
											name={`${field}.key`}
											component={TextField}
											placeholder='Field Key'
											validate={validate.key}
											className={{ fontBold: false }}
											// onFocus={(e) => onFocus(e, idx)}
											// onBlur={onBlur}
										/>
									</td>
									<td className='!border-none' style={{ verticalAlign: 'top' }}>
										<Field
											name={`${field}.value`}
											placeholder='Field Value'
											component={TextField}
											validate={validate.value}
											className={{ fontBold: true }}
											// onFocus={(e) => onFocus(e, idx)}
											// onBlur={onBlur}
										/>
									</td>
									<td className='!border-none' style={{ verticalAlign: 'top' }}>
										<button
											type='button'
											className='btn text-primary w-full !bg-[#F2F5FF]'
											onClick={(e) => handleDelete(e, id, idx)}>
											<i className='uil uil-trash' />
										</button>
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</div>
		</Fragment>
	)
}

const TextField = ({ meta, className, input, ...rest }) => {
	const [val] = useState('')
	const { touched, invalid, error } = meta

	return (
		<Fragment>
			<div className='form-group'>
				<Input
					{...rest}
					{...input}
					value={val || input.value}
					invalid={touched && Boolean(invalid)}
					className={classNames('form-control', { ...className })}
				/>
				{touched && error && (
					<FormFeedback className='text-danger'>{touched && error}</FormFeedback>
				)}
			</div>
		</Fragment>
	)
}
