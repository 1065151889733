/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import apiEndpoints from '../../../helpers/apiEndpoints'
import * as authActions from '../../../store/jwtauth/actions'
import { showSuccessToast } from '../../../utils/commonFunctions'
import { add, get } from '../../../utils/storageService'
import ForgetPasswordForm from './components/ForgetPasswordForm'

const ForgetPasswordPage = (props) => {
	const { loading } = useSelector((state) => state.jwtauth)
	const dispatch = useDispatch()
	const { history } = props

	useEffect(() => {
		if (!get('logout')) {
			add('logout', true)
		}
	}, [])

	return (
		<Fragment>
			<ForgetPasswordForm
				loading={loading}
				onSubmit={(value) => {
					dispatch(
						authActions.forgotPassword(apiEndpoints.auth.forgotPassword, value),
					).then((response) => {
						if (response?.status === 200) {
							showSuccessToast(response?.message)
							history.push('/login')
						}
					})
				}}
			/>
		</Fragment>
	)
}

export default ForgetPasswordPage
