import classNames from 'classnames'
import { FormFeedback, Input, Label } from 'reactstrap'

export const RadioComponent = (props) => {
	const { id, input, label, meta, variant, readOnly, ignoreBottomMargin = false } = props
	const { touched, error } = meta

	return (
		<div
			className={classNames('form-check', {
				'mb-3': !ignoreBottomMargin,
			})}>
			<Input
				{...input}
				type='radio'
				id={id}
				className={classNames({
					'form-check-input-danger': variant === 'red',
				})}
				disabled={readOnly}
			/>
			<Label htmlFor={id} className='cursor-pointer'>
				{label}
			</Label>
			{touched && error && (
				<FormFeedback style={{ display: 'block' }}>{touched && error}</FormFeedback>
			)}
		</div>
	)
}
