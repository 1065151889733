import { SpinLoader } from 'components/Common'
import ResourceNotFound from 'pages/StaticPages/ResourceNotFound'
import { Fragment, useMemo } from 'react'
import { getSanitizedFields } from '.'
import FieldsList from './fields-list'

const FieldsComparison = ({
	default_fields,
	address_fields,
	settings_fields,
	isUpdating,
	onUpdate,
}) => {
	const filterFields = (field) => {
		// eslint-disable-next-line no-unused-vars
		const [_, values] = field
		const old_value = values?.old
		const new_value = values?.new
		return new_value && old_value
	}

	const defaultFieldsArray = useMemo(
		() => getSanitizedFields(default_fields).filter(filterFields),
		[default_fields],
	)

	const addressFieldsArray = useMemo(
		() => getSanitizedFields(address_fields).filter(filterFields),
		[address_fields],
	)

	const settingsFieldsArray = useMemo(
		() => getSanitizedFields(settings_fields).filter(filterFields),
		[settings_fields],
	)

	const fieldItems = useMemo(
		() =>
			[
				{
					title: 'Default System Fields',
					fieldsList: defaultFieldsArray,
				},
				{
					title: 'Address Fields',
					fieldsList: addressFieldsArray,
				},
				{
					title: 'Other Fields',
					fieldsList: settingsFieldsArray,
				},
			].filter((item) => item.fieldsList.length > 0),
		[defaultFieldsArray, addressFieldsArray, settingsFieldsArray],
	)

	return (
		<Fragment>
			{fieldItems.length > 0 ? (
				<div>
					<div className='grid grid-cols-1'>
						{fieldItems.map((fieldItem, idx) => (
							<FieldsList
								key={idx}
								fields={fieldItem.fieldsList}
								title={fieldItem?.title}
							/>
						))}
					</div>

					<div className='!my-4 flex justify-center'>
						<button className='btn-primary-1' disabled={isUpdating} onClick={onUpdate}>
							{isUpdating ? (
								<SpinLoader color='white' size='sm' />
							) : (
								<span>Update Now</span>
							)}
						</button>
					</div>
				</div>
			) : (
				<ResourceNotFound title='No Field is required to update.' />
			)}
		</Fragment>
	)
}

export default FieldsComparison
