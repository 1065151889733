import classNames from 'classnames'
import { createElement, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as DocumentIcon } from 'assets/svg/document-icon.svg'
import { ReactComponent as ReadymadeTemplateIcon } from 'assets/svg/readymade-template-icon.svg'
import { useUser } from 'hooks'
import Badge from 'theme/ui/badge'
import { FILE_MANAGER_ROUTES } from '../routes'

const Sidebar = () => {
	const { group, isSuperAdmin } = useUser()

	const menuItems = useMemo(
		() => [
			{
				label: 'Home',
				icon: 'uil-home-alt',
				href: FILE_MANAGER_ROUTES.home(),
				permission: ['owner', 'advisor'].includes(group?.slug) || isSuperAdmin,
			},

			{
				label: 'All Folders',
				icon: 'uil-folder',
				href: FILE_MANAGER_ROUTES.allFolders(),
				permission: ['owner', 'advisor'].includes(group?.slug) || isSuperAdmin,
			},
			{
				label: 'My Documents',
				iconType: 'svg',
				icon: DocumentIcon,
				href: FILE_MANAGER_ROUTES.myDocuments(),
				permission: ['owner', 'advisor'].includes(group?.slug),
			},
			{
				label: 'Readymade Templates',
				iconType: 'svg',
				icon: ReadymadeTemplateIcon,
				tag: 'By Experivise',
				href: FILE_MANAGER_ROUTES.readyMadeTemplate(),
				permission: ['owner', 'advisor'].includes(group?.slug) || isSuperAdmin,
			},
			{
				label: 'Starred',
				icon: 'uil-star',
				href: FILE_MANAGER_ROUTES.starred(),
				permission: ['owner', 'advisor'].includes(group?.slug) || isSuperAdmin,
			},
			{
				label: 'Trash',
				icon: 'uil-trash-alt',
				href: FILE_MANAGER_ROUTES.trash(),
				permission: ['owner', 'advisor'].includes(group?.slug) || isSuperAdmin,
			},
		],
		[],
	)

	return (
		<div className='h-full min-h-[70vh] rounded bg-white py-2 shadow' id='sidebar-menu'>
			<ul className='m-0 p-0'>
				{menuItems
					.filter((m) => m?.permission === true)
					.map((menuItem, idx) => (
						<li
							key={idx}
							className={classNames({
								'mm-active': menuItem.href === window.location.pathname,
							})}>
							<Link
								className='!flex !items-center space-x-1 text-sm font-medium hover:bg-[#F3F8FB] hover:!text-main'
								to={menuItem?.href}>
								<i>
									{menuItem?.iconType === 'svg' ? (
										createElement(menuItem?.icon, {
											height: 22,
											style: { marginLeft: -2 },
										})
									) : (
										<i className={`${menuItem?.icon} mt-1 hover:text-main`}></i>
									)}
								</i>
								<div className='flex flex-col space-y-1'>
									<span>{menuItem?.label}</span>
									{menuItem?.tag ? (
										<Badge rounded={false}>{menuItem?.tag}</Badge>
									) : undefined}
								</div>
							</Link>
						</li>
					))}
			</ul>
		</div>
	)
}

export default Sidebar
