import { useLazyGetNylasQuery } from 'modules/authentication/auth-api'
import Message from 'theme/ui/message'
import notification from 'utilities/notification'

const NylasNotification = ({ setShowMessage }) => {
	const [fetchNylas, { isLoading }] = useLazyGetNylasQuery()

	const fetchApi = async () => {
		try {
			const response = await fetchNylas().unwrap()

			if (response.status === 200) {
				const redirectUrl = response?.data
				if (redirectUrl) {
					window.location.href = redirectUrl
				}
			}
		} catch (error) {
			notification('error', error?.Message)
		}
	}
	return (
		<div className='p-2'>
			<Message variant={'info'} onClose={() => setShowMessage(false)}>
				<i className='uil uil-question-circle me-2'></i>
				<span className='font-bold'>Connect your email</span>
				<p className='ms-4 mt-2'>
					Completed documents sent to clients, reviewers, and receivers will come from
					your email address.
				</p>

				<button
					onClick={fetchApi}
					disabled={isLoading}
					className=' mx-4 mb-2 me-2 rounded-lg !border !border-gray-400 bg-white px-3 py-1  font-medium text-gray-900 hover:bg-gray-100'>
					{isLoading ? 'Loading..' : 'Link My Email Inbox'}
				</button>
			</Message>
		</div>
	)
}

export default NylasNotification
