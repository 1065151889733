const EmailTemplatePreview = ({ emailData, title }) => {

	const emailTemplate = emailData.replace(/<%=\s*(.*?)\s*%>/g, (match, p1) => {
		return `<strong>[${p1}]</strong>`
	})

	return (
		<div>
			<h1>{title}</h1>
			<p
				className='text-sm font-normal text-[#74788D] '
				dangerouslySetInnerHTML={{ __html: emailTemplate }}
			/>
		</div>
	)
}

export default EmailTemplatePreview
