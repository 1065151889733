import { Fragment, useState, useEffect, useRef } from 'react'
import { isAllowTo } from 'utilities/helpers'
import notification from 'utilities/notification'
import { permissions } from 'utilities/permissions'
import { useDeleteDocumentPackageMutation } from '../document-package-api'
import DeleteConfirmAlert from './delete-confirm-alert'

const Actions = ({ field, handleClickAddDocument, handleClickEditPackage }) => {
	const [isOpen, setIsOpen] = useState({
		open: false,
		id: undefined,
	})

	const [showMenu, setShowMenu] = useState(false) // State to control mobile menu visibility
	const menuRef = useRef(null) // Ref to track the dropdown menu

	const onOpen = (id) => {
		setIsOpen({
			open: true,
			id,
		})
	}

	const onClose = () =>
		setIsOpen({
			open: false,
			id: undefined,
		})

	const [deleteDocumentPackage, { isLoading }] = useDeleteDocumentPackageMutation()

	const onDelete = async () => {
		const id = isOpen?.id
		const response = await deleteDocumentPackage(id)
		if (response?.data?.status === 200) {
			notification('success', response?.data?.message)
		}
		onClose()
	}

	const toggleMenu = (e) => {
		e.stopPropagation()
		setShowMenu((prev) => !prev)
	}

	// Close the menu if clicked outside
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (menuRef.current && !menuRef.current.contains(event.target)) {
				setShowMenu(false) // Close the menu if clicked outside
			}
		}

		// Add the event listener when the component mounts
		document.addEventListener('mousedown', handleClickOutside)

		// Clean up the event listener when the component unmounts
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [menuRef])

	return (
		<Fragment>
			{/* Modal to confirm delete, added z-index */}
			<DeleteConfirmAlert
				isOpen={isOpen?.open}
				onClose={onClose}
				isLoading={isLoading}
				onConfirm={onDelete}
				className="z-[9999]" // Ensuring it shows on top
			/>

			{/* Large screen: show buttons */}
			<div className='flex items-center space-x-2 My_hide'>
				{isAllowTo(permissions?.documentPackage?.edit) ? (
					<>
						<button
							type='button'
							className='text-primary flex size-8 items-center justify-center rounded border-none bg-gray-100 p-1.5 px-2 hover:bg-gray-200'
							title='Add Document'
							onClick={(e) => {
								e.stopPropagation()
								handleClickAddDocument(field?.id)
							}}>
							<i className='uil-plus' style={{ fontSize: 16 }} />
						</button>
						<button
							type='button'
							className='text-primary flex size-8 items-center justify-center rounded border-none bg-gray-100 p-1.5 px-2 hover:bg-gray-200'
							title='Edit Document Package'
							onClick={(e) => {
								e.stopPropagation()
								handleClickEditPackage(field?.id)
							}}>
							<i className='uil-edit-alt' style={{ fontSize: 16 }} />
						</button>
					</>
				) : undefined}

				{isAllowTo(permissions?.documentPackage?.delete) ? (
					<button
						type='button'
						className='text-danger flex size-8 items-center justify-center rounded border-none bg-gray-100 p-1.5 px-2 hover:bg-gray-200'
						title='Delete Document Package'
						onClick={(e) => {
							e.stopPropagation()
							onOpen(field?.id)
						}}>
						<i className='uil-trash' style={{ fontSize: 16 }} />
					</button>
				) : undefined}
			</div>

			{/* Mobile view: show 3-dot menu only for screens less than 768px */}
			<div className='relative block My_hide2' ref={menuRef}>
				<button
					type='button'
					className='text-primary flex size-8 items-center justify-center rounded border-none p-1 px-1 hover:bg-gray-200'
					title='More Options'
					onClick={toggleMenu}>
					<i className='uil-ellipsis-v' style={{ fontSize: 16 }} />
				</button>

				{/* Dropdown menu when 3-dots are clicked */}
				{showMenu && (
					<div className='absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50'>
						<div className='flex flex-row space-x-2 py-1 px-2' role='menu'>
							{isAllowTo(permissions?.documentPackage?.edit) && (
								<>
									<button
										type='button'
										className='text-primary flex items-center justify-center px-2.5 py-2 text-sm bg-gray-100 hover:bg-gray-200 rounded'
										onClick={(e) => {
											e.stopPropagation()
											handleClickAddDocument(field?.id)
											setShowMenu(false) // Close menu after action
										}}>
										<i className='uil-plus' style={{ fontSize: 16 }} />
									</button>
									<button
										type='button'
										className='text-primary flex items-center justify-center px-2.5 py-2 text-sm bg-gray-100 hover:bg-gray-200 rounded'
										onClick={(e) => {
											e.stopPropagation()
											handleClickEditPackage(field?.id)
											setShowMenu(false) // Close menu after action
										}}>
										<i className='uil-edit-alt' style={{ fontSize: 16 }} />
									</button>
								</>
							)}
							{isAllowTo(permissions?.documentPackage?.delete) && (
								<button
									type='button'
									className='text-danger flex items-center justify-center px-2.5 py-2 text-sm bg-gray-100 hover:bg-gray-200 rounded'
									onClick={(e) => {
										e.stopPropagation()
										onOpen(field?.id)
										setShowMenu(false) // Close menu after action
									}}>
									<i className='uil-trash' style={{ fontSize: 16 }} />
								</button>
							)}
						</div>
					</div>
				)}
			</div>
		</Fragment>
	)
}

export default Actions
