import { useEffect } from 'react'

import { useCanvas } from 'contexts/canvas-context'
import { Button } from 'theme/ui/forms'

const DrawTab = ({ formik }) => {
	const {
		canvasRef,
		prepareCanvas,
		startDrawing,
		finishDrawing,
		draw,
		clearCanvas,
		saveCanvasAsImage,
	} = useCanvas()

	useEffect(() => {
		prepareCanvas()
	}, [])

	const onClear = () => {
		clearCanvas()
		formik.setFieldValue('signatureFile', null)
	}

	const onSave = () => {
		const dataURL = saveCanvasAsImage()
		const blobBin = atob(dataURL.split(',')[1])

		const blobArray = []
		for (var i = 0; i < blobBin.length; i++) {
			blobArray.push(blobBin.charCodeAt(i))
		}

		const file = new Blob([new Uint8Array(blobArray)], {
			type: 'image/png',
		})

		formik.setFieldValue('signatureFile', file)
	}

	return (
		<div className='rounded-md !border bg-white p-3'>
			<canvas
				onMouseDown={startDrawing}
				onMouseUp={finishDrawing}
				onMouseMove={draw}
				ref={canvasRef}
			/>
			<div className='mt-2 flex justify-between space-x-3'>
				<Button type='button' onClick={onClear} variant='white' size='sm'>
					Clear
				</Button>
				<Button type='button' onClick={onSave} variant='primary-outline' size='sm'>
					Lock Signature
				</Button>
			</div>
		</div>
	)
}

export default DrawTab
