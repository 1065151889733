import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import { FormikProvider, useFormik } from 'formik'
import { toUpper } from 'lodash'
import { useEffect, useMemo } from 'react'

import { CanvasProvider } from 'contexts/canvas-context'
import { useUser } from 'hooks'
import { Button } from 'theme/ui/forms'
import { generateUniqueId } from 'utilities/helpers'
import notification from 'utilities/notification'
import { validationSchema } from '.'
import {
	useDeleteUserSignatureMutation,
	useLazyGetUserSignatureQuery,
	useUpdateUserSignatureMutation,
} from '../user-profile-api'
import DrawTab from './draw-tab'
import UploadTab from './upload-tab'

const SignatureForm = () => {
	const tabs = useMemo(
		() => [
			{ title: 'Draw', id: 0 },
			{ title: 'Upload', id: 1 },
		],
		[],
	)

	const { user } = useUser()

	const [updateSignature] = useUpdateUserSignatureMutation()
	const [fetchSignature, { data, isFetching }] = useLazyGetUserSignatureQuery()
	const signature = useMemo(() => data?.signature, [data?.signature])

	useEffect(() => {
		if (user?.id) {
			fetchSignature(user?.id)
		}
	}, [user?.id])

	const formik = useFormik({
		initialValues: {
			signatureFile: null,
		},
		validationSchema: validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true)
				const formData = new FormData()
				const imageId = generateUniqueId()
				formData.append('signature', values?.signatureFile, `${toUpper(imageId)}.png`)

				const response = await updateSignature({ payload: formData, userId: user?.id })
				if (response?.data?.status === 200) {
					await fetchSignature(user?.id)
					notification('success', response?.data?.message)
				}
				setSubmitting(false)
			} catch (error) {
				setSubmitting(false)
				notification('error', error?.message)
			}
		},
	})

	const [deleteSignature, { isLoading: isDeleting }] = useDeleteUserSignatureMutation()

	const onDelete = async (userId) => {
		try {
			const response = await deleteSignature(userId)
			if (response?.data?.status === 200) {
				await fetchSignature(user?.id)
				notification('success', response?.data?.message)
			}
		} catch (error) {
			notification('warn', error?.message)
		}
	}

	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit} className='flex flex-col divide-y divide-dashed'>
				<div
					className='!sm:flex-row flex flex-col  justify-between gap-10 py-6'
					id='signatures'>
					<div className='prose my-3 max-w-none'>
						<h3>Signatures</h3>
						<p className='text-sm text-gray-500'>
							Leave Your Mark: Effortlessly Manage and Customize Your Digital
							Signatures for Authenticity and Security
						</p>
					</div>
					<div className='not-prose grid grid-cols-1 gap-x-6 sm:min-w-[400px]'>
						{signature ? (
							<div className='flex flex-col space-y-3'>
								<div className='flex h-[165px] items-center justify-center space-x-6 rounded-lg !border border-dashed border-main p-6 text-center sm:min-w-[400px]'>
									<img src={signature?.path} className='block h-full w-auto' />
								</div>
								<div className='flex  space-x-1'>
									<p className='text-sm text-gray-500'>
										Click the delete action button to remove the signature
										permanently.
									</p>
									<Button
										type='button'
										variant='danger-outline'
										size='sm'
										onClick={() => onDelete(user?.id)}
										isLoading={isDeleting}
										isLoadingText='Deleting...'>
										<div className='flex items-center space-x-1'>
											<i className='uil-trash' />
											<span>Delete</span>
										</div>
									</Button>
								</div>
							</div>
						) : (
							<Tab.Group onChange={() => formik.setFieldValue('signatureFile', null)}>
								<Tab.List className='flex p-1'>
									{tabs.map((tab) => (
										<Tab
											key={tab.id}
											className={({ selected }) =>
												classNames(
													'w-full !border-b-2 py-2.5 text-sm font-medium',
													selected
														? 'rounded-t-md border-main bg-main/10 text-main'
														: 'text-gray-500',
												)
											}>
											{tab.title}
										</Tab>
									))}
								</Tab.List>
								<Tab.Panels className='mt-2'>
									<Tab.Panel>
										<CanvasProvider
											options={{
												classNames: classNames(
													'rounded-lg !border border-dashed border-main',
												),
												height: 153,
												width: 370,
											}}>
											<DrawTab formik={formik} />
										</CanvasProvider>
									</Tab.Panel>
									<Tab.Panel>
										<UploadTab
											fetchSignature={fetchSignature}
											formik={formik}
											signature={data?.signature}
											isFetching={isFetching}
										/>
									</Tab.Panel>
								</Tab.Panels>
							</Tab.Group>
						)}
					</div>
				</div>

				{!signature ? (
					<div className='flex justify-end py-6'>
						<Button
							type='submit'
							disabled={!formik.values?.signatureFile}
							isLoading={formik.isSubmitting}
							isLoadingText='Saving...'>
							Upload Now
						</Button>
					</div>
				) : undefined}
			</form>
		</FormikProvider>
	)
}

export default SignatureForm
