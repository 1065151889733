import { useMemo } from 'react'
import Badge from 'theme/ui/badge'
import { formatDate, formatPrice, getStatusVariant } from 'utilities/helpers'

const PaymentsDetails = ({ payments }) => {
	const $payments = useMemo(
		() =>
			payments?.map((payment) => ({
				id: payment?.id,
				amount: formatPrice(payment?.custom_price, payment?.currency),
				status: payment?.status,
				payment_date: payment?.payment_date,
				payment_method: payment?.payment_method,
				transaction_id: payment?.transaction_id,
			})),
		[payments],
	)

	const payment = $payments[0]

	return (
		<div>
			<div className='!mb-3 flex items-center justify-between'>
				<h3 className='m-0 text-lg font-medium text-gray-600'>Payment Details</h3>
			</div>

			<div className='flex justify-between gap-4'>
				{payment ? (
					<div className=' grid w-full grid-cols-2 gap-3 rounded-lg !border !px-4 !py-3 sm:grid-cols-4'>
						<div className='flex flex-col'>
							<span className='text-[13px] text-gray-500'>Amount</span>
							<div className='flex items-center space-x-1'>
								<span className='text-sm font-semibold text-gray-950'>
									{payment?.amount}
								</span>
								<Badge variant={getStatusVariant(payment?.status)}>
									{payment?.status}
								</Badge>
							</div>
						</div>

						<div className='flex flex-col'>
							<span className='text-[13px] text-gray-500'>Payment Method</span>
							<span className='text-sm font-semibold capitalize text-gray-950'>
								{payment?.payment_method || '-'}
							</span>
						</div>

						<div className='flex flex-col'>
							<span className='text-[13px] text-gray-500'>Payment Date</span>
							<span className='text-sm font-semibold text-gray-950'>
								{formatDate(payment?.payment_date) || '-'}
							</span>
						</div>

						<div className='flex flex-col'>
							<span className='text-[13px] text-gray-500'>Transaction Id</span>
							<span className='text-sm font-semibold text-gray-950'>
								{payment?.transaction_id || '-'}
							</span>
						</div>
					</div>
				) : undefined}
			</div>
		</div>
	)
}

export default PaymentsDetails
