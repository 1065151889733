import React, { Fragment } from 'react'

const Loader = () => {
    return (
        <Fragment>
            <div id="preloader">
                <div id="status">
                    <div className="spinner">
                        <i className="uil-shutter-alt spin-icon"></i>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Loader
