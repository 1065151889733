import { TeamMemberInvitationListingContainer } from 'modules/team-members'
import Header from 'modules/team-members/header'
import AppLayout from 'theme/layouts/app-layout'

const TeamMembersInvitationListing = () => {
	return (
		<AppLayout
			meta={{
				title: 'Team Member Invitations',
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<div className='page-header bg-white'>
						<Header />
					</div>
					<div className='page-body'>
						<TeamMemberInvitationListingContainer />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default TeamMembersInvitationListing
