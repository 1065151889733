import { useUser } from 'hooks'
import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

import AddToFolder from '../all-folders-listing/add-to-folder'
import { FILE_MANAGER_ROUTES } from '../routes'
import CreateFolder from '../search-bar/create-folder'

const OptionsList = () => {
	const [isOpen, setIsOpen] = useState(false)
	const [isFolderPopupOpen, setIsFolderPopupOpen] = useState(false)
	const [menu, setMenu] = useState(false)
	const onOpen = () => setIsOpen(true)
	const onClose = () => setIsOpen(false)
	const { isSuperAdmin } = useUser()

	return (
		<Fragment>
			<>
				<Dropdown
					isOpen={menu}
					toggle={() => setMenu(!menu)}
					className='dropdown  inline-block'
					tag='li'>
					<DropdownToggle
						tag='button'
						type='button'
						className='m-0 !border !border-none bg-transparent p-0'>
						<i className='mdi mdi-dots-vertical text-[16px]'></i>
					</DropdownToggle>
					<DropdownMenu className='dropdown-menu-md dropdown-menu-end  p-0 '>
						<div className='w-full overflow-hidden rounded-md bg-white text-left shadow-lg ring-1 ring-black/5'>
							<div className='relative divide-y p-2'>
								<div className='flex flex-col gap-1'>
									<Link
										to={FILE_MANAGER_ROUTES.findOneFolder(12)}
										className='file-manager-action-dropdown-item'>
										<i className='uil-eye'></i>
										<span className='text-sm'>View</span>
									</Link>
									{isSuperAdmin ? (
										<Fragment>
											<Link
												to='#'
												className='file-manager-action-dropdown-item'>
												<i className='uil-pen'></i>
												<span className='text-sm'>Edit</span>
											</Link>
											<span
												className='file-manager-action-dropdown-item'
												onClick={() =>
													setIsFolderPopupOpen(!isFolderPopupOpen)
												}>
												<i className='uil-edit-alt'></i>
												<span className='text-sm'>Rename</span>
											</span>
										</Fragment>
									) : undefined}

									{!isSuperAdmin ? (
										<span className='file-manager-action-dropdown-item'>
											<i className='uil-paperclip'></i>
											<span className='text-sm'>Attach in Workflow</span>
										</span>
									) : undefined}

									<span
										className='file-manager-action-dropdown-item'
										onClick={onOpen}>
										<i className='uil-folder'></i>
										<span className='text-sm'>Move to Folder</span>
									</span>

									<span className='file-manager-action-dropdown-item'>
										<i className='uil-star'></i>
										<span className='text-sm'>Star</span>
									</span>

									{isSuperAdmin ? (
										<span className='file-manager-action-dropdown-item'>
											<i className='uil-trash  text-red-600'></i>
											<span className='text-sm text-red-600'>Delete</span>
										</span>
									) : undefined}
								</div>
							</div>
						</div>
					</DropdownMenu>
				</Dropdown>
			</>
			<CreateFolder
				onClose={() => setIsFolderPopupOpen(!isFolderPopupOpen)}
				isOpen={isFolderPopupOpen}
			/>
			<AddToFolder onClose={onClose} isOpen={isOpen} />
		</Fragment>
	)
}

export default OptionsList
