import { required } from "utils/formValidation";

export const validate = {
    first_name: required('first name'),
    last_name: required('last name'),
    phone_number: required('phone number'),
    address1: required('address 1'),
    address2: required('address 2'),
    unit: required('unit'),
    city: required('city'),
    province: required('province'),
    country: required('country'),
    zipCode: required('zip code'),
}