import { DndContext, rectIntersection } from '@dnd-kit/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import apiEndpoints from 'helpers/apiEndpoints'
import { snakeCase } from 'lodash'
import { useDispatch } from 'react-redux'
import { update } from 'store/common/actions'
import { COLUMNS } from '.'
import Column from './Column'

const Board = ({ columns, mutate }) => {
	const dispatch = useDispatch()
	const [toDoList, setToDoList] = useState([])
	const [inProcess, setInProcess] = useState([])
	const [inReview, setInReview] = useState([])
	const [completed, setCompleted] = useState([])

	const setColumnTasks = useCallback((column_type, tasks) => {
		switch (column_type) {
			case COLUMNS.TO_DO_LIST:
				setToDoList(tasks)
				break

			case COLUMNS.IN_PROCESS:
				setInProcess(tasks)
				break

			case COLUMNS.IN_REVIEW:
				setInReview(tasks)
				break

			case COLUMNS.COMPLETED:
				setCompleted(tasks)
				break
		}
	}, [])

	useEffect(() => {
		const reCreateColumns = (columns) => {
			return new Promise((resolve) => {
				columns.forEach((column) => {
					setColumnTasks(column?.title, column?.cards)
				})
				resolve()
			})
		}

		if (columns.length > 0) {
			reCreateColumns(columns)
		}
	}, [columns])

	const onDragEnd = (event) => {
		const { active, over } = event
		if (over?.data?.current?.accepts.includes(active?.data?.current?.type)) {
			const container = over?.data?.current?.status
			const task = active?.data?.current?.task
			const index = active?.data?.current?.index ?? 0
			const parent = active?.data?.current?.parent

			if (container === COLUMNS.TO_DO_LIST) {
				setToDoList([...toDoList, { ...task }])
			} else if (container === COLUMNS.COMPLETED) {
				setCompleted([...completed, { ...task }])
			} else if (container === COLUMNS.IN_REVIEW) {
				setInReview([...inReview, { ...task }])
			} else {
				setInProcess([...inProcess, { ...task }])
			}
			if (parent === COLUMNS.TO_DO_LIST) {
				setToDoList([...toDoList.slice(0, index), ...toDoList.slice(index + 1)])
			} else if (parent === COLUMNS.COMPLETED) {
				setCompleted([...completed.slice(0, index), ...completed.slice(index + 1)])
			} else if (parent === COLUMNS.IN_REVIEW) {
				setInReview([...inReview.slice(0, index), ...inReview.slice(index + 1)])
			} else {
				setInProcess([...inProcess.slice(0, index), ...inProcess.slice(index + 1)])
			}

			const status = over?.data?.current?.status
			const task_id = active?.id

			dispatch(
				update(`${apiEndpoints.task}/${task_id}`, 'update-status', {
					status,
				}),
			).then((response) => {
				if (response?.status === 200) {
					mutate()
				}
			})
		}
	}

	const __columns = useMemo(() => columns.map((column) => snakeCase(column?.title)), [columns])

	return (
		<React.Fragment>
			<DndContext collisionDetection={rectIntersection} onDragEnd={onDragEnd}>
				{columns.length > 0 ? (
					<div className='grid grid-cols-4 gap-[16px]'>
						<Column
							column={columns[0]}
							tasks={toDoList}
							accepts={__columns.filter((c) => c !== snakeCase(columns[0]?.title))}
							isLast={false}
						/>
						<Column
							column={columns[1]}
							tasks={inProcess}
							accepts={__columns.filter((c) => c !== snakeCase(columns[1]?.title))}
							isLast={false}
						/>
						<Column
							column={columns[2]}
							tasks={inReview}
							accepts={__columns.filter((c) => c !== snakeCase(columns[2]?.title))}
							isLast={false}
						/>
						<Column
							column={columns[3]}
							tasks={completed}
							accepts={__columns.filter((c) => c !== snakeCase(columns[3]?.title))}
							isLast={true}
						/>
					</div>
				) : (
					<div className='alert alert-info'> No column found</div>
				)}
			</DndContext>
		</React.Fragment>
	)
}

export default Board
