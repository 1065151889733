import { Fragment } from 'react'
import PdfFieldRender from '../PdfFieldRender'

const SamePage = ({ error, fields, isReadOnly, currentStatus }) => {
	return (
		<Fragment>
			{fields.length > 0 ? (
				<div className='mt-4 flex w-full space-x-6'>
					<div className='grid w-full grid-cols-1 gap-x-3 md:grid-cols-2'>
						{fields.map((field, i) => (
							<div key={i}>
								<PdfFieldRender
									isReadOnly={isReadOnly}
									currentStatus={currentStatus}
									field={field}
									i={i}
									error={error}
								/>
							</div>
						))}
					</div>
				</div>
			) : (
				<div className='alert alert-info mx-auto max-w-md'>
					No profile field is required to be filled here. Please proceed.
				</div>
			)}
		</Fragment>
	)
}

export default SamePage
