import { Button } from 'theme/ui/forms'

const Filters = ({ selectedRows }) => {
	return (
		<div className='flex items-center justify-between pb-3'>
			<h3 className='m-0 mt-1 text-base font-semibold'>My Documents</h3>
			{selectedRows.length > 0 ? (
				<div className='flex items-center space-x-3'>
					<div className='bg-white'>
						<Button type='button' variant='primary-outline'>
							<i className='uil-plus'></i>
							<span>Move to Folder</span>
						</Button>
					</div>
					<div className='bg-white'>
						<Button type='button' variant='primary-outline'>
							<span>Attach in Workflow</span>
						</Button>
					</div>
					<div className='bg-white'>
						<Button type='button' variant='danger-outline'>
							<span>Delete All</span>
						</Button>
					</div>
				</div>
			) : undefined}
		</div>
	)
}

export default Filters
