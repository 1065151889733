import { FileManagerLayout, HomeContainer } from 'modules/file-manager'

const FileManager = () => {
	return (
		<FileManagerLayout
			meta={{
				title: 'File Manager',
			}}>
			<div>
				<HomeContainer />
			</div>
		</FileManagerLayout>
	)
}

export default FileManager
