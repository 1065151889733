import { io } from 'socket.io-client'

const options =
	process.env.NODE_ENV !== 'development'
		? { path: '/socket.io', forceNew: true, autoConnect: false }
		: { autoConnect: false }

export const socket = io(process.env.REACT_APP_SOCKET_URL, options)
// export const socket = io("wss://dev.experivise.com", {
//     forceNew: true,
//     path: '/api/socket.io'
// })
