import { FilterBadge, ResetButton } from '.'

const TableFiltersCard = ({ filters, formik, onResetFilters }) => {
	return (
		<div className='flex items-center justify-between space-x-3'>
			<div>
				{filters.map((filter, i) => {
					const [meta, value] = filter
					const { label, name } = meta
					return (
						<FilterBadge
							key={i}
							label={label}
							value={value}
							onClose={() => formik.setFieldValue(name, '')}
						/>
					)
				})}
			</div>
			<div>
				<ResetButton onReset={onResetFilters} />
			</div>
		</div>
	)
}

export default TableFiltersCard
