import { useMemo } from 'react'

import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import { getWorkflowSteps } from '..'

const WorkflowQuery = (id) => {
	const URL = useMemo(() => (id ? `${apiEndpoints.workflow_template}/${id}` : undefined), [id])
	const { data, isLoading } = useQuery(URL)

	const steps = data?.data?.steps || []
	const { documents, uploads } = useMemo(() => getWorkflowSteps(steps), [steps])
	const user_count = documents?.raw_document_client_count || 1

	return {
		workflow: data?.data,
		steps: {
			uploads,
			documents,
		},
		user_count,
		isLoading,
	}
}

export default WorkflowQuery
