import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import { useMemo } from 'react'

import ClientsTab from './clients-tab'
import OwnerDetailsTab from './owner-details-tab'
import SubscriptionDetailsTab from './subscription-details-tab'
import UsersTab from './users-tab'

const TeamDetailsContainer = ({ total_users, team, clients, users, owner, $subscription }) => {
	const tabs = useMemo(
		() => [
			{
				id: 0,
				label: 'Owner',
			},
			{
				id: 1,
				label: 'Clients',
			},
			{
				id: 2,
				label: 'Other Users',
			},
			{
				id: 3,
				label: 'Subscriptions',
			},
		],
		[],
	)

	const subscription = Array.isArray(team?.userHasSubscriptions)
		? team?.userHasSubscriptions[0]
		: null

	return (
		<div>
			<div className='prose mb-8 flex max-w-none items-center space-x-10'>
				<div className='flex flex-col text-sm'>
					<span className='text-[13px] text-gray-500'>Team Name</span>
					<span className='text-sm font-semibold text-gray-800'>{team?.team_name}</span>
				</div>
			</div>

			<Tab.Group>
				<Tab.List className='mb-3 flex max-w-fit divide-x overflow-hidden rounded !border border-gray-300'>
					{tabs.map((tab) => (
						<Tab
							key={tab.id}
							className={({ selected }) =>
								classNames(
									'w-auto !py-2 px-3 text-sm leading-5 transition-colors',
									selected
										? 'bg-white font-medium text-main'
										: 'text-gray-500 hover:bg-gray-100 hover:text-main',
								)
							}>
							{tab.label}
						</Tab>
					))}
				</Tab.List>
				<Tab.Panels>
					<Tab.Panel>
						<OwnerDetailsTab owner={owner} />
					</Tab.Panel>
					<Tab.Panel>
						<ClientsTab clients={clients} team={team} />
					</Tab.Panel>
					<Tab.Panel>
						<UsersTab users={users} team={team} />
					</Tab.Panel>
					<Tab.Panel>
						<SubscriptionDetailsTab
							subscription={subscription}
							$subscription={$subscription}
							team={team}
							total_users={total_users}
						/>
					</Tab.Panel>
				</Tab.Panels>
			</Tab.Group>
		</div>
	)
}

export default TeamDetailsContainer
