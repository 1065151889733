import { Fragment, useEffect, useState } from 'react'

// import experiviseDarkLogo from "../../assets/images/experivise-logo-dark.png";
import experiviseLogoWhite from '../../assets/images/experivise-logo-dark.png'
// import logoDark from "../../assets/images/logo-dark.png";
import ProfileMenu from './ProfileMenu'

const ClientOnBoardingNavBar = () => {
	const [scrollY, setScrollY] = useState(window.scrollY)

	const headerClassNames = scrollY > 0 ? 'bg-white shadow-sm' : 'shadow-none bg-transparent'

	const onScroll = () => {
		setScrollY(window.scrollY)
	}

	useEffect(() => {
		window.addEventListener('scroll', onScroll)
		return () => {
			window.removeEventListener('scroll', onScroll)
		}
	}, [])

	return (
		<Fragment>
			<header id='page-topbar' className={`!left-0 ${headerClassNames}`}>
				<div className='navbar-header'>
					<div className='flex'>
						<div className='mx-6'>
							<img src={experiviseLogoWhite} alt='Experivise' className='h-12' />
						</div>
					</div>

					<div className='flex space-x-6 px-8'>
						{/* <button className='!border rounded-lg px-2.5 py-1'>
                            <div className='flex items-center space-x-2'>
                                <i className='uil uil-info-circle text-2xl' />
                                <span className='font-medium text-sm'>Need Help</span>
                            </div>
                        </button> */}

						<ProfileMenu />
					</div>
				</div>
			</header>
		</Fragment>
	)
}

export default ClientOnBoardingNavBar
