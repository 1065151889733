import useSWR from 'swr'
import fetcher from 'utils/fetcher'

const useQuery = (url, options = {}) => {
	const { data, error, mutate, isLoading, isValidating } = useSWR(url ? url : null, fetcher, {
		...options,
		shouldRetryOnError: false,
		dedupingInterval: 0,
		revalidateOnMount: true,
	})

	return {
		data: data,
		isLoading: isLoading,
		isValidating: isValidating,
		isError: error,
		mutate,
	}
}

export default useQuery
