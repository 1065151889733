const PdfPreviewSkeleton = () => {
	return (
		<div className='animate-pulse space-y-6'>
			<div className='flex justify-between gap-6'>
				<div className='h-screen w-full bg-slate-200' />
			</div>
		</div>
	)
}

export default PdfPreviewSkeleton
