/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	error: null,
	loading: false,
	loggedIn: false,
	user: null,
}

export const JwtAuthSlices = createSlice({
	name: 'jwtauth',
	initialState: initialState,
	reducers: {
		startCall: (state, action) => {
			state.error = null
			state.loading = true
		},

		endCall: (state, action) => {
			state.error = null
			state.loading = false
		},

		catchError: (state, action) => {
			state.error = action.payload.message
			state.loading = false
		},

		login: (state, action) => {
			state.error = null
			state.loading = false
			state.loggedIn = true
			state.user = action.payload
		},

		logout: (state, action) => {
			state.error = null
			state.loading = false
			state.loggedIn = false
			state.user = null
		},
	},
})

export const { endCall, catchError } = JwtAuthSlices.actions
