import Dropzone from 'components/Forms/Dropzone'
import { Fragment } from 'react'
import { Progress } from 'reactstrap'
import FileIcon from './FileIcon'

const FileUploader = ({ onDrop, accept, maxSize, fileState, progress, onRemove }) => {
	const [currentFile] = fileState

	return (
		<Fragment>
			{currentFile ? (
				<Fragment>
					<div className='border-1 mb-2 max-w-sm rounded-md border-gray-300'>
						<div className='h-24 p-2.5'>
							<div className='justify-content-between flex min-h-full'>
								<div className='flex items-center space-x-2'>
									<FileIcon type={currentFile.type} className='w-12' />
									<span className='line-clamp-2 w-80 break-words text-base font-semibold'>
										{currentFile.name}
									</span>
								</div>
								<div className=''>
									<button className='text-xl' onClick={onRemove}>
										<i className='uil uil-times' />
									</button>
								</div>
							</div>
						</div>
						{progress > 0 ? (
							<Progress className='!h-1 !rounded-t-none' value={progress} />
						) : undefined}
					</div>
				</Fragment>
			) : (
				<Dropzone
					onDrop={onDrop}
					className='h-24 max-w-sm'
					maxSize={Number(maxSize)}
					accept={accept}
					maxFiles={1}
					multiple={false}
				/>
			)}
		</Fragment>
	)
}

export default FileUploader
