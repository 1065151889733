import { ReactComponent as LineIcon } from 'assets/svg/line-icon.svg'
import FileCard from './file-card'

const FilesContainer = ({ files }) => {
	return (
		<div className='grid grid-cols-1 gap-3'>
			<div className='flex items-center'>
				<h4 className='m-0 text-sm font-medium text-gray2-dark'>
					{files.length} Documents
				</h4>
				<span>
					<LineIcon height={10} width={30} />
				</span>
			</div>
			{files.map((file, idx) => (
				<FileCard key={idx} file={file} />
			))}
		</div>
	)
}

export default FilesContainer
