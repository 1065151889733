import internationalNumber from 'assets/svg/internationalNumber.svg'
import { parsePhoneNumber } from 'react-phone-number-input'
import PhoneNumberField from './phone-number-field'

export function getFlagURLfromCode(countryShortName) {
	if (!countryShortName) {
		return internationalNumber
	}

	return `http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryShortName}.svg` // https://www.npmjs.com/package/country-flag-icons
}

export const getParsePhoneNumber = (value) => {
	try {
		return parsePhoneNumber(value)
	} catch (error) {
		return null
	}
}

export default PhoneNumberField
