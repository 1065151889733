export const documentEventLog = {
	eventTypes: {
		SignatureFieldCreation: 'SignatureFieldCreation',
		SignatureInsertion: 'SignatureInsertion',
		DocumentPreview: 'DocumentPreview',
	},
	moduleTypes: {
		ClientWorkflowFulfillment: 'ClientWorkflowFulfillment',
		DocumentTemplateCreation: 'DocumentTemplateCreation',
		CompletedDocumentPreview: 'CompletedDocumentPreview',
	},
}
