const FieldsTable = ({ fields }) => {
	return (
		<div className='table-responsive'>
			<table className='table-border table-1 mb-0 border text-sm'>
				<thead>
					<tr className='divide-x'>
						<th>#</th>
						<th>Field Label</th>
						<th>Field Value</th>
					</tr>
				</thead>
				<tbody>
					{fields.length > 0 ? (
						fields.map((field, idx) => (
							<tr key={idx} className='divide-x'>
								<td className='vertical-middle'>{idx + 1}</td>
								<td className='vertical-middle'>{field?.label}</td>
								<td className='vertical-middle'>{field?.value}</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan={5}>
								<p className='m-0 text-center'>No fields found here</p>
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	)
}

export default FieldsTable
