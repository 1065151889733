import { Fragment, useEffect, useRef, useState } from 'react'

import { useUploadFileMutation } from 'modules/files/file-apis'
import FilePreviewCard from 'theme/ui/file-preview-card'
import { FileUploadField } from 'theme/ui/forms'
import notification from 'utilities/notification'

const FileUploadCard = ({ file, title, onSetFileId }) => {
	const [document, setDocument] = useState(null)
	const [isUploading, setIsUploading] = useState(false)
	const [uploadFile] = useUploadFileMutation()
	const fileRef = useRef(null)

	useEffect(() => {
		if (file) {
			setDocument(file)
		}
	}, [file])

	const onChange = async ($file) => {
		try {
			if ($file) {
				setIsUploading(true)
				onSetFileId(null)
				const formData = new FormData()
				formData.append('file', $file)
				const response = await uploadFile({ payload: formData })
				setIsUploading(false)
				if (response?.data?.status === 200) {
					notification(
						'success',
						'File uploaded successfully, please click on update button to save',
					)

					onSetFileId(response?.data?.data?.fileID)
				}
			}
		} catch (error) {
			setIsUploading(false)
		}
	}

	return (
		<Fragment>
			{document ? (
				<div>
					<div className='relative -left-[2%] top-[2%]'>
						<span
							className='absolute -right-3 -top-3 mt-1 flex h-7 w-7 cursor-pointer items-center justify-center rounded-full !border bg-white text-sm hover:!bg-main/10 hover:text-main'
							onClick={() => {
								onSetFileId(null)
								setDocument(null)
							}}>
							<i className='uil-pen'></i>
						</span>
					</div>
					<FilePreviewCard
						path={document?.path}
						mime_type={document?.mime_type}
						name={document?.name}
						title={title}
					/>
				</div>
			) : (
				<div>
					<FileUploadField
						isUploading={isUploading}
						onUpload={(file) => onChange(file)}
						isMarginBottom={false}
						fileRef={fileRef}
						allowedExtentions='.jpg,.jpeg,.png,.pdf'
					/>
					{file ? (
						<span
							className='float-right -mt-1 cursor-pointer text-sm text-main hover:underline'
							onClick={() => {
								setDocument(file)
								onSetFileId(null)
							}}>
							Cancel
						</span>
					) : undefined}
				</div>
			)}
		</Fragment>
	)
}

export default FileUploadCard
