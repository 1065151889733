import WatcherDropdown from './WatcherDropdown'

export default WatcherDropdown

export const createWatcherOption = (watcher) => ({
	fullName: `${watcher?.first_name} ${watcher?.last_name}`,
	email: watcher?.email,
	value: watcher?.id,
})

export const createWatcherOptionLabel = (watcher) => {
	return (
		<div className='flex items-center space-x-1'>
			<span className='font-bold text-gray-700'>{watcher?.fullName}</span>
			<span className='text-sm text-gray-500'>({watcher?.email})</span>
		</div>
	)
}