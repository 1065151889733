import { createApi } from '@reduxjs/toolkit/query/react'

import apiEndpoints from 'helpers/apiEndpoints'
import axiosBaseQuery from 'store/axios-base-query'
import { transformChangedFieldsResponse } from './profile-update'

export const authApi = createApi({
	reducerPath: 'Authentication',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	tagTypes: ['Profile'],
	baseQuery: axiosBaseQuery,
	endpoints: (builder) => ({
		verifyOtp: builder.mutation({
			query: (payload) => ({
				url: apiEndpoints.auth.verifyOtp,
				method: 'POST',
				data: payload,
			}),
		}),
		getChangedFields: builder.query({
			query: (token) => ({
				url: `${apiEndpoints.profileInformation}/${token}`,
				method: 'GET',
			}),
			transformResponse: (response) => transformChangedFieldsResponse(response),
		}),
		updateChangedFields: builder.mutation({
			query: (payload) => ({
				url: apiEndpoints.profileInformation,
				method: 'POST',
				data: payload,
			}),
		}),
		getNylas: builder.query({
			query: () => ({
				url: apiEndpoints.nylas.add,
				method: 'GET',
			}),
			providesTags: ['Profile'],
		}),
		disconnectNylas: builder.mutation({
			query: (id) => ({
				url: `${apiEndpoints.nylas.remove}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Profile'],
		}),
		getProfile: builder.query({
			query: () => ({
				url: apiEndpoints.user.profile,
				method: 'GET',
			}),
			providesTags: ['Profile'],
		}),
		updateProfile: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${apiEndpoints.user.profile}/${id}`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['Profile'],
		}),
		uploadDocument: builder.mutation({
			query: (payload) => ({
				url: apiEndpoints.userOnBoard.verifyDocument,
				method: 'POST',
				data: payload,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}),
			invalidatesTags: ['Profile'],
		}),
	}),
})

export const {
	useVerifyOtpMutation,
	useLazyGetChangedFieldsQuery,
	useUpdateChangedFieldsMutation,
	useDisconnectNylasMutation,
	useGetProfileQuery,
	useLazyGetNylasQuery,
	useUpdateProfileMutation,
	useUploadDocumentMutation,
} = authApi
