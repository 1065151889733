import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'

const RoleOptionsQuery = () => {
	const { data, isLoading } = useQuery(apiEndpoints.group.groups)
	return {
		roles: Array.isArray(data?.data?.groups) ? data?.data?.groups : [],
		isLoading,
	}
}

export default RoleOptionsQuery
