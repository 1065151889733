import { flexRender } from '@tanstack/react-table'
import sortingIcon from 'assets/svg/sorting-icon.svg'
import classNames from 'classnames'

const TableHeader = ({ table }) => {
	return (
		<thead>
			{table.getHeaderGroups().map((headerGroup) => {
				return (
					<tr key={headerGroup.id}>
						{headerGroup.headers.map((header, idx) => {
							const border = headerGroup.headers.length !== idx + 1 ? '!border-r' : ''
							return (
								<th key={header.id} style={{ width: `${header.getSize()}px` }}>
									{header.isPlaceholder ? null : (
										<div
											{...{
												className: classNames(
													'!my-3  border-[#CED4DA] text-[#74788D] text-[13px] px-3',
													border,
													{
														'cursor-pointer select-none flex justify-between':
															header.column.getCanSort(),
													},
												),

												onClick: header.column.getToggleSortingHandler(),
											}}>
											{flexRender(
												header.column.columnDef.header,
												header.getContext(),
											)}
											<span>
												{{
													asc: <img src={sortingIcon} />,
													desc: (
														<img
															src={sortingIcon}
															className='rotate-180'
														/>
													),
												}[header.column.getIsSorted()] ?? null}
											</span>
										</div>
									)}
								</th>
							)
						})}
					</tr>
				)
			})}
		</thead>
	)
}

export default TableHeader
