import { useEffect } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

const DocumentPreview = ({ url, isOpen, toggle }) => {
	useEffect(() => {
		const body = document.getElementsByTagName('body')[0].style

		return () => {
			body.paddingRight = 0
		}
	}, [isOpen])

	return (
		<Modal isOpen={isOpen} toggle={toggle}>
			<ModalHeader toggle={toggle}>Document Preview</ModalHeader>
			<ModalBody className='p-0'>
				<embed title='Document Preview' src={url} className='min-h-full w-full !border' />
			</ModalBody>
		</Modal>
	)
}

export default DocumentPreview
