import CommentCard from 'modules/assigned-workflows/comment-card'
import { Button } from 'theme/ui/forms'
import FilesContainer from './files-container'

const WorkflowBegin = ({ files, backgroundColor, colorName, onClose, notes }) => {
	return (
		<div className='grid grid-cols-1 gap-4'>
			<FilesContainer files={files} />
			<CommentCard backgroundColor={backgroundColor} colorName={colorName} comment={notes} />

			<Button block={true} size='lg' onClick={onClose}>
				Let&apos;s Start
			</Button>
		</div>
	)
}

export default WorkflowBegin
