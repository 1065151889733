/* eslint-disable react-hooks/exhaustive-deps */
import { createColumnHelper } from '@tanstack/react-table'

import { Fragment, useState } from 'react'
import { ReactDataTable } from 'theme/ui/data-table'
import CreateNewNote from './CreateNewNote'
import FilterForm from './FilterForm'
import useNoteViewQuery from './useNoteViewQuery'

const Notes = ({ client, role }) => {
	const [isOpen, setIsOpen] = useState(false)
	const [note, setNote] = useState(null)

	function removeBodyCss() {
		document.body.classList.add('no_padding')
	}

	const onOpen = (note) => {
		removeBodyCss()
		setNote(note)
		setIsOpen(true)
	}
	const onClose = () => {
		setNote(null)
		setIsOpen(false)
	}

	const columnHelper = createColumnHelper()
	const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 })
	const { data, meta, isLoading } = useNoteViewQuery(pagination, client, role)

	const columns = [
		columnHelper.accessor('index', {
			id: 'index',
			size: 40,
			header: () => <span>#</span>,
			cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		}),

		columnHelper.accessor((row) => row.note, {
			id: 'note',
			size: 200,
			header: () => <span>Note</span>,
			cell: (info) => <span>{info.getValue()}</span>,
		}),
		columnHelper.accessor((row) => row.sharing, {
			id: 'sharing',
			size: 200,
			header: () => <span>Sharing</span>,
			cell: (info) => <span>{info.getValue()}</span>,
		}),
		columnHelper.accessor((row) => row, {
			id: 'actions',
			size: 100,
			header: () => <span>Actions</span>,
			cell: (info) => (
				<button
					type='button'
					onClick={() => onOpen(info.getValue())}
					className='action-btn btn-primary-outline'>
					<i className='far fa-edit' />
					<span>Edit</span>
				</button>
			),
		}),
	]

	return (
		<Fragment>
			<CreateNewNote
				client={client}
				isOpen={isOpen}
				note={note}
				onClose={() => onClose(null)}
			/>
			<div className='card !rounded-lg shadow-none'>
				<div className='card-header !border-none !bg-[#F2F5FF] p-4 py-3'>
					<h5 className='mb-0 font-bold'>Notes</h5>
					<div>
						<button
							className='btn btn-primary'
							onClick={() => onOpen(null)}
							size='sm'
							color='primary'>
							<i className='uil uil-plus' /> Create Note
						</button>
					</div>
				</div>
				<div className='card-body'>
					<FilterForm />
					<ReactDataTable
						columns={columns}
						data={data}
						isLoading={isLoading}
						pagination={pagination}
						setPagination={setPagination}
						meta={meta}
					/>
				</div>
			</div>
		</Fragment>
	)
}

export default Notes
