import { Field } from 'formik'
import { SelectField } from 'theme/ui/forms'
import { createOptionLabel } from '.'
import RolesQuery from './roles-query'

const RolesDropdown = () => {
	const { roles, isLoading } = RolesQuery()
	return (
		<Field
			label='Select Role'
			name='role'
			type='select'
			isRequiredField
			options={roles}
			component={SelectField}
			isLoading={isLoading}
			isSearchable={false}
			isDisabled={true}
			getOptionLabel={(option) => createOptionLabel(option, true)}
		/>
	)
}

export default RolesDropdown
