import { useParams } from 'react-router-dom'

import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const FeatureDetails = () => {
	const params = useParams()

	return (
		<AppLayout
			meta={{
				title: 'Features Details',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<PageHeader
						title='Features'
						hasBackButton={true}
						description='Easily manage your Features hassle-free from one convenient dashboard. Stay in control of your spending, effortlessly track renewal dates, and make changes with just a few clicks, all in one centralized hub designed for your convenience'
					/>

					<div className='page-body'>Feature details</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default FeatureDetails
