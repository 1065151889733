import DangerZone from './danger-zone'
import WorkflowTable from './workflow-table'

const DocumentWorkflows = ({ id, fetchDocument, workflows, totalWorkflows }) => {
	return (
		<div className='mx-auto my-6 max-w-lg rounded-md bg-white p-3'>
			<div className='flex flex-col gap-4'>
				<div>
					<h3 className='text-lg font-bold text-gray-800'>
						Document is in used with {totalWorkflows} workflow(s)
					</h3>
					<p className='m-0 text-sm text-gray-500'>
						You must wait until all assigned workflows are completed before editing this
						document, or you must cancel all workflows prior to making any changes to
						this document.
					</p>
				</div>
				<WorkflowTable workflows={workflows} />

				<DangerZone id={id} fetchDocument={fetchDocument} />
			</div>
		</div>
	)
}

export default DocumentWorkflows
