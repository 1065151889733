import { Fragment, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import { useLazyGetEmailTemplatesQuery } from '../email-templates-api'
import EmailTemplatesFilters from './email-templates-filters'
import EmailTemplatesTable from './email-templates-table'

const EmailTemplatesListingContainer = () => {
	const location = useLocation()
	const history = useHistory()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination
	const parseQueryString = getParseQueryString(location?.search)

	useEffect(() => {
		if (pagination) {
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			history.push(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	const [fetchEmailTemplates, { data, isFetching }] = useLazyGetEmailTemplatesQuery()

	useEffect(() => {
		if (location?.search) {
			fetchEmailTemplates({
				...parseQueryString,
			})
		}
	}, [location?.search])

	return (
		<Fragment>
			<EmailTemplatesFilters setPagination={setPagination} />
			<EmailTemplatesTable
				data={data?.emailTemplates}
				meta={data?.meta}
				isLoading={isFetching}
				pagination={pagination}
				setPagination={setPagination}
			/>
		</Fragment>
	)
}

export default EmailTemplatesListingContainer
