import { AllFoldersListingContainer, FileManagerLayout } from 'modules/file-manager'
import { TableProvider } from 'theme/ui/data-table'

const AllFolders = () => {
	return (
		<FileManagerLayout
			meta={{
				title: 'File Manager - All Folders',
			}}>
			<div>
				<TableProvider>
					<AllFoldersListingContainer />
				</TableProvider>
			</div>
		</FileManagerLayout>
	)
}

export default AllFolders
