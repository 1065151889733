import { Dialog, Transition } from '@headlessui/react'
import apiEndpoints from 'helpers/apiEndpoints'
import { useState } from 'react'
import { Button } from 'theme/ui/forms'
import notification from 'utilities/notification'
import request from 'utilities/request'

const DeleteConfirmAlert = ({ isOpen, onClose, id, mutate }) => {
	const [isDeleting, setIsDeleting] = useState(false)

	const onConfirm = (id, onClose) => {
		const URL = `${apiEndpoints.systemFields}/${id}`
		setIsDeleting(true)
		request
			.destroy(URL)
			.then((response) => {
				setIsDeleting(false)
				if (response?.status === 200) {
					notification('success', response?.message)
					onClose()
					mutate()
				}
			})
			.catch(() => {
				setIsDeleting(false)
				onClose()
			})
	}
	return (
		<Transition
			show={isOpen}
			enter='transition duration-100 ease-out'
			enterFrom='transform scale-95 opacity-0'
			enterTo='transform scale-100 opacity-100'
			leave='transition duration-75 ease-out'
			leaveFrom='transform scale-100 opacity-100'
			leaveTo='transform scale-95 opacity-0'
			as='div'>
			<Dialog as='div' open={true} onClose={() => {}} className='popup-overlay relative'>
				<div className='fixed inset-0 bg-black/30' aria-hidden='true' />
				<div className='fixed inset-0 flex w-screen items-center justify-center p-4'>
					<Dialog.Panel className='w-96 rounded-md bg-white'>
						<div className='rounded-t px-8 py-4'>
							<div className='relative left-[8%] top-[8%]'>
								<button
									type='button'
									className='absolute -top-4 right-0'
									onClick={onClose}>
									<i className='fas fa-times text-lg' />
								</button>
							</div>
							<div className='mx-auto'>
								<h1 className='mb-1 text-center text-lg font-bold'>
									Delete system field?
								</h1>
								<p className='m-0 text-center text-sm font-normal text-gray-500'>
									Are you sure you want to delete this System field
								</p>
							</div>
						</div>
						<div className='px-8 py-[20px]'>
							<div className='mx-auto flex w-fit items-center space-x-3'>
								<Button variant='white' size='sm' onClick={onClose}>
									<span>No, Cancel</span>
								</Button>

								<Button
									variant='danger'
									size='sm'
									isLoading={isDeleting}
									isLoadingText='Deleting...'
									onClick={() => onConfirm(id, onClose)}>
									<div className='flex items-center space-x-2'>
										<span>Yes, I&apos;m Sure</span>
									</div>
								</Button>
							</div>
						</div>
					</Dialog.Panel>
				</div>
			</Dialog>
		</Transition>
	)
}

export default DeleteConfirmAlert
