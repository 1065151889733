import {
    Card,
    CardBody,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
} from 'reactstrap'
import SimpleBar from 'simplebar-react'

const RecentActivity = () => {
	return (
		<Card>
			<CardBody>
				<div className='float-end'>
					<UncontrolledDropdown>
						<DropdownToggle
							tag='a'
							className='text-reset'
							id='dropdownMenuButton5'
							caret>
							<span className='text-muted'>
								Recent<i className='mdi mdi-chevron-down ms-1'></i>
							</span>
						</DropdownToggle>
						<DropdownMenu className='dropdown-menu-end'>
							<DropdownItem href='#'>Recent</DropdownItem>
							<DropdownItem href='#'>By Users</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>
				</div>

				<h4 className='card-title mb-4'>Recent Activity</h4>

				<SimpleBar className='activity-feed mb-0 ps-2' style={{ maxHeight: '300px' }}>
					<li className='feed-item'>
						<div className='feed-item-list'>
							<p className='text-muted font-size-13 mb-1'>
								Today<small className='d-inline-block ms-1'>12:20 pm</small>
							</p>
							<p className='mb-0 mt-0'>
								<span className='text-primary'>Deposit $5,500</span>
							</p>
						</div>
					</li>
					<li className='feed-item'>
						<p className='text-muted font-size-13 mb-1'>
							22 Jul, 2020 <small className='d-inline-block ms-1'>12:36 pm</small>
						</p>
						<p className='mb-0 mt-0'>
							<span className='text-primary'>Dividend $0.56 | XBB</span>
						</p>
					</li>
					<li className='feed-item'>
						<p className='text-muted font-size-13 mb-1'>
							18 Jul, 2020 <small className='d-inline-block ms-1'>07:56 am</small>
						</p>
						<p className='mb-0 mt-0'>
							<span className='text-primary'>
								Transfer $55.000 from account ending 3490
							</span>
						</p>
					</li>
					<li className='feed-item'>
						<p className='text-muted font-size-13 mb-1'>
							10 Jul, 2020 <small className='d-inline-block ms-1'>08:42 pm</small>
						</p>
						<p className='mb-0 mt-0'>
							<span className='text-primary'>Management fee - $220.50</span>
						</p>
					</li>

					<li className='feed-item'>
						<p className='text-muted font-size-13 mb-1'>
							23 Jun, 2020 <small className='d-inline-block ms-1'>12:22 am</small>
						</p>
						<p className='mb-0 mt-0'>
							<span className='text-primary'>Dividend $0.60 | XBB</span>
						</p>
					</li>
				</SimpleBar>
			</CardBody>
		</Card>
	)
}

export default RecentActivity
