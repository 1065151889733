import AssigneeDropdown from './AssigneeDropdown'

export default AssigneeDropdown

export const createAssigneeOption = (assignee) => ({
	fullName: `${assignee?.first_name} ${assignee?.last_name}`,
	email: assignee?.email,
	value: assignee?.id,
})

export const createAssigneeOptionLabel = (assignee) => {
	return (
		<div className='flex items-center space-x-1'>
			<span className='font-bold text-gray-700'>{assignee?.fullName}</span>
			<span className='text-sm text-gray-500'>({assignee?.email})</span>
		</div>
	)
}
