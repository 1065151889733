import { Fragment, useState } from 'react'

import { useUser } from 'hooks'
import { useCancelSubscriptionMutation } from 'modules/subscriptions/user-subscriptions-api'
import DeleteConfirmAlert from 'theme/ui/delete-confirm-alert'
import { Button } from 'theme/ui/forms'
import notification from 'utilities/notification'

const DangerZone = ({ subscription }) => {
	const [isOpen, setIsOpen] = useState(false)
	const { user } = useUser()
	const [cancelSubscription, { isLoading: isCancelling }] = useCancelSubscriptionMutation()

	const onCancelSubscription = async (subscription, user) => {
		try {
			const payload = {
				user_id: user?.id,
				user_has_subscriptions_id: subscription?.id,
			}

			await cancelSubscription(payload)
			setIsOpen(false)
		} catch (error) {
			notification('error', error?.message)
		}
	}

	return (
		<Fragment>
			<DeleteConfirmAlert
				isDeleting={isCancelling}
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				onConfirm={() => onCancelSubscription(subscription, user)}
				subtitle='Are you sure you want to cancel your subscription? Once you do, you will lose access to your team.'
				title='Cancel Subscription'
			/>
			<div>
				<div className='!mb-3 flex items-center justify-between'>
					<h3 className='m-0 text-lg font-medium text-gray-600'>Danger Zone</h3>
				</div>

				<div className='rounded-lg !border border-red-200 !px-4 py-3'>
					<div className='items-center justify-between sm:m-3 sm:flex sm:gap-6'>
						<div className='flex flex-col'>
							<span className='text-sm font-semibold text-gray-950'>
								Cancel Subscription
							</span>
							<span className='text-[13px] text-gray-500'>
								Cancel your subscription here. Once you do, you will no longer have
								access to your team.
							</span>
						</div>
						<Button
							variant='danger'
							size='sm'
							onClick={() => setIsOpen(true)}
							className={'mt-3 sm:m-0'}>
							Cancel Now
						</Button>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default DangerZone
