import { FileManagerLayout, ReadymadeTemplateListingContainer } from 'modules/file-manager'
import { TableProvider } from 'theme/ui/data-table'

const ReadymadeTemplates = () => {
	return (
		<FileManagerLayout
			meta={{
				title: 'File Manager - Readymade Templates',
			}}>
			<div>
				<TableProvider>
					<ReadymadeTemplateListingContainer />
				</TableProvider>
			</div>
		</FileManagerLayout>
	)
}

export default ReadymadeTemplates
