import WebViewer from '@pdftron/webviewer'
import { Fragment, useEffect, useRef, useState } from 'react'

import { SoftLoader } from 'components/Common'
import { documentEventLog } from 'modules/document-event-log'
import { useCreateDocumentEventLogMutation } from 'modules/document-event-log/document-event-log-api'
import PdfPreviewSkeleton from 'modules/documents/document-template-form/pdf-preview-skeleton'
import { useLazyGetSettingQuery } from 'modules/settings/settings-api'
import { useSetDocumentViewer } from 'pages/Authentication/profile/components/workflow/document-viewer-provider'
import notification from 'utilities/notification'
import { disabledElements } from './config'

const PdfPreview = ({ url, documentId, isAutoSaving }) => {
	const viewer = useRef(null)
	const [isLoading, setIsLoading] = useState(true)
	const [createDocumentEventLog] = useCreateDocumentEventLogMutation()
	const [getSetting] = useLazyGetSettingQuery()
	const setDocumentViewer = useSetDocumentViewer()

	useEffect(() => {
		// Function to handle component loading
		const checkIfLoaded = () => {
			// Check if the element referenced is available in the DOM
			if (viewer.current) {
				// Hide the loader once the component is loaded
				setIsLoading(false)
			}
		}

		// Use a timeout to simulate loading or data fetching (if needed)
		const loadingTimeout = setTimeout(checkIfLoaded, 1000) // Adjust delay as needed

		return () => clearTimeout(loadingTimeout) // Cleanup timeout on unmount
	}, [])

	const webviewerInstance = async (fileUrl) => {
		const response = await getSetting('pdf_tron_apikey')
		const settingValue = response?.data?.setting
		try {
			if (settingValue?.status === 200 && settingValue?.data?.setting_value) {
				const instance = await WebViewer(
					{
						path: '/lib',
						initialDoc: fileUrl,
						fullAPI: true,
						disabledElements: disabledElements,
						licenseKey: settingValue?.data?.setting_value,
					},
					viewer.current,
				)
				setDocumentViewer(instance)
				return instance
			}
		} catch (error) {
			notification(error)
		}
	}

	useEffect(() => {
		if (documentId) {
			createDocumentEventLog({
				document_id: documentId,
				event_type: documentEventLog.eventTypes.DocumentPreview,
				module_type: documentEventLog.moduleTypes.DocumentTemplateCreation,
			})
		}
	}, [documentId])

	useEffect(() => {
		if (url !== '') {
			webviewerInstance(url)
		}

		return () => {
			viewer.current = null
		}
	}, [url])

	return (
		<Fragment>
			{!isLoading && isAutoSaving ? <SoftLoader /> : undefined}
			{isLoading ? <PdfPreviewSkeleton /> : undefined}
			<div className='MyComponent'>
				<div className='webviewer' ref={viewer} style={{ height: '100vh' }}></div>
			</div>
		</Fragment>
	)
}

export default PdfPreview
