import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useRef, useState } from 'react'

import { Button } from 'theme/ui/forms'

const TermsAndServicesPopup = ({ isOpen, onClose, onAgree }) => {
	const containerRef = useRef(null)
	const [isDisabled, setIsDisabled] = useState(true)

	const onScroll = () => {
		if (containerRef.current) {
			const { scrollTop, offsetHeight, scrollHeight } = containerRef.current
			const scrollAmount = scrollTop + offsetHeight
			const isReaded = scrollHeight - scrollAmount
			if (isReaded <= 10) {
				setIsDisabled(false)
			} else {
				setIsDisabled(true)
			}
		}
	}

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-10' onClose={onClose}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/65' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'>
							<Dialog.Panel className='max-h-[90vh] w-full max-w-lg transform overflow-hidden rounded-md bg-white p-3 text-left align-middle shadow-xl transition-all'>
								<Dialog.Title
									as='h3'
									className='mb-6 text-center text-xl font-bold leading-6 text-gray-900'>
									Terms of Service
								</Dialog.Title>
								<div
									className='mt-2 max-h-[70vh] overflow-y-scroll'
									ref={containerRef}
									id='content-container'
									onScroll={onScroll}>
									<div>
										of Terms: By accessing or using this service, you agree to
										be bound by these terms and conditions. User Conduct: Users
										are prohibited from engaging in any activity that may
										disrupt the service or infringe upon the rights of others.
										Intellectual Property: All content provided through this
										service is protected by copyright and other intellectual
										property laws. Privacy Policy: Our privacy policy outlines
										how we collect, use, and disclose personal information. By
										using this service, you consent to our privacy practices.
										Limitation of Liability: We are not liable for any damages
										or losses arising from the use of this service, including
										but not limited to, direct, indirect, incidental, or
										consequential damages. Governing Law: These terms are
										governed by the laws of [Jurisdiction], and any disputes
										shall be resolved in the courts of [Jurisdiction].
										Modification of Terms: We reserve the right to modify these
										terms at any time without prior notice. Continued use of the
										service after any such changes constitutes acceptance of the
										modified terms. Termination: We reserve the right to
										terminate or suspend access to the service at any time,
										without prior notice, for any reason or no reason at all.
										Indemnification: Users agree to indemnify and hold harmless
										the service provider from any claims, damages, or
										liabilities arising out of their use of the service.
										Severability: If any provision of these terms is found to be
										invalid or unenforceable, the remaining provisions shall
										remain in full force and effect. Acceptance of Terms: By
										accessing or using this service, you agree to be bound by
										these terms and conditions. User Conduct: Users are
										prohibited from engaging in any activity that may disrupt
										the service or infringe upon the rights of others.
										Intellectual Property: All content provided through this
										service is protected by copyright and other intellectual
										property laws. Privacy Policy: Our privacy policy outlines
										how we collect, use, and disclose personal information. By
										using this service, you consent to our privacy practices.
										Limitation of Liability: We are not liable for any damages
										or losses arising from the use of this service, including
										but not limited to, direct, indirect, incidental, or
										consequential damages. Governing Law: These terms are
										governed by the laws of [Jurisdiction], and any disputes
										shall be resolved in the courts of [Jurisdiction].
										Modification of Terms: We reserve the right to modify these
										terms at any time without prior notice. Continued use of the
										service after any such changes constitutes acceptance of the
										modified terms. Termination: We reserve the right to
										terminate or suspend access to the service at any time,
										without prior notice, for any reason or no reason at all.
										Indemnification: Users agree to indemnify and hold harmless
										the service provider from any claims, damages, or
										liabilities arising out of their use of the service.
										Severability: If any provision of these terms is found to be
										invalid or unenforceable, the remaining provisions shall
										remain in full force and effect.
									</div>
									<div>
										of Terms: By accessing or using this service, you agree to
										be bound by these terms and conditions. User Conduct: Users
										are prohibited from engaging in any activity that may
										disrupt the service or infringe upon the rights of others.
										Intellectual Property: All content provided through this
										service is protected by copyright and other intellectual
										property laws. Privacy Policy: Our privacy policy outlines
										how we collect, use, and disclose personal information. By
										using this service, you consent to our privacy practices.
										Limitation of Liability: We are not liable for any damages
										or losses arising from the use of this service, including
										but not limited to, direct, indirect, incidental, or
										consequential damages. Governing Law: These terms are
										governed by the laws of [Jurisdiction], and any disputes
										shall be resolved in the courts of [Jurisdiction].
										Modification of Terms: We reserve the right to modify these
										terms at any time without prior notice. Continued use of the
										service after any such changes constitutes acceptance of the
										modified terms. Termination: We reserve the right to
										terminate or suspend access to the service at any time,
										without prior notice, for any reason or no reason at all.
										Indemnification: Users agree to indemnify and hold harmless
										the service provider from any claims, damages, or
										liabilities arising out of their use of the service.
										Severability: If any provision of these terms is found to be
										invalid or unenforceable, the remaining provisions shall
										remain in full force and effect. Acceptance of Terms: By
										accessing or using this service, you agree to be bound by
										these terms and conditions. User Conduct: Users are
										prohibited from engaging in any activity that may disrupt
										the service or infringe upon the rights of others.
										Intellectual Property: All content provided through this
										service is protected by copyright and other intellectual
										property laws. Privacy Policy: Our privacy policy outlines
										how we collect, use, and disclose personal information. By
										using this service, you consent to our privacy practices.
										Limitation of Liability: We are not liable for any damages
										or losses arising from the use of this service, including
										but not limited to, direct, indirect, incidental, or
										consequential damages. Governing Law: These terms are
										governed by the laws of [Jurisdiction], and any disputes
										shall be resolved in the courts of [Jurisdiction].
										Modification of Terms: We reserve the right to modify these
										terms at any time without prior notice. Continued use of the
										service after any such changes constitutes acceptance of the
										modified terms. Termination: We reserve the right to
										terminate or suspend access to the service at any time,
										without prior notice, for any reason or no reason at all.
										Indemnification: Users agree to indemnify and hold harmless
										the service provider from any claims, damages, or
										liabilities arising out of their use of the service.
										Severability: If any provision of these terms is found to be
										invalid or unenforceable, the remaining provisions shall
										remain in full force and effect.
									</div>
								</div>

								<div className='mt-4 flex items-center justify-center space-x-3'>
									<Button
										type='button'
										size='sm'
										variant='white'
										onClick={onClose}>
										Decline
									</Button>
									<Button
										type='button'
										size='sm'
										disabled={isDisabled}
										onClick={() => {
											onAgree()
											onClose()
										}}>
										Accept
									</Button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export default TermsAndServicesPopup
