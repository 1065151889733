import { Field, FormikProvider, useFormik } from 'formik'
import { Fragment } from 'react'

import {
	useCreateDocumentPackageMutation,
	useUpdateDocumentPackageMutation,
} from 'modules/documents/document-package-api'
import DocumentTemplateDropdown from 'modules/documents/document-template-dropdown'
import { Button, InputField } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { validationSchema } from '../validate'

const Form = ({ initialValues, onClose, id }) => {
	const [updateDocumentPackage] = useUpdateDocumentPackageMutation()
	const [createDocumentPackage] = useCreateDocumentPackageMutation()

	const formik = useFormik({
		initialValues: initialValues || {
			title: '',
			documents: [],
		},
		enableReinitialize: true,
		validationSchema: validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true)
				const payload = {
					...values,
					documents: values?.documents.map((item) => ({ id: item?.value })),
				}
				const response = id
					? await updateDocumentPackage({ payload, id })
					: await createDocumentPackage(payload)
				if (response?.data?.status === 200) {
					notification('success', response?.data?.message)
					onClose()
				}
				setSubmitting(false)
			} catch (error) {
				setSubmitting(false)
			}
		},
	})

	return (
		<Fragment>
			<FormikProvider value={formik}>
				<form onSubmit={formik.handleSubmit}>
					<div className='row g-3'>
						<div className='col-sm-12'>
							<Field
								label='Package Title'
								labelHelperText={true}
								isRequiredField={true}
								name='title'
								component={InputField}
								className={{ labelClass: 'w-full' }}
							/>
						</div>

						<div className='col-sm-12'>
							<DocumentTemplateDropdown name='documents' />
						</div>
						<div className='col-sm-12'>
							<hr className='my-2' />
						</div>
						<div className='col-sm-12'>
							<div className='flex justify-end space-x-2'>
								<button
									className='btn btn-outline-secondary'
									loading={formik.isSubmitting}
									onClick={onClose}
									type='button'>
									Cancel
								</button>
								<Button
									isLoading={formik.isSubmitting}
									isLoadingText='Saving...'
									type='submit'>
									Save & Submit
								</Button>
							</div>
						</div>
					</div>
				</form>
			</FormikProvider>
		</Fragment>
	)
}

export default Form
