import { useMemo } from 'react'
import TagList from '../../list-view/tag-list'

const Tags = ({ tags }) => {
	const _tags = useMemo(() => (Array.isArray(tags) ? tags : []), [tags])

	return (
		<div className='flex-wrap space-x-1'>
			<TagList tags={_tags} />,
		</div>
	)
}

export default Tags
