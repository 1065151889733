import { useState } from 'react'
import { Tooltip } from 'reactstrap'

const ToolTip = ({ targetId, tooltipText, placement = 'top' }) => {
	const [tooltipOpen, setTooltipOpen] = useState(false)
	const toggle = () => setTooltipOpen(!tooltipOpen)

	return (
		<Tooltip isOpen={tooltipOpen} target={targetId} toggle={toggle} placement={placement}>
			{tooltipText}
		</Tooltip>
	)
}

export default ToolTip
