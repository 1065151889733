import { useMemo, useState } from 'react'
import ViewByDropdown from '../viewBy-filter'
import StarredTable from './starred-table'

const StarredListingContainer = () => {
	const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 })

	const data = useMemo(
		() =>
			new Array(8).fill(0).map(() => ({
				title: 'SIN Number Application',
				files: [2, 13],
				last_edit: new Date(),
				children: new Array(6).fill(0).map((_, idx) => ({
					title: 'Passport Application',
					type: idx <= 1 ? 'folder' : 'document',
					noOfFiles: 42,
					folders: ['RBC Bank', 'Applications 1', 'Applications 2'],
					last_edit: new Date(),
				})),
			})),
		[],
	)
	const meta = useMemo(
		() => ({
			currentPage: 1,
			limit: 1000,
			totalPages: 1,
			totalRecords: 3,
			hasNextPage: false,
			hasPrevPage: false,
			nextPage: null,
			prevPage: null,
		}),
		[],
	)

	return (
		<div className='divide-y'>
			<div className='flex items-center justify-between pb-3'>
				<h3 className='m-0 text-base'>Starred</h3>
				{/* <button type='button' className='cursor-pointer text-main hover:!underline'>
					<i>+</i>
					<span className='ml-1'>New Folder</span>
				</button> */}
				<ViewByDropdown />
			</div>

			<div className='pt-4'>
				<StarredTable
					data={data}
					pagination={pagination}
					meta={meta}
					setPagination={setPagination}
					isLoading={false}
				/>
			</div>
		</div>
	)
}

export default StarredListingContainer
