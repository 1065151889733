import { useLazyGetSubscriptionsQuery } from 'modules/subscriptions/subscriptions-api'
import { useEffect, useMemo } from 'react'
import { useDebounce } from 'use-debounce'
import { createSubscriptionOption } from '.'

const useSubscriptionOptionsQuery = (search) => {
	const [debounceSearch] = useDebounce(search, 800)
	const [fetchSubscriptions, { data, isFetching }] = useLazyGetSubscriptionsQuery()
	const params = useMemo(() => {
		const $params = { page: 1, limit: 10 }
		if (debounceSearch) {
			Object.assign($params, { search: debounceSearch })
		}
		return $params
	}, [debounceSearch])

	useEffect(() => {
		fetchSubscriptions(params)
	}, [params])

	const options = useMemo(
		() =>
			Array.isArray(data?.subscriptions)
				? data?.subscriptions.map((subscription) => createSubscriptionOption(subscription))
				: [],
		[data?.subscriptions],
	)

	return {
		options: options,
		isLoading: isFetching,
	}
}

export default useSubscriptionOptionsQuery
