import { createColumnHelper } from '@tanstack/react-table'
import moment from 'moment'
import { useState } from 'react'

import { ReactComponent as DocumentIcon } from 'assets/svg/document-icon.svg'
import { ReactComponent as FolderIcon } from 'assets/svg/folder-icon.svg'
import DataTable from 'theme/ui/data-table'
import Actions from '../actions'
import FoldersColumn from '../folders-column'

const TableRowBody = ({ data }) => {
	const [sorting, setSorting] = useState([])
	const [actionActiveId, setActionActiveId] = useState(null)
	const onMouseEnter = (id) => setActionActiveId(id)
	const onMouseLeave = () => setActionActiveId(null)
	const columnHelper = createColumnHelper()

	const columns = [
		columnHelper.accessor((row) => row, {
			id: 'info',
			size: 270,
			header: () => <span>Title</span>,
			cell: (info) => (
				<div className='flex items-center space-x-2'>
					{info.getValue()?.type === 'folder' ? (
						<div>
							<i className='uil-angle-right mt-1 text-[15px]'></i>
						</div>
					) : (
						<div className='w-[15px]'></div>
					)}

					<input type='checkbox' name='' className='form-check-input' id='' />
					<div className='flex items-center justify-start'>
						<span>
							{info.getValue()?.type === 'folder' ? (
								<FolderIcon height={15} />
							) : (
								<DocumentIcon height={20} />
							)}
						</span>
						<span className='my-1 ml-2 line-clamp-1 text-sm font-medium'>
							{info.getValue()?.title}
						</span>
					</div>
				</div>
			),
		}),

		columnHelper.accessor((row) => row.folders, {
			id: 'folders',
			size: 170,
			header: () => <span>Folder</span>,
			cell: (info) => <FoldersColumn key={info?.row?.id} folders={info.getValue()} />,
		}),

		columnHelper.accessor((row) => row.last_edit, {
			id: 'last_edit',
			size: 160,
			header: () => <span>Last Edited</span>,
			cell: (info) => (
				<span className='text-[13px]'>{moment(info.getValue()).calendar()}</span>
			),
		}),

		columnHelper.accessor((row) => row, {
			id: 'actions',
			header: () => <span className='float-end'>Actions</span>,
			cell: (info) => (
				<Actions
					data={info?.getValue()}
					type={info.getValue()?.type}
					isActionActive={actionActiveId === info?.row?.id}
				/>
			),
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={data}
			isLoading={false}
			setSorting={setSorting}
			sorting={sorting}
			variant='secondary'
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		/>
	)
}

export default TableRowBody
