import { SpinLoader } from 'components/Common'
import CommentCardContainer from './comment-card-container'

const CommentsListing = ({ comments, isLoading, workflowId, stepId, isReplyToComment = true }) => {
	return (
		<div className='mt-6 max-w-2xl'>
			{isLoading ? (
				<SpinLoader />
			) : (
				<>
					{comments.length > 0 ? (
						<div className='flex flex-col divide-y'>
							{comments.map((comment, idx) => (
								<CommentCardContainer
									key={idx}
									comment={comment}
									stepId={stepId}
									workflowId={workflowId}
									isReplyToComment={isReplyToComment}
								/>
							))}
						</div>
					) : (
						<p className='text-center'>No Comment is added yet</p>
					)}
				</>
			)}
		</div>
	)
}

export default CommentsListing
