import { Link } from 'react-router-dom'

import { SUBSCRIPTION_APP_ROUTES } from 'modules/subscriptions/routes'
import SubscriptionListingContainer from 'modules/subscriptions/subscription-listing'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const SubscriptionListing = () => {
	return (
		<AppLayout
			meta={{
				title: 'Subscriptions',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<PageHeader
						title='Subscriptions'
						description='Easily manage your subscriptions hassle-free from one convenient dashboard. Stay in control of your spending, effortlessly track renewal dates, and make changes with just a few clicks, all in one centralized hub designed for your convenience'>
						<Link to={SUBSCRIPTION_APP_ROUTES.create()} className='btn btn-primary'>
							Create new
						</Link>
					</PageHeader>
					<div className='page-body'>
						<SubscriptionListingContainer />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default SubscriptionListing
