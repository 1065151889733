import { Tab } from '@headlessui/react'
import classNames from 'classnames'

import { isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'
import ActiveSubscription from './active-subscription'
import SubscriptionHistory from './subscription-history'

const MySubscriptions = ({ activeTab }) => {
	const tabs = [
		{
			id: 0,
			label: 'Active Subscription',
			component: <ActiveSubscription activeTab={activeTab} />,
			permission: isAllowTo(permissions?.mySubscription?.view),
		},
		{
			id: 1,
			label: 'Subscription History',
			component: <SubscriptionHistory />,
			permission: isAllowTo(permissions?.mySubscription?.list),
		},
	].filter((t) => t?.permission)
	
	return (
		<div>
			<Tab.Group as='div'>
				<Tab.List className='!mb-5 w-full border-b border-slate-200 '>
					{tabs.map((tab) => (
						<Tab
							key={tab.id}
							className={({ selected }) =>
								classNames(
									'w-[50%] rounded-t !border px-3 py-2 text-sm font-semibold hover:bg-slate-100',
									selected
										? ' !border-b-0 border-slate-200 bg-slate-100 text-main'
										: 'border-transparent','break-on-mobile'
								)
							}>
							{tab.label}
						</Tab>
					))}
				</Tab.List>
				<Tab.Panels>
					{tabs.map((tab, idx) => (
						<Tab.Panel key={idx}>{tab?.component}</Tab.Panel>
					))}
				</Tab.Panels>
			</Tab.Group>
		</div>
	)
}

export default MySubscriptions
