import { createApi } from '@reduxjs/toolkit/query/react'

import apiEndpoints from 'helpers/apiEndpoints'
import axiosBaseQuery from 'store/axios-base-query'
import { formatDate } from 'utilities/helpers'

const API_URL = apiEndpoints.notes

export const noteApi = createApi({
	reducerPath: 'Notes',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['Note'],
	endpoints: (builder) => ({
		getNotes: builder.query({
			keepUnusedDataFor: 0,
			query: (params) => ({
				url: API_URL,
				method: 'GET',
				params: params,
			}),
			providesTags: ['Note'],
			transformResponse: (response, _, arg) => {
				const notes = Array.isArray(response?.data?.notes)
					? response?.data?.notes.map((note, idx) => ({
							...note,
							index: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + idx + 1,
							created_at: formatDate(note?.created_at),
					  }))
					: []

				return {
					notes,
					meta: response?.data?.meta,
				}
			},
		}),
		getPublicNotes: builder.query({
			keepUnusedDataFor: 0,
			query: (params) => ({
				url: `${API_URL}/dashboard`,
				method: 'GET',
				params: params,
			}),
			providesTags: ['Note'],
			transformResponse: (response, _, arg) => {
				const notes = Array.isArray(response?.data)
					? response?.data.map((note, idx) => ({
							...note,
							index: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + idx + 1,
							created_at: formatDate(note?.created_at),
					  }))
					: []

				return {
					notes,
				}
			},
		}),
		getNote: builder.query({
			keepUnusedDataFor: 0,
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'GET',
			}),
			providesTags: ['Note'],
			transformResponse: (response) => {
				const note = response?.data

				return {
					note: {
						id: note?.id,
						notes: note?.notes,
						visibility: note?.visibility.toString() || '1',
						teams:
							note?.teams.map((team) => ({
								team_name: team?.team_name,
								label: team?.team_name,
								value: team?.id,
							})) || [],
					},
				}
			},
		}),
		createNote: builder.mutation({
			query: (payload) => ({
				url: API_URL,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['Note'],
		}),
		updateNote: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${API_URL}/${id}`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['Note'],
		}),
		deleteNote: builder.mutation({
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Note'],
		}),
	}),
})

export const {
	useLazyGetNotesQuery,
	useLazyGetPublicNotesQuery,
	useLazyGetNoteQuery,
	useCreateNoteMutation,
	useUpdateNoteMutation,
	useDeleteNoteMutation,
} = noteApi
