/* eslint-disable react-hooks/exhaustive-deps */
import { createColumnHelper } from '@tanstack/react-table'
import { DataTable } from 'components/Common'
import { Fragment, useState } from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'
import FilterForm from './FilterForm'
import useDocumentViewQuery from './useDocumentViewQuery'

const Documents = ({ client, role }) => {
	const columnHelper = createColumnHelper()

	const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 })
	const { documents, meta, isLoading } = useDocumentViewQuery(pagination, client, role)

	const columns = [
		columnHelper.accessor('id', {
			id: 'id',
			size: 40,
			header: () => <span>#</span>,
			cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		}),

		columnHelper.accessor((row) => row.document_name, {
			id: 'document_name',
			size: 200,
			header: () => <span>Document Name</span>,
			cell: (info) => <span>{info.getValue()}</span>,
		}),
	]

	return (
		<Fragment>
			<Card className='!rounded-lg shadow-none'>
				<CardHeader className='!border-none !bg-[#F2F5FF] p-4 py-3'>
					<h5 className='mb-0 font-bold'>Documents</h5>

					{/* <div>
                        <Button size='sm' color='primary'><i className='uil uil-plus' /> Assign Document</Button>&nbsp;
                        <Button size='sm' color='primary'><i className='uil uil-plus' /> Assign Document Package</Button>
                    </div> */}
				</CardHeader>
				<CardBody>
					<FilterForm />
					<DataTable
						columns={columns}
						data={documents}
						isLoading={isLoading}
						pagination={pagination}
						setPagination={setPagination}
						meta={meta}
					/>
				</CardBody>
			</Card>
		</Fragment>
	)
}

export default Documents
