import classNames from 'classnames'
import PropTypes from 'prop-types'
import DatePicker from 'react-date-picker'
import { Label } from 'reactstrap'

import 'react-calendar/dist/Calendar.css'
import 'react-date-picker/dist/DatePicker.css'

const DatePickerInput = ({
	meta: { touched, error },
	label,
	input,
	variant,
	className,
	minDate,
}) => {
	return (
		<div
			className={classNames('mb-3 flex', {
				'flex-row items-center space-x-[10px]': variant === 'horizontal',
				'flex-col space-y-[10px]': variant === 'default',
			})}>
			{label ? <Label className='m-0'>{label}</Label> : undefined}
			<div className='flex flex-col'>
				<DatePicker
					onChange={input.onChange}
					value={input?.value}
					format='y-MM-dd'
					calendarClassName='custom-calendar'
					className={className}
					minDate={minDate}
				/>
				{touched && error && (
					<span className=' mt-1 w-full text-[87.5%] text-[#f46a6a]'>
						{touched && error}
					</span>
				)}
			</div>
		</div>
	)
}

DatePickerInput.propTypes = {
	input: PropTypes.object.isRequired,
	meta: PropTypes.object,
	variant: PropTypes.oneOf(['default', 'horizontal']),
	className: PropTypes.string,
}

DatePickerInput.defaultProps = {
	meta: null,
	variant: 'default',
	className: '',
}

export default DatePickerInput
