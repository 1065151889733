import classNames from 'classnames'
import { useMemo } from 'react'

const StatusBadge = ({ status, color, bgColor, width = 'max-w-fit' }) => {
	// eslint-disable-next-line no-useless-escape
	const $color = useMemo(() => (color ? color.split('-')[1].replace(/[\[\]]/g, '') : ''), [color])
	const $bgColor = useMemo(
		() => (bgColor ? bgColor.split('-')[1].replace(/[\[\]]/g, '') : ''),
		[bgColor],
	)

	return (
		<div
			className={classNames(
				'flex items-center justify-center space-x-1.5 rounded  px-1.5 py-0.5 text-[12px] font-medium',
				color,
				width,
			)}
			style={{
				backgroundColor: $bgColor || '#fff',
			}}>
			<span
				className={classNames('block h-[6px] w-[6px] rounded-full')}
				style={{
					backgroundColor: $color,
				}}
			/>{' '}
			<span>{status}</span>
		</div>
	)
}

export default StatusBadge
