import { Fragment, useEffect, useState } from 'react'

import { Loader } from 'components/Common'
import { useAppRouter, useUser } from 'hooks'
import {
	useAssignClientToTeamMutation,
	useAssignPublicWorkflowMutation,
	useGetWorkflowAssignPayloadMutation,
} from 'modules/assigned-workflows/assigned-workflow-apis'
import { fetchPermissions } from 'modules/authentication/login'
import { useDispatch } from 'react-redux'
import { setDefaultGroup } from 'store/permissions/actions'
import notification from 'utilities/notification'
import WorkflowInitiatePopup from './workflow-initiate-popup'

const WorkflowLinkManage = () => {
	const { params, navigate } = useAppRouter()
	const id = params?.id
	const dispatch = useDispatch()
	const [isOpen, setIsOpen] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [workflow, setWorkflow] = useState(null)
	const [error, setIsError] = useState(null)
	const { user, group, team, teams, groups } = useUser()
	const [fetchPayload] = useGetWorkflowAssignPayloadMutation()
	const [assignWorkflow] = useAssignPublicWorkflowMutation()
	const [assignClientToTeam] = useAssignClientToTeamMutation()

	const onClose = () => setIsOpen(false)
	const onOpen = () => setIsOpen(true)

	const onSwitchTeam = (id, redirectUrl) => {
		if (group?.id !== id) {
			const currentGroup = groups.filter((group) => group.id === id)
			dispatch(setDefaultGroup(currentGroup[0])).then((response) => {
				if (response?.success) {
					notification('success', response?.message)
					if (redirectUrl) {
						window.location.href = redirectUrl
					} else {
						window.location.reload()
					}
				}
			})
		}
	}

	useEffect(() => {
		const startWorkflow = async (id) => {
			try {
				setIsLoading(true)
				setIsError(null)
				const response = await fetchPayload(id)
				const payload = response?.data?.data?.data
				if (response?.data?.status === 200 && payload) {
					const $payload = {
						...payload,
						client: [
							{
								...payload?.client,
								id: user?.id,
								signPriority: 1,
								screen_type: 'same_page',
							},
						],
					}
					delete $payload.groupData
					delete $payload.workflowID

					const workflowTeamId = payload?.team?.id
					const currentTeamId = team?.id
					const assignedTeamIds = teams.map((t) => t?.id)
					const isClientRole = group?.slug === 'client'

					if (workflowTeamId === currentTeamId && isClientRole) {
						const response = await assignWorkflow($payload)
						if (response?.data?.status === 200) {
							onOpen()
							setWorkflow(response?.data?.data)
						}
					} else if (assignedTeamIds.includes(workflowTeamId)) {
						const selectedGroup = groups.find((g) => g?.team?.id === workflowTeamId)
						const $isClientRole = selectedGroup?.group?.slug === 'client'
						if ($isClientRole) {
							onSwitchTeam(selectedGroup?.id)
						} else {
							notification(
								'info',
								'You cannot access this link because you are not a client of the workflow team.',
							)
							navigate('/clients')
						}
					} else {
						const response = await assignClientToTeam(workflowTeamId)
						if (response?.data?.status === 200) {
							const permissions = await fetchPermissions()
							const groups = Array.isArray(permissions?.groups)
								? permissions?.groups
								: []
							const addedGroup = groups.find((g) => g?.team?.id === workflowTeamId)
							const workflowResponse = await assignWorkflow($payload)

							if (workflowResponse?.data?.status === 200) {
								dispatch(setDefaultGroup(addedGroup)).then((response) => {
									if (response?.success) {
										notification('success', response?.message)
										setWorkflow(workflowResponse?.data?.data)
										// window.location.href = '/assigned-workflows'
										onOpen()
									}
								})
							}
						}
					}
				}
				setIsLoading(false)
			} catch (error) {
				setIsError(error?.message)
				setIsLoading(false)
			}
		}

		if (id) {
			startWorkflow(id)
		}
	}, [id])

	return (
		<div>
			{isLoading ? (
				<Loader />
			) : (
				<div>
					{error ? (
						<span>{error}</span>
					) : (
						<Fragment>
							<WorkflowInitiatePopup
								workflow={workflow}
								redirectUrl={`/assigned-workflows/${workflow?.id}/fill`}
								isOpen={isOpen}
								onClose={onClose}
							/>
						</Fragment>
					)}
				</div>
			)}
		</div>
	)
}

export default WorkflowLinkManage
