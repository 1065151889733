import { Link } from 'react-router-dom'

import { PAYMENTS_APP_ROUTES } from 'modules/payments/routes'
import { formatDate } from 'utilities/helpers'

const PendingSubActions = ({ payment, isActionActive }) => {
	const $payment = payment?.payments[0]

	return (
		<div className='max-w-fit'>
			{isActionActive ? (
				<div className='flex space-x-2'>
					<Link
						to={PAYMENTS_APP_ROUTES.edit($payment?.id)}
						className='action-btn btn-info-outline'>
						<i className='uil uil-edit' style={{ fontSize: 16 }} />
						<span>Edit</span>
					</Link>
				</div>
			) : (
				<span className='text-sm'>{formatDate(payment?.end_date)}</span>
			)}
		</div>
	)
}

export default PendingSubActions
