import axios from 'axios'

const get = (url) => axios.get(url).then((res) => res.data)

const post = (url, data = {}, config = {}) => axios.post(url, data, config).then((res) => res.data)

const put = (url, data = {}, config = {}) => axios.put(url, data, config).then((res) => res.data)

const patch = (url, data = {}, config = {}) =>
	axios.patch(url, data, config).then((res) => res.data)

const destroy = (url, data = {}, config = {}) =>
	axios.delete(url, data, config).then((res) => res.data)

const request = {
	get,
	post,
	put,
	patch,
	destroy,
}

export default request
