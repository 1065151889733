import { AppLayout } from 'theme/layouts'
import SearchBar from '../search-bar'
import Sidebar from '../sidebar'

const FileManagerLayout = ({ children, meta }) => {
	return (
		<AppLayout
			meta={{
				title: meta?.title,
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					{/* <PageHeader title='File Manager' /> */}
					<SearchBar />
					<div className='page-body px-4'>
						<div className='flex space-x-4'>
							<div className='min-w-[240px]'>
								<Sidebar />
							</div>
							<div className='flex-1 overflow-auto px-[10px]'>{children}</div>
						</div>
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default FileManagerLayout
