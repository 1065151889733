import { useMemo, useState } from 'react'
import DocumentsTable from './documents-table'

const DocumentsListingContainer = () => {
	const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 })

	const data = useMemo(
		() =>
			new Array(6).fill(0).map(() => ({
				title: 'Passport Application',
				type: 'Document',
				folders: ['RBC Bank', 'Applications 1', 'Applications 2'],
				last_edit: new Date(),
			})),
		[],
	)
	const meta = useMemo(
		() => ({
			currentPage: 1,
			limit: 1000,
			totalPages: 1,
			totalRecords: 3,
			hasNextPage: false,
			hasPrevPage: false,
			nextPage: null,
			prevPage: null,
		}),
		[],
	)

	return (
		<div>
			<DocumentsTable
				data={data}
				pagination={pagination}
				meta={meta}
				setPagination={setPagination}
			/>
		</div>
	)
}

export default DocumentsListingContainer
