import { useEffect } from 'react'
import Swal from 'sweetalert2'

import successIcon from 'assets/gif/success-icon.gif'

const SuccessPopup = ({ isVisible }) => {
	useEffect(() => {
		if (isVisible) {
			Swal.fire({
				timer: 2000,
				showCancelButton: false,
				showConfirmButton: false,
				html:
					'<div class="text-center flex flex-col items-center !space-y-4 mb-3"><img src="' +
					successIcon +
					'" class="!h-[120px] !w-[215px]" /><h3 class="font-semibold text-[#4CAF50]">Verification successful</h3><p class="text-gray-500 !text-lg !mb-0">We will fetch the verified information for you in the next step</p></div>',
				customClass: {
					container: 'bg-green-200',
					popup: '!max-w-md !rounded-lg',
					footer: '!bg-[#4CAF50] !-mb-5 !rounded-b-lg !h-2',
				},
				footer: `<div></div>`,
			})
		}
	}, [isVisible])

	return <></>
}

export default SuccessPopup
