import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import { useMemo } from 'react'
import { useDebounce } from 'use-debounce'

const TeamsQuery = (search) => {
	const [debounceSearch] = useDebounce(search, 500)
	const pageIndex = 0
	const pageSize = 10

	const URL = useMemo(
		() =>
			debounceSearch
				? `${apiEndpoints.teams.allTeams}?page=${
						pageIndex + 1
				  }&limit=${pageSize}&search=${search}`
				: `${apiEndpoints.teams.allTeams}?page=${pageIndex + 1}&limit=${pageSize}`,
		[pageIndex, pageSize, debounceSearch],
	)

	const { data, isLoading } = useQuery(URL)

	return {
		teams: data?.data?.items || [],
		isLoading,
	}
}

export default TeamsQuery
