import { Fragment } from 'react'

import SamePage from './same-page'
import StepByStep from './step-by-step'

const ProfileFieldsTab = ({
	docs,
	error,
	fields,
	handleNext,
	toggleField,
	activeField,
	total_fields,
	isFieldSaved,
	setIsFieldSaved,
	disableNext,
	screen_type,
	isReadOnly,
	currentStatus,
}) => {
	return (
		<Fragment>
			{screen_type === 'step_by_step' ? (
				<StepByStep
					handleNext={handleNext}
					docs={docs}
					isFieldSaved={isFieldSaved}
					error={error}
					activeField={activeField}
					fields={fields}
					setIsFieldSaved={setIsFieldSaved}
					toggleField={toggleField}
					total_fields={total_fields}
					disableNext={disableNext}
					isReadOnly={isReadOnly}
					currentStatus={currentStatus}
				/>
			) : (
				<SamePage
					isReadOnly={isReadOnly}
					docs={docs}
					error={error}
					fields={fields}
					currentStatus={currentStatus}
				/>
			)}
		</Fragment>
	)
}

export default ProfileFieldsTab
