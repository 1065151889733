import { useEffect, useMemo, useState } from 'react'

import { useAppRouter } from 'hooks'
import { useLazyGetDocumentTemplatesQuery } from 'modules/documents/document-api'
import { formatDate, getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import DocumentsTable from './documents-table'
import Filters from './filters'

const MyDocumentsListingContainer = () => {
	const [rowSelection, setRowSelection] = useState({})
	const selectedRows = useMemo(() => Object.keys(rowSelection), [rowSelection])
	const { location, navigate } = useAppRouter()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination
	const [fetchDocumentTemplates, { data, isUninitialized, isLoading }] =
		useLazyGetDocumentTemplatesQuery()

	useEffect(() => {
		if (pagination) {
			const parseQueryString = getParseQueryString(location?.search)
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			navigate(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	useEffect(() => {
		if (location?.search) {
			fetchDocumentTemplates({
				params: getParseQueryString(location?.search),
			})
		}
	}, [location?.search])

	const $data = useMemo(
		() =>
			Array.isArray(data?.documents)
				? data?.documents.map((document) => ({
						id: document?.id,
						title: document?.title,
						status: document?.status,
						type: 'Document',
						folders: Array.isArray(document?.folders)
							? document?.folders.map((p) => p?.title)
							: [],
						last_edit: formatDate(document?.last_edit),
				  }))
				: [],
		[data?.documents],
	)

	return (
		<div>
			<Filters selectedRows={selectedRows} />
			<DocumentsTable
				data={$data}
				pagination={pagination}
				meta={data?.meta}
				isLoading={isUninitialized || isLoading}
				setPagination={setPagination}
				setRowSelection={setRowSelection}
				rowSelection={rowSelection}
			/>
		</div>
	)
}

export default MyDocumentsListingContainer
