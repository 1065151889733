import * as mime from 'react-native-mime-types'
import { formatFileSize } from 'utilities/helpers'
import FileUploadField from './file-upload-field'

export const validateFileType = (file, allowedExtentions) => {
	return new Promise((resolve, reject) => {
		const fileExtention = `.${mime.extension(file.type)}`
		if (allowedExtentions.includes(fileExtention)) {
			resolve({ message: 'file type is accepted' })
		} else {
			reject({
				message: `only these file extentions are allowed ${allowedExtentions.join(', ')}`,
			})
		}
	})
}

export const validateFileSize = (file, maxSize, minSize) => {
	return new Promise((resolve, reject) => {
		const fileSize = file.size
		if (fileSize > maxSize) {
			reject({ message: `file size should not exceed ${formatFileSize(maxSize)}` })
		} else if (fileSize < minSize) {
			reject({ message: `file size should be at least ${formatFileSize(minSize)}` })
		} else {
			resolve({ message: 'file size is accepted' })
		}
	})
}

export default FileUploadField
