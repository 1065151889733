import classNames from 'classnames'
import PropTypes from 'prop-types'

const ProgressBar = ({ variant, value, className, isVisibleProgress = false }) => {
	return (
		<div className={classNames('flex items-center gap-x-3 whitespace-nowrap', className)}>
			<div
				className='flex h-2 w-full overflow-hidden rounded-full bg-gray-200'
				role='progressbar'
				aria-valuenow={value}
				aria-valuemin='0'
				aria-valuemax='100'>
				<div
					className={classNames(
						'flex flex-col justify-center overflow-hidden whitespace-nowrap rounded-full text-center text-xs text-white transition duration-500',
						{
							'bg-main': variant === 'primary',
							'bg-green-600': variant === 'success',
							'bg-red-600': variant === 'danger',
							'bg-blue-500': variant === 'info',
							'bg-yellow-500': variant === 'warning',
							'bg-gray-600': variant === 'secondary',
						},
					)}
					style={{ width: `${value}%` }}></div>
			</div>

			{isVisibleProgress ? (
				<div className='w-10 text-end'>
					<span className='text-sm text-gray-800'>{value}%</span>
				</div>
			) : undefined}
		</div>
	)
}

ProgressBar.propTypes = {
	variant: PropTypes.oneOf(['primary', 'danger', 'success', 'warning', 'secondary']),
	value: PropTypes.number,
	isVisibleProgress: PropTypes.bool,
	className: PropTypes.string,
}

ProgressBar.defaultProps = {
	className: '',
	variant: 'primary',
	value: 30,
	isVisibleProgress: false,
}

export default ProgressBar
