import { Fragment, useState } from 'react'
import { isAllowTo } from 'utilities/helpers'
import notification from 'utilities/notification'
import { permissions } from 'utilities/permissions'
import { useRestoreDeletedDocumentMutation } from '../deleted-document-api'
import DeleteConfirmAlert from './delete-confirm-alert'

const Actions = ({ field }) => {
	const canRestore = isAllowTo(permissions?.documentTemplate?.restore)
	const [isOpen, setIsOpen] = useState({
		open: false,
		id: undefined,
	})

	const onOpen = (id) => {
		setIsOpen({
			open: true,
			id,
		})
	}

	const onClose = () =>
		setIsOpen({
			open: false,
			id: undefined,
		})

	const [restoreDeletedDocument, { isLoading }] = useRestoreDeletedDocumentMutation()

	const onRestore = async () => {
		const id = isOpen?.id
		const response = await restoreDeletedDocument(id)
		if (response?.data?.status === 200) {
			notification('success', response?.data?.message)
		}
		onClose()
	}

	return (
		<Fragment>
			<DeleteConfirmAlert
				isOpen={isOpen?.open}
				onClose={onClose}
				isLoading={isLoading}
				onConfirm={onRestore}
			/>
			<div className='w-[250px]'>
				<div className='flex space-x-2'>
					{canRestore && (
						<button
							type='button'
							className='action-btn btn-primary-outline'
							onClick={() => onOpen(field?.id)}>
							<Fragment>
								<i className='uil-repeat' style={{ fontSize: 16 }} />
								<span>Restore</span>
							</Fragment>
						</button>
					)}
				</div>
			</div>
		</Fragment>
	)
}

export default Actions
