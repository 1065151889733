import { ReactComponent as WorkflowAssignIcon } from 'assets/svg/workflow-assign-popup.svg'
import { ReactComponent as WorkflowReviewIcon } from 'assets/svg/workflow-review-icon.svg'
import { WORKFLOW_ACTIONS } from '.'
import Popup from './popup'
import PopupHeader from './popup-header'
import WorkflowBegin from './workflow-begin'
import WorkflowCorrection from './workflow-correction'
import WorkflowSubmitted from './workflow-submited'

const PopupContainer = ({
	isOpen,
	onClose,
	backgroundColor,
	colorName,
	files,
	action_type,
	notes,
	comments,
}) => {
	switch (action_type) {
		case WORKFLOW_ACTIONS.BEGIN:
			return (
				<Popup
					isOpen={isOpen}
					onClose={onClose}
					header={
						<PopupHeader
							title='Please keep below documents handy'
							icon={<WorkflowAssignIcon />}
							onClose={onClose}
						/>
					}>
					<WorkflowBegin
						backgroundColor={backgroundColor}
						colorName={colorName}
						files={files}
						onClose={onClose}
						notes={notes}
					/>
				</Popup>
			)

		case WORKFLOW_ACTIONS.SUBMITTED:
			return (
				<Popup
					isOpen={isOpen}
					onClose={onClose}
					colorName={colorName}
					backgroundColor={backgroundColor}>
					<WorkflowSubmitted
						backgroundColor={backgroundColor}
						colorName={colorName}
						files={files}
						onClose={onClose}
					/>
				</Popup>
			)

		case WORKFLOW_ACTIONS.CORRECTIONS:
			return (
				<Popup
					isOpen={isOpen}
					onClose={onClose}
					colorName={colorName}
					backgroundColor={backgroundColor}
					header={
						<PopupHeader
							title='Comments from Reviewers'
							icon={<WorkflowReviewIcon />}
							onClose={onClose}
						/>
					}>
					<WorkflowCorrection
						backgroundColor={backgroundColor}
						colorName={colorName}
						onClose={onClose}
						comments={comments}
					/>
				</Popup>
			)

		default:
			return <div></div>
	}
}

export default PopupContainer
