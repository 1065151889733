import { Field, useFormikContext } from 'formik'
import { snakeCase } from 'lodash'
import Switch from 'react-switch'
import { UncontrolledTooltip } from 'reactstrap'

const DocumentBoundField = ({ fieldName }) => {
	const { setFieldValue } = useFormikContext()

	return (
		<Field name={fieldName}>
			{({ field }) => (
				<div className='flex items-center justify-between gap-2'>
					<div className='flex'>
						<label htmlFor={fieldName} className='!mb-0 cursor-pointer'>
							Client profile specific
						</label>
					</div>

					<Switch
						id={fieldName}
						className=' m-0'
						uncheckedIcon={false}
						checkedIcon={false}
						offColor='#bbbfbd'
						onColor='#626ed4'
						height={20}
						width={40}
						handleDiameter={12}
						onChange={(checked) => setFieldValue(fieldName, checked)}
						checked={field.value}
					/>
					<div className='flex items-center'>
						<label htmlFor={fieldName} className='!mb-0 cursor-pointer'>
							Document specific
						</label>

						<div id={`${snakeCase(fieldName)}_info`} className='ml-1 cursor-pointer'>
							<i className='bx bx-info-circle mb-0 mt-1 text-sm text-gray-400' />
							<UncontrolledTooltip
								placement='top'
								target={`${snakeCase(fieldName)}_info`}>
								Switch on if the value of this field is exclusively saved within the
								scope of this document and not at a client&apos;s profile scope.
							</UncontrolledTooltip>
						</div>
					</div>
				</div>
			)}
		</Field>
	)
}

export default DocumentBoundField
