import { createColumnHelper } from '@tanstack/react-table'
import { Fragment, useState } from 'react'

import fileIcon from 'assets/svg/file-icon.svg'
import { ReactDataTable } from 'theme/ui/data-table'
import { isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'
import Actions from './actions'
import FilterForm from './filter-form'
import WorkflowFilesQuery from './workflow-files-query'

const WorkflowFiles = ({ user_id, groupSlug }) => {
	const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 })
	const { files, isLoading, meta } = WorkflowFilesQuery(user_id, groupSlug, pagination)

	const columnHelper = createColumnHelper()

	const columns = [
		columnHelper.accessor('index', {
			header: () => <span>#</span>,
			cell: (info) => <span>{info.getValue()}.</span>,
		}),
		columnHelper.accessor((row) => row, {
			id: 'file_info',
			header: () => <span>File</span>,
			cell: (info) => (
				<div className='flex max-w-xs items-center space-x-2'>
					<img src={fileIcon} alt='file-icon' className='w-[30px]' />
					<div className='flex flex-col'>
						<span className='font-bold text-gray-800'>{info.getValue()?.title}</span>
						<span className='line-clamp-1 max-w-fit text-xs text-gray-500'>
							{info.getValue()?.file?.original_name}
						</span>
					</div>
				</div>
			),
		}),
		// columnHelper.accessor('file_size', {
		// 	header: () => <span>File Size</span>,
		// 	cell: (info) => <span>{info.getValue()}</span>,
		// }),
		columnHelper.accessor('created_at', {
			header: () => <span>Uploaded At</span>,
			cell: (info) => <span>{info.getValue()}</span>,
		}),

		columnHelper.accessor((row) => row.file, {
			id: 'action',
			header: () => <span>Actions</span>,
			cell: (info) => (
				<Fragment>
					{isAllowTo(permissions?.workflowFiles?.download) ? (
						<Actions file={info.getValue()} />
					) : (
						<>-</>
					)}
				</Fragment>
			),
		}),
	]

	return (
		<div>
			<FilterForm />
			<ReactDataTable
				columns={columns}
				data={files}
				meta={meta}
				isLoading={isLoading}
				pagination={pagination}
				setPagination={setPagination}
			/>
		</div>
	)
}

export default WorkflowFiles
