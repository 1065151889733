import { useParams } from 'react-router-dom'

import { SpinLoader } from 'components/Common'
import { WorkflowForm, getInitialValues } from 'modules/workflows/workflow-template'
import { useLazyGetOneWorkflowTemplateQuery } from 'modules/workflows/workflow-template/workflow-template-apis'
import { useEffect, useMemo } from 'react'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const WorkflowTemplateEdit = () => {
	const params = useParams()
	const ID = params?.id
	const [fetchWorkflowTemplate, { data, isLoading }] = useLazyGetOneWorkflowTemplateQuery()

	useEffect(() => {
		if (ID) {
			fetchWorkflowTemplate(ID)
		}
	}, [ID])

	const initialValues = useMemo(() => getInitialValues(data?.workflow), [data?.workflow])

	return (
		<AppLayout
			meta={{
				title: 'Edit Workflow Template',
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<PageHeader
						title='Edit Workflow'
						description='When assigning documents/packages here, do not ignore to fill in all the required fields completely and follow the document/package adding rules.'
						hasBackButton={true}
					/>
					<div className='page-body'>
						{isLoading && !initialValues ? (
							<SpinLoader />
						) : (
							<WorkflowForm initialValues={initialValues} id={ID} />
						)}
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default WorkflowTemplateEdit
