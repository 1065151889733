import { ReactComponent as CommentIcon } from 'assets/svg/comment-icon.svg'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { getWorkflowCardBg } from '../assigned-workflows-listing/workflow-card'
import StatusBadge from '../assigned-workflows-listing/workflow-card/status-badge'
import Popup from './popup'
import PopupHeader from './popup-header'

const WorkflowDetails = ({ workflow }) => {
	const [isOpen, setIsOpen] = useState(false)
	const onClose = () => setIsOpen(false)
	const colors = useMemo(() => getWorkflowCardBg(workflow?.status), [workflow?.status])

	const infos = useMemo(
		() => [
			{
				label: 'Advisor/Assigner',
				value: workflow?.advisor?.first_name + ' ' + workflow?.advisor?.last_name,
			},
			{
				label: 'Final Reviewer',
				value: '-',
			},
			{
				label: 'Assigned On',
				value: moment(workflow?.created_at).format('DD MMM YYYY'),
			},
		],
		[workflow],
	)

	return (
		<div>
			<Popup
				isOpen={isOpen}
				onClose={onClose}
				header={<PopupHeader title='Workflow Details' onClose={onClose} />}>
				<div className='mb-3 rounded-md bg-white p-1'>
					<StatusBadge
						width='w-full'
						status={workflow?.status}
						color={colors?.color}
						bgColor={colors?.backgroundColor}
					/>

					<div
						className='flex flex-col space-y-3 p-2
                    '>
						{infos.map((item, idx) => (
							<div key={idx} className='flex justify-between'>
								<span className='text-[13px] text-[#74788D]'>{item?.label}</span>
								<span className='text-sm font-medium text-gray2-dark'>
									{item?.value}
								</span>
							</div>
						))}
					</div>
				</div>

				{workflow?.notes ? (
					<div className='rounded-md bg-white p-3'>
						<div className='flex items-center space-x-3'>
							<span>
								<CommentIcon />
							</span>
							<div>
								<h4 className='text-sm font-bold text-[#495057]'>
									Note from Advisor
								</h4>
								<p className='m-0 text-xs text-gray-500'>{workflow?.notes}</p>
							</div>
						</div>
					</div>
				) : undefined}
			</Popup>
			<button
				type='button'
				onClick={() => setIsOpen(true)}
				className='cursor-pointer text-main hover:underline'>
				Workflow Details
				<i className='uil-angle-down' />
			</button>
		</div>
	)
}

export default WorkflowDetails
