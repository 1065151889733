import Filters from './filters'

export const getFormikFilterValues = (values) => {
	const filters = {}

	const search = values?.search || ''
	if (search) {
		Object.assign(filters, { search })
	}

	const clients = Array.isArray(values?.client_id) ? values.client_id : []
	if (clients.length > 0) {
		Object.assign(filters, { client_id: clients })
	}

	const reviewers = Array.isArray(values?.reviewer_id) ? values.reviewer_id : []

	if (reviewers.length > 0) {
		Object.assign(filters, { reviewer_id: reviewers })
	}

	const statuses = Array.isArray(values?.status) ? values.status : []
	if (statuses.length > 0) {
		Object.assign(filters, { status: statuses })
	}

	const advisors = Array.isArray(values?.advisor_id) ? values.advisor_id : []
	if (advisors.length > 0) {
		Object.assign(filters, { advisor_id: advisors })
	}

	return filters
}

export const getInitialValues = (queryObj) => {
	const initialValues = {
		search: '',
		status: [],
		client_id: [],
		advisor_id: [],
		reviewer_id: [],
	}
	if (queryObj?.search) {
		Object.assign(initialValues, { search: queryObj?.search })
	}

	if (queryObj?.status) {
		Object.assign(initialValues, { status: queryObj?.status.split(',') })
	}

	if (queryObj?.client_id) {
		Object.assign(initialValues, { client_id: queryObj?.client_id.split(',') })
	}

	if (queryObj?.advisor_id) {
		Object.assign(initialValues, { advisor_id: queryObj?.advisor_id.split(',') })
	}

	if (queryObj?.reviewer_id) {
		Object.assign(initialValues, { reviewer_id: queryObj?.reviewer_id.split(',') })
	}

	return initialValues
}

export const filterKeys = {
	search: 'Search',
	status: 'Statuses',
	client_id: 'Clients',
	advisor_id: 'Advisors',
	reviewer_id: 'Reviewers',
}

export const getActiveFilters = (filterObj) => {
	return Object.entries(filterObj)
		.filter((filter) => !['page', 'limit'].includes(filter[0]))
		.filter((filter) => {
			// eslint-disable-next-line no-unused-vars
			const [_, values] = filter
			return values.length > 0
		})
		.map((filter) => {
			const [key, values] = filter
			return [
				{
					label: filterKeys[key],
					name: key,
				},
				values,
			]
		})
}

export default Filters
