import { Fragment } from 'react'
import { Field } from 'redux-form'

import { RadioComponent } from 'components/Forms/RadioComponent'
import { userTypes } from 'modules/workflows'
import PdfFieldRender from 'modules/workflows/pdf-field-render/pdf-field-render'

const FieldsList = ({ fields, fieldName }) => {
	return (
		<table className='react-table overflow-x-auto'>
			<thead>
				<tr>
					<th className='px-3 py-2'>#</th>
					<th className='px-3 py-2'>Field Name</th>
					<th className='px-3 py-2'>Field Value</th>
					<th className='px-3 py-2'>Document</th>
					<th className='px-3 py-2 text-center'>Approve</th>
					<th className='px-3 py-2 text-center'>Reject</th>
				</tr>
			</thead>
			<tbody>
				{fields.length > 0 ? (
					<Fragment>
						{fields.map((field, idx) => {
							let userType = userTypes.find(
								(item) => item?.value === field?.user_type,
							)
							return (
								<tr key={idx}>
									<td className='vertical-middle'>{idx + 1}.</td>
									<td className='vertical-middle'>
										<div className='form-group'>
											<span
												className='line-clamp-1 text-sm font-bold'
												title={field?.label}>
												{field?.label}
											</span>
										</div>
									</td>
									<td className='vertical-middle'>
										<PdfFieldRender
											field={field}
											name={`fields.${fieldName}[${idx}].field_value`}
										/>
									</td>
									<td className='vertical-middle'>
										<span
											className='line-clamp-1 text-main'
											title={field?.document_title}>
											{field?.document_title || '-'}
										</span>
									</td>
									<td className='vertical-middle text-center'>
										<div className='w-f-content m-auto'>
											<Field
												name={`fields.${fieldName}[${idx}].field_name`}
												value={field?.name}
												type='hidden'
												component='input'
											/>
											<Field
												name={`fields.${fieldName}[${idx}].user_type`}
												value={userType?.value}
												type='hidden'
												component='input'
											/>
											<Field
												name={`fields.${fieldName}[${idx}].status`}
												value='approved'
												type='radio'
												component={RadioComponent}
												defaultChecked={true}
												ignoreBottomMargin={true}
											/>
										</div>
									</td>
									<td className='vertical-middle text-center'>
										<div className='w-f-content m-auto'>
											<Field
												name={`fields.${fieldName}[${idx}].status`}
												value='reject'
												type='radio'
												variant='red'
												component={RadioComponent}
												ignoreBottomMargin={true}
											/>
										</div>
									</td>
								</tr>
							)
						})}
					</Fragment>
				) : (
					<tr>
						<td colSpan={6} className='p-3'>
							<p className='m-0 text-center'>
								No field has been assigned to this user; you may proceed by clicking
								the &quot;Next&quot; button.
							</p>
						</td>
					</tr>
				)}
			</tbody>
		</table>
	)
}

export default FieldsList
