import { uniqWith } from 'lodash'
import PendingPaymentsFilters from './pending-sub-filters'

export const getFormikFilterValues = (values) => {
	const filters = {}

	const search = values?.search || ''
	if (search) {
		Object.assign(filters, { search })
	}

	return filters
}

export const getInitialValues = (queryObj) => {
	const initialValues = {
		search: '',
	}
	if (queryObj?.search) {
		Object.assign(initialValues, { search: queryObj?.search })
	}

	return initialValues
}

const getFitlerKey = (key) => {
	if (['from', 'to'].includes(key)) {
		return 'date_range'
	}
	return key
}

export const getActiveFilters = (filterObj) => {
	const filtersArray = Object.entries(filterObj)
		.filter((filter) => !['page', 'limit'].includes(filter[0]))
		.filter((filter) => {
			// eslint-disable-next-line no-unused-vars
			const [_, values] = filter
			return values.length > 0
		})
		.map((filter) => {
			const [key, values] = filter
			return [
				{
					label: filterKeys[key],
					name: getFitlerKey(key),
				},
				values,
			]
		})
	return uniqWith(filtersArray, (arrayVal, otherVal) => {
		return arrayVal[0]?.name === otherVal[0]?.name
	})
}

export const filterKeys = {
	search: 'Search',
	status: 'Statuses',
	from: 'Date Range',
	to: 'Date Range',
}

export default PendingPaymentsFilters
