import { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

const SearchInput = ({ data, setFilteredData }) => {
	const [searchTerm, setSearchTerm] = useState('')
	const [debouncedSearchTerm] = useDebounce(searchTerm, 300)

	useEffect(() => {
		const filtered = data.filter((item) =>
			item.title.toLowerCase().includes(debouncedSearchTerm.toLowerCase()),
		)
		setFilteredData(filtered)
	}, [debouncedSearchTerm, data])

	const handleSearch = (e) => {
		const term = e.target.value
		setSearchTerm(term)
	}
	return (
		<div className='relative'>
			<div className='absolute inset-y-0 left-0 flex items-center pl-4'>
				<i className='uil-search text-gray-500' />
			</div>
			<input
				type='text'
				placeholder='Search documents...'
				className='block w-full border-b border-b-gray-200  py-2.5 pl-10 pr-4  focus:bg-white focus:outline-none'
				onChange={handleSearch}
				value={searchTerm}
			/>
		</div>
	)
}

export default SearchInput
