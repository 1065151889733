import { FormikProvider, useFormik } from 'formik'
import { useState } from 'react'
import { Col, Row } from 'reactstrap'

import { useUpdateClientMutation } from 'modules/clients/client-apis'
import { Button } from 'theme/ui/forms'
import notification from 'utilities/notification'
import FileUploadCard from './file-upload-card'

const ClientDocuments = ({ client }) => {
	const [validDocumentId, setValidDocumentId] = useState(null)
	const [governmentDocumentId, setGovernmentDocumentId] = useState(null)
	const [updateClient] = useUpdateClientMutation()

	const formik = useFormik({
		initialValues: {},
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true)

				const payload = { ...values }
				if (validDocumentId) {
					Object.assign(payload, {
						valid_document: validDocumentId,
					})
				}
				if (governmentDocumentId) {
					Object.assign(payload, {
						government_document: governmentDocumentId,
					})
				}

				const response = await updateClient({ payload, id: client?.id })
				if (response?.data?.status === 200) {
					if (validDocumentId) {
						setValidDocumentId(null)
					}

					if (governmentDocumentId) {
						setGovernmentDocumentId(null)
					}
					notification('success', 'Documents updated successfully')
				}
				setSubmitting(false)
			} catch (error) {
				setSubmitting(false)
			}
		},
	})

	return (
		<div>
			<div className='mb-4 rounded-lg bg-white'>
				<div className='flex items-center justify-between bg-[#F2F5FF] p-4 py-3'>
					<h5 className='m-0 font-bold'>Identification Documents</h5>
				</div>

				{/* <Row className='m-0 border-b p-3'>
							<Col xs={6}>
								<span className='text-gray-500'>SIN</span>
							</Col>
							<Col xs={6}>
								<strong>{client?.sin_number}</strong>
							</Col>
						</Row> */}
				<FormikProvider value={formik}>
					<form onSubmit={formik.handleSubmit}>
						<Row className='m-0 items-center border-b p-3'>
							<Col xs={6}>
								<span className='text-gray-500'>Valid Document</span>
							</Col>
							<Col xs={6}>
								<FileUploadCard
									title='Valid ID'
									fileType={client?.valid_document?.mime_type}
									file={client?.valid_document}
									onSetFileId={(e) => setValidDocumentId(e)}
								/>
							</Col>
						</Row>
						<Row className='m-0 items-center border-b p-3'>
							<Col xs={6}>
								<span className='text-gray-500'>Government Document</span>
							</Col>
							<Col xs={6}>
								<FileUploadCard
									title='Government Document'
									fileType={client?.government_document?.mime_type}
									file={client?.government_document}
									onSetFileId={(e) => setGovernmentDocumentId(e)}
								/>
							</Col>
						</Row>
						{[validDocumentId, governmentDocumentId].filter((d) => d !== null).length >
						0 ? (
							<div className='flex items-center justify-center py-3'>
								<Button type='submit' isLoading={formik.isSubmitting}>
									Save
								</Button>
							</div>
						) : undefined}
					</form>
				</FormikProvider>
			</div>
		</div>
	)
}

export default ClientDocuments
