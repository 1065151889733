import SearchBar from './search-bar'

export const getFormValues = (values) => {
	return {
		...values,
		search_type: values?.search_type?.value,
	}
}

export default SearchBar
