import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

const ButtonLink = ({ color, to, className, children, ...props }) => {
    return (
        <React.Fragment>
            <Link to={to} className={className}>
                <Button color={color} {...props} >{children}</Button>
            </Link>
        </React.Fragment>
    )
}

export default ButtonLink
