import { Field, reduxForm } from 'redux-form'

import { CustomCheckBox } from 'components/Forms2'
import { AssigneeDropdown, FilterDropdown, TagsDropdown } from '../common-filters'

const FilterForm = () => {
	return (
		<form>
			<div className='mb-3 flex items-center justify-between space-x-3'>
				<Field
					type='search'
					name='search'
					className='form-control mb-3 max-w-xs'
					placeholder='Search title, roles...'
					component='input'
				/>

				<div className='mb-3 flex space-x-3'>
					<Field
						type='checkbox'
						name='mytask'
						label='My Tasks'
						id='mytask'
						component={CustomCheckBox}
					/>

					<div>
						<AssigneeDropdown />
					</div>
					<div>
						<TagsDropdown />
					</div>
					<div>
						<FilterDropdown />
					</div>
				</div>
			</div>
		</form>
	)
}

export default reduxForm({
	form: 'kanban_filter',
})(FilterForm)
