import { useMemo } from 'react'
import { useDebounce } from 'use-debounce'

import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import { toQueryString } from 'utils/commonFunctions'
import { createClientOption } from '.'

const ClientOptionsQuery = (search) => {
	const [debounceSearch] = useDebounce(search, 600)

	const defaultParams = { page: 1, limit: 20, role: 'client' }
	const searchParams = debounceSearch
		? { ...defaultParams, search: debounceSearch }
		: defaultParams

	const query = toQueryString(searchParams)
	const URL = `${apiEndpoints.clients}?${query}`

	const { data, isLoading } = useQuery(URL)
	const clients = data?.clients || []

	const options = useMemo(
		() =>
			Array.isArray(clients) ? clients.map((client) => createClientOption(client?.user)) : [],
		[clients],
	)

	return {
		clientOptions: options,
		isLoading,
	}
}

export default ClientOptionsQuery
