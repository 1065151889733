import moment from 'moment'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import workflowIcon from 'assets/svg/assigned-workflow.svg'
import { ASSIGN_WORKFLOW_APP_ROUTES } from 'modules/assigned-workflows/routes'
import { getWorkflowCardBg, getWorkflowLinkTitle } from '.'
import CommentCard from '../../comment-card'
import StatusBadge from './status-badge'

const WorkflowCard = ({ workflow }) => {
	const statusColors = useMemo(() => getWorkflowCardBg(workflow?.status), [workflow?.status])
	const notes = useMemo(() => workflow?.notes, [workflow?.notes])

	return (
		<div
			className='h-fit max-w-md rounded-lg bg-cover bg-no-repeat md:min-w-[448px]'
			style={{
				backgroundImage: `url(${statusColors?.backgroundImage})`,
			}}>
			<div className='flex flex-col gap-3 !p-3'>
				<div className='flex justify-end'>
					<StatusBadge color={statusColors?.color} status={workflow?.status} />
				</div>
				<div className='rounded-lg bg-white p-[20px]'>
					<div className='relative'>
						<img src={workflowIcon} className='absolute -top-10 rounded-full' />
					</div>
					<div className='mt-[4px]'>
						<div className='mb-3 flex flex-col space-y-1'>
							<h3
								className='m-0 line-clamp-1 text-lg font-bold'
								title={workflow?.title}>
								{workflow?.title}
							</h3>
							<div className='flex items-center space-x-1 text-sm text-gray2'>
								<span>Assigned on:</span>
								<span className='font-medium'>
									{moment(workflow?.assigned_at).format('DD MMM, YYYY')}
								</span>
							</div>
							<div className='flex items-center space-x-1 text-sm text-gray2'>
								<span>Advisor:</span>
								<span className='font-medium'>
									{workflow?.assigned_by?.fullName || '-'}
								</span>
							</div>
						</div>

						<CommentCard
							advisor={workflow?.assigned_by}
							backgroundColor={statusColors?.backgroundColor}
							colorName={statusColors?.colorName}
							comment={{ message: notes }}
						/>

						<div className='!my-4 !border border-[#EBEBED]' />

						<Link
							to={ASSIGN_WORKFLOW_APP_ROUTES.findOne(workflow?.id)}
							className='text-sm font-medium text-main hover:!underline'>
							{getWorkflowLinkTitle(workflow?.status)}

							<i className='uil uil-angle-right' />
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default WorkflowCard
