import { useState } from "react"
import Form from "./Form"

import lightBulb from "assets/images/onboarding/light-bulb.png"
import apiEndpoints from "helpers/apiEndpoints"
import { useDispatch } from "react-redux"
import { store } from "store/common/actions"
import { mutate } from "swr"
import Layout from "../components/Layout"
import withSteps from "../components/withSteps"

const SINNumber = (props) => {
    const step = props?.step
    const [sin, setSin] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()

    const onSubmit = () => {
        setIsLoading(true)
        dispatch(store(apiEndpoints.userOnBoard.sinNumber, { sin_number: Number(sin) }))
            .then(response => {
                setIsLoading(false)
                if (response?.status === 200) {
                    mutate(apiEndpoints.userOnBoard.stepStatus)
                }
            })
    }

    const helperItems = [
        {
            title: 'Why do we need your SIN number?',
            description: 'We need your SIN number to verify that you are working professional or not.',
            icon: <img src={lightBulb} alt="" className="w-6 h-6" />
        }
    ]

    return (
        <Layout helperItems={helperItems}>
            <div className="grid grid-cols-12">
                <div className="col-span-1" />
                <div className="px-2 col-span-9">
                    <h3 className="text-xl font-bold">
                        Fill out your SIN number
                    </h3>
                    <p className="text-[#74788D]">
                        SIN is a nine digit number issued to working people
                    </p>

                    {step?.reason ? (
                        <p className="text-red-700">
                            <strong>Note:</strong> &nbsp;{step?.reason}
                        </p>
                    ) : undefined}

                    <Form
                        sinState={[sin, setSin]}
                        onSubmit={onSubmit}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </Layout>
    )
}

export default withSteps(SINNumber)