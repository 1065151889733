/* eslint-disable react-hooks/exhaustive-deps */
import { Loader } from 'components/Common'
import { findLast } from 'lodash'
import { Fragment, useEffect, useState } from 'react'
import {
	useStepStatusQueryOptions,
	useStepStatusQueryOptionsUpdate,
} from '../context/StepStatusContext'
import useStepsQuery from './useStepsQuery'

const withSteps = (Component) => {
	const EnhancedComponent = (props) => {
		const queryOptions = useStepStatusQueryOptions()
		const updateOptions = useStepStatusQueryOptionsUpdate()
		const { data, onboardStatus, isLoading } = useStepsQuery(queryOptions)
		const [step, setStep] = useState(null)
		const pathArray = window.location.pathname.split('/') || []
		const path = findLast(pathArray) || null
		const uploadDocuments = 'upload-documents'

		useEffect(() => {
			if (Array.isArray(data)) {
				if (path === uploadDocuments) {
					const _step = data.filter((step) => step?.id === path)
					setStep(_step)
					updateOptions(false)
				} else {
					updateOptions(true)
					const _step = data.find((step) => step?.id === path)
					setStep(_step)
				}
			}
		}, [data])

		return (
			<Fragment>
				{isLoading ? (
					<Loader />
				) : (
					<Component {...props} step={step} onboardStatus={onboardStatus} />
				)}
			</Fragment>
		)
	}
	return EnhancedComponent
}

export default withSteps
