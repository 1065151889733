import { Dialog, Transition } from '@headlessui/react'
import base64 from 'base-64'
import { snakeCase } from 'lodash'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'

import apiEndpoints from 'helpers/apiEndpoints'
import { useUser } from 'hooks'
import { useDeclineInivitationMutation } from 'modules/clients/invited-client-api'
import * as actions from 'store/common/actions'
import { getPermissions } from 'store/jwtauth/actions'
import { setDefaultGroup } from 'store/permissions/actions'
import notification from 'utilities/notification'
import { showSuccessToast } from 'utils/commonFunctions'
import { get, remove } from 'utils/storageService'
import InvitationForm from './invitation-form'

const ClientInvitation = () => {
	const dispatch = useDispatch()
	const { isSuperAdmin } = useSelector((state) => state.Permissions)
	const [showInvitationPopup, setShowInvitationPopup] = useState(false)
	const [declineInvitation, { isLoading: isDeclining }] = useDeclineInivitationMutation()
	const { user, team } = useUser()
	const [invitation, setInvitation] = useState({
		first_name: null,
		last_name: null,
		email: null,
		team: null,
		role_name: null,
	})
	const token = get('token')
	const CLIENT_ROLE = 'client'

	const fetchPermissions = async () => {
		return new Promise((resolve) => {
			dispatch(getPermissions()).then((response) => {
				resolve(response)
			})
		})
	}
	useEffect(() => {
		if (token) {
			try {
				const data = token ? base64.decode(token) : ''
				const parsed = data ? JSON.parse(data) : null
				if (parsed) {
					if (parsed?.email === user?.email && team?.id !== parsed?.teamId) {
						setInvitation(parsed)
						setShowInvitationPopup(true)
					} else {
						remove('token')
					}
				}
			} catch (_) {
				// showErrorToast('Invitation token is invalid or expire.')
				remove('token')
			}
		}
	}, [token])

	const removeInvitationData = async () => {
		return new Promise((resolve) => {
			remove('token')
			setShowInvitationPopup(false)
			showSuccessToast('Invitation successfully accepted.')
			resolve(true)
		})
	}

	const is_owner = useMemo(
		() => snakeCase(invitation?.role_name) === 'owner',
		[invitation?.role_name],
	)

	const title = useMemo(
		() =>
			is_owner ? (
				<h3 className='text-center text-lg font-medium text-gray-700'>
					You&apos;re invited to form your own team on our platform.
				</h3>
			) : (
				<h3 className='text-center text-lg font-medium text-gray-700'>
					You&apos;ve been invited to join the{' '}
					<span className='font-bold italic'>{invitation?.team}</span> team as{' '}
					<span className='font-bold italic'>{invitation?.role_name}</span>.
				</h3>
			),
		[is_owner, invitation],
	)

	const onAcceptInvitation = (payload) => {
		dispatch(actions.store(apiEndpoints.user.assignTeam, payload)).then((response) => {
			if (response?.status === 200) {
				const team = response?.data?.team
				const group = response?.data?.group

				fetchPermissions().then((permissions) => {
					const currentGroup = permissions?.user?.groups.filter(
						(item) => item?.team?.id === team?.id && item?.group?.id === group?.id,
					)[0]
					const currentRole = currentGroup?.group
					const isClient = snakeCase(currentRole?.name) === CLIENT_ROLE

					if (currentGroup) {
						dispatch(setDefaultGroup(currentGroup))
					}

					removeInvitationData().then(() => {
						if (isClient && !permissions?.onBoardStatus) {
							window.location.href = '/onboarding/required-document-list'
						} else {
							window.location.href = '/dashboard'
						}
					})
				})
			} else {
				remove('token')
				setShowInvitationPopup(false)
			}
		})
	}

	const onDeclineInvitation = async (token) => {
		try {
			const response = await declineInvitation({ token: token })
			if (response?.data?.status === 200) {
				remove('token')
				setShowInvitationPopup(false)
				notification('success', 'You have declined the Team invitation.')
			}
		} catch (error) {
			notification('error', error?.message)
		}
	}

	return (
		<Fragment>
			{!isSuperAdmin ? (
				<div id='client-invitation-popup'>
					<Transition
						appear
						show={showInvitationPopup}
						as={Fragment}
						enter='transition duration-100 ease-out'
						enterFrom='transform scale-95 opacity-0'
						enterTo='transform scale-100 opacity-100'
						leave='transition duration-75 ease-out'
						leaveFrom='transform scale-100 opacity-100'
						leaveTo='transform scale-95 opacity-0'>
						<Dialog as='div' className='popup-overlay relative' onClose={() => {}}>
							<div className='fixed inset-0 bg-black/30' aria-hidden='true' />

							<div className='fixed inset-0 overflow-y-auto'>
								<div className='flex min-h-full items-center justify-center p-4 text-center'>
									<Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all'>
										{title}
										<InvitationForm
											onDeclineInvitation={() => onDeclineInvitation(token)}
											token={token}
											is_owner={is_owner}
											isDeclining={isDeclining}
											onAcceptInvitation={onAcceptInvitation}
										/>
									</Dialog.Panel>
								</div>
							</div>
						</Dialog>
					</Transition>
				</div>
			) : undefined}
		</Fragment>
	)
}

export default withRouter(ClientInvitation)
