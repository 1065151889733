/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Row } from 'reactstrap'
import { clear } from 'store/client-onboarding/actions'
import DocumentCard from './DocumentCard'

// Assets
import GovID from 'assets/svg/figma/FrameGovID.svg'
import OfficialDoc from 'assets/svg/figma/FrameOfficialDoc.svg'
import SIN from 'assets/svg/figma/FrameSIN.svg'
import useStepsQuery from '../components/useStepsQuery'
import WorkFlowMap from './WorkFlowMap'

const DocumentList = (props) => {
	const { history } = props
	const dispatch = useDispatch()
	const { data } = useStepsQuery()
	const PENDING = 'pending'

	useEffect(() => {
		if (Array.isArray(data)) {
			const steps = data.filter((step) => step?.status === PENDING)
			if (steps.length === 0) {
				window.location.href = '/portfolio'
			}
		}
	}, [data])

	useEffect(() => {
		dispatch(clear())
	}, [])

	let documents = [
		{
			imgUrl: SIN,
			title: 'Social Insurance Number (SIN)',
			description: 'You should have your SIN card ready',
		},
		{
			imgUrl: GovID,
			title: 'Government ID',
			description: 'Your government ID is for example your driving license, etc.',
		},
		{
			imgUrl: OfficialDoc,
			title: 'Official government document',
			description: 'Your official document is for example electricity bill, etc.',
		},
	]

	return (
		<div className='container-fluid overflow-hidden bg-white'>
			<div className='row'>
				<div className='col-sm-3 min-h-screen bg-[#F5F7FF] px-0 pt-20'>
					<WorkFlowMap />
				</div>

				<div className='col-sm-9 flex justify-center pt-20'>
					<div className=''>
						<h3 className='mb-2 text-center text-xl font-bold'>
							Before we continue...
						</h3>
						<p className='text-muted mb-9 text-center'>
							Please keep following documents handy
						</p>

						<div className='h-6'></div>

						<Row className='mx-auto flex max-w-[915px] justify-center '>
							{documents.map(({ title, description, imgUrl }, index) => {
								return (
									<DocumentCard
										key={title + index}
										imgUrl={imgUrl}
										title={title}
										description={description}
									/>
								)
							})}
						</Row>

						<div className='h-20'></div>

						<div className='text-center'>
							<button
								type='submit'
								onClick={() => history.push('/onboarding/input-sin-number')}
								className='btn-primary-1'
								disabled={false}>
								Continue
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default DocumentList
