import classNames from 'classnames'
import ClientDropdown from './client-dropdown'

export const createClientOption = (client, formValues, client_applicants) => ({
	fullName: `${client?.first_name} ${client?.last_name}`,
	email: client?.email,
	value: client?.id,
	isDisabled: client_applicants.includes(client?.id) && formValues?.client?.value !== client?.id,
})

export const createClientOptionLabel = (client) => {
	return (
		<div
			className={classNames(
				'flex items-center space-x-1',
				client?.isDisabled ? 'cursor-not-allowed opacity-50 hover:!bg-transparent' : '',
			)}>
			<span className='font-bold text-gray-700'>{client?.fullName}</span>
			<span className='text-sm text-gray-500'>({client?.email})</span>
		</div>
	)
}

export default ClientDropdown
