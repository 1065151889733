import DocumentsListingContainer from './documents-listing'
import RecentFolders from './recent-folders'

const HomeContainer = () => {
	return (
		<div>
			<RecentFolders />

			<h3 className='mb-3 mt-4 text-base'>Recent Files</h3>
			<DocumentsListingContainer />
		</div>
	)
}

export default HomeContainer
