import { Fragment } from 'react'
import { reduxForm } from 'redux-form'

import checkCircle from 'assets/images/onboarding/success-check.png'
import OTPField from 'components/Forms/OTPField'

const Form = (props) => {
	const { handleSubmit, isLoading, sinState } = props
	const [sin, setSin] = sinState
	const SIN_LENGTH = 9
	const isDisabled = sin.length !== SIN_LENGTH

	return (
		<Fragment>
			<form onSubmit={handleSubmit} className='mt-12 space-y-8'>
				<div className='mb-4 flex items-center  space-x-4'>
					<OTPField otpState={[sin, setSin]} numInputs={SIN_LENGTH} />

					{!isDisabled && (
						<span className='mb-4 w-5'>
							<img src={checkCircle} alt='' className='h-5 w-5' />
						</span>
					)}
				</div>

				<div>
					<button
						type='submit'
						className='btn-primary-1'
						disabled={isDisabled || isLoading}>
						{isLoading ? 'Saving...' : 'Next'}
					</button>
				</div>
			</form>
		</Fragment>
	)
}

export default reduxForm({
	form: 'sin_number',
})(Form)
