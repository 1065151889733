import { useEffect } from 'react'

import lightBulb from 'assets/images/onboarding/light-bulb.png'
import { useAppRouter } from 'hooks'
import LogoutButton from 'theme/ui/logout-button'
import HelperCard from '../components/HelperCard'
import LeftSidebar from '../components/LeftSidebar'
import useStepsQuery from '../components/useStepsQuery'

const ReviewInformation = () => {
	const { data, onboardStatus } = useStepsQuery()
	const PENDING = 'pending'
	const { parseQuery, navigate } = useAppRouter()
	const $continue = parseQuery?.continue

	useEffect(() => {
		if (Array.isArray(data)) {
			for (let index = 0; index < data.length; index++) {
				const step = data[index]
				if (step?.status === PENDING && typeof step?.id !== 'undefined') {
					navigate(`/onboarding/${step?.id}`)
					break
				}
			}
		}
	}, [data])

	useEffect(() => {
		if (Array.isArray(data)) {
			const steps = data.filter((step) => step?.status === PENDING)
			if (steps.length === 0 && onboardStatus === 'Approved') {
				if ($continue) {
					navigate(`${$continue}`)
				} else {
					navigate(`/assigned-workflows`)
				}
			}
		}
	}, [data])

	const helperItems = [
		{
			title: 'Information Review',
			description: 'We need to review your proved information.',
			icon: <img src={lightBulb} alt='' className='h-6 w-6' />,
		},
	]
	return (
		<div className='container-fluid overflow-hidden bg-white'>
			<div className='row'>
				<div className='col-sm-3 min-h-screen bg-[#F5F7FF] px-0 pt-2'>
					<LeftSidebar />
				</div>
				<div className='col-sm-9 '>
					<div className='grid  grid-cols-3'>
						<div className='col-span-3 mb-3 flex flex-row items-center justify-end  !border-b bg-white p-2 px-3'>
							<LogoutButton />
						</div>

						<div className='col-span-2'>
							<div className='grid grid-cols-12  pt-5'>
								<div className='col-span-1' />
								<div className='col-span-9 flex flex-col space-y-9 px-2'>
									<div className='my-6 text-center'>
										<i className='uil-clock-three text-8xl text-yellow-500' />
										<h3 className='text-xl font-bold'>
											Your information is currently under review.
										</h3>
										<p className='text-gray-500'>
											You submit documents and information for review. After
											this submission, the advisor will carefully examine your
											profile and assess the provided materials. Following
											their thorough review, we will provide you with updates
											on the progress of your application.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className='col-span-1 pt-5'>
							<div className='px-4'>
								<HelperCard items={helperItems} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ReviewInformation
