import * as yup from 'yup'
import AssignSubscription from './assign-subscription'

export const getPayload = (values) => {
	return {
		user_id: values?.user_id,
		plan_id: values?.subscription?.value?.id,
		team_id: values?.team_id,
		plan_price: parseInt(values?.plan_price),
		users_allowed: values?.users_allowed,
		status: values?.status,
	}
}

export const validationSchema = yup.object().shape({
	subscription: yup.mixed().required('Please select subscription plan'),
	plan_price: yup
		.number('Plan price must be type of number')
		.min(1, 'Price must be greater than or equal to 1')
		.required('Please add plan price'),
	users_allowed: yup.number().min(1).required('Please add plan price'),
})

export default AssignSubscription
