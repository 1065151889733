import { useMemo } from 'react'

const Clients = ({ clients }) => {
	const _clients = useMemo(() => {
		if (Array.isArray(clients)) {
			const _clients = clients.map((client) => `${client?.first_name} ${client?.last_name}`)
			return _clients.slice(0, 3)
		} else {
			return []
		}
	}, [clients])

	const totalClients = useMemo(() => Number(clients?.length) || 0, [clients])
	const restCount = totalClients - 2

	return (
		<div className='flex flex-wrap gap-1'>
			{_clients.map((client, i) => {
				if (i > 1) {
					return (
						<div
							className='min-w-fit rounded-3xl !border border-solid border-[#B5B5C3] px-2 py-1 text-xs font-normal not-italic text-[#495057]'
							key={i}>
							+{restCount}
						</div>
					)
				} else {
					return (
						<div
							className='min-w-fit rounded-3xl !border border-solid border-[#B5B5C3] px-2 py-1 text-xs font-normal not-italic text-[#495057]'
							key={i}>
							{client}
						</div>
					)
				}
			})}
		</div>
	)
}

export default Clients
