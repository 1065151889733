import { Dialog, Transition } from '@headlessui/react'
import { ReactComponent as SearchIcon } from 'assets/svg/search-icon.svg'
import { Field, FormikProvider, useFormik } from 'formik'
import { Fragment, useEffect, useMemo } from 'react'
import SimpleBar from 'simplebar-react'
import { getFormValues } from '.'
import DataList from './data-list'

const AddToFolder = ({ isOpen, onClose, type = 'folder' }) => {
	const formik = useFormik({
		initialValues: {
			search: '',
			search_type: {
				icon: 'uil-file-blank',
				label: 'All',
				value: 'all',
			},
		},
	})

	const formValues = useMemo(
		() => (formik?.values?.search?.length >= 3 ? getFormValues(formik.values) : undefined),
		[formik.values],
	)

	useEffect(() => {
		if (formValues) {
			console.log('formValues', formValues)
		}
	}, [formValues])

	const data = useMemo(
		() =>
			new Array(8).fill(0).map(() => ({
				title: 'SIN Number Application',
				files: [2, 13],
				last_edit: new Date(),
			})),
		[],
	)
	const documents = useMemo(
		() =>
			new Array(6).fill(0).map((_, idx) => ({
				title: 'Passport Application',
				type: idx <= 1 ? 'folder' : 'document',
				noOfFiles: 42,
				folders: ['RBC Bank', 'Applications 1', 'Applications 2'],
				last_edit: new Date(),
			})),
		[],
	)
	const listData = type === 'folder' ? data : documents

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-[1008]' onClose={onClose}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/30' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'>
							<Dialog.Panel className='w-full max-w-lg transform overflow-hidden rounded-3xl bg-white p-2 text-left align-middle shadow-xl transition-all'>
								<div className='relative flex justify-end rounded-3xl bg-blue-50 p-3'>
									<button type='button' onClick={onClose}>
										<i className='fas fa-times rounded-full bg-white px-2 py-1 text-[20px]' />
									</button>
								</div>
								<div className='mt-3 flex flex-col '>
									<div className='ms-4 flex flex-col justify-start ps-2'>
										<Dialog.Title
											as='h2'
											className='mb-3 text-left text-xl font-bold leading-6 text-gray-900'>
											{type === 'folder'
												? 'Move to folder'
												: 'Select document to add'}
										</Dialog.Title>
										<div className='flex h-[56px] w-full max-w-[512px] items-center space-x-1 rounded'>
											<FormikProvider value={formik}>
												<form className='flex-1 !pe-6'>
													<div className='flex h-[56px] w-full items-center space-x-1 rounded px-2.5 shadow'>
														<div className='px-2'>
															<SearchIcon height={20} width={20} />
														</div>
														<Field name='search'>
															{({ field }) => (
																<input
																	type='text'
																	{...field}
																	className='flex-1 px-1 py-2 text-base font-normal text-gray2-dark'
																	placeholder='Search documents & folder'
																/>
															)}
														</Field>
													</div>
												</form>
											</FormikProvider>
										</div>
									</div>
									<div className='ms-4 mt-2 flex flex-col justify-start gap-1 pe-4 ps-2'>
										<SimpleBar style={{ maxHeight: '300px' }}>
											<DataList type={type} listData={listData} />{' '}
										</SimpleBar>
									</div>

									<hr className='mx-4 my-3 bg-gray-300' />
									<div className='mb-3 ms-4 flex w-full flex-col gap-2 pe-5 ps-2'>
										<button
											type='button'
											disabled={listData.length === 0}
											className={`rounded-md !border p-2 font-medium text-white ${
												listData.length > 0
													? 'border-main bg-main'
													: 'border-gray-400 bg-gray-500'
											}`}>
											Add Now
										</button>

										<button
											type='button'
											className='rounded-md !border !border-main bg-white p-2 font-medium text-main'>
											+ Create new
										</button>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export default AddToFolder
