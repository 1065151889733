import { useEffect, useMemo, useState } from 'react'
import { Button } from 'theme/ui/forms'
import CommentForm from './comment-form'
import CommentsListing from './comments-listing'
import { useLazyGetWorkflowCommentsQuery } from './workflow-comment-api'

const WorkflowCommentSection = ({ workflowId, stepId }) => {
	const [isDesc, setDesc] = useState(true)
	const [fetchComments, { data, isLoading }] = useLazyGetWorkflowCommentsQuery()

	const toggleSorting = () => setDesc(!isDesc)

	useEffect(() => {
		fetchComments({
			page: 1,
			limit: 10,
			workflow: workflowId,
			workflow_step: stepId,
			sort: isDesc ? 'DESC' : 'ASC',
		})
	}, [workflowId, stepId, isDesc])
	const comments = useMemo(() => data?.comments || [], [data?.comments])

	return (
		<section className='max-w-[70%] antialiased lg:py-16'>
			<div>
				<div className='flex items-center justify-between'>
					<h2 className='text-lg font-bold text-gray-800'>
						Comments ({comments.length})
					</h2>

					<Button type='button' size='sm' variant='white' onClick={toggleSorting}>
						{isDesc ? (
							<div>
								{/* <span className=''>Newest First</span> */}
								<i className='bx bx-sort-down text-base' />
							</div>
						) : (
							<div>
								{/* <span className=''>Oldest First</span> */}
								<i className='bx bx-sort-up text-base' />
							</div>
						)}
					</Button>
				</div>

				<CommentForm workflowId={workflowId} stepId={stepId} />
			</div>

			<CommentsListing
				comments={comments}
				isLoading={isLoading}
				workflowId={workflowId}
				stepId={stepId}
			/>
		</section>
	)
}

export default WorkflowCommentSection
