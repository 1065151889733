import { Field } from 'formik'
import { useUser } from 'hooks'
import { useCreateSystemFieldMutation } from 'modules/system-fields/system-fields-api'
import { CreatableSelectField } from 'theme/ui/forms'
import useSystemFieldOptionsQuery from './use-system-field-options-query'

const SystemFieldDropdown = ({ label, name, onAutoSave, onHighlight }) => {
	const { team } = useUser()
	const { options, isLoading } = useSystemFieldOptionsQuery()
	const [createSystemField] = useCreateSystemFieldMutation()

	const onCreateOption = async (value) => {
		const team_ids = [team?.id]
		const payload = {
			documentFields: {
				field_name: value,
				is_system_field: team_ids.length > 0 ? false : true,
			},
		}
		await createSystemField(payload)
	}

	return (
		<Field
			name={name}
			type='select'
			label={label}
			placeholder='System field'
			isLoading={isLoading}
			options={options}
			className='!min-w-[55%]'
			onAutoSave={onAutoSave}
			onFocus={onHighlight}
			onCreateOption={onCreateOption}
			component={CreatableSelectField}
		/>
	)
}

export default SystemFieldDropdown
