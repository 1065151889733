const WorkflowOutstandingsSkeleton = ({ pageSize }) => {
	return (
		<div className='animate-pulse'>
			{new Array(pageSize).fill(0).map((_, idx) => (
				<div
					className='mb-2 flex w-full justify-between rounded border-b bg-white px-3 py-1'
					key={idx}>
					<div className='flex w-full items-center justify-between'>
						<div className='flex items-center space-x-4'>
							<span className='block !h-[15px] w-[15px] rounded bg-slate-200'></span>
							<span className='block !h-[26px] w-[270px] rounded bg-slate-200'></span>
						</div>

						<div className='flex items-center space-x-4'>
							<div
								className='flex h-[42px] items-center'
								style={{
									fontSize: 28,
								}}>
								<span className='mx-2 block !h-[20px] w-[20px] rounded bg-slate-200'></span>
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	)
}

export default WorkflowOutstandingsSkeleton
