import { Field, FormikProvider, useFormik } from 'formik'

import { useAppRouter } from 'hooks'
import { FEATURES_APP_ROUTES } from 'modules/subscriptions/routes'
import { Button, InputField, TextareaField } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { validationSchema } from '.'
import { useCreateFeatureMutation, useUpdateFeatureMutation } from '../features-api'

const FeatureForm = ({ feature }) => {
	const [createFeature] = useCreateFeatureMutation()
	const [updateFeature] = useUpdateFeatureMutation()
	const { goBack, navigate, hasHistory } = useAppRouter()

	const formik = useFormik({
		initialValues: {
			name: feature?.name || '',
			description: feature?.description || '',
		},
		enableReinitialize: true,
		validationSchema: validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true)

				let response
				if (feature) {
					response = await updateFeature({ payload: values, id: feature?.id })
				} else {
					response = await createFeature(values)
				}

				if (response?.data?.status === 200) {
					notification('success', response?.data?.message)
					return hasHistory ? goBack() : navigate(FEATURES_APP_ROUTES.findAll())
				}

				setSubmitting(false)
			} catch (error) {
				setSubmitting(false)
				notification('warn', error?.message)
			}
		},
	})

	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				<div className='flex w-full gap-10'>
					<div className='form-container'>
						<div className='form-row'>
							<div className='form-heading'>
								<h3>Feature Details</h3>
								<p>
									Selected is the workflow which you will assign it to specific
									client and co-applicant.
								</p>
							</div>

							<div className='form-fields-card space-y-6'>
								<Field
									name='name'
									type='text'
									label='Enter Feature name'
									component={InputField}
									isRequiredField
								/>
								<Field
									label='Enter Feature description'
									name='description'
									type='textarea'
									component={TextareaField}
									isRequiredField
								/>
							</div>
						</div>
					</div>
					<div className='max-w-[200px]'>
						<div className='flex items-start space-x-2 !py-5'>
							<i className='mdi mdi-lightbulb-on text-main' />
							<p className='text-xs text-gray-500'>
								Easily manage your feature hassle-free from one convenient
								dashboard.
							</p>
						</div>
					</div>
				</div>
				<div className='form-container !border-t py-6'>
					<div className='flex justify-end'>
						<Button
							type='submit'
							isLoading={formik.isSubmitting}
							isLoadingText='Saving...'>
							Save
						</Button>
					</div>
				</div>
			</form>
		</FormikProvider>
	)
}

export default FeatureForm
