const TagList = ({ tags }) => {
	const colors = [
		'bg-blue-100 text-blue-800',
		'bg-green-100 text-green-800',
		'bg-gray-100 text-gray-800',
		'bg-red-100 text-red-800',
		'bg-purple-100 text-purple-800',
	]

	return (
		<div className='flex flex-wrap gap-1'>
			{tags.map((tag, index) => (
				<span
					key={index}
					className={`tag bg-gre ${
						colors[index % colors.length]
					} rounded px-2 py-1 text-xs font-medium`}>
					{tag?.tag}
				</span>
			))}
		</div>
	)
}

export default TagList
