import { Fragment, useEffect, useState } from 'react'
import { validatePassword } from 'utils/formValidation'

// Style CSS
import styles from 'styles/animation/transition.module.css'
const { validationColorTrs } = styles

const PasswordSuggestion = ({ password }) => {
	const [errors, seterrors] = useState([])

	useEffect(() => {
		let errors = validatePassword(password)
		seterrors(errors)
	}, [password])

	return (
		<Fragment>
			<div className='my-2.5 flex justify-between rounded-xl bg-zinc-50 p-3 '>
				{errors.map(({ contain, name, isWrong }, index) => {
					let validationColor = ' text-slate-600'

					if (isWrong === true) {
						validationColor = 'text-red-500'
					} else if (isWrong === false) {
						validationColor = 'text-green-500'
					}

					return (
						<div key={index + name}>
							<div
								className={`text-center text-lg ${validationColor} ${validationColorTrs}  `}>
								{contain}
							</div>

							<div className='text-xs text-slate-500'>{name}</div>
						</div>
					)
				})}
			</div>
		</Fragment>
	)
}

export default PasswordSuggestion
