import ProfileTabs from 'modules/profile'
import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardHeader, CardTitle, Container } from 'reactstrap'

import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const Profile = () => {
	const { isSuperAdmin } = useSelector((state) => state.Permissions)

	return (
		<AppLayout
			meta={{
				title: 'Profile',
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<PageHeader
						title='Profile'
						description='Customize Your Experience: Personalize, Control, and Manage Your Profile Settings Effortlessly.'
					/>
					<div className='page-body'>
						{isSuperAdmin ? (
							<Fragment>
								<Container fluid>
									<Card>
										<CardHeader>
											<CardTitle>Profile</CardTitle>
										</CardHeader>
										<CardBody>Super-Admin User</CardBody>
									</Card>
								</Container>
							</Fragment>
						) : (
							<ProfileTabs />
						)}
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default Profile
