import { useMemo } from 'react'

const useInitialValues = ({ client }) => {
	const step = useMemo(() => {
		const _values = {}
		if (Array.isArray(client)) {
			for (let i = 0; i < client.length; i++) {
				const e = client[i]
				const valueObj = {
					status: e?.status.toString(),
					reason: e?.reason,
				}

				if (e?.user_key === 'sin_number') {
					Object.assign(valueObj, { value: e?.user_value })
				}

				if (e?.user_key === 'verify_info') {
					const user = e?.user
					const profile = e?.user?.userProfile

					Object.assign(valueObj, {
						user: {
							first_name: user?.first_name,
							last_name: user?.last_name,
							address1: profile?.address_1,
							address2: profile?.address_2,
							city: profile?.city,
							country: profile?.country,
							unit: profile?.unit,
							zip_code: profile?.zip_code,
							province: profile?.province,
						},
					})
				}

				_values[e?.user_key] = valueObj
			}
		}
		return _values
	}, [client])

	return {
		step,
	}
}

export default useInitialValues
