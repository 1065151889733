import { useState } from 'react'
import { Link } from 'react-router-dom'

import DeleteConfirmAlert from 'theme/ui/delete-confirm-alert'
import { formatDate } from 'utilities/helpers'
import notification from 'utilities/notification'
import { useDeletePaymentMutation } from '../payments-api'
import { PAYMENTS_APP_ROUTES } from '../routes'

const PaymentsActions = ({ payment, isActionActive }) => {
	const [isOpen, setIsOpen] = useState(false)
	const onClose = () => setIsOpen(false)
	// const onOpen = () => setIsOpen(true)

	const [deletePayment, { isLoading: isDeleting }] = useDeletePaymentMutation()
	const onDeletePayment = async (id) => {
		try {
			const response = await deletePayment(id)
			if (response?.data?.status === 200) {
				notification('success', response?.data?.message)
			}
			onClose()
		} catch (error) {
			notification('warn', error?.message)
		}
	}

	return (
		<div>
			{isActionActive ? (
				<>
					<DeleteConfirmAlert
						isOpen={isOpen}
						onClose={onClose}
						isLoading={isDeleting}
						onConfirm={() => onDeletePayment(payment?.id)}
					/>
					<div className='max-w-fit'>
						<div className='flex space-x-2'>
							<Link
								to={PAYMENTS_APP_ROUTES.findOne(payment?.id)}
								className='action-btn btn-gray-outline'>
								<i className='uil uil-eye' style={{ fontSize: 16 }} />
								<span>View</span>
							</Link>
							{/* {payment?.status !== 'completed' ? (
						<Link
							to={PAYMENTS_APP_ROUTES.edit(payment?.id)}
							className='action-btn btn-info-outline'>
							<i className='uil uil-edit' style={{ fontSize: 16 }} />
							<span>Edit</span>
						</Link>
					) : undefined} */}
							{/* <button type='button' className='action-btn btn-red-outline' onClick={onOpen}>
						<i className='uil-trash-alt' style={{ fontSize: 16 }} />
						<span>Delete</span>
					</button> */}
						</div>
					</div>
				</>
			) : payment?.payment_date ? (
				formatDate(payment?.payment_date)
			) : (
				'-'
			)}
		</div>
	)
}

export default PaymentsActions
