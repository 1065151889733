import { Dialog, Transition } from '@headlessui/react'
import DocumentVersionsListing from './document-versions-listing'

const DocumentVersionPopup = ({ isOpen, onClose, id }) => {
	return (
		<Transition
			show={isOpen}
			enter='transition duration-100 ease-out'
			enterFrom='transform scale-95 opacity-0'
			enterTo='transform scale-100 opacity-100'
			leave='transition duration-75 ease-out'
			leaveFrom='transform scale-100 opacity-100'
			leaveTo='transform scale-95 opacity-0'
			as='div'>
			<Dialog as='div' open={true} onClose={() => {}} className='popup-overlay relative'>
				<div className='fixed inset-0 bg-black/30' aria-hidden='true' />
				<div className='fixed inset-0 flex w-screen items-center justify-center p-4'>
					<Dialog.Panel className='w-[700px] rounded-md bg-white'>
						<div className='rounded-t border-b  !px-5  py-4'>
							<div className='relative left-[1%] top-[8%]'>
								<button
									type='button'
									className='absolute -top-4 right-0'
									onClick={onClose}>
									<i className='fas fa-times text-lg' />
								</button>
							</div>
							<div className='mx-auto'>
								<h1 className='mb-3 text-left text-lg font-bold'>
									Document Versions History
								</h1>
							</div>

							<div className='max-h-96 overflow-auto'>
								<DocumentVersionsListing id={id} />
							</div>
						</div>
					</Dialog.Panel>
				</div>
			</Dialog>
		</Transition>
	)
}

export default DocumentVersionPopup
