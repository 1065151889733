import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import Select from 'react-select'
import { FormFeedback, Label } from 'reactstrap'

const SelectDropdown = ({
	meta: { touched, error },
	isSearchable = true,
	isLoading,
	label,
	onInputChange,
	input,
	name,
	options,
	isMulti,
	variant,
	width,
	isClearable,
	...custom
}) => {
	return (
		<Fragment>
			<div
				className={classNames('mb-3 flex', {
					'flex-row items-center space-x-[10px]': variant === 'horizontal',
					'flex-col space-y-[10px]': variant === 'default',
				})}>
				{label && <Label className='m-0'>{label}</Label>}
				<div className='flex flex-col'>
					<Select
						{...input}
						{...custom}
						name={name}
						className={`select-dropdown-container ${width || ''}`}
						classNamePrefix='select-dropdown'
						options={options}
						onInputChange={onInputChange}
						onChange={(value) => input.onChange(value)}
						onBlur={(value) => input.onBlur(value.value)}
						value={input.value}
						isSearchable={isSearchable}
						isClearable={isClearable}
						isMulti={isMulti}
						isLoading={isLoading}
					/>
					{touched && error && (
						<FormFeedback style={{ display: 'block' }}>{touched && error}</FormFeedback>
					)}
				</div>
			</div>
		</Fragment>
	)
}

SelectDropdown.propTypes = {
	meta: PropTypes.object,
	input: PropTypes.object.isRequired,
	label: PropTypes.string,
	name: PropTypes.string,
	options: PropTypes.array,
	isMulti: PropTypes.bool,
	isClearable: PropTypes.bool,
	variant: PropTypes.oneOf(['default', 'horizontal']),
}

SelectDropdown.defaultProps = {
	meta: null,
	options: [],
	label: '',
	name: '',
	isMulti: false,
	isClearable: true,
	variant: 'default',
}

export default SelectDropdown
