import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap'
import { Field, reduxForm } from 'redux-form'
import Spinner from 'theme/ui/spinner'
import ButtonLink from '../../../components/Common/ButtonLink'
import ButtonWithLoader from '../../../components/Common/ButtonWithLoader'
import { CheckBox } from '../../../components/Forms/CheckBoxComponent'
import { TextField } from '../../../components/Forms/TextFieldComponent'
import { normalizeMaxLength, required } from '../../../utils/formValidation'
import MethodGroup from './MethodGroup'
import { capitalize } from './customHooks'

const validate = {
	name: required('Name'),
	description: required('Short description'),
}

const GroupsForm = (props) => {
	const {
		loading,
		isFetching,
		handleSubmit,
		initialize,
		initialValues,
		groupId,
		newPermissionsArray,
	} = props

	useEffect(() => {
		if (initialValues) {
			initialize({
				name: initialValues.name,
				description: initialValues.description,
				is_active: initialValues.is_active,
				accesses: initialValues.accesses,
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues])

	return (
		<React.Fragment>
			{/* {loading && <Loader />} */}
			<Card>
				<CardHeader className='d-flex justify-content-between items-content-center'>
					<CardTitle>{groupId ? 'Edit' : 'Create'} Roles</CardTitle>
					<ButtonLink to='/roles' color='primary'>
						Back
					</ButtonLink>
				</CardHeader>
				{isFetching ? (
					<div className='my-6 flex w-full items-center justify-center'>
						<Spinner />
						<span className='ml-2'>Loading...</span>
					</div>
				) : (
					<CardBody>
						<form onSubmit={handleSubmit}>
							<Row>
								<Col sm={12}>
									<Field
										type='checkbox'
										component={CheckBox}
										name='is_active'
										label='Is Active *'
										id='is_active'
									/>
								</Col>
								<Col sm={6}>
									<Field
										component={TextField}
										name='name'
										label='Enter Role Name *'
										validate={validate.name}
										disabled={groupId}
									/>
								</Col>
								<Col sm={6}>
									<Field
										component={TextField}
										name='description'
										label='Enter Short Description *'
										validate={validate.description}
										normalize={normalizeMaxLength(30)}
									/>
								</Col>
							</Row>
							<Row>
								<Col sm={12} md={12} xs={12}>
									<h4>Permissions</h4>
									<div className='table-responsive'>
										<table className='table-1 table-bordered'>
											<thead className=''>
												<tr>
													<th className='col-sm-2'>Module</th>
													<th className='col-sm-1 text-center'>
														Select All
													</th>
													<th className='col-sm-7 text-center'>
														Actions
													</th>
												</tr>
											</thead>
											<tbody>
												{newPermissionsArray &&
													newPermissionsArray.map((n) => [
														<>
															<tr key={n.name}>
																<td>
																	<span className='font-bold'>
																		{capitalize(n.name)}
																	</span>
																</td>
																<Field
																	name='accesses'
																	label={n.name}
																	component={MethodGroup}
																	options={n.method}
																/>
															</tr>
														</>,
													])}
											</tbody>
										</table>
									</div>
								</Col>
							</Row>
							<ButtonWithLoader type='submit' loading={loading}>
								Submit
							</ButtonWithLoader>
						</form>
					</CardBody>
				)}
			</Card>
		</React.Fragment>
	)
}

GroupsForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
}

const ReduxGroupsForm = reduxForm({ form: 'groups', enableReinitialize: true })(GroupsForm)

export default ReduxGroupsForm
