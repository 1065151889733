/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

import { useAppRouter } from 'hooks'
import { filterGroups } from 'modules/authentication'
import useStepsQuery from 'pages/Authentication/client-document-upload/components/useStepsQuery'
import { add } from 'utils/storageService'
import { setDefaultGroup } from '../../store/permissions/actions'
import { fromQueryToObject, showSuccessToast } from '../../utils/commonFunctions'

const TeamDropdown = ({ disableTeamPage }) => {
	const [IsOpen, setIsOpen] = useState(false)
	const { groups, defaultGroup, hasTeam } = useSelector((state) => state.Permissions)
	const dispatch = useDispatch()
	const { data } = useStepsQuery()
	const query = window.location.search
	const invitationToken = fromQueryToObject(query)?.token
	const PENDING = 'pending'
	const { navigate } = useAppRouter()

	const isOnBoarded = useMemo(() => {
		if (Array.isArray(data)) {
			const steps = data.filter((step) => step?.status === PENDING)
			return steps.length === 0 ? true : false
		} else {
			return true
		}
	}, [data])

	const $groups = groups.filter((g) => filterGroups(g))

	useEffect(() => {
		if (!defaultGroup) {
			if (hasTeam) {
				const currentGroup = $groups.find((group) => group?.team?.id === hasTeam?.id)
				if (currentGroup) {
					dispatch(setDefaultGroup(currentGroup))
				} else {
					dispatch(setDefaultGroup($groups[0]))
				}
			} else {
				dispatch(setDefaultGroup($groups[0]))
			}
		}
	}, [defaultGroup])

	useEffect(() => {
		if (invitationToken && isOnBoarded) {
			add('token', invitationToken)
		}
	}, [invitationToken])

	const toggle = () => {
		setIsOpen(!IsOpen)
	}

	const handleChange = (id) => {
		if (defaultGroup.id !== id) {
			const currentGroup = $groups.filter((group) => group.id === id)
			dispatch(setDefaultGroup(currentGroup[0])).then((response) => {
				if (response?.success) {
					const redirectTo =
						response?.data?.group?.slug === 'client'
							? '/assigned-workflows'
							: '/clients'

					showSuccessToast(response?.message)
					navigate(redirectTo)
				}
			})
		}
	}

	return (
		<Dropdown toggle={toggle} isOpen={IsOpen} className='team-dropdown-btn'>
			<DropdownToggle data-toggle='dropdown' tag='div' className='flex flex-row'>
				<span>
					<i className='uil-building'></i>
				</span>
				{defaultGroup && (
					<div className='text_hide flex items-center space-x-1'>
						<span className='line-clamp-1'>{defaultGroup?.team?.team_name || '-'}</span>
						<span className='team-role'>&nbsp;({defaultGroup?.group?.name})</span>
					</div>
				)}
				{disableTeamPage && (
					<span className='mobile_hide ms-2'>
						{IsOpen ? (
							<span className='uil-angle-up'></span>
						) : (
							<span className='uil-angle-down '></span>
						)}
					</span>
				)}
			</DropdownToggle>
			<DropdownMenu>
				{$groups.map((group, index) => (
					<Fragment key={index}>
						{group?.team?.team_name && (
							<DropdownItem
								active={defaultGroup && defaultGroup.id === group.id}
								onClick={() => handleChange(group.id)}>
								<small>
									{group?.team?.team_name ? group?.team?.team_name : 'No Team'}
								</small>
								&nbsp;
								<small className='text-muted'>
									({group?.group?.name ? group?.group?.name : 'No Group'})
								</small>
							</DropdownItem>
						)}
					</Fragment>
				))}
			</DropdownMenu>
		</Dropdown>
	)
}

export default TeamDropdown
