import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Row, Spinner } from 'reactstrap'
import { Field, reduxForm } from 'redux-form'
import ButtonLink from '../../../components/Common/ButtonLink'
import { SelectField } from '../../../components/Forms/SelectComponent'
import { TextField } from '../../../components/Forms/TextFieldComponent'
import apiEndpoints from '../../../helpers/apiEndpoints'
import * as actions from '../../../store/common/actions'
import { addToArray, removeFromArray } from '../../../utils/commonFunctions'
import { emailCheck, passwordCheck, required } from '../../../utils/formValidation'
import roles from '../../../utils/roles'

const validate = {
	first_name: required('first name'),
	last_name: required('last name'),
	email: [required('email'), emailCheck],
	phone_number: required('phone number'),
	team_name: required('Team', 'select'),
	password: [required('password'), passwordCheck],
	team_selection: required('Team selection', 'select'),
	group: required('Role', 'select'),
}

const InviteUserForm = (props) => {
	const { handleSubmit, loading } = props
	const [TeamSelection, setTeamSelection] = useState(null)
	const [teamData, setTeamData] = useState([])
	const dispatch = useDispatch()
	const { data, loading: teamLoader } = useSelector((state) => state.common)
	const owner = { id: 2, name: 'Owner' }

	useEffect(() => {
		addToArray(roles, owner)
		return () => {
			removeFromArray(roles, owner)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const roleOptions = roles.map((role) => ({
		label: role.name,
		value: role.id,
	}))

	const handleChange = (e) => {
		if (e && e.value) {
			if (e.value === 2) {
				setTeamSelection('YES')
			} else {
				setTeamSelection('NO')
				dispatch(actions.all(apiEndpoints.teams.allTeams))
			}
		}
	}

	useEffect(() => {
		if (data && data.items) {
			const teamOptions =
				data && data.items
					? data.items.map((team) => ({
							label: team.team_name,
							value: team.id,
					  }))
					: [{ label: 'Please Wait...', value: null }]
			setTeamData(teamOptions)
		}
	}, [data])

	return (
		<React.Fragment>
			<Card>
				<CardHeader className=''>
					<CardTitle className='mb-0'>Create User</CardTitle>
					<ButtonLink to='/users' color='primary'>
						Back
					</ButtonLink>
				</CardHeader>
				<CardBody>
					<form onSubmit={handleSubmit} noValidate>
						<Container fluid>
							<Row className='align-items-center justify-content-center1'>
								<Col xs={4} sm={4} md={4}>
									<Field
										name='first_name'
										label='First Name *'
										component={TextField}
										placeholder='Enter First Name'
										type='text'
										required
										validate={validate.first_name}
										autoComplete='off'
									/>
								</Col>

								<Col item xs={4} sm={4} md={4}>
									<Field
										name='last_name'
										label='Last Name *'
										component={TextField}
										placeholder='Enter Last Name'
										type='text'
										required
										validate={validate.last_name}
										autoComplete='off'
									/>
								</Col>

								<Col item xs={4} sm={4} md={4}>
									<Field
										name='email'
										label='Email *'
										component={TextField}
										placeholder='Enter email'
										type='email'
										required
										validate={validate.email}
										autoComplete='off'
									/>
								</Col>

								<Col item xs={4} sm={4} md={4}>
									<Field
										name='phone_number'
										label='Phone Number *'
										component={TextField}
										placeholder='Enter Phone Number'
										type='text'
										required
										validate={validate.phone_number}
										autoComplete='off'
									/>
								</Col>

								<Col item xs={4} sm={4} md={4}>
									<Field
										name='password'
										label='Enter Password *'
										component={TextField}
										placeholder='Enter Password'
										type='password'
										required
										validate={validate.password}
										autoComplete='off'
									/>
								</Col>
							</Row>
							<Row>
								<Col sm={4} md={4}>
									<Field
										name='group_id'
										label='Select Role *'
										component={SelectField}
										type='select'
										required
										validate={validate.group}
										autoComplete='off'
										options={roleOptions}
										onChange={handleChange}
									/>
								</Col>
								<Col item xs={4} sm={4} md={4}>
									{TeamSelection && (
										<Fragment>
											{TeamSelection === 'YES' ? (
												<Fragment>
													<Col>
														<Field
															type='text'
															name='team_name'
															component={TextField}
															label='Team Name *'
															validate={validate.team_name}
														/>
													</Col>
												</Fragment>
											) : (
												<Fragment>
													<Field
														type='select'
														name='team'
														label='Select Team *'
														options={teamData}
														isLoading={teamLoader}
														component={SelectField}
														validate={validate.team_name}
													/>
												</Fragment>
											)}
										</Fragment>
									)}
								</Col>

								<div className='mt-3 text-end'>
									<button
										className='btn btn-primary w-sm waves-effect waves-light'
										type='submit'
										disabled={loading}>
										{!loading ? 'Create' : ''}
										{loading && (
											<Spinner animation='border' size='sm' color='default' />
										)}
									</button>
								</div>
							</Row>
						</Container>
					</form>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}

InviteUserForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
}

const InviteUserFormRedux = reduxForm({
	form: 'invite',
	enableReinitialize: true,
	initialValues: {
		team_selection: 'YES',
	},
})(InviteUserForm)

export default InviteUserFormRedux
