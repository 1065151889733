import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import classNames from 'classnames'
import { useMemo } from 'react'

const TextEditor = ({ id, form, label, field, isRequiredField, labelHelperText, helperText }) => {
	const { name, value } = field

	const touched = useMemo(() => form?.touched[name], [form?.touched, name])
	const error = useMemo(() => form?.errors[name], [form?.errors, name])

	return (
		<div className='form-group'>
			{label && (
				<label
					htmlFor={id}
					className='flex items-baseline justify-between text-[14px] font-bold text-[#495057]'>
					<span>
						{label} {isRequiredField && <span className='text-red-500'>*</span>}
					</span>
					{labelHelperText && (
						<span className='text-[11px] font-normal'>
							<span className='text-red-500'>*</span> This asterisk indicates not
							optional
						</span>
					)}
				</label>
			)}
			<div
				className={classNames('form-control !flex items-center justify-between', {
					'is-invalid !px-[10px]': touched && error,
				})}
				style={{ width: '100%' }} // Ensure the wrapper spans full width of form card
			>
				<div className='editor-wrapper' style={{ width: '100%' }}>
					{' '}
					{/* Editor wrapper */}
					<CKEditor
						editor={ClassicEditor}
						data={value}
						onChange={(event, editor) => {
							const data = editor.getData()
							form.setFieldValue(name, data)
						}}
						onBlur={() => form.setFieldTouched(name, true)}
					/>
				</div>
			</div>
			{helperText && <span className='text-[13px] italic text-gray-400'>{helperText}</span>}
			{touched && error && <div className='invalid-feedback text-xs'>{error}</div>}
		</div>
	)
}

TextEditor.defaultProps = {
	autoComplete: 'off',
}

export default TextEditor
