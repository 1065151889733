import { Fragment } from 'react'

import DocumentPackageDropdown from './document-package-dropdown'
import { MultiDocumentsDropdown } from './documents-dropdown'

const AssignDocuments = ({ formik }) => {
	return (
		<Fragment>
			<MultiDocumentsDropdown formik={formik} />

			<DocumentPackageDropdown />
		</Fragment>
	)
}

export default AssignDocuments
