export const ASSIGN_WORKFLOW_APP_ROUTES = {
	findAll: () => '/assigned-workflows',
	findOne: (id) => `/assigned-workflows/${id}/fill`,
}

export const ASSIGN_WORKFLOW_API_ROUTES = {
	client: {
		findAll: () => '/client/workflows',
	},
	advisor: {
		findAll: () => '/advisor/workflows',
	},
}
