import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';
import Loader from './components/Common/Loader';

const Callback = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        // dispatch(authActions.handleAuthentication())
    }, [dispatch]);

    return (
        <React.Fragment>
            <Loader />
        </React.Fragment>
    )
}

export default Callback;
