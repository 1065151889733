/* eslint-disable react-hooks/exhaustive-deps */
import {
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'

import classNames from 'classnames'
import DataTablePagination from './data-table-pagination'
import TableHeader from './table-header'
import TbodySekeleton from './tbody-sekeleton'

const DataTable = ({
	columns,
	data,
	isLoading,
	meta,
	pagination,
	setPagination,
	onMouseEnter,
	onMouseLeave,
	sorting,
	setSorting,
	isFullRowSelection,
	setRowSelection,
	rowSelection,
	isRowSelection,
	variant,
}) => {
	const table = useReactTable({
		columns: columns || [],
		data: data || [],
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		manualPagination: true,
		pageCount: meta?.totalPages ?? -1,
		state: { pagination, sorting, rowSelection },
		onPaginationChange: setPagination,
		onSortingChange: setSorting,
		enableSorting: true,
		onRowSelectionChange: setRowSelection,
		enableRowSelection: isRowSelection,
		getRowId: (row) => (isFullRowSelection ? JSON.stringify(row) : row.id),
	})

	return (
		<React.Fragment>
			<div className='w-full overflow-auto'>
				<table
					className={classNames(
						variant === 'secondary' ? 'react-table-secondary' : 'react-table',
					)}>
					<TableHeader table={table} variant={variant} />

					<Fragment>
						{isLoading ? (
							<TbodySekeleton table={table} />
						) : (
							<tbody>
								{table.getRowModel().rows?.length > 0 ? (
									<Fragment>
										{table.getRowModel().rows.map((row) => (
											<tr
												key={row.id}
												onMouseEnter={
													onMouseEnter ? () => onMouseEnter(row.id) : null
												}
												onMouseLeave={onMouseLeave}>
												{row.getVisibleCells().map((cell) => (
													<td key={cell.id} className='h-[48px]'>
														{flexRender(
															cell.column.columnDef.cell,
															cell.getContext(),
														)}
													</td>
												))}
											</tr>
										))}
									</Fragment>
								) : (
									<tr>
										<td colSpan={100} className='text-center'>
											No record found
										</td>
									</tr>
								)}
							</tbody>
						)}
					</Fragment>
				</table>
			</div>

			{pagination && meta ? <DataTablePagination table={table} meta={meta} /> : undefined}
		</React.Fragment>
	)
}

DataTable.propTypes = {
	columns: PropTypes.array,
	data: PropTypes.array,
	pagination: PropTypes.shape({ pageIndex: PropTypes.number, pageSize: PropTypes.number }),
	meta: PropTypes.shape({
		currentPage: PropTypes.number,
		limit: PropTypes.number,
		totalPages: PropTypes.number,
		totalRecords: PropTypes.number,
		hasNextPage: PropTypes.bool,
		hasPrevPage: PropTypes.bool,
		nextPage: PropTypes.number,
		prevPage: PropTypes.number,
	}),
	setPagination: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	variant: PropTypes.oneOf(['primary', 'secondary']),
}
DataTable.defaultProps = {
	columns: [],
	data: [],
	variant: 'primary',
}

export default DataTable
