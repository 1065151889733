import apiEndpoints from 'helpers/apiEndpoints';
import useSWR from 'swr';
import fetcher from 'utils/fetcher';

const TokenValidateQuery = (token) => {
    const url = `${apiEndpoints.userOnBoard.selfie.qrCodeToken.validate}?token=${token}`
    const { data, error } = useSWR(token ? url : null, fetcher)
    const isLoading = !error && !data

    return {
        istokenInvalid: typeof error !== "undefined",
        isValidating: isLoading
    }
}

export default TokenValidateQuery